/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, message } from "antd";
import { CSSTransition } from "react-transition-group";
import TopTitleBar from "../components/TopTitleBar";
import Dropdown from "../components/FormComponents/Dropdown";
import style from "./pages.module.css";
import RadioButton from "../components/FormComponents/RadioButton";
import Input from "../components/FormComponents/Input";
import PrimaryButton from "../components/FormComponents/PrimaryButton";
import { getVaccineData } from "../components/Firebase/util";
import { districtIds } from "../data/DistrictIdsFinal";
import { Card } from "../components/FormComponents";
import OTPHandler from "../components/VaccineEmailAuth";
import "../css/otp.css";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 20, color: "#00008b" }} spin />
);

const vaccines = ["Covishield", "Covaxin", "Sputnik V", "All"];

const VaccineBook = ({ firebase, onToggleBetweenPages }) => {
  const [states, setStates] = useState({});
  const [cities, setCities] = useState([]);
  const [stateSelected, setStateSelected] = useState(undefined);
  const [stateClicked, setStateClicked] = useState(false);
  const [cityClicked, setCityClicked] = useState(false);
  const [citySelected, setCitySelected] = useState(undefined);
  const [vaccineSelected, setVaccineSelected] = useState("All");
  const [age, setAge] = useState("Both");
  const [email, setEmail] = useState("");
  const [mailClicked, setMailClicked] = useState(false);
  const [preference, setPreference] = useState("Only Notify");
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openOTPHandler, setOpenOTPHandler] = useState(false);
  const [verifiedEmailToken, setVerifiedEmailToken] = useState(undefined);
  const [verificationError, setVerificationError] = useState("");
  const [otpLoading, setOTPLoading] = useState(false);
  const history = useHistory();

  const msg = firebase.messaging;

  const subscribeVaccine = firebase.app
    .functions()
    .httpsCallable("subscribeVaccine");
  const getOTP = firebase.app.functions().httpsCallable("getOTP");
  const verifyOTP = firebase.app.functions().httpsCallable("verifyOTP");

  const getNotification = async () => {
    Notification.requestPermission();
  };

  function handleOTPVerification(otp) {
    setOTPLoading(true);
    setVerificationError("");
    verifyOTP({ email: email.toLowerCase(), otp })
      .then(async (res) => {
        const { data } = res;
        if (data.status === "error") {
          setVerificationError("Invalid OTP");
          setOTPLoading(false);
        } else if (data.status === "success") {
          await getNotification();
          await generateToken().then((token) => {
            subscribeVaccine({
              state: stateSelected,
              district: citySelected,
              age,
              vaccine: vaccineSelected,
              email: email.toLowerCase(),
              token,
              verifyEmailToken: data.token,
            });
          });
          setOTPLoading(false);
          setOpenOTPHandler(false);
          setVerificationError("");
          setVerifiedEmailToken({
            email: email.toLowerCase(),
            token: data.token,
          });
          const emailTokenData = JSON.stringify({
            email: email.toLowerCase(),
            token: data.token,
          });
          window.localStorage.setItem("vaccineEmailToken", emailTokenData);
          message.success({
            content: "Successfully registered for Alert!",
            style: { marginTop: "65px" },
          });
        }
      })
      .catch((err) => {
        console.error(err);
        setOTPLoading(false);
        message.error(
          {
            content: "Request timed out, Please try again!",
            style: { marginTop: "65px", zIndex: "9999" },
          },
          100
        );
      });
  }

  const generateToken = () => {
    if (!msg) {
      return;
    }

    return msg
      .getToken({
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      })
      .then((currentToken) => {
        if (currentToken) {
          return currentToken;
        }
        // Show permission request UI
      })
      .catch((err) => {});
  };

  function emailHandler() {
    if (!mailClicked) setMailClicked(true);
  }

  let stateRef = null;
  let stateListener = null;
  const prefRef = null;
  const prefListener = null;
  let verificationTokenSent = false;

  const verifyEmail = async () => {
    window.localStorage.setItem("state", stateSelected);
    window.localStorage.setItem("city", citySelected);
    window.localStorage.setItem("age", age);
    window.localStorage.setItem("vaccine", vaccineSelected);
    window.localStorage.setItem("email", email);
    if (
      // No Verified Token & OTP not generated
      (!verifiedEmailToken ||
        (verifiedEmailToken.email &&
          email &&
          verifiedEmailToken.email.toLowerCase() !== email.toLowerCase()) ||
        !verifiedEmailToken.token) &&
      !verificationTokenSent
    ) {
      setButtonLoading(true);
      const sendingOTP = message.loading(
        { content: "Getting OTP to email", style: { marginTop: "65px" } },
        0
      );
      getOTP({ email })
        .then(async (res) => {
          setButtonLoading(false);
          sendingOTP();
          setOpenOTPHandler(true);
          verificationTokenSent = false;
        })
        .catch((err) => {
          sendingOTP();
          setButtonLoading(false);
          setOpenOTPHandler(false);
          console.error(err);
          message.error(
            {
              content: "Sending OTP failed, Please try again!",
              style: { marginTop: "65px" },
            },
            0
          );
        });
    } else if (verificationTokenSent && !verifiedEmailToken) {
      setOpenOTPHandler(true);
    } else {
      setButtonLoading(true);
      // preference === "Auto Book" && onToggleBetweenPages(); // go to next page only if user want to auto book else
      await getNotification();
      await generateToken().then((token) => {
        return subscribeVaccine({
          state: stateSelected,
          district: citySelected,
          age,
          vaccine: vaccineSelected,
          email: email.toLowerCase(),
          token,
          verifyEmailToken: verifiedEmailToken.token,
        });
      });
      setButtonLoading(false);
      message.success({
        content: "Successfully updated Alert!",
        style: { marginTop: "65px" },
      });
    }
  };

  function buttonTextHandler() {
    let text = preference === "Only Notify" ? "Update Alert" : "Coming Soon";
    if (
      !verifiedEmailToken ||
      (verifiedEmailToken.email &&
        email &&
        verifiedEmailToken.email.toLowerCase() !== email.toLowerCase()) ||
      !verifiedEmailToken.token
    ) {
      text = "Verify Email & Create Alert";
    }
    return (
      <div style={{ position: "relative" }}>
        {text}{" "}
        <Spin
          style={{
            position: "absolute",
            right: "-25px",
            lineHeight: "1",
            top: "calc(50% - 10px)",
          }}
          spinning={buttonLoading}
          indicator={antIcon}
        />
      </div>
    );
  }

  function onMount() {
    [stateRef, stateListener] = getVaccineData(firebase, function (stateData) {
      // let notAvaialble = [];
      Object.keys(stateData).forEach((state) => {
        stateData[state].forEach((city) => {
          if (districtIds[state].cities[city] === -1) {
            stateData[state].splice(stateData[state].indexOf(city), 1);
          }
        });

        setStates(stateData);
        const vaccineEmailToken =
          window.localStorage.getItem("vaccineEmailToken");
        let verifiedEmail = {};
        if (vaccineEmailToken) {
          try {
            verifiedEmail = JSON.parse(vaccineEmailToken);
          } catch {
            console.error("Invalid item stored in localStorage");
          }
        }
        const stateSaved = window.localStorage.getItem("state");
        const citySaved = window.localStorage.getItem("city");
        const ageSaved = window.localStorage.getItem("age")
          ? window.localStorage.getItem("age")
          : "Both";
        const vaccineSaved = window.localStorage.getItem("vaccine")
          ? window.localStorage.getItem("vaccine")
          : "All";
        const emailSaved = window.localStorage.getItem("email");
        setVerifiedEmailToken(verifiedEmail);
        setAge(ageSaved);
        setVaccineSelected(vaccineSaved);
        setStateSelected(stateSaved || undefined);
        setCitySelected(citySaved || undefined);
        setEmail(emailSaved || "");
        setLoading(false);
      });
    });
  }

  function onUnmount() {
    if (!stateRef && !stateListener) {
    } else if (stateRef && stateListener) {
      stateRef.off(undefined, stateListener);
    } else {
      console.error(
        "Ref and listener can only be both null or both initialized"
      );
    }
    if (!prefRef && !prefListener) {
    } else if (prefRef && prefListener) {
      prefRef.off(undefined, prefListener);
    } else {
      console.error(
        "Ref and listener can only be both null or both initialized"
      );
    }
  }

  useEffect(() => {
    onMount();
    return onUnmount;
  }, []);

  function selectState(val) {
    setCities(states[val]);
    setCitySelected("");
    setStateSelected(val);
    setCityClicked(false);
  }

  function handleState() {
    if (!stateClicked) setStateClicked(true);
  }

  function handleCity() {
    if (!cityClicked) setCityClicked(true);
  }

  return (
    <>
      <TopTitleBar
        title="Vaccination Slots"
        chosen={() => {}}
        backLink="/home"
      />
      <CSSTransition
        in={openOTPHandler}
        timeout={300}
        classNames="slide-up-down"
        mountOnEnter
        unmountOnExit
      >
        <OTPHandler
          email={email}
          onSubmitCallback={(otp) => handleOTPVerification(otp)}
          backHandler={() => {
            setOpenOTPHandler(false);
            setVerificationError("");
          }}
          otpLoading={otpLoading}
          verificationError={verificationError}
        />
      </CSSTransition>
      <div className={style.vaccinepage} style={{ minHeight: "100vh" }}>
        <div className={`${style.formContainer}`}>
          {loading ? (
            <Spin spinning />
          ) : (
            <>
              <div
                className={`${style.dropdown}`}
                style={{ marginBottom: "1rem" }}
              >
                <Card
                  title="Vaccine Slot Notifier"
                  text="As soon as slot will be available, an email & notification will be sent on your device."
                  note="** Notification feature is available for 54 most populous
                  cities only"
                />
              </div>
              <div className={`${style.dropdown}`} onClick={handleState}>
                <Dropdown
                  placeholder="State"
                  label="State"
                  values={Object.keys(states)}
                  onSelect={selectState}
                  selected={stateSelected}
                />
                <p
                  className={style.errormsg}
                  style={{
                    visibility:
                      stateClicked && !stateSelected ? "visible" : "hidden",
                  }}
                >
                  Please select a state
                </p>
              </div>
              <div className={`${style.dropdown}`} onClick={handleCity}>
                <Dropdown
                  placeholder="City"
                  label="City"
                  values={stateSelected ? cities : []}
                  onSelect={setCitySelected}
                  selected={citySelected}
                />
                <p
                  className={style.errormsg}
                  style={{
                    visibility:
                      cityClicked && !stateSelected && !citySelected
                        ? "visible"
                        : "hidden",
                  }}
                >
                  Please select a city
                </p>
              </div>
              <div
                className={`${style.dropdown}`}
                style={{ marginBottom: "1rem" }}
              >
                <RadioButton
                  data={["18+", "45+", "Both"]}
                  onSelect={setAge}
                  label="Age"
                  value={age}
                />
              </div>
              <div
                className={`${style.dropdown}`}
                style={{ marginBottom: "1rem" }}
              >
                <RadioButton
                  data={vaccines}
                  onSelect={setVaccineSelected}
                  label="Vaccine"
                  value={vaccineSelected}
                />
              </div>
              <div className={`${style.dropdown}`}>
                <Input
                  label="Email"
                  setInput={setEmail}
                  placeholder="example@mail.com"
                  onFocus={emailHandler}
                  value={email}
                />
                <p
                  className={style.errormsg}
                  style={{
                    color: "red",
                    visibility:
                      mailClicked &&
                      email.search(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g) !== 0
                        ? "visible"
                        : "hidden",
                  }}
                >
                  {" "}
                  Please enter a valid email address{" "}
                </p>
              </div>
              {/* <div
                className={`${style.dropdown}`}
                style={{ marginBottom: "1rem" }}
              >
                <RadioButton
                  data={["Only Notify", "Auto Book"]}
                  value={preference}
                  onSelect={setPreference}
                  dataIcons={[
                    <AlertFilled
                      style={{ fontSize: "20px", marginRight: "4px" }}
                    />,
                    <ThunderboltFilled
                      style={{ fontSize: "20px", marginRight: "4px" }}
                    />,
                  ]}
                  buttonStyle={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                />
              </div> */}

              <PrimaryButton
                isSecondary={false}
                icon={null}
                text={buttonTextHandler()}
                onClick={verifyEmail}
                disable={
                  buttonLoading ||
                  stateSelected === "" ||
                  citySelected === "" ||
                  email.search(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g) !== 0 ||
                  preference === "Auto Book"
                }
              />
              <br />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default VaccineBook;
