/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import { LeftOutlined } from "@ant-design/icons";
import { Hidden } from "@material-ui/core";
import React from "react";
import Iframe from "react-iframe";
import { useHistory } from "react-router-dom";
import TopTitleBar from "../components/TopTitleBar";
import style from "./hotspots.module.css";

function OneMgHotspots() {
  const primaryColor = "rgb(33, 150, 243)";
  const history = useHistory();

  return (
    <>
    <TopTitleBar title="COVID-19 Case Numbers by 1mg" legend="A geo-temporal representation of search related to covid 19 on 1mg  platforms." />    
    <div className={style.iframeContainer}>  
        <Iframe
          src="https://geekayush1mg.github.io/search-heatmap/"
          width="100%"
          scrolling="no"
          className={style.iframe}
        />
    </div>
    </>
  );
}

export default OneMgHotspots;
