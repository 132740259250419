/* eslint-disable import/prefer-default-export */
export const homepageData = [
  // json object for rendering the cards/tiles in /pages/HomePage.js file
  {
    text: "Need Help?",
    image: "/assets/homepage/survivor.jpg",
    path: "NeedHelp",
  },
  {
    text: "Live Oxygen Leads",
    image: "/assets/homepage/twitter.png",
    path: "OxygenLeads",
  },
  {
    text: "Vacant Beds Tracker",
    image: "/assets/homepage/beds.png",
    path: "VacantBeds",
  },
  {
    text: "Vaccination Slots",
    image: "/assets/homepage/vaccine.png",
    path: "Vaccination",
  },
  // {
  //   text: "Donate /  Request Plasma",
  //   image: "/assets/homepage/first_aid.png",
  //   path: "Plasma",
  // },
  {
    text: "Help in the COVID-19 efforts by filling survey",
    image: "/assets/homepage/survey.png",
    path: "Survey",
  },
  {
    text: "Food",
    image: "/assets/homepage/food.png",
    path: "Food",
  },
  {
    text: "Resource List",
    image: "/assets/homepage/list.png",
    path: "Resources",
  },
  {
    text: "Statewise Covid Helplines",
    image: "/assets/homepage/statewise_help.png",
    path: "CovidHelplines",
  },
  {
    text: "City Stress Meter",
    image: "/assets/homepage/stress_meter.png",
    path: "CityStressMeter",
  },
  {
    text: "Crowdsourced COVID-19 Predictions",
    image: "/assets/icons/icon-192x192.png",
    path: "PredictiveAnalysis",
  },
  {
    text: "COVID-19 Case Numbers by 1mg",
    image: "/assets/homepage/1mg-squarelogo.png",
    path: "1mgHotspots",
  },
  {
    text: "Feedback",
    image: "/assets/homepage/Feedback.png",
    path: "Feedback",
  },
];
