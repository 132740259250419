/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { Input as AntdInput} from 'antd';
import style from './components.module.css';

const Input = ({ setInput, placeholder, label, onFocus, value }) => {

  return (
    <>
      <label className={style.label}>{label}</label>
      <AntdInput
        placeholder={placeholder}
        onChange={(e) => setInput(e.target.value)}
        onFocus={(e) => {
          if (onFocus) {
            return onFocus(e);
          }
        }}
        value={value}
        style={{
          maxHeight: "40px",
          overflow: "auto",
          padding: "0 10px",
          fontSize: "16px",
          height: "40px",
          borderRadius: "6px",
        }}
      />
    </>
  );
};

export default Input;
