/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import ShowNumber from "../components/ShowNumber";
import { helplineData } from "../data/ExtractedData/Helpline";
import TopTitleBar from "../components/TopTitleBar";

function StateHelpline({ match }) {
  const primaryColor = "rgb(33, 150, 243)";
  if (!match) {
    // User repeatedly pressed back
    return null;
  }

  const stateHelpline = helplineData[match.params.state][0];

  return (
    <div className="container-fluid" style={{ fontFamily: "sans-serif" }}>
      <>
        <TopTitleBar
          title={stateHelpline.state}
          backLink="/home/CovidHelplines"
          chosen={() => {}}
        />
        <div>
          <div
            style={{
              fontSize: "1.3rem",
              fontWeight: "900",
              fontFamily: "sans-serif",
              color: "rgb(80,80,80)",
            }}
            className="align-center"
          >
            {stateHelpline.state}
          </div>
          <div style={{ fontSize: "1rem" }}>{stateHelpline.Contact}</div>
          <div />
          <ShowNumber Contact={stateHelpline.Contact} />
        </div>
      </>
    </div>
  );
}

export default StateHelpline;
