import React from "react";

const primaryColor = "rgb(33, 150, 243)";

function Header({ title }) {
  return (
    <div
      className="pt-1 pb-2 pl-1 row"
      style={{ backgroundColor: primaryColor, margin: "0" }}
    >
      <div
        className="col-10"
        style={{
          color: "white",
          fontSize: "1.4rem",
          fontWeight: "600",
          fontFamily: "sans-serif",
        }}
        className="m-auto"
      >
        {title}
      </div>
    </div>
  );
}
export default Header;
