/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
import { RightOutlined } from "@ant-design/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import Tiles from "../components/Tiles/HomePageTiles";
import { aboutData } from "../data/AboutData";
import Header from "../components/Header";

function About() {
  const history = useHistory();

  return (
    <>
      <Header title="About" />
      <div className="container-fluid">
        <div>
          <div>
            <>
              {aboutData.map((data) => (
                <div
                  key={data.title}
                  onClick={() => history.push(`/about/${data.title}`)}
                >
                  <Tiles
                    avatar
                    avatarIcon={data.icon}
                    text={data.title}
                    icon={<RightOutlined />}
                  />
                </div>
              ))}
            </>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
