/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-duplicate-props */
import { LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

function TopTitleBar(props) {
  const primaryColor = "rgb(33, 150, 243)";
  const history = useHistory();

  return (
    <div className="sticky-top row" style={{ backgroundColor: primaryColor }}>
      <div
        className="text-truncate p-2 text-center col-12 m-auto"
        style={{
          backgroundColor: primaryColor,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {/* <NavLink to={props.backLink ? props.backLink : "/"}> */}
        <LeftOutlined
          onClick={() => {
            props.chosen && props.chosen(0);
            history.replace(props.backLink ? props.backLink : "/home");
          }}
          style={{
            fontSize: "22px",
            position: "absolute",
            color: "white",
            padding: "1px",
            left: "10px",
            marginTop: "2px",
          }}
        />
        {/* </NavLink> */}
        <div
          className="text-truncate"
          style={{
            color: "white",
            fontSize: "1.2rem",
            fontWeight: "500",
            fontFamily: "sans-serif",
            paddingLeft: "24px",
            paddingRight: "20px",
          }}
        >
          {props.title}
        </div>
      </div>
      {
        props.legend &&
        <div
        className="m-1 m-auto"
        style={{ backgroundColor: primaryColor, color: "white", textAlign: "center", alignItems: "center" }}
      >
        {props.legend}
      </div>
      }
      
    </div>
  );
}

export default TopTitleBar;
