/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import { IoMdCall, IoIosText } from "react-icons/io";

function ShowNumber({ Contact }) {
  const primaryColor = "rgb(33, 150, 243)";

  return (
    <div className="" style={{ fontFamily: "sans-serif" }}>
      <div className="">
        <div
          style={{
            fontSize: "1rem",
            fontWeight: "400",
            fontFamily: "sans-serif",
            color: "rgb(120,120,120)",
          }}
          className="align-center"
        >
          Number
        </div>
        <div style={{ fontSize: "1rem" }}>
          {Contact}

          <a href={`tel:${Contact}`}>
            <IoMdCall
              className="float-right mr-2 align-top"
              size="25px"
              style={{ cursor: "pointer" }}
              color={primaryColor}
            />
          </a>
          <a href={`sms:${Contact}`}>
            <IoIosText
              className="float-right mr-2 align-top"
              size="25px"
              style={{ cursor: "pointer" }}
              color={primaryColor}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ShowNumber;
