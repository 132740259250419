import { sha256 } from "js-sha256";
import Firebase from "firebase/app";
import utf8 from "utf8";
import base64 from "base-64";

function addLike(firebase, resourceInfo, val) {
  if (!Number.isInteger(val) || val < -2 || val > 2 || val === 0) {
    throw new Error("Invalid transaction");
  }
  const { db } = firebase;
  let { cityName, resourceType, resourceName } = resourceInfo;
  cityName = sha256(cityName);
  resourceType = sha256(resourceType);
  resourceName = sha256(resourceName);
  const likeRef = db.ref(`likes/${cityName}/${resourceType}/${resourceName}`);
  return likeRef
    .push({
      timestamp: Firebase.database.ServerValue.TIMESTAMP,
      transaction: val,
    })
    .then(() => {
      // Data saved successfully!
    })
    .catch((error) => {
      // The write failed...
      console.error(error);
    });
}

function getLike(firebase, resourceInfo) {
  const { db } = firebase;
  let { cityName, resourceType, resourceName } = resourceInfo;
  cityName = sha256(cityName);
  resourceType = sha256(resourceType);
  resourceName = sha256(resourceName);
  const likeRef = db.ref(`likes/${cityName}/${resourceType}/${resourceName}`);
  return likeRef
    .get()
    .then((snapshot) => {
      if (snapshot.exists()) {
        const likesData = snapshot.val();
        let likes = 0;
        const lastHour = Date.now() - 1000 * 60 * 60;
        Object.keys(likesData).forEach((key) => {
          const { timestamp, transaction } = likesData[key];
          if (timestamp >= lastHour) {
            likes += transaction;
          }
        });
        return `${likes}`;
      }
      return "0";
    })
    .catch((error) => {
      console.error(error);
    });
}

function getLikeRealtime(firebase, resourceInfo, callback) {
  const { db } = firebase;
  let { cityName, resourceType, resourceName } = resourceInfo;
  cityName = sha256(cityName);
  resourceType = sha256(resourceType);
  resourceName = sha256(resourceName);
  const likeRef = db.ref(`likes/${cityName}/${resourceType}/${resourceName}`);
  return [
    likeRef,
    likeRef.on("value", (snapshot) => {
      if (snapshot.exists()) {
        const likesData = snapshot.val();
        let likes = 0;
        const lastHour = Date.now() - 1000 * 60 * 60;
        Object.keys(likesData).forEach((key) => {
          const { timestamp, transaction } = likesData[key];
          if (timestamp >= lastHour) {
            likes += transaction;
          }
        });
        return callback(`${likes}`);
      }
      return callback("0");
    }),
  ];
}

function addComment(firebase, resourceInfo, comment) {
  const { db } = firebase;
  let { cityName, resourceType, resourceName } = resourceInfo;
  cityName = sha256(cityName);
  resourceType = sha256(resourceType);
  resourceName = sha256(resourceName);
  let user = utf8.encode(comment.name);
  user = base64.encode(user);
  let message = utf8.encode(comment.msg);
  message = base64.encode(message);
  const commentRef = db.ref(
    `comments/${cityName}/${resourceType}/${resourceName}`
  );
  return commentRef
    .push({
      time: Firebase.database.ServerValue.TIMESTAMP,
      comment: message,
      name: user,
      // email: comment.email,
    })
    .then(() => {
      // Data saved successfully!
    })
    .catch((error) => {
      // The write failed...
      console.error(error);
    });
}

function getComments(firebase, resourceInfo, callback) {
  const { db } = firebase;
  let { cityName, resourceType, resourceName } = resourceInfo;
  cityName = sha256(cityName);
  resourceType = sha256(resourceType);
  resourceName = sha256(resourceName);
  const commentRef = db.ref(
    `comments/${cityName}/${resourceType}/${resourceName}`
  );
  return [
    commentRef,
    commentRef.on("value", (snapshot) => {
      if (snapshot.exists()) {
        const commentData = snapshot.val();
        const comments = [];
        Object.keys(commentData).forEach((key) => {
          const { time, name, comment } = commentData[key];
          comments.push({
            Username: utf8.decode(base64.decode(name)),
            time,
            Comment: utf8.decode(base64.decode(comment)),
          });
        });
        return callback(comments.sort((a, b) => b.time - a.time));
      }
      // No data available
      return callback([]);
    }),
  ];
}

function addVaccineData(firebase, resourceInfo, city) {
  const { db } = firebase;
  let { state } = resourceInfo;
  const vaccineRef = db.ref(`vaccines`);
  city = utf8.encode(city);
  city = base64.encode(city);
  state = utf8.encode(state);
  state = base64.encode(state);
  return vaccineRef
    .push({
      state,
      city,
    })
    .then(() => {
      // Data saved successfully!
    })
    .catch((error) => {
      // The write failed...
      console.error(error);
    });
}

function getVaccineData(firebase, callback) {
  const { db } = firebase;
  const vaccineRef = db.ref(`vaccines`);
  return [
    vaccineRef,
    vaccineRef.on("value", (snapshot) => {
      if (snapshot.exists()) {
        const vaccineData = snapshot.val();
        const data = {};
        Object.keys(vaccineData).forEach((key) => {
          let { state, city } = vaccineData[key];
          state = utf8.decode(base64.decode(state));
          city = utf8.decode(base64.decode(city));
          if (
            Object.keys(data).indexOf(state) !== -1 &&
            data[state].indexOf(city) === -1
          ) {
            data[state].push(city);
          } else if (Object.keys(data).indexOf(state) === -1) {
            data[state] = [city];
          }
        });
        return callback(data);
      }
      // No data available
      return callback([]);
    }),
  ];
}

function addVaccinePreference(firebase, resourceInfo, data) {
  const { db } = firebase;
  let { stateName, cityName, age, vaccine } = resourceInfo;
  cityName = sha256(cityName);
  stateName = sha256(stateName);
  vaccine = sha256(vaccine);
  age = sha256(age);
  const vaccinePref = db.ref(
    `vaccinePreference/${stateName}/${cityName}/${age}/${vaccine}`
  );
  let { email } = data;
  email = base64.encode(utf8.encode(email));
  return vaccinePref
    .push({
      email,
    })
    .then(() => {
      // Data saved successfully!
    })
    .catch((error) => {
      // The write failed...
      console.error(error);
    });
}

function getVaccinePreference(firebase, resourceInfo, callback) {
  const { db } = firebase;
  let { email } = resourceInfo;
  email = utf8.encode(email);
  email = base64.encode(email);
  const vaccinePref = db.ref(`vaccinePreference/${email}`);
  return [
    vaccinePref,
    vaccinePref.on("value", (snapshot) => {
      if (snapshot.exists()) {
        const preferenceData = snapshot.val();
        return callback(preferenceData);
      }
      // No data available
      return callback({});
    }),
  ];
}

function updateVaccinePreference(firebase, resourceInfo, email) {
  const { db } = firebase;
  const { stateName, cityName, age, vaccine } = resourceInfo;
  const emailEncoded = base64.encode(utf8.encode(email));
  // email = base64.encode(email);
  const vaccinePref = db.ref(`vaccinePreference/${emailEncoded}`);

  return vaccinePref.remove().then(() => {
    vaccinePref
      .update({
        state: stateName,
        city: cityName,
        age,
        vaccine,
        state_city_agegroup_vaccine: `${stateName}_${cityName}_${age}_${vaccine}`,
        email,
      })
      .then(() => {
        // Data saved successfully!
      })
      .catch((error) => {
        // The write failed...
        console.error(error);
      });
  });
}

export {
  addLike,
  getLike,
  getLikeRealtime,
  addComment,
  getComments,
  getVaccineData,
  addVaccineData,
  addVaccinePreference,
  getVaccinePreference,
  updateVaccinePreference,
};
