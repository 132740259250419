import {
  ContactsFilled,
  QuestionOutlined,
  WarningFilled,
} from "@ant-design/icons";

export const aboutData = [
  {
    icon: (
      <QuestionOutlined
        style={{ fontSize: "28px", marginRight: "15px", padding: "5px" }}
      />
    ),
    title: "Why This App?",
    content:
      "This is a non-profit app for the live tracking of hospital beds. All information is taken from respective government websites. To further avoid the hassle, we suggest that you call the hospital of your choice before going there to confirm the availability shown here. This is a small initiative from 3 IITD alumni. If you have links to data for more cities please feel free to share at “milan@edvicer.com”",
  },
  {
    icon: (
      <WarningFilled
        style={{ fontSize: "28px", marginRight: "15px", padding: "5px" }}
      />
    ),
    title: "Disclaimer",
    content: `Karuna a website and application from Path Check Inc, dba PathCheck Foundation (“PCF”), a US non-profit corporation. PCF partnered Covid Survivor Force India (CSFI), an unincorporated organization  connecting people.  PCF shall not be held liable for any loss that you may incur by relying on this information or app. 

We strongly advise you against forwarding advance money to any vendor(s) or service provider(s) in order to safe-guard you from any kind of fraud.

Images used are from free sources or bought from official websites. If you want us to remove any of your image(s) kindly write to us on the email provided in the contact section and we will remove it.

If your number/contact has been added by error, please contact us on the email provided in the contact section. We will remove it.

Use of medicines/resources must be done in keeping with the doctor's advice & government policy.

PCF and CSFI are not a part of or endorse any fundraisers. 

The survey collects anonymous data and thus the privacy of the subject is preserved. The survey's purpose is to help the researchers understand the covid pandemic and make predictive models.

This application is intended for informational, education and research purposes only. The contents of this app are provided as a convenience to our app users as a source of information only, and we do not take responsibility for the decisions taken by the user  based solely on the information provided in this app. This application is not a substitute for medical advice. Users of the application should consult with medical and healthcare professionals before making any health, medical, or other decisions based on the data contained herein.`,
  },
  {
    icon: (
      <ContactsFilled
        style={{ fontSize: "28px", marginRight: "15px", padding: "5px" }}
      />
    ),
    title: "Contact",
    content: "For any feedback/suggestions contact:",
    mails: {
      "Milan Roy": "milan@edvicer.com",
      "Rohan Sukumaran": "rohan.sukumaran@pathcheck.org",
      "Imane Chafi": "imane.chafi@pathcheck.org",
    },
  },
];
