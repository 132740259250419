/* eslint-disable react/jsx-filename-extension */
import React from "react";
import Iframe from "react-iframe";
import { useHistory } from "react-router-dom";
import { sprinklrData } from "../data/ExtractedData/OxygenLinks";
import useWindowSize from "../useWindowSize";

function OxygenLeads({ match }) {
  const primaryColor = "rgb(33, 150, 243)";
  const history = useHistory();
  const { height } = useWindowSize();
  const iframeHeight = height / 0.866477 - 45 - 47;

  return (
    <div className="container-fluid" style={{ fontFamily: "sans-serif" }}>
      <div className="h-100">
        <div
          className="row sticky-top"
          style={{ backgroundColor: primaryColor }}
        >
          <div
            className="col-2 d-flex mt-1 p-2"
            onClick={() => {
              history.replace("/home/OxygenLeads");
            }}
            style={{ cursor: "pointer" }}
          >
            <span
              style={{
                color: "white",
                fontSize: "1rem",
                fontWeight: "500",
                fontFamily: "sans-serif",
              }}
            >
              Done
            </span>
          </div>

          <div
            style={{
              color: "white",
              fontSize: "1.2rem",
              fontWeight: "500",
              fontFamily: "sans-serif",
            }}
            className="m-auto"
          >
            Sprinklr
          </div>

          <div className="col-2" />
        </div>
        {match && (
          <Iframe
            className="m-auto survey-form"
            url={
              !!match.params.city &&
              sprinklrData[match.params.city][0]["Link Oxygen"]
            }
            width="100%"
            height={iframeHeight}
            display="initial"
            position="relative"
          />
        )}
      </div>
    </div>
  );
}

export default OxygenLeads;
