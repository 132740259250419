import React, { useState, useEffect } from "react";
import { BiLink } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import TopTitleBar from "../components/TopTitleBar";
import Tiles from "../components/Tiles/HomePageTiles";
import { openInNewTab } from "../JsUtil/OpenInNewTab";

function NeedHelp(props) {
  // const [ locationKeys, setLocationKeys ] = useState([])
  // const history = useHistory()

  const primaryColor = "rgb(33, 150, 243)";

  return (
    <div className="" style={{ fontFamily: "sans-serif" }}>
      <TopTitleBar title="Need Help?" backLink="/home" />
      <div>
        <p style={{ fontSize: "1.2rem", fontWeight: "700" }}> Need Help? </p>
        <div style={{ fontSize: "0.9rem", fontWeight: "500" }}>
          In partnership with Covid survivor force India, Karuna would like to
          entend the help and support. Covid survivor force connects people in
          need with verified leads on ground.
          <br />
          To post your request use either Twitter or Facebook.
        </div>
      </div>
      <div className="mt-3">
        <a onClick={() => openInNewTab("https://twitter.com/CovRelief")}>
          <Tiles
            text="Twitter"
            image="/assets/8.png"
            icon={<BiLink size="20px" color={primaryColor} />}
          />
        </a>
        <a
          onClick={() =>
            openInNewTab(
              "https://www.facebook.com/groups/2489879517824917/?ref=share"
            )
          }
        >
          <Tiles
            text="Facebook"
            image="/assets/9.png"
            icon={<BiLink size="20px" color={primaryColor} />}
          />
        </a>
      </div>
    </div>
  );
}

export default NeedHelp;
