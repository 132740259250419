import { sha256 } from "js-sha256";

function encryptLike(resourceInfo) {
  let { cityName, resourceType, resourceName } = resourceInfo;
  cityName = sha256(cityName);
  resourceType = sha256(resourceType);
  resourceName = sha256(resourceName);
  const key = sha256(`${cityName}/${resourceType}/${resourceName}`);
  return key;
}
export { encryptLike };
