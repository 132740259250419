import React from "react";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/en/world.json";

import "antd-country-phone-input/dist/index.css";

function PhoneInput({ value, onChange }) {
  return (
    <ConfigProvider locale={en}>
      <CountryPhoneInput value={value} onChange={onChange} />
    </ConfigProvider>
  );
}
export default PhoneInput;
