/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from "react";
import { LinkedinOutlined } from "@ant-design/icons";
import { BiLink } from "react-icons/bi";
import { openInNewTab } from "../../JsUtil/OpenInNewTab";
import TopTitleBar from "../TopTitleBar";
import { teamData } from "../../data/ExtractedData/TeamData";

function TeamMemberDisplay({ match }) {
  const primaryColor = "rgb(33, 150, 243)";
  if (!match) {
    return null;
  }

  const memberData = teamData.filter(
    (entry) => entry.member === match.params.member
  )[0];

  const {
    member,
    linkedin,
    profile,
    details,
    title,
    additionalLink,
    additionalLinkTitle,
  } = memberData;

  return (
    <div className="container-fluid">
      <TopTitleBar title={member} chosen={() => {}} backLink="/team" />
      <div
        style={{
          fontSize: "1.2rem",
          fontWeight: "600",
          fontFamily: "sans-serif",
          color: "rgb(80,80,80)",
        }}
        className="align-center mt-3"
      >
        {member}
        <div style={{ fontSize: ".8rem", fontWeight: "400" }}>{title}</div>
      </div>
      <div style={{ fontSize: "1rem", lineHeight: 1.8, marginTop: "5px" }}>
        {details}
        {additionalLink && (
          <a target="_blank" rel="noreferrer" href={additionalLink}>
            {additionalLinkTitle}
          </a>
        )}
      </div>
      {linkedin !== "" && (
        <>
          <a onClick={() => openInNewTab(linkedin)}>
            <p className="mt-3">
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "500",
                  alignSelf: "center",
                  color: primaryColor,
                }}
              >
                {profile ? (
                  <>
                    {" "}
                    Link to Profile
                    <BiLink
                      size="28px"
                      color={primaryColor}
                      className="float-right p-1"
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    LinkedIn
                    <LinkedinOutlined
                      className="float-right mt-1"
                      style={{ color: primaryColor, fontSize: "24px" }}
                    />
                  </>
                )}
              </span>
            </p>
          </a>
        </>
      )}
    </div>
  );
}

export default TeamMemberDisplay;
