/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import { Button } from "antd";
import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import WriteComment from "../Comments/WriteComment";
import AddUser from "../Comments/AddUser";
import "./transtition.css";

function AddCommentButton(props) {
  const [addComment, setAddComment] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const { firebase, resourceInfo } = props;
  function checkLogin() {
    if (firebase.app.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt("Please provide your email for confirmation");
      }
      let username = window.localStorage.getItem("usernameForSignIn");
      if (!username) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        username = window.prompt(
          "Please provide your username for confirmation"
        );
      }
      // The client SDK will parse the code from the link for you.
      firebase.app
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then((result) => {
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          const usernameForSignIn =
            window.localStorage.getItem("usernameForSignIn");
          return result.user.updateProfile({ displayName: usernameForSignIn });
        })
        .then(() => {
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn");
          window.localStorage.removeItem("usernameForSignIn");
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }

  useEffect(() => {
    const unlisten = firebase.app
      .auth()
      .onAuthStateChanged((authUserChanged) => {
        if (authUserChanged) {
          return setAuthUser(authUserChanged);
        }
        return setAuthUser(null);
      });
    checkLogin();
    // firebase.app.auth().signOut().then(() => console.log('Signed Out')).catch((err) => console.error(err));
    return () => {
      unlisten();
    };
  }, []);

  return (
    <>
      <Button onClick={() => setAddComment(!addComment)}>Add comment...</Button>
      <CSSTransition
        in={addComment && !!authUser}
        timeout={200}
        classNames="slide-up-down-fast"
        unmountOnExit
      >
        <WriteComment
          resourceInfo={resourceInfo}
          setAddComment={setAddComment}
          authUser={authUser}
        />
      </CSSTransition>
      <CSSTransition
        in={addComment && !authUser}
        timeout={300}
        classNames="slide-up-down"
        unmountOnExit
      >
        <AddUser firebase={firebase} setAddComment={setAddComment} />
      </CSSTransition>
    </>
  );
}
export default AddCommentButton;
