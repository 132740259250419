/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from "react";
import { RightOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import Avatar from "antd/lib/avatar/avatar";
import Tiles from "../components/Tiles/HomePageTiles";
import { homepageData } from "../data/HomepageData";
import style from "../css/homepageNav.module.css";

function Homepage() {
  useEffect(() => {
    // scroll to top, on mount
    window.scrollTo(0, 0);
  }, []);

  const primaryColor = "rgb(33, 150, 243)";
  const iconSize = 50;
  const karunaTextSize = "2.5rem";

  return (
    <>
      <nav
        className={`navbar p-none sticky-top ${style.flex}`}
        style={{ backgroundColor: primaryColor }}
      >
        <a href="#homepage" id="logo" className={style.box}>
          <Avatar
            size={iconSize}
            shape="square"
            className="ml-1"
            src="/assets/vector/default-monochrome-white-logo.png"
          />
        </a>

        <span
          style={{
            color: "white",
            fontSize: karunaTextSize,
            fontWeight: "500",
            fontFamily: "sans-serif",
          }}
          className={`${style.box} m-auto`}
        >
          Karuna
        </span>

        <div className={style.box} />
      </nav>
      <div className="container-fluid">
        <>
          {homepageData.map((data) => (
            <NavLink to={`/home/${data.path.toString()}`} key={data.path}>
              <Tiles
                classNames="touched"
                text={data.text}
                image={data.image}
                icon={<RightOutlined />}
              />
            </NavLink>
          ))}
        </>
      </div>
    </>
  );
}

export default Homepage;
