/* eslint-disable react/jsx-filename-extension */
import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";

import style from "./components.module.css";

const useStyles = makeStyles((theme) => ({
  normalButton: {
    border: `2px solid "rgb(33, 150, 243)"`,
    textTransform: "none",
  },
  selectedButton: {
    border: `2px solid "white"`,
    textTransform: "none",
  },
}));

const RadioButton = ({
  data,
  onSelect,
  label,
  value,
  dataIcons,
  buttonStyle,
  selectedColor,
}) => {
  const classes = useStyles();

  function handleChange(val, key) {
    onSelect(val);
  }
  let buttonStyleValidated = {};
  if (buttonStyle) {
    buttonStyleValidated = buttonStyle;
  }

  return (
    <div className={`${style.labelContainer}`}>
      <label className={`${style.label}`}> Select {label} Preference </label>
      <div className={`${style.buttonsContainer}`}>
        {data.map((val, idx) => (
          <Button
            classes={{
              outlined:
                val === value ? classes.selectedButton : classes.normalButton,
            }}
            variant="outlined"
            onClick={() => handleChange(val, idx)}
            key={idx}
            style={{
              background: val === value ? "rgb(33, 150, 243)" : "white",
              border: `2px solid ${
                val === value ? "rgb(33, 150, 243)" : "white"
              }`,
              color: !(val === value)
                ? selectedColor && "rgb(33, 150, 243)"
                : "white",
              alignSelf: "stretch",
              borderRadius: "5px",
              width: `calc(${100 / data.length}% - 10px)`,
              alignItems: "center",
              display: "flex",
              whiteSpace: "pre",
              ...buttonStyleValidated,
            }}
          >
            {dataIcons ? dataIcons[idx] : ""}
            {val}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default RadioButton;
