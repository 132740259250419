/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from "react";
import BookOrNotify from "./BookOrNotify";
import style from "./pages.module.css";
import VaccineBook from "./VaccineBook";

function VaccineMainRoute({ firebase }) {
  const [vaccineBookHidden, setVaccineBookHidden] = useState(false);

  const onToggleBetweenPages = () => {
    setVaccineBookHidden(!vaccineBookHidden);
  };


  return (
    <>
      <div className={vaccineBookHidden ? style["no-display"] : ""}>
        <VaccineBook
          firebase={firebase}
          onToggleBetweenPages={onToggleBetweenPages}
        />
      </div>

      <div className={!vaccineBookHidden ? style["no-display"] : ""}>
        <BookOrNotify
          firebase={firebase}
          onToggleBetweenPages={onToggleBetweenPages}
        />
      </div>
    </>
  );
}

export default VaccineMainRoute;
