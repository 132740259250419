export const helplineData = {
    "Andaman & Nicobar Islands": [
        {
            "avatar": "AN",
            "state": "Andaman & Nicobar Islands",
            "Contact": "03192-232102"
        }
    ],
    "Andhra Pradesh": [
        {
            "avatar": "AP",
            "state": "Andhra Pradesh",
            "Contact": "104"
        }
    ],
    "Arunachal Pradesh": [
        {
            "avatar": "AP",
            "state": "Arunachal Pradesh",
            "Contact": "9436055743"
        }
    ],
    "Assam": [
        {
            "avatar": "A",
            "state": "Assam",
            "Contact": "6913347770"
        }
    ],
    "Bihar": [
        {
            "avatar": "B",
            "state": "Bihar",
            "Contact": "104"
        }
    ],
    "Chandigarh": [
        {
            "avatar": "C",
            "state": "Chandigarh",
            "Contact": "9779558282"
        }
    ],
    "Chhattisgarh": [
        {
            "avatar": "C",
            "state": "Chhattisgarh",
            "Contact": "104"
        }
    ],
    "Dadra and Nagar Haveli and Daman\nand Diu": [
        {
            "avatar": "DA",
            "state": "Dadra and Nagar Haveli and Daman\nand Diu",
            "Contact": "104"
        }
    ],
    "Delhi": [
        {
            "avatar": "D",
            "state": "Delhi",
            "Contact": "011-22307145"
        }
    ],
    "Goa": [
        {
            "avatar": "G",
            "state": "Goa",
            "Contact": "104"
        }
    ],
    "Gujarat": [
        {
            "avatar": "G",
            "state": "Gujarat",
            "Contact": "104"
        }
    ],
    "Haryana": [
        {
            "avatar": "H",
            "state": "Haryana",
            "Contact": "8558893911"
        }
    ],
    "Himachal Pradesh": [
        {
            "avatar": "HP",
            "state": "Himachal Pradesh",
            "Contact": "104"
        }
    ],
    "Jammu and Kashmir": [
        {
            "avatar": "JA",
            "state": "Jammu and Kashmir",
            "Contact": "01912520982, 0194-\n2440283"
        }
    ],
    "Jharkhand": [
        {
            "avatar": "J",
            "state": "Jharkhand",
            "Contact": "104"
        }
    ],
    "Karnataka": [
        {
            "avatar": "K",
            "state": "Karnataka",
            "Contact": "104"
        }
    ],
    "Kerala": [
        {
            "avatar": "K",
            "state": "Kerala",
            "Contact": "0471-2552056"
        }
    ],
    "Ladakh": [
        {
            "avatar": "L",
            "state": "Ladakh",
            "Contact": "01982256462"
        }
    ],
    "Lakshadweep": [
        {
            "avatar": "L",
            "state": "Lakshadweep",
            "Contact": "104"
        }
    ],
    "Madhya Pradesh": [
        {
            "avatar": "MP",
            "state": "Madhya Pradesh",
            "Contact": "104"
        }
    ],
    "Maharashtra": [
        {
            "avatar": "M",
            "state": "Maharashtra",
            "Contact": "020-26127394"
        }
    ],
    "Manipur": [
        {
            "avatar": "M",
            "state": "Manipur",
            "Contact": "3852411668"
        }
    ],
    "Meghalaya": [
        {
            "avatar": "M",
            "state": "Meghalaya",
            "Contact": "108"
        }
    ],
    "Mizoram": [
        {
            "avatar": "M",
            "state": "Mizoram",
            "Contact": "102"
        }
    ],
    "Nagaland": [
        {
            "avatar": "N",
            "state": "Nagaland",
            "Contact": "7005539653"
        }
    ],
    "Odisha": [
        {
            "avatar": "O",
            "state": "Odisha",
            "Contact": "9439994859"
        }
    ],
    "Puducherry": [
        {
            "avatar": "P",
            "state": "Puducherry",
            "Contact": "104"
        }
    ],
    "Punjab": [
        {
            "avatar": "P",
            "state": "Punjab",
            "Contact": "104"
        }
    ],
    "Rajasthan": [
        {
            "avatar": "R",
            "state": "Rajasthan",
            "Contact": "0141-2225624"
        }
    ],
    "Sikkim": [
        {
            "avatar": "S",
            "state": "Sikkim",
            "Contact": "104"
        }
    ],
    "Tamil Nadu": [
        {
            "avatar": "TN",
            "state": "Tamil Nadu",
            "Contact": "044-29510500"
        }
    ],
    "Telangana": [
        {
            "avatar": "T",
            "state": "Telangana",
            "Contact": "104"
        }
    ],
    "Tripura": [
        {
            "avatar": "T",
            "state": "Tripura",
            "Contact": "0381-2315879"
        }
    ],
    "Uttar Pradesh": [
        {
            "avatar": "UP",
            "state": "Uttar Pradesh",
            "Contact": "18001805145"
        }
    ],
    "Uttarakhand": [
        {
            "avatar": "U",
            "state": "Uttarakhand",
            "Contact": "104"
        }
    ],
    "West Bengal": [
        {
            "avatar": "WB",
            "state": "West Bengal",
            "Contact": "1800313444222,\n03323412600,"
        }
    ]
};