export const resourcesData = {
  "Covid Tips for Everyday Situations in India": [
    {
      avatar: "CT",
      Provider: "Covid Tips for Everyday Situations in India",
      Image: "/assets/youtubex80.png",
      Link: "https://www.youtube.com/watch?time_continue=1&v=w1BiXinSANg&feature=emb_logo",
    },
  ],
  "Handling Covid by Dr. Trehan and Dr. Guleria": [
    {
      avatar: "HC",
      Provider: "Handling Covid by Dr. Trehan and Dr. Guleria",
      Image: "/assets/megaphone.png",
      Link: "https://youtu.be/CePBKirF14k",
    },
  ],
  "Cipla Helpline For Medicine": [
    {
      avatar: "CH",
      Provider: "Cipla Helpline For Medicine",
      Image: "/assets/drug.png",
      Link: "https://www.cipla.com/",
    },
  ],
  "Covid National & State Resources": [
    {
      avatar: "CN",
      Provider: "Covid National & State Resources",
      Image: "/assets/flag.png",
      Link: "https://docs.google.com/spreadsheets/d/e/2PACX-1vS-t0R9m4p0qLhi5T753AMkOMQdxbxFlFvzkzxY8v43q2rxDgLTetN8WleWg4G0CQ/pubhtml",
    },
  ],
  "Covid Lucknow": [
    {
      avatar: "CL",
      Provider: "Covid Lucknow",
      Image: "/assets/cities/lucknow.jpg",
      Link: "https://docs.google.com/spreadsheets/d/1roxOi2_Uw4YBzLd5s8vC8cp6lbuM9016tWeWTcx2q5Y/edit#gid=0",
    },
  ],
  "Covid Gujarat": [
    {
      avatar: "CG",
      Provider: "Covid Gujarat",
      Image: "/assets/cities/Vadodra.jpg",
      Link: "https://docs.google.com/spreadsheets/d/1ZyrYsowjk6PdC9N5yKBxMslI7FypoeIqDvlAYrqprL8/edit#gid=0",
    },
  ],
  "Covid Maharashtra": [
    {
      avatar: "CM",
      Provider: "Covid Maharashtra",
      Image: "/assets/cities/mumbai.jpg",
      Link: "http://bit.ly/covidmaha",
    },
  ],
  "Covid Rajasthan": [
    {
      avatar: "CR",
      Provider: "Covid Rajasthan",
      Image: "/assets/cities/Jaipu.jpg",
      Link: "https://covidinfo.rajasthan.gov.in/COVID19HOSPITALBEDSSTATUSSTATE.aspx",
    },
  ],
};
