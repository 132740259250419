/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import capitalize from "lodash/capitalize";
import Skeleton from "react-loading-skeleton";
import Discussion from "../components/Discussion/Discussion";
import LikeButton from "../components/LikeButton";
import ShowNumber from "../components/ShowNumber";
import TopTitleBar from "../components/TopTitleBar";
import { vacantBedData } from "../data/ExtractedData/VacantBeds";
import { getLikeRealtime, getComments } from "../components/Firebase/util";
import "./hospital.css";

import AddCommentButton from "../components/AddCommentButton";

function Hospital({ firebase, match }) {
  if (!match) {
    return null;
  }
  const [likesReceived, setLikesReceived] = useState("");
  const [commentsReceived, setCommentsReceived] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  let ref = null;
  let listener = null;
  let commentRef = null;
  let commentListener = null;
  const data = vacantBedData[match.params.city.toUpperCase()];
  const hospital = data.filter(
    (entry) => entry.hospitalName.replace(/\s/g, "") === match.params.id
  )[0];

  function onMount() {
    [ref, listener] = getLikeRealtime(
      firebase,
      {
        cityName: match.params.city,
        resourceType: "hospitals",
        resourceName: hospital.hospitalName,
      },
      setLikesReceived
    );
    [commentRef, commentListener] = getComments(
      firebase,
      {
        cityName: match.params.city,
        resourceType: "hospitals",
        resourceName: hospital.hospitalName,
      },
      function (comments) {
        setCommentsReceived(comments);
        setLoading(false);
      }
    );
  }

  function onUnmount() {
    if (!ref && !listener) {
    } else if (ref && listener) {
      ref.off(undefined, listener);
    } else {
      console.error(
        "Ref and listener can only be both null or both initialized"
      );
    }
    if (!commentRef && !commentListener) {
    } else if (commentRef && commentListener) {
      commentRef.off(undefined, commentListener);
    } else {
      console.error(
        "Ref and listener can only be both null or both initialized"
      );
    }
  }

  useEffect(() => {
    onMount();
    return onUnmount;
  }, []);

  return (
    <div className="container-fluid">
      <TopTitleBar
        title={hospital.hospitalName}
        backLink={`/home/VacantBeds/${match.params.city}`}
        chosen={() => {}}
      />

      <div
        style={{
          fontSize: "1.3rem",
          fontWeight: "700",
          fontFamily: "sans-serif",
          color: "rgb(80,80,80)",
        }}
        className="align-center"
      >
        {hospital.hospitalName}
      </div>

      <div
        style={{
          fontSize: "1rem",
          fontWeight: "400",
          fontFamily: "sans-serif",
          color: "rgb(80,80,80)",
        }}
        className="align-center"
      >
        {hospital.Contact !== "NA" && hospital.Contact}
      </div>

      <div
        style={{
          fontSize: "1rem",
          fontWeight: "600",
          fontFamily: "sans-serif",
          color: "rgb(80,80,80)",
          padding: "200!important",
        }}
      >
        {Object.keys(hospital.bedsData).map((key) => {
          return (
            <span key={key}>
              {key && key !== "NA" && `${key} : ${hospital.bedsData[key]}  `}
              {key === "NA" && key}
            </span>
          );
        })}
        {/* Normal:{" "}
        {hospital.bedsData["Normal Beds"] !== undefined
          ? hospital.bedsData["Normal Beds"]
          : hospital.bedsData.Normal !== undefined
          ? hospital.bedsData.Normal
          : hospital.bedsData.General !== undefined
          ? hospital.bedsData.General
          : 0}{" "}
        {", "}
        Oxygen:{" "}
        {hospital.bedsData["Oxygen Beds"] !== undefined
          ? hospital.bedsData["Oxygen Beds"]
          : hospital.bedsData.Oxygen !== undefined
          ? hospital.bedsData.Oxygen
          : 0}{" "}
        {", "}
        ICU:{" "}
        {hospital.bedsData["ICU Beds"] !== undefined
          ? hospital.bedsData["ICU Beds"]
          : hospital.bedsData.ICU !== undefined
          ? hospital.bedsData.ICU
          : 0}{" "}
        {", "}
        Ventilator:{" "}
        {hospital.bedsData["Ventilator Beds"] !== undefined
          ? hospital.bedsData["Ventilator Beds"]
          : hospital.bedsData.Ventilator !== undefined
          ? hospital.bedsData.Ventilator
          : 0}{" "}
        {", "} */}
      </div>
      {!iframeLoaded && <Skeleton width="100%" height="250px" />}
      <iframe
        width="100%"
        height="250"
        className="hospital-map-iframe"
        title="Map"
        onLoad={() => setIframeLoaded(true)}
        style={{
          display: iframeLoaded ? "" : "none",
        }}
        src={`https://www.google.com/maps/embed/v1/search?q=${encodeURIComponent(
          `${hospital.hospitalName},${capitalize(match.params.city.toString())}`
        )}&key=${process.env.REACT_APP_MAP_API_KEY}`}
        allow="fullscreen"
      />

      <div className="mt-2 p-0">
        {hospital.Contact !== "" && <ShowNumber Contact={hospital.Contact} />}
      </div>

      <div
        style={{
          fontSize: "1rem",
          fontWeight: "600",
          fontFamily: "sans-serif",
          color: "rgb(120,120,120)",
          marginTop: "10px",
        }}
      >
        Found Useful (In last 1 hour)
      </div>
      <div
        style={{
          fontSize: "1rem",
          fontWeight: "600",
          fontFamily: "sans-serif",
          color: "rgb(120,120,120)",
        }}
      >
        {likesReceived === "" ? <Spin spinning /> : likesReceived}
      </div>
      <LikeButton
        resourceInfo={{
          cityName: match.params.city.toString(),
          resourceType: "hospitals",
          resourceName: hospital.hospitalName,
        }}
      />
      <AddCommentButton
        resourceInfo={{
          cityName: match.params.city,
          resourceType: "hospitals",
          resourceName: hospital.hospitalName,
        }}
        firebase={firebase}
      />
      <br />
      {!isLoading && commentsReceived.length > 0
        ? `${commentsReceived.length} ${
            commentsReceived.length > 1 ? "comments" : "comment"
          }`
        : "No Comments"}
      <Discussion discussion={commentsReceived} isLoading={isLoading} />
    </div>
  );
}

export default Hospital;
