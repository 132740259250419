/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
import { LeftOutlined, SearchOutlined, FormOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React, { useState, useEffect } from "react";
import {
  useHistory,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { headingData } from "../data/VacantBedsData";
import { cityData } from "../data/ExtractedData/CityData";
import SearchBar from "../components/SearchBar";
import Cities from "../components/Cities";

import VacantBedsHospitals from "./VacantBedsHospitals";

function VacantBedsCities(props) {
  const primaryColor = "rgb(33, 150, 243)";
  const [showData, setShowData] = useState(false);
  const [search, setSearch] = useState("");
  const [city, setCity] = useState("");
  const history = useHistory();

  useEffect(() => {
    // scroll to top, on mount
    window.scrollTo(0, 0);
  }, []);

  const [filteredCityData, setFilteredCityData] = useState(cityData);

  function filterCities(citySearched) {
    setFilteredCityData(
      cityData.filter((data) =>
        data.city.toLowerCase().includes(citySearched.toLowerCase())
      )
    );
  }

  const setSearchString = (val) => {
    setSearch(val);
  };

  const chooseCity = (val) => {
    setCity(val);
    history.push(`/home/VacantBeds/${val}`);
  };

  const setBackFunction = (val) => {
    if (city === "") {
      history.replace("/home");
    }
    setCity("");
    setSearchString("");
  };

  const cities = () => {
    return (
      <>
        <div className="mt-1">
          <div style={{ fontSize: "0.9rem", fontWeight: "500" }}>
            Last Updated : {headingData.lastUpdate}
            <br />
            <br />
            {headingData.warning}
          </div>
        </div>

        <div className="mt-3">
          <Cities
            setCity={chooseCity}
            cityData={filteredCityData}
            searchInput={search}
          />
        </div>
      </>
    );
  };

  return (
    <div className="" style={{ fontFamily: "sans-serif" }}>
      <SearchBar
        searchBarData={{
          back: setBackFunction,
          backLink: "/home",
          cityFilter: filterCities,
          searchString: setSearchString,
          title: city === "" ? "Vacant Beds Tracker" : city,
          serach: search,
        }}
      />

      <div>
        <p style={{ fontSize: "1.2rem", fontWeight: "700" }}>
          {city === "" && <> Vacant Beds Tracker </>}
          {city}
        </p>
      </div>

      <div className="mt-1">
        <div style={{ fontSize: "0.9rem", fontWeight: "500" }}>
          Last Updated : {headingData.lastUpdate}
          <br />
          <br />
          {headingData.warning}
        </div>
      </div>

      <div className="mt-3">
        <Cities
          setCity={chooseCity}
          cityData={filteredCityData}
          searchInput={search}
        />
      </div>
    </div>
  );
}

export default VacantBedsCities;
