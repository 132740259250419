export const content = {
  heading: "Live Oxygen Leads",
  warning:
    " Data is taken from Twitter. We are not liable for any inaccuracies. kindly verify the lead at your end before any financial transaction.",
};

export const headingData = {
  lastUpdate: "date time",
  warning:
    "Data is taken from official government sources & Twitter. We are not liable for any inaccuracies. Kindly verify the vacancy by calling the hospital.",
  buttonText: "Ask for Help",
  twitterbuttonText: "Live Twitter Leads",
};

export const oxygenSprinklrLinks = {
  Ahmedabad:
    "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/6?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=6%246_Oxygen%20Supplies",
};
