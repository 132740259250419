import { RightOutlined } from "@ant-design/icons";
import Tiles from "../Tiles/HomePageTiles";
import { cityData } from "../../data/ExtractedData/CityData";

function Cities(props) {
  let data = props.cityData;
  const { searchInput } = props;
  if (!props.cityData) {
    data = cityData;
  }

  return (
    <div className="mb-5" style={{ fontFamily: "sans-serif" }}>
      <>
        {data.length > 0 &&
          data.map((data) => (
            <div onClick={() => props.setCity(data.city)} key={data.city}>
              <Tiles
                text={data.city}
                image={`/assets/cityAssets/${data.city.toUpperCase()}x80.jpg`}
                icon={<RightOutlined />}
              />
            </div>
          ))}
        {data.length === 0 && searchInput && (
          <div>{`No results found for "${searchInput}"`}</div>
        )}
      </>
    </div>
  );
}

export default Cities;
