/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import { RightOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { Avatar } from "antd";
import { useHistory } from "react-router-dom";
import Tiles from "../components/Tiles/HomePageTiles";
import { teamData } from "../data/ExtractedData/TeamData";
import Header from "../components/Header";

function Team() {
  const history = useHistory();
  useEffect(() => {
    // scroll to top, on mount
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header title="Team" />
      <div className="container-fluid">
        <div>
          {teamData.map((data, key) => (
            <div key={key} onClick={() => history.push(`/team/${data.member}`)}>
              <Tiles
                avatar
                avatarIcon={
                  <Avatar
                    size={48}
                    style={{
                      color: "#0066b2",
                      backgroundColor: "#B9D9EB",
                      fontSize: "24px",
                      float: "left",
                    }}
                  >
                    {data.avatarIcon}
                  </Avatar>
                }
                food
                text={data.member}
                subtext={data.title}
                icon={<RightOutlined />}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Team;
