/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { content } from "../data/OxygenData";
import { sprinklrData } from "../data/ExtractedData/OxygenLinks";
import { cityData } from "../data/ExtractedData/CityData";
import SearchBar from "../components/SearchBar";
import Cities from "../components/Cities";

// Converts {city, Image}[] to {city: Image}
const formattedCityData = Object.fromEntries(
  cityData.map((city) => [city.city, city.Image])
);

function OxygenCities(props) {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [city, setCity] = useState("");
  const [filteredCityData, setFilteredCityData] = useState(
    Object.keys(sprinklrData)
  );

  useEffect(() => {
    // scroll to top, on mount
    window.scrollTo(0, 0);
  }, []);

  function filterCities(citySearched) {
    setFilteredCityData(
      Object.keys(sprinklrData).filter((data) =>
        data.toLowerCase().includes(citySearched.toLowerCase())
      )
    );
  }

  function getMetadata(sprinklrCities) {
    return sprinklrCities.map((_city) => ({
      image: formattedCityData[_city.toUpperCase()],
      city: _city,
    }));
  }

  const chooseCity = (val) => {
    setCity(val);
    history.push(`/home/OxygenLeads/${val}`);
  };

  const setBackFunction = () => {
    if (city === "") {
      history.push("/home");
    }
    setCity("");
  };

  return (
    <div className="" style={{ fontFamily: "sans-serif" }}>
      <SearchBar
        searchBarData={{
          backLink: "/home",
          back: setBackFunction,
          cityFilter: filterCities,
          searchString: setSearch,
          title: "Live Oxygen Leads",
          search,
        }}
      />

      <div>
        <p style={{ fontSize: "1.2rem", fontWeight: "700" }}>
          {content.heading}
        </p>
        <div style={{ fontSize: "0.9rem", fontWeight: "500" }}>
          {content.warning}
        </div>
      </div>
      <div className="mt-3">
        <Cities
          setCity={chooseCity}
          cityData={getMetadata(filteredCityData)}
          searchInput={search}
        />
      </div>
    </div>
  );
}

export default OxygenCities;
