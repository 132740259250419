/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Homepage from "./pages/Homepage";
import About from "./pages/About";
import Team from "./pages/Team";
import BottomNavigation from "./components/BottomNavigation";
import pushNotification from "./components/pushNotification";
import "./transitions.css";
import Pages from "./components/Pages";
import HomePages from "./pages/HomesPages";
import ScrollToTop from "./components/ScrollToTop";
import DisclaimerPopUp from "./components/DisclaimerDrawer";

function App({ firebase }) {
  const msg = firebase.messaging;

  useEffect(() => {
    if (msg) {
      msg.onMessage((payload) => {
        pushNotification(payload, "info");
      });
    }
  }, []);

  const routes = [
    { path: ["/home", "/"], name: "Home", Component: Homepage, index: 0 },
    { path: "/about", name: "About", Component: About, index: 2 },
    { path: "/team", name: "Team", Component: Team, index: 1 },
  ];

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route
          exact
          path={[
            "/home/:page",
            "/home/OxygenLeads/:city",
            "/home/VacantBeds/:city",
            "/home/VacantBeds/:city/LiveTwitterLeads",
            "/home/Hospital/:city/:id",
            "/home/Helpline/:state",
            "/home/foodProvider/:city/:provider",
            "/team/:member",
            "/about/:about",
            "/home",
            "/about",
            "/team",
          ]}
        />
        <Redirect to="/home" />
      </Switch>
      <div className="container-main">
        <Pages routes={routes} />
        <HomePages />
        <br />
        <br />
      </div>
      {/* <BottomNavigation tempTab={0} navIconsSize="24px" /> */}
      <BottomNavigation />
      <DisclaimerPopUp />
    </Router>
  );
}

export default App;
