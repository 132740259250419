/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  HomeOutlined,
  InfoCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";

function NavTabUI({ icon, text, path, active}) {
  return (
    <Link to={path} className="col-4 text-center">
      <div
        style={{ color: active ? "rgb(33, 150, 243)" : "rgb(153, 153, 153)" }}
      >
        {icon}
        <div style={{
          fontSize: "10px",
          lineHeight: "18px",
          fontWeight: "600"
        }}>
          {text}
        </div>
      </div>
    </Link>
  );
}

function BottomNavigation() {
  const navIconsSize = "24px";
  const { pathname }= useLocation();

  return (
      <div
        className="fixed-bottom row d-flex p-none pt-1"
        style={{
          borderTop: "1px solid rgb(60,60,60)",
          cursor: "pointer",
          fontSize: "12px",
          backgroundColor: "rgba(255, 255, 255, 0.79)",
          backdropFilter: "blur(10px)",
        }}
      >
        <NavTabUI
          icon={<HomeOutlined style={{ fontSize: navIconsSize }} />}
          text="Karuna"
          path="/home"
          active={pathname === "/home"}
        />
        <NavTabUI
          icon={<TeamOutlined style={{ fontSize: navIconsSize }} />}
          text="Team"
          path="/team"
          active={pathname === "/team"}
        />
        <NavTabUI
          icon={<InfoCircleOutlined style={{ fontSize: navIconsSize }} />}
          text="About"
          path="/about"
          active={pathname === "/about"}
        />
      </div>
  );
}

export default BottomNavigation;
