/* eslint-disable react/jsx-filename-extension */
/* eslint-disable prettier/prettier */
import React from "react";
import { useRouteMatch } from "react-router";
import NeedHelp from "./NeedHelp";
import Food from "./Food";
import Survey from "./Survey";
import ResourceList from "./ResourceList";
import StatewiseCovidHelplines from "./StatewiseCovidHelplines";
import CityStressMeter from "./CityStressMeter";
import Feedback from "./Feedback";
import OxygenCities from "./OxygenCities";
import VacantBedsCities from "./VacantBedsCities";
import VaccineMainRote from "./VaccineMainRoute";
import { FirebaseContext } from "../components/Firebase";
import OneMgHotspots from "./OneMgHotspots";
import PredictiveAnalysis from "./PredictiveAnalysis";

function HomePagesHandler() {
  const match = useRouteMatch();
  function wrapWithFirebaseFeedback() {
    return (
      <FirebaseContext.Consumer>
        {(firebase) => (<Feedback firebase={firebase} />)}
      </FirebaseContext.Consumer>
    )
  }
  function wrapWithFirebaseVaccine() {
    return (
      <FirebaseContext.Consumer>
        {(firebase) => (<VaccineMainRote firebase={firebase} />)}
      </FirebaseContext.Consumer>
    )
  }
  
  const pages = {
    "needhelp": <NeedHelp />,
    "oxygenleads": <OxygenCities />,
    "vaccination": wrapWithFirebaseVaccine(),
    "vacantbeds": <VacantBedsCities />,
    "1mghotspots": <OneMgHotspots />,
    // "Plasma": <Plasma />,
    "survey": <Survey />,
    "food": <Food />,
    "resources": <ResourceList />,
    "covidhelplines": <StatewiseCovidHelplines />,
    "citystressmeter": <CityStressMeter />,
    "feedback": wrapWithFirebaseFeedback(),
    "predictiveanalysis": <PredictiveAnalysis />,
  };

  return (
    <>
      {/* <div className="container-fluid" style={{height: "100%"}}> */}
      <div className="container-fluid" style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
        {match && pages[match.params.page.toString().toLowerCase()]}
      </div>
    </>
  );
}

export default HomePagesHandler;
