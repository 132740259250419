/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from "react";
import { Route, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

function calc(location, prevLocation) {
  if (location.pathname.startsWith("/home")) {
    if (prevLocation) {
      if (!prevLocation.pathname.startsWith("/home")) {
        return "right";
      }
      const prevDepth = prevLocation.pathname.split("/").length;
      const currDepth = location.pathname.split("/").length;
      if (currDepth < prevDepth) {
        return "right";
      }
    }
    return "left";
  }
  if (location.pathname.startsWith("/team")) {
    if (prevLocation) {
      if (prevLocation.pathname.startsWith("/home")) {
        return "left";
      }
      if (prevLocation.pathname.startsWith("/about")) {
        return "right";
      }
      const prevDepth = prevLocation.pathname.split("/").length;
      const currDepth = location.pathname.split("/").length;
      if (currDepth < prevDepth) {
        return "right";
      }
      return "left";
    }
  }
  if (location.pathname.startsWith("/about")) {
    if (prevLocation) {
      if (!prevLocation.pathname.startsWith("/about")) {
        return "left";
      }
      const prevDepth = prevLocation.pathname.split("/").length;
      const currDepth = location.pathname.split("/").length;
      if (currDepth < prevDepth) {
        return "right";
      }
      return "left";
    }
  }
}

function SlidePage({ Component, match, location, prevLocation }) {
  return (
    <CSSTransition
      in={match != null}
      timeout={300}
      classNames="page"
      unmountOnExit
    >
      <div className={`page ${calc(location, prevLocation)}`}>
        <Component />
      </div>
    </CSSTransition>
  );
}

function Pages({ routes }) {
  const location1 = useLocation();
  const prevCountRef = useRef();
  useEffect(() => {
    prevCountRef.current = location1;
  }, [location1]);
  const prevLocation = prevCountRef.current;

  return routes.map(({ path, Component, index, name }) => (
    <Route key={path} exact path={path}>
      {({ match, location }) => (
        <SlidePage
          Component={Component}
          name={name}
          location={location}
          index={index}
          match={match}
          prevLocation={prevLocation}
        />
      )}
    </Route>
  ));
}
export default Pages;
