/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-filename-extension */
import { Button, Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useRef } from "react";
import data from "../../data/DisclaimerData";
import styles from "./style.module.css";

function useOutsideToggle(ref, toggle) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        toggle(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function DisclaimerPopUp() {
  const [visibe, setVisible] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideToggle(wrapperRef, setVisible);

  useEffect(() => {
    console.log(window.sessionStorage.getItem("disclaimer"));
    window.sessionStorage.getItem("disclaimer") !== "accepted" &&
      setVisible(true);
  }, []);

  const onClose = () => {
    setVisible(false);
    window.sessionStorage.setItem("disclaimer", "accepted");
  };

  return (
    <Drawer
     
      bodyStyle={{ marginBottom: "30px",}}
      className={styles.drawer}
      placement="bottom"
      closable={false}
      visible={visibe}
      onClose={onClose}
      height="fit-content"
    >
      {" "}
      <p  ref={wrapperRef}>
        <Button
          onClick={onClose}
          type="link"
          shape="circle"
          style={{ float: "right", marginTop: -15 }}
          icon={<CloseOutlined />}
        />
        {data.disclaimer}
      </p>
    </Drawer>
  );
}

export default DisclaimerPopUp;
