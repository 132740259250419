/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import Iframe from "react-iframe";
import { useHistory } from "react-router-dom";
import useWindowSize from "../useWindowSize";
import { sprinklrData } from "../data/ExtractedData/OxygenLinks";

function VacantBedsTwitter({ match }) {
  const primaryColor = "rgb(33, 150, 243)";
  const history = useHistory();
  const { height } = useWindowSize();

  return (
    <div className="container-fluid">
      <div
        className="pt-2 pb-2 pl-1 mb-2 row sticky-top"
        style={{ backgroundColor: primaryColor }}
      >
        <div
          className="col-2 d-flex mt-1"
          onClick={() => {
            if (!match) {
              // Repeatedly pressing back button
              return;
            }
            history.push(`/home/VacantBeds/${match.params.city.toUpperCase()}`);
          }}
          style={{ cursor: "pointer" }}
        >
          <span
            style={{
              color: "white",
              fontSize: "1rem",
              fontWeight: "500",
              fontFamily: "sans-serif",
            }}
          >
            Done
          </span>
        </div>

        <div
          className="col-10"
          style={{
            color: "white",
            fontSize: "1.2rem",
            fontWeight: "500",
            fontFamily: "sans-serif",
          }}
          className="m-auto"
        >
          Sprinklr
        </div>

        <div className="col-2" />
      </div>
      {match && (
        <Iframe
          className="m-auto"
          url={
            sprinklrData[match.params.city.toUpperCase()]
              ? sprinklrData[match.params.city.toUpperCase()][0]["Link Bed"]
              : ""
          }
          width="100%"
          height={height}
          display="initial"
          position="relative"
        />
      )}
    </div>
  );
}

export default VacantBedsTwitter;
