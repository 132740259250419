/* eslint-disable func-names */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";
import { BiLink } from "react-icons/bi";
import { Button, Avatar, Radio } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { FormOutlined, RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Tiles from "../components/Tiles/HomePageTiles";
import "../css/search.css";
import { headingData } from "../data/FoodData";
import { foodData } from "../data/ExtractedData/FoodData";
import SearchBar from "../components/SearchBar";
import { openInNewTab } from "../JsUtil/OpenInNewTab";

function Food() {
  const primaryColor = "rgb(33, 150, 243)";
  const [search, setSearch] = useState("");
  const citiesList = Object.keys(foodData);
  const [filteredCityData, setFilteredCityData] = useState(citiesList);
  const [searchType, setSearchType] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [cityLoaded, setCityLoaded] = useState(filteredCityData.slice(0, 2));
  const history = useHistory();
  let ref = null;
  let listener = null;
  useEffect(() => {
    filterCities(search);
  }, [searchType]);
  const filterComponent = (
    <Radio.Group
      onChange={(e) => setSearchType(e.target.value)}
      value={searchType}
      optionType="button"
    >
      <Radio.Button value={1}>Search by City</Radio.Button>
      <Radio.Button value={2}>Search by Service</Radio.Button>
    </Radio.Group>
  );

  useEffect(() => {
    // scroll to top, on mount
    window.scrollTo(0, 0);
  }, []);

  const setSearchString = (val) => {
    setSearch(val);
  };

  const handleInfiniteOnLoad = () => {
    setLoading(true);
    if (cityLoaded.length >= filteredCityData.length) {
      setLoading(false);
      return;
    }

    const newData = cityLoaded.concat(
      filteredCityData.slice(cityLoaded.length, cityLoaded.length + 2)
    );
    setCityLoaded(newData);
    setLoading(false);
  };

  function filterCities(citySearched) {
    const searchResults =
      searchType === 1
        ? citiesList.filter((data) =>
            data.toLowerCase().includes(citySearched.toLowerCase())
          )
        : citiesList.filter((data) => {
            for (let i = 0; i < foodData[data].length; i += 1) {
              if (
                foodData[data][i].Provider.toLowerCase().includes(
                  citySearched.toLowerCase()
                )
              ) {
                return true;
              }
            }
            return false;
          });
    setFilteredCityData(searchResults);
    setCityLoaded(searchResults.slice(0, 2));
  }
  const onChangeChosenData = (val, city) => {
    if (!ref && !listener) {
      // Not listening to likes
    } else if (ref && listener) {
      ref.off(listener);
    } else {
      console.error(
        "Ref and listener can only be both null or both initialized"
      );
    }
    ref = null;
    listener = null;
    history.push(`/home/foodProvider/${city}/${val.Provider}`);
  };

  const setBackFunction = () => {
    history.push("/home");
  };

  return (
    <div style={{ fontFamily: "sans-serif" }}>
      <>
        <SearchBar
          searchBarData={{
            back: setBackFunction,
            backLink: "/home",
            cityFilter: filterCities,
            searchString: setSearchString,
            title: "Food",
            search,
            filterComponent,
          }}
        />

        <div>
          <p style={{ fontSize: "1.2rem", fontWeight: "700" }}> Food </p>
        </div>

        <div className="mt-1">
          <Button
            onClick={() => openInNewTab(headingData.sourceLink)}
            size="large"
            className="w-100"
          >
            <p
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                cursor: "pointer",
                color: primaryColor,
                textAlign: "center",
              }}
            >
              <BiLink
                size="20px"
                style={{ margin: "3px", float: "right" }}
                color={primaryColor}
              />
              Data Source
            </p>
          </Button>
          <br />

          <div
            className="mt-1"
            style={{ fontSize: "0.9rem", fontWeight: "500" }}
          >
            Last Updated : {headingData.lastUpdated}
            <br />
            <br />
            {headingData.warning}
          </div>
          <Button
            onClick={() =>
              openInNewTab(
                "https://www.facebook.com/groups/2489879517824917/?ref=share"
              )
            }
            className="w-100 mt-2"
            size="large"
            style={{
              background: primaryColor,
              color: "white",
              fontWeight: "500",
              borderRadius: "10px",
            }}
          >
            <FormOutlined style={{ fontSize: "25px" }} />
            {headingData.buttonText}
          </Button>
        </div>
        <InfiniteScroll
          initialLoad
          pageStart={1}
          loadMore={handleInfiniteOnLoad}
          hasMore={!loading && cityLoaded.length <= filteredCityData.length}
          useWindow
        >
          <div className="mt-5">
            {cityLoaded.map((city) => (
              <React.Fragment key={city}>
                <div className="mt-2">
                  <p style={{ fontSize: "1rem", fontWeight: "700" }}>
                    {" "}
                    {city}{" "}
                  </p>
                </div>
                {foodData[city]
                  .filter((ele, ind) => {
                    const isNotUndefined =
                      ind ===
                      foodData[city].findIndex(
                        (elem) =>
                          elem.Provider === ele.Provider &&
                          elem.Contact === ele.Contact
                      );
                    const isMatchingSearch =
                      ele.Provider.toLowerCase().includes(search.toLowerCase());
                    return (
                      isNotUndefined &&
                      (searchType !== 1 ? isMatchingSearch : true)
                    );
                  })
                  .map((data) => (
                    <div
                      key={`${data.Provider}${data.Contact}`}
                      onClick={() => {
                        onChangeChosenData(data, city);
                      }}
                    >
                      <Tiles
                        avatar
                        avatarIcon={
                          <Avatar
                            size={48}
                            style={{
                              color: "#0066b2",
                              backgroundColor: "#B9D9EB",
                              fontSize: "24px",
                              float: "left",
                            }}
                          >
                            {data.avatar}
                          </Avatar>
                        }
                        food
                        text={data.Provider}
                        subtext={data.Verified}
                        icon={<RightOutlined />}
                      />
                    </div>
                  ))}
              </React.Fragment>
            ))}
          </div>
        </InfiniteScroll>
      </>
    </div>
  );
}

export default Food;
