/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable prettier/prettier */
import { MailFilled, MailOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import Avatar from "antd/lib/avatar/avatar";
import { aboutData } from "../../data/AboutData";
import TopTitleBar from "../TopTitleBar";
import Tiles from '../Tiles/HomePageTiles';
import {openInNewTab} from "../../JsUtil/OpenInNewTab"


function ShowAbout({ match }) {
  const primaryColor = "rgb(33, 150, 243)";
  if (!match) {
    return null;
  }
  const about = aboutData.filter(
    (entry) => (entry.title === match.params.about) || (entry.title === `${match.params.about  }?`)
  )[0];

  const {title, content} = about;

  return (
    <div className="container-fluid">
      <TopTitleBar title={title} chosen={() => {}} backLink="/about" />

      <div className="">
        <div style={{ fontSize: "1.1rem", padding: "10px", whiteSpace: "pre-wrap" }}>
          {content}
        </div>
        {
          about.mails && Object.keys(about.mails).map((name, mailId) => (
            <div
            key={name}
          >
            <Tiles
              avatar
              avatarIcon={
                <Avatar
                  size={48}
                  style={{
                    color: "#0066b2",
                    backgroundColor: "#B9D9EB",
                    fontSize: "24px",
                  }}
                >
                  {name.slice(0,1)}
                </Avatar>
              }
              text={name}
              icon={<MailOutlined onClick={() => {
                openInNewTab(`mailto:${about.mails[name].toString()}`)
               }}
               style={{cursor: "pointer", background: "rgba(33, 150, 243, 0.1)", borderRadius: "50%", color: "rgb(33, 150, 243)",padding: "7px", fontSize: "18px"  }} />}
              food
              subtext={about.mails[name]}
            />
          </div>
        ))
        }
      </div>
    </div>
  );
}

export default ShowAbout;
