export const teamData = [
  {
    avatarIcon: "MR",
    member: "Milan Roy",
    details:
      "Milan is the co-founder of Edvicer and a 2019 graduate of IITD. He aims to democratize education in India.",
    linkedin: "https://www.linkedin.com/in/milan-roy/",
    profile: false,
    title: "Karuna Founder",
  },

  {
    avatarIcon: "SS",
    member: "Swapnil Sharma",
    details:
      "Swapnil is a 2019 dual degree ChemE graduate from IITD. He has 1.5 yrs+ of experience in industry and is interested in creating impact through his work.",
    linkedin: "https://www.linkedin.com/in/swapnil-sharma-71b5a4110/",
    profile: false,
    title: "Karuna Founder",
  },

  {
    avatarIcon: "PG",
    member: "Pranit Ganvir",
    details: "Pranit Ganvir is a 2018 ChemE graduate from IITD. ",
    linkedin: "https://www.linkedin.com/in/pranit-ganvir-37b5a2210/",
    profile: false,
    title: "Karuna Founder",
  },

  {
    avatarIcon: "IC",
    member: "Imane Chafi",
    details: " ",
    linkedin: "https://ca.linkedin.com/in/imane-chafi",
    profile: false,
    title: "Karuna Project Manager",
  },
  {
    avatarIcon: "CG",
    member: "Dr. Christin Glorioso",
    details:
      "Physician and MIT-trained Epidemiologist, Head of Research of the DICE Institute at the PathCheck Foundation, Founder and CEO of ",
    additionalLink: "https://realscience.community/",
    additionalLinkTitle: "Real Science Community",
    linkedin: "https://www.linkedin.com/in/christin-glorioso-md-phd-39627719/",
    profile: false,
    title: "Head of Research at DICE PathCheck",
  },

  {
    avatarIcon: "IG",
    member: "Indraneel Ghosh",
    details: "",
    linkedin: "",
    profile: false,
    title: "Karuna Development Lead",
  },

  {
    avatarIcon: "PD",
    member: "Prashansa Dadu",
    details: "",
    linkedin: "",
    profile: false,
    title: "Karuna Developer",
  },

  {
    avatarIcon: "HA",
    member: "Himanshu Anuragi",
    details: "",
    linkedin: "",
    profile: false,
    title: "Karuna Developer",
  },

  {
    avatarIcon: "JN",
    member: "Jayesh Narwaria",
    details: "",
    linkedin: "",
    profile: false,
    title: "Karuna Developer",
  },

  {
    avatarIcon: "KD",
    member: "Keerthivasan Krishnamurthy",
    details: "",
    linkedin: "",
    profile: false,
    title: "Karuna Developer",
  },

  {
    avatarIcon: "NS",
    member: "Nishit Shetty",
    details: "",
    linkedin: "",
    profile: false,
    title: "Karuna Documentation Lead",
  },

  {
    avatarIcon: "RP",
    member: "Roshni Pattath",
    details: "",
    linkedin: "",
    profile: false,
    title: "UI/UX Lead",
  },

  {
    avatarIcon: "JC",
    member: "Joel Saji Chacko",
    details: "",
    linkedin: "",
    profile: false,
    title: "UI/UX Designer",
  },

  {
    avatarIcon: "UB",
    member: "Ullas R Bhat",
    details: "",
    linkedin: "",
    profile: false,
    title: "UI/UX Designer",
  },

  {
    avatarIcon: "DB",
    member: "Debjit Bhattacharyya",
    details: "",
    linkedin: "",
    profile: false,
    title: "UI/UX Designer",
  },

  {
    avatarIcon: "KD",
    member: "Krishnendu Dasgupta",
    details: "",
    linkedin: "",
    profile: false,
    title: "Research Adviser",
  },

  {
    avatarIcon: "RS",
    member: "Rohan Sukumaran",
    details: "",
    linkedin: "",
    profile: false,
    title: "Research Adviser",
  },

  {
    avatarIcon: "SM",
    member: "Sadguru Manukonda",
    details: "",
    linkedin: "",
    profile: false,
    title: "Tech Adviser",
  },
];
