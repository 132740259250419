/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { FormOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroller";
import Discussion from "../components/Discussion/Discussion";
import TopTitleBar from "../components/TopTitleBar";
import { getComments } from "../components/Firebase/util";
import AddCommentButton from "../components/AddCommentButton";

function Feedback({ firebase, chosen }) {
  const primaryColor = "rgb(33, 150, 243)";
  const [totalLength, setTotalLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [commentsReceived, setCommentsReceived] = useState([]);

  let commentRef = null;
  let commentListener = null;

  function onMount() {
    [commentRef, commentListener] = getComments(
      firebase,
      {
        cityName: "feedback",
        resourceType: "feedback",
        resourceName: "feedback",
      },
      (comments) => {
        setCommentsReceived(comments);
        setTotalLength(comments.length);
        setData(comments.slice(0, 10));
        setLoading(false);
      }
    );
  }

  function onUnmount() {
    if (!commentRef && !commentListener) {
    } else if (commentRef && commentListener) {
      commentRef.off(undefined, commentListener);
    } else {
      console.error(
        "Ref and listener can only be both null or both initialized"
      );
    }
  }

  useEffect(() => {
    onMount();
    return onUnmount;
  }, []);

  const handleInfiniteOnLoad = () => {
    setLoading(true);
    if (data.length >= totalLength) {
      setLoading(false);
      return;
    }

    const newData = data.concat(
      commentsReceived.slice(data.length, data.length + 10)
    );

    setData(newData);
    setLoading(false);
  };
  // open link in new tab
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <TopTitleBar title="Feedback" chosen={chosen} />

      <p style={{ fontSize: "1.2rem", fontWeight: "700" }}> Feedback </p>
      <a
        onClick={() =>
          openInNewTab(
            "https://www.facebook.com/groups/2489879517824917/?ref=share"
          )
        }
      >
        <Button
          className="w-100 mt-2"
          size="large"
          style={{
            background: primaryColor,
            color: "white",
            fontWeight: "500",
            borderRadius: "10px",
          }}
        >
          <FormOutlined style={{ fontSize: "25px" }} />
          Ask for Help
        </Button>
      </a>
      <div
        style={{
          fontSize: "1rem",
          fontWeight: "400",
          fontFamily: "sans-serif",
          color: "rgb(120,120,120)",
          marginTop: "15px",
        }}
      >
        Comments
      </div>
      <AddCommentButton
        resourceInfo={{
          cityName: "feedback",
          resourceType: "feedback",
          resourceName: "feedback",
        }}
        firebase={firebase}
      />
      <br />
      {!loading && commentsReceived.length > 0
        ? `${commentsReceived.length} ${
            commentsReceived.length > 1 ? "comments" : "comment"
          }`
        : "No Comments"}
      <InfiniteScroll
        initialLoad
        pageStart={1}
        loadMore={handleInfiniteOnLoad}
        hasMore={!loading && data.length <= totalLength}
        useWindow
      >
        <Discussion discussion={data} isLoading={loading} />
      </InfiniteScroll>
    </>
  );
}

export default Feedback;
