/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import TopTitleBar from "../components/TopTitleBar";
import Discussion from "../components/Discussion/Discussion";
import ShowNumber from "../components/ShowNumber";
import LikeButton from "../components/LikeButton";
import { getComments, getLikeRealtime } from "../components/Firebase/util";
import AddCommentButton from "../components/AddCommentButton";
import { foodData } from "../data/ExtractedData/FoodData";

function ShowParticularData({ firebase, match }) {
  if (!match) {
    return null;
  }
  const primaryColor = "rgb(33, 150, 243)";
  const [likesReceived, setLikesReceived] = useState("");
  const [commentsReceived, setCommentsReceived] = useState([]);
  const [isLoading, setLoading] = useState(true);
  let ref = null;
  let listener = null;
  let commentRef = null;
  let commentListener = null;

  const data = foodData[match.params.city].filter(
    (entry) => entry.Provider === match.params.provider
  )[0];

  const { City, Notes, Verified, Provider, Contact } = data;

  function onMount() {
    [ref, listener] = getLikeRealtime(
      firebase,
      {
        cityName: match.params.city,
        resourceType: "food",
        resourceName: data.Provider,
      },
      setLikesReceived
    );
    [commentRef, commentListener] = getComments(
      firebase,
      {
        cityName: match.params.city,
        resourceType: "food",
        resourceName: data.Provider,
      },
      function (comments) {
        setCommentsReceived(comments);
        setLoading(false);
      }
    );
  }

  function onUnmount() {
    if (!ref && !listener) {
    } else if (ref && listener) {
      ref.off(undefined, listener);
    } else {
      console.error(
        "Ref and listener can only be both null or both initialized"
      );
    }
    if (!commentRef && !commentListener) {
    } else if (commentRef && commentListener) {
      commentRef.off(undefined, commentListener);
    } else {
      console.error(
        "Ref and listener can only be both null or both initialized"
      );
    }
  }

  useEffect(() => {
    onMount();
    return onUnmount;
  }, []);

  const titleNdetail = (title, detail) => (
    <>
      <div
        style={{
          fontSize: "1rem",
          fontWeight: "400",
          fontFamily: "sans-serif",
          color: "rgb(120,120,120)",
          marginTop: "10px",
        }}
        className="align-center"
      >
        {title}
      </div>
      <div style={{ fontSize: "1rem" }}>{detail}</div>
    </>
  );

  return (
    <div className="container-fluid">
      <TopTitleBar title={Provider} chosen={() => {}} backLink="/home/Food" />

      <div
        style={{
          fontSize: "1.3rem",
          fontWeight: "800",
          fontFamily: "sans-serif",
          color: "rgb(80,80,80)",
          padding: "2px",
        }}
        className="align-center"
      >
        {Provider}
      </div>

      <div
        style={{
          fontSize: "1rem",
          fontWeight: "400",
          fontFamily: "sans-serif",
          color: "rgb(80,80,80)",
        }}
        className="align-center"
      >
        {Contact}
      </div>

      <div className="mt-5 p-0">
        {Contact !== "" && <ShowNumber Contact={Contact} />}
      </div>

      {titleNdetail("Notes", Notes === "" && " NA")}
      {titleNdetail("Verified", Verified)}
      {titleNdetail("Last Verified", data["Last Verified"] === " " && "NA")}
      {titleNdetail("City", City)}
      {titleNdetail(
        "Found Useful",
        likesReceived === "" ? <Spin spinning /> : likesReceived
      )}
      <br />
      <LikeButton
        resourceInfo={{
          cityName: City,
          resourceType: "food",
          resourceName: Provider,
        }}
      />
      <AddCommentButton
        resourceInfo={{
          cityName: match.params.city,
          resourceType: "food",
          resourceName: Provider,
        }}
        firebase={firebase}
      />
      <br />
      {!isLoading && commentsReceived.length > 0
        ? `${commentsReceived.length} ${
            commentsReceived.length > 1 ? "comments" : "comment"
          }`
        : "No Comments"}
      <Discussion discussion={commentsReceived} isLoading={isLoading} />
      <br />
    </div>
  );
}

export default ShowParticularData;
