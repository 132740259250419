/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import { Avatar } from "antd";
import { RightOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Tiles from "../components/Tiles/HomePageTiles";
import "../css/search.css";
import { helplineData } from "../data/ExtractedData/Helpline";
import SearchBar from "../components/SearchBar";

function StatewiseCovidHelplines() {
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState(helplineData);
  const history = useHistory();

  const chooseState = (val) => {
    history.push(`/home/Helpline/${val}`);
  };

  function filterState(searchState) {
    setFilteredData(
      Object.fromEntries(
        Object.keys(helplineData)
          .filter((fullName) =>
            fullName.toLowerCase().includes(searchState.toLowerCase())
          )
          .map((key) => [key, helplineData[key]])
      )
    );
  }

  const setBackFunction = () => {
    history.push("/home");
  };

  return (
    <div style={{ fontFamily: "sans-serif" }}>
      <SearchBar
        searchBarData={{
          backLink: "/home",
          back: setBackFunction,
          cityFilter: filterState,
          searchString: setSearchInput,
          title: "Statewise Covid Helplines",
          search: searchInput,
        }}
      />

      <div>
        <p style={{ fontSize: "1.2rem", fontWeight: "700" }}>
          <> Statewise Covid Helplines </>
        </p>
      </div>
      <>
        <div className="mt-3">
          {Object.keys(filteredData).length > 0 &&
            Object.keys(filteredData).map((data) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <div
                key={data}
                onClick={() => {
                  chooseState(data);
                }}
              >
                <Tiles
                  avatar
                  avatarIcon={
                    <Avatar
                      size={48}
                      style={{
                        color: "#0066b2",
                        backgroundColor: "#B9D9EB",
                        fontSize: "24px",
                      }}
                    >
                      {helplineData[data][0].avatar}
                    </Avatar>
                  }
                  text={helplineData[data][0].state}
                  icon={<RightOutlined />}
                />
              </div>
            ))}
          {Object.keys(filteredData).length === 0 && (
            <div>{`No results found for "${searchInput}"`}</div>
          )}
        </div>
      </>
    </div>
  );
}

export default StatewiseCovidHelplines;
