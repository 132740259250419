import { updateData } from "./ExtractedData/LastUpdateData";

export const headingData = {
  lastUpdate: updateData.bedUpdate,
  warning:
    "Data is taken from official government sources & Twitter. We are not liable for any inaccuracies. Kindly verify the vacancy by calling the hospital.",
  buttonText: "Ask for Help",
  twitterbuttonText: "Live Twitter Leads",
  buttonLink: "https://www.facebook.com/groups/2489879517824917/?ref=share",
  sourceLink: "https://covidamd.com/",
};
