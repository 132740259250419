/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Avatar } from "antd";
import style from "./HomePageTiles.module.css";

function Tiles(props) {
  let textOverflow = "clip";
  textOverflow = props.beds || props.food ? "clip" : "ellipsis";
  let classNames = "";
  if (props.classNames) {
    classNames = props.classNames;
  }
  return (
    <div
      className={`p-2 row ${classNames}`}
      style={{ borderBottom: "1px solid silver " }}
    >
      <div
        className={`col-12 text-truncate ${style.flex}`}
        style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
      >
        {!props.avatar && (
          <Avatar size={50} src={props.image} style={{ flexShrink: 0 }} />
        )}
        {props.avatar && props.avatarIcon}
        {!props.beds && !props.food && !props.oxygen && (
          <span
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              fontFamily: "sans-serif",
              color: "rgb(80,80,80)",
            }}
            className="ml-2 text-truncate"
          >
            {props.text}
          </span>
        )}
        <div className={`text-truncate pl-2 ${style.grow}`}>
          {(props.food || props.beds) && (
            <div style={{ fontSize: "1rem", fontWeight: "600" }}>
              {props.text}
            </div>
          )}
          <div style={{ fontSize: ".75rem", fontWeight: "400" }}>
            {props.food && props.subtext}
            {props.beds && (
              <>
                {Object.keys(props.bedsData).map((key) => {
                  return (
                    <span key={key}>
                      {key &&
                        key !== "NA" &&
                        `${key} : ${props.bedsData[key]}  `}
                      {key === "NA" && key}
                    </span>
                  );
                })}
                {/* Normal:{" "}
                {props.bedsData["Normal Beds"] !== undefined
                  ? props.bedsData["Normal Beds"]
                  : props.bedsData.Normal !== undefined
                  ? props.bedsData.Normal
                  : props.bedsData.General !== undefined
                  ? props.bedsData.General
                  : 0}{" "}
                {", "}
                Oxygen:{" "}
                {props.bedsData["Oxygen Beds"] !== undefined
                  ? props.bedsData["Oxygen Beds"]
                  : props.bedsData.Oxygen !== undefined
                  ? props.bedsData.Oxygen
                  : 0}{" "}
                {", "}
                ICU:{" "}
                {props.bedsData["ICU Beds"] !== undefined
                  ? props.bedsData["ICU Beds"]
                  : props.bedsData.ICU !== undefined
                  ? props.bedsData.ICU
                  : 0}{" "}
                {", "}
                Ventilator:{" "}
                {props.bedsData["Ventilator Beds"] !== undefined
                  ? props.bedsData["Ventilator Beds"]
                  : parseInt(props.bedsData["Ventilator Beds"], 10) >= 0
                  ? props.bedsData.Ventilator !== undefined
                    ? props.bedsData.Ventilator
                    : 0
                  : 0}{" "}
                {", "} */}
              </>
            )}
          </div>
        </div>
        <span>{props.icon}</span>
      </div>
    </div>
  );
}
export default Tiles;
