export const stressData = [
    {
        "avatar": "BA",
        "city": "Bangalore",
        "stress": "3.16"
    },
    {
        "avatar": "CH",
        "city": "Chennai",
        "stress": "2.38"
    },
    {
        "avatar": "JA",
        "city": "Jaipur",
        "stress": "1.97"
    },
    {
        "avatar": "VI",
        "city": "Visakhapatnam",
        "stress": "1.29"
    },
    {
        "avatar": "RA",
        "city": "Ranchi",
        "stress": "0.81"
    },
    {
        "avatar": "NA",
        "city": "Nagpur",
        "stress": "0.63"
    },
    {
        "avatar": "PU",
        "city": "Pune",
        "stress": "0.6"
    },
    {
        "avatar": "GU",
        "city": "Gurugram",
        "stress": "0.53"
    },
    {
        "avatar": "SU",
        "city": "Surat",
        "stress": "0.47"
    },
    {
        "avatar": "AH",
        "city": "Ahmedabad",
        "stress": "0.44"
    },
    {
        "avatar": "BH",
        "city": "Bhopal",
        "stress": "0.37"
    },
    {
        "avatar": "DE",
        "city": "Dehradun",
        "stress": "0.31"
    },
    {
        "avatar": "GW",
        "city": "Gwalior",
        "stress": "0.27"
    },
    {
        "avatar": "LU",
        "city": "Ludhiana",
        "stress": "0.2"
    },
    {
        "avatar": "IN",
        "city": "Indore",
        "stress": "0.19"
    },
    {
        "avatar": "FA",
        "city": "Faridabad",
        "stress": "0.16"
    },
    {
        "avatar": "RA",
        "city": "Raipur",
        "stress": "0.01"
    }
];