/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LeftOutlined, SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import styles from "./style.module.css";

function SearchBar({ searchBarData }) {
  const primaryColor = "rgb(33, 150, 243)";
  const { back, backLink, searchString, cityFilter, title, search, filterComponent } =
    searchBarData;
  const history = useHistory();

  return (
    <div className="row sticky-top">
      <div
        className="text-truncate p-2 text-center col-12"
        style={{
          backgroundColor: primaryColor,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <LeftOutlined
          onClick={() => {
            back();
          }}
          style={{ cursor: "pointer" }}
          style={{
            fontSize: "22px",
            color: "white",
            padding: "4px",
            float: "left",
          }}
        />

        <div
          className="m-auto text-truncate"
          style={{
            color: "white",
            fontSize: "1.2rem",
            fontWeight: "500",
            fontFamily: "sans-serif",
          }}
        >
          {title}
        </div>
      </div>
      <div
        className="col-12 pb-2 m-auto text-center"
        style={{ backgroundColor: primaryColor }}
      >
        <Input
          className={`mt-1 background text-white-imp background ${styles.background}`}
          style={{
            backgroundColor: "#0066b2",
            border: "none",
            width: "95%",
            margin: "0 auto",
            borderRadius: "10px",
            color: "#fff",
          }}
          onChange={(e) => {
            searchString(e.target.value);
            cityFilter(e.target.value);
          }}
          value={search}
          onPressEnter={(e) => cityFilter(e.target.value)}
          size="large"
          color="white"
          placeholder="Search"
          prefix={
            <SearchOutlined style={{ fontSize: "24px", color: "white" }} />
          }
        />
        {filterComponent}
      </div>
    </div>
  );
}

export default SearchBar;
