/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { BiBorderNone } from "react-icons/bi";



function PrimaryButton({ isSecondary, icon, text, onClick, disable }) {
  const borderColor = isSecondary ? "rgb(33, 150, 243)" : "white";
  const useStyles = makeStyles((theme) => ({
    outlined: {
      border: `2px solid ${!isSecondary ? (disable ? "grey" : "rgb(33, 150, 243)") : (disable ? "grey" : "white")}`,
      textTransform: "none",
      fontSize: '15px'
    },
  }));
  const classes = useStyles();

  return (
      <Button
        classes={{ outlined: classes.outlined }}
        variant="outlined"
        style={{
          background: isSecondary ? (disable ? "grey" : "rgb(33, 150, 243)") : "white",
          color: !isSecondary ? (disable ? "grey" : "rgb(33, 150, 243)") : "white",
          alignSelf: "stretch",
        }}
        onClick={onClick}
        startIcon={icon}
        disabled={disable}
        // fullWidth
      >
        {text}
      </Button>
  );
}

export default PrimaryButton;
