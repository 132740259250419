import { stressData } from "./ExtractedData/CityStressData";

export const content = {
  heading: "City Stress Meter",
  bodyStart: "Stress = f(Active Cases in City, Vacant Beds)",
  body: "Higher value on the graph means more stress on remaining hospital infrastructure.",
  bodyEnd:
    "More Stress means less beds to cases ratio. Lesser the stress better the situation of the city is!",
};

function sort(arr, prop) {
  // clone before sorting, to preserve the original array
  const clone = arr;
  clone.sort((a, b) => {
    if (a[prop] < b[prop]) {
      return 1;
    }
    if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  });

  return clone;
}

export const data = sort(stressData, "stress");
data.forEach((cityData) => {
  cityData.stress = cityData.stress < 0 ? 0 : cityData.stress;
});

const numbers = data.map((city) => city.stress);
const cities = data.map((city) => city.city);

export const Chartoptions = {
  chart: {
    height: 150,
    width: "100%",
    type: "bar",
    toolbar: {
      show: false,
    },
  },
  colors: [
    "#008FFB",
    "#00E396",
    "#FEB019",
    "#FF4560",
    "#775DD0",
    "#546E7A",
    "#26a69a",
    "#D10CE8",
  ],
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },

  plotOptions: {
    bar: {
      columnWidth: "90%",
      distributed: true,
    },
  },
  xaxis: {
    categories: cities,
    labels: {
      style: {
        colors: [
          "#008FFB",
          "#00E396",
          "#FEB019",
          "#FF4560",
          "#775DD0",
          "#546E7A",
          "#26a69a",
          "#D10CE8",
        ],
        fontSize: "12px",
      },
    },
  },
};

export const Chartseries = [
  {
    name: "City Stress Meter",
    data: numbers,
  },
];
