/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { InfoCircleFilled } from "@ant-design/icons";
import { BsSquare } from "react-icons/bs";
import colormap from "colormap";
import {
  content,
  Chartoptions,
  Chartseries,
  data,
} from "../data/StressMeterData";
import TopTitleBar from "../components/TopTitleBar";

function CityStressMeter({ chosen }) {
  const getMax = (arr, key) => {
    let max;
    for (let i = 0; i < arr.length; i += 1) {
      if (max == null || parseInt(arr[i][key], 10) > parseInt(max[key], 10)) {
        max = arr[i];
      }
    }
    return parseInt(max[key], 10);
  };

  useEffect(() => {
    // scroll to top, on mount
    window.scrollTo(0, 0);
  }, []);

  const maxStress = getMax(data, "stress");
  const [showData, setShowData] = useState(false);

  const generateColorsFromData = (data) => {
    const allColors = colormap({
      colormap: "RdBu",
      nshades: maxStress * 100,
      format: "hex",
      alpha: 1,
    });
    return data
      .map((data) => data.stress)
      .map((value) => allColors[Math.floor(parseInt(value * 100, 10) / 1.1)]);
  };

  // Update Colors in chartoptions
  const colorPallete = generateColorsFromData(data);
  const updatedChartOptions = { ...Chartoptions };
  updatedChartOptions.colors = colorPallete;
  updatedChartOptions.xaxis.labels.style.colors = colorPallete;
  const updatedData = [...data];
  updatedData.forEach((cityInfo, index) => {
    cityInfo.color = colorPallete[index];
  });

  const dataTile = (dataTiles) => (
    <p className="">
      <span style={{ fontSize: ".8rem", fontWeight: "400" }}>
        {dataTiles.city}
      </span>

      <BsSquare
        style={{
          float: "left",
          margin: "4px",
          marginRight: "10px",
          backgroundColor: dataTiles.color,
          color: dataTiles.color,
        }}
      />
      <span style={{ fontSize: ".8rem", fontWeight: "500", float: "right" }}>
        {dataTiles.stress}
      </span>
    </p>
  );

  return (
    <div className="" style={{ fontFamily: "sans-serif" }}>
      <TopTitleBar title="City Stress Meter" chosen={chosen} />

      <div className="pb-2" style={{}}>
        <p style={{ fontSize: "1.2rem", fontWeight: "700" }}>
          {" "}
          {content.heading}{" "}
        </p>
        <div style={{ fontSize: "0.9rem", fontWeight: "500" }}>
          {content.bodyStart}
          <br />
          {content.body}
          <br />
          {content.bodyEnd}
          <br />
        </div>
      </div>
      <div className="mt-3">
        <div onClick={() => setShowData(!showData)}>
          <InfoCircleFilled style={{ fontSize: "18px" }} />
        </div>

        <Chart
          options={updatedChartOptions}
          series={Chartseries}
          type="bar"
          width="100%"
          height="300"
        />

        {!showData && (
          <>
            {updatedData.map((cityData) => (
              <React.Fragment key={cityData.city}>
                {dataTile(cityData)}
              </React.Fragment>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default CityStressMeter;
