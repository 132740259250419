export const vacantBedData = {
    "DELHI": [
        {
            "hospitalName": "Bhatia Global Hospital, Paschim Vihar",
            "Contact": "01149775977",
            "avatarIcon": "BG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 33"
            }
        },
        {
            "hospitalName": "Irene Hospital, Kalkaji",
            "Contact": "9540948480",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 34"
            }
        },
        {
            "hospitalName": "Dharamvir Solanki Hospital, Rohini",
            "Contact": "9311483147",
            "avatarIcon": "DS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 34"
            }
        },
        {
            "hospitalName": "Universal Centre of Health services, Kalkaji",
            "Contact": "8800800500",
            "avatarIcon": "UC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 35"
            }
        },
        {
            "hospitalName": "Samar Hospital, Dwarka",
            "Contact": "9818096199",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 35"
            }
        },
        {
            "hospitalName": "Pushpanjali Medical Centre, Pushpanjali Enclave, Vikas Marg Ext.",
            "Contact": "9818599728",
            "avatarIcon": "PM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 35"
            }
        },
        {
            "hospitalName": "Maharaja Agarsain Multispecialty Hospital, Narela",
            "Contact": "9891654929",
            "avatarIcon": "MA",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 35"
            }
        },
        {
            "hospitalName": "Divine Multispeciality Hospital and Cancer Centre",
            "Contact": "9999272859",
            "avatarIcon": "DM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 26"
            }
        },
        {
            "hospitalName": "SMS Hospital, Gagan Vihar",
            "Contact": "01122505116",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 36"
            }
        },
        {
            "hospitalName": "Garg Hospital, Karkardooma",
            "Contact": "01143274444",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 36"
            }
        },
        {
            "hospitalName": "Dr. Chaudhary Moral Hospital, Yamuna Vihar",
            "Contact": "9810110566",
            "avatarIcon": "DC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 36"
            }
        },
        {
            "hospitalName": "Jeewan Nursing Home, Pusa Road",
            "Contact": "01142430246",
            "avatarIcon": "JN",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 37"
            }
        },
        {
            "hospitalName": "Sir Ganga Ram Hospital",
            "Contact": "01142253001",
            "avatarIcon": "SG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 34"
            }
        },
        {
            "hospitalName": "Rescue Hospital",
            "Contact": "9711960667",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 36"
            }
        },
        {
            "hospitalName": "IBS Hospital, Lajpat Nagar",
            "Contact": "01143210000",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 39"
            }
        },
        {
            "hospitalName": "Vikas Hospital, Najafgarh",
            "Contact": "8744077882",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 40"
            }
        },
        {
            "hospitalName": "Tulsi Multispeciality Hospital & Critical Care Unit",
            "Contact": "8410445275",
            "avatarIcon": "TM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 40"
            }
        },
        {
            "hospitalName": "SURYA KIRAN HOSPITAL",
            "Contact": "011-25322335",
            "avatarIcon": "SK",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 40"
            }
        },
        {
            "hospitalName": "Surya Hospital, Krishna Nagar",
            "Contact": "9810198845",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 40"
            }
        },
        {
            "hospitalName": "Sanjeevan Medical Res. , Daryaganj",
            "Contact": "9810203358",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 40"
            }
        },
        {
            "hospitalName": "Panchsheel Hospital, Yamuna Vihar",
            "Contact": "9717611842",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 40"
            }
        },
        {
            "hospitalName": "Lalita Hospital, Begumpur",
            "Contact": "8595075850",
            "avatarIcon": "LH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 40"
            }
        },
        {
            "hospitalName": "Kapil Multispecialty Hospital, Nathupura",
            "Contact": "9990781810",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 40"
            }
        },
        {
            "hospitalName": "K K Surgical & Maternity Centre",
            "Contact": "9999577020",
            "avatarIcon": "KK",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 40"
            }
        },
        {
            "hospitalName": "Gupta Multispecialty Hospital, Vivek Vihar",
            "Contact": "01145652410",
            "avatarIcon": "GM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 40"
            }
        },
        {
            "hospitalName": "CD Global Hospital, Najafgarh Road",
            "Contact": "9310171264",
            "avatarIcon": "CG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 39"
            }
        },
        {
            "hospitalName": "Bhagwan Mahavir Hospital, Madhuban Chowk",
            "Contact": "9582585676",
            "avatarIcon": "BM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 40"
            }
        },
        {
            "hospitalName": "BH SALVAS HOSPITAL",
            "Contact": "9990780442",
            "avatarIcon": "BS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 10"
            }
        },
        {
            "hospitalName": "Bansal Hospital & Research Centre, New Friends Colony",
            "Contact": "9650846789",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 40"
            }
        },
        {
            "hospitalName": "Ansari Hospital, Sagarpur",
            "Contact": "8373904800",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 40"
            }
        },
        {
            "hospitalName": "AASTHA HOSPITAL",
            "Contact": "9250913363",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 40"
            }
        },
        {
            "hospitalName": "Satyabhama Hospital, Nangloi",
            "Contact": "9311116607",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 42"
            }
        },
        {
            "hospitalName": "Santom Hospital, Prashant Vihar",
            "Contact": "01127562255",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 41"
            }
        },
        {
            "hospitalName": "Mansaram Hospital, Nangloi",
            "Contact": "8285853737",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 42"
            }
        },
        {
            "hospitalName": "Jain Hospital, Vikas Marg Extn.",
            "Contact": "9015111222",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 42"
            }
        },
        {
            "hospitalName": "Oncoplus Hospital",
            "Contact": "8588909091",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 43"
            }
        },
        {
            "hospitalName": "Konark Hospital, Nangloi",
            "Contact": "9625695449",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 43"
            }
        },
        {
            "hospitalName": "PGH Hospital, Vikas Nagar",
            "Contact": "9599198053",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 44"
            }
        },
        {
            "hospitalName": "World Brain Center Hospital",
            "Contact": "01125391266",
            "avatarIcon": "WB",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 45"
            }
        },
        {
            "hospitalName": "National Heart Institute, East of Kailash",
            "Contact": "01146600700",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 44"
            }
        },
        {
            "hospitalName": "Jeewan Mala Hospital, Rohtak Road",
            "Contact": "01147774151",
            "avatarIcon": "JM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 45"
            }
        },
        {
            "hospitalName": "Malik Radix Hospital",
            "Contact": "01122011192",
            "avatarIcon": "MR",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 47"
            }
        },
        {
            "hospitalName": "Maharaja Agrasen Hospital, Dwarka",
            "Contact": "8826824672",
            "avatarIcon": "MA",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 47"
            }
        },
        {
            "hospitalName": "Alshifa Hospital",
            "Contact": "9599687441",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 26"
            }
        },
        {
            "hospitalName": "Sushila Hospial",
            "Contact": "9999390291",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 50"
            }
        },
        {
            "hospitalName": "Sitaram Bhartia Institute of Science & Res., Qutab Institutional Area",
            "Contact": "01142111111",
            "avatarIcon": "SB",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 50"
            }
        },
        {
            "hospitalName": "Shri Ram Singh Hospital, Krishna Nagar",
            "Contact": "9811100764",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 50"
            }
        },
        {
            "hospitalName": "khanna hospital",
            "Contact": "01125001711",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 50"
            }
        },
        {
            "hospitalName": "Ayushman Hospital, Dwarka",
            "Contact": "01147031100",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 50"
            }
        },
        {
            "hospitalName": "Medeor Hospital, Qutab Instl. Area",
            "Contact": "01141222222",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 55"
            }
        },
        {
            "hospitalName": "MGS Hospital, Punjabi Bagh",
            "Contact": "01145111444",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 52"
            }
        },
        {
            "hospitalName": "Sant Parmanand Hospital, Civil Lines",
            "Contact": "01123994401",
            "avatarIcon": "SP",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 60"
            }
        },
        {
            "hospitalName": "Jeevan Anmol Hospital, Mayur Vihar Phase-I",
            "Contact": "01122795237",
            "avatarIcon": "JA",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 60"
            }
        },
        {
            "hospitalName": "Cygnus MLS, Rama Vihar",
            "Contact": "9999655155",
            "avatarIcon": "CM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 60"
            }
        },
        {
            "hospitalName": "Bansal Global Hospital, Jahangirpuri",
            "Contact": "9811062832",
            "avatarIcon": "BG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 60"
            }
        },
        {
            "hospitalName": "Venkateshwar Hospital, Dwarka",
            "Contact": "7290072997",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 64"
            }
        },
        {
            "hospitalName": "Cygnus Sonia Hospital, Nangloi",
            "Contact": "8750060177",
            "avatarIcon": "CS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 68"
            }
        },
        {
            "hospitalName": "RLKC Metro Hospital, Naraina",
            "Contact": "01161316666",
            "avatarIcon": "RM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 70"
            }
        },
        {
            "hospitalName": "Goyal Hospital & Urology Centre, Krishna Nagar",
            "Contact": "9015142888",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 70"
            }
        },
        {
            "hospitalName": "B.M. Gupta Hospital, Uttam Nagar",
            "Contact": "8287229094",
            "avatarIcon": "BG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 70"
            }
        },
        {
            "hospitalName": "Madhukar Rainbow Children Hosp., Malviya Nagar",
            "Contact": "9100065913",
            "avatarIcon": "MR",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 71"
            }
        },
        {
            "hospitalName": "Delhi Heart & Lung Institute, Pachkuian Road",
            "Contact": "01142999999",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 73"
            }
        },
        {
            "hospitalName": "Sehgal Neo Hospital, Paschim Vihar",
            "Contact": "8527597171",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 80"
            }
        },
        {
            "hospitalName": "Navjeevan Hospital, Pitampura",
            "Contact": "8802693809",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 80"
            }
        },
        {
            "hospitalName": "Mata Roop Rani Maggo Hospital, Uttam Nagar",
            "Contact": "01161290000",
            "avatarIcon": "MR",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 80"
            }
        },
        {
            "hospitalName": "Brahm Shakti Hospital, Budh Vihar",
            "Contact": "7840089703",
            "avatarIcon": "BS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 80"
            }
        },
        {
            "hospitalName": "NKS Hospital, Gulabi Bagh",
            "Contact": "8130894500",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 81"
            }
        },
        {
            "hospitalName": "Kalra Hospital, Kirti Nagar",
            "Contact": "01125100000",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 93"
            }
        },
        {
            "hospitalName": "Sir Ganga Ram City Hospital",
            "Contact": "01142255555",
            "avatarIcon": "SG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 96"
            }
        },
        {
            "hospitalName": "Maha Durga Charitable Trust Hospital",
            "Contact": "9811755635",
            "avatarIcon": "MD",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 96"
            }
        },
        {
            "hospitalName": "Kukreja Hospital and Heart Centre, Rajouri Garden",
            "Contact": "7982046610",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 96"
            }
        },
        {
            "hospitalName": "Fortis SS Hospital, Vasant Kunj",
            "Contact": "01142776222",
            "avatarIcon": "FS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 103"
            }
        },
        {
            "hospitalName": "Shanti Mukand Hospital, Vikas Marg Extn.",
            "Contact": "01147276600",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 110"
            }
        },
        {
            "hospitalName": "Park Hospital, Meera Bagh",
            "Contact": "01145323232",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 110"
            }
        },
        {
            "hospitalName": "Metro Hospital, Preet Vihar",
            "Contact": "01149286666",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 102"
            }
        },
        {
            "hospitalName": "Primus Super Specialty Hospital, Chanakyapuri",
            "Contact": "9910071222",
            "avatarIcon": "PS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 105"
            }
        },
        {
            "hospitalName": "Mool Chand Khairati Ram Hospital, Lajpat Nagar",
            "Contact": "9818813891",
            "avatarIcon": "MC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 112"
            }
        },
        {
            "hospitalName": "Fortis Escorts Heart Institute, Okhla",
            "Contact": "01147135000",
            "avatarIcon": "FE",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 112"
            }
        },
        {
            "hospitalName": "Bhagwati Hospital, Rohini",
            "Contact": "01143126000",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 130"
            }
        },
        {
            "hospitalName": "Akash Healthcare, Dwarka",
            "Contact": "8800015991",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 132"
            }
        },
        {
            "hospitalName": "Tirath Ram Shah Charitable Hospital, Civil Lines",
            "Contact": "8375975629",
            "avatarIcon": "TR",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 135"
            }
        },
        {
            "hospitalName": "BLK Hospital, Pusa Road",
            "Contact": "8448386700",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 136"
            }
        },
        {
            "hospitalName": "Saroj Super Speciality Hospital",
            "Contact": "01127903333",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 150"
            }
        },
        {
            "hospitalName": "Indian spinal injury centre,Vasant Kunj",
            "Contact": "01142255384",
            "avatarIcon": "IS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 157"
            }
        },
        {
            "hospitalName": "Dharamshila Narayana Hosp., Vasundhra Enclave",
            "Contact": "8067506880",
            "avatarIcon": "DN",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 160"
            }
        },
        {
            "hospitalName": "Pushpawati Singhania Hospital, Saket",
            "Contact": "9717159780",
            "avatarIcon": "PS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 167"
            }
        },
        {
            "hospitalName": "Shree Agarsain International Hospital, Rohini- 22",
            "Contact": "9625941440",
            "avatarIcon": "SA",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 169"
            }
        },
        {
            "hospitalName": "VIMHANS",
            "Contact": "01166176617",
            "avatarIcon": "V",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 180"
            }
        },
        {
            "hospitalName": "Mata Chanan Devi Hospital, Janakpuri",
            "Contact": "8920601050",
            "avatarIcon": "MC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 196"
            }
        },
        {
            "hospitalName": "MANIPAL HOSPITAL, DWARKA",
            "Contact": "7978031920",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 196"
            }
        },
        {
            "hospitalName": "Jaipur Golden Hospital, Rohini",
            "Contact": "8929730572",
            "avatarIcon": "JG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 188"
            }
        },
        {
            "hospitalName": "Fortis Hospital, Shalimar Bagh",
            "Contact": "9821051424",
            "avatarIcon": "FH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 200"
            }
        },
        {
            "hospitalName": "Sri Balaji Action Medical Inst., Paschim Vihar",
            "Contact": "9999174129",
            "avatarIcon": "SB",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 217"
            }
        },
        {
            "hospitalName": "Max SS Hospital, Shalimar Bagh",
            "Contact": "7042500536",
            "avatarIcon": "MS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 214"
            }
        },
        {
            "hospitalName": "Rajiv Gandhi Cancer Institute & RC, Rohini*",
            "Contact": "9717888473",
            "avatarIcon": "RG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 230"
            }
        },
        {
            "hospitalName": "Max Smart Gujarmal Modi hospital ,Saket",
            "Contact": "01171212121",
            "avatarIcon": "MS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 250"
            }
        },
        {
            "hospitalName": "Maharaja Agrasen Hospital, Punjabi Bagh",
            "Contact": "9910489495",
            "avatarIcon": "MA",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 250"
            }
        },
        {
            "hospitalName": "Max East/West Block",
            "Contact": "1126515050",
            "avatarIcon": "ME",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 255"
            }
        },
        {
            "hospitalName": "Holy Family Hospital, Okhla",
            "Contact": "9716832400",
            "avatarIcon": "HF",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 269"
            }
        },
        {
            "hospitalName": "Batra Hospital",
            "Contact": "01129958747",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 299"
            }
        },
        {
            "hospitalName": "St. Stephens Hospital, Tis Hazari",
            "Contact": "01123966021",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 324"
            }
        },
        {
            "hospitalName": "MAX SS HOSPITAL, PATPARGANJ",
            "Contact": "8800333777",
            "avatarIcon": "MS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 346"
            }
        },
        {
            "hospitalName": "HAHC Hospital, Hamdard Nagar",
            "Contact": "8588890999",
            "avatarIcon": "HH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 375"
            }
        },
        {
            "hospitalName": "INDRAPRASTHA APOLLO HOSPITAL, SARITA VIHAR",
            "Contact": "01126925801",
            "avatarIcon": "IA",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 468"
            }
        },
        {
            "hospitalName": "Covid Health Centre Tilak Nagar CHC",
            "Contact": "8448977018",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 64"
            }
        },
        {
            "hospitalName": "Covid Care Centre Balak Ram Hospital",
            "Contact": "8800394525",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 25"
            }
        },
        {
            "hospitalName": "Covid Care Centre Cantonment General Hospital",
            "Contact": "9999999999",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 26"
            }
        },
        {
            "hospitalName": "Covid Care Centre RADHA SOAMI SATSANG BEAS , COVID CARE CENTRE, ASHOK NAGAR",
            "Contact": "9718990195",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 100"
            }
        },
        {
            "hospitalName": "Covid Care Centre Covid care and treatment centre Gurdwara Rakab ganj",
            "Contact": "9311061107",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 394"
            }
        },
        {
            "hospitalName": "Covid Care Centre Covid Care Center -CWG AKSHARDHAM",
            "Contact": "9310080537",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 455"
            }
        },
        {
            "hospitalName": "Covid Care Centre Covid Care Center- Sardar Patel, Chattrpur",
            "Contact": "9927088888",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 593"
            }
        },
        {
            "hospitalName": "Covid Care Centre Covid Care and Treatment centre Yamuna sports complex",
            "Contact": "01122141103",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 800"
            }
        },
        {
            "hospitalName": "Covid Care Centre Sant Nirankari Covid Care and Treatment centre burari",
            "Contact": "6398377083",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 996"
            }
        },
        {
            "hospitalName": "AIIMS Main Delhi",
            "Contact": "01126588500",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 13"
            }
        },
        {
            "hospitalName": "National Institute of TB and Respiratory Diseases (LRS TB)",
            "Contact": "01126517829",
            "avatarIcon": "NI",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 15"
            }
        },
        {
            "hospitalName": "ESIC hospital Okhla",
            "Contact": "01126814161",
            "avatarIcon": "EH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 19"
            }
        },
        {
            "hospitalName": "Northern Railway",
            "Contact": "011-23341970",
            "avatarIcon": "NR",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 51"
            }
        },
        {
            "hospitalName": "Kalawati Saran Children's Hospital (LHMC)",
            "Contact": "01123344160",
            "avatarIcon": "KS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 29"
            }
        },
        {
            "hospitalName": "Swami Dayanand",
            "Contact": "011-22110065",
            "avatarIcon": "SD",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 69"
            }
        },
        {
            "hospitalName": "ESIC Hospital Jhilmil",
            "Contact": "011-22152197",
            "avatarIcon": "EH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 76"
            }
        },
        {
            "hospitalName": "SHEHNAI LNH EXTENSION",
            "Contact": "22222323",
            "avatarIcon": "SL",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 100"
            }
        },
        {
            "hospitalName": "Janakpuri Super Speciality Hospital Society",
            "Contact": "01128504206",
            "avatarIcon": "JS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 100"
            }
        },
        {
            "hospitalName": "LHMC",
            "Contact": "01123498269",
            "avatarIcon": "L",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 21"
            }
        },
        {
            "hospitalName": "Sanjay Gandhi Memorial Hospital",
            "Contact": "01127913220",
            "avatarIcon": "SG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 128"
            }
        },
        {
            "hospitalName": "Acharyashree Bhikshu Govt. Hospital",
            "Contact": "01125423514",
            "avatarIcon": "AB",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 150"
            }
        },
        {
            "hospitalName": "GURU NANAK EYE CENTRE ( ANNEXED TO LOK NAYAK HOSPITAL)",
            "Contact": "01123234622",
            "avatarIcon": "GN",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 198"
            }
        },
        {
            "hospitalName": "Satyawati Harishchand Hsopital",
            "Contact": "01127787008",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 220"
            }
        },
        {
            "hospitalName": "Deep Chand Bandhu",
            "Contact": "01127305952",
            "avatarIcon": "DC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 224"
            }
        },
        {
            "hospitalName": "Bara Hindurao Hospital",
            "Contact": "01123905839",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 246"
            }
        },
        {
            "hospitalName": "AIIMS, Trauma Delhi",
            "Contact": "01126731109",
            "avatarIcon": "AT",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 196"
            }
        },
        {
            "hospitalName": "Ram Manohar Lohia Hospital",
            "Contact": "01123365525",
            "avatarIcon": "RM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 313"
            }
        },
        {
            "hospitalName": "Dr Baba Saheb Ambedkar Hospital",
            "Contact": "01127049963",
            "avatarIcon": "DB",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 365"
            }
        },
        {
            "hospitalName": "AIIMS Jhajjar",
            "Contact": "8929900156",
            "avatarIcon": "AJ",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 351"
            }
        },
        {
            "hospitalName": "Safdarjung Hospital",
            "Contact": "01126730000",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 406"
            }
        },
        {
            "hospitalName": "Sardar Vallabhbhai Patel Covid hospital (DRDO)",
            "Contact": "7303885606",
            "avatarIcon": "SV",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 500"
            }
        },
        {
            "hospitalName": "Deen Dayal Upadhyay hospital",
            "Contact": "01125401075",
            "avatarIcon": "DD",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 489"
            }
        },
        {
            "hospitalName": "Base Hospital Delhi Cantt",
            "Contact": "01125683581",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 481"
            }
        },
        {
            "hospitalName": "Ambedkar Nagar hospital",
            "Contact": "01126052016",
            "avatarIcon": "AN",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 599"
            }
        },
        {
            "hospitalName": "Indira Gandhi Hospital, Dwarka",
            "Contact": "01120895989",
            "avatarIcon": "IG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 617"
            }
        },
        {
            "hospitalName": "Rajeev Gandhi Super Speciality Hospital",
            "Contact": "01122890773",
            "avatarIcon": "RG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 641"
            }
        },
        {
            "hospitalName": "Burari Hospital",
            "Contact": "01127611215",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 697"
            }
        },
        {
            "hospitalName": "GTB Hospital",
            "Contact": "8595948014",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 1621"
            }
        },
        {
            "hospitalName": "Lok Nayak Hospital",
            "Contact": "8929834864",
            "avatarIcon": "LN",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 1840"
            }
        }
    ],
    "COIMBATORE": [
        {
            "hospitalName": "V.G Hospital,Thudiyalur",
            "Contact": "9894288514",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Fathima Hospital,Podanur",
            "Contact": "8098011072",
            "avatarIcon": "FH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Coimbatore Child Trust Hospital,Singanallur",
            "Contact": "7708682666",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Manu Hospital",
            "Contact": "9789324445",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "N.M. Hospital, Annur",
            "Contact": "9944376111",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Gem Hospital and Research centre",
            "Contact": "9003932323",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Sathya Medical Centre and Hospital, Sivanandha Colony",
            "Contact": "9994499585",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "NG Hospital Pvt Ltd & Research Centre, Singanallur",
            "Contact": "9865755568",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "1",
                "Oxygen": "0",
                "Non Oxygen": "1",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Kumaran Medical Centre, Kurumbapalayam",
            "Contact": "9344022222",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Kalpana Medical Centre Pvt Ltd, Kavundampalayam",
            "Contact": "8508655534",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "K.Govindhasamy Memorial Annex Hospital, Saravanampatti",
            "Contact": "7094441551",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "FIMS Hospital, Sundarapuram",
            "Contact": "9600240521",
            "avatarIcon": "FH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "6",
                "Oxygen": "0",
                "Non Oxygen": "6",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Dr.Muthus Hospital, Saravanampatti",
            "Contact": "8012312000",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "11",
                "Oxygen": "0",
                "Non Oxygen": "11",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CSR Nursing Home, Gandhipuram",
            "Contact": "8300128777",
            "avatarIcon": "CN",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Chennai Hospitals Pvt Ltd, Gandhipuram",
            "Contact": "NULL",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "37",
                "Oxygen": "37",
                "Non Oxygen": "0",
                "ICU": "7",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Coimbatore Diabetic Foundation,maruthamalai road",
            "Contact": "9362622723",
            "avatarIcon": "CD",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "10",
                "Oxygen": "2",
                "Non Oxygen": "8",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Sree Abirami Hospital, Coimbatore TN.",
            "Contact": "9952200057",
            "avatarIcon": "SA",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Abinand\u00a0 Hospital",
            "Contact": "8838689898",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "13",
                "Oxygen": "0",
                "Non Oxygen": "13",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Atlas Pain Care Sowripalayam",
            "Contact": "9865509999",
            "avatarIcon": "AP",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "9",
                "Oxygen": "0",
                "Non Oxygen": "9",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "KMCH Kovilampalayam Hospital, Sathy Road",
            "Contact": "8754187551",
            "avatarIcon": "KK",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Sri Ramakrishna Hospital",
            "Contact": "9384084606",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "10",
                "Oxygen": "0",
                "Non Oxygen": "10",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Royal Care Super Speciality Hospital Gandhipuram",
            "Contact": "7397769343",
            "avatarIcon": "RC",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Royal Care Super Speciality Hospital",
            "Contact": "7397769343",
            "avatarIcon": "RC",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "PSG Institute Of Medical Sciences and Research, Coimbatore",
            "Contact": "9894798896",
            "avatarIcon": "PI",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "20",
                "Oxygen": "0",
                "Non Oxygen": "20",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "One Care Medical Centre",
            "Contact": "9894955990",
            "avatarIcon": "OC",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Kovai Medical College Hospital",
            "Contact": "9600400451",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Kongunad Hospital",
            "Contact": "7094316000",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "KG Hospital",
            "Contact": "4222212121",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Karpagam Faculty Medical College And Hospital",
            "Contact": "9566760490",
            "avatarIcon": "KF",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "53",
                "Oxygen": "2",
                "Non Oxygen": "51",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Hindusthan Hospital, Udaiyampalayam",
            "Contact": "9361694437",
            "avatarIcon": "HH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "15",
                "Oxygen": "0",
                "Non Oxygen": "15",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "G. Kuppusamy Naidu Hospital",
            "Contact": "8220037466",
            "avatarIcon": "GK",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "108",
                "Oxygen": "0",
                "Non Oxygen": "108",
                "ICU": "0",
                "Ventilator": "0"
            }
        }
    ],
    "THIRUCHIRAPPALLI": [
        {
            "hospitalName": "Sugam Hospital, Manapparai",
            "Contact": "9965015543",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "",
                "Oxygen": "",
                "Non Oxygen": "",
                "ICU": "",
                "Ventilator": ""
            }
        },
        {
            "hospitalName": "Kathir Hospital, Woraiyur",
            "Contact": "9865355503",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Aiyshwariya Hospital, Woraiyur",
            "Contact": "9791498865",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Sri Kumaran Hospital Manapparai",
            "Contact": "9003772250",
            "avatarIcon": "SK",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "SMS Hospital,Salai Road",
            "Contact": "9750958305",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "5",
                "Oxygen": "0",
                "Non Oxygen": "5",
                "ICU": "0",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Silverline Speciality Hospital, Thillai Nagar",
            "Contact": "9384875957",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "6",
                "Oxygen": "2",
                "Non Oxygen": "2",
                "ICU": "2",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Max Care Hospital, Annamalai Nagar",
            "Contact": "9600073580",
            "avatarIcon": "MC",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Kanapathy Memorial Hospital, Musiri",
            "Contact": "9566813205",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "1",
                "Oxygen": "0",
                "Non Oxygen": "1",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Jagadha Hospital,Thillai Nagar",
            "Contact": "9488333620",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "14",
                "Oxygen": "10",
                "Non Oxygen": "4",
                "ICU": "0",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Decca Hospital, Thillai Nagar",
            "Contact": "9842410780",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "4",
                "Oxygen": "0",
                "Non Oxygen": "4",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "City Medical Center, Thennur",
            "Contact": "7397271129",
            "avatarIcon": "CM",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "2",
                "Oxygen": "0",
                "Non Oxygen": "2",
                "ICU": "0",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Assured Best Care Hospital(P) Ltd (ABC Hospital) Annamalai Nagar",
            "Contact": "9655240724",
            "avatarIcon": "AB",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "14",
                "Oxygen": "6",
                "Non Oxygen": "7",
                "ICU": "1",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Sri AuroMa Hospital,Trichy",
            "Contact": "9894226648",
            "avatarIcon": "SA",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Prabhu Diabetes Specialty Centre,Trichy",
            "Contact": "9787955955",
            "avatarIcon": "PD",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "3",
                "Oxygen": "0",
                "Non Oxygen": "3",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Akshaya Hospital,Thottiyam",
            "Contact": "9566683380",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Atlas Hospital",
            "Contact": "7092980125",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "8",
                "Oxygen": "5",
                "Non Oxygen": "0",
                "ICU": "3",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Royal Pearl Hospital",
            "Contact": "8300244928",
            "avatarIcon": "RP",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Trichy Medical Centre and Hospital, Thillai Nagar",
            "Contact": "9842011199",
            "avatarIcon": "TM",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "3",
                "Oxygen": "3",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Star KIMS Hospital, Thillai Nagar",
            "Contact": "9789488894",
            "avatarIcon": "SK",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Retna Global Hospital, Tennur",
            "Contact": "9245148941",
            "avatarIcon": "RG",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "MMM Multi Speciality Hospital",
            "Contact": "9600340430",
            "avatarIcon": "MM",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "1",
                "Oxygen": "1",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Mangalam Hospital",
            "Contact": "9092829992",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "20",
                "Oxygen": "10",
                "Non Oxygen": "5",
                "ICU": "5",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Kavi Hospital & Neuro Foundation",
            "Contact": "9843005288",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Athma Hospitals, Thillai Nagar",
            "Contact": "9865779707",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Velan Speciality Hospital, Highways Colony",
            "Contact": "7339565444",
            "avatarIcon": "VS",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Sri Venkateswara Hospital T.V Koil",
            "Contact": "7604919191",
            "avatarIcon": "SV",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Sundaram Hospital, Pudhur",
            "Contact": "7397789749",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "4",
                "Oxygen": "4",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Pankajam Sitaram Nursing Home (A Unit Of GVN Hospital)",
            "Contact": "9095505550",
            "avatarIcon": "PS",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "1",
                "Oxygen": "0",
                "Non Oxygen": "1",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Dr.G.Viswanathan Hospital Mambalasalai",
            "Contact": "9344270185",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "SRM Medical College Hospital And Research Centre, Trichy",
            "Contact": "8939999269",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Sinduja Hospital",
            "Contact": "9442009956",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "36",
                "Oxygen": "2",
                "Non Oxygen": "31",
                "ICU": "3",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Maruti Hospital",
            "Contact": "9944920163",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Kavery Medical Centre And Hospital, Trichy",
            "Contact": "8870515222",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "5",
                "Oxygen": "0",
                "Non Oxygen": "5",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "G V N Hospital Pvt Ltd",
            "Contact": "9095505550",
            "avatarIcon": "GV",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "13",
                "Oxygen": "3",
                "Non Oxygen": "10",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Apollo Speciality Hospital",
            "Contact": "9842164211",
            "avatarIcon": "AS",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Neuro One Hospital, Karur Bye Pass Road",
            "Contact": "7397771405",
            "avatarIcon": "NO",
            "foundUseful": "0",
            "bedsData": {
                "Covid": "0",
                "Oxygen": "0",
                "Non Oxygen": "0",
                "ICU": "0",
                "Ventilator": "0"
            }
        }
    ],
    "AJMER": [
        {
            "hospitalName": "St. Francis Hospital, Ajmer",
            "Contact": "9828125153",
            "avatarIcon": "SF",
            "foundUseful": "",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "ICU without Ventilator": "8",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "R S  HOSPITAL",
            "Contact": "8852852152",
            "avatarIcon": "RS",
            "foundUseful": "",
            "bedsData": {
                "General": "5",
                "Oxygen": "15",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Nasirabad",
            "Contact": "1491221053",
            "avatarIcon": "N",
            "foundUseful": "",
            "bedsData": {
                "General": "75",
                "Oxygen": "57",
                "ICU without Ventilator": "0",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "Militry Hospital ,Nasirabad",
            "Contact": "7838560640",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "General": "75",
                "Oxygen": "16",
                "ICU without Ventilator": "0",
                "Ventilator": "17"
            }
        },
        {
            "hospitalName": "MITTAL HOSPITAL and RESEARCH CENTRE, AJMER",
            "Contact": "9001096223",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "General": "18",
                "Oxygen": "45",
                "ICU without Ventilator": "8",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "MARBLE HOSPITAL KISHANGARH",
            "Contact": "8696178696",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "General": "11",
                "Oxygen": "23",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Kshetrapal Multispeciality Hospital",
            "Contact": "9116002893",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "35",
                "ICU without Ventilator": "9",
                "Ventilator": "6"
            }
        },
        {
            "hospitalName": "JLN medical college  Ajmer",
            "Contact": "0145-2625509",
            "avatarIcon": "JM",
            "foundUseful": "0",
            "bedsData": {
                "General": "35",
                "Oxygen": "395",
                "ICU without Ventilator": "90",
                "Ventilator": "80"
            }
        },
        {
            "hospitalName": "JAI CLINIC NURSING",
            "Contact": "8107530407",
            "avatarIcon": "JC",
            "foundUseful": "0",
            "bedsData": {
                "General": "11",
                "Oxygen": "10",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Govt. Satellite Hospital Ajmer",
            "Contact": "0145-2680241",
            "avatarIcon": "GS",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "50",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Govt Y N Hospital Kishangarh",
            "Contact": "7014616109",
            "avatarIcon": "GY",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "40",
                "ICU without Ventilator": "0",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "Govt District Hospital Kekri",
            "Contact": "01467-220005",
            "avatarIcon": "GD",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "61",
                "ICU without Ventilator": "0",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "DR VIJAY ENT HOSPITAL AJMER",
            "Contact": "9982537977",
            "avatarIcon": "DV",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "DIVISIONAL RAILWAY",
            "Contact": "9001196555",
            "avatarIcon": "DR",
            "foundUseful": "0",
            "bedsData": {
                "General": "23",
                "Oxygen": "50",
                "ICU without Ventilator": "1",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "COMPOSIT HOSP.",
            "Contact": "1452671791",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "General": "45",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC sawar block kekri",
            "Contact": "NA",
            "avatarIcon": "CS",
            "foundUseful": "0",
            "bedsData": {
                "General": "4",
                "Oxygen": "6",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC kadera Block kekri",
            "Contact": "8306800438",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "General": "2",
                "Oxygen": "0",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC TODGARH BLOCK JAWAJA",
            "Contact": "NA",
            "avatarIcon": "CT",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC TANTOTI Block BHINAI",
            "Contact": "8980008113",
            "avatarIcon": "CT",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC Shrinagar Block Nasirabad",
            "Contact": "94360355403",
            "avatarIcon": "CS",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC SARWAR BLOCK ARAIN",
            "Contact": "8094361159",
            "avatarIcon": "CS",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "10",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC ROOPANGARH BLOCK KISHANGARH",
            "Contact": "9413204173",
            "avatarIcon": "CR",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC Pushkar block pisangan",
            "Contact": "9414213880",
            "avatarIcon": "CP",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "10",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC Panchsheel Ajmer",
            "Contact": "8130830260",
            "avatarIcon": "CP",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "50",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC PISANGAN BLOCK PISANGAN",
            "Contact": "7742363054",
            "avatarIcon": "CP",
            "foundUseful": "0",
            "bedsData": {
                "General": "9",
                "Oxygen": "10",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC MASUDA BLOCK MASOODA",
            "Contact": "9414644980",
            "avatarIcon": "CM",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC JAWAJA BLOCK JAWAJA",
            "Contact": "NA",
            "avatarIcon": "CJ",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC DEOGAVN",
            "Contact": "9643340061",
            "avatarIcon": "CD",
            "foundUseful": "0",
            "bedsData": {
                "General": "3",
                "Oxygen": "2",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC BIJAINAGAR Block Masuda",
            "Contact": "01462-230028,231101",
            "avatarIcon": "CB",
            "foundUseful": "0",
            "bedsData": {
                "General": "7",
                "Oxygen": "8",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC BHINAI BLOCK BHINAI",
            "Contact": "14,662,734,349,460,600,000",
            "avatarIcon": "CB",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC BANDANWARA",
            "Contact": "9,928,853,075",
            "avatarIcon": "CB",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC ARAIN BLOCK ARAIN",
            "Contact": "1463281127",
            "avatarIcon": "CA",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Anand Multispeciality Hospital and Research Center",
            "Contact": "9269625000",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "General": "6",
                "Oxygen": "14",
                "ICU without Ventilator": "4",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "AKH BEAWAR  ( DH)",
            "Contact": "8306007302",
            "avatarIcon": "AB",
            "foundUseful": "0",
            "bedsData": {
                "General": "11",
                "Oxygen": "40",
                "ICU without Ventilator": "0",
                "Ventilator": "4"
            }
        }
    ],
    "KRISHNA": [
        {
            "hospitalName": "CHC Guduru",
            "Contact": "7207029649",
            "avatarIcon": "CG",
            "foundUseful": "",
            "bedsData": {
                "9494382958": "0"
            }
        },
        {
            "hospitalName": "CHC Kaikaluru",
            "Contact": "8677223723",
            "avatarIcon": "CK",
            "foundUseful": "",
            "bedsData": {
                "9441748063": "0"
            }
        },
        {
            "hospitalName": "COMMUNITY HEALTH CENTER-NANDIGMA",
            "Contact": "8678275288",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "8074774060": "0"
            }
        },
        {
            "hospitalName": "ASHOKA HOSPITAL  TIRUVURU",
            "Contact": "8673253333",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9640157803": "0"
            }
        },
        {
            "hospitalName": "Sri Gayatri Superspeciality Hospital",
            "Contact": "8662434799",
            "avatarIcon": "SG",
            "foundUseful": "",
            "bedsData": {
                "7569772489": "0"
            }
        },
        {
            "hospitalName": "Grace Hospital, Vuyyuru",
            "Contact": "8676233666",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "8317587822": "0"
            }
        },
        {
            "hospitalName": "Area Hospital,Nuzvid",
            "Contact": "8656236132",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "8919238437": "0"
            }
        },
        {
            "hospitalName": "CHC Vuyyuru",
            "Contact": "7207565980",
            "avatarIcon": "CV",
            "foundUseful": "",
            "bedsData": {
                "9951197546": "0"
            }
        },
        {
            "hospitalName": "CHC, Thiruvuru",
            "Contact": "9381952660",
            "avatarIcon": "CT",
            "foundUseful": "",
            "bedsData": {
                "9701229914": "0"
            }
        },
        {
            "hospitalName": "District Hospital,  Machilipatnam",
            "Contact": "08672-355091\n",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "8008705709": "0"
            }
        },
        {
            "hospitalName": "Latha Super speciality hospitals",
            "Contact": "8297255515",
            "avatarIcon": "LS",
            "foundUseful": "",
            "bedsData": {
                "9642923770": "0"
            }
        },
        {
            "hospitalName": "S.V.D Multispeciality Hospitals",
            "Contact": "0866-2474445\n",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "91 91826 37549": "0",
                "": "0"
            }
        },
        {
            "hospitalName": "Annapurna Multi Speciality Hospital, Gudivada",
            "Contact": "7207210425",
            "avatarIcon": "AM",
            "foundUseful": "",
            "bedsData": {
                "8074842259": "0"
            }
        },
        {
            "hospitalName": "EVR Hospital, Gudivada",
            "Contact": "7207161879",
            "avatarIcon": "EH",
            "foundUseful": "",
            "bedsData": {
                "7287080513": "0"
            }
        },
        {
            "hospitalName": "KMR Universal Hospital",
            "Contact": "9014727757",
            "avatarIcon": "KU",
            "foundUseful": "",
            "bedsData": {
                "7095768389": "0"
            }
        },
        {
            "hospitalName": "Keerthi Hospital, Challapalli",
            "Contact": "7207069288",
            "avatarIcon": "KH",
            "foundUseful": "",
            "bedsData": {
                "9441494570": "0"
            }
        },
        {
            "hospitalName": "Siddharth Multi Speciality Dental Hospital",
            "Contact": "8662432290",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "7013625115": "0"
            }
        },
        {
            "hospitalName": "MAHESH HOSPITAL",
            "Contact": "7207503443",
            "avatarIcon": "MH",
            "foundUseful": "",
            "bedsData": {
                "9963737954": "0"
            }
        },
        {
            "hospitalName": "GIFFERD HOSPITAL",
            "Contact": "7207423467",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "8977133912": "0"
            }
        },
        {
            "hospitalName": "CHC Avanigadda",
            "Contact": "08671-272242\n",
            "avatarIcon": "CA",
            "foundUseful": "",
            "bedsData": {
                "8639645269": "0"
            }
        },
        {
            "hospitalName": "UNION HOSPITAL",
            "Contact": "0866-2435355\n",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "8341215222": "0"
            }
        },
        {
            "hospitalName": "Sai Madhavi Hospital, Vijayawada",
            "Contact": "0866-2845788\n",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "8985817383": "0"
            }
        },
        {
            "hospitalName": "Tamma Vinod Reddy Sruthi Hospital, Pamarru",
            "Contact": "08674-255155\n",
            "avatarIcon": "TV",
            "foundUseful": "",
            "bedsData": {
                "8501000141": "0"
            }
        },
        {
            "hospitalName": "ASSURE HOSPITAL",
            "Contact": "8663510054",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7989048911": "0"
            }
        },
        {
            "hospitalName": "NIMRA Hospital",
            "Contact": "8662972222",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "7893453111": "0"
            }
        },
        {
            "hospitalName": "Vignesh Superspeciality Hospital",
            "Contact": "0866 6632345\n",
            "avatarIcon": "VS",
            "foundUseful": "",
            "bedsData": {
                "9154696588": "0"
            }
        },
        {
            "hospitalName": "LIBERTY HOSPITALS ,VIJAYAWADA",
            "Contact": "9154954232",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9966573883": "0"
            }
        },
        {
            "hospitalName": "AIMS for CHILD HEALTH",
            "Contact": "0866 2437969\n",
            "avatarIcon": "AF",
            "foundUseful": "",
            "bedsData": {
                "9030753736": "0"
            }
        },
        {
            "hospitalName": "Sri Santhi Hospitals,Vijayawada",
            "Contact": "0866-2438585\n",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9182757141": "0"
            }
        },
        {
            "hospitalName": "Dr. kiran reddys joint care",
            "Contact": "9505722345",
            "avatarIcon": "DK",
            "foundUseful": "",
            "bedsData": {
                "9652166884": "0"
            }
        },
        {
            "hospitalName": "Govt. General Hospital (GGH), Vijayawada,KRISHNA",
            "Contact": "8662457789",
            "avatarIcon": "GG",
            "foundUseful": "",
            "bedsData": {
                "9985095379": "0"
            }
        },
        {
            "hospitalName": "K V SHINE HOSPITAL",
            "Contact": "8662433325",
            "avatarIcon": "KV",
            "foundUseful": "",
            "bedsData": {
                "9704552752": "0"
            }
        },
        {
            "hospitalName": "CHIKITSAA MULTISPECIALITY HOSPITAL",
            "Contact": "0866 2402911\n",
            "avatarIcon": "CM",
            "foundUseful": "",
            "bedsData": {
                "9666821431": "0"
            }
        },
        {
            "hospitalName": "Health Wise Hospital",
            "Contact": "8662546203",
            "avatarIcon": "HW",
            "foundUseful": "",
            "bedsData": {
                "9533272303": "0"
            }
        },
        {
            "hospitalName": "SURESH SUPER SPL",
            "Contact": "9246787115",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281565841": "0"
            }
        },
        {
            "hospitalName": "SAIDEEPU HOSPITAL",
            "Contact": "7675928752",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951662747": "0"
            }
        },
        {
            "hospitalName": "Indo-British Hospital, Vijayawada",
            "Contact": "8662958884",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "8464940911": "0"
            }
        },
        {
            "hospitalName": "ANIL NEURO AND TRAUMA CENTRE",
            "Contact": "8662435777",
            "avatarIcon": "AN",
            "foundUseful": "",
            "bedsData": {
                "9393932797": "0"
            }
        },
        {
            "hospitalName": "Vijayawada Nursing Home Polyclinic Pvt Ltd",
            "Contact": "9985371888",
            "avatarIcon": "VN",
            "foundUseful": "",
            "bedsData": {
                "8125603363": "0"
            }
        },
        {
            "hospitalName": "Railway Hospital Vijayawada",
            "Contact": "0866-2767716\n",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "8886789714": "0"
            }
        },
        {
            "hospitalName": "SUNRISE HOSPITAL",
            "Contact": "9494537915",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9494537915": "0"
            }
        },
        {
            "hospitalName": "Pinnamaneni Hospital, Gannavaram",
            "Contact": "7386399429",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "7093293273": "0"
            }
        },
        {
            "hospitalName": "chaitanya life care hospital",
            "Contact": "9381072559",
            "avatarIcon": "CL",
            "foundUseful": "",
            "bedsData": {
                "9985566639": "0"
            }
        },
        {
            "hospitalName": "VENKATESWARA HOSPITAL, NUZVID",
            "Contact": "7989070175",
            "avatarIcon": "VH",
            "foundUseful": "",
            "bedsData": {
                "9492127722": "0"
            }
        },
        {
            "hospitalName": "SRIKARAHOSPITAL",
            "Contact": "8666812345",
            "avatarIcon": "S",
            "foundUseful": "",
            "bedsData": {
                "8885137343": "0"
            }
        },
        {
            "hospitalName": "AREA HOSPITAL, GUDIVADA",
            "Contact": "8674245040",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9030823353": "0"
            }
        },
        {
            "hospitalName": "LIFE LINE TRIMURTY HOSPITAL",
            "Contact": "6304597973",
            "avatarIcon": "LL",
            "foundUseful": "",
            "bedsData": {
                "9581883302": "0"
            }
        },
        {
            "hospitalName": "Sri Anuhospitals Pvt Ltd",
            "Contact": "9440020659",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9700198144": "0"
            }
        },
        {
            "hospitalName": "Aayush Nri Lepl Healthcare Pvt Ltd",
            "Contact": "8662541414",
            "avatarIcon": "AN",
            "foundUseful": "",
            "bedsData": {
                "9490461137": "0"
            }
        },
        {
            "hospitalName": "Help Hospital",
            "Contact": "9246871777",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "6301576757": "0"
            }
        },
        {
            "hospitalName": "Nagarjuna Hospital",
            "Contact": "8662463596",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "8106472891": "0"
            }
        },
        {
            "hospitalName": "SAI BHASKAR HOSPITALS (A UNIT OF BMR HOSPITALS)",
            "Contact": "8662433766",
            "avatarIcon": "SB",
            "foundUseful": "",
            "bedsData": {
                "8019697606": "0"
            }
        },
        {
            "hospitalName": "VIJAYA SUPER SPL HOSPITAL",
            "Contact": "7207536616",
            "avatarIcon": "VS",
            "foundUseful": "",
            "bedsData": {
                "8686779912": "0"
            }
        },
        {
            "hospitalName": "TIME HOSPITALS PVT LTD",
            "Contact": "9394247666",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9666698554": "0"
            }
        },
        {
            "hospitalName": "Andhra Hospitals Machilipatnam A Unit Of Viswadeep Medical Services Pvt Ltd",
            "Contact": "8672227277",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9000324923": "0"
            }
        },
        {
            "hospitalName": "Svr Neuro Hospital",
            "Contact": "7386278323",
            "avatarIcon": "SN",
            "foundUseful": "",
            "bedsData": {
                "8008913866": "0"
            }
        },
        {
            "hospitalName": "LALITHA LIFE CARE SOLUTIONS",
            "Contact": "9642860555",
            "avatarIcon": "LL",
            "foundUseful": "",
            "bedsData": {
                "9848248879": "0"
            }
        },
        {
            "hospitalName": "Heart Care Centre",
            "Contact": "0866-2438588\n",
            "avatarIcon": "HC",
            "foundUseful": "",
            "bedsData": {
                "8886516719": "0"
            }
        },
        {
            "hospitalName": "ANDHRA HOSPITALS (VIJAYAWADA) PVT LTD",
            "Contact": "8328018574",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9492466889": "0"
            }
        },
        {
            "hospitalName": "Capital Hospital",
            "Contact": "7095386488",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "8019439271": "0"
            }
        },
        {
            "hospitalName": "CITI ORTHOPAEDIC CENTRE,VJA",
            "Contact": "0866 2495456\n",
            "avatarIcon": "CO",
            "foundUseful": "",
            "bedsData": {
                "7207669241": "0"
            }
        },
        {
            "hospitalName": "Harini Hospital",
            "Contact": "9440853344",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9000130071": "0"
            }
        },
        {
            "hospitalName": "SENTINI HOSPITALS PRIVATE LIMITED",
            "Contact": "8666677869",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8977778801": "0"
            }
        },
        {
            "hospitalName": "GLOBAL MULTI SPECIALITY HOSPITAL",
            "Contact": "0866-2571613\n",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "7013129343": "0"
            }
        },
        {
            "hospitalName": "Hanshu ortho and Ent Hospital, Thiruvuru",
            "Contact": "088044 99799\n",
            "avatarIcon": "HO",
            "foundUseful": "",
            "bedsData": {
                "6309078891": "0"
            }
        },
        {
            "hospitalName": "Andhra Hospitals Bhavanipuram Pvt Ltd",
            "Contact": "0866-2415757\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9700563711": "0"
            }
        },
        {
            "hospitalName": "LAHARI HOSPITAL",
            "Contact": "9550098847",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "7095053386": "0"
            }
        },
        {
            "hospitalName": "Charitha Sri Hospital",
            "Contact": "9246490913",
            "avatarIcon": "CS",
            "foundUseful": "",
            "bedsData": {
                "9700561651": "0"
            }
        },
        {
            "hospitalName": "M/S SOWJANYA HOSPITAL",
            "Contact": "0866-6652111\n",
            "avatarIcon": "MS",
            "foundUseful": "",
            "bedsData": {
                "8074751994": "0"
            }
        },
        {
            "hospitalName": "Martha Health Care Services  Pvt Ltd",
            "Contact": "08673-251115\n",
            "avatarIcon": "MH",
            "foundUseful": "",
            "bedsData": {
                "9014674360": "0"
            }
        },
        {
            "hospitalName": "VIJAY ORTHO AND ACCIDENTAL CARE",
            "Contact": "8666633108",
            "avatarIcon": "VO",
            "foundUseful": "",
            "bedsData": {
                "7989300789": "0"
            }
        },
        {
            "hospitalName": "Kamineni Hospital, Vijayawada,KRISHNA",
            "Contact": "9494228316",
            "avatarIcon": "KH",
            "foundUseful": "",
            "bedsData": {
                "8328374367": "0"
            }
        },
        {
            "hospitalName": "Krishna Gastro Hospital",
            "Contact": "8662439999",
            "avatarIcon": "KG",
            "foundUseful": "",
            "bedsData": {
                "9010469575": "0"
            }
        },
        {
            "hospitalName": "M V S Accident Hospital",
            "Contact": "7993453460",
            "avatarIcon": "MV",
            "foundUseful": "",
            "bedsData": {
                "8977711193": "0"
            }
        },
        {
            "hospitalName": "Gayatri Hospitals",
            "Contact": "9246432328",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "8317669577": "0"
            }
        },
        {
            "hospitalName": "Ravi Hospital",
            "Contact": "7075785878",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9963534510": "0"
            }
        },
        {
            "hospitalName": "SMILE HOSPITAL, VIJAYAWADA",
            "Contact": "6301597779",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9502560326": "0"
            }
        },
        {
            "hospitalName": "Best Hospital",
            "Contact": "8367722722",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9492555092": "0"
            }
        },
        {
            "hospitalName": "ASHOKA HOSPITAL  TIRUVURU",
            "Contact": "8673253333",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:5\nGeneral:20\nVentilator:0",
            "bedsData": {
                "9640157803": "0"
            }
        },
        {
            "hospitalName": "Sri Gayatri Superspeciality Hospital",
            "Contact": "8662434799",
            "avatarIcon": "SG",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8712348758": "0"
            }
        },
        {
            "hospitalName": "Grace Hospital, Vuyyuru",
            "Contact": "8676233666",
            "avatarIcon": "GH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:6\nVentilator:0",
            "bedsData": {
                "7673941132": "0"
            }
        },
        {
            "hospitalName": "Area Hospital,Nuzvid",
            "Contact": "8656236132",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:3\nVentilator:0",
            "bedsData": {
                "8499839962": "0"
            }
        },
        {
            "hospitalName": "CHC Vuyyuru",
            "Contact": "7207565980",
            "avatarIcon": "CV",
            "foundUseful": "ICU:3\nOxygen:0\nGeneral:8\nVentilator:0",
            "bedsData": {
                "9951197546": "0"
            }
        },
        {
            "hospitalName": "CHC, Thiruvuru",
            "Contact": "9381952660",
            "avatarIcon": "CT",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9701229914": "0"
            }
        },
        {
            "hospitalName": "District Hospital,  Machilipatnam",
            "Contact": "08672-355091\n",
            "avatarIcon": "DH",
            "foundUseful": "ICU:0\nOxygen:30\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8008705709": "0"
            }
        },
        {
            "hospitalName": "Latha Super speciality hospitals",
            "Contact": "8297255515",
            "avatarIcon": "LS",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9642923770": "0"
            }
        },
        {
            "hospitalName": "S.V.D Multispeciality Hospitals",
            "Contact": "0866-2474445\n",
            "avatarIcon": "SM",
            "foundUseful": "ICU:2\nOxygen:6\nGeneral:0\nVentilator:0",
            "bedsData": {
                "91 91826 37549": "0",
                "": "0"
            }
        },
        {
            "hospitalName": "Annapurna Multi Speciality Hospital, Gudivada",
            "Contact": "7207210425",
            "avatarIcon": "AM",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:2\nVentilator:0",
            "bedsData": {
                "8074842259": "0"
            }
        },
        {
            "hospitalName": "EVR Hospital, Gudivada",
            "Contact": "7207161879",
            "avatarIcon": "EH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "7287080513": "0"
            }
        },
        {
            "hospitalName": "KMR Universal Hospital",
            "Contact": "9014727757",
            "avatarIcon": "KU",
            "foundUseful": "ICU:8\nOxygen:25\nGeneral:55\nVentilator:0",
            "bedsData": {
                "9248355335": "0"
            }
        },
        {
            "hospitalName": "Keerthi Hospital, Challapalli",
            "Contact": "7207069288",
            "avatarIcon": "KH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9441494570": "0"
            }
        },
        {
            "hospitalName": "Siddharth Multi Speciality Dental Hospital",
            "Contact": "8662432290",
            "avatarIcon": "SM",
            "foundUseful": "ICU:1\nOxygen:3\nGeneral:3\nVentilator:0",
            "bedsData": {
                "7013625115": "0"
            }
        },
        {
            "hospitalName": "MAHESH HOSPITAL",
            "Contact": "7207503443",
            "avatarIcon": "MH",
            "foundUseful": "ICU:3\nOxygen:14\nGeneral:4\nVentilator:0",
            "bedsData": {
                "8333022209": "0"
            }
        },
        {
            "hospitalName": "GIFFERD HOSPITAL",
            "Contact": "7207423467",
            "avatarIcon": "GH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:14\nVentilator:0",
            "bedsData": {
                "6301585969": "0"
            }
        },
        {
            "hospitalName": "CHC Avanigadda",
            "Contact": "08679-272242\n",
            "avatarIcon": "CA",
            "foundUseful": "ICU:0\nOxygen:3\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8500730590": "0"
            }
        },
        {
            "hospitalName": "UNION HOSPITAL",
            "Contact": "0866-2435355\n",
            "avatarIcon": "UH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8341215222": "0"
            }
        },
        {
            "hospitalName": "Sai Madhavi Hospital, Vijayawada",
            "Contact": "0866-2845788\n",
            "avatarIcon": "SM",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8985817383": "0"
            }
        },
        {
            "hospitalName": "Tamma Vinod Reddy Sruthi Hospital, Pamarru",
            "Contact": "08674-255155\n",
            "avatarIcon": "TV",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8919227819": "0"
            }
        },
        {
            "hospitalName": "M/S ASSURE HOSPITAL",
            "Contact": "8663510054",
            "avatarIcon": "MA",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "7989048911": "0"
            }
        },
        {
            "hospitalName": "NIMRA Hospital",
            "Contact": "9133575143",
            "avatarIcon": "NH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:373\nVentilator:0",
            "bedsData": {
                "9133575143": "0"
            }
        },
        {
            "hospitalName": "Vignesh Superspeciality Hospital",
            "Contact": "0866 6632345\n",
            "avatarIcon": "VS",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:2\nVentilator:0",
            "bedsData": {
                "9154696588": "0"
            }
        },
        {
            "hospitalName": "LIBERTY HOSPITALS ,VIJAYAWADA",
            "Contact": "9492001173",
            "avatarIcon": "LH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:22\nVentilator:0",
            "bedsData": {
                "9966573883": "0"
            }
        },
        {
            "hospitalName": "AIMS for CHILD HEALTH",
            "Contact": "0866 2437969\n",
            "avatarIcon": "AF",
            "foundUseful": "ICU:6\nOxygen:3\nGeneral:0\nVentilator:1",
            "bedsData": {
                "9441869026": "0"
            }
        },
        {
            "hospitalName": "Sri Santhi Hospitals,Vijayawada",
            "Contact": "0866-2438585\n",
            "avatarIcon": "SS",
            "foundUseful": "ICU:7\nOxygen:8\nGeneral:6\nVentilator:0",
            "bedsData": {
                "9182757141": "0"
            }
        },
        {
            "hospitalName": "Dr. kiran reddys joint care",
            "Contact": "9505223450",
            "avatarIcon": "DK",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:5\nVentilator:0",
            "bedsData": {
                "7036100725": "0"
            }
        },
        {
            "hospitalName": "Govt. General Hospital (GGH), Vijayawada",
            "Contact": "8662457789",
            "avatarIcon": "GG",
            "foundUseful": "ICU:0\nOxygen:7\nGeneral:31\nVentilator:0",
            "bedsData": {
                "9985095379": "0"
            }
        },
        {
            "hospitalName": "K V SHINE HOSPITAL",
            "Contact": "8662433325",
            "avatarIcon": "KV",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8466098792": "0"
            }
        },
        {
            "hospitalName": "CHIKITSAA MULTISPECIALITY HOSPITAL",
            "Contact": "0866 2402911\n",
            "avatarIcon": "CM",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:7\nVentilator:0",
            "bedsData": {
                "9666821431": "0"
            }
        },
        {
            "hospitalName": "Health Wise Hospital",
            "Contact": "8662546203",
            "avatarIcon": "HW",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:2\nVentilator:0",
            "bedsData": {
                "9533272303": "0"
            }
        },
        {
            "hospitalName": "SURESH SUPER SPL",
            "Contact": "8662444556",
            "avatarIcon": "SS",
            "foundUseful": "ICU:2\nOxygen:1\nGeneral:3\nVentilator:0",
            "bedsData": {
                "8317580116": "0"
            }
        },
        {
            "hospitalName": "SAIDEEPU HOSPITAL",
            "Contact": "7675928752",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:10\nVentilator:0",
            "bedsData": {
                "9951662747": "0"
            }
        },
        {
            "hospitalName": "Indo-British Hospital, Vijayawada",
            "Contact": "8662958884",
            "avatarIcon": "IH",
            "foundUseful": "ICU:0\nOxygen:6\nGeneral:5\nVentilator:0",
            "bedsData": {
                "7736539843": "0"
            }
        },
        {
            "hospitalName": "ANIL NEURO AND TRAUMA CENTRE",
            "Contact": "8662435777",
            "avatarIcon": "AN",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:3\nVentilator:0",
            "bedsData": {
                "9393932797": "0"
            }
        },
        {
            "hospitalName": "Vijayawada Nursing Home Polyclinic Pvt Ltd",
            "Contact": "9985371888",
            "avatarIcon": "VN",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:2\nVentilator:0",
            "bedsData": {
                "8125603363": "0"
            }
        },
        {
            "hospitalName": "Railway Hospital Vijayawada",
            "Contact": "0866-2767716\n",
            "avatarIcon": "RH",
            "foundUseful": "ICU:0\nOxygen:28\nGeneral:44\nVentilator:0",
            "bedsData": {
                "8886789714": "0"
            }
        },
        {
            "hospitalName": "SUNRISE HOSPITAL",
            "Contact": "9494537915",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9494537915": "0"
            }
        },
        {
            "hospitalName": "Pinnamaneni Hospital, Gannavaram",
            "Contact": "8676257223",
            "avatarIcon": "PH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:10\nVentilator:0",
            "bedsData": {
                "9440814731": "0"
            }
        },
        {
            "hospitalName": "VENKATESWARA HOSPITAL, NUZVID",
            "Contact": "7989070175",
            "avatarIcon": "VH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:2\nVentilator:0",
            "bedsData": {
                "9493909190": "0"
            }
        },
        {
            "hospitalName": "SRIKARAHOSPITAL",
            "Contact": "8666812345",
            "avatarIcon": "S",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8885137343": "0"
            }
        },
        {
            "hospitalName": "AREA HOSPITAL, GUDIVADA",
            "Contact": "8674245040",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9133950116": "0"
            }
        },
        {
            "hospitalName": "LIFE LINE TRIMURTY HOSPITAL",
            "Contact": "6304597973",
            "avatarIcon": "LL",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "7013215054": "0"
            }
        },
        {
            "hospitalName": "Sri Anuhospitals Pvt Ltd",
            "Contact": "0866-2438881\n",
            "avatarIcon": "SA",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9700198144": "0"
            }
        },
        {
            "hospitalName": "Aayush Nri Lepl Healthcare Pvt Ltd",
            "Contact": "8662541414",
            "avatarIcon": "AN",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:8\nVentilator:0",
            "bedsData": {
                "9490461137": "0"
            }
        },
        {
            "hospitalName": "Help Hospital",
            "Contact": "9246871777",
            "avatarIcon": "HH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:3\nVentilator:0",
            "bedsData": {
                "6301576757": "0"
            }
        },
        {
            "hospitalName": "Nagarjuna Hospital",
            "Contact": "8662463596",
            "avatarIcon": "NH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:5\nVentilator:0",
            "bedsData": {
                "8106472891": "0"
            }
        },
        {
            "hospitalName": "SAI BHASKAR HOSPITALS (A UNIT OF BMR HOSPITALS)",
            "Contact": "8662433766",
            "avatarIcon": "SB",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8019697606": "0"
            }
        },
        {
            "hospitalName": "VIJAYA SUPER SPL HOSPITAL",
            "Contact": "7207536616",
            "avatarIcon": "VS",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8686779912": "0"
            }
        },
        {
            "hospitalName": "TIME HOSPITALS PVT LTD",
            "Contact": "9394247666",
            "avatarIcon": "TH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9666698554": "0"
            }
        },
        {
            "hospitalName": "CRANE, Vijayawada",
            "Contact": "6304684602",
            "avatarIcon": "CV",
            "foundUseful": "ICU:6\nOxygen:4\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8686571400": "0"
            }
        },
        {
            "hospitalName": "Andhra Hospitals Machilipatnam A Unit Of Viswadeep Medical Services Pvt Ltd",
            "Contact": "8672227277",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:12\nGeneral:2\nVentilator:0",
            "bedsData": {
                "9000324923": "0"
            }
        },
        {
            "hospitalName": "Svr Neuro Hospital",
            "Contact": "7207424405",
            "avatarIcon": "SN",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8919103350": "0"
            }
        },
        {
            "hospitalName": "LALITHA LIFE CARE SOLUTIONS",
            "Contact": "9642860555",
            "avatarIcon": "LL",
            "foundUseful": "ICU:2\nOxygen:2\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9848248879": "0"
            }
        },
        {
            "hospitalName": "Heart Care Centre",
            "Contact": "0866-2438588\n",
            "avatarIcon": "HC",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8074280800": "0"
            }
        },
        {
            "hospitalName": "ANDHRA HOSPITALS (VIJAYAWADA) PVT LTD",
            "Contact": "8328018574",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:3\nVentilator:0",
            "bedsData": {
                "9492466889": "0"
            }
        },
        {
            "hospitalName": "Capital Hospital",
            "Contact": "7095386488",
            "avatarIcon": "CH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8019439271": "0"
            }
        },
        {
            "hospitalName": "CITI ORTHOPAEDIC CENTRE,VJA",
            "Contact": "9381983898",
            "avatarIcon": "CO",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "7207669241": "0"
            }
        },
        {
            "hospitalName": "Harini Hospital",
            "Contact": "9440853344",
            "avatarIcon": "HH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9000130071": "0"
            }
        },
        {
            "hospitalName": "SENTINI HOSPITALS PRIVATE LIMITED",
            "Contact": "8666677869",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8977778801": "0"
            }
        },
        {
            "hospitalName": "GLOBAL MULTI SPECIALITY HOSPITAL",
            "Contact": "0866-2571613\n",
            "avatarIcon": "GM",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "7013129343": "0"
            }
        },
        {
            "hospitalName": "Hanshu ortho and Ent Hospital, Thiruvuru",
            "Contact": "088044 99799\n",
            "avatarIcon": "HO",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:14\nVentilator:0",
            "bedsData": {
                "6309078891": "0"
            }
        },
        {
            "hospitalName": "Andhra Hospitals Bhavanipuram Pvt Ltd",
            "Contact": "0866-2415757\n",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:20\nVentilator:0",
            "bedsData": {
                "9700563711": "0"
            }
        },
        {
            "hospitalName": "LAHARI HOSPITAL",
            "Contact": "9550098847",
            "avatarIcon": "LH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "7095053386": "0"
            }
        },
        {
            "hospitalName": "Charitha Sri Hospital",
            "Contact": "9246490913",
            "avatarIcon": "CS",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9246490998": "0"
            }
        },
        {
            "hospitalName": "M/S SOWJANYA HOSPITAL",
            "Contact": "0866-6652111\n",
            "avatarIcon": "MS",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:2\nVentilator:0",
            "bedsData": {
                "9666441685": "0"
            }
        },
        {
            "hospitalName": "Martha Health Care Services  Pvt Ltd",
            "Contact": "08673-251115\n",
            "avatarIcon": "MH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:11\nVentilator:0",
            "bedsData": {
                "9014674360": "0"
            }
        },
        {
            "hospitalName": "VIJAY ORTHO AND ACCIDENTAL CARE",
            "Contact": "8666633108",
            "avatarIcon": "VO",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:7\nVentilator:0",
            "bedsData": {
                "7989300789": "0"
            }
        },
        {
            "hospitalName": "Kamineni Hospital, Vijayawada",
            "Contact": "9494228316",
            "avatarIcon": "KH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8328374367": "0"
            }
        },
        {
            "hospitalName": "Krishna Gastro Hospital",
            "Contact": "8662439999",
            "avatarIcon": "KG",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8019839112": "0"
            }
        },
        {
            "hospitalName": "M V S Accident Hospital",
            "Contact": "8662435555",
            "avatarIcon": "MV",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:1\nVentilator:0",
            "bedsData": {
                "8977711193": "0"
            }
        },
        {
            "hospitalName": "Gayatri Hospitals",
            "Contact": "9246432328",
            "avatarIcon": "GH",
            "foundUseful": "ICU:0\nOxygen:6\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9494665773": "0"
            }
        },
        {
            "hospitalName": "Ravi Hospital",
            "Contact": "7075785878",
            "avatarIcon": "RH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9963534510": "0"
            }
        },
        {
            "hospitalName": "SMILE HOSPITAL, VIJAYAWADA",
            "Contact": "6301597779",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:3\nVentilator:0",
            "bedsData": {
                "9502560326": "0"
            }
        },
        {
            "hospitalName": "Best Hospital",
            "Contact": "8367722722",
            "avatarIcon": "BH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:6\nVentilator:0",
            "bedsData": {
                "9492555092": "0"
            }
        }
    ],
    "KURNOOL": [
        {
            "hospitalName": "ABC Hospital",
            "Contact": "8518233341",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9494803163": "0"
            }
        },
        {
            "hospitalName": "SUNRISE MULTISPECIALITY HOSPITAL",
            "Contact": "8514249880",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9989941265": "0"
            }
        },
        {
            "hospitalName": "SRI SAI SATYA HOSPITAL",
            "Contact": "8518279898",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9440908340": "0"
            }
        },
        {
            "hospitalName": "Sri Gayathri Hospital",
            "Contact": "8518256664",
            "avatarIcon": "SG",
            "foundUseful": "",
            "bedsData": {
                "8985549691": "0"
            }
        },
        {
            "hospitalName": "VIJAY CARE HOSPITAL",
            "Contact": "8688440970",
            "avatarIcon": "VC",
            "foundUseful": "",
            "bedsData": {
                "8688440970": "0"
            }
        },
        {
            "hospitalName": "sai balaji nursing home",
            "Contact": "7729892577",
            "avatarIcon": "SB",
            "foundUseful": "",
            "bedsData": {
                "9010801965": "0"
            }
        },
        {
            "hospitalName": "CBR KURNOOL RAMESH HOSPITAL",
            "Contact": "9490678960",
            "avatarIcon": "CK",
            "foundUseful": "",
            "bedsData": {
                "8639962652": "0"
            }
        },
        {
            "hospitalName": "SJ HOSPITAL",
            "Contact": "8518220444",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9848590170": "0"
            }
        },
        {
            "hospitalName": "MSR MULTI SPECIALITY HOSPITAL",
            "Contact": "8518233888",
            "avatarIcon": "MM",
            "foundUseful": "",
            "bedsData": {
                "9052228983": "0"
            }
        },
        {
            "hospitalName": "Seven Hills Hospital",
            "Contact": "9133210011",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7032196998": "0"
            }
        },
        {
            "hospitalName": "Udayanand Hospital, Nandyal",
            "Contact": "9154752002",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "9182372293": "0"
            }
        },
        {
            "hospitalName": "Noble Multispecialty Hospital, Kurnool",
            "Contact": "9493795776",
            "avatarIcon": "NM",
            "foundUseful": "",
            "bedsData": {
                "8331057548": "0"
            }
        },
        {
            "hospitalName": "JEEVAN HOSPITAL",
            "Contact": "08518-357637\n",
            "avatarIcon": "JH",
            "foundUseful": "",
            "bedsData": {
                "8639151752": "0"
            }
        },
        {
            "hospitalName": "RR HOSPITAL",
            "Contact": "08518-225414\n",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "7780705791": "0"
            }
        },
        {
            "hospitalName": "Omni Hospitals",
            "Contact": "8518277188",
            "avatarIcon": "OH",
            "foundUseful": "",
            "bedsData": {
                "9640488047": "0"
            }
        },
        {
            "hospitalName": "Government General Hospital,Kurnool",
            "Contact": "18004255422",
            "avatarIcon": "GG",
            "foundUseful": "",
            "bedsData": {
                "6309720608": "0"
            }
        },
        {
            "hospitalName": "Ameelio Hospitals,KURNOOL",
            "Contact": "7207082844",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7995086794": "0"
            }
        },
        {
            "hospitalName": "Area Hospital, Adoni",
            "Contact": "8512295796",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9100122509": "0"
            }
        },
        {
            "hospitalName": "Sai Vani Hospital",
            "Contact": "7207524291",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9885379083": "0"
            }
        },
        {
            "hospitalName": "VR Multispeciality",
            "Contact": "8332844455",
            "avatarIcon": "VM",
            "foundUseful": "",
            "bedsData": {
                "9160411059": "0"
            }
        },
        {
            "hospitalName": "Medicover Hospital",
            "Contact": "9100633353",
            "avatarIcon": "MH",
            "foundUseful": "",
            "bedsData": {
                "7995086793": "0"
            }
        },
        {
            "hospitalName": "KVR hospital",
            "Contact": "7013207948",
            "avatarIcon": "KH",
            "foundUseful": "",
            "bedsData": {
                "9100122488": "0"
            }
        },
        {
            "hospitalName": "Vishwabharathi Medical College and Hospital,KURNOOL",
            "Contact": "9059978128",
            "avatarIcon": "VM",
            "foundUseful": "",
            "bedsData": {
                "8328623297": "0"
            }
        },
        {
            "hospitalName": "Vijaya Hospitals",
            "Contact": "9494516163",
            "avatarIcon": "VH",
            "foundUseful": "",
            "bedsData": {
                "9440554629": "0"
            }
        },
        {
            "hospitalName": "District Hospital, Nandyal",
            "Contact": "8514222230",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9440941160": "0"
            }
        },
        {
            "hospitalName": "SGR Hospital",
            "Contact": "8518289299",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9885558553": "0"
            }
        },
        {
            "hospitalName": "Sanjeevani Hospitals",
            "Contact": "08518 226463\n",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9441458139": "0"
            }
        },
        {
            "hospitalName": "Shanthiram Medical College, Nandyal,KURNOOL",
            "Contact": "8514245345",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "8309490861": "0"
            }
        },
        {
            "hospitalName": "Abhaya hospitals",
            "Contact": "8518222040",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "8332997770": "0"
            }
        },
        {
            "hospitalName": "SRI CHAKRA HOSPITAL",
            "Contact": "8518277895",
            "avatarIcon": "SC",
            "foundUseful": "",
            "bedsData": {
                "9440079892": "0"
            }
        },
        {
            "hospitalName": "Sri Gayathri Hospital",
            "Contact": "8518256664",
            "avatarIcon": "SG",
            "foundUseful": "ICU:4\nOxygen:16\nGeneral:7\nVentilator:1",
            "bedsData": {
                "8985549691": "0"
            }
        },
        {
            "hospitalName": "VIJAY CARE HOSPITAL",
            "Contact": "8688440970",
            "avatarIcon": "VC",
            "foundUseful": "ICU:3\nOxygen:9\nGeneral:8\nVentilator:1",
            "bedsData": {
                "8688440970": "0"
            }
        },
        {
            "hospitalName": "sai balaji nursing home",
            "Contact": "7729892577",
            "avatarIcon": "SB",
            "foundUseful": "ICU:0\nOxygen:4\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9010801965": "0"
            }
        },
        {
            "hospitalName": "CBR KURNOOL RAMESH HOSPITAL",
            "Contact": "9490678960",
            "avatarIcon": "CK",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:1\nVentilator:0",
            "bedsData": {
                "9440087950": "0"
            }
        },
        {
            "hospitalName": "SJ HOSPITAL",
            "Contact": "8518220444",
            "avatarIcon": "SH",
            "foundUseful": "ICU:4\nOxygen:5\nGeneral:15\nVentilator:0",
            "bedsData": {
                "9848590170": "0"
            }
        },
        {
            "hospitalName": "MSR MULTI SPECIALITY HOSPITAL",
            "Contact": "8518233888",
            "avatarIcon": "MM",
            "foundUseful": "ICU:2\nOxygen:3\nGeneral:3\nVentilator:0",
            "bedsData": {
                "9052228983": "0"
            }
        },
        {
            "hospitalName": "Seven Hills Hospital",
            "Contact": "9133210011",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:2\nVentilator:0",
            "bedsData": {
                "9398190665": "0"
            }
        },
        {
            "hospitalName": "Udayanand Hospital, Nandyal",
            "Contact": "9154752002",
            "avatarIcon": "UH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:10\nVentilator:0",
            "bedsData": {
                "9182372293": "0"
            }
        },
        {
            "hospitalName": "Noble Multispecialty Hospital, Kurnool",
            "Contact": "9493795776",
            "avatarIcon": "NM",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8331057548": "0"
            }
        },
        {
            "hospitalName": "JEEVAN HOSPITAL",
            "Contact": "08518-357637\n",
            "avatarIcon": "JH",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:32\nVentilator:2",
            "bedsData": {
                "8639151752": "0"
            }
        },
        {
            "hospitalName": "RR HOSPITAL",
            "Contact": "08518-225414\n",
            "avatarIcon": "RH",
            "foundUseful": "ICU:0\nOxygen:5\nGeneral:7\nVentilator:0",
            "bedsData": {
                "7780705791": "0"
            }
        },
        {
            "hospitalName": "Omni Hospitals",
            "Contact": "8518277188",
            "avatarIcon": "OH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9703023292": "0"
            }
        },
        {
            "hospitalName": "Government General Hospital",
            "Contact": "18004255422",
            "avatarIcon": "GG",
            "foundUseful": "ICU:21\nOxygen:0\nGeneral:0\nVentilator:1",
            "bedsData": {
                "6309720608": "0"
            }
        },
        {
            "hospitalName": "Ameelio Hospitals",
            "Contact": "7207082844",
            "avatarIcon": "AH",
            "foundUseful": "ICU:21\nOxygen:11\nGeneral:8\nVentilator:0",
            "bedsData": {
                "7382080663": "0"
            }
        },
        {
            "hospitalName": "Area Hospital, Adoni",
            "Contact": "8512295796",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:42\nGeneral:10\nVentilator:0",
            "bedsData": {
                "9652259277": "0"
            }
        },
        {
            "hospitalName": "Sai Vani Hospital",
            "Contact": "7207524291",
            "avatarIcon": "SV",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:14\nVentilator:2",
            "bedsData": {
                "9494554425": "0"
            }
        },
        {
            "hospitalName": "AYUSHMAN HOSPITAL",
            "Contact": "7093982291",
            "avatarIcon": "AH",
            "foundUseful": "ICU:1\nOxygen:13\nGeneral:8\nVentilator:0",
            "bedsData": {
                "9441290572": "0"
            }
        },
        {
            "hospitalName": "VR Multispeciality",
            "Contact": "8332844455",
            "avatarIcon": "VM",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9160411059": "0"
            }
        },
        {
            "hospitalName": "Medicover Hospital",
            "Contact": "9100633353",
            "avatarIcon": "MH",
            "foundUseful": "ICU:0\nOxygen:9\nGeneral:1\nVentilator:0",
            "bedsData": {
                "7799223227": "0"
            }
        },
        {
            "hospitalName": "KVR hospital",
            "Contact": "7013207948",
            "avatarIcon": "KH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9100122488": "0"
            }
        },
        {
            "hospitalName": "Vishwabharathi Medical College and Hospital",
            "Contact": "9059978128",
            "avatarIcon": "VM",
            "foundUseful": "ICU:4\nOxygen:25\nGeneral:186\nVentilator:0",
            "bedsData": {
                "8328623297": "0"
            }
        },
        {
            "hospitalName": "Vijaya Hospitals",
            "Contact": "9494516163",
            "avatarIcon": "VH",
            "foundUseful": "ICU:2\nOxygen:4\nGeneral:11\nVentilator:0",
            "bedsData": {
                "9440554629": "0"
            }
        },
        {
            "hospitalName": "District Hospital, Nandyal",
            "Contact": "8514222230",
            "avatarIcon": "DH",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:0\nVentilator:10",
            "bedsData": {
                "9494134010": "0"
            }
        },
        {
            "hospitalName": "SGR Hospital",
            "Contact": "8518289299",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:15\nVentilator:0",
            "bedsData": {
                "9885558553": "0"
            }
        },
        {
            "hospitalName": "Sanjeevani Hospitals",
            "Contact": "08518 226463\n",
            "avatarIcon": "SH",
            "foundUseful": "ICU:2\nOxygen:0\nGeneral:5\nVentilator:0",
            "bedsData": {
                "9490610875": "0"
            }
        },
        {
            "hospitalName": "Shanthiram Medical College, Nandyal",
            "Contact": "8514245345",
            "avatarIcon": "SM",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:259\nVentilator:0",
            "bedsData": {
                "9440293580": "0"
            }
        },
        {
            "hospitalName": "Abhaya hospitals",
            "Contact": "8518222040",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:10\nVentilator:0",
            "bedsData": {
                "8332997770": "0"
            }
        },
        {
            "hospitalName": "SRI CHAKRA HOSPITAL",
            "Contact": "8518277895",
            "avatarIcon": "SC",
            "foundUseful": "ICU:1\nOxygen:0\nGeneral:1\nVentilator:0",
            "bedsData": {
                "9440079892": "0"
            }
        }
    ],
    "NELLORE": [
        {
            "hospitalName": "CHC Podalakur",
            "Contact": "6300474967",
            "avatarIcon": "CP",
            "foundUseful": "",
            "bedsData": {
                "9000661027": "0"
            }
        },
        {
            "hospitalName": "Sri Ram specialty Hospital",
            "Contact": "0861-2338787\n",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9440376277": "0"
            }
        },
        {
            "hospitalName": "CHC Kovuru",
            "Contact": "8978084878",
            "avatarIcon": "CK",
            "foundUseful": "",
            "bedsData": {
                "9848279515": "0"
            }
        },
        {
            "hospitalName": "CHC Buchireddy pallem",
            "Contact": "08622-272400,8186828205\n",
            "avatarIcon": "CB",
            "foundUseful": "",
            "bedsData": {
                "9949911767": "0"
            }
        },
        {
            "hospitalName": "CHC Udayagiri",
            "Contact": "9618669273",
            "avatarIcon": "CU",
            "foundUseful": "",
            "bedsData": {
                "7331128624": "0"
            }
        },
        {
            "hospitalName": "CHC Kota",
            "Contact": "08624-295960\n",
            "avatarIcon": "CK",
            "foundUseful": "",
            "bedsData": {
                "9949911506": "0"
            }
        },
        {
            "hospitalName": "Vijaya care Multyspecialty",
            "Contact": "8121450112",
            "avatarIcon": "VC",
            "foundUseful": "",
            "bedsData": {
                "9848517547": "0"
            }
        },
        {
            "hospitalName": "Rainbow super Specialty Hospital,",
            "Contact": "7207246442",
            "avatarIcon": "RS",
            "foundUseful": "",
            "bedsData": {
                "7416118451": "0"
            }
        },
        {
            "hospitalName": "Amaravathi Hospital ,Guduru",
            "Contact": "8624250055",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9949911505": "0"
            }
        },
        {
            "hospitalName": "Rich Hospital",
            "Contact": "9666520465",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9849904499": "0"
            }
        },
        {
            "hospitalName": "Mythili",
            "Contact": "9440445180",
            "avatarIcon": "M",
            "foundUseful": "",
            "bedsData": {
                "8179203351": "0"
            }
        },
        {
            "hospitalName": "Abhiram Instutions of Medical Sciences,Atmakur",
            "Contact": "9160930037",
            "avatarIcon": "AI",
            "foundUseful": "",
            "bedsData": {
                "8008903164": "0"
            }
        },
        {
            "hospitalName": "Dr Vijayakumar Nellore Memorial  Hospital, Nellore",
            "Contact": "0861-2347880\n",
            "avatarIcon": "DV",
            "foundUseful": "",
            "bedsData": {
                "9618404383": "0"
            }
        },
        {
            "hospitalName": "Area Hospital Gudur",
            "Contact": "9491242054 , 08624221364\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9491448640": "0"
            }
        },
        {
            "hospitalName": "CHC Allur",
            "Contact": "08622-295580\n",
            "avatarIcon": "CA",
            "foundUseful": "",
            "bedsData": {
                "7093172137": "0"
            }
        },
        {
            "hospitalName": "Trinity Hospital Naidupeta",
            "Contact": "7386101770",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9100121730": "0"
            }
        },
        {
            "hospitalName": "Sri Chakra Multispecialty",
            "Contact": "7207103550",
            "avatarIcon": "SC",
            "foundUseful": "",
            "bedsData": {
                "9703262999": "0"
            }
        },
        {
            "hospitalName": "Anasuya Instute of Medical Science ,Nellore",
            "Contact": "9492272514",
            "avatarIcon": "AI",
            "foundUseful": "",
            "bedsData": {
                "9492555115": "0"
            }
        },
        {
            "hospitalName": "St Joseph Hospital",
            "Contact": "8612331846",
            "avatarIcon": "SJ",
            "foundUseful": "",
            "bedsData": {
                "9182361352": "0"
            }
        },
        {
            "hospitalName": "CHC Venkatagiri",
            "Contact": "7207048401",
            "avatarIcon": "CV",
            "foundUseful": "",
            "bedsData": {
                "9949911501": "0"
            }
        },
        {
            "hospitalName": "Govt General Hospital,SPSR NELLORE",
            "Contact": "8612330026",
            "avatarIcon": "GG",
            "foundUseful": "",
            "bedsData": {
                "9390765565": "0"
            }
        },
        {
            "hospitalName": "KIMS Nelloore",
            "Contact": "8612312777",
            "avatarIcon": "KN",
            "foundUseful": "",
            "bedsData": {
                "918074783959": "0"
            }
        },
        {
            "hospitalName": "Padmavathi Hospital ,Nellore",
            "Contact": "0861-2356667\n",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9849356979": "0"
            }
        },
        {
            "hospitalName": "Sri Sai Multyspecility",
            "Contact": "8626242255",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "7997721020": "0"
            }
        },
        {
            "hospitalName": "NARAYANA MEDICAL COLLEGE HOSPITAL,SPSR NELLORE",
            "Contact": "8612350554",
            "avatarIcon": "NM",
            "foundUseful": "",
            "bedsData": {
                "9063188888": "0"
            }
        },
        {
            "hospitalName": "District Hospital Atmakur NLR",
            "Contact": "733,097,594,508,627,000,000",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "8985501725": "0"
            }
        },
        {
            "hospitalName": "Medicover",
            "Contact": "8613500350",
            "avatarIcon": "M",
            "foundUseful": "",
            "bedsData": {
                "8328016755": "0"
            }
        },
        {
            "hospitalName": "Sri Venkateswara Prajavidyasala Hospital,Atmakur",
            "Contact": "08627-221870\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "7995086781": "0"
            }
        },
        {
            "hospitalName": "Vijaya Krishna Hospital",
            "Contact": "8712264222",
            "avatarIcon": "VK",
            "foundUseful": "",
            "bedsData": {
                "7995086780": "0"
            }
        },
        {
            "hospitalName": "CHC Rapur",
            "Contact": "8328624821,  7207369971\n",
            "avatarIcon": "CR",
            "foundUseful": "",
            "bedsData": {
                "7093930973": "0"
            }
        },
        {
            "hospitalName": "APOLLO SPECIALTY HOSPITAL",
            "Contact": "8616667028",
            "avatarIcon": "AS",
            "foundUseful": "",
            "bedsData": {
                "9849903744": "0"
            }
        },
        {
            "hospitalName": "Jaya Bharath Hospitals",
            "Contact": "9492240473",
            "avatarIcon": "JB",
            "foundUseful": "",
            "bedsData": {
                "8886645575": "0"
            }
        },
        {
            "hospitalName": "AH Kavali",
            "Contact": "O8626 242524\n",
            "avatarIcon": "AK",
            "foundUseful": "",
            "bedsData": {
                "8374444961": "0"
            }
        },
        {
            "hospitalName": "CHC Podalakur",
            "Contact": "6300474967",
            "avatarIcon": "CP",
            "foundUseful": "ICU:0\nOxygen:6\nGeneral:9\nVentilator:0",
            "bedsData": {
                "9000661027": "0"
            }
        },
        {
            "hospitalName": "Sri Ram specialty Hospital",
            "Contact": "0861-2338787\n",
            "avatarIcon": "SR",
            "foundUseful": "ICU:0\nOxygen:7\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9440376277": "0"
            }
        },
        {
            "hospitalName": "CHC Kovuru",
            "Contact": "8978084878",
            "avatarIcon": "CK",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:5\nVentilator:0",
            "bedsData": {
                "9848279515": "0"
            }
        },
        {
            "hospitalName": "CHC Buchireddy pallem",
            "Contact": "08622-272400,8186828205\n",
            "avatarIcon": "CB",
            "foundUseful": "ICU:0\nOxygen:9\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9949911767": "0"
            }
        },
        {
            "hospitalName": "CHC Udayagiri",
            "Contact": "9618669273",
            "avatarIcon": "CU",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "7331128624": "0"
            }
        },
        {
            "hospitalName": "CHC Kota",
            "Contact": "08624-295960\n",
            "avatarIcon": "CK",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:6\nVentilator:0",
            "bedsData": {
                "9949911506": "0"
            }
        },
        {
            "hospitalName": "Vijaya care Multyspecialty",
            "Contact": "7702394666",
            "avatarIcon": "VC",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:15\nVentilator:0",
            "bedsData": {
                "9848517547": "0"
            }
        },
        {
            "hospitalName": "Rainbow super Specialty Hospital,",
            "Contact": "7207246442",
            "avatarIcon": "RS",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "7416118451": "0"
            }
        },
        {
            "hospitalName": "Amaravathi Hospital ,Guduru",
            "Contact": "8624250055",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:5\nGeneral:3\nVentilator:0",
            "bedsData": {
                "9949911505": "0"
            }
        },
        {
            "hospitalName": "Rich Hospital",
            "Contact": "9666520465",
            "avatarIcon": "RH",
            "foundUseful": "ICU:1\nOxygen:0\nGeneral:4\nVentilator:0",
            "bedsData": {
                "9849904499": "0"
            }
        },
        {
            "hospitalName": "Mythili",
            "Contact": "9440445180",
            "avatarIcon": "M",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8179203351": "0"
            }
        },
        {
            "hospitalName": "Abhiram Instutions of Medical Sciences,Atmakur",
            "Contact": "9100969921",
            "avatarIcon": "AI",
            "foundUseful": "ICU:0\nOxygen:3\nGeneral:1\nVentilator:0",
            "bedsData": {
                "8008903164": "0"
            }
        },
        {
            "hospitalName": "Dr Vijayakumar Nellore Memorial  Hospital, Nellore",
            "Contact": "0861-2347880\n",
            "avatarIcon": "DV",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:20\nVentilator:0",
            "bedsData": {
                "9618404383": "0"
            }
        },
        {
            "hospitalName": "Area Hospital Gudur",
            "Contact": "9491242054",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9491448640": "0"
            }
        },
        {
            "hospitalName": "CHC Allur",
            "Contact": "08622-295580\n",
            "avatarIcon": "CA",
            "foundUseful": "ICU:0\nOxygen:9\nGeneral:14\nVentilator:0",
            "bedsData": {
                "7093172137": "0"
            }
        },
        {
            "hospitalName": "RSR Super Specialty Hospital,",
            "Contact": "9010817060",
            "avatarIcon": "RS",
            "foundUseful": "ICU:0\nOxygen:5\nGeneral:9\nVentilator:0",
            "bedsData": {
                "8328486778": "0"
            }
        },
        {
            "hospitalName": "Trinity Hospital Naidupeta",
            "Contact": "7386101770",
            "avatarIcon": "TH",
            "foundUseful": "ICU:1\nOxygen:1\nGeneral:1\nVentilator:0",
            "bedsData": {
                "9100121730": "0"
            }
        },
        {
            "hospitalName": "Sri Chakra Multispecialty",
            "Contact": "7386739404",
            "avatarIcon": "SC",
            "foundUseful": "ICU:0\nOxygen:4\nGeneral:6\nVentilator:0",
            "bedsData": {
                "9703262999": "0"
            }
        },
        {
            "hospitalName": "Anasuya Instute of Medical Science ,Nellore",
            "Contact": "9492272514",
            "avatarIcon": "AI",
            "foundUseful": "ICU:0\nOxygen:3\nGeneral:10\nVentilator:0",
            "bedsData": {
                "9492555155": "0"
            }
        },
        {
            "hospitalName": "St Joseph Hospital",
            "Contact": "8612331846",
            "avatarIcon": "SJ",
            "foundUseful": "ICU:2\nOxygen:0\nGeneral:6\nVentilator:0",
            "bedsData": {
                "9182361352": "0"
            }
        },
        {
            "hospitalName": "CHC Venkatagiri",
            "Contact": "7207048401",
            "avatarIcon": "CV",
            "foundUseful": "ICU:0\nOxygen:4\nGeneral:5\nVentilator:0",
            "bedsData": {
                "9949911501": "0"
            }
        },
        {
            "hospitalName": "Govt General Hospital",
            "Contact": "8612330026",
            "avatarIcon": "GG",
            "foundUseful": "ICU:0\nOxygen:55\nGeneral:114\nVentilator:0",
            "bedsData": {
                "9390765565": "0"
            }
        },
        {
            "hospitalName": "KIMS Nelloore",
            "Contact": "7207246442",
            "avatarIcon": "KN",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:0\nVentilator:0",
            "bedsData": {
                "918074783959": "0"
            }
        },
        {
            "hospitalName": "Padmavathi Hospital ,Nellore",
            "Contact": "0861-2356668,0861-2356667\n",
            "avatarIcon": "PH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9849356379": "0"
            }
        },
        {
            "hospitalName": "Sri Sai Multyspecility",
            "Contact": "8626242255",
            "avatarIcon": "SS",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:18\nVentilator:0",
            "bedsData": {
                "7997721020": "0"
            }
        },
        {
            "hospitalName": "NARAYANA MEDICAL COLLEGE HOSPITAL",
            "Contact": "8612350554",
            "avatarIcon": "NM",
            "foundUseful": "ICU:4\nOxygen:0\nGeneral:514\nVentilator:0",
            "bedsData": {
                "9000661904": "0"
            }
        },
        {
            "hospitalName": "District Hospital Atmakur NLR",
            "Contact": "7330975945",
            "avatarIcon": "DH",
            "foundUseful": "ICU:0\nOxygen:5\nGeneral:7\nVentilator:0",
            "bedsData": {
                "8985501725": "0"
            }
        },
        {
            "hospitalName": "Medicover",
            "Contact": "8613500350",
            "avatarIcon": "M",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:15\nVentilator:0",
            "bedsData": {
                "8328016755": "0"
            }
        },
        {
            "hospitalName": "Sri Venkateswara Prajavidyasala Hospital,Atmakur",
            "Contact": "08627-221870\n",
            "avatarIcon": "SV",
            "foundUseful": "ICU:0\nOxygen:6\nGeneral:8\nVentilator:0",
            "bedsData": {
                "7995086781": "0"
            }
        },
        {
            "hospitalName": "Kandukuri Hospital ,Kavali",
            "Contact": "7993062104",
            "avatarIcon": "KH",
            "foundUseful": "ICU:0\nOxygen:5\nGeneral:8\nVentilator:0",
            "bedsData": {
                "9949911737": "0"
            }
        },
        {
            "hospitalName": "Vijaya Krishna Hospital",
            "Contact": "8712264222",
            "avatarIcon": "VK",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:10\nVentilator:0",
            "bedsData": {
                "7995086780": "0"
            }
        },
        {
            "hospitalName": "CHC Rapur",
            "Contact": "8328624821,  7207369971\n",
            "avatarIcon": "CR",
            "foundUseful": "ICU:0\nOxygen:4\nGeneral:6\nVentilator:0",
            "bedsData": {
                "7093930973": "0"
            }
        },
        {
            "hospitalName": "APOLLO SPECIALTY HOSPITAL",
            "Contact": "8616667028",
            "avatarIcon": "AS",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9849903744": "0"
            }
        },
        {
            "hospitalName": "Jaya Bharath Hospitals",
            "Contact": "9492240473",
            "avatarIcon": "JB",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8886645575": "0"
            }
        },
        {
            "hospitalName": "AH Kavali",
            "Contact": "O8626 242524\n",
            "avatarIcon": "AK",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:2\nVentilator:0",
            "bedsData": {
                "8374444961": "0"
            }
        }
    ],
    "GUNTUR": [
        {
            "hospitalName": "Sree Prathima super Speciality Hospital, Guntur",
            "Contact": "7207411887",
            "avatarIcon": "SP",
            "foundUseful": "",
            "bedsData": {
                "8333818741": "0"
            }
        },
        {
            "hospitalName": "Kocher s Institute of Medical Sciences, Guntur",
            "Contact": "9133097458",
            "avatarIcon": "KS",
            "foundUseful": "",
            "bedsData": {
                "9493443343": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre Amaravathi",
            "Contact": "8645255565",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9121190732": "0"
            }
        },
        {
            "hospitalName": "SHRI Hospital",
            "Contact": "9121139391",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8074812322": "0"
            }
        },
        {
            "hospitalName": "NRI Hospital,GUNTUR",
            "Contact": "08645-230139\n",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9100678970": "0"
            }
        },
        {
            "hospitalName": "KNR Hospital , Guntur",
            "Contact": "9502819652",
            "avatarIcon": "KH",
            "foundUseful": "",
            "bedsData": {
                "8187810259": "0"
            }
        },
        {
            "hospitalName": "AIIMS",
            "Contact": "9440642909",
            "avatarIcon": "A",
            "foundUseful": "",
            "bedsData": {
                "9492555142": "0"
            }
        },
        {
            "hospitalName": "Suraksha Hospital, Guntur",
            "Contact": "8632222286",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7901093215": "0"
            }
        },
        {
            "hospitalName": "GGH,GUNTUR",
            "Contact": "9494520370",
            "avatarIcon": "G",
            "foundUseful": "",
            "bedsData": {
                "9100109185": "0"
            }
        },
        {
            "hospitalName": "Gajjala Hospital, Sattenapalli",
            "Contact": "9177094700",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9989034658": "0"
            }
        },
        {
            "hospitalName": "Nuha Hospitals, Guntur",
            "Contact": "8632255559",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9912885857": "0"
            }
        },
        {
            "hospitalName": "AREA HOSPITAL  BAPATLA",
            "Contact": "8643220077",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "8712394523": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre Prathipadu",
            "Contact": "0863 2280545\n",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9441997757": "0"
            }
        },
        {
            "hospitalName": "Andhra Prime Hospitals, Guntur",
            "Contact": "8632222259",
            "avatarIcon": "AP",
            "foundUseful": "",
            "bedsData": {
                "9550587505": "0"
            }
        },
        {
            "hospitalName": "Sanjana Specialty Hospital,Sattenaplli",
            "Contact": "8641232193",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6301445787": "0"
            }
        },
        {
            "hospitalName": "Geetha Multi Specialty Hospital, Sattenapalli",
            "Contact": "8641232320",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "8331056931": "0"
            }
        },
        {
            "hospitalName": "CHC Gurazala",
            "Contact": "7893949778",
            "avatarIcon": "CG",
            "foundUseful": "",
            "bedsData": {
                "9100121480": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre Pedakurapadu",
            "Contact": "9490450578",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "8555074091": "0"
            }
        },
        {
            "hospitalName": "Area Hospital Narasaraopet",
            "Contact": "7207267708",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "8977410878": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre,Sattenapalli",
            "Contact": "8641232213",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "8919370019": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre Vemuru",
            "Contact": "8644246941",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "8247844347": "0"
            }
        },
        {
            "hospitalName": "Viswas Hospitals, Inner Ring Road",
            "Contact": "9100071519",
            "avatarIcon": "VH",
            "foundUseful": "",
            "bedsData": {
                "9848337614": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre\tPonnur",
            "Contact": "8643243388",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9849917667": "0"
            }
        },
        {
            "hospitalName": "CHC kollipara",
            "Contact": "8644244455",
            "avatarIcon": "CK",
            "foundUseful": "",
            "bedsData": {
                "9441235567": "0"
            }
        },
        {
            "hospitalName": "Community Health Center\tMacherla",
            "Contact": "8642222180",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9133560890": "0"
            }
        },
        {
            "hospitalName": "Siva Hospital, Guntur",
            "Contact": "8919329361",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9440644026": "0"
            }
        },
        {
            "hospitalName": "Jayathi Hospitals",
            "Contact": "0863 2325434\n",
            "avatarIcon": "JH",
            "foundUseful": "",
            "bedsData": {
                "9948946232": "0"
            }
        },
        {
            "hospitalName": "Yashaswi Hospitals",
            "Contact": "8632262255",
            "avatarIcon": "YH",
            "foundUseful": "",
            "bedsData": {
                "8333019139": "0"
            }
        },
        {
            "hospitalName": "CHC Vinukonda",
            "Contact": "8646273273",
            "avatarIcon": "CV",
            "foundUseful": "",
            "bedsData": {
                "7382335933": "0"
            }
        },
        {
            "hospitalName": "CHC Nizampatnam",
            "Contact": "0864-8257266\n",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "9346327594": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre, Nagaram",
            "Contact": "08648-256032\n",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9949641499": "0"
            }
        },
        {
            "hospitalName": "KIMS Hospital",
            "Contact": "8096261653",
            "avatarIcon": "KH",
            "foundUseful": "",
            "bedsData": {
                "7901093246": "0"
            }
        },
        {
            "hospitalName": "Sai Tirumala Super Specialty Hospital",
            "Contact": "8647220229",
            "avatarIcon": "ST",
            "foundUseful": "",
            "bedsData": {
                "9985953389": "0"
            }
        },
        {
            "hospitalName": "Varababu Hospital",
            "Contact": "8647232888",
            "avatarIcon": "VH",
            "foundUseful": "",
            "bedsData": {
                "8886597070": "0"
            }
        },
        {
            "hospitalName": "Ammaji Pavani Memorial Hospital",
            "Contact": "0863-2244278\n",
            "avatarIcon": "AP",
            "foundUseful": "",
            "bedsData": {
                "9885181110": "0"
            }
        },
        {
            "hospitalName": "Health Hospitals",
            "Contact": "08644-226060\n",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9246744462": "0"
            }
        },
        {
            "hospitalName": "Aswini Hospitals, Guntur",
            "Contact": "8632227000",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9493168154": "0"
            }
        },
        {
            "hospitalName": "Vedanta Hospitals,Guntur",
            "Contact": "9133332737",
            "avatarIcon": "VH",
            "foundUseful": "",
            "bedsData": {
                "9885880083": "0"
            }
        },
        {
            "hospitalName": "CHC Ipuru",
            "Contact": "8646258327",
            "avatarIcon": "CI",
            "foundUseful": "",
            "bedsData": {
                "9985499055": "0"
            }
        },
        {
            "hospitalName": "ID Hospital",
            "Contact": "0863-2231050\n",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "9949530595": "0"
            }
        },
        {
            "hospitalName": "Kommineni Super Specialty Hospital",
            "Contact": "8647254222",
            "avatarIcon": "KS",
            "foundUseful": "",
            "bedsData": {
                "9441128175": "0"
            }
        },
        {
            "hospitalName": "Star Hospital, Guntur",
            "Contact": "7207366777",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8331056926": "0"
            }
        },
        {
            "hospitalName": "Community Health Center, Pittalavanipalem",
            "Contact": "8643258202",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9701078188": "0"
            }
        },
        {
            "hospitalName": "Anjireddy Hospital, Piduguralla",
            "Contact": "08649-254001\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "8790904685": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre,Repalle",
            "Contact": "7207086825",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "7901093151": "0"
            }
        },
        {
            "hospitalName": "A.V.R. Hospital",
            "Contact": "0863-2263699\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "8500726335": "0"
            }
        },
        {
            "hospitalName": "Siri Hospital",
            "Contact": "0863-2253456\n",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9848184615": "0"
            }
        },
        {
            "hospitalName": "Amaravathi  Hospital",
            "Contact": "8632256699",
            "avatarIcon": "A",
            "foundUseful": "",
            "bedsData": {
                "9949142415": "0"
            }
        },
        {
            "hospitalName": "District hospital Tenali",
            "Contact": "8008553549",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9440818061": "0"
            }
        },
        {
            "hospitalName": "Lalitha Super Specialty, Guntur",
            "Contact": "0863-2228317\n",
            "avatarIcon": "LS",
            "foundUseful": "",
            "bedsData": {
                "9849901142": "0"
            }
        },
        {
            "hospitalName": "Swathi Institute  of Medical  Science",
            "Contact": "9966552035",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9347458830": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre Chilakaluripet",
            "Contact": "08647-254433\n",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9440818758": "0"
            }
        },
        {
            "hospitalName": "Dr. Raos Hospital",
            "Contact": "9010056444",
            "avatarIcon": "DR",
            "foundUseful": "",
            "bedsData": {
                "9010947770": "0"
            }
        },
        {
            "hospitalName": "MANIPAL HOSPITAL",
            "Contact": "9989614312",
            "avatarIcon": "MH",
            "foundUseful": "",
            "bedsData": {
                "7093883010": "0"
            }
        },
        {
            "hospitalName": "GBR Hospital, NarasaraoPeta",
            "Contact": "8886062686",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9848474070": "0"
            }
        },
        {
            "hospitalName": "Likhitha Super Specialty Hospital",
            "Contact": "8647229900",
            "avatarIcon": "LS",
            "foundUseful": "",
            "bedsData": {
                "9177116886": "0"
            }
        },
        {
            "hospitalName": "Uday Hospital",
            "Contact": "0863-2227719\n",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "9492555159": "0"
            }
        },
        {
            "hospitalName": "NIMS 24  Hospital",
            "Contact": "8646275666",
            "avatarIcon": "N2",
            "foundUseful": "",
            "bedsData": {
                "9640343714": "0"
            }
        },
        {
            "hospitalName": "Sri Rama Hospital, Guntur",
            "Contact": "8632215137",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9492555148": "0"
            }
        },
        {
            "hospitalName": "Mother Theresa  Hospital",
            "Contact": "7207399656",
            "avatarIcon": "MT",
            "foundUseful": "",
            "bedsData": {
                "9618022809": "0"
            }
        },
        {
            "hospitalName": "BMR Hospitals, Guntur",
            "Contact": "0863 2241370\n",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9848779529": "0"
            }
        },
        {
            "hospitalName": "Katuri medical college,GUNTUR",
            "Contact": "0863-2288555\n",
            "avatarIcon": "KM",
            "foundUseful": "",
            "bedsData": {
                "8008204148": "0"
            }
        },
        {
            "hospitalName": "Sri Datta Hospital, Narasaraopet",
            "Contact": "8886655383",
            "avatarIcon": "SD",
            "foundUseful": "",
            "bedsData": {
                "9866183998": "0"
            }
        },
        {
            "hospitalName": "Sri Sai Hospital",
            "Contact": "8632234963",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9493445643": "0"
            }
        },
        {
            "hospitalName": "Renuuka Hospital",
            "Contact": "0863-2269666\n",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9848585774": "0"
            }
        },
        {
            "hospitalName": "Amrutha Hospitals, Guntur",
            "Contact": "0863-2210123\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7981486998": "0"
            }
        },
        {
            "hospitalName": "Viswas Hospital, Kothapet, Guntur",
            "Contact": "8632323700",
            "avatarIcon": "VH",
            "foundUseful": "",
            "bedsData": {
                "9440017101": "0"
            }
        },
        {
            "hospitalName": "Tulasi Multi Speciality Hospital, Kothapeta, Guntur",
            "Contact": "8632323235",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "9492555147": "0"
            }
        },
        {
            "hospitalName": "Sri Lakshmi Hospitals",
            "Contact": "6301508027",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "8501074974": "0"
            }
        },
        {
            "hospitalName": "Mythri Multi Specialty Hospital",
            "Contact": "0863 - 2200222\n",
            "avatarIcon": "MM",
            "foundUseful": "",
            "bedsData": {
                "9492555149": "0"
            }
        },
        {
            "hospitalName": "Sravani Hospital, Guntur",
            "Contact": "9642877979",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7095409415": "0"
            }
        },
        {
            "hospitalName": "Pragathi Nursing Home",
            "Contact": "8647253253",
            "avatarIcon": "PN",
            "foundUseful": "",
            "bedsData": {
                "9100121478": "0"
            }
        },
        {
            "hospitalName": "Coastal Care Hospital",
            "Contact": "0863-2233377\n",
            "avatarIcon": "CC",
            "foundUseful": "",
            "bedsData": {
                "9492555143": "0"
            }
        },
        {
            "hospitalName": "Citizen Super Specialty Hospital",
            "Contact": "9866094844",
            "avatarIcon": "CS",
            "foundUseful": "",
            "bedsData": {
                "7386388805": "0"
            }
        },
        {
            "hospitalName": "Sri Balaji Hospitals, Guntur",
            "Contact": "7207310544",
            "avatarIcon": "SB",
            "foundUseful": "",
            "bedsData": {
                "9966418444": "0"
            }
        },
        {
            "hospitalName": "People's Trauma Hospital, Guntur",
            "Contact": "7013594221",
            "avatarIcon": "PT",
            "foundUseful": "",
            "bedsData": {
                "9440353708": "0"
            }
        },
        {
            "hospitalName": "Aditya Multi Specialty Hospital",
            "Contact": "8632220395",
            "avatarIcon": "AM",
            "foundUseful": "",
            "bedsData": {
                "9440988904": "0"
            }
        },
        {
            "hospitalName": "Cherukuri Hospitals",
            "Contact": "08647-295777\n",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9032782640": "0"
            }
        },
        {
            "hospitalName": "Guntur City Hospitals",
            "Contact": "8632237337",
            "avatarIcon": "GC",
            "foundUseful": "",
            "bedsData": {
                "7995087048": "0"
            }
        },
        {
            "hospitalName": "Vivekananda Super Speciality Hospital",
            "Contact": "8647296777",
            "avatarIcon": "VS",
            "foundUseful": "",
            "bedsData": {
                "8331089985": "0"
            }
        },
        {
            "hospitalName": "Sree Prathima super Speciality Hospital, Guntur",
            "Contact": "7207411887",
            "avatarIcon": "SP",
            "foundUseful": "ICU:1\nOxygen:1\nGeneral:1\nVentilator:0",
            "bedsData": {
                "8333818741": "0"
            }
        },
        {
            "hospitalName": "Kocher s Institute of Medical Sciences, Guntur",
            "Contact": "9133097458",
            "avatarIcon": "KS",
            "foundUseful": "ICU:4\nOxygen:21\nGeneral:6\nVentilator:0",
            "bedsData": {
                "9493443343": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre Amaravathi",
            "Contact": "8985895992",
            "avatarIcon": "CH",
            "foundUseful": "ICU:0\nOxygen:12\nGeneral:4\nVentilator:0",
            "bedsData": {
                "9121190732": "0"
            }
        },
        {
            "hospitalName": "SHRI Hospital",
            "Contact": "9121139391",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8074812322": "0"
            }
        },
        {
            "hospitalName": "NRI Hospital",
            "Contact": "08645-230139\n",
            "avatarIcon": "NH",
            "foundUseful": "ICU:3\nOxygen:40\nGeneral:138\nVentilator:0",
            "bedsData": {
                "9100678970": "0"
            }
        },
        {
            "hospitalName": "KNR Hospital , Guntur",
            "Contact": "9502819652",
            "avatarIcon": "KH",
            "foundUseful": "ICU:6\nOxygen:8\nGeneral:21\nVentilator:3",
            "bedsData": {
                "8187810259": "0"
            }
        },
        {
            "hospitalName": "AIIMS",
            "Contact": "9440642909",
            "avatarIcon": "A",
            "foundUseful": "ICU:0\nOxygen:23\nGeneral:17\nVentilator:0",
            "bedsData": {
                "9492555142": "0"
            }
        },
        {
            "hospitalName": "Suraksha Hospital, Guntur",
            "Contact": "9613377377",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:4\nGeneral:9\nVentilator:0",
            "bedsData": {
                "7901093215": "0"
            }
        },
        {
            "hospitalName": "GGH",
            "Contact": "9494520370",
            "avatarIcon": "G",
            "foundUseful": "ICU:8\nOxygen:20\nGeneral:45\nVentilator:0",
            "bedsData": {
                "9100109185": "0"
            }
        },
        {
            "hospitalName": "Gajjala Hospital, Sattenapalli",
            "Contact": "0864-1232888\n",
            "avatarIcon": "GH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:8\nVentilator:0",
            "bedsData": {
                "9949083777": "0"
            }
        },
        {
            "hospitalName": "Sree Srinivasa Multi Speciality Hospital",
            "Contact": "8647226669",
            "avatarIcon": "SS",
            "foundUseful": "ICU:0\nOxygen:7\nGeneral:16\nVentilator:0",
            "bedsData": {
                "7997458802": "0"
            }
        },
        {
            "hospitalName": "Nuha Hospitals, Guntur",
            "Contact": "8632255559",
            "avatarIcon": "NH",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:3\nVentilator:0",
            "bedsData": {
                "9912885857": "0"
            }
        },
        {
            "hospitalName": "AREA HOSPITAL  BAPATLA",
            "Contact": "8643220077",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8712394523": "0"
            }
        },
        {
            "hospitalName": "Vunnam  Hospital",
            "Contact": "8309988422",
            "avatarIcon": "V",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9100121482": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre Prathipadu",
            "Contact": "0863 2280545\n",
            "avatarIcon": "CH",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:7\nVentilator:0",
            "bedsData": {
                "9441997757": "0"
            }
        },
        {
            "hospitalName": "Andhra Prime Hospitals, Guntur",
            "Contact": "8632222259",
            "avatarIcon": "AP",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9550587505": "0"
            }
        },
        {
            "hospitalName": "Sanjana Specialty Hospital,Sattenaplli",
            "Contact": "8641232193",
            "avatarIcon": "SS",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:1\nVentilator:0",
            "bedsData": {
                "6301445787": "0"
            }
        },
        {
            "hospitalName": "Geetha Multi Specialty Hospital, Sattenapalli",
            "Contact": "8641232320",
            "avatarIcon": "GM",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:4\nVentilator:0",
            "bedsData": {
                "7093930800": "0"
            }
        },
        {
            "hospitalName": "CHC Gurazala",
            "Contact": "9347476122",
            "avatarIcon": "CG",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:1\nVentilator:0",
            "bedsData": {
                "9100121480": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre Pedakurapadu",
            "Contact": "8640247780",
            "avatarIcon": "CH",
            "foundUseful": "ICU:0\nOxygen:9\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9440154803": "0"
            }
        },
        {
            "hospitalName": "Area Hospital Narasaraopet",
            "Contact": "7207267708",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:12\nVentilator:0",
            "bedsData": {
                "8977410878": "0"
            }
        },
        {
            "hospitalName": "Sri Sai Hospital,Vinukonda",
            "Contact": "8646275555",
            "avatarIcon": "SS",
            "foundUseful": "ICU:4\nOxygen:6\nGeneral:30\nVentilator:0",
            "bedsData": {
                "9704222504": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre,Sattenapalli",
            "Contact": "8641232213",
            "avatarIcon": "CH",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8919370019": "0"
            }
        },
        {
            "hospitalName": "Venkateswara Nursing Home,Narasaraopet",
            "Contact": "0864-7230543\n",
            "avatarIcon": "VN",
            "foundUseful": "ICU:0\nOxygen:3\nGeneral:5\nVentilator:0",
            "bedsData": {
                "8309450193": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre Vemuru",
            "Contact": "8644246941",
            "avatarIcon": "CH",
            "foundUseful": "ICU:0\nOxygen:23\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8247844347": "0"
            }
        },
        {
            "hospitalName": "Viswas Hospitals, Inner Ring Road",
            "Contact": "8632325434",
            "avatarIcon": "VH",
            "foundUseful": "ICU:0\nOxygen:7\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9848337614": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre\tPonnur",
            "Contact": "8643243388",
            "avatarIcon": "CH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9440692001": "0"
            }
        },
        {
            "hospitalName": "Abhaya Multi Speciality Hospitals",
            "Contact": "6305526433",
            "avatarIcon": "AM",
            "foundUseful": "ICU:1\nOxygen:2\nGeneral:10\nVentilator:0",
            "bedsData": {
                "7396978565": "0"
            }
        },
        {
            "hospitalName": "CHC kollipara",
            "Contact": "8644244455",
            "avatarIcon": "CK",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:14\nVentilator:0",
            "bedsData": {
                "9441235567": "0"
            }
        },
        {
            "hospitalName": "Tirumala Nursing Home, Vinukonda",
            "Contact": "8646272555",
            "avatarIcon": "TN",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9030241054": "0"
            }
        },
        {
            "hospitalName": "Community Health Center\tMacherla",
            "Contact": "7013823682",
            "avatarIcon": "CH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9133560890": "0"
            }
        },
        {
            "hospitalName": "Nikhil Hospital",
            "Contact": "8647228424",
            "avatarIcon": "NH",
            "foundUseful": "ICU:0\nOxygen:3\nGeneral:8\nVentilator:0",
            "bedsData": {
                "9000414365": "0"
            }
        },
        {
            "hospitalName": "Siva Hospital, Guntur",
            "Contact": "9030256571",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9440644026": "0"
            }
        },
        {
            "hospitalName": "Jayathi Hospitals",
            "Contact": "8125660266",
            "avatarIcon": "JH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9948946232": "0"
            }
        },
        {
            "hospitalName": "Yashaswi Hospitals",
            "Contact": "8632262255",
            "avatarIcon": "YH",
            "foundUseful": "ICU:14\nOxygen:20\nGeneral:15\nVentilator:0",
            "bedsData": {
                "8333019139": "0"
            }
        },
        {
            "hospitalName": "CHC Vinukonda",
            "Contact": "8646273273",
            "avatarIcon": "CV",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:12\nVentilator:0",
            "bedsData": {
                "7382335933": "0"
            }
        },
        {
            "hospitalName": "CHC Nizampatnam",
            "Contact": "0864-8257266\n",
            "avatarIcon": "CN",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:13\nVentilator:0",
            "bedsData": {
                "9346327594": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre, Nagaram",
            "Contact": "08648-256032\n",
            "avatarIcon": "CH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:15\nVentilator:0",
            "bedsData": {
                "9949641499": "0"
            }
        },
        {
            "hospitalName": "KIMS Hospital",
            "Contact": "8096261653",
            "avatarIcon": "KH",
            "foundUseful": "ICU:0\nOxygen:3\nGeneral:1\nVentilator:0",
            "bedsData": {
                "7901093246": "0"
            }
        },
        {
            "hospitalName": "Sai Tirumala Super Specialty Hospital",
            "Contact": "8647220229",
            "avatarIcon": "ST",
            "foundUseful": "ICU:0\nOxygen:3\nGeneral:17\nVentilator:0",
            "bedsData": {
                "9985953389": "0"
            }
        },
        {
            "hospitalName": "Puvvada Hospital",
            "Contact": "8647225464",
            "avatarIcon": "PH",
            "foundUseful": "ICU:2\nOxygen:1\nGeneral:14\nVentilator:0",
            "bedsData": {
                "9121190712": "0"
            }
        },
        {
            "hospitalName": "Varababu Hospital",
            "Contact": "8647232888",
            "avatarIcon": "VH",
            "foundUseful": "ICU:0\nOxygen:11\nGeneral:19\nVentilator:1",
            "bedsData": {
                "8886597070": "0"
            }
        },
        {
            "hospitalName": "Ammaji Pavani Memorial Hospital",
            "Contact": "0863-2446999\n",
            "avatarIcon": "AP",
            "foundUseful": "ICU:1\nOxygen:2\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9885181110": "0"
            }
        },
        {
            "hospitalName": "Health Hospitals",
            "Contact": "08644-226060\n",
            "avatarIcon": "HH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:4\nVentilator:0",
            "bedsData": {
                "9246744462": "0"
            }
        },
        {
            "hospitalName": "Aswini Hospitals, Guntur",
            "Contact": "8632227000",
            "avatarIcon": "AH",
            "foundUseful": "ICU:4\nOxygen:4\nGeneral:13\nVentilator:0",
            "bedsData": {
                "9493168154": "0"
            }
        },
        {
            "hospitalName": "Vedanta Hospitals,Guntur",
            "Contact": "9133332737",
            "avatarIcon": "VH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9885880083": "0"
            }
        },
        {
            "hospitalName": "CHC Ipuru",
            "Contact": "8646258327",
            "avatarIcon": "CI",
            "foundUseful": "ICU:0\nOxygen:7\nGeneral:15\nVentilator:0",
            "bedsData": {
                "9985499055": "0"
            }
        },
        {
            "hospitalName": "ID Hospital",
            "Contact": "8632231050",
            "avatarIcon": "IH",
            "foundUseful": "ICU:0\nOxygen:5\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9949530595": "0"
            }
        },
        {
            "hospitalName": "Kommineni Super Specialty Hospital",
            "Contact": "8647254222",
            "avatarIcon": "KS",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9441128175": "0"
            }
        },
        {
            "hospitalName": "Star Hospital, Guntur",
            "Contact": "7207366777",
            "avatarIcon": "SH",
            "foundUseful": "ICU:7\nOxygen:18\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8331056926": "0"
            }
        },
        {
            "hospitalName": "Community Health Center, Pittalavanipalem",
            "Contact": "8643258202",
            "avatarIcon": "CH",
            "foundUseful": "ICU:0\nOxygen:3\nGeneral:16\nVentilator:0",
            "bedsData": {
                "9701078188": "0"
            }
        },
        {
            "hospitalName": "Sajja Chandramouli Hospital",
            "Contact": "8643242004",
            "avatarIcon": "SC",
            "foundUseful": "ICU:0\nOxygen:3\nGeneral:2\nVentilator:0",
            "bedsData": {
                "9866215569": "0"
            }
        },
        {
            "hospitalName": "Anjireddy Hospital, Piduguralla",
            "Contact": "08649-254001\n",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:21\nVentilator:0",
            "bedsData": {
                "8331056951": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre,Repalle",
            "Contact": "7207086825",
            "avatarIcon": "CH",
            "foundUseful": "ICU:0\nOxygen:9\nGeneral:0\nVentilator:0",
            "bedsData": {
                "7901093151": "0"
            }
        },
        {
            "hospitalName": "A.V.R. Hospital",
            "Contact": "0863-2263699\n",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:7\nVentilator:2",
            "bedsData": {
                "8500726335": "0"
            }
        },
        {
            "hospitalName": "Siri Hospital",
            "Contact": "0863-2253456\n",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:3\nGeneral:9\nVentilator:0",
            "bedsData": {
                "9848184615": "0"
            }
        },
        {
            "hospitalName": "Kandimalla Specialty Hospital",
            "Contact": "8647257788",
            "avatarIcon": "KS",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9490757935": "0"
            }
        },
        {
            "hospitalName": "Amaravathi  Hospital",
            "Contact": "8632256699",
            "avatarIcon": "A",
            "foundUseful": "ICU:5\nOxygen:3\nGeneral:4\nVentilator:4",
            "bedsData": {
                "9949142415": "0"
            }
        },
        {
            "hospitalName": "District hospital Tenali",
            "Contact": "8008553549",
            "avatarIcon": "DH",
            "foundUseful": "ICU:0\nOxygen:42\nGeneral:16\nVentilator:0",
            "bedsData": {
                "9440818061": "0"
            }
        },
        {
            "hospitalName": "Lalitha Super Specialty, Guntur",
            "Contact": "0863-2228317\n",
            "avatarIcon": "LS",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:20\nVentilator:0",
            "bedsData": {
                "9849901142": "0"
            }
        },
        {
            "hospitalName": "Swathi Institute  of Medical  Science",
            "Contact": "9966552035",
            "avatarIcon": "SI",
            "foundUseful": "ICU:9\nOxygen:8\nGeneral:9\nVentilator:0",
            "bedsData": {
                "9347458830": "0"
            }
        },
        {
            "hospitalName": "Community Health Centre Chilakaluripet",
            "Contact": "08647-254433\n",
            "avatarIcon": "CH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9618331640": "0"
            }
        },
        {
            "hospitalName": "Dr. Raos Hospital",
            "Contact": "9010056444",
            "avatarIcon": "DR",
            "foundUseful": "ICU:8\nOxygen:3\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9010947770": "0"
            }
        },
        {
            "hospitalName": "MANIPAL HOSPITAL",
            "Contact": "9989614312",
            "avatarIcon": "MH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "7093883010": "0"
            }
        },
        {
            "hospitalName": "GBR Hospital, NarasaraoPeta",
            "Contact": "8647222771",
            "avatarIcon": "GH",
            "foundUseful": "ICU:23\nOxygen:31\nGeneral:20\nVentilator:0",
            "bedsData": {
                "9848474070": "0"
            }
        },
        {
            "hospitalName": "Likhitha Super Specialty Hospital",
            "Contact": "8647229900",
            "avatarIcon": "LS",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:23\nVentilator:0",
            "bedsData": {
                "9177116886": "0"
            }
        },
        {
            "hospitalName": "Uday Hospital",
            "Contact": "0863-2227719\n",
            "avatarIcon": "UH",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:6\nVentilator:0",
            "bedsData": {
                "9492555159": "0"
            }
        },
        {
            "hospitalName": "Raghavendra Hospitals",
            "Contact": "0863 2267333\n",
            "avatarIcon": "RH",
            "foundUseful": "ICU:4\nOxygen:10\nGeneral:22\nVentilator:2",
            "bedsData": {
                "9000349106": "0"
            }
        },
        {
            "hospitalName": "NIMS 24  Hospital",
            "Contact": "8646275666",
            "avatarIcon": "N2",
            "foundUseful": "ICU:2\nOxygen:2\nGeneral:19\nVentilator:0",
            "bedsData": {
                "9640343714": "0"
            }
        },
        {
            "hospitalName": "Sri Rama Hospital, Guntur",
            "Contact": "8632215137",
            "avatarIcon": "SR",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:7\nVentilator:0",
            "bedsData": {
                "9492555148": "0"
            }
        },
        {
            "hospitalName": "Mother Theresa  Hospital",
            "Contact": "7207399656",
            "avatarIcon": "MT",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:22\nVentilator:0",
            "bedsData": {
                "9618022809": "0"
            }
        },
        {
            "hospitalName": "BMR Hospitals, Guntur",
            "Contact": "0863 2241370\n",
            "avatarIcon": "BH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:11\nVentilator:0",
            "bedsData": {
                "9848779529": "0"
            }
        },
        {
            "hospitalName": "Katuri medical college",
            "Contact": "0863-2288555\n",
            "avatarIcon": "KM",
            "foundUseful": "ICU:0\nOxygen:8\nGeneral:51\nVentilator:1",
            "bedsData": {
                "8008705713": "0"
            }
        },
        {
            "hospitalName": "Blossoms Mother and Child Hospital",
            "Contact": "8632222335",
            "avatarIcon": "BM",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:9\nVentilator:0",
            "bedsData": {
                "9948345990": "0"
            }
        },
        {
            "hospitalName": "Sri Datta Hospital, Narasaraopet",
            "Contact": "08647-231309\n",
            "avatarIcon": "SD",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:1\nVentilator:0",
            "bedsData": {
                "9866183998": "0"
            }
        },
        {
            "hospitalName": "Sri Sai Hospital",
            "Contact": "8632232963",
            "avatarIcon": "SS",
            "foundUseful": "ICU:0\nOxygen:12\nGeneral:4\nVentilator:0",
            "bedsData": {
                "9493445643": "0"
            }
        },
        {
            "hospitalName": "Renuuka Hospital",
            "Contact": "0863-2269666\n",
            "avatarIcon": "RH",
            "foundUseful": "ICU:3\nOxygen:9\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9848585774": "0"
            }
        },
        {
            "hospitalName": "Amrutha Hospitals, Guntur",
            "Contact": "0863-2210123\n",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:6\nVentilator:0",
            "bedsData": {
                "7981486998": "0"
            }
        },
        {
            "hospitalName": "Viswas Hospital, Kothapet, Guntur",
            "Contact": "8632323700",
            "avatarIcon": "VH",
            "foundUseful": "ICU:3\nOxygen:6\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9440017101": "0"
            }
        },
        {
            "hospitalName": "Tulasi Multi Speciality Hospital, Kothapeta, Guntur",
            "Contact": "8632323235",
            "avatarIcon": "TM",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9492555147": "0"
            }
        },
        {
            "hospitalName": "Sri Lakshmi Hospitals",
            "Contact": "6301508027",
            "avatarIcon": "SL",
            "foundUseful": "ICU:2\nOxygen:17\nGeneral:7\nVentilator:0",
            "bedsData": {
                "9381866165": "0"
            }
        },
        {
            "hospitalName": "Sri Raghavendra Hospital",
            "Contact": "8647295916",
            "avatarIcon": "SR",
            "foundUseful": "ICU:6\nOxygen:1\nGeneral:20\nVentilator:0",
            "bedsData": {
                "8897817680": "0"
            }
        },
        {
            "hospitalName": "Mythri Multi Specialty Hospital",
            "Contact": "0863 - 2200222\n",
            "avatarIcon": "MM",
            "foundUseful": "ICU:4\nOxygen:7\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9492555149": "0"
            }
        },
        {
            "hospitalName": "GUNTUR KIDNEY",
            "Contact": "0863 2252123\n",
            "avatarIcon": "GK",
            "foundUseful": "ICU:0\nOxygen:7\nGeneral:19\nVentilator:0",
            "bedsData": {
                "8008526767": "0"
            }
        },
        {
            "hospitalName": "Sravani Hospital, Guntur",
            "Contact": "9642877979",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "7095409415": "0"
            }
        },
        {
            "hospitalName": "Pragathi Nursing Home",
            "Contact": "8647253253",
            "avatarIcon": "PN",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9100121478": "0"
            }
        },
        {
            "hospitalName": "Palnadu Hospitals, Piduguralla",
            "Contact": "08649-256060\n",
            "avatarIcon": "PH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:20\nVentilator:0",
            "bedsData": {
                "9100121483": "0"
            }
        },
        {
            "hospitalName": "Coastal Care Hospital",
            "Contact": "0863-2233377\n",
            "avatarIcon": "CC",
            "foundUseful": "ICU:4\nOxygen:13\nGeneral:13\nVentilator:0",
            "bedsData": {
                "9492555143": "0"
            }
        },
        {
            "hospitalName": "Citizen Super Specialty Hospital",
            "Contact": "9866094844",
            "avatarIcon": "CS",
            "foundUseful": "ICU:2\nOxygen:0\nGeneral:3\nVentilator:0",
            "bedsData": {
                "7386388805": "0"
            }
        },
        {
            "hospitalName": "Sri Balaji Hospitals, Guntur",
            "Contact": "7207310544",
            "avatarIcon": "SB",
            "foundUseful": "ICU:0\nOxygen:19\nGeneral:5\nVentilator:0",
            "bedsData": {
                "9966418444": "0"
            }
        },
        {
            "hospitalName": "People's Trauma Hospital, Guntur",
            "Contact": "7013594221",
            "avatarIcon": "PT",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:3\nVentilator:0",
            "bedsData": {
                "9440353708": "0"
            }
        },
        {
            "hospitalName": "Aditya Multi Specialty Hospital",
            "Contact": "0863 2220395\n",
            "avatarIcon": "AM",
            "foundUseful": "ICU:5\nOxygen:15\nGeneral:10\nVentilator:1",
            "bedsData": {
                "9440988904": "0"
            }
        },
        {
            "hospitalName": "Cherukuri Hospitals",
            "Contact": "08647-295777\n",
            "avatarIcon": "CH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:30\nVentilator:0",
            "bedsData": {
                "9032782640": "0"
            }
        },
        {
            "hospitalName": "Guntur City Hospitals",
            "Contact": "8632237337",
            "avatarIcon": "GC",
            "foundUseful": "ICU:3\nOxygen:23\nGeneral:15\nVentilator:3",
            "bedsData": {
                "7995087048": "0"
            }
        },
        {
            "hospitalName": "Vivekananda Super Speciality Hospital",
            "Contact": "8647296777",
            "avatarIcon": "VS",
            "foundUseful": "ICU:5\nOxygen:0\nGeneral:8\nVentilator:0",
            "bedsData": {
                "8331089985": "0"
            }
        }
    ],
    "CHITTOOR": [
        {
            "hospitalName": "SWETHA MULTI SPECIALITY HOSPITAL",
            "Contact": "8978349888",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9440088173": "0"
            }
        },
        {
            "hospitalName": "RAVI NEURO",
            "Contact": "7207304614",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398886974": "0"
            }
        },
        {
            "hospitalName": "AH Palamaner",
            "Contact": "9030230030",
            "avatarIcon": "AP",
            "foundUseful": "",
            "bedsData": {
                "9441959625": "0"
            }
        },
        {
            "hospitalName": "Sanjana Hospitals",
            "Contact": "0877-2238842\n",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143634459": "0"
            }
        },
        {
            "hospitalName": "SRI PRIYA NURSING HOME",
            "Contact": "08570-257136\n",
            "avatarIcon": "SP",
            "foundUseful": "",
            "bedsData": {
                "9573502710": "0"
            }
        },
        {
            "hospitalName": "CMC Vellore Chittoor Campus",
            "Contact": "8572211883",
            "avatarIcon": "CV",
            "foundUseful": "",
            "bedsData": {
                "9493951454": "0"
            }
        },
        {
            "hospitalName": "SURAKSHA HOSPITAL",
            "Contact": "8772280044",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9505953415": "0"
            }
        },
        {
            "hospitalName": "MEDIGO MULTY SPECILITY HOSPITAL",
            "Contact": "9182794746",
            "avatarIcon": "MM",
            "foundUseful": "",
            "bedsData": {
                "9440814500": "0"
            }
        },
        {
            "hospitalName": "SURENDRA HOSPITAL",
            "Contact": "7207073669",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9441443218": "0"
            }
        },
        {
            "hospitalName": "DBR  Hospital,Tirupathi",
            "Contact": "0877-2223512\n",
            "avatarIcon": "D",
            "foundUseful": "",
            "bedsData": {
                "9440818360": "0"
            }
        },
        {
            "hospitalName": "SVRRGG Hospital,CHITTOOR",
            "Contact": "9491764604",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9849909063": "0"
            }
        },
        {
            "hospitalName": "Sub divisional railway hospital,Renigunta",
            "Contact": "9701374476",
            "avatarIcon": "SD",
            "foundUseful": "",
            "bedsData": {
                "9440681361": "0"
            }
        },
        {
            "hospitalName": "Narayanadri Hospitals and Research Institute Private Ltd",
            "Contact": "8772233449",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9063188888": "0"
            }
        },
        {
            "hospitalName": "SREE RAMADEVI MULTI SUPER SPECIALITY HOSPITAL",
            "Contact": "8772287156",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9701315310": "0"
            }
        },
        {
            "hospitalName": "PRASAD HOSPITAL",
            "Contact": "8584244552",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9908890606": "0"
            }
        },
        {
            "hospitalName": "Mary Lott Lyles Hospital",
            "Contact": "8571231500",
            "avatarIcon": "ML",
            "foundUseful": "",
            "bedsData": {
                "9949599222": "0"
            }
        },
        {
            "hospitalName": "DIST HQ HOSPITAL AIMSR",
            "Contact": "08572 295911\n",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9490495698": "0"
            }
        },
        {
            "hospitalName": "RVS Hospital",
            "Contact": "7729991055",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "7330980950": "0"
            }
        },
        {
            "hospitalName": "SHUBHASHINIHOSPITAL",
            "Contact": "8577262765",
            "avatarIcon": "S",
            "foundUseful": "",
            "bedsData": {
                "9105050505": "0"
            }
        },
        {
            "hospitalName": "Madhuri Remdey Hospital",
            "Contact": "7337002143",
            "avatarIcon": "MR",
            "foundUseful": "",
            "bedsData": {
                "9642221971": "0"
            }
        },
        {
            "hospitalName": "Lotus Hospital",
            "Contact": "8772252962",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9441642505": "0"
            }
        },
        {
            "hospitalName": "ESI Hospital Tirupati",
            "Contact": "8772999010",
            "avatarIcon": "EH",
            "foundUseful": "",
            "bedsData": {
                "9849909133": "0"
            }
        },
        {
            "hospitalName": "AH SRI KALAHASTHI",
            "Contact": "08578 222530\n",
            "avatarIcon": "AS",
            "foundUseful": "",
            "bedsData": {
                "7901090457": "0"
            }
        },
        {
            "hospitalName": "ANKURA HOSPITAL",
            "Contact": "8776900205",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9667638689": "0"
            }
        },
        {
            "hospitalName": "SANKALPA SUPER SPECIALITY HOSPITAL",
            "Contact": "9550821114",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "7013181336": "0"
            }
        },
        {
            "hospitalName": "Amara Hospital",
            "Contact": "7993935777",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490504700": "0"
            }
        },
        {
            "hospitalName": "RUSSH HOSPITALS PRIVATE LIMITED",
            "Contact": "9848386464",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9030600006": "0"
            }
        },
        {
            "hospitalName": "MGM HOSPITAL",
            "Contact": "7288877300",
            "avatarIcon": "MH",
            "foundUseful": "",
            "bedsData": {
                "9490031381": "0"
            }
        },
        {
            "hospitalName": "DH MADANAPALLI",
            "Contact": "8571222087",
            "avatarIcon": "DM",
            "foundUseful": "",
            "bedsData": {
                "9010420777": "0"
            }
        },
        {
            "hospitalName": "CHANDRAMOHANS NURSING HOME",
            "Contact": "08571-222167\n",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "9533145105": "0"
            }
        },
        {
            "hospitalName": "ELITE HOSPITAL",
            "Contact": "8772251000",
            "avatarIcon": "EH",
            "foundUseful": "",
            "bedsData": {
                "8374171148": "0"
            }
        },
        {
            "hospitalName": "SURYA MULTISPECIALITY AND EMERGENCY HOSPITAL",
            "Contact": "8772280567",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9441381481": "0"
            }
        },
        {
            "hospitalName": "VISHNU SRI HOSPITAL",
            "Contact": "9502855357",
            "avatarIcon": "VS",
            "foundUseful": "",
            "bedsData": {
                "8333998676": "0"
            }
        },
        {
            "hospitalName": "SREE HOSPITAL",
            "Contact": "8772970517",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9701996025": "0"
            }
        },
        {
            "hospitalName": "SRI MARUTH SPECIALITY HOSPITAL",
            "Contact": "8096472077",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "7799353387": "0"
            }
        },
        {
            "hospitalName": "S.V.AYURVEDA HOSPITAL",
            "Contact": "8772264630",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8333902509": "0"
            }
        },
        {
            "hospitalName": "AH KUPPAM",
            "Contact": "8570255011",
            "avatarIcon": "AK",
            "foundUseful": "",
            "bedsData": {
                "9491920918": "0"
            }
        },
        {
            "hospitalName": "CITY CARE HOSPITAL",
            "Contact": "7386755512",
            "avatarIcon": "CC",
            "foundUseful": "",
            "bedsData": {
                "8074244381": "0"
            }
        },
        {
            "hospitalName": "PES Institute of Medical Sceinces",
            "Contact": "8570277722",
            "avatarIcon": "PI",
            "foundUseful": "",
            "bedsData": {
                "9494367910": "0"
            }
        },
        {
            "hospitalName": "Sr Padmavathi Hospital Tirupati",
            "Contact": "8772287777",
            "avatarIcon": "SP",
            "foundUseful": "",
            "bedsData": {
                "7702091111": "0"
            }
        },
        {
            "hospitalName": "SWETHA MULTI SPECIALITY HOSPITAL",
            "Contact": "\n",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9440088173": "0"
            }
        },
        {
            "hospitalName": "RAVI NEURO",
            "Contact": "\n",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9182242445": "0"
            }
        },
        {
            "hospitalName": "AH Palamaner",
            "Contact": "9030230030",
            "avatarIcon": "AP",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:15\nVentilator:0",
            "bedsData": {
                "9441959625": "0"
            }
        },
        {
            "hospitalName": "Sanjana Hospitals",
            "Contact": "0877-2238842\n",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:3\nVentilator:0",
            "bedsData": {
                "9985376225": "0"
            }
        },
        {
            "hospitalName": "SRI PRIYA NURSING HOME",
            "Contact": "08570-257136\n",
            "avatarIcon": "SP",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9573502710": "0"
            }
        },
        {
            "hospitalName": "CMC Vellore Chittoor Campus",
            "Contact": "8572211883",
            "avatarIcon": "CV",
            "foundUseful": "ICU:0\nOxygen:3\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9493951454": "0"
            }
        },
        {
            "hospitalName": "SURAKSHA HOSPITAL",
            "Contact": "9652661486",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:10\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9505953415": "0"
            }
        },
        {
            "hospitalName": "MEDIGO MULTY SPECILITY HOSPITAL",
            "Contact": "9182794746",
            "avatarIcon": "MM",
            "foundUseful": "ICU:0\nOxygen:3\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8099491212": "0"
            }
        },
        {
            "hospitalName": "SURENDRA HOSPITAL",
            "Contact": "7207073669",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:3\nGeneral:7\nVentilator:0",
            "bedsData": {
                "9441443218": "0"
            }
        },
        {
            "hospitalName": "DBR",
            "Contact": "0877-2223512\n",
            "avatarIcon": "D",
            "foundUseful": "ICU:3\nOxygen:2\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9440818360": "0"
            }
        },
        {
            "hospitalName": "SVRRGG Hospital",
            "Contact": "9491764604",
            "avatarIcon": "SH",
            "foundUseful": "ICU:3\nOxygen:28\nGeneral:93\nVentilator:0",
            "bedsData": {
                "9849909063": "0"
            }
        },
        {
            "hospitalName": "BALAJI ORTHOPAEDIC",
            "Contact": "8772222700",
            "avatarIcon": "BO",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:21\nVentilator:0",
            "bedsData": {
                "8921983050": "0"
            }
        },
        {
            "hospitalName": "MODERN HOSPITAL",
            "Contact": "8571796052",
            "avatarIcon": "MH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9490820468": "0"
            }
        },
        {
            "hospitalName": "Sub divisional railway hospital,Renigunta",
            "Contact": "9701374476",
            "avatarIcon": "SD",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9701374492": "0"
            }
        },
        {
            "hospitalName": "Narayanadri Hospitals and Research Institute Private Ltd",
            "Contact": "8772233449",
            "avatarIcon": "NH",
            "foundUseful": "ICU:0\nOxygen:38\nGeneral:6\nVentilator:0",
            "bedsData": {
                "9063188888": "0"
            }
        },
        {
            "hospitalName": "SREE RAMADEVI MULTI SUPER SPECIALITY HOSPITAL",
            "Contact": "8772287156",
            "avatarIcon": "SR",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:34\nVentilator:0",
            "bedsData": {
                "9701315310": "0"
            }
        },
        {
            "hospitalName": "PRASAD HOSPITAL",
            "Contact": "8584244552",
            "avatarIcon": "PH",
            "foundUseful": "ICU:0\nOxygen:4\nGeneral:19\nVentilator:0",
            "bedsData": {
                "9908890606": "0"
            }
        },
        {
            "hospitalName": "Mary Lott Lyles Hospital",
            "Contact": "8571231500",
            "avatarIcon": "ML",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:7\nVentilator:0",
            "bedsData": {
                "9949599222": "0"
            }
        },
        {
            "hospitalName": "DIST HQ HOSPITAL AIMSR",
            "Contact": "8572295911",
            "avatarIcon": "DH",
            "foundUseful": "ICU:8\nOxygen:96\nGeneral:210\nVentilator:0",
            "bedsData": {
                "9490495698": "0"
            }
        },
        {
            "hospitalName": "RVS Hospital",
            "Contact": "7729991055",
            "avatarIcon": "RH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:3\nVentilator:0",
            "bedsData": {
                "7995086784": "0"
            }
        },
        {
            "hospitalName": "SHUBHASHINIHOSPITAL",
            "Contact": "8577262765",
            "avatarIcon": "S",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9105050505": "0"
            }
        },
        {
            "hospitalName": "Madhuri Remdey Hospital",
            "Contact": "7337002143",
            "avatarIcon": "MR",
            "foundUseful": "ICU:0\nOxygen:9\nGeneral:10\nVentilator:0",
            "bedsData": {
                "964221971": "0"
            }
        },
        {
            "hospitalName": "Lotus Hospital",
            "Contact": "8772252962",
            "avatarIcon": "LH",
            "foundUseful": "ICU:0\nOxygen:15\nGeneral:25\nVentilator:0",
            "bedsData": {
                "9441642505": "0"
            }
        },
        {
            "hospitalName": "OBULAM HOSPITAL",
            "Contact": "0877-2224994\n",
            "avatarIcon": "OH",
            "foundUseful": "ICU:2\nOxygen:1\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9440818952": "0"
            }
        },
        {
            "hospitalName": "ESI Hospital Tirupati",
            "Contact": "8772999010",
            "avatarIcon": "EH",
            "foundUseful": "ICU:0\nOxygen:11\nGeneral:74\nVentilator:0",
            "bedsData": {
                "9849909133": "0"
            }
        },
        {
            "hospitalName": "AH SRI KALAHASTHI",
            "Contact": "08578 222530\n",
            "avatarIcon": "AS",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:5\nVentilator:0",
            "bedsData": {
                "7901090457": "0"
            }
        },
        {
            "hospitalName": "ANKURA HOSPITAL",
            "Contact": "8776900205",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9667638689": "0"
            }
        },
        {
            "hospitalName": "SANKALPA SUPER SPECIALITY HOSPITAL",
            "Contact": "9550821114",
            "avatarIcon": "SS",
            "foundUseful": "ICU:1\nOxygen:1\nGeneral:6\nVentilator:0",
            "bedsData": {
                "7013181336": "0"
            }
        },
        {
            "hospitalName": "Amara Hospital",
            "Contact": "7993935777",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:5\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9490504700": "0"
            }
        },
        {
            "hospitalName": "RUSSH HOSPITALS PRIVATE LIMITED",
            "Contact": "9848386464",
            "avatarIcon": "RH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:34\nVentilator:0",
            "bedsData": {
                "9494739297": "0"
            }
        },
        {
            "hospitalName": "MGM HOSPITAL",
            "Contact": "7288877300",
            "avatarIcon": "MH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8074084455": "0"
            }
        },
        {
            "hospitalName": "DR KRISHNAS LIFE HOSPITAL",
            "Contact": "0877-2240888\n",
            "avatarIcon": "DK",
            "foundUseful": "ICU:1\nOxygen:8\nGeneral:8\nVentilator:0",
            "bedsData": {
                "9885877840": "0"
            }
        },
        {
            "hospitalName": "DH MADANAPALLI",
            "Contact": "8571222087",
            "avatarIcon": "DM",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9010420777": "0"
            }
        },
        {
            "hospitalName": "CHANDRAMOHANS NURSING HOME",
            "Contact": "08571-222167\n",
            "avatarIcon": "CN",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:1\nVentilator:0",
            "bedsData": {
                "9550182948": "0"
            }
        },
        {
            "hospitalName": "KR HOSPITAL",
            "Contact": "8772231999",
            "avatarIcon": "KH",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9030836726": "0"
            }
        },
        {
            "hospitalName": "SRI CHAKRA HOSPITAL",
            "Contact": "8074371853",
            "avatarIcon": "SC",
            "foundUseful": "ICU:5\nOxygen:13\nGeneral:18\nVentilator:0",
            "bedsData": {
                "7995337933": "0"
            }
        },
        {
            "hospitalName": "ELITE HOSPITAL",
            "Contact": "8772251000",
            "avatarIcon": "EH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:10\nVentilator:0",
            "bedsData": {
                "8374171148": "0"
            }
        },
        {
            "hospitalName": "SURYA MULTISPECIALITY AND EMERGENCY HOSPITAL",
            "Contact": "8772280567",
            "avatarIcon": "SM",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:7\nVentilator:0",
            "bedsData": {
                "9000402989": "0"
            }
        },
        {
            "hospitalName": "VISHNU SRI HOSPITAL",
            "Contact": "9502855357",
            "avatarIcon": "VS",
            "foundUseful": "ICU:8\nOxygen:5\nGeneral:13\nVentilator:0",
            "bedsData": {
                "8333998676": "0"
            }
        },
        {
            "hospitalName": "SREE HOSPITAL",
            "Contact": "8772970517",
            "avatarIcon": "SH",
            "foundUseful": "ICU:2\nOxygen:12\nGeneral:9\nVentilator:0",
            "bedsData": {
                "9441443218": "0"
            }
        },
        {
            "hospitalName": "SRI MARUTH SPECIALITY HOSPITAL",
            "Contact": "8096472077",
            "avatarIcon": "SM",
            "foundUseful": "ICU:0\nOxygen:11\nGeneral:5\nVentilator:0",
            "bedsData": {
                "9493409875": "0"
            }
        },
        {
            "hospitalName": "S.V.AYURVEDA HOSPITAL",
            "Contact": "8772264630",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:4\nGeneral:8\nVentilator:0",
            "bedsData": {
                "8333902509": "0"
            }
        },
        {
            "hospitalName": "AH KUPPAM",
            "Contact": "8570255011",
            "avatarIcon": "AK",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9491920918": "0"
            }
        },
        {
            "hospitalName": "CITY CARE HOSPITAL",
            "Contact": "7386755512",
            "avatarIcon": "CC",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:3\nVentilator:0",
            "bedsData": {
                "8074244381": "0"
            }
        },
        {
            "hospitalName": "PES Institute of Medical Sceinces",
            "Contact": "8570277722",
            "avatarIcon": "PI",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:255\nVentilator:0",
            "bedsData": {
                "6301068429": "0"
            }
        },
        {
            "hospitalName": "Sr Padmavathi Hospital Tirupati",
            "Contact": "8772287777",
            "avatarIcon": "SP",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:104\nVentilator:0",
            "bedsData": {
                "7702091111": "0"
            }
        }
    ],
    "VIZIANAGARAM": [
        {
            "hospitalName": "SWETHA MULTI SPECIALITY HOSPITAL",
            "Contact": "8978349888",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9440088173": "0"
            }
        },
        {
            "hospitalName": "RAVI NEURO",
            "Contact": "7207304614",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398886974": "0"
            }
        },
        {
            "hospitalName": "AH Palamaner",
            "Contact": "9030230030",
            "avatarIcon": "AP",
            "foundUseful": "",
            "bedsData": {
                "9441959625": "0"
            }
        },
        {
            "hospitalName": "Sanjana Hospitals",
            "Contact": "0877-2238842\n",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143634459": "0"
            }
        },
        {
            "hospitalName": "SRI PRIYA NURSING HOME",
            "Contact": "08570-257136\n",
            "avatarIcon": "SP",
            "foundUseful": "",
            "bedsData": {
                "9573502710": "0"
            }
        },
        {
            "hospitalName": "CMC Vellore Chittoor Campus",
            "Contact": "8572211883",
            "avatarIcon": "CV",
            "foundUseful": "",
            "bedsData": {
                "9493951454": "0"
            }
        },
        {
            "hospitalName": "SURAKSHA HOSPITAL",
            "Contact": "8772280044",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9505953415": "0"
            }
        },
        {
            "hospitalName": "MEDIGO MULTY SPECILITY HOSPITAL",
            "Contact": "9182794746",
            "avatarIcon": "MM",
            "foundUseful": "",
            "bedsData": {
                "9440814500": "0"
            }
        },
        {
            "hospitalName": "SURENDRA HOSPITAL",
            "Contact": "7207073669",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9441443218": "0"
            }
        },
        {
            "hospitalName": "DBR  Hospital,Tirupathi",
            "Contact": "0877-2223512\n",
            "avatarIcon": "D",
            "foundUseful": "",
            "bedsData": {
                "9440818360": "0"
            }
        },
        {
            "hospitalName": "SVRRGG Hospital,CHITTOOR",
            "Contact": "9491764604",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9849909063": "0"
            }
        },
        {
            "hospitalName": "Sub divisional railway hospital,Renigunta",
            "Contact": "9701374476",
            "avatarIcon": "SD",
            "foundUseful": "",
            "bedsData": {
                "9440681361": "0"
            }
        },
        {
            "hospitalName": "Narayanadri Hospitals and Research Institute Private Ltd",
            "Contact": "8772233449",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9063188888": "0"
            }
        },
        {
            "hospitalName": "SREE RAMADEVI MULTI SUPER SPECIALITY HOSPITAL",
            "Contact": "8772287156",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9701315310": "0"
            }
        },
        {
            "hospitalName": "PRASAD HOSPITAL",
            "Contact": "8584244552",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9908890606": "0"
            }
        },
        {
            "hospitalName": "Mary Lott Lyles Hospital",
            "Contact": "8571231500",
            "avatarIcon": "ML",
            "foundUseful": "",
            "bedsData": {
                "9949599222": "0"
            }
        },
        {
            "hospitalName": "DIST HQ HOSPITAL AIMSR",
            "Contact": "08572 295911\n",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9490495698": "0"
            }
        },
        {
            "hospitalName": "RVS Hospital",
            "Contact": "7729991055",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "7330980950": "0"
            }
        },
        {
            "hospitalName": "SHUBHASHINIHOSPITAL",
            "Contact": "8577262765",
            "avatarIcon": "S",
            "foundUseful": "",
            "bedsData": {
                "9105050505": "0"
            }
        },
        {
            "hospitalName": "Madhuri Remdey Hospital",
            "Contact": "7337002143",
            "avatarIcon": "MR",
            "foundUseful": "",
            "bedsData": {
                "9642221971": "0"
            }
        },
        {
            "hospitalName": "Lotus Hospital",
            "Contact": "8772252962",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9441642505": "0"
            }
        },
        {
            "hospitalName": "ESI Hospital Tirupati",
            "Contact": "8772999010",
            "avatarIcon": "EH",
            "foundUseful": "",
            "bedsData": {
                "9849909133": "0"
            }
        },
        {
            "hospitalName": "AH SRI KALAHASTHI",
            "Contact": "08578 222530\n",
            "avatarIcon": "AS",
            "foundUseful": "",
            "bedsData": {
                "7901090457": "0"
            }
        },
        {
            "hospitalName": "ANKURA HOSPITAL",
            "Contact": "8776900205",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9667638689": "0"
            }
        },
        {
            "hospitalName": "SANKALPA SUPER SPECIALITY HOSPITAL",
            "Contact": "9550821114",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "7013181336": "0"
            }
        },
        {
            "hospitalName": "Amara Hospital",
            "Contact": "7993935777",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490504700": "0"
            }
        },
        {
            "hospitalName": "RUSSH HOSPITALS PRIVATE LIMITED",
            "Contact": "9848386464",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9030600006": "0"
            }
        },
        {
            "hospitalName": "MGM HOSPITAL",
            "Contact": "7288877300",
            "avatarIcon": "MH",
            "foundUseful": "",
            "bedsData": {
                "9490031381": "0"
            }
        },
        {
            "hospitalName": "DH MADANAPALLI",
            "Contact": "8571222087",
            "avatarIcon": "DM",
            "foundUseful": "",
            "bedsData": {
                "9010420777": "0"
            }
        },
        {
            "hospitalName": "CHANDRAMOHANS NURSING HOME",
            "Contact": "08571-222167\n",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "9533145105": "0"
            }
        },
        {
            "hospitalName": "ELITE HOSPITAL",
            "Contact": "8772251000",
            "avatarIcon": "EH",
            "foundUseful": "",
            "bedsData": {
                "8374171148": "0"
            }
        },
        {
            "hospitalName": "SURYA MULTISPECIALITY AND EMERGENCY HOSPITAL",
            "Contact": "8772280567",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9441381481": "0"
            }
        },
        {
            "hospitalName": "VISHNU SRI HOSPITAL",
            "Contact": "9502855357",
            "avatarIcon": "VS",
            "foundUseful": "",
            "bedsData": {
                "8333998676": "0"
            }
        },
        {
            "hospitalName": "SREE HOSPITAL",
            "Contact": "8772970517",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9701996025": "0"
            }
        },
        {
            "hospitalName": "SRI MARUTH SPECIALITY HOSPITAL",
            "Contact": "8096472077",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "7799353387": "0"
            }
        },
        {
            "hospitalName": "S.V.AYURVEDA HOSPITAL",
            "Contact": "8772264630",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8333902509": "0"
            }
        },
        {
            "hospitalName": "AH KUPPAM",
            "Contact": "8570255011",
            "avatarIcon": "AK",
            "foundUseful": "",
            "bedsData": {
                "9491920918": "0"
            }
        },
        {
            "hospitalName": "CITY CARE HOSPITAL",
            "Contact": "7386755512",
            "avatarIcon": "CC",
            "foundUseful": "",
            "bedsData": {
                "8074244381": "0"
            }
        },
        {
            "hospitalName": "PES Institute of Medical Sceinces",
            "Contact": "8570277722",
            "avatarIcon": "PI",
            "foundUseful": "",
            "bedsData": {
                "9494367910": "0"
            }
        },
        {
            "hospitalName": "Sr Padmavathi Hospital Tirupati",
            "Contact": "8772287777",
            "avatarIcon": "SP",
            "foundUseful": "",
            "bedsData": {
                "7702091111": "0"
            }
        },
        {
            "hospitalName": "SWETHA MULTI SPECIALITY HOSPITAL",
            "Contact": "\n",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9440088173": "0"
            }
        },
        {
            "hospitalName": "RAVI NEURO",
            "Contact": "\n",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9182242445": "0"
            }
        },
        {
            "hospitalName": "AH Palamaner",
            "Contact": "9030230030",
            "avatarIcon": "AP",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:15\nVentilator:0",
            "bedsData": {
                "9441959625": "0"
            }
        },
        {
            "hospitalName": "Sanjana Hospitals",
            "Contact": "0877-2238842\n",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:3\nVentilator:0",
            "bedsData": {
                "9985376225": "0"
            }
        },
        {
            "hospitalName": "SRI PRIYA NURSING HOME",
            "Contact": "08570-257136\n",
            "avatarIcon": "SP",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9573502710": "0"
            }
        },
        {
            "hospitalName": "CMC Vellore Chittoor Campus",
            "Contact": "8572211883",
            "avatarIcon": "CV",
            "foundUseful": "ICU:0\nOxygen:3\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9493951454": "0"
            }
        },
        {
            "hospitalName": "SURAKSHA HOSPITAL",
            "Contact": "9652661486",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:10\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9505953415": "0"
            }
        },
        {
            "hospitalName": "MEDIGO MULTY SPECILITY HOSPITAL",
            "Contact": "9182794746",
            "avatarIcon": "MM",
            "foundUseful": "ICU:0\nOxygen:3\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8099491212": "0"
            }
        },
        {
            "hospitalName": "SURENDRA HOSPITAL",
            "Contact": "7207073669",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:3\nGeneral:7\nVentilator:0",
            "bedsData": {
                "9441443218": "0"
            }
        },
        {
            "hospitalName": "DBR",
            "Contact": "0877-2223512\n",
            "avatarIcon": "D",
            "foundUseful": "ICU:3\nOxygen:2\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9440818360": "0"
            }
        },
        {
            "hospitalName": "SVRRGG Hospital",
            "Contact": "9491764604",
            "avatarIcon": "SH",
            "foundUseful": "ICU:3\nOxygen:28\nGeneral:93\nVentilator:0",
            "bedsData": {
                "9849909063": "0"
            }
        },
        {
            "hospitalName": "BALAJI ORTHOPAEDIC",
            "Contact": "8772222700",
            "avatarIcon": "BO",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:21\nVentilator:0",
            "bedsData": {
                "8921983050": "0"
            }
        },
        {
            "hospitalName": "MODERN HOSPITAL",
            "Contact": "8571796052",
            "avatarIcon": "MH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9490820468": "0"
            }
        },
        {
            "hospitalName": "Sub divisional railway hospital,Renigunta",
            "Contact": "9701374476",
            "avatarIcon": "SD",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9701374492": "0"
            }
        },
        {
            "hospitalName": "Narayanadri Hospitals and Research Institute Private Ltd",
            "Contact": "8772233449",
            "avatarIcon": "NH",
            "foundUseful": "ICU:0\nOxygen:38\nGeneral:6\nVentilator:0",
            "bedsData": {
                "9063188888": "0"
            }
        },
        {
            "hospitalName": "SREE RAMADEVI MULTI SUPER SPECIALITY HOSPITAL",
            "Contact": "8772287156",
            "avatarIcon": "SR",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:34\nVentilator:0",
            "bedsData": {
                "9701315310": "0"
            }
        },
        {
            "hospitalName": "PRASAD HOSPITAL",
            "Contact": "8584244552",
            "avatarIcon": "PH",
            "foundUseful": "ICU:0\nOxygen:4\nGeneral:19\nVentilator:0",
            "bedsData": {
                "9908890606": "0"
            }
        },
        {
            "hospitalName": "Mary Lott Lyles Hospital",
            "Contact": "8571231500",
            "avatarIcon": "ML",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:7\nVentilator:0",
            "bedsData": {
                "9949599222": "0"
            }
        },
        {
            "hospitalName": "DIST HQ HOSPITAL AIMSR",
            "Contact": "8572295911",
            "avatarIcon": "DH",
            "foundUseful": "ICU:8\nOxygen:96\nGeneral:210\nVentilator:0",
            "bedsData": {
                "9490495698": "0"
            }
        },
        {
            "hospitalName": "RVS Hospital",
            "Contact": "7729991055",
            "avatarIcon": "RH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:3\nVentilator:0",
            "bedsData": {
                "7995086784": "0"
            }
        },
        {
            "hospitalName": "SHUBHASHINIHOSPITAL",
            "Contact": "8577262765",
            "avatarIcon": "S",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9105050505": "0"
            }
        },
        {
            "hospitalName": "Madhuri Remdey Hospital",
            "Contact": "7337002143",
            "avatarIcon": "MR",
            "foundUseful": "ICU:0\nOxygen:9\nGeneral:10\nVentilator:0",
            "bedsData": {
                "964221971": "0"
            }
        },
        {
            "hospitalName": "Lotus Hospital",
            "Contact": "8772252962",
            "avatarIcon": "LH",
            "foundUseful": "ICU:0\nOxygen:15\nGeneral:25\nVentilator:0",
            "bedsData": {
                "9441642505": "0"
            }
        },
        {
            "hospitalName": "OBULAM HOSPITAL",
            "Contact": "0877-2224994\n",
            "avatarIcon": "OH",
            "foundUseful": "ICU:2\nOxygen:1\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9440818952": "0"
            }
        },
        {
            "hospitalName": "ESI Hospital Tirupati",
            "Contact": "8772999010",
            "avatarIcon": "EH",
            "foundUseful": "ICU:0\nOxygen:11\nGeneral:74\nVentilator:0",
            "bedsData": {
                "9849909133": "0"
            }
        },
        {
            "hospitalName": "AH SRI KALAHASTHI",
            "Contact": "08578 222530\n",
            "avatarIcon": "AS",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:5\nVentilator:0",
            "bedsData": {
                "7901090457": "0"
            }
        },
        {
            "hospitalName": "ANKURA HOSPITAL",
            "Contact": "8776900205",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9667638689": "0"
            }
        },
        {
            "hospitalName": "SANKALPA SUPER SPECIALITY HOSPITAL",
            "Contact": "9550821114",
            "avatarIcon": "SS",
            "foundUseful": "ICU:1\nOxygen:1\nGeneral:6\nVentilator:0",
            "bedsData": {
                "7013181336": "0"
            }
        },
        {
            "hospitalName": "Amara Hospital",
            "Contact": "7993935777",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:5\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9490504700": "0"
            }
        },
        {
            "hospitalName": "RUSSH HOSPITALS PRIVATE LIMITED",
            "Contact": "9848386464",
            "avatarIcon": "RH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:34\nVentilator:0",
            "bedsData": {
                "9494739297": "0"
            }
        },
        {
            "hospitalName": "MGM HOSPITAL",
            "Contact": "7288877300",
            "avatarIcon": "MH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8074084455": "0"
            }
        },
        {
            "hospitalName": "DR KRISHNAS LIFE HOSPITAL",
            "Contact": "0877-2240888\n",
            "avatarIcon": "DK",
            "foundUseful": "ICU:1\nOxygen:8\nGeneral:8\nVentilator:0",
            "bedsData": {
                "9885877840": "0"
            }
        },
        {
            "hospitalName": "DH MADANAPALLI",
            "Contact": "8571222087",
            "avatarIcon": "DM",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9010420777": "0"
            }
        },
        {
            "hospitalName": "CHANDRAMOHANS NURSING HOME",
            "Contact": "08571-222167\n",
            "avatarIcon": "CN",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:1\nVentilator:0",
            "bedsData": {
                "9550182948": "0"
            }
        },
        {
            "hospitalName": "KR HOSPITAL",
            "Contact": "8772231999",
            "avatarIcon": "KH",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9030836726": "0"
            }
        },
        {
            "hospitalName": "SRI CHAKRA HOSPITAL",
            "Contact": "8074371853",
            "avatarIcon": "SC",
            "foundUseful": "ICU:5\nOxygen:13\nGeneral:18\nVentilator:0",
            "bedsData": {
                "7995337933": "0"
            }
        },
        {
            "hospitalName": "ELITE HOSPITAL",
            "Contact": "8772251000",
            "avatarIcon": "EH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:10\nVentilator:0",
            "bedsData": {
                "8374171148": "0"
            }
        },
        {
            "hospitalName": "SURYA MULTISPECIALITY AND EMERGENCY HOSPITAL",
            "Contact": "8772280567",
            "avatarIcon": "SM",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:7\nVentilator:0",
            "bedsData": {
                "9000402989": "0"
            }
        },
        {
            "hospitalName": "VISHNU SRI HOSPITAL",
            "Contact": "9502855357",
            "avatarIcon": "VS",
            "foundUseful": "ICU:8\nOxygen:5\nGeneral:13\nVentilator:0",
            "bedsData": {
                "8333998676": "0"
            }
        },
        {
            "hospitalName": "SREE HOSPITAL",
            "Contact": "8772970517",
            "avatarIcon": "SH",
            "foundUseful": "ICU:2\nOxygen:12\nGeneral:9\nVentilator:0",
            "bedsData": {
                "9441443218": "0"
            }
        },
        {
            "hospitalName": "SRI MARUTH SPECIALITY HOSPITAL",
            "Contact": "8096472077",
            "avatarIcon": "SM",
            "foundUseful": "ICU:0\nOxygen:11\nGeneral:5\nVentilator:0",
            "bedsData": {
                "9493409875": "0"
            }
        },
        {
            "hospitalName": "S.V.AYURVEDA HOSPITAL",
            "Contact": "8772264630",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:4\nGeneral:8\nVentilator:0",
            "bedsData": {
                "8333902509": "0"
            }
        },
        {
            "hospitalName": "AH KUPPAM",
            "Contact": "8570255011",
            "avatarIcon": "AK",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9491920918": "0"
            }
        },
        {
            "hospitalName": "CITY CARE HOSPITAL",
            "Contact": "7386755512",
            "avatarIcon": "CC",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:3\nVentilator:0",
            "bedsData": {
                "8074244381": "0"
            }
        },
        {
            "hospitalName": "PES Institute of Medical Sceinces",
            "Contact": "8570277722",
            "avatarIcon": "PI",
            "foundUseful": "ICU:0\nOxygen:2\nGeneral:255\nVentilator:0",
            "bedsData": {
                "6301068429": "0"
            }
        },
        {
            "hospitalName": "Sr Padmavathi Hospital Tirupati",
            "Contact": "8772287777",
            "avatarIcon": "SP",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:104\nVentilator:0",
            "bedsData": {
                "7702091111": "0"
            }
        }
    ],
    "EAST GODAVARI": [
        {
            "hospitalName": "MAGNA MOTHER",
            "Contact": "\n",
            "avatarIcon": "MM",
            "foundUseful": "",
            "bedsData": {
                "9959631111": "0"
            }
        },
        {
            "hospitalName": "CDR HOSPITAL",
            "Contact": "8857226456",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "8790466950": "0"
            }
        },
        {
            "hospitalName": "SATHAYU KIDENY",
            "Contact": "8832555777",
            "avatarIcon": "SK",
            "foundUseful": "",
            "bedsData": {
                "9492259993": "0"
            }
        },
        {
            "hospitalName": "VENNELA CHILDRENS AND MUTLIPSECIALITY HOSPITAL",
            "Contact": "9032715998",
            "avatarIcon": "VC",
            "foundUseful": "",
            "bedsData": {
                "8341878045": "0"
            }
        },
        {
            "hospitalName": "ARK MULTISPECIALITY HOSPITAL",
            "Contact": "8837963493",
            "avatarIcon": "AM",
            "foundUseful": "",
            "bedsData": {
                "8008356493": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Dr. Veerraju Nursing Home",
            "Contact": "0884-2383636\n",
            "avatarIcon": "DV",
            "foundUseful": "",
            "bedsData": {
                "7382838485": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "9666408705",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9177488831": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302685",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8500005418": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "9912561643",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "DR BRM CAREWELL HOSPITAL",
            "Contact": "\n",
            "avatarIcon": "DB",
            "foundUseful": "",
            "bedsData": {
                "8074781347": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA(EAST GODAVARI)",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9182293871",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9441747346": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "9441747346": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9440164231": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356002",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "7416456635",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada(CHITTOOR)",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "",
            "bedsData": {
                "9849002974": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "7382122200": "0"
            }
        },
        {
            "hospitalName": "SATHAYU KIDENY",
            "Contact": "8832555777",
            "avatarIcon": "SK",
            "foundUseful": "",
            "bedsData": {
                "9492259993": "0"
            }
        },
        {
            "hospitalName": "VENNELA CHILDRENS AND MUTLIPSECIALITY HOSPITAL",
            "Contact": "9032715998",
            "avatarIcon": "VC",
            "foundUseful": "",
            "bedsData": {
                "8341878045": "0"
            }
        },
        {
            "hospitalName": "ARK MULTISPECIALITY HOSPITAL",
            "Contact": "8837963493",
            "avatarIcon": "AM",
            "foundUseful": "",
            "bedsData": {
                "8008356493": "0"
            }
        },
        {
            "hospitalName": "DAY",
            "Contact": "8834014921",
            "avatarIcon": "D",
            "foundUseful": "",
            "bedsData": {
                "9490340346": "0"
            }
        },
        {
            "hospitalName": "SARADHI HOSPITALS (Emergency",
            "Contact": "0883-2476887\n",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9959849990": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Dr. Veerraju Nursing Home",
            "Contact": "0884-2383636\n",
            "avatarIcon": "DV",
            "foundUseful": "",
            "bedsData": {
                "7382838485": "0"
            }
        },
        {
            "hospitalName": "Sri Akshara Hospital Kakinada",
            "Contact": "9949417141",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "8099944354": "0"
            }
        },
        {
            "hospitalName": "SRI HARI HOSPITAL",
            "Contact": "8464882882",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143047819": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "RS NEURO HOSPITAL",
            "Contact": "8832474744",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9848067068": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "SRUJANA MULTISPECIALITY HOSPITAL",
            "Contact": "8842361669",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9911603937": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "REVATHI HOSPITAL",
            "Contact": "8832491849",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9000826680": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "9666408705",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9949134963": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302600",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9885386671": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "9912561643",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "NARAYANAREDDY HOSPITALS",
            "Contact": "8855232123",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9849720763": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "Aruna Hospital",
            "Contact": "8842361599",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7997990181": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "PULSE EMERGENCY HOSPITAL",
            "Contact": "8842348889",
            "avatarIcon": "PE",
            "foundUseful": "",
            "bedsData": {
                "9963243005": "0"
            }
        },
        {
            "hospitalName": "CITY HOSPITAL",
            "Contact": "7331112105",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9701740676": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "RAJAHMUNDRY ORTHOPEDIC HOSPITAL",
            "Contact": "8832468110",
            "avatarIcon": "RO",
            "foundUseful": "",
            "bedsData": {
                "7396245369": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA(EAST GODAVARI)",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "Sri Ravi Hospitals",
            "Contact": "9866255678",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9989778821": "0"
            }
        },
        {
            "hospitalName": "SURAREDDY NURSING HOME",
            "Contact": "8857233454",
            "avatarIcon": "SN",
            "foundUseful": "",
            "bedsData": {
                "7095901789": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9182293871",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9182077956": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "918074542890": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9346633478": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356001",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Universal Hospital rajahmundry",
            "Contact": "8832555544",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "8499022777": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "-\n",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada(CHITTOOR)",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "",
            "bedsData": {
                "8317662551": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "PRIME HOSPITALS",
            "Contact": "7075623517",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9395183463": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9949923899": "0"
            }
        },
        {
            "hospitalName": "VENNELA CHILDRENS AND MUTLIPSECIALITY HOSPITAL",
            "Contact": "9032715998",
            "avatarIcon": "VC",
            "foundUseful": "",
            "bedsData": {
                "8341878045": "0"
            }
        },
        {
            "hospitalName": "ARK MULTISPECIALITY HOSPITAL",
            "Contact": "8837963493",
            "avatarIcon": "AM",
            "foundUseful": "",
            "bedsData": {
                "8008356493": "0"
            }
        },
        {
            "hospitalName": "DAY",
            "Contact": "8834014921",
            "avatarIcon": "D",
            "foundUseful": "",
            "bedsData": {
                "9490340346": "0"
            }
        },
        {
            "hospitalName": "SARADHI HOSPITALS (Emergency",
            "Contact": "0883-2476887\n",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9959849990": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Dr. Veerraju Nursing Home",
            "Contact": "0884-2383636\n",
            "avatarIcon": "DV",
            "foundUseful": "",
            "bedsData": {
                "7382838485": "0"
            }
        },
        {
            "hospitalName": "Sri Akshara Hospital Kakinada",
            "Contact": "9949417141",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "8099944354": "0"
            }
        },
        {
            "hospitalName": "SRI HARI HOSPITAL",
            "Contact": "8464882882",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143047819": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "RS NEURO HOSPITAL",
            "Contact": "8832474744",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9848067068": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "SRUJANA MULTISPECIALITY HOSPITAL",
            "Contact": "8842361669",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9911603937": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "REVATHI HOSPITAL",
            "Contact": "8832491849",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9000826680": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "9666408705",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9949134963": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302600",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9885386671": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "9912561643",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "NARAYANAREDDY HOSPITALS",
            "Contact": "8855232123",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9849720763": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "Aruna Hospital",
            "Contact": "8842361599",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7997990181": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "PULSE EMERGENCY HOSPITAL",
            "Contact": "8842348889",
            "avatarIcon": "PE",
            "foundUseful": "",
            "bedsData": {
                "9963243005": "0"
            }
        },
        {
            "hospitalName": "CITY HOSPITAL",
            "Contact": "7331112105",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9701740676": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "RAJAHMUNDRY ORTHOPEDIC HOSPITAL",
            "Contact": "8832468110",
            "avatarIcon": "RO",
            "foundUseful": "",
            "bedsData": {
                "7396245369": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "Sri Ravi Hospitals",
            "Contact": "9866255678",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9989778821": "0"
            }
        },
        {
            "hospitalName": "SURAREDDY NURSING HOME",
            "Contact": "8857233454",
            "avatarIcon": "SN",
            "foundUseful": "",
            "bedsData": {
                "7095901789": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9182293871",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9182077956": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "918074542890": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9346633478": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356001",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Universal Hospital rajahmundry",
            "Contact": "8832555544",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "8499022777": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "-\n",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "",
            "bedsData": {
                "8317662551": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "PRIME HOSPITALS",
            "Contact": "7075623517",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9395183463": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9949923899": "0"
            }
        },
        {
            "hospitalName": "VENNELA CHILDRENS AND MUTLIPSECIALITY HOSPITAL",
            "Contact": "9032715998",
            "avatarIcon": "VC",
            "foundUseful": "",
            "bedsData": {
                "8341878045": "0"
            }
        },
        {
            "hospitalName": "ARK MULTISPECIALITY HOSPITAL",
            "Contact": "8837963493",
            "avatarIcon": "AM",
            "foundUseful": "",
            "bedsData": {
                "8008356493": "0"
            }
        },
        {
            "hospitalName": "DAY",
            "Contact": "8834014921",
            "avatarIcon": "D",
            "foundUseful": "",
            "bedsData": {
                "9490340346": "0"
            }
        },
        {
            "hospitalName": "SARADHI HOSPITALS (Emergency",
            "Contact": "0883-2476887\n",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9959849990": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Dr. Veerraju Nursing Home",
            "Contact": "0884-2383636\n",
            "avatarIcon": "DV",
            "foundUseful": "",
            "bedsData": {
                "7382838485": "0"
            }
        },
        {
            "hospitalName": "Sri Akshara Hospital Kakinada",
            "Contact": "9949417141",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "8099944354": "0"
            }
        },
        {
            "hospitalName": "SRI HARI HOSPITAL",
            "Contact": "8464882882",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143047819": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "RS NEURO HOSPITAL",
            "Contact": "8832474744",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9848067068": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "SRUJANA MULTISPECIALITY HOSPITAL",
            "Contact": "8842361669",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9911603937": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "REVATHI HOSPITAL",
            "Contact": "8832491849",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9000826680": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "9666408705",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9949134963": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302600",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9885386671": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "9912561643",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "NARAYANAREDDY HOSPITALS",
            "Contact": "8855232123",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9849720763": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "Aruna Hospital",
            "Contact": "8842361599",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7997990181": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "PULSE EMERGENCY HOSPITAL",
            "Contact": "8842348889",
            "avatarIcon": "PE",
            "foundUseful": "",
            "bedsData": {
                "9963243005": "0"
            }
        },
        {
            "hospitalName": "CITY HOSPITAL",
            "Contact": "7331112105",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9701740676": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "RAJAHMUNDRY ORTHOPEDIC HOSPITAL",
            "Contact": "8832468110",
            "avatarIcon": "RO",
            "foundUseful": "",
            "bedsData": {
                "7396245369": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "Sri Ravi Hospitals",
            "Contact": "9866255678",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9989778821": "0"
            }
        },
        {
            "hospitalName": "SURAREDDY NURSING HOME",
            "Contact": "8857233454",
            "avatarIcon": "SN",
            "foundUseful": "",
            "bedsData": {
                "7095901789": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9182293871",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9182077956": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "918074542890": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9346633478": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356001",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Universal Hospital rajahmundry",
            "Contact": "8832555544",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "8499022777": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "-\n",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "",
            "bedsData": {
                "8317662551": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "PRIME HOSPITALS",
            "Contact": "7075623517",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9395183463": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9949923899": "0"
            }
        },
        {
            "hospitalName": "VENNELA CHILDRENS AND MUTLIPSECIALITY HOSPITAL",
            "Contact": "9032715998",
            "avatarIcon": "VC",
            "foundUseful": "",
            "bedsData": {
                "8341878045": "0"
            }
        },
        {
            "hospitalName": "ARK MULTISPECIALITY HOSPITAL",
            "Contact": "8837963493",
            "avatarIcon": "AM",
            "foundUseful": "",
            "bedsData": {
                "8008356493": "0"
            }
        },
        {
            "hospitalName": "DAY",
            "Contact": "8834014921",
            "avatarIcon": "D",
            "foundUseful": "",
            "bedsData": {
                "9490340346": "0"
            }
        },
        {
            "hospitalName": "SARADHI HOSPITALS (Emergency",
            "Contact": "0883-2476887\n",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9959849990": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Dr. Veerraju Nursing Home",
            "Contact": "0884-2383636\n",
            "avatarIcon": "DV",
            "foundUseful": "",
            "bedsData": {
                "7382838485": "0"
            }
        },
        {
            "hospitalName": "Sri Akshara Hospital Kakinada",
            "Contact": "9949417141",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "8099944354": "0"
            }
        },
        {
            "hospitalName": "SRI HARI HOSPITAL",
            "Contact": "8464882882",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143047819": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "RS NEURO HOSPITAL",
            "Contact": "8832474744",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9848067068": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "SRUJANA MULTISPECIALITY HOSPITAL",
            "Contact": "8842361669",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9911603937": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "REVATHI HOSPITAL",
            "Contact": "8832491849",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9000826680": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "9666408705",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9949134963": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302600",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9885386671": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "9912561643",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "NARAYANAREDDY HOSPITALS",
            "Contact": "8855232123",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9849720763": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "Aruna Hospital",
            "Contact": "8842361599",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7997990181": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "PULSE EMERGENCY HOSPITAL",
            "Contact": "8842348889",
            "avatarIcon": "PE",
            "foundUseful": "",
            "bedsData": {
                "9963243005": "0"
            }
        },
        {
            "hospitalName": "CITY HOSPITAL",
            "Contact": "7331112105",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9701740676": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "RAJAHMUNDRY ORTHOPEDIC HOSPITAL",
            "Contact": "8832468110",
            "avatarIcon": "RO",
            "foundUseful": "",
            "bedsData": {
                "7396245369": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "Sri Ravi Hospitals",
            "Contact": "9866255678",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9989778821": "0"
            }
        },
        {
            "hospitalName": "SURAREDDY NURSING HOME",
            "Contact": "8857233454",
            "avatarIcon": "SN",
            "foundUseful": "",
            "bedsData": {
                "7095901789": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9182293871",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9182077956": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "918074542890": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9346633478": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356001",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Universal Hospital rajahmundry",
            "Contact": "8832555544",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "8499022777": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "-\n",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "",
            "bedsData": {
                "8317662551": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "PRIME HOSPITALS",
            "Contact": "7075623517",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9395183463": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9949923899": "0"
            }
        },
        {
            "hospitalName": "VENNELA CHILDRENS AND MUTLIPSECIALITY HOSPITAL",
            "Contact": "9032715998",
            "avatarIcon": "VC",
            "foundUseful": "",
            "bedsData": {
                "8341878045": "0"
            }
        },
        {
            "hospitalName": "ARK MULTISPECIALITY HOSPITAL",
            "Contact": "8837963493",
            "avatarIcon": "AM",
            "foundUseful": "",
            "bedsData": {
                "8008356493": "0"
            }
        },
        {
            "hospitalName": "DAY",
            "Contact": "8834014921",
            "avatarIcon": "D",
            "foundUseful": "",
            "bedsData": {
                "9490340346": "0"
            }
        },
        {
            "hospitalName": "SARADHI HOSPITALS (Emergency",
            "Contact": "0883-2476887\n",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9959849990": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Dr. Veerraju Nursing Home",
            "Contact": "0884-2383636\n",
            "avatarIcon": "DV",
            "foundUseful": "",
            "bedsData": {
                "7382838485": "0"
            }
        },
        {
            "hospitalName": "Sri Akshara Hospital Kakinada",
            "Contact": "9949417141",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "8099944354": "0"
            }
        },
        {
            "hospitalName": "SRI HARI HOSPITAL",
            "Contact": "8464882882",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143047819": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "RS NEURO HOSPITAL",
            "Contact": "8832474744",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9848067068": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "SRUJANA MULTISPECIALITY HOSPITAL",
            "Contact": "8842361669",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9911603937": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "REVATHI HOSPITAL",
            "Contact": "8832491849",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9000826680": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "9666408705",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9949134963": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302600",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9885386671": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "9912561643",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "NARAYANAREDDY HOSPITALS",
            "Contact": "8855232123",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9849720763": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "Aruna Hospital",
            "Contact": "8842361599",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7997990181": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "PULSE EMERGENCY HOSPITAL",
            "Contact": "8842348889",
            "avatarIcon": "PE",
            "foundUseful": "",
            "bedsData": {
                "9963243005": "0"
            }
        },
        {
            "hospitalName": "CITY HOSPITAL",
            "Contact": "7331112105",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9701740676": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "RAJAHMUNDRY ORTHOPEDIC HOSPITAL",
            "Contact": "8832468110",
            "avatarIcon": "RO",
            "foundUseful": "",
            "bedsData": {
                "7396245369": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "Sri Ravi Hospitals",
            "Contact": "9866255678",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9989778821": "0"
            }
        },
        {
            "hospitalName": "SURAREDDY NURSING HOME",
            "Contact": "8857233454",
            "avatarIcon": "SN",
            "foundUseful": "",
            "bedsData": {
                "7095901789": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9182293871",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9182077956": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "918074542890": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9346633478": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356001",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Universal Hospital rajahmundry",
            "Contact": "8832555544",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "8499022777": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "-\n",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "",
            "bedsData": {
                "8317662551": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "PRIME HOSPITALS",
            "Contact": "7075623517",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9395183463": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9949923899": "0"
            }
        },
        {
            "hospitalName": "VENNELA CHILDRENS AND MUTLIPSECIALITY HOSPITAL",
            "Contact": "9032715998",
            "avatarIcon": "VC",
            "foundUseful": "",
            "bedsData": {
                "8341878045": "0"
            }
        },
        {
            "hospitalName": "ARK MULTISPECIALITY HOSPITAL",
            "Contact": "8837963493",
            "avatarIcon": "AM",
            "foundUseful": "",
            "bedsData": {
                "8008356493": "0"
            }
        },
        {
            "hospitalName": "DAY",
            "Contact": "8834014921",
            "avatarIcon": "D",
            "foundUseful": "",
            "bedsData": {
                "9490340346": "0"
            }
        },
        {
            "hospitalName": "SARADHI HOSPITALS (Emergency",
            "Contact": "0883-2476887\n",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9959849990": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Dr. Veerraju Nursing Home",
            "Contact": "0884-2383636\n",
            "avatarIcon": "DV",
            "foundUseful": "",
            "bedsData": {
                "7382838485": "0"
            }
        },
        {
            "hospitalName": "Sri Akshara Hospital Kakinada",
            "Contact": "9949417141",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "8099944354": "0"
            }
        },
        {
            "hospitalName": "SRI HARI HOSPITAL",
            "Contact": "8464882882",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143047819": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "RS NEURO HOSPITAL",
            "Contact": "8832474744",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9848067068": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "SRUJANA MULTISPECIALITY HOSPITAL",
            "Contact": "8842361669",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9911603937": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "REVATHI HOSPITAL",
            "Contact": "8832491849",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9000826680": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "9666408705",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9949134963": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302600",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9885386671": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "9912561643",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "NARAYANAREDDY HOSPITALS",
            "Contact": "8855232123",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9849720763": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "Aruna Hospital",
            "Contact": "8842361599",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7997990181": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "PULSE EMERGENCY HOSPITAL",
            "Contact": "8842348889",
            "avatarIcon": "PE",
            "foundUseful": "",
            "bedsData": {
                "9963243005": "0"
            }
        },
        {
            "hospitalName": "CITY HOSPITAL",
            "Contact": "7331112105",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9701740676": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "RAJAHMUNDRY ORTHOPEDIC HOSPITAL",
            "Contact": "8832468110",
            "avatarIcon": "RO",
            "foundUseful": "",
            "bedsData": {
                "7396245369": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "Sri Ravi Hospitals",
            "Contact": "9866255678",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9989778821": "0"
            }
        },
        {
            "hospitalName": "SURAREDDY NURSING HOME",
            "Contact": "8857233454",
            "avatarIcon": "SN",
            "foundUseful": "",
            "bedsData": {
                "7095901789": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9550028963",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9182077956": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "918074542890": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9346633478": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356001",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Universal Hospital rajahmundry",
            "Contact": "8832555544",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "8499022777": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "-\n",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "",
            "bedsData": {
                "8317662551": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "PRIME HOSPITALS",
            "Contact": "7075623517",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9395183463": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9949923899": "0"
            }
        },
        {
            "hospitalName": "ARK MULTISPECIALITY HOSPITAL",
            "Contact": "8837963493",
            "avatarIcon": "AM",
            "foundUseful": "",
            "bedsData": {
                "8008356493": "0"
            }
        },
        {
            "hospitalName": "DAY",
            "Contact": "8834014921",
            "avatarIcon": "D",
            "foundUseful": "",
            "bedsData": {
                "9490340346": "0"
            }
        },
        {
            "hospitalName": "SARADHI HOSPITALS (Emergency",
            "Contact": "0883-2476887\n",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9959849990": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Dr. Veerraju Nursing Home",
            "Contact": "0884-2383636\n",
            "avatarIcon": "DV",
            "foundUseful": "",
            "bedsData": {
                "7382838485": "0"
            }
        },
        {
            "hospitalName": "Sri Akshara Hospital Kakinada",
            "Contact": "9949417141",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "8099944354": "0"
            }
        },
        {
            "hospitalName": "SRI HARI HOSPITAL",
            "Contact": "8464882882",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143047819": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "RS NEURO HOSPITAL",
            "Contact": "8832474744",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9848067068": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "SRUJANA MULTISPECIALITY HOSPITAL",
            "Contact": "8842361669",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9911603937": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "REVATHI HOSPITAL",
            "Contact": "8832491849",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9000826680": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "9666408705",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9949134963": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302600",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9885386671": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "9912561643",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "NARAYANAREDDY HOSPITALS",
            "Contact": "8855232123",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9849720763": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "Aruna Hospital",
            "Contact": "8842361599",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7997990181": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "PULSE EMERGENCY HOSPITAL",
            "Contact": "8842348889",
            "avatarIcon": "PE",
            "foundUseful": "",
            "bedsData": {
                "9963243005": "0"
            }
        },
        {
            "hospitalName": "CITY HOSPITAL",
            "Contact": "7331112105",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9701740676": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "RAJAHMUNDRY ORTHOPEDIC HOSPITAL",
            "Contact": "8832468110",
            "avatarIcon": "RO",
            "foundUseful": "",
            "bedsData": {
                "7396245369": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "Sri Ravi Hospitals",
            "Contact": "9866255678",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9989778821": "0"
            }
        },
        {
            "hospitalName": "SURAREDDY NURSING HOME",
            "Contact": "8857233454",
            "avatarIcon": "SN",
            "foundUseful": "",
            "bedsData": {
                "7095901789": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9550028963",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9182077956": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "918074542890": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9346633478": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356001",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Universal Hospital rajahmundry",
            "Contact": "8832555544",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "8499022777": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "-\n",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "",
            "bedsData": {
                "8317662551": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "PRIME HOSPITALS",
            "Contact": "7075623517",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9395183463": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9949923899": "0"
            }
        },
        {
            "hospitalName": "SARADHI HOSPITALS (Emergency",
            "Contact": "0883-2476887\n",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9959849990": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Sri Akshara Hospital Kakinada",
            "Contact": "9949417141",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "8099944354": "0"
            }
        },
        {
            "hospitalName": "SRI HARI HOSPITAL",
            "Contact": "8464882882",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143047819": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "RS NEURO HOSPITAL",
            "Contact": "8832474744",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9848067068": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "SRUJANA MULTISPECIALITY HOSPITAL",
            "Contact": "8842361669",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9911603937": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "REVATHI HOSPITAL",
            "Contact": "8832491849",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9000826680": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "9666408705",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9949134963": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302600",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9885386671": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "9912561643",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "NARAYANAREDDY HOSPITALS",
            "Contact": "8855232123",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9849720763": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "Aruna Hospital",
            "Contact": "8842361599",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7997990181": "0"
            }
        },
        {
            "hospitalName": "ASCENT HOSPITALS A UNIT OF ASCENT MEDI EXCELLENCE PRIVATE LIMITED",
            "Contact": "8832428055",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9849720610": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "PULSE EMERGENCY HOSPITAL",
            "Contact": "8842348889",
            "avatarIcon": "PE",
            "foundUseful": "",
            "bedsData": {
                "9963243005": "0"
            }
        },
        {
            "hospitalName": "BHAVANI MULTISPECIALITY",
            "Contact": "7207303629",
            "avatarIcon": "BM",
            "foundUseful": "",
            "bedsData": {
                "9441765646": "0"
            }
        },
        {
            "hospitalName": "CITY HOSPITAL",
            "Contact": "7331112105",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9701740676": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "RAJAHMUNDRY ORTHOPEDIC HOSPITAL",
            "Contact": "8832468110",
            "avatarIcon": "RO",
            "foundUseful": "",
            "bedsData": {
                "7396245369": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "Sri Ravi Hospitals",
            "Contact": "9866255678",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9989778821": "0"
            }
        },
        {
            "hospitalName": "SURAREDDY NURSING HOME",
            "Contact": "8857233454",
            "avatarIcon": "SN",
            "foundUseful": "",
            "bedsData": {
                "7095901789": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9550028963",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9182077956": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "918074542890": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9346633478": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356001",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Universal Hospital rajahmundry",
            "Contact": "8832555544",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "8499022777": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "-\n",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "",
            "bedsData": {
                "8317662551": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "PRIME HOSPITALS",
            "Contact": "7075623517",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9395183463": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9949923899": "0"
            }
        },
        {
            "hospitalName": "SARADHI HOSPITALS (Emergency",
            "Contact": "0883-2476887\n",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9959849990": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Sri Akshara Hospital Kakinada",
            "Contact": "9949417141",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "8099944354": "0"
            }
        },
        {
            "hospitalName": "SRI HARI HOSPITAL",
            "Contact": "8464882882",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143047819": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "RS NEURO HOSPITAL",
            "Contact": "8832474744",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9848067068": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "SRUJANA MULTISPECIALITY HOSPITAL",
            "Contact": "8842361669",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9911603937": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "REVATHI HOSPITAL",
            "Contact": "8832491849",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9000826680": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "8842382381",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9949134963": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302600",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9885386671": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "8832426277",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "NARAYANAREDDY HOSPITALS",
            "Contact": "8855232123",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9849720763": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "Aruna Hospital",
            "Contact": "8842361599",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7997990181": "0"
            }
        },
        {
            "hospitalName": "ASCENT HOSPITALS A UNIT OF ASCENT MEDI EXCELLENCE PRIVATE LIMITED",
            "Contact": "8832428055",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9849720610": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "PULSE EMERGENCY HOSPITAL",
            "Contact": "8842348889",
            "avatarIcon": "PE",
            "foundUseful": "",
            "bedsData": {
                "9963243005": "0"
            }
        },
        {
            "hospitalName": "BHAVANI MULTISPECIALITY",
            "Contact": "7207303629",
            "avatarIcon": "BM",
            "foundUseful": "",
            "bedsData": {
                "9441765646": "0"
            }
        },
        {
            "hospitalName": "CITY HOSPITAL",
            "Contact": "7331112105",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9701740676": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "RAJAHMUNDRY ORTHOPEDIC HOSPITAL",
            "Contact": "8832468110",
            "avatarIcon": "RO",
            "foundUseful": "",
            "bedsData": {
                "7396245369": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "Sri Ravi Hospitals",
            "Contact": "9866255678",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9989778821": "0"
            }
        },
        {
            "hospitalName": "SURAREDDY NURSING HOME",
            "Contact": "8857233454",
            "avatarIcon": "SN",
            "foundUseful": "",
            "bedsData": {
                "7095901789": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9550028963",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491433023": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "918074542890": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9346633478": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356001",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Universal Hospital rajahmundry",
            "Contact": "8832555544",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "8499022777": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "8842376634",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "",
            "bedsData": {
                "8317662551": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "PRIME HOSPITALS",
            "Contact": "7075623517",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9395183463": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9949923899": "0"
            }
        },
        {
            "hospitalName": "SARADHI HOSPITALS (Emergency",
            "Contact": "0883-2476887\n",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9959849990": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Sri Akshara Hospital Kakinada",
            "Contact": "9949417141",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "8099944354": "0"
            }
        },
        {
            "hospitalName": "SRI HARI HOSPITAL",
            "Contact": "8464882882",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143047819": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "RS NEURO HOSPITAL",
            "Contact": "8832474744",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9848067068": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "SRUJANA MULTISPECIALITY HOSPITAL",
            "Contact": "8842361669",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9911603937": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "REVATHI HOSPITAL",
            "Contact": "8832491849",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9000826680": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "8842382381",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9949134963": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302600",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9885386671": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "8832426277",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "NARAYANAREDDY HOSPITALS",
            "Contact": "8855232123",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9849720763": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "Aruna Hospital",
            "Contact": "8842361599",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7997990181": "0"
            }
        },
        {
            "hospitalName": "ASCENT HOSPITALS A UNIT OF ASCENT MEDI EXCELLENCE PRIVATE LIMITED",
            "Contact": "8832428055",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9849720610": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "PULSE EMERGENCY HOSPITAL",
            "Contact": "8842348889",
            "avatarIcon": "PE",
            "foundUseful": "",
            "bedsData": {
                "9963243005": "0"
            }
        },
        {
            "hospitalName": "BHAVANI MULTISPECIALITY",
            "Contact": "7207303629",
            "avatarIcon": "BM",
            "foundUseful": "",
            "bedsData": {
                "9441765646": "0"
            }
        },
        {
            "hospitalName": "CITY HOSPITAL",
            "Contact": "7331112105",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9701740676": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "RAJAHMUNDRY ORTHOPEDIC HOSPITAL",
            "Contact": "8832468110",
            "avatarIcon": "RO",
            "foundUseful": "",
            "bedsData": {
                "7396245369": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "Sri Ravi Hospitals",
            "Contact": "9866255678",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9989778821": "0"
            }
        },
        {
            "hospitalName": "SURAREDDY NURSING HOME",
            "Contact": "8857233454",
            "avatarIcon": "SN",
            "foundUseful": "",
            "bedsData": {
                "7095901789": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9550028963",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491433023": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "918074542890": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9346633478": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356001",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Universal Hospital rajahmundry",
            "Contact": "8832555544",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "8499022777": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "8842376634",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "",
            "bedsData": {
                "8317662551": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "PRIME HOSPITALS",
            "Contact": "7075623517",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9395183463": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9949923899": "0"
            }
        },
        {
            "hospitalName": "SARADHI HOSPITALS (Emergency",
            "Contact": "0883-2476887\n",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9959849990": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Sri Akshara Hospital Kakinada",
            "Contact": "9949417141",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "8099944354": "0"
            }
        },
        {
            "hospitalName": "SRI HARI HOSPITAL",
            "Contact": "8464882882",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143047819": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "RS NEURO HOSPITAL",
            "Contact": "8832474744",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9848067068": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "SRUJANA MULTISPECIALITY HOSPITAL",
            "Contact": "8842361669",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9911603937": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "REVATHI HOSPITAL",
            "Contact": "8832491849",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9000826680": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "8842382381",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9949134963": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302600",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9885386671": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "8832426277",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "NARAYANAREDDY HOSPITALS",
            "Contact": "8855232123",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9849720763": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "Aruna Hospital",
            "Contact": "8842361599",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7997990181": "0"
            }
        },
        {
            "hospitalName": "ASCENT HOSPITALS A UNIT OF ASCENT MEDI EXCELLENCE PRIVATE LIMITED",
            "Contact": "8832428055",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9849720610": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "PULSE EMERGENCY HOSPITAL",
            "Contact": "8842348889",
            "avatarIcon": "PE",
            "foundUseful": "",
            "bedsData": {
                "9963243005": "0"
            }
        },
        {
            "hospitalName": "BHAVANI MULTISPECIALITY",
            "Contact": "7207303629",
            "avatarIcon": "BM",
            "foundUseful": "",
            "bedsData": {
                "9441765646": "0"
            }
        },
        {
            "hospitalName": "CITY HOSPITAL",
            "Contact": "7331112105",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9701740676": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "RAJAHMUNDRY ORTHOPEDIC HOSPITAL",
            "Contact": "8832468110",
            "avatarIcon": "RO",
            "foundUseful": "",
            "bedsData": {
                "7396245369": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "Sri Ravi Hospitals",
            "Contact": "9866255678",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9989778821": "0"
            }
        },
        {
            "hospitalName": "SURAREDDY NURSING HOME",
            "Contact": "8857233454",
            "avatarIcon": "SN",
            "foundUseful": "",
            "bedsData": {
                "7095901789": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9550028963",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491433023": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "918074542890": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9346633478": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356001",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Universal Hospital rajahmundry",
            "Contact": "8832555544",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "8499022777": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "8842376634",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "",
            "bedsData": {
                "8317662551": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "PRIME HOSPITALS",
            "Contact": "7075623517",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9395183463": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9949923899": "0"
            }
        },
        {
            "hospitalName": "SARADHI HOSPITALS (Emergency",
            "Contact": "8835476887",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9959849990": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Sri Akshara Hospital Kakinada",
            "Contact": "9949417141",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "8099944354": "0"
            }
        },
        {
            "hospitalName": "SRI HARI HOSPITAL",
            "Contact": "8464882882",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143047819": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "RS NEURO HOSPITAL",
            "Contact": "8832474744",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9848067068": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "SRUJANA MULTISPECIALITY HOSPITAL",
            "Contact": "8842361669",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9911603937": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "REVATHI HOSPITAL",
            "Contact": "8832491849",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9000826680": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "8842382381",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9949134963": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302600",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9885386671": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "8832426277",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "NARAYANAREDDY HOSPITALS",
            "Contact": "8855232123",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9849720763": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "Aruna Hospital",
            "Contact": "8842361599",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7997990181": "0"
            }
        },
        {
            "hospitalName": "ASCENT HOSPITALS A UNIT OF ASCENT MEDI EXCELLENCE PRIVATE LIMITED",
            "Contact": "8832428055",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9849720610": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "PULSE EMERGENCY HOSPITAL",
            "Contact": "8842348889",
            "avatarIcon": "PE",
            "foundUseful": "",
            "bedsData": {
                "9963243005": "0"
            }
        },
        {
            "hospitalName": "BHAVANI MULTISPECIALITY",
            "Contact": "7207303629",
            "avatarIcon": "BM",
            "foundUseful": "",
            "bedsData": {
                "9441765646": "0"
            }
        },
        {
            "hospitalName": "CITY HOSPITAL",
            "Contact": "7331112105",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9701740676": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "RAJAHMUNDRY ORTHOPEDIC HOSPITAL",
            "Contact": "8832468110",
            "avatarIcon": "RO",
            "foundUseful": "",
            "bedsData": {
                "7396245369": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "Sri Ravi Hospitals",
            "Contact": "9866255678",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9989778821": "0"
            }
        },
        {
            "hospitalName": "SURAREDDY NURSING HOME",
            "Contact": "8857233454",
            "avatarIcon": "SN",
            "foundUseful": "",
            "bedsData": {
                "7095901789": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9550028963",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491433023": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "918074542890": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9346633478": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356001",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Universal Hospital rajahmundry",
            "Contact": "8832555544",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "8499022777": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "8842376634",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "",
            "bedsData": {
                "8317662551": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "PRIME HOSPITALS",
            "Contact": "7075623517",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9395183463": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9949923899": "0"
            }
        },
        {
            "hospitalName": "SARADHI HOSPITALS (Emergency",
            "Contact": "8835476887",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9959849990": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Sri Akshara Hospital Kakinada",
            "Contact": "9949417141",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "8099944354": "0"
            }
        },
        {
            "hospitalName": "SRI HARI HOSPITAL",
            "Contact": "8464882882",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143047819": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "RS NEURO HOSPITAL",
            "Contact": "8832474744",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9848067068": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "SRUJANA MULTISPECIALITY HOSPITAL",
            "Contact": "8842361669",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9911603937": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "REVATHI HOSPITAL",
            "Contact": "8832491849",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9000826680": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "8842382381",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9949134963": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302600",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9885386671": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "8832426277",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "NARAYANAREDDY HOSPITALS",
            "Contact": "8855232123",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9849720763": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "Aruna Hospital",
            "Contact": "8842361599",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7997990181": "0"
            }
        },
        {
            "hospitalName": "ASCENT HOSPITALS A UNIT OF ASCENT MEDI EXCELLENCE PRIVATE LIMITED",
            "Contact": "8832428055",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9849720610": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "PULSE EMERGENCY HOSPITAL",
            "Contact": "8842348889",
            "avatarIcon": "PE",
            "foundUseful": "",
            "bedsData": {
                "9963243005": "0"
            }
        },
        {
            "hospitalName": "BHAVANI MULTISPECIALITY",
            "Contact": "7207303629",
            "avatarIcon": "BM",
            "foundUseful": "",
            "bedsData": {
                "9441765646": "0"
            }
        },
        {
            "hospitalName": "CITY HOSPITAL",
            "Contact": "7331112105",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9701740676": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "RAJAHMUNDRY ORTHOPEDIC HOSPITAL",
            "Contact": "8832468110",
            "avatarIcon": "RO",
            "foundUseful": "",
            "bedsData": {
                "7396245369": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "Sri Ravi Hospitals",
            "Contact": "9866255678",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9989778821": "0"
            }
        },
        {
            "hospitalName": "SURAREDDY NURSING HOME",
            "Contact": "8857233454",
            "avatarIcon": "SN",
            "foundUseful": "",
            "bedsData": {
                "7095901789": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9550028963",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491433023": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "918074542890": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9346633478": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356001",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Universal Hospital rajahmundry",
            "Contact": "8832555544",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "8499022777": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "8842376634",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "",
            "bedsData": {
                "8317662551": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "PRIME HOSPITALS",
            "Contact": "7075623517",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9395183463": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9949923899": "0"
            }
        },
        {
            "hospitalName": "SARADHI HOSPITALS (Emergency",
            "Contact": "8835476887",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9959849990": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Sri Akshara Hospital Kakinada",
            "Contact": "9949417141",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "8099944354": "0"
            }
        },
        {
            "hospitalName": "SRI HARI HOSPITAL",
            "Contact": "8464882882",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143047819": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "RS NEURO HOSPITAL",
            "Contact": "8832474744",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9848067068": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "SRUJANA MULTISPECIALITY HOSPITAL",
            "Contact": "8842361669",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9911603937": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "REVATHI HOSPITAL",
            "Contact": "8832491849",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9000826680": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "8842382381",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9949134963": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302600",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9885386671": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "8832426277",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "NARAYANAREDDY HOSPITALS",
            "Contact": "8855232123",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9849720763": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "Aruna Hospital",
            "Contact": "8842361599",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7997990181": "0"
            }
        },
        {
            "hospitalName": "ASCENT HOSPITALS A UNIT OF ASCENT MEDI EXCELLENCE PRIVATE LIMITED",
            "Contact": "8832428055",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9849720610": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "PULSE EMERGENCY HOSPITAL",
            "Contact": "8842348889",
            "avatarIcon": "PE",
            "foundUseful": "",
            "bedsData": {
                "9963243005": "0"
            }
        },
        {
            "hospitalName": "BHAVANI MULTISPECIALITY",
            "Contact": "7207303629",
            "avatarIcon": "BM",
            "foundUseful": "",
            "bedsData": {
                "9441765646": "0"
            }
        },
        {
            "hospitalName": "CITY HOSPITAL",
            "Contact": "7331112105",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9701740676": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "RAJAHMUNDRY ORTHOPEDIC HOSPITAL",
            "Contact": "8832468110",
            "avatarIcon": "RO",
            "foundUseful": "",
            "bedsData": {
                "7396245369": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "Sri Ravi Hospitals",
            "Contact": "9866255678",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9989778821": "0"
            }
        },
        {
            "hospitalName": "SURAREDDY NURSING HOME",
            "Contact": "8857233454",
            "avatarIcon": "SN",
            "foundUseful": "",
            "bedsData": {
                "7095901789": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9550028963",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491433023": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "918074542890": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9346633478": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356001",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Universal Hospital rajahmundry",
            "Contact": "8832555544",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "8499022777": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "8842376634",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "",
            "bedsData": {
                "8317662551": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "PRIME HOSPITALS",
            "Contact": "7075623517",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9395183463": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9949923899": "0"
            }
        },
        {
            "hospitalName": "SARADHI HOSPITALS (Emergency",
            "Contact": "8835476887",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9959849990": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Sri Akshara Hospital Kakinada",
            "Contact": "9949417141",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "8099944354": "0"
            }
        },
        {
            "hospitalName": "SRI HARI HOSPITAL",
            "Contact": "8464882882",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8143047819": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "RS NEURO HOSPITAL",
            "Contact": "8832474744",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9848067068": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "SRUJANA MULTISPECIALITY HOSPITAL",
            "Contact": "8842361669",
            "avatarIcon": "SM",
            "foundUseful": "",
            "bedsData": {
                "9911603937": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "REVATHI HOSPITAL",
            "Contact": "8832491849",
            "avatarIcon": "RH",
            "foundUseful": "",
            "bedsData": {
                "9000826680": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "8842382381",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9949134963": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302600",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9885386671": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "8832426277",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "NARAYANAREDDY HOSPITALS",
            "Contact": "8855232123",
            "avatarIcon": "NH",
            "foundUseful": "",
            "bedsData": {
                "9849720763": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "Aruna Hospital",
            "Contact": "8842361599",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "7997990181": "0"
            }
        },
        {
            "hospitalName": "ASCENT HOSPITALS A UNIT OF ASCENT MEDI EXCELLENCE PRIVATE LIMITED",
            "Contact": "8832428055",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9849720610": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "PULSE EMERGENCY HOSPITAL",
            "Contact": "8842348889",
            "avatarIcon": "PE",
            "foundUseful": "",
            "bedsData": {
                "9963243005": "0"
            }
        },
        {
            "hospitalName": "BHAVANI MULTISPECIALITY",
            "Contact": "7207303629",
            "avatarIcon": "BM",
            "foundUseful": "",
            "bedsData": {
                "9441765646": "0"
            }
        },
        {
            "hospitalName": "CITY HOSPITAL",
            "Contact": "7331112105",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9701740676": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "RAJAHMUNDRY ORTHOPEDIC HOSPITAL",
            "Contact": "8832468110",
            "avatarIcon": "RO",
            "foundUseful": "",
            "bedsData": {
                "7396245369": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "Sri Ravi Hospitals",
            "Contact": "9866255678",
            "avatarIcon": "SR",
            "foundUseful": "",
            "bedsData": {
                "9989778821": "0"
            }
        },
        {
            "hospitalName": "SURAREDDY NURSING HOME",
            "Contact": "8857233454",
            "avatarIcon": "SN",
            "foundUseful": "",
            "bedsData": {
                "7095901789": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9550028963",
            "avatarIcon": "CH",
            "foundUseful": "",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491433023": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "",
            "bedsData": {
                "918074542890": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "",
            "bedsData": {
                "9346633478": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356001",
            "avatarIcon": "TH",
            "foundUseful": "",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Universal Hospital rajahmundry",
            "Contact": "8832555544",
            "avatarIcon": "UH",
            "foundUseful": "",
            "bedsData": {
                "8499022777": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "8842376634",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "",
            "bedsData": {
                "8317662551": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "PRIME HOSPITALS",
            "Contact": "7075623517",
            "avatarIcon": "PH",
            "foundUseful": "",
            "bedsData": {
                "9395183463": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "",
            "bedsData": {
                "9949923899": "0"
            }
        },
        {
            "hospitalName": "SARADHI HOSPITALS (Emergency",
            "Contact": "8835476887",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "9959849990": "0"
            }
        },
        {
            "hospitalName": "SAI SANJEEVANI HOSPITAL",
            "Contact": "8856233888",
            "avatarIcon": "SS",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "6281481182": "0"
            }
        },
        {
            "hospitalName": "BEST HOSPITAL",
            "Contact": "8842374747",
            "avatarIcon": "BH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9494172461": "0"
            }
        },
        {
            "hospitalName": "Sri Akshara Hospital Kakinada",
            "Contact": "9949417141",
            "avatarIcon": "SA",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8099944354": "0"
            }
        },
        {
            "hospitalName": "SRI HARI HOSPITAL",
            "Contact": "8464882882",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8143047819": "0"
            }
        },
        {
            "hospitalName": "Akasam Hospital",
            "Contact": "9391675194",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:14\nVentilator:0",
            "bedsData": {
                "9866498195": "0"
            }
        },
        {
            "hospitalName": "Chiranjeeva Nursing Home",
            "Contact": "8856234000",
            "avatarIcon": "CN",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:1\nVentilator:0",
            "bedsData": {
                "7893323843": "0"
            }
        },
        {
            "hospitalName": "SRI ADITYA HOSPITAL",
            "Contact": "8842349797",
            "avatarIcon": "SA",
            "foundUseful": "ICU:4\nOxygen:4\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9949101634": "0"
            }
        },
        {
            "hospitalName": "RS NEURO HOSPITAL",
            "Contact": "8832474744",
            "avatarIcon": "RN",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9848067068": "0"
            }
        },
        {
            "hospitalName": "SVASTA EMERGENCY",
            "Contact": "9044339977",
            "avatarIcon": "SE",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:5\nVentilator:0",
            "bedsData": {
                "7799624999": "0"
            }
        },
        {
            "hospitalName": "SRI VISHNU MULTISPECIALITY HOSPITAL",
            "Contact": "08869-255777\n",
            "avatarIcon": "SV",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9985745191": "0"
            }
        },
        {
            "hospitalName": "SRUJANA MULTISPECIALITY HOSPITAL",
            "Contact": "8842361669",
            "avatarIcon": "SM",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9911603937": "0"
            }
        },
        {
            "hospitalName": "NEURO STAR MULTISPECIALITY HOSPTAL",
            "Contact": "9885943399",
            "avatarIcon": "NS",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9618986509": "0"
            }
        },
        {
            "hospitalName": "REVATHI HOSPITAL",
            "Contact": "8832491849",
            "avatarIcon": "RH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:1\nVentilator:0",
            "bedsData": {
                "9000826680": "0"
            }
        },
        {
            "hospitalName": "AAROGYA HOSPITALS",
            "Contact": "0883 7964908\n",
            "avatarIcon": "AH",
            "foundUseful": "ICU:1\nOxygen:2\nGeneral:14\nVentilator:0",
            "bedsData": {
                "9490738038": "0"
            }
        },
        {
            "hospitalName": "Trust Hospital A Unit Of Sarvottam Health Care Pvt Ltd",
            "Contact": "8842382381",
            "avatarIcon": "TH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9949134963": "0"
            }
        },
        {
            "hospitalName": "Samudra Healthcare Enterprises Ltd Apollo Hospital Kakinada",
            "Contact": "8842302600",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9885386671": "0"
            }
        },
        {
            "hospitalName": "Chakradhar Hospitals",
            "Contact": "8832426277",
            "avatarIcon": "CH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9010991435": "0"
            }
        },
        {
            "hospitalName": "NARAYANAREDDY HOSPITALS",
            "Contact": "8855232123",
            "avatarIcon": "NH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:8\nVentilator:0",
            "bedsData": {
                "9849720763": "0"
            }
        },
        {
            "hospitalName": "Raghava 24 Hours Hospital",
            "Contact": "7075323257",
            "avatarIcon": "R2",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:2",
            "bedsData": {
                "9441914187": "0"
            }
        },
        {
            "hospitalName": "Aruna Hospital",
            "Contact": "8842361599",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "7997990181": "0"
            }
        },
        {
            "hospitalName": "ASCENT HOSPITALS A UNIT OF ASCENT MEDI EXCELLENCE PRIVATE LIMITED",
            "Contact": "8832428055",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:10\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9849720610": "0"
            }
        },
        {
            "hospitalName": "S.A.I. Hospitals, Rjy",
            "Contact": "8832470033",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8328181134": "0"
            }
        },
        {
            "hospitalName": "Sreelatha Hospital",
            "Contact": "8832461960",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9951281595": "0"
            }
        },
        {
            "hospitalName": "PULSE EMERGENCY HOSPITAL",
            "Contact": "8842348889",
            "avatarIcon": "PE",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9963243005": "0"
            }
        },
        {
            "hospitalName": "BHAVANI MULTISPECIALITY",
            "Contact": "7207303629",
            "avatarIcon": "BM",
            "foundUseful": "ICU:0\nOxygen:8\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9441765646": "0"
            }
        },
        {
            "hospitalName": "CITY HOSPITAL",
            "Contact": "7331112105",
            "avatarIcon": "CH",
            "foundUseful": "ICU:0\nOxygen:4\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9701740676": "0"
            }
        },
        {
            "hospitalName": "Siddartha Hospital KKD",
            "Contact": "8842340102",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "7981633677": "0"
            }
        },
        {
            "hospitalName": "RAJAHMUNDRY ORTHOPEDIC HOSPITAL",
            "Contact": "8832468110",
            "avatarIcon": "RO",
            "foundUseful": "ICU:0\nOxygen:5\nGeneral:2\nVentilator:0",
            "bedsData": {
                "7396245369": "0"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITAL",
            "Contact": "8832437199",
            "avatarIcon": "GH",
            "foundUseful": "ICU:0\nOxygen:8\nGeneral:5\nVentilator:0",
            "bedsData": {
                "9394556639": "0"
            }
        },
        {
            "hospitalName": "District Hospital Rajahmundry",
            "Contact": "8832951177",
            "avatarIcon": "DH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9849905961": "0"
            }
        },
        {
            "hospitalName": "GGH KAKINADA",
            "Contact": "8842302094",
            "avatarIcon": "GK",
            "foundUseful": "ICU:2\nOxygen:4\nGeneral:12\nVentilator:0",
            "bedsData": {
                "8842302094": "0"
            }
        },
        {
            "hospitalName": "Laxmi Hospital Kakinada",
            "Contact": "8842382225",
            "avatarIcon": "LH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9700032215": "0"
            }
        },
        {
            "hospitalName": "SRINIDHI HOSPITAL",
            "Contact": "7207038108",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9866736430": "0"
            }
        },
        {
            "hospitalName": "Sri Ravi Hospitals",
            "Contact": "9866255678",
            "avatarIcon": "SR",
            "foundUseful": "ICU:3\nOxygen:12\nGeneral:6\nVentilator:0",
            "bedsData": {
                "9989778821": "0"
            }
        },
        {
            "hospitalName": "SURAREDDY NURSING HOME",
            "Contact": "8857233454",
            "avatarIcon": "SN",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:0\nVentilator:0",
            "bedsData": {
                "7095901789": "0"
            }
        },
        {
            "hospitalName": "KIMS AMALAPURAM",
            "Contact": "8856239999",
            "avatarIcon": "KA",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:238\nVentilator:0",
            "bedsData": {
                "9912233988": "0"
            }
        },
        {
            "hospitalName": "GSL MEDICAL COLLEGE",
            "Contact": "7815858263",
            "avatarIcon": "GM",
            "foundUseful": "ICU:0\nOxygen:11\nGeneral:161\nVentilator:0",
            "bedsData": {
                "9441226393": "0"
            }
        },
        {
            "hospitalName": "CEM HOSPITAL",
            "Contact": "9550028963",
            "avatarIcon": "CH",
            "foundUseful": "ICU:0\nOxygen:26\nGeneral:12\nVentilator:0",
            "bedsData": {
                "9912713355": "0"
            }
        },
        {
            "hospitalName": "SAI HOSPITALS, JaggamPet",
            "Contact": "9493843355",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:1\nGeneral:3\nVentilator:0",
            "bedsData": {
                "9491433023": "0"
            }
        },
        {
            "hospitalName": "Tholath Memorial Multispeciality Hospital",
            "Contact": "8331086108",
            "avatarIcon": "TM",
            "foundUseful": "ICU:1\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "918074542890": "0"
            }
        },
        {
            "hospitalName": "SANJIVI INISTITUTE OF ORTHOPAEDICS AND SUPER SPECIALITIES PVT LTD",
            "Contact": "9346633478",
            "avatarIcon": "SI",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:7\nVentilator:0",
            "bedsData": {
                "9346633478": "0"
            }
        },
        {
            "hospitalName": "Safe Emergency Hospital",
            "Contact": "8842383366",
            "avatarIcon": "SE",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9963743833": "0"
            }
        },
        {
            "hospitalName": "TEAM HOSPITAL",
            "Contact": "8842356001",
            "avatarIcon": "TH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:6\nVentilator:0",
            "bedsData": {
                "9848124080": "0"
            }
        },
        {
            "hospitalName": "Sri Lalitha Hospitals Insitute Of Laparoscopic Surgery And Training",
            "Contact": "8019800443",
            "avatarIcon": "SL",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9441328097": "0"
            }
        },
        {
            "hospitalName": "ANNAPURNA HOSPITAL",
            "Contact": "8985516099",
            "avatarIcon": "AH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:3\nVentilator:0",
            "bedsData": {
                "9989316201": "0"
            }
        },
        {
            "hospitalName": "Universal Hospital rajahmundry",
            "Contact": "8832555544",
            "avatarIcon": "UH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8499022777": "0"
            }
        },
        {
            "hospitalName": "Sai Sudha Hospital",
            "Contact": "8842376634",
            "avatarIcon": "SS",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9494636572": "0"
            }
        },
        {
            "hospitalName": "7 star Hospital Kakinada",
            "Contact": "8341887888",
            "avatarIcon": "7S",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "8317662551": "0"
            }
        },
        {
            "hospitalName": "HOPE HOSPITAL KAKINADA",
            "Contact": "8842326283",
            "avatarIcon": "HH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9010371359": "0"
            }
        },
        {
            "hospitalName": "sunrise hospital",
            "Contact": "8846604441",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9246691636": "0"
            }
        },
        {
            "hospitalName": "Inodaya Hospital Kakinada",
            "Contact": "8842333033",
            "avatarIcon": "IH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "7386003455": "0"
            }
        },
        {
            "hospitalName": "SUNSTAR HOSPITAL",
            "Contact": "9705705088",
            "avatarIcon": "SH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9491062099": "0"
            }
        },
        {
            "hospitalName": "PRIME HOSPITALS",
            "Contact": "7075623517",
            "avatarIcon": "PH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:5\nVentilator:0",
            "bedsData": {
                "9395183463": "0"
            }
        },
        {
            "hospitalName": "Raju Neuro Hospital Rajahmundry",
            "Contact": "8832445199",
            "avatarIcon": "RN",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9398567858": "0"
            }
        },
        {
            "hospitalName": "DELTA HOSPITAL",
            "Contact": "9613613613",
            "avatarIcon": "DH",
            "foundUseful": "ICU:0\nOxygen:0\nGeneral:0\nVentilator:0",
            "bedsData": {
                "9949923899": "0"
            }
        }
    ],
    "BHILWARA": [
        {
            "hospitalName": "",
            "Contact": "",
            "avatarIcon": "",
            "foundUseful": "0",
            "bedsData": {
                "General": "",
                "Oxygen": "",
                "ICU without Ventilator": "",
                "Ventilator": ""
            }
        },
        {
            "hospitalName": "",
            "Contact": "",
            "avatarIcon": "",
            "foundUseful": "0",
            "bedsData": {
                "General": "",
                "Oxygen": "",
                "ICU without Ventilator": "",
                "Ventilator": ""
            }
        },
        {
            "hospitalName": "",
            "Contact": "",
            "avatarIcon": "",
            "foundUseful": "0",
            "bedsData": {
                "General": "",
                "Oxygen": "",
                "ICU without Ventilator": "",
                "Ventilator": ""
            }
        },
        {
            "hospitalName": "",
            "Contact": "",
            "avatarIcon": "",
            "foundUseful": "0",
            "bedsData": {
                "General": "",
                "Oxygen": "",
                "ICU without Ventilator": "",
                "Ventilator": ""
            }
        },
        {
            "hospitalName": "",
            "Contact": "",
            "avatarIcon": "",
            "foundUseful": "0",
            "bedsData": {
                "General": "",
                "Oxygen": "",
                "ICU without Ventilator": "",
                "Ventilator": ""
            }
        },
        {
            "hospitalName": "TB HOSPITAL",
            "Contact": "0",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "18",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Swastik Hospital",
            "Contact": "8696924703",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "25",
                "ICU without Ventilator": "11",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "Smt Kesar Bai Soni Hospital",
            "Contact": "7791064556",
            "avatarIcon": "SK",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "50",
                "ICU without Ventilator": "6",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Shri Ambesh Hospital",
            "Contact": "0",
            "avatarIcon": "SA",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Satellite Hospital Shahpura",
            "Contact": "1484222203",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "25",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "SHRI SIDHI VINAYAK HOSPITAL BHILWARA",
            "Contact": "1482231900",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "21",
                "ICU without Ventilator": "4",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "RAMSNEHI CHIKITSALAYA and ANUSANDHAN KENDRA, BHILWARA",
            "Contact": "1482234100",
            "avatarIcon": "RC",
            "foundUseful": "0",
            "bedsData": {
                "General": "17",
                "Oxygen": "73",
                "ICU without Ventilator": "34",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Porwal Hospital Pvt Ltd",
            "Contact": "9829946350",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "General": "54",
                "Oxygen": "54",
                "ICU without Ventilator": "5",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "MGH hospital Bhilwara",
            "Contact": "9664454017",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "General": "20",
                "Oxygen": "162",
                "ICU without Ventilator": "4",
                "Ventilator": "39"
            }
        },
        {
            "hospitalName": "Krishna Hospital",
            "Contact": "8432535169",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "34",
                "ICU without Ventilator": "14",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "CHC MANDAL",
            "Contact": "NA",
            "avatarIcon": "CM",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "10",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC KOTRI",
            "Contact": "0",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "10",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC KAREDA",
            "Contact": "9414575637",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "7",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC GULABPURA",
            "Contact": "1483223099",
            "avatarIcon": "CG",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "20",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC BANERA",
            "Contact": "123",
            "avatarIcon": "CB",
            "foundUseful": "0",
            "bedsData": {
                "General": "14",
                "Oxygen": "6",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC BADNOR",
            "Contact": "1480226039",
            "avatarIcon": "CB",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "10",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "C.H.C. JAHAZPUR",
            "Contact": "1485230102",
            "avatarIcon": "CJ",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "15",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "C.H.C.  RAIPUR",
            "Contact": "917976969998",
            "avatarIcon": "C",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "15",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "C.H.C.  MANDALGARH",
            "Contact": "01489-230040",
            "avatarIcon": "C",
            "foundUseful": "0",
            "bedsData": {
                "General": "15",
                "Oxygen": "25",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "C.H.C.  GANGAPUR",
            "Contact": "1481220550",
            "avatarIcon": "C",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "30",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "C.H.C.  BIJOLIYA",
            "Contact": "8003422748",
            "avatarIcon": "C",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "15",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "C.H.C.  ASIND",
            "Contact": "01480 220 878",
            "avatarIcon": "C",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "24",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Brijesh Banger Memorial Hospital",
            "Contact": "7300151515",
            "avatarIcon": "BB",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "70",
                "ICU without Ventilator": "16",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "Arihant Hospital and Research Sansthan",
            "Contact": "1482253101",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "General": "13",
                "Oxygen": "51",
                "ICU without Ventilator": "0",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "Agarwal Utsav Bhawan",
            "Contact": "NA",
            "avatarIcon": "AU",
            "foundUseful": "0",
            "bedsData": {
                "General": "35",
                "Oxygen": "49",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "AYUSH",
            "Contact": "1482232643",
            "avatarIcon": "A",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        }
    ],
    "JODHPUR": [
        {
            "hospitalName": "Yash aman Hospital 21 kishore bagh mandore road jodhpur .",
            "Contact": "2912570263",
            "avatarIcon": "YA",
            "foundUseful": "",
            "bedsData": {
                "General": "19",
                "Oxygen": "5",
                "ICU without Ventilator": "3",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "Vishnoi Hospital",
            "Contact": "NA",
            "avatarIcon": "VH",
            "foundUseful": "",
            "bedsData": {
                "General": "0",
                "Oxygen": "16",
                "ICU without Ventilator": "3",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Vasundhara Hospital ltd",
            "Contact": "2912709001",
            "avatarIcon": "VH",
            "foundUseful": "",
            "bedsData": {
                "General": "2",
                "Oxygen": "29",
                "ICU without Ventilator": "5",
                "Ventilator": "6"
            }
        },
        {
            "hospitalName": "UPHC Residency",
            "Contact": "NA",
            "avatarIcon": "UR",
            "foundUseful": "",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Shri Ram mahamandir",
            "Contact": "02912770600\n9660887703",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "23",
                "ICU without Ventilator": "6",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Shri Ram Banar",
            "Contact": "NA",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "36",
                "ICU without Ventilator": "1",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Shree Ram Hospital",
            "Contact": "NA",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "46",
                "ICU without Ventilator": "7",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "Rajdadisa Hospital",
            "Contact": "0291-2625933",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "86",
                "ICU without Ventilator": "10",
                "Ventilator": "14"
            }
        },
        {
            "hospitalName": "Raj Hospital",
            "Contact": "NA",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Railway Hospital",
            "Contact": "9460144796",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "General": "6",
                "Oxygen": "50",
                "ICU without Ventilator": "2",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "PHC, Bapini",
            "Contact": "NA",
            "avatarIcon": "PB",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Medipulse Hospital",
            "Contact": "8239345655",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "10",
                "ICU without Ventilator": "3",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "MedicityHospital and Research Center Pno A 72 near SBI bank Aakhliya Circle",
            "Contact": "9549212367",
            "avatarIcon": "MA",
            "foundUseful": "0",
            "bedsData": {
                "General": "18",
                "Oxygen": "15",
                "ICU without Ventilator": "1",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Mathura Das Mathur Hospital",
            "Contact": "0291 2650314,0291 2650707",
            "avatarIcon": "MD",
            "foundUseful": "0",
            "bedsData": {
                "General": "30",
                "Oxygen": "600",
                "ICU without Ventilator": "7",
                "Ventilator": "82"
            }
        },
        {
            "hospitalName": "Marwar Hospital",
            "Contact": "9001293029",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "5",
                "ICU without Ventilator": "3",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "MUKUNDAM HOSPITAL",
            "Contact": "9413634455",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "5",
                "ICU without Ventilator": "4",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "MH",
            "Contact": "7300080965",
            "avatarIcon": "M",
            "foundUseful": "0",
            "bedsData": {
                "General": "136",
                "Oxygen": "50",
                "ICU without Ventilator": "0",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "MGH",
            "Contact": "0291-2661403",
            "avatarIcon": "M",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "72",
                "ICU without Ventilator": "0",
                "Ventilator": "18"
            }
        },
        {
            "hospitalName": "L.N Memorial hospital and Research Center (Rathi hospital)",
            "Contact": "7506360805",
            "avatarIcon": "LM",
            "foundUseful": "0",
            "bedsData": {
                "General": "2",
                "Oxygen": "1",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "KAMLA NAGAR HOSPITAL",
            "Contact": "9414753466",
            "avatarIcon": "KN",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "8",
                "ICU without Ventilator": "0",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "JECRC, Bornada",
            "Contact": "NA",
            "avatarIcon": "JB",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "District Hospital Mahila bagh",
            "Contact": "2912545311",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Dhanwantri Hospital",
            "Contact": "NA",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Daukiya Pratap nagar",
            "Contact": "9413550362",
            "avatarIcon": "DP",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "4",
                "ICU without Ventilator": "2",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Dar al shifa hospital",
            "Contact": "9983257686",
            "avatarIcon": "DA",
            "foundUseful": "0",
            "bedsData": {
                "General": "18",
                "Oxygen": "10",
                "ICU without Ventilator": "1",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "DH, Phalodi",
            "Contact": "2925224610",
            "avatarIcon": "DP",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "DH Paota, paota circle",
            "Contact": "9414410838",
            "avatarIcon": "DP",
            "foundUseful": "0",
            "bedsData": {
                "General": "8",
                "Oxygen": "8",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Chandra Mangal Hospital 235 mandore mandi circle, mandore road jodhpur.",
            "Contact": "8104597197",
            "avatarIcon": "CM",
            "foundUseful": "0",
            "bedsData": {
                "General": "29",
                "Oxygen": "25",
                "ICU without Ventilator": "1",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "CHC. Aau",
            "Contact": "8130520817",
            "avatarIcon": "CA",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "10",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Tinwari",
            "Contact": "9001834096",
            "avatarIcon": "CT",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "4",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Tepu",
            "Contact": "8003335338",
            "avatarIcon": "CT",
            "foundUseful": "0",
            "bedsData": {
                "General": "1",
                "Oxygen": "1",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Shergarh",
            "Contact": "2929243673",
            "avatarIcon": "CS",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "8",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Setrawa",
            "Contact": "919166771861",
            "avatarIcon": "CS",
            "foundUseful": "0",
            "bedsData": {
                "General": "3",
                "Oxygen": "3",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Salawas",
            "Contact": "9414374934",
            "avatarIcon": "CS",
            "foundUseful": "0",
            "bedsData": {
                "General": "20",
                "Oxygen": "10",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Pipar",
            "Contact": "2930233104",
            "avatarIcon": "CP",
            "foundUseful": "0",
            "bedsData": {
                "General": "13",
                "Oxygen": "41",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Osian",
            "Contact": "2922274547",
            "avatarIcon": "CO",
            "foundUseful": "0",
            "bedsData": {
                "General": "20",
                "Oxygen": "20",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Mathania",
            "Contact": "7742257429",
            "avatarIcon": "CM",
            "foundUseful": "0",
            "bedsData": {
                "General": "20",
                "Oxygen": "7",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Luni",
            "Contact": "02031 284102",
            "avatarIcon": "CL",
            "foundUseful": "0",
            "bedsData": {
                "General": "16",
                "Oxygen": "1",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Lohawat",
            "Contact": "9828516015",
            "avatarIcon": "CL",
            "foundUseful": "0",
            "bedsData": {
                "General": "9",
                "Oxygen": "1",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Keru",
            "Contact": "02931 285656",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "General": "6",
                "Oxygen": "4",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Kelansar",
            "Contact": "9079270129",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "2",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Jhanwar",
            "Contact": "29312696217",
            "avatarIcon": "CJ",
            "foundUseful": "0",
            "bedsData": {
                "General": "16",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Fidusar",
            "Contact": "9214981313",
            "avatarIcon": "CF",
            "foundUseful": "0",
            "bedsData": {
                "General": "6",
                "Oxygen": "4",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Dhundara",
            "Contact": "9828598354",
            "avatarIcon": "CD",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Dhawa",
            "Contact": "0",
            "avatarIcon": "CD",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "4",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Dechu",
            "Contact": "02928-266580",
            "avatarIcon": "CD",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Chamu",
            "Contact": "9970943174",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "12",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Borunda",
            "Contact": "8999755561",
            "avatarIcon": "CB",
            "foundUseful": "0",
            "bedsData": {
                "General": "4",
                "Oxygen": "4",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Bilara",
            "Contact": "9784196907",
            "avatarIcon": "CB",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "24",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Bhopalgarh",
            "Contact": "2925224610",
            "avatarIcon": "CB",
            "foundUseful": "0",
            "bedsData": {
                "General": "15",
                "Oxygen": "15",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Baru",
            "Contact": "9983867283",
            "avatarIcon": "CB",
            "foundUseful": "0",
            "bedsData": {
                "General": "2",
                "Oxygen": "2",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Bap",
            "Contact": "8949192859",
            "avatarIcon": "CB",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "10",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Baori",
            "Contact": "#ERROR!",
            "avatarIcon": "CB",
            "foundUseful": "0",
            "bedsData": {
                "General": "3",
                "Oxygen": "12",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Banar",
            "Contact": "2912942288",
            "avatarIcon": "CB",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Balesar",
            "Contact": "0",
            "avatarIcon": "CB",
            "foundUseful": "0",
            "bedsData": {
                "General": "13",
                "Oxygen": "10",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC, Asop",
            "Contact": "243541",
            "avatarIcon": "CA",
            "foundUseful": "0",
            "bedsData": {
                "General": "2",
                "Oxygen": "3",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "AIIMS",
            "Contact": "8764505002",
            "avatarIcon": "A",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "89",
                "ICU without Ventilator": "21",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "2 S Wellness center",
            "Contact": "2713000",
            "avatarIcon": "2S",
            "foundUseful": "0",
            "bedsData": {
                "General": "2",
                "Oxygen": "12",
                "ICU without Ventilator": "3",
                "Ventilator": "3"
            }
        }
    ],
    "KOTA": [
        {
            "hospitalName": "UCHC VIGYAN NAGAR",
            "Contact": "7424857221",
            "avatarIcon": "UV",
            "foundUseful": "",
            "bedsData": {
                "General": "2",
                "Oxygen": "8",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Sudha Hospital and Medical Research Centre",
            "Contact": "7442790092",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "General": "46",
                "Oxygen": "46",
                "ICU without Ventilator": "36",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "Shiv Shakti Hospital(Private Hospital)",
            "Contact": "6350568713",
            "avatarIcon": "SS",
            "foundUseful": "",
            "bedsData": {
                "General": "8",
                "Oxygen": "12",
                "ICU without Ventilator": "5",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Shanti Hospital (Private Hospital)",
            "Contact": "9214331933",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "General": "10",
                "Oxygen": "8",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Sara Hospital 3-A-6 Chatrapur main road vigyan nagar",
            "Contact": "9587207867",
            "avatarIcon": "SH",
            "foundUseful": "",
            "bedsData": {
                "General": "1",
                "Oxygen": "6",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Sanjeevani Hospital",
            "Contact": "7442325305",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "General": "11",
                "Oxygen": "3",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "SUDHA GENERAL HOSPITAL JAGPURA KOTA",
            "Contact": "7442790031",
            "avatarIcon": "SG",
            "foundUseful": "0",
            "bedsData": {
                "General": "50",
                "Oxygen": "20",
                "ICU without Ventilator": "30",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "SHREE RAM MULTI SPECIALITY HOSPITAL KOTA",
            "Contact": "7442437378",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "S. N. Pareek Hospital and Research Center Sanathan",
            "Contact": "7442402770",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "General": "14",
                "Oxygen": "47",
                "ICU without Ventilator": "3",
                "Ventilator": "6"
            }
        },
        {
            "hospitalName": "Ramakrishna Hospital",
            "Contact": "9610209797",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "General": "9",
                "Oxygen": "14",
                "ICU without Ventilator": "8",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Radha krishna Hospital",
            "Contact": "8005699326",
            "avatarIcon": "RK",
            "foundUseful": "0",
            "bedsData": {
                "General": "14",
                "Oxygen": "8",
                "ICU without Ventilator": "8",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "OPERA HOSPITAL MEDICAL and RESEARCH CENTRE PVT LTD",
            "Contact": "9886003410",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "32",
                "ICU without Ventilator": "4",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "NHMC",
            "Contact": "9462568921, 9462568902, 9462568904",
            "avatarIcon": "N",
            "foundUseful": "0",
            "bedsData": {
                "General": "22",
                "Oxygen": "68",
                "ICU without Ventilator": "15",
                "Ventilator": "76"
            }
        },
        {
            "hospitalName": "Military hospital kota",
            "Contact": "7442450421",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "General": "29",
                "Oxygen": "16",
                "ICU without Ventilator": "0",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Mewar Hospital",
            "Contact": "181",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "General": "3",
                "Oxygen": "9",
                "ICU without Ventilator": "1",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Mandal Chikitsalay",
            "Contact": "9001017864",
            "avatarIcon": "MC",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "20",
                "ICU without Ventilator": "3",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Maitri Hospital",
            "Contact": "9694094661",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "20",
                "ICU without Ventilator": "6",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "Mahaveer ENT Hospital",
            "Contact": "NA",
            "avatarIcon": "ME",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "MBS Hospital",
            "Contact": "0744-2326000. Extension- 213",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "General": "88",
                "Oxygen": "49",
                "ICU without Ventilator": "0",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "Kota Heart Hospital and Research Centre",
            "Contact": "NA",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "20",
                "ICU without Ventilator": "10",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "KOTA UNIVERSITY",
            "Contact": "NA",
            "avatarIcon": "KU",
            "foundUseful": "0",
            "bedsData": {
                "General": "15",
                "Oxygen": "121",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "KOTA TRAUMA HOSPITAL",
            "Contact": "9602485849",
            "avatarIcon": "KT",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "7",
                "ICU without Ventilator": "9",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Jaiswal Hospital and Neuro Institute",
            "Contact": "7442433232",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "52",
                "ICU without Ventilator": "8",
                "Ventilator": "20"
            }
        },
        {
            "hospitalName": "JANANI HOSPITAL",
            "Contact": "9116832259",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "General": "2",
                "Oxygen": "9",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Goyal endoscopy surgery and research centre pvt. Ltd, Kota",
            "Contact": "9314393789",
            "avatarIcon": "GE",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "4",
                "ICU without Ventilator": "10",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Divisional Railway Hospital",
            "Contact": "9001017864",
            "avatarIcon": "DR",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "City Heart and Critical Care Centre Hospital(Private Hospital)",
            "Contact": "7410810801",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC SULTANPUR",
            "Contact": "9950430545",
            "avatarIcon": "CS",
            "foundUseful": "0",
            "bedsData": {
                "General": "7",
                "Oxygen": "8",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC SANGOD",
            "Contact": "7891687351",
            "avatarIcon": "CS",
            "foundUseful": "0",
            "bedsData": {
                "General": "3",
                "Oxygen": "12",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC RAMGANJMANDI",
            "Contact": "7459223650",
            "avatarIcon": "CR",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "9",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC KAITHON",
            "Contact": "NA",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "General": "15",
                "Oxygen": "10",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC ITAWA",
            "Contact": "NA",
            "avatarIcon": "CI",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "10",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Bharat Vikas Parishad Hospital Dadabari",
            "Contact": "7442504501",
            "avatarIcon": "BV",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "20",
                "ICU without Ventilator": "5",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Acharya Children Hospital",
            "Contact": "NA",
            "avatarIcon": "AC",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Acharya Children Hospital",
            "Contact": "7442420222",
            "avatarIcon": "AC",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "6",
                "ICU without Ventilator": "7",
                "Ventilator": "0"
            }
        }
    ],
    "UDAIPUR": [
        {
            "hospitalName": "Sunrise Hospital",
            "Contact": "8107288889",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "13",
                "ICU without Ventilator": "6",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Sidhi Vinayak Hospital Sec 6",
            "Contact": "7877016677",
            "avatarIcon": "SV",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "9",
                "ICU without Ventilator": "4",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "Sharma Multispeciality Hospital",
            "Contact": "9660319736",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "31",
                "ICU without Ventilator": "7",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Satellite Hospital Sec. 6",
            "Contact": "0294 2462539",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "General": "15",
                "Oxygen": "65",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Saraswati Hospital Sec. 3",
            "Contact": "9950995333",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Paras JK Hospital, Shobhagpura, Udaipur",
            "Contact": "8949956217",
            "avatarIcon": "PJ",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "45",
                "ICU without Ventilator": "9",
                "Ventilator": "6"
            }
        },
        {
            "hospitalName": "Pacific Institute of Medical sciences, Umarda Udaipur",
            "Contact": "9358883197",
            "avatarIcon": "PI",
            "foundUseful": "0",
            "bedsData": {
                "General": "246",
                "Oxygen": "32",
                "ICU without Ventilator": "13",
                "Ventilator": "15"
            }
        },
        {
            "hospitalName": "PMCH Bheelo ka Bedla, Udaipur",
            "Contact": "7300081575(9AM to 6PM) 9116151115(6PM to 9AM)",
            "avatarIcon": "PB",
            "foundUseful": "0",
            "bedsData": {
                "General": "232",
                "Oxygen": "89",
                "ICU without Ventilator": "29",
                "Ventilator": "15"
            }
        },
        {
            "hospitalName": "MBGH and ESIC Hospital",
            "Contact": "9414155775",
            "avatarIcon": "MA",
            "foundUseful": "0",
            "bedsData": {
                "General": "118",
                "Oxygen": "193",
                "ICU without Ventilator": "42",
                "Ventilator": "74"
            }
        },
        {
            "hospitalName": "Karnawati Hospital",
            "Contact": "9549995432",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "General": "3",
                "Oxygen": "2",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Kanak Hospital Sec. 3",
            "Contact": "9929702777",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "35",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Kalpana Nursing Home Pvt Ltd",
            "Contact": "9414734868",
            "avatarIcon": "KN",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "29",
                "ICU without Ventilator": "11",
                "Ventilator": "6"
            }
        },
        {
            "hospitalName": "GEETANJALI MEDICAL COLLEGE AND HOSPITAL",
            "Contact": "7300081575(9AM to 6PM) 9116151115(6PM to 9AM)",
            "avatarIcon": "GM",
            "foundUseful": "0",
            "bedsData": {
                "General": "312",
                "Oxygen": "40",
                "ICU without Ventilator": "34",
                "Ventilator": "22"
            }
        },
        {
            "hospitalName": "GBH Hospital, Madhuwan",
            "Contact": "9352304050",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "48",
                "ICU without Ventilator": "3",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "DR CHAUDHARY HOSPITAL AND MEDICAL RESEARCH CENTER PRIVATE LIMITED",
            "Contact": "2942465566",
            "avatarIcon": "DC",
            "foundUseful": "0",
            "bedsData": {
                "General": "17",
                "Oxygen": "25",
                "ICU without Ventilator": "4",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "CHC RISHABDEV",
            "Contact": "7568281138",
            "avatarIcon": "CR",
            "foundUseful": "0",
            "bedsData": {
                "General": "20",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC PARSAAD",
            "Contact": "8740865215",
            "avatarIcon": "CP",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC NAI",
            "Contact": "9602381250",
            "avatarIcon": "CN",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC MAVLI",
            "Contact": "9950868576",
            "avatarIcon": "CM",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC LASADIYA",
            "Contact": "9829278535",
            "avatarIcon": "CL",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC KURABAD",
            "Contact": "8890906275",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "15",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC KOTDA",
            "Contact": "9571949571",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC KERWADA",
            "Contact": "9929307271",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "10",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC JHADOL (F)",
            "Contact": "7568361033",
            "avatarIcon": "CJ",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC GINGLA",
            "Contact": "8826189397",
            "avatarIcon": "CG",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC BHINDAR",
            "Contact": "9829048855",
            "avatarIcon": "CB",
            "foundUseful": "0",
            "bedsData": {
                "General": "27",
                "Oxygen": "8",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC BADGAUV",
            "Contact": "9024870290",
            "avatarIcon": "CB",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "5",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Aravali Hospital Mallatalai",
            "Contact": "2942430222",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "30",
                "ICU without Ventilator": "8",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "American International Institute Of Medical Sciences, GBH General Hospital AIIMS, Bedwas Udaipur",
            "Contact": "9314595205",
            "avatarIcon": "AI",
            "foundUseful": "0",
            "bedsData": {
                "General": "185",
                "Oxygen": "60",
                "ICU without Ventilator": "5",
                "Ventilator": "10"
            }
        },
        {
            "hospitalName": "CHC GOGUNDA",
            "Contact": "8769636310",
            "avatarIcon": "CG",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "10",
                "ICU without Ventilator": "0",
                "Ventilator": "0"
            }
        }
    ],
    "HYDERABAD": [
        {
            "hospitalName": "RAGHAVENDRA HOSPITALS (A UNIT OF SKHC PVT LTD)",
            "Contact": "9396810101",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "NIMS HOSPITALS",
            "Contact": "9490296073",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "ARAVIND EYE CARE CENTRE PVT LTD-NALLAKUNTA,HYDERABAD",
            "Contact": "9959337799, 9390069495",
            "avatarIcon": "AE",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "LAKSHMI HOSPITAL AND RESEARCH CENTRE_LLP",
            "Contact": "9246541363",
            "avatarIcon": "LH",
            "foundUseful": "0",
            "bedsData": {
                "General": "2",
                "Oxygen": "2",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "ZOI HOSPITAL - SOMAJIGUDA",
            "Contact": "9849029580",
            "avatarIcon": "ZH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "34",
                "Ventilator": "16"
            }
        },
        {
            "hospitalName": "YASHODA HOSPITALS - SOMAJIGUDA",
            "Contact": "9989975559, 9390006070",
            "avatarIcon": "YH",
            "foundUseful": "0",
            "bedsData": {
                "General": "63",
                "Oxygen": "162",
                "Ventilator": "69"
            }
        },
        {
            "hospitalName": "YASHODA HOSPITALS - SECUNDERABAD",
            "Contact": "9989975559, 9390006070, 9849522440",
            "avatarIcon": "YH",
            "foundUseful": "0",
            "bedsData": {
                "General": "130",
                "Oxygen": "140",
                "Ventilator": "95"
            }
        },
        {
            "hospitalName": "YASHODA HEALTH CARE SERVICES PVT LTD,",
            "Contact": "9989975559, 9390006070",
            "avatarIcon": "YH",
            "foundUseful": "0",
            "bedsData": {
                "General": "151",
                "Oxygen": "27",
                "Ventilator": "62"
            }
        },
        {
            "hospitalName": "WOODLANDS HOSPITAL, A MULTI SPECIALITY CENTRE,",
            "Contact": "9885099998",
            "avatarIcon": "WH",
            "foundUseful": "0",
            "bedsData": {
                "General": "12",
                "Oxygen": "12",
                "Ventilator": "10"
            }
        },
        {
            "hospitalName": "WELLNESS HOSPITAL - AMEERPET",
            "Contact": "9490667172",
            "avatarIcon": "WH",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "3",
                "Ventilator": "9"
            }
        },
        {
            "hospitalName": "VIVEKANANDA-BEGUMPET",
            "Contact": "9948268778",
            "avatarIcon": "V",
            "foundUseful": "0",
            "bedsData": {
                "General": "15",
                "Oxygen": "12",
                "Ventilator": "6"
            }
        },
        {
            "hospitalName": "VIRINCHI HEALTH CARE PVT. LTD.",
            "Contact": "9848386307, 9100957701",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "66",
                "Ventilator": "60"
            }
        },
        {
            "hospitalName": "VINN HOSPITAL - BEGUMPET",
            "Contact": "7993360813",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "59",
                "Ventilator": "57"
            }
        },
        {
            "hospitalName": "VIJAYA HEALTH TRUST - KUMMARIGUDA",
            "Contact": "8335084693",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "General": "40",
                "Oxygen": "22",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "VCARE HOSPITAL - AMEERPET",
            "Contact": "",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "15",
                "Ventilator": "8"
            }
        },
        {
            "hospitalName": "VASCULAR CARE CENTRE - SOMAJIGUDA",
            "Contact": "9542300060, 9100560241",
            "avatarIcon": "VC",
            "foundUseful": "0",
            "bedsData": {
                "General": "6",
                "Oxygen": "6",
                "Ventilator": "8"
            }
        },
        {
            "hospitalName": "VASAVI HOSPITAL - LAKDIKAPUL",
            "Contact": "9848120104",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "47",
                "Ventilator": "15"
            }
        },
        {
            "hospitalName": "UNIMED HEALTHCARE PVT- LTD STAR HOSPITALS",
            "Contact": "7982144457",
            "avatarIcon": "UH",
            "foundUseful": "0",
            "bedsData": {
                "General": "42",
                "Oxygen": "41",
                "Ventilator": "16"
            }
        },
        {
            "hospitalName": "UDAI OMNI HOSPITAL - NAMPALLY",
            "Contact": "9951493415",
            "avatarIcon": "UO",
            "foundUseful": "0",
            "bedsData": {
                "General": "7",
                "Oxygen": "19",
                "Ventilator": "13"
            }
        },
        {
            "hospitalName": "TX-KACHIGUDA",
            "Contact": "9701667662",
            "avatarIcon": "T",
            "foundUseful": "0",
            "bedsData": {
                "General": "17",
                "Oxygen": "48",
                "Ventilator": "27"
            }
        },
        {
            "hospitalName": "TRICOLOUR HOSPITAL & CRITICAL CARE - AMEERPET",
            "Contact": "9949375536",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "14",
                "Ventilator": "6"
            }
        },
        {
            "hospitalName": "THUMBAY HOSPITAL NEW LIFE A UNIT OF THUMBAY HOSPITAL INDIA PVT. LTD.",
            "Contact": "9642334949",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "General": "2",
                "Oxygen": "15",
                "Ventilator": "83"
            }
        },
        {
            "hospitalName": "THE DECCAN HOSPITAL (A UNIT OF PARK HEALTH SYSTEMS PVT. LTD",
            "Contact": "9849338067, 9848011397, 9160023406, 9908015319",
            "avatarIcon": "TD",
            "foundUseful": "0",
            "bedsData": {
                "General": "18",
                "Oxygen": "19",
                "Ventilator": "38"
            }
        },
        {
            "hospitalName": "TAVNIR HOSPITAL",
            "Contact": "9951911155",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "General": "6",
                "Oxygen": "4",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "SWASA HOSPITAL",
            "Contact": "9676760000",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "14",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "SWARAG HEALTHCARE SERVICES PVT LTD-SOMAJIGUDA",
            "Contact": "7602777935",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "General": "4",
                "Oxygen": "4",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "SVS HOSPITALS PVT. LTD.,",
            "Contact": "9246297659",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "General": "12",
                "Oxygen": "20",
                "Ventilator": "18"
            }
        },
        {
            "hospitalName": "SUNSHINE HOSPITAL",
            "Contact": "9849991319, 9100043229",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "General": "29",
                "Oxygen": "150",
                "Ventilator": "47"
            }
        },
        {
            "hospitalName": "SUNRIDGE MULTI SPECIALITY HOSPITAL-MOTINAGAR,HYDERABAD",
            "Contact": "9391458613, 8247681640",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "General": "11",
                "Oxygen": "11",
                "Ventilator": "13"
            }
        },
        {
            "hospitalName": "ST THERESA HOSPITAL",
            "Contact": "9032067678",
            "avatarIcon": "ST",
            "foundUseful": "0",
            "bedsData": {
                "General": "28",
                "Oxygen": "36",
                "Ventilator": "9"
            }
        },
        {
            "hospitalName": "S R R I T  C D GOVT FEVER HOSPITAL",
            "Contact": "9347043707",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "General": "33",
                "Oxygen": "0",
                "Ventilator": "100"
            }
        },
        {
            "hospitalName": "SRI VCARE MULTI SPECIALITY HOSPITAL",
            "Contact": "",
            "avatarIcon": "SV",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "10",
                "Ventilator": "10"
            }
        },
        {
            "hospitalName": "Sri Tirumala Maternity & Nursing Home-Sitafalmandi, Secunderabad",
            "Contact": "9959321450",
            "avatarIcon": "ST",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "25",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "SRI SAI SRINIVASA SPECIALITY HOSPITALS PVT LTD-HYDERABAD",
            "Contact": "9700333337, 9059549665",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "General": "9",
                "Oxygen": "10",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "SRI RAMA KRISHNA HOSPITAL-KACHIGUDA",
            "Contact": "9986618040, 9246344044",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "General": "7",
                "Oxygen": "7",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "SRIKARA HOSPITAL - SECUNDERABAD",
            "Contact": "9848097591",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "General": "14",
                "Oxygen": "26",
                "Ventilator": "18"
            }
        },
        {
            "hospitalName": "SREESTA KAMALA HOSPITAL - DILSUKNAGAR",
            "Contact": "9032771234",
            "avatarIcon": "SK",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "18",
                "Ventilator": "17"
            }
        },
        {
            "hospitalName": "SOWMYA CHILDRENS HOSPITAL,",
            "Contact": "9182107656",
            "avatarIcon": "SC",
            "foundUseful": "0",
            "bedsData": {
                "General": "4",
                "Oxygen": "6",
                "Ventilator": "10"
            }
        },
        {
            "hospitalName": "SKS NEURO HOSPITAL - KACHIGUDA",
            "Contact": "9848629407",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "15",
                "Ventilator": "12"
            }
        },
        {
            "hospitalName": "SHRAVANA MULTI SPECIALITY HOSPITAL-MOZAMJAHI MARKET, HYDERABAD",
            "Contact": "8341816161",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "General": "4",
                "Oxygen": "20",
                "Ventilator": "14"
            }
        },
        {
            "hospitalName": "SHANTILAL NAVODAYA MULTISPECIALITY HOSPITAL",
            "Contact": "8341816161",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "4",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "SHALIVAHANA MULTI SPECIALITY HOSPITAL-HYDERABAD",
            "Contact": "9246887077",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "8",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "SHALINI HOSPITAL BARKATPURA",
            "Contact": "8341816161",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "40",
                "Ventilator": "10"
            }
        },
        {
            "hospitalName": "SEASONS HOSPITAL - BAGH AMBERPET",
            "Contact": "9490627117",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "General": "8",
                "Oxygen": "6",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "SAMEER HOSPITAL.",
            "Contact": "9177373017",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "General": "12",
                "Oxygen": "12",
                "Ventilator": "13"
            }
        },
        {
            "hospitalName": "SAMEENA MATERNITY & NURSING HOME -BANDLAGUDA, HYDERABAD",
            "Contact": "9160186681",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "6",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "SAI VANI HOSPITAL - INDIRA PARK",
            "Contact": "9866458511",
            "avatarIcon": "SV",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "10",
                "Ventilator": "8"
            }
        },
        {
            "hospitalName": "SAI NURSING HOME - SAIDABAD MAIN ROAD",
            "Contact": "9989179309",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "20",
                "Ventilator": "14"
            }
        },
        {
            "hospitalName": "Sagarlal Memorial Hospital and Matadin Goel Research Centre,multi Speiality Hospital-Musheerabad, Hyderabad",
            "Contact": "9848069089, 9849246878",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "General": "20",
                "Oxygen": "20",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "SAFE KIDS HOSPITAL - KARWAN",
            "Contact": "8247230849, 9949019903",
            "avatarIcon": "SK",
            "foundUseful": "0",
            "bedsData": {
                "General": "17",
                "Oxygen": "7",
                "Ventilator": "16"
            }
        },
        {
            "hospitalName": "RUSH SUPERSPECIALITY HOSPITAL",
            "Contact": "8886611109",
            "avatarIcon": "RS",
            "foundUseful": "0",
            "bedsData": {
                "General": "3",
                "Oxygen": "0",
                "Ventilator": "13"
            }
        },
        {
            "hospitalName": "RENOVO HOSPITAL - LANGER HOUSE",
            "Contact": "9989143340",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "General": "28",
                "Oxygen": "21",
                "Ventilator": "11"
            }
        },
        {
            "hospitalName": "RENEW MEDICAL & REHABILITATION CENTRE - BEGUMPET",
            "Contact": "9963471103",
            "avatarIcon": "RM",
            "foundUseful": "0",
            "bedsData": {
                "General": "2",
                "Oxygen": "6",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "RAVI HELIOS HOSPITAL - INDIRA PARK",
            "Contact": "9849084566",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "General": "6",
                "Oxygen": "5",
                "Ventilator": "8"
            }
        },
        {
            "hospitalName": "RAINBOW CHILDRENS MEDICARE PVT LTD VIKRAMPURI,SECUNDRABAD",
            "Contact": "9849041198",
            "avatarIcon": "RC",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "24",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "RAINBOW CHILDRENS HEART INSTITUTE (A UNIT OF RAINBOW SPECIALITY HOSPITALS PVT LTD.)",
            "Contact": "9959115050",
            "avatarIcon": "RC",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "37",
                "Ventilator": "17"
            }
        },
        {
            "hospitalName": "RAINBOW CHILDREN HOSPITAL BANJARA HILLS",
            "Contact": "9959115050",
            "avatarIcon": "RC",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "9",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "RAILWAY HOSPITAL - LALAGUDA",
            "Contact": "9701370514",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "162",
                "Ventilator": "28"
            }
        },
        {
            "hospitalName": "PULSE HOSPITAL - PADMARAO NAGAR",
            "Contact": "8341816161",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "General": "7",
                "Oxygen": "10",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "PRINCESS ESRA HOSPITAL - SHALIBANDA",
            "Contact": "994967129, 8686063153",
            "avatarIcon": "PE",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "20",
                "Ventilator": "70"
            }
        },
        {
            "hospitalName": "PREMIER HOSPITAL",
            "Contact": "9848034747",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "53",
                "Ventilator": "18"
            }
        },
        {
            "hospitalName": "PRATHIMA HOSPITALS, KACHIGUDA",
            "Contact": "9959361880, 9703990177",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "46",
                "Ventilator": "24"
            }
        },
        {
            "hospitalName": "PRAJA SAI HOSPITAL",
            "Contact": "9100059777",
            "avatarIcon": "PS",
            "foundUseful": "0",
            "bedsData": {
                "General": "20",
                "Oxygen": "20",
                "Ventilator": "10"
            }
        },
        {
            "hospitalName": "PHYSIO REHAB CARE HEALTH AND WELLNESS CENTRE",
            "Contact": "9963678314",
            "avatarIcon": "PR",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "5",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "PADMAVATHI  MEDICAL CENTRE",
            "Contact": "9160668686",
            "avatarIcon": "P",
            "foundUseful": "0",
            "bedsData": {
                "General": "7",
                "Oxygen": "4",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "OSMANIA GENERAL HOSPITAL",
            "Contact": "9849902977",
            "avatarIcon": "OG",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "61",
                "Ventilator": "200"
            }
        },
        {
            "hospitalName": "ONUS HOSPITAL",
            "Contact": "9502792322",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "General": "50",
                "Oxygen": "0",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "OMEGAHOSPITALS",
            "Contact": "9848011421",
            "avatarIcon": "O",
            "foundUseful": "0",
            "bedsData": {
                "General": "23",
                "Oxygen": "15",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "OLIVE HOSPITAL",
            "Contact": "9666919293",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "General": "4",
                "Oxygen": "56",
                "Ventilator": "16"
            }
        },
        {
            "hospitalName": "NOVA ENT HOSPITAL-HYDERABAD",
            "Contact": "9391711122",
            "avatarIcon": "NE",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "18",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "NILOUFER MOTHER AND CHILD HOSPITAL-HYDERABAD",
            "Contact": "6303050439",
            "avatarIcon": "NM",
            "foundUseful": "0",
            "bedsData": {
                "General": "4",
                "Oxygen": "12",
                "Ventilator": "8"
            }
        },
        {
            "hospitalName": "NIKHIL HOSPITAL - DILSUKNAGAR",
            "Contact": "8519993543",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "8",
                "Ventilator": "12"
            }
        },
        {
            "hospitalName": "NIKHIL HOSPITAL, AMEERPET",
            "Contact": "9866966778",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "General": "6",
                "Oxygen": "7",
                "Ventilator": "20"
            }
        },
        {
            "hospitalName": "NICE HOPSITAL FOR WOMEN NEWBORNS & CHILDREN - MASAB TANK",
            "Contact": "8971569999",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "7",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "NEWLIFE REHABILITATION CENTRE - BANJARA HILLS",
            "Contact": "7680950686",
            "avatarIcon": "NR",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "36",
                "Ventilator": "10"
            }
        },
        {
            "hospitalName": "New JBI Hospital, Maternity, Surgical & Emergency Care-Tolichowki, Hyderabad",
            "Contact": "9666611110",
            "avatarIcon": "NJ",
            "foundUseful": "0",
            "bedsData": {
                "General": "14",
                "Oxygen": "9",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "NEHA DIAGNOSTICS",
            "Contact": "9346982268",
            "avatarIcon": "ND",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "20",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "NEELIMA HOSPITAL PRIVATE LIMITED,",
            "Contact": "8919630845, 9848233835, 9246160976",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "34",
                "Ventilator": "10"
            }
        },
        {
            "hospitalName": "NAVODAYA HOSPITAL - HYDERABAD",
            "Contact": "8341816161",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "General": "27",
                "Oxygen": "43",
                "Ventilator": "34"
            }
        },
        {
            "hospitalName": "NAVJEEVAN HOSPITALS",
            "Contact": "9703586000",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "20",
                "Ventilator": "25"
            }
        },
        {
            "hospitalName": "NAVEEN REDDY HOSPITAL-HYDERABAD",
            "Contact": "88010, 71236",
            "avatarIcon": "NR",
            "foundUseful": "0",
            "bedsData": {
                "General": "3",
                "Oxygen": "1",
                "Ventilator": "9"
            }
        },
        {
            "hospitalName": "NATIONAL INSTITUTE OF GASTROENTEROLOGY AND LIVER DISEASES (A UNIT OF TEJASWINI HEALTHCARE SERVICES PVT LTD.)",
            "Contact": "9849705112",
            "avatarIcon": "NI",
            "foundUseful": "0",
            "bedsData": {
                "General": "12",
                "Oxygen": "13",
                "Ventilator": "15"
            }
        },
        {
            "hospitalName": "MYTHRI HOSPITAL,",
            "Contact": "9160378092, 8179865282, 9948667886",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "General": "45",
                "Oxygen": "10",
                "Ventilator": "25"
            }
        },
        {
            "hospitalName": "MOHAMMEDI MEMORIAL HOSPITAL,",
            "Contact": "9849286151",
            "avatarIcon": "MM",
            "foundUseful": "0",
            "bedsData": {
                "General": "5",
                "Oxygen": "5",
                "Ventilator": "9"
            }
        },
        {
            "hospitalName": "MILITARY HOSPITAL - TIRUMALGERRY",
            "Contact": "7889529724",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "General": "207",
                "Oxygen": "156",
                "Ventilator": "23"
            }
        },
        {
            "hospitalName": "METRO CURE HOSPITAL - MALAKPET",
            "Contact": "9849054543, 9866243456",
            "avatarIcon": "MC",
            "foundUseful": "0",
            "bedsData": {
                "General": "2",
                "Oxygen": "3",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "MEDICOVER HOSPITALS (A UNIT OF SAHRUDAYA HEALTH CARE PVT. LTD.) SECRETARIAT",
            "Contact": "8008800233, 9849593231, 7674999567",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "General": "45",
                "Oxygen": "40",
                "Ventilator": "84"
            }
        },
        {
            "hospitalName": "MEDICLINIC MEDICAL CENTRE-CHARKAMAN, HYDERABAD",
            "Contact": "9849091683, 6304706063",
            "avatarIcon": "MM",
            "foundUseful": "0",
            "bedsData": {
                "General": "7",
                "Oxygen": "5",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "MALLAREDDY INSTITUTE OF MEDICAL SCIENCES-HYDERABAD",
            "Contact": "9849891212",
            "avatarIcon": "MI",
            "foundUseful": "0",
            "bedsData": {
                "General": "298",
                "Oxygen": "116",
                "Ventilator": "78"
            }
        },
        {
            "hospitalName": "MAHAVIR HOSPITAL - A C GAURDS",
            "Contact": "",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "General": "58",
                "Oxygen": "32",
                "Ventilator": "10"
            }
        },
        {
            "hospitalName": "MADHAVA NURSING HOME MULTISPECIALITY HOSPITAL - BANJARA HILLS",
            "Contact": "9848048914",
            "avatarIcon": "MN",
            "foundUseful": "0",
            "bedsData": {
                "General": "6",
                "Oxygen": "6",
                "Ventilator": "6"
            }
        },
        {
            "hospitalName": "MAA HOSPITALS PVT LTD, (MAA ENT HOSPITAL)",
            "Contact": "8008886688, 9100033289",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "General": "14",
                "Oxygen": "4",
                "Ventilator": "6"
            }
        },
        {
            "hospitalName": "LOTUS HOSPITAL FOR WOMEN AND CHILDREN (A UNIT OF SRI VISWA MEDICARE LTD.)",
            "Contact": "9849067373, 8919652560",
            "avatarIcon": "LH",
            "foundUseful": "0",
            "bedsData": {
                "General": "25",
                "Oxygen": "20",
                "Ventilator": "32"
            }
        },
        {
            "hospitalName": "LOTUS CURE MULTI SPECIALITY HOSPITAL-HYDERABAD",
            "Contact": "8520041688",
            "avatarIcon": "LC",
            "foundUseful": "0",
            "bedsData": {
                "General": "18",
                "Oxygen": "10",
                "Ventilator": "18"
            }
        },
        {
            "hospitalName": "KRISHNA INSTITUTE OF MEDICAL SCIENCES LTD, SECUNDERABAD",
            "Contact": "7995888432",
            "avatarIcon": "KI",
            "foundUseful": "0",
            "bedsData": {
                "General": "163",
                "Oxygen": "204",
                "Ventilator": "147"
            }
        },
        {
            "hospitalName": "KIRLOSKAR HOSPITAL,",
            "Contact": "9396882109, 9700345845",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "General": "20",
                "Oxygen": "5",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "INTEGRO HOSPITAL",
            "Contact": "8008866323",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "18",
                "Ventilator": "12"
            }
        },
        {
            "hospitalName": "INNOVA HOSPITAL - TARNAKA",
            "Contact": "9291525354",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "General": "12",
                "Oxygen": "10",
                "Ventilator": "23"
            }
        },
        {
            "hospitalName": "INDO - US SUPERSPECIALITY HOSPITAL - AMEERPET",
            "Contact": "8978480855",
            "avatarIcon": "I-",
            "foundUseful": "0",
            "bedsData": {
                "General": "25",
                "Oxygen": "18",
                "Ventilator": "27"
            }
        },
        {
            "hospitalName": "IMTIYAZ HOSPITAL,",
            "Contact": "9849066786",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "14",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "IMAGE HOSPITALS - AMEERPET",
            "Contact": "9000007644",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "General": "30",
                "Oxygen": "15",
                "Ventilator": "15"
            }
        },
        {
            "hospitalName": "HYDERABAD NURSING HOME - BASEERBAGH",
            "Contact": "8790413535, 9490272941",
            "avatarIcon": "HN",
            "foundUseful": "0",
            "bedsData": {
                "General": "9",
                "Oxygen": "25",
                "Ventilator": "15"
            }
        },
        {
            "hospitalName": "HYDERABAD MULTI SPECIALITY HOSPITAL (UNIT OF VVR HOSPITALS PVT. LTD.),",
            "Contact": "9391012791",
            "avatarIcon": "HM",
            "foundUseful": "0",
            "bedsData": {
                "General": "11",
                "Oxygen": "4",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "GSN HOSPITAL-SR NAGAR,HYDERABAD",
            "Contact": "9849055293",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "General": "13",
                "Oxygen": "12",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "GOVT MENTAL CARE HOSPITAL (OSM)",
            "Contact": "",
            "avatarIcon": "GM",
            "foundUseful": "0",
            "bedsData": {
                "General": "75",
                "Oxygen": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "GOVT ENT HOSPITAL (OSM)",
            "Contact": "",
            "avatarIcon": "GE",
            "foundUseful": "0",
            "bedsData": {
                "General": "202",
                "Oxygen": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "GLOBAL GLENEAGLES HOSPITAL - HYDERABAD",
            "Contact": "9849690680",
            "avatarIcon": "GG",
            "foundUseful": "0",
            "bedsData": {
                "General": "8",
                "Oxygen": "27",
                "Ventilator": "14"
            }
        },
        {
            "hospitalName": "GANDHI HOSPITALS",
            "Contact": "9392249569, 7989085425",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "General": "641",
                "Oxygen": "574",
                "Ventilator": "268"
            }
        },
        {
            "hospitalName": "FOCUS HOSPITAL - CHAMPAPET",
            "Contact": "9441163090, 9848039099",
            "avatarIcon": "FH",
            "foundUseful": "0",
            "bedsData": {
                "General": "4",
                "Oxygen": "5",
                "Ventilator": "8"
            }
        },
        {
            "hospitalName": "ESIC HOSPITAL(GOI)",
            "Contact": "7702985555",
            "avatarIcon": "EH",
            "foundUseful": "0",
            "bedsData": {
                "General": "43",
                "Oxygen": "71",
                "Ventilator": "9"
            }
        },
        {
            "hospitalName": "DURGABAI DESHMUKH HOSPITAL - VIDYA NAGAR",
            "Contact": "",
            "avatarIcon": "DD",
            "foundUseful": "0",
            "bedsData": {
                "General": "7",
                "Oxygen": "0",
                "Ventilator": "14"
            }
        },
        {
            "hospitalName": "DR.NOSHINAS  NOBLE HOSPITAL",
            "Contact": "8008123708",
            "avatarIcon": "D",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "10",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "DISTRICT HOSPITAL -  KINGKOTI",
            "Contact": "8008553882",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "280",
                "Ventilator": "36"
            }
        },
        {
            "hospitalName": "DHARANI MULTI SPECIALITY HOSPITAL-MEHDIPANAM",
            "Contact": "9100134141",
            "avatarIcon": "DM",
            "foundUseful": "0",
            "bedsData": {
                "General": "20",
                "Oxygen": "10",
                "Ventilator": "10"
            }
        },
        {
            "hospitalName": "DECCAN COLLEGE OF MEDICAL SCIENCES-KANCHANBAGH",
            "Contact": "",
            "avatarIcon": "DC",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "0",
                "Ventilator": "25"
            }
        },
        {
            "hospitalName": "CURE REHAB, PHYSIOTHERAPY POST HOSPITALIZATION REHABLITATION",
            "Contact": "8042784140",
            "avatarIcon": "CR",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "8",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "CITI NEURO CENTRE (A UNIT OF HYDERABAD INSTITUTE OF NEURO SCIENCES PVT LTD)",
            "Contact": "9160019361",
            "avatarIcon": "CN",
            "foundUseful": "0",
            "bedsData": {
                "General": "30",
                "Oxygen": "30",
                "Ventilator": "11"
            }
        },
        {
            "hospitalName": "CHEST HOSPITAL",
            "Contact": "9949216758",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "187",
                "Ventilator": "23"
            }
        },
        {
            "hospitalName": "CENTURY HOSPITAL - BANJARA HILLS",
            "Contact": "9989143340",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "General": "18",
                "Oxygen": "34",
                "Ventilator": "39"
            }
        },
        {
            "hospitalName": "C C SHROFF - BARKATPURA",
            "Contact": "9948075675, 9052895821",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "27",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "CARE HOSPITALS- NAMPALLY",
            "Contact": "7331126430",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "29",
                "Ventilator": "21"
            }
        },
        {
            "hospitalName": "CARE HOSPITALS MUSHIRABAD",
            "Contact": "9466574429",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "8",
                "Ventilator": "25"
            }
        },
        {
            "hospitalName": "CARE HOSPITALS BANJARAHILLS",
            "Contact": "9956069034",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "General": "18",
                "Oxygen": "73",
                "Ventilator": "68"
            }
        },
        {
            "hospitalName": "BRISTLECONE HOSPITALS - BARKATPURA",
            "Contact": "9848386307, 9030271787, 9640492333",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "General": "15",
                "Oxygen": "18",
                "Ventilator": "14"
            }
        },
        {
            "hospitalName": "BLISS HOSPITAL",
            "Contact": "9399952715, 9398290435",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "General": "4",
                "Oxygen": "6",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "BASAVATARAKAM INDO AMERICAN CANCER HOSPITAL AND RESEARCH INSTITUTE, HYDERABAD",
            "Contact": "9848011421",
            "avatarIcon": "BI",
            "foundUseful": "0",
            "bedsData": {
                "General": "3",
                "Oxygen": "10",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "ASVINS HOSPITAL - SOMAJIGUDA",
            "Contact": "9849984735",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "14",
                "Ventilator": "16"
            }
        },
        {
            "hospitalName": "ASTER PRIME HOSPITAL",
            "Contact": "9177700125",
            "avatarIcon": "AP",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "54",
                "Ventilator": "31"
            }
        },
        {
            "hospitalName": "APOORVA HOSPITAL,",
            "Contact": "9246598086",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "15",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "APOLLO INST. OF MEDICAL SCIENCES & RESEARCH, APOLLO HOSPITAL CAMPUS, HYDERABAD",
            "Contact": "9246240001",
            "avatarIcon": "AI",
            "foundUseful": "0",
            "bedsData": {
                "General": "199",
                "Oxygen": "30",
                "Ventilator": "19"
            }
        },
        {
            "hospitalName": "APOLLO HOSPITALS - SECUNDERABAD",
            "Contact": "9390094515",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "General": "29",
                "Oxygen": "19",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "APOLLO HOSPITALS ENTERPRISES LTD JUBILEE HILLS",
            "Contact": "9849637920",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "100",
                "Ventilator": "63"
            }
        },
        {
            "hospitalName": "APOLLO HOSPITALS ENTERPRISE LTD DRDO",
            "Contact": "9391031601",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "General": "3",
                "Oxygen": "23",
                "Ventilator": "13"
            }
        },
        {
            "hospitalName": "APOLLO HOSPITAL - HYDERGUDA",
            "Contact": "9849744721",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "25",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "Apna Multi Speciality Hospital-Santosh Nagar, Hyderabad",
            "Contact": "8977775659, 9849839108",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "30",
                "Ventilator": "10"
            }
        },
        {
            "hospitalName": "Anusha Hospital-Mehdipatnam, Hyderabad",
            "Contact": "9849026483",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "General": "10",
                "Oxygen": "10",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "ANKURA HOSPITAL - BANJARA HILLS",
            "Contact": "9100900279",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "General": "25",
                "Oxygen": "5",
                "Ventilator": "20"
            }
        },
        {
            "hospitalName": "ALPHA SUPER SPECIALITY HOSPITAL,",
            "Contact": "9666995296",
            "avatarIcon": "AS",
            "foundUseful": "0",
            "bedsData": {
                "General": "25",
                "Oxygen": "10",
                "Ventilator": "15"
            }
        },
        {
            "hospitalName": "AL-ARIF GENERAL HOSPITAL",
            "Contact": "9550297717",
            "avatarIcon": "AG",
            "foundUseful": "0",
            "bedsData": {
                "General": "0",
                "Oxygen": "7",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "AH NAMPALLY",
            "Contact": "8008553888",
            "avatarIcon": "AN",
            "foundUseful": "0",
            "bedsData": {
                "General": "8",
                "Oxygen": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "AH MALAKPET",
            "Contact": "9866244211",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "General": "80",
                "Oxygen": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "AH GOLKONDA",
            "Contact": "9440938674",
            "avatarIcon": "AG",
            "foundUseful": "0",
            "bedsData": {
                "General": "50",
                "Oxygen": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "ADITHYA HOSPITAL - BOGGULAKUNTA",
            "Contact": "9985175197",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "General": "8",
                "Oxygen": "19",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "AAYUSH MULTISPECIALITY HOSPITAL - DILSUKNAGAR",
            "Contact": "9391653419",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "General": "8",
                "Oxygen": "0",
                "Ventilator": "10"
            }
        }
    ],
    "PUDUCHERRY": [
        {
            "hospitalName": "Yanam",
            "Contact": "",
            "avatarIcon": "Y",
            "foundUseful": "",
            "bedsData": {
                "": "0"
            }
        },
        {
            "hospitalName": "Mahe",
            "Contact": "",
            "avatarIcon": "M",
            "foundUseful": "",
            "bedsData": {
                "Isolation": "55",
                "Oxygen": "16",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "VMMC Karaikal",
            "Contact": "",
            "avatarIcon": "VK",
            "foundUseful": "",
            "bedsData": {
                "Isolation": "119",
                "Oxygen": "29",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Karaikal",
            "Contact": "",
            "avatarIcon": "K",
            "foundUseful": "",
            "bedsData": {
                "Isolation": "60",
                "Oxygen": "90",
                "Ventilator": "10"
            }
        },
        {
            "hospitalName": "MVR",
            "Contact": "8842321224",
            "avatarIcon": "M",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "0",
                "Oxygen": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "New Medical Centre",
            "Contact": "0490-2332243",
            "avatarIcon": "NM",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "0",
                "Oxygen": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Rani Hospital",
            "Contact": "04368 263 340",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "0",
                "Oxygen": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Be Well Hospital",
            "Contact": "04368-222450",
            "avatarIcon": "BW",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "3",
                "Oxygen": "3",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "AVMC",
            "Contact": "",
            "avatarIcon": "A",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "513",
                "Oxygen": "130",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "SMVMC",
            "Contact": "0413 225 7662",
            "avatarIcon": "S",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "298",
                "Oxygen": "98",
                "Ventilator": "11"
            }
        },
        {
            "hospitalName": "SLIMS",
            "Contact": "0413 226 1200",
            "avatarIcon": "S",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "438",
                "Oxygen": "135",
                "Ventilator": "8"
            }
        },
        {
            "hospitalName": "MGMC",
            "Contact": "0413 - 2272905, 2272854, 2273115, 4202024",
            "avatarIcon": "M",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "400",
                "Oxygen": "142",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "SVMC",
            "Contact": "073732 02111",
            "avatarIcon": "S",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "492",
                "Oxygen": "102",
                "Ventilator": "12"
            }
        },
        {
            "hospitalName": "PIMS",
            "Contact": "0413 - 2272905, 2272854, 2273115 & 4202024",
            "avatarIcon": "P",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "488",
                "Oxygen": "14",
                "Ventilator": "10"
            }
        },
        {
            "hospitalName": "GHCD",
            "Contact": "0413 264 3014",
            "avatarIcon": "G",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "0",
                "Oxygen": "100",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Bahour CCC",
            "Contact": "0413 266 1998",
            "avatarIcon": "BC",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "20",
                "Oxygen": "5",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Mudhaliyarpet CCC",
            "Contact": "0413 261 5449",
            "avatarIcon": "MC",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "15",
                "Oxygen": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Kalapet CCC",
            "Contact": "0413 226 0601",
            "avatarIcon": "KC",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "0",
                "Oxygen": "30",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Ayush CCC",
            "Contact": "0413 265 1111",
            "avatarIcon": "AC",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "24",
                "Oxygen": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "PHC Nettapakkam",
            "Contact": "",
            "avatarIcon": "PN",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "10",
                "Oxygen": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "CHC Karikalampakkam",
            "Contact": "",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "0",
                "Oxygen": "30",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Dental College",
            "Contact": "0413 227 9601",
            "avatarIcon": "DC",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "22",
                "Oxygen": "6",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "JIPMER",
            "Contact": "0413 227 1301",
            "avatarIcon": "J",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "0",
                "Oxygen": "57",
                "Ventilator": "9"
            }
        },
        {
            "hospitalName": "IGMC",
            "Contact": "",
            "avatarIcon": "I",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "154",
                "Oxygen": "284",
                "Ventilator": "16"
            }
        },
        {
            "hospitalName": "IGGGH & PGI",
            "Contact": "0413 233 6971",
            "avatarIcon": "I&",
            "foundUseful": "0",
            "bedsData": {
                "Isolation": "0",
                "Oxygen": "0",
                "Ventilator": "0"
            }
        }
    ],
    "SURAT": [
        {
            "hospitalName": "Yuva Sanskruti Charitable Trust",
            "Contact": "9913566221",
            "avatarIcon": "YS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 27"
            }
        },
        {
            "hospitalName": "YOGI MULTI SPECIALITY HOSPITAL",
            "Contact": "2496900",
            "avatarIcon": "YM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 5",
                "Oxygen Beds": " 2"
            }
        },
        {
            "hospitalName": "WE CARE MULTISPECIALITY HOSPITAL",
            "Contact": "7096571571",
            "avatarIcon": "WC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 8"
            }
        },
        {
            "hospitalName": "VIVEK HOSPITAL",
            "Contact": "9825163951",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 2",
                "Oxygen Beds": " 4"
            }
        },
        {
            "hospitalName": "VEDANT HOSPITAL & ICU",
            "Contact": "02612571050",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 13",
                "Oxygen Beds": " 2"
            }
        },
        {
            "hospitalName": "Vastadevi Community Hall",
            "Contact": "7567576776",
            "avatarIcon": "VC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "VARDAN HOSPITAL & ICU",
            "Contact": "9925903776",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 9",
                "Oxygen Beds": " 1"
            }
        },
        {
            "hospitalName": "UNITY TRAUMA CENTER AND ICU",
            "Contact": "9879955855",
            "avatarIcon": "UT",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "UNITED GREEN HOSPITAL",
            "Contact": "02617199036",
            "avatarIcon": "UG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "UNIQUE HOSPITAL MULTISPECIALITY AND RESEARCH INSTITUTE RUN BY SURAT LIFE CARE PVT LTD",
            "Contact": "02616776666",
            "avatarIcon": "UH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "UDHNA HOSPITAL PRIVATE LIMITED",
            "Contact": "02612277991",
            "avatarIcon": "UH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 20"
            }
        },
        {
            "hospitalName": "TRISTAR HOSPITALS",
            "Contact": "02612200200",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 58"
            }
        },
        {
            "hospitalName": "The Park Infiniy",
            "Contact": "NA",
            "avatarIcon": "TP",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 80",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "THE NEW LIFE HOSPITAL",
            "Contact": "9714351351",
            "avatarIcon": "TN",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "SURAT INSTITUTE OF MEDICAL SCIENCE LLP",
            "Contact": "9044290448",
            "avatarIcon": "SI",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "SUNSHINE GLOBAL HOSPITAL",
            "Contact": "02614111000",
            "avatarIcon": "SG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 15",
                "Oxygen Beds": " 20"
            }
        },
        {
            "hospitalName": "SUFI SARVAJANIK HOSPITAL",
            "Contact": "02612450293",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "Sudama Group & PAS Team",
            "Contact": "8000005628",
            "avatarIcon": "SG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 17",
                "Oxygen Beds": " 14"
            }
        },
        {
            "hospitalName": "SMT R B SHAH MAHAVIR SUPER SPECIALITY HOSPITAL",
            "Contact": "02612292000",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 2",
                "Oxygen Beds": " 18"
            }
        },
        {
            "hospitalName": "SMIMER HOSPITAL",
            "Contact": "9724346042",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 691"
            }
        },
        {
            "hospitalName": "SILVER MULTI SPECIALITY HOSPITAL",
            "Contact": "9967079992",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 5",
                "Oxygen Beds": " 5"
            }
        },
        {
            "hospitalName": "SHYAMUBA HOSPITAL",
            "Contact": "02612570570",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 5",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "Shyama Prasad Mukherji Community Hall",
            "Contact": "9825527424",
            "avatarIcon": "SP",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 25",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "SHRI AMBIKANIKETAN MEDICAL TRUST - SHRI K.P.SANGHVI HOSPITAL",
            "Contact": "02612477788",
            "avatarIcon": "SA",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 16",
                "Oxygen Beds": " 14"
            }
        },
        {
            "hospitalName": "SHREYAL HOSPITAL",
            "Contact": "7874555983",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 3",
                "Oxygen Beds": " 2"
            }
        },
        {
            "hospitalName": "SHREEJI HOSPITAL",
            "Contact": "9724234772",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 12",
                "Oxygen Beds": " 5"
            }
        },
        {
            "hospitalName": "SHREE PRANNATH MULTI-SPECIALITY HOSPITAL",
            "Contact": "9558086019",
            "avatarIcon": "SP",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 10",
                "Oxygen Beds": " 12"
            }
        },
        {
            "hospitalName": "SHREE PRANNATH HOSPITAL",
            "Contact": "02612510050",
            "avatarIcon": "SP",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 40",
                "Oxygen Beds": " 25"
            }
        },
        {
            "hospitalName": "SHREE GURUNANAK DHARMARTH HOSPITAL",
            "Contact": "9408595599",
            "avatarIcon": "SG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 9",
                "Oxygen Beds": " 11"
            }
        },
        {
            "hospitalName": "SHREE BALAJI HOSPITAL AND ICU",
            "Contact": "02612859990",
            "avatarIcon": "SB",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 15",
                "Oxygen Beds": " 6"
            }
        },
        {
            "hospitalName": "SHRADDHA MULTISPECIALITY HOSPITAL",
            "Contact": "9978783338",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 18",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "SHIVJYOTI HOSPITAL",
            "Contact": "02672233490",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 10",
                "Oxygen Beds": " 13"
            }
        },
        {
            "hospitalName": "SHETH P. T. SURAT GENERAL HOSPITAL",
            "Contact": "02612604000",
            "avatarIcon": "SP",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 49"
            }
        },
        {
            "hospitalName": "SHALBY HOSPITAL",
            "Contact": "9512049891",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "SATADHAR MULTISPECIALITY HOSPITAL AND I.C.U",
            "Contact": "9913633004",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 5",
                "Oxygen Beds": " 1"
            }
        },
        {
            "hospitalName": "SARTHI GENARAL HOSPITAL",
            "Contact": "9879675260",
            "avatarIcon": "SG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 5",
                "Oxygen Beds": " 6"
            }
        },
        {
            "hospitalName": "SARDAR HOSPITAL",
            "Contact": "9825030000",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 9",
                "Oxygen Beds": " 12"
            }
        },
        {
            "hospitalName": "SANJIVANI ICU PVT LTD",
            "Contact": "7405104477",
            "avatarIcon": "SI",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 19"
            }
        },
        {
            "hospitalName": "SANJEEVANI MULTI SPECIALITY HOSPITAL",
            "Contact": "8140215777",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "SANGINI ADVANCE WOMEN'S CARE",
            "Contact": "9313407474",
            "avatarIcon": "SA",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 9"
            }
        },
        {
            "hospitalName": "Samprati Isolation Center",
            "Contact": "7801832244",
            "avatarIcon": "SI",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 45",
                "Oxygen Beds": " 42"
            }
        },
        {
            "hospitalName": "SAMARPAN MULTISPECIALITY HOSPITAL",
            "Contact": "9377101101",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 14"
            }
        },
        {
            "hospitalName": "SAMARPAN GENERAL HOSPITAL",
            "Contact": "9978966033",
            "avatarIcon": "SG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 13"
            }
        },
        {
            "hospitalName": "Sahay Health and Education Charitable Trust",
            "Contact": "9979875000",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 7"
            }
        },
        {
            "hospitalName": "SADVICHAR TRUST GENERAL HOSPITAL",
            "Contact": "7600306420",
            "avatarIcon": "ST",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 2",
                "Oxygen Beds": " 10"
            }
        },
        {
            "hospitalName": "S.M.V.Hospita",
            "Contact": "9978094344",
            "avatarIcon": "S",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "Ramji Wadi",
            "Contact": "9726221133",
            "avatarIcon": "RW",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 20",
                "Oxygen Beds": " 71"
            }
        },
        {
            "hospitalName": "RADHIKA HOSPITAL&POLYCLINIC",
            "Contact": "02612227652",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 5",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "PULSE HOSPITAL & ICU",
            "Contact": "8866067267",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 4",
                "Oxygen Beds": " 3"
            }
        },
        {
            "hospitalName": "POOJA HOSPITAL",
            "Contact": "02612536815",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 11",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "PIPLOD HOSPITAL",
            "Contact": "8735882281",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "Parvat Patia Community Hall",
            "Contact": "8141382828",
            "avatarIcon": "PP",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "PARIJAT MEDICAL HOSPITAL",
            "Contact": "02612777341",
            "avatarIcon": "PM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 9",
                "Oxygen Beds": " 14"
            }
        },
        {
            "hospitalName": "PAL CHC",
            "Contact": "9924995410",
            "avatarIcon": "PC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 4",
                "Oxygen Beds": " 28"
            }
        },
        {
            "hospitalName": "P.P.SAVANI HEART INSTITUTE AND MULTISPECIALITY HOSPITAL",
            "Contact": "9979967688",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 2",
                "Oxygen Beds": " 2"
            }
        },
        {
            "hospitalName": "NISHTHA HOSPITAL",
            "Contact": "02612540222",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 9"
            }
        },
        {
            "hospitalName": "NIRMAL HOSPITAL PVT LTD",
            "Contact": "4089999",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "New Civil Hospital",
            "Contact": "02612244457",
            "avatarIcon": "NC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 46",
                "Oxygen Beds": " 978"
            }
        },
        {
            "hospitalName": "NAVJIVAN MULTISPECIALITY HOSPITAL AND PRASUTI GRUH",
            "Contact": "9737065544",
            "avatarIcon": "NM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 3",
                "Oxygen Beds": " 6"
            }
        },
        {
            "hospitalName": "National Yuva Sangathan",
            "Contact": "9825130258",
            "avatarIcon": "NY",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 9",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "Modhvanik Samaj Wadi",
            "Contact": "9601737293",
            "avatarIcon": "MS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 67"
            }
        },
        {
            "hospitalName": "Modhavanik ni Wadi",
            "Contact": "9601737293",
            "avatarIcon": "MN",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "MEDIWISE HOSPITAL AND ICU",
            "Contact": "02612798091",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 26"
            }
        },
        {
            "hospitalName": "MEDICAL EDUCATIONAL TRUST ASSOCIATION SURAT OF SEVENTH DAY ADVENTIST",
            "Contact": "9624555777",
            "avatarIcon": "ME",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 30",
                "Oxygen Beds": " 100"
            }
        },
        {
            "hospitalName": "MAX HOSPITAL",
            "Contact": "7802944351",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 12"
            }
        },
        {
            "hospitalName": "MARINA GRAND HOSPITAL",
            "Contact": "9913359569",
            "avatarIcon": "MG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 7",
                "Oxygen Beds": " 33"
            }
        },
        {
            "hospitalName": "MANTRA HOSPITAL & ICU",
            "Contact": "9979595391",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 9",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "MALAVIA MULTISPECIALITY HOSPITAL",
            "Contact": "02612767668",
            "avatarIcon": "MM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 24"
            }
        },
        {
            "hospitalName": "MAITREYA MEDICARE PRIVATE LIMITED",
            "Contact": "7203940878",
            "avatarIcon": "MM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 69"
            }
        },
        {
            "hospitalName": "LIFELINE MULTISPECIALITY HOSPITAL",
            "Contact": "9909959968",
            "avatarIcon": "LM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 2"
            }
        },
        {
            "hospitalName": "Kumar Chhatralay",
            "Contact": "9998107959",
            "avatarIcon": "KC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 169",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "Krishna",
            "Contact": "NA",
            "avatarIcon": "K",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 12",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "KIRAN MULTI SUPER SPECIALITY HOSPITAL AND RESEARCH CENTER",
            "Contact": "02617161111",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "Kapodra Community Hall",
            "Contact": "9054263559",
            "avatarIcon": "KC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 10",
                "Oxygen Beds": " 7"
            }
        },
        {
            "hospitalName": "Kapadia Health Club",
            "Contact": "NA",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 103",
                "Oxygen Beds": " 6"
            }
        },
        {
            "hospitalName": "KAMLABA MULTI SPECIALITY HOSPITAL",
            "Contact": "02612512200",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 16",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "JIOMAX MULTI SUPER SPECIALITY HOSPITAL",
            "Contact": "02612265551",
            "avatarIcon": "JM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 5"
            }
        },
        {
            "hospitalName": "ITALIYA MULTISPECIALITY HOSPITAL",
            "Contact": "9067826000",
            "avatarIcon": "IM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 9",
                "Oxygen Beds": " 1"
            }
        },
        {
            "hospitalName": "INSTITUTE OF INFECTIOUS DISEASE AND CRITICAL CARE",
            "Contact": "9638150301",
            "avatarIcon": "IO",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 1",
                "Oxygen Beds": " 4"
            }
        },
        {
            "hospitalName": "INS HOSPITAL",
            "Contact": "02612706000",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "Hirabaugh Community Hall",
            "Contact": "9377713501",
            "avatarIcon": "HC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 9",
                "Oxygen Beds": " 3"
            }
        },
        {
            "hospitalName": "HIBA CHC",
            "Contact": "7859978680",
            "avatarIcon": "HC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 69"
            }
        },
        {
            "hospitalName": "HAJEE A M LOCKHAT & DR A M MOOLLA SARVAJANIK HOSPITAL",
            "Contact": "02612422080",
            "avatarIcon": "HA",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 17"
            }
        },
        {
            "hospitalName": "GUJARAT GASTRO AND VASCULAR HOSPITAL",
            "Contact": "9033362020",
            "avatarIcon": "GG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "GREEN LEAF HOSPITAL",
            "Contact": "02612203550",
            "avatarIcon": "GL",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "Goldstar",
            "Contact": "NA",
            "avatarIcon": "G",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 52",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "Gokul Solataire",
            "Contact": "NA",
            "avatarIcon": "GS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 86",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "GLOBAL HOSPITAL",
            "Contact": "9967079992",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 6",
                "Oxygen Beds": " 6"
            }
        },
        {
            "hospitalName": "GIRISH GROUP OF HOSPITALS",
            "Contact": "02612472500",
            "avatarIcon": "GG",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 34",
                "Oxygen Beds": " 6"
            }
        },
        {
            "hospitalName": "Gangani House",
            "Contact": "NA",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 62",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "G B VAGHANI MULTISPECIALITY HOSPITAL",
            "Contact": "9510695854",
            "avatarIcon": "GB",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 2",
                "Oxygen Beds": " 2"
            }
        },
        {
            "hospitalName": "DWTI PRABHU GENERAL HOSPITAL & BANKERS HEART INSTITUTE",
            "Contact": "02612293000",
            "avatarIcon": "DP",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 7",
                "Oxygen Beds": " 10"
            }
        },
        {
            "hospitalName": "DHWANI HOSPITAL AND ICCU",
            "Contact": "9427644934",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "Dayalji Ashram",
            "Contact": "NA",
            "avatarIcon": "DA",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 35",
                "Oxygen Beds": " 7"
            }
        },
        {
            "hospitalName": "CHIRAYU MULTISPECIALITY HOSPITAL",
            "Contact": "7210808108",
            "avatarIcon": "CM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 7",
                "Oxygen Beds": " 28"
            }
        },
        {
            "hospitalName": "Celebration",
            "Contact": "NA",
            "avatarIcon": "C",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 171",
                "Oxygen Beds": " 9"
            }
        },
        {
            "hospitalName": "BURHANI HOSPITAL",
            "Contact": "2505202",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 24"
            }
        },
        {
            "hospitalName": "BRIDGECROSS HOSPITAL",
            "Contact": "9377171007",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 11",
                "Oxygen Beds": " 4"
            }
        },
        {
            "hospitalName": "Botawala Isolation Center",
            "Contact": "9081079597",
            "avatarIcon": "BI",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 14",
                "Oxygen Beds": " 6"
            }
        },
        {
            "hospitalName": "Bhestan Community Hall",
            "Contact": "9825935999",
            "avatarIcon": "BC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 90",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "Bhestan CHC",
            "Contact": "7575856555",
            "avatarIcon": "BC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 64"
            }
        },
        {
            "hospitalName": "BHARI MATA CHC",
            "Contact": "8000160003",
            "avatarIcon": "BM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 35",
                "Oxygen Beds": " 5"
            }
        },
        {
            "hospitalName": "BHALANI HOSPITAL",
            "Contact": "7069100500",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 19"
            }
        },
        {
            "hospitalName": "BAPS PRAMUKH SWAMI HOSPITAL",
            "Contact": "02612781000",
            "avatarIcon": "BP",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 2",
                "Oxygen Beds": " 3"
            }
        },
        {
            "hospitalName": "BANSARI HOSPITAL&ICU",
            "Contact": "9228080806",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "AYUSH ICU AND MULTISPECIALITY HOSPITAL",
            "Contact": "9825106019",
            "avatarIcon": "AI",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 7",
                "Oxygen Beds": " 3"
            }
        },
        {
            "hospitalName": "ATOZ MULTISPECIALITY HOSPITAL LLP",
            "Contact": "7610810833",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 30"
            }
        },
        {
            "hospitalName": "Atal Samvedna",
            "Contact": "9925109982",
            "avatarIcon": "AS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 27",
                "Oxygen Beds": " 40"
            }
        },
        {
            "hospitalName": "ASUTOSH MULTI SPECIALITY HOSPITAL",
            "Contact": "02612509300",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 8"
            }
        },
        {
            "hospitalName": "APPLE HOSPITAL THE VENTURE OF SURAT HEALTH SERVICES PVT. LTD.",
            "Contact": "02616696000",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 3"
            }
        },
        {
            "hospitalName": "ANUPAM HOAPITAL",
            "Contact": "9979864770",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 2",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "ANSH HOSPITAL",
            "Contact": "07046543339",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 6",
                "Oxygen Beds": " 6"
            }
        },
        {
            "hospitalName": "ANJANI HOSPITAL AND ICU",
            "Contact": "02612776000",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 5",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "AMRUTA HOSPITAL",
            "Contact": "02612244979",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 21"
            }
        },
        {
            "hospitalName": "AMAAN MULTISPECIALITY HOSPITAL",
            "Contact": "02612751200",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 5",
                "Oxygen Beds": " 5"
            }
        },
        {
            "hospitalName": "AKANKSHA MEDICAL HOSPITAL",
            "Contact": "9825833525",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "AADHYA HOSPITAL",
            "Contact": "9979747619",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 14",
                "Oxygen Beds": " 5"
            }
        },
        {
            "hospitalName": "5 STAR MULTISPECIALITY HOSPITAL & ICU",
            "Contact": "9909735000",
            "avatarIcon": "5S",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 12",
                "Oxygen Beds": " 9"
            }
        },
        {
            "hospitalName": "21ST CENTURY FERTILITY CENTRE",
            "Contact": "02612490190",
            "avatarIcon": "2C",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 13",
                "Oxygen Beds": " 5"
            }
        }
    ],
    "JAIPUR": [],
    "PUNE": [
        {
            "hospitalName": "",
            "Contact": "NA",
            "avatarIcon": "",
            "foundUseful": "0",
            "bedsData": {
                "NA": "0"
            }
        },
        {
            "hospitalName": "",
            "Contact": "NA",
            "avatarIcon": "",
            "foundUseful": "0",
            "bedsData": {
                "NA": "0"
            }
        },
        {
            "hospitalName": "",
            "Contact": "NA",
            "avatarIcon": "",
            "foundUseful": "0",
            "bedsData": {
                "NA": "0"
            }
        },
        {
            "hospitalName": "",
            "Contact": "NA",
            "avatarIcon": "",
            "foundUseful": "0",
            "bedsData": {
                "NA": "0"
            }
        },
        {
            "hospitalName": "",
            "Contact": "NA",
            "avatarIcon": "",
            "foundUseful": "0",
            "bedsData": {
                "NA": "0"
            }
        },
        {
            "hospitalName": " S.M. Joshi College",
            "Contact": "NA",
            "avatarIcon": " S.M. JOSHI COLLEGE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 47",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " S.M. Joshi College",
            "Contact": "020-24372008 /9860888892",
            "avatarIcon": " S.M. JOSHI COLLEGE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 47",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Golden Care Hospital",
            "Contact": "NA",
            "avatarIcon": " GOLDEN CARE HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 49",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " S.M. Joshi College",
            "Contact": "NA",
            "avatarIcon": " S.M. JOSHI COLLEGE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 47",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Golden Care Hospital",
            "Contact": "NA",
            "avatarIcon": " GOLDEN CARE HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 49",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Smt Kashibai Navale Medical College(Narhe)",
            "Contact": "7755929401",
            "avatarIcon": " SMT KASHIBAI NAVALE MEDICAL COLLEGE(NARHE)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Trauma Care Center Bhigawan",
            "Contact": "NA",
            "avatarIcon": " TRAUMA CARE CENTER BHIGAWAN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 60",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Apeksha Hospital, Charholi",
            "Contact": "9422143011 9975513434",
            "avatarIcon": " APEKSHA HOSPITAL, CHARHOLI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Alandi Hospital, Alandi",
            "Contact": "NA",
            "avatarIcon": " ALANDI HOSPITAL, ALANDI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " S.M. Joshi College",
            "Contact": "NA",
            "avatarIcon": " S.M. JOSHI COLLEGE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 47",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " S.M. Joshi College",
            "Contact": "NA",
            "avatarIcon": " S.M. JOSHI COLLEGE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 47",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Golden Care Hospital",
            "Contact": "8275964116",
            "avatarIcon": " GOLDEN CARE HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 49",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Smt Kashibai Navale Medical College(Narhe)",
            "Contact": "NA",
            "avatarIcon": " SMT KASHIBAI NAVALE MEDICAL COLLEGE(NARHE)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Trauma Care Center Bhigawan",
            "Contact": "9665914363",
            "avatarIcon": " TRAUMA CARE CENTER BHIGAWAN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 60",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " S.M. Joshi College",
            "Contact": "NA",
            "avatarIcon": " S.M. JOSHI COLLEGE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 47",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Golden Care Hospital",
            "Contact": "9422300897",
            "avatarIcon": " GOLDEN CARE HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 49",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Smt Kashibai Navale Medical College(Narhe)",
            "Contact": "NA",
            "avatarIcon": " SMT KASHIBAI NAVALE MEDICAL COLLEGE(NARHE)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Trauma Care Center Bhigawan",
            "Contact": "NA",
            "avatarIcon": " TRAUMA CARE CENTER BHIGAWAN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 60",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Apeksha Hospital, Charholi",
            "Contact": "020-26451691, 9922414489",
            "avatarIcon": " APEKSHA HOSPITAL, CHARHOLI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Alandi Hospital, Alandi",
            "Contact": "NA",
            "avatarIcon": " ALANDI HOSPITAL, ALANDI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shree Hospital, Varale, Maval",
            "Contact": "7507077608 9969595757 7709442323",
            "avatarIcon": " SHREE HOSPITAL, VARALE, MAVAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 10",
                "ICU": " 1",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Kamshet Hospital",
            "Contact": "NA",
            "avatarIcon": " KAMSHET HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 2",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Orchid Hotel CCC attached om hosp ( Balewadi)",
            "Contact": "NA",
            "avatarIcon": " ORCHID HOTEL CCC ATTACHED OM HOSP ( BALEWADI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 250",
                "Oxygen": " 20",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Siddhivinayak Attached Siddhinvinayak Hosp CCC (",
            "Contact": "020-41499999",
            "avatarIcon": " SIDDHIVINAYAK ATTACHED SIDDHINVINAYAK HOSP CCC (",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 25",
                "Oxygen": " 20",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Zisha Remedis pvt ltd Rivhalyu Asso Center CCC",
            "Contact": "NA",
            "avatarIcon": " ZISHA REMEDIS PVT LTD RIVHALYU ASSO CENTER CCC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 48",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " PRUTHVI MEDICAL CO , PUNE ATTACHED AMEY NURSING HOME CCC",
            "Contact": "9561383251",
            "avatarIcon": " PRUTHVI MEDICAL CO , PUNE ATTACHED AMEY NURSING HOME CCC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 16",
                "Oxygen": " 9",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Auto-Cluster PCMC",
            "Contact": "NA",
            "avatarIcon": " AUTO-CLUSTER PCMC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 91",
                "ICU": " 14",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " The Pride Hotel CCC",
            "Contact": "NA",
            "avatarIcon": " THE PRIDE HOTEL CCC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 75",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Adinath Welfare Foundation Attached Ambassador Hotel",
            "Contact": "8888205884",
            "avatarIcon": " ADINATH WELFARE FOUNDATION ATTACHED AMBASSADOR HOTEL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 50",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Rotary R.I,D 3131 CCC",
            "Contact": "NA",
            "avatarIcon": " ROTARY R.I,D 3131 CCC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 70",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Raghav das Vidyalay CCC Attached Vinayak Hosp",
            "Contact": "NA",
            "avatarIcon": " RAGHAV DAS VIDYALAY CCC ATTACHED VINAYAK HOSP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 109",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Krishna CCC Attached Noble Hosp",
            "Contact": "8830409001",
            "avatarIcon": " KRISHNA CCC ATTACHED NOBLE HOSP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "Oxygen": " 66",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Mahesh Smruti Hospital ( Hadapsar )",
            "Contact": "NA",
            "avatarIcon": " MAHESH SMRUTI HOSPITAL ( HADAPSAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 20",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Khenat Hospital ( Karve Nagar)",
            "Contact": "8888821002/ 02066811802/1801",
            "avatarIcon": " KHENAT HOSPITAL ( KARVE NAGAR)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " SHREE SAI HOSPITAL ( Mundhwa )",
            "Contact": "NA",
            "avatarIcon": " SHREE SAI HOSPITAL ( MUNDHWA )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Mehata Hospital Baramati",
            "Contact": "NA",
            "avatarIcon": " MEHATA HOSPITAL BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Morya Hospital, Ubalenagar, Wagholi",
            "Contact": "9371333533",
            "avatarIcon": " MORYA HOSPITAL, UBALENAGAR, WAGHOLI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 29",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Agrwal Samaj federation Attached Columbia Hosp CCC",
            "Contact": "NA",
            "avatarIcon": " AGRWAL SAMAJ FEDERATION ATTACHED COLUMBIA HOSP CCC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 24",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " AKHIL KALE BORATE NAGAR PRATISHTHAAN",
            "Contact": "9860383840",
            "avatarIcon": " AKHIL KALE BORATE NAGAR PRATISHTHAAN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 50",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Kulkarni Hospital & Diagnostic Center ( Warje )",
            "Contact": "NA",
            "avatarIcon": " KULKARNI HOSPITAL & DIAGNOSTIC CENTER ( WARJE )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shree Mangalmurti Hospital Rahatani",
            "Contact": "NA",
            "avatarIcon": " SHREE MANGALMURTI HOSPITAL RAHATANI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Millenium Hospital Hinjawadi, Tal. Mulshi",
            "Contact": "9921714141",
            "avatarIcon": " MILLENIUM HOSPITAL HINJAWADI, TAL. MULSHI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 12",
                "ICU": " 3",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Sanchit Hospital Baramati",
            "Contact": "NA",
            "avatarIcon": " SANCHIT HOSPITAL BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 22",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Hutatma Rajguru Vidyalay CCC",
            "Contact": "NA",
            "avatarIcon": " HUTATMA RAJGURU VIDYALAY CCC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " JANHITARTH MULTISPECIALITY HOSPITAL",
            "Contact": "8080772936, 020 24352976, 7769951782, 8999074246,",
            "avatarIcon": " JANHITARTH MULTISPECIALITY HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Dhanwantari Hospital ( Fursungi )",
            "Contact": "NA",
            "avatarIcon": " DHANWANTARI HOSPITAL ( FURSUNGI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Mauli Hospital Pabal ,Shikrapur",
            "Contact": "8975134242",
            "avatarIcon": " MAULI HOSPITAL PABAL ,SHIKRAPUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 1",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " City Care Hospital ( Wadgoan Sheri)",
            "Contact": "NA",
            "avatarIcon": " CITY CARE HOSPITAL ( WADGOAN SHERI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 7",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shwas Hospital, Chakan",
            "Contact": "NA",
            "avatarIcon": " SHWAS HOSPITAL, CHAKAN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 18",
                "ICU": " 3",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " SAYALI NURSING HOME KONDWE DHAWADE",
            "Contact": "9011062329",
            "avatarIcon": " SAYALI NURSING HOME KONDWE DHAWADE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Mulshi Speciality Hospital,",
            "Contact": "NA",
            "avatarIcon": " MULSHI SPECIALITY HOSPITAL,",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Seth tarachand ramnath charitable ayurvedic hospital ( Rasta Peth )",
            "Contact": "NA",
            "avatarIcon": " SETH TARACHAND RAMNATH CHARITABLE AYURVEDIC HOSPITAL ( RASTA PETH )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "Oxygen": " 20",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Makan Surgical Maternity and Accident Hospital, Old Sangvi",
            "Contact": "9623331574",
            "avatarIcon": " MAKAN SURGICAL MATERNITY AND ACCIDENT HOSPITAL, OLD SANGVI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 17",
                "Oxygen": " 21",
                "ICU": " 3",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " SAHARA MULTISPECILITY HOSPITAL( Shinhgad rd )",
            "Contact": "NA",
            "avatarIcon": " SAHARA MULTISPECILITY HOSPITAL( SHINHGAD RD )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 7",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Z.V.M MULTISPECIALITY HOSPITAL",
            "Contact": "9860885508",
            "avatarIcon": " Z.V.M MULTISPECIALITY HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 17",
                "Oxygen": " 9",
                "ICU": " 2",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " CT NURSING HOME ( Dhanori )",
            "Contact": "NA",
            "avatarIcon": " CT NURSING HOME ( DHANORI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Crystal Hospital, Thergaon",
            "Contact": "NA",
            "avatarIcon": " CRYSTAL HOSPITAL, THERGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 4",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Onyx Hospital, Chinchwad",
            "Contact": "9890364104",
            "avatarIcon": " ONYX HOSPITAL, CHINCHWAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 18",
                "ICU": " 4",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " CHANDRA NURSING HOME ( Kondhwa BK )",
            "Contact": "NA",
            "avatarIcon": " CHANDRA NURSING HOME ( KONDHWA BK )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " VIMAL MULTISPECIALITY HOSPITAL ( Lohgaon)",
            "Contact": "8624953002",
            "avatarIcon": " VIMAL MULTISPECIALITY HOSPITAL ( LOHGAON)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " PAWAR HOSPITAL CHAKAN",
            "Contact": "NA",
            "avatarIcon": " PAWAR HOSPITAL CHAKAN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 44",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Galaxy Care Hospital (Karve rd)",
            "Contact": "NA",
            "avatarIcon": " GALAXY CARE HOSPITAL (KARVE RD)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 22",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Velhe ITI CCC",
            "Contact": "9860576115",
            "avatarIcon": " VELHE ITI CCC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 8",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Pawar Hospital Baramati",
            "Contact": "NA",
            "avatarIcon": " PAWAR HOSPITAL BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 1",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " CT Bora Collage Hostel, Shirur",
            "Contact": "NA",
            "avatarIcon": " CT BORA COLLAGE HOSTEL, SHIRUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 136",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Yashodhara ICU and Trauma Center, Bhigwan",
            "Contact": "9763832111",
            "avatarIcon": " YASHODHARA ICU AND TRAUMA CENTER, BHIGWAN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 16",
                "Oxygen": " 5",
                "ICU": " 1",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Magasvargiy Mulinche Shaskiya Vastigruh Shirur",
            "Contact": "NA",
            "avatarIcon": " MAGASVARGIY MULINCHE SHASKIYA VASTIGRUH SHIRUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 65",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Magasvargiy Mulinche Shaskiya Vastigruh Indapur",
            "Contact": "9922944083",
            "avatarIcon": " MAGASVARGIY MULINCHE SHASKIYA VASTIGRUH INDAPUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 65",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " SHANTI HOSPITAL SANASWADI SHIRUR",
            "Contact": "NA",
            "avatarIcon": " SHANTI HOSPITAL SANASWADI SHIRUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shivneri Hospital, Junnar",
            "Contact": "NA",
            "avatarIcon": " SHIVNERI HOSPITAL, JUNNAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 1",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Surya Hospital, Shikrapur",
            "Contact": "020-24212818",
            "avatarIcon": " SURYA HOSPITAL, SHIKRAPUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Swami Vivekanand COVID Health Centre, Narayangoan",
            "Contact": "NA",
            "avatarIcon": " SWAMI VIVEKANAND COVID HEALTH CENTRE, NARAYANGOAN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 32",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Navjeevan Nursing Home ( Hadapsar )",
            "Contact": "NA",
            "avatarIcon": " NAVJEEVAN NURSING HOME ( HADAPSAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 25",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " JEEWAN JYOT MULTISPECIALITY MEDICARE CENCRE ( Kharadi)",
            "Contact": "9372030440",
            "avatarIcon": " JEEWAN JYOT MULTISPECIALITY MEDICARE CENCRE ( KHARADI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 16",
                "ICU": " 7",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sancheti Hospital ( Shivajinagar )",
            "Contact": "NA",
            "avatarIcon": " SANCHETI HOSPITAL ( SHIVAJINAGAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 25",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Sant Sopandev Hospital, saswad",
            "Contact": "9623649936",
            "avatarIcon": " SANT SOPANDEV HOSPITAL, SASWAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 7",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Paravati General Hospital ( Dandekar Bridge )",
            "Contact": "NA",
            "avatarIcon": " PARAVATI GENERAL HOSPITAL ( DANDEKAR BRIDGE )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " MHADA -11 C, Mahalunge (Bhise Hospital)",
            "Contact": "NA",
            "avatarIcon": " MHADA -11 C, MAHALUNGE (BHISE HOSPITAL)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 249",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Gharkul D-6, Chikhali (Icon Hosp)",
            "Contact": "8983451788",
            "avatarIcon": " GHARKUL D-6, CHIKHALI (ICON HOSP)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 199",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Gharkul B-12, Chikhali (Divine Hosp)",
            "Contact": "NA",
            "avatarIcon": " GHARKUL B-12, CHIKHALI (DIVINE HOSP)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 107",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Gharkul B-10, Chikhali (Divine Hosp)",
            "Contact": "9325362742",
            "avatarIcon": " GHARKUL B-10, CHIKHALI (DIVINE HOSP)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 171",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shri Samarth Krupa Health Center, Wakad",
            "Contact": "NA",
            "avatarIcon": " SHRI SAMARTH KRUPA HEALTH CENTER, WAKAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shashwat Hosp, Kothrud",
            "Contact": "NA",
            "avatarIcon": " SHASHWAT HOSP, KOTHRUD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 2",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " Sai Prasad Hospital ( Dattannagar )",
            "Contact": "020-67212000",
            "avatarIcon": " SAI PRASAD HOSPITAL ( DATTANNAGAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 35",
                "Oxygen": " 42",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shri Someshwar ICU & Hospital Waghalwadi Baramati",
            "Contact": "NA",
            "avatarIcon": " SHRI SOMESHWAR ICU & HOSPITAL WAGHALWADI BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 13",
                "ICU": " 5",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Sai Samarth Hospital ( Kasba Peth )",
            "Contact": "NA",
            "avatarIcon": " SAI SAMARTH HOSPITAL ( KASBA PETH )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 12",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Nikhil Polyclinic ( Yerwada )",
            "Contact": "9975586761",
            "avatarIcon": " NIKHIL POLYCLINIC ( YERWADA )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " MATRUCHHAYA NURSING HOME ( Warje)",
            "Contact": "NA",
            "avatarIcon": " MATRUCHHAYA NURSING HOME ( WARJE)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Pioneer Hospital, Somatane, Maval",
            "Contact": "9834975542",
            "avatarIcon": " PIONEER HOSPITAL, SOMATANE, MAVAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 20",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Anuja Hospital ( Dhayari )",
            "Contact": "NA",
            "avatarIcon": " ANUJA HOSPITAL ( DHAYARI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sharda Hospital (Fursungi )",
            "Contact": "NA",
            "avatarIcon": " SHARDA HOSPITAL (FURSUNGI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 8",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Seetai Hospital ( Vishrantwadi )",
            "Contact": "9096044482",
            "avatarIcon": " SEETAI HOSPITAL ( VISHRANTWADI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 7",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shivam Hospital ( Hadapsar )",
            "Contact": "NA",
            "avatarIcon": " SHIVAM HOSPITAL ( HADAPSAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " CCC Janseva Foundation,Ranawadi(Velha)",
            "Contact": "9822316866",
            "avatarIcon": " CCC JANSEVA FOUNDATION,RANAWADI(VELHA)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 65",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " New Sofia Clinic ( Kondhwa KH )",
            "Contact": "NA",
            "avatarIcon": " NEW SOFIA CLINIC ( KONDHWA KH )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Waghmode hospital Alephata, Junnar",
            "Contact": "NA",
            "avatarIcon": " WAGHMODE HOSPITAL ALEPHATA, JUNNAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 12",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " AUNDH ITI CCC",
            "Contact": "9823921693,8485098798,8177856807",
            "avatarIcon": " AUNDH ITI CCC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 100",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Mai Mangeshkar hosp warje",
            "Contact": "NA",
            "avatarIcon": " MAI MANGESHKAR HOSP WARJE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 11",
                "Oxygen": " 34",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Ranka Hospital ( Swargate )",
            "Contact": "NA",
            "avatarIcon": " RANKA HOSPITAL ( SWARGATE )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 25",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " nagare nursing home ( Uttamnagar )",
            "Contact": "9049374040",
            "avatarIcon": " NAGARE NURSING HOME ( UTTAMNAGAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 14",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " RUBY HALL CLINIC, Hinjawadi",
            "Contact": "NA",
            "avatarIcon": " RUBY HALL CLINIC, HINJAWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 8",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Prabha Hospital ( Hadapsar )",
            "Contact": "8788469760",
            "avatarIcon": " PRABHA HOSPITAL ( HADAPSAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sahyadri Hospital (Hadapsar)",
            "Contact": "NA",
            "avatarIcon": " SAHYADRI HOSPITAL (HADAPSAR)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 33",
                "Oxygen": " 42",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Medwin Cares Hospital, Narhe, Haveli",
            "Contact": "NA",
            "avatarIcon": " MEDWIN CARES HOSPITAL, NARHE, HAVELI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 8",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Asha Clinic ( Fursungi )",
            "Contact": "9422080954",
            "avatarIcon": " ASHA CLINIC ( FURSUNGI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shraddha Hospital & ICU Daund",
            "Contact": "NA",
            "avatarIcon": " SHRADDHA HOSPITAL & ICU DAUND",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 17",
                "Oxygen": " 1",
                "ICU": " 4",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Shivam Hosp.( fursungi)",
            "Contact": "NA",
            "avatarIcon": " SHIVAM HOSP.( FURSUNGI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 11",
                "Oxygen": " 29",
                "ICU": " 5",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Dr.Mhaske hospital ( Hadapsar)",
            "Contact": "9623475007",
            "avatarIcon": " DR.MHASKE HOSPITAL ( HADAPSAR)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 14",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Vighnaharta Multi-speciality Hospital ( Bibwewadi )",
            "Contact": "NA",
            "avatarIcon": " VIGHNAHARTA MULTI-SPECIALITY HOSPITAL ( BIBWEWADI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 24",
                "ICU": " 4",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Minerna hospital ( Shivne )",
            "Contact": "8806666732",
            "avatarIcon": " MINERNA HOSPITAL ( SHIVNE )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 9",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Tribal Boys Hostel, Moshi (Trust Healthcare)",
            "Contact": "NA",
            "avatarIcon": " TRIBAL BOYS HOSTEL, MOSHI (TRUST HEALTHCARE)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 100",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sparsh Hospital, somatane phata",
            "Contact": "NA",
            "avatarIcon": " SPARSH HOSPITAL, SOMATANE PHATA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 50",
                "Oxygen": " 6",
                "ICU": " 10",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Swasthya Hospital, Khodad Road, Narayangaon",
            "Contact": "8888302638",
            "avatarIcon": " SWASTHYA HOSPITAL, KHODAD ROAD, NARAYANGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 6",
                "ICU": " 1",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " SOMTWADI CCC JUNNAR",
            "Contact": "NA",
            "avatarIcon": " SOMTWADI CCC JUNNAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 92",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " LifeLine Hospital Baramati",
            "Contact": "9921359090",
            "avatarIcon": " LIFELINE HOSPITAL BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 24",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Kasturba Specility Hospital (Visharant Wadi)",
            "Contact": "NA",
            "avatarIcon": " KASTURBA SPECILITY HOSPITAL (VISHARANT WADI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 28",
                "Oxygen": " 6",
                "ICU": " 6",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " KAVADE NURSING HOME ( Uttam nagar )",
            "Contact": "NA",
            "avatarIcon": " KAVADE NURSING HOME ( UTTAM NAGAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 7",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Mahalaxmi Hospital,",
            "Contact": "9765078515/8484083184",
            "avatarIcon": " MAHALAXMI HOSPITAL,",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 3",
                "ICU": " 1",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Gaikwad Hospital ( Hadapsar)",
            "Contact": "NA",
            "avatarIcon": " GAIKWAD HOSPITAL ( HADAPSAR)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Mantri Hospital ( Kavde rd, Ghorpadi )",
            "Contact": "NA",
            "avatarIcon": " MANTRI HOSPITAL ( KAVDE RD, GHORPADI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " CCC Lonavala",
            "Contact": "8888442202",
            "avatarIcon": " CCC LONAVALA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 41",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Walchandnagar,Indapur",
            "Contact": "NA",
            "avatarIcon": " WALCHANDNAGAR,INDAPUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Tukaai multispecility hospital ( Kharadi)",
            "Contact": "7387039191",
            "avatarIcon": " TUKAAI MULTISPECILITY HOSPITAL ( KHARADI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Inaam hospital( yerwada)",
            "Contact": "NA",
            "avatarIcon": " INAAM HOSPITAL( YERWADA)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sanjeevan Hospital ( karve rd )",
            "Contact": "NA",
            "avatarIcon": " SANJEEVAN HOSPITAL ( KARVE RD )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "Oxygen": " 41",
                "ICU": " 17",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": " Pyramid Hospital Daund",
            "Contact": "8485841070/74, 8805598244",
            "avatarIcon": " PYRAMID HOSPITAL DAUND",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 32",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " LifeCare Multispecility Hospital ( Near Samruddhi Hotel Hadapsar )",
            "Contact": "NA",
            "avatarIcon": " LIFECARE MULTISPECILITY HOSPITAL ( NEAR SAMRUDDHI HOTEL HADAPSAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 8",
                "ICU": " 10",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " ORCHID HOSPITAL ( Lohegaon )",
            "Contact": "9067887610",
            "avatarIcon": " ORCHID HOSPITAL ( LOHEGAON )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 14",
                "ICU": " 6",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Shree Hospital Criticare & Trauma Center Pvt Ltd( Kharadi)",
            "Contact": "NA",
            "avatarIcon": " SHREE HOSPITAL CRITICARE & TRAUMA CENTER PVT LTD( KHARADI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 19",
                "Oxygen": " 16",
                "ICU": " 6",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " APPLE HOSPITAL ( LOHAGON )",
            "Contact": "NA",
            "avatarIcon": " APPLE HOSPITAL ( LOHAGON )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 14",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " SHREE GANESH MULTISPECILITY HOSPITAL ( manjari) )",
            "Contact": "9096729839",
            "avatarIcon": " SHREE GANESH MULTISPECILITY HOSPITAL ( MANJARI) )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "Oxygen": " 12",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Vishwaraj Hospital",
            "Contact": "NA",
            "avatarIcon": " VISHWARAJ HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 47",
                "Oxygen": " 75",
                "ICU": " 8",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": " Remedy Hospital ( Hadapsar )",
            "Contact": "NA",
            "avatarIcon": " REMEDY HOSPITAL ( HADAPSAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 17",
                "ICU": " 1",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Pearl hospital ( Hadapsar )",
            "Contact": "020-24372008 /9860888892",
            "avatarIcon": " PEARL HOSPITAL ( HADAPSAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 14",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " CCC Attached SIlver line Hosp CCC",
            "Contact": "NA",
            "avatarIcon": " CCC ATTACHED SILVER LINE HOSP CCC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " shatayu hospital ( mundhawa )",
            "Contact": "+91 98186 46489, +91 20 6193 0044, +91 866 998 8365",
            "avatarIcon": " SHATAYU HOSPITAL ( MUNDHAWA )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Gawade Hospital Baramati",
            "Contact": "NA",
            "avatarIcon": " GAWADE HOSPITAL BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "Oxygen": " 24",
                "ICU": " 10",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Krushnadeep Hospital Kamshet",
            "Contact": "NA",
            "avatarIcon": " KRUSHNADEEP HOSPITAL KAMSHET",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " NOBLE HOSPITAL, ( Hadapsar )",
            "Contact": "7798838485",
            "avatarIcon": " NOBLE HOSPITAL, ( HADAPSAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 35",
                "Oxygen": " 190",
                "ICU": " 8",
                "Ventilator": " 37"
            }
        },
        {
            "hospitalName": " dhanwantari hospital ( dhanori)",
            "Contact": "NA",
            "avatarIcon": " DHANWANTARI HOSPITAL ( DHANORI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Dhakne Hospital Talegaon Dabhade",
            "Contact": "NA",
            "avatarIcon": " DHAKNE HOSPITAL TALEGAON DABHADE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "Oxygen": " 15",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Gurukrupa Hospital ( Dandekar Bridge )",
            "Contact": "0",
            "avatarIcon": " GURUKRUPA HOSPITAL ( DANDEKAR BRIDGE )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 7",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Mantri Hospital (Erandwane )",
            "Contact": "NA",
            "avatarIcon": " MANTRI HOSPITAL (ERANDWANE )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Surya Multispeciality Hospital, Chakan",
            "Contact": "9890276968",
            "avatarIcon": " SURYA MULTISPECIALITY HOSPITAL, CHAKAN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "Oxygen": " 8",
                "ICU": " 4",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Brahmhachaitanya Super Sp Hospital, Chinchwad",
            "Contact": "NA",
            "avatarIcon": " BRAHMHACHAITANYA SUPER SP HOSPITAL, CHINCHWAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 11",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Shree Multispeciality Hospital, Bhosari",
            "Contact": "NA",
            "avatarIcon": " SHREE MULTISPECIALITY HOSPITAL, BHOSARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 4",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Saileela Hospital, Pimpale Gurav",
            "Contact": "8087099040, 02114308380, 02114308386",
            "avatarIcon": " SAILEELA HOSPITAL, PIMPALE GURAV",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Saidham Multispeciality Hospital, Bhosari",
            "Contact": "NA",
            "avatarIcon": " SAIDHAM MULTISPECIALITY HOSPITAL, BHOSARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sabale Superspeciality Hosp, Bhosari",
            "Contact": "9022219098 ,9552414151",
            "avatarIcon": " SABALE SUPERSPECIALITY HOSP, BHOSARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 28",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Omkar Khalane Hospital, Rahatani",
            "Contact": "NA",
            "avatarIcon": " OMKAR KHALANE HOSPITAL, RAHATANI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 17",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Dhanvantari Hospital, Chikhali",
            "Contact": "NA",
            "avatarIcon": " DHANVANTARI HOSPITAL, CHIKHALI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 15",
                "ICU": " 6",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Vivekanand Hospital, Bhosari",
            "Contact": "9763095350",
            "avatarIcon": " VIVEKANAND HOSPITAL, BHOSARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "Oxygen": " 9",
                "ICU": " 3",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Oxycare Hospital, Walhekarwadi",
            "Contact": "NA",
            "avatarIcon": " OXYCARE HOSPITAL, WALHEKARWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 25",
                "Oxygen": " 8",
                "ICU": " 5",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Matoshri Hospital Vadgaon",
            "Contact": "NA",
            "avatarIcon": " MATOSHRI HOSPITAL VADGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 17",
                "Oxygen": " 0",
                "ICU": " 3",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " YASHORAHI MULTISPECILAITY HOSPITAL ( Shivne )",
            "Contact": "9822051716",
            "avatarIcon": " YASHORAHI MULTISPECILAITY HOSPITAL ( SHIVNE )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Tanmay Hospital ( Hadapsar )",
            "Contact": "NA",
            "avatarIcon": " TANMAY HOSPITAL ( HADAPSAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shree Hospital, Shikrapur",
            "Contact": "0",
            "avatarIcon": " SHREE HOSPITAL, SHIKRAPUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Khomane Hospital Baramati",
            "Contact": "NA",
            "avatarIcon": " KHOMANE HOSPITAL BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Onkar Hospital Baramati",
            "Contact": "NA",
            "avatarIcon": " ONKAR HOSPITAL BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Nilkanth Hospital, Indapur",
            "Contact": "8766437980",
            "avatarIcon": " NILKANTH HOSPITAL, INDAPUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 12",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Covid Care Centre Talegaon Dabhade",
            "Contact": "NA",
            "avatarIcon": " COVID CARE CENTRE TALEGAON DABHADE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 80",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Langhe Hospital, Shikrapur",
            "Contact": "NA",
            "avatarIcon": " LANGHE HOSPITAL, SHIKRAPUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "Oxygen": " 23",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": " Hotel Central Park Attached Hosp Sahyadri Deccan CCC",
            "Contact": "9960251560",
            "avatarIcon": " HOTEL CENTRAL PARK ATTACHED HOSP SAHYADRI DECCAN CCC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 35",
                "Oxygen": " 15",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shivnandan Hospital Baramati",
            "Contact": "NA",
            "avatarIcon": " SHIVNANDAN HOSPITAL BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 22",
                "Oxygen": " 8",
                "ICU": " 6",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Aadhar Multispecialty Hospital",
            "Contact": "9890886361",
            "avatarIcon": " AADHAR MULTISPECIALTY HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 22",
                "ICU": " 10",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Giridhar Hospital & Maternity Home",
            "Contact": "NA",
            "avatarIcon": " GIRIDHAR HOSPITAL & MATERNITY HOME",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 1",
                "ICU": " 4",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Maulinath Hospital Shikrapur",
            "Contact": "NA",
            "avatarIcon": " MAULINATH HOSPITAL SHIKRAPUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 20",
                "ICU": " 7",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Shree Chaitanya hospital Baramati",
            "Contact": "9822351796",
            "avatarIcon": " SHREE CHAITANYA HOSPITAL BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 22",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Phoenix Multispeciality Hospital Wagholi Pune",
            "Contact": "NA",
            "avatarIcon": " PHOENIX MULTISPECIALITY HOSPITAL WAGHOLI PUNE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 2",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " PARAS NURSING HOME ( Dhankawadi )",
            "Contact": "020-41403700",
            "avatarIcon": " PARAS NURSING HOME ( DHANKAWADI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 3",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sharad Hospital (sinhgad rd)",
            "Contact": "NA",
            "avatarIcon": " SHARAD HOSPITAL (SINHGAD RD)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " LIFELINE HOSPITAL WAGHOLI",
            "Contact": "NA",
            "avatarIcon": " LIFELINE HOSPITAL WAGHOLI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 20",
                "ICU": " 4",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Sinhgad Speciality Hospital,Nanded phata,Sinhagad Road Pune",
            "Contact": "020-24394410",
            "avatarIcon": " SINHGAD SPECIALITY HOSPITAL,NANDED PHATA,SINHAGAD ROAD PUNE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Anandi Jumbo Covid Care Center, Khalad",
            "Contact": "NA",
            "avatarIcon": " ANANDI JUMBO COVID CARE CENTER, KHALAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 92",
                "Oxygen": " 50",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Lokmanya Hospital-Gokhale Nagar",
            "Contact": "NA",
            "avatarIcon": " LOKMANYA HOSPITAL-GOKHALE NAGAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 31",
                "ICU": " 3",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Shah Accident Hospital ( Yerwada )",
            "Contact": "9545019848",
            "avatarIcon": " SHAH ACCIDENT HOSPITAL ( YERWADA )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " ANUP HOSPITAL ( Dattawadi )",
            "Contact": "NA",
            "avatarIcon": " ANUP HOSPITAL ( DATTAWADI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " 7",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Hardikar Hospital ( Shivaji nagar)",
            "Contact": "9284968321, 8208083738",
            "avatarIcon": " HARDIKAR HOSPITAL ( SHIVAJI NAGAR)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 1",
                "ICU": " 18",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " prakash hospital( hadapsar)",
            "Contact": "NA",
            "avatarIcon": " PRAKASH HOSPITAL( HADAPSAR)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 7",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " sane guruji aarogy kendra ( Hadapsar )",
            "Contact": "NA",
            "avatarIcon": " SANE GURUJI AAROGY KENDRA ( HADAPSAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 15",
                "ICU": " 3",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Agarwal Maternity & General Hospital (Chandan Nagar )",
            "Contact": "9028595874",
            "avatarIcon": " AGARWAL MATERNITY & GENERAL HOSPITAL (CHANDAN NAGAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 11",
                "Oxygen": " 23",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Ushakiran Hospital ( Hadapsar )",
            "Contact": "NA",
            "avatarIcon": " USHAKIRAN HOSPITAL ( HADAPSAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "Oxygen": " 9",
                "ICU": " 9",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Adhar Hospital, Shingave Avasari Budruk Ambegaon",
            "Contact": "8446650688",
            "avatarIcon": " ADHAR HOSPITAL, SHINGAVE AVASARI BUDRUK AMBEGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 11",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Allince Munot Hospital ( Bhawani Peth )",
            "Contact": "NA",
            "avatarIcon": " ALLINCE MUNOT HOSPITAL ( BHAWANI PETH )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 14",
                "ICU": " 8",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Chiatanya Hospital Koregaon Bhima Shirur",
            "Contact": "NA",
            "avatarIcon": " CHIATANYA HOSPITAL KOREGAON BHIMA SHIRUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 8",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Mahavir Hospital Kamshet",
            "Contact": "9975564133",
            "avatarIcon": " MAHAVIR HOSPITAL KAMSHET",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "Oxygen": " 14",
                "ICU": " 3",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " MJM Hospital ( Shivajinagar )",
            "Contact": "NA",
            "avatarIcon": " MJM HOSPITAL ( SHIVAJINAGAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 14",
                "ICU": " 3",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " RH Narayangaon",
            "Contact": "NA",
            "avatarIcon": " RH NARAYANGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " SANKALP HOSPITAL SHIRUR",
            "Contact": "7066963300 / 9967090083",
            "avatarIcon": " SANKALP HOSPITAL SHIRUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 14",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sushrut Hospital Rajgurunagar",
            "Contact": "NA",
            "avatarIcon": " SUSHRUT HOSPITAL RAJGURUNAGAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "Oxygen": " 10",
                "ICU": " 13",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Vighnaharta Multispeciality Hospital, Narayangoan",
            "Contact": "9021028646",
            "avatarIcon": " VIGHNAHARTA MULTISPECIALITY HOSPITAL, NARAYANGOAN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Bhakare Super Speciality Hospital & Research Institute ( Ambegaon BK)",
            "Contact": "NA",
            "avatarIcon": " BHAKARE SUPER SPECIALITY HOSPITAL & RESEARCH INSTITUTE ( AMBEGAON BK)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 28",
                "ICU": " 2",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": " Jahangir Hospital Pune ( Sangam wadi )",
            "Contact": "NA",
            "avatarIcon": " JAHANGIR HOSPITAL PUNE ( SANGAM WADI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 29",
                "Oxygen": " 112",
                "ICU": " 8",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Kohakade hospital (Chandan Nagar)",
            "Contact": "7387565123",
            "avatarIcon": " KOHAKADE HOSPITAL (CHANDAN NAGAR)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 39",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Om Hospital somatane phata",
            "Contact": "NA",
            "avatarIcon": " OM HOSPITAL SOMATANE PHATA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 7",
                "ICU": " 5",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " DHANVANTARI HOSPITAL SASWAD",
            "Contact": "NA",
            "avatarIcon": " DHANVANTARI HOSPITAL SASWAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 4",
                "ICU": " 1",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " SHREEYASH MULTISPECIALITY HOSPITAL KIRKATWADI",
            "Contact": "8805387387",
            "avatarIcon": " SHREEYASH MULTISPECIALITY HOSPITAL KIRKATWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Morya Multi-specialty Hospital ( Sinhgad rd )",
            "Contact": "NA",
            "avatarIcon": " MORYA MULTI-SPECIALTY HOSPITAL ( SINHGAD RD )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 64",
                "ICU": " 4",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " GANGA NURSING HOME (Mundhwa)",
            "Contact": "9960980990",
            "avatarIcon": " GANGA NURSING HOME (MUNDHWA)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 15",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " JAGTAP MULTISPECIALITY HOSPITAL ( Sinhgad rd)",
            "Contact": "NA",
            "avatarIcon": " JAGTAP MULTISPECIALITY HOSPITAL ( SINHGAD RD)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "Oxygen": " 25",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Vighnaharta Hospital Narhe",
            "Contact": "NA",
            "avatarIcon": " VIGHNAHARTA HOSPITAL NARHE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 11",
                "Oxygen": " 11",
                "ICU": " 4",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Vedant Hospital, Shirur, Pune",
            "Contact": "9822024041",
            "avatarIcon": " VEDANT HOSPITAL, SHIRUR, PUNE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "Oxygen": " 31",
                "ICU": " 5",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Dr Babasaheb Cantonment General Hospital Dehuroad/Covid Care Center MG School MB Camp Dehuroad",
            "Contact": "NA",
            "avatarIcon": " DR BABASAHEB CANTONMENT GENERAL HOSPITAL DEHUROAD/COVID CARE CENTER MG SCHOOL MB CAMP DEHUROAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 44",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " N M Wadia institute of Cardiology ( Sasson rd )",
            "Contact": "9689154522",
            "avatarIcon": " N M WADIA INSTITUTE OF CARDIOLOGY ( SASSON RD )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 19",
                "ICU": " 3",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Siddhivinayak Hospital, Nasrapur",
            "Contact": "NA",
            "avatarIcon": " SIDDHIVINAYAK HOSPITAL, NASRAPUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 2",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Jagannath Hospital Baramati",
            "Contact": "NA",
            "avatarIcon": " JAGANNATH HOSPITAL BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " District Hospital Aundh Pune",
            "Contact": "7385735137",
            "avatarIcon": " DISTRICT HOSPITAL AUNDH PUNE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 31",
                "ICU": " 8",
                "Ventilator": " 9"
            }
        },
        {
            "hospitalName": " K K Multispeciality Hospital, Alandi",
            "Contact": "NA",
            "avatarIcon": " K K MULTISPECIALITY HOSPITAL, ALANDI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " RH Velhe",
            "Contact": "NA",
            "avatarIcon": " RH VELHE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 17",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Bhandari Hospital( Anand nagar )",
            "Contact": "8788711413",
            "avatarIcon": " BHANDARI HOSPITAL( ANAND NAGAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 14",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sai Spandan Hospital ( Hadapsar )",
            "Contact": "NA",
            "avatarIcon": " SAI SPANDAN HOSPITAL ( HADAPSAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 15",
                "ICU": " 2",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Baramati Hospital, Baramati",
            "Contact": "9665914363",
            "avatarIcon": " BARAMATI HOSPITAL, BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 27",
                "Oxygen": " 38",
                "ICU": " 5",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Kamal Nayan Hospital ( Shukarwar Peth )",
            "Contact": "NA",
            "avatarIcon": " KAMAL NAYAN HOSPITAL ( SHUKARWAR PETH )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shashwat Hospital, Aundh",
            "Contact": "NA",
            "avatarIcon": " SHASHWAT HOSPITAL, AUNDH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 22",
                "ICU": " 4",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " sanjivani hospital, manchar Ambegaon",
            "Contact": "9822247627",
            "avatarIcon": " SANJIVANI HOSPITAL, MANCHAR AMBEGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 9",
                "ICU": " 7",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " ESIC Hospital ( Bibwewadi )",
            "Contact": "NA",
            "avatarIcon": " ESIC HOSPITAL ( BIBWEWADI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 26",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Oyster and Pearl Hospital ( Shivajinagar )",
            "Contact": "9822035935",
            "avatarIcon": " OYSTER AND PEARL HOSPITAL ( SHIVAJINAGAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 91",
                "ICU": " 5",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": " Rising Medicare Hospital ( Kharadi)",
            "Contact": "NA",
            "avatarIcon": " RISING MEDICARE HOSPITAL ( KHARADI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 48",
                "Oxygen": " 17",
                "ICU": " 11",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Arogya Criticare Hospital Baramati",
            "Contact": "NA",
            "avatarIcon": " AROGYA CRITICARE HOSPITAL BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 10",
                "ICU": " 1",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Sahyadri Hospital Karve Road Deccan",
            "Contact": "9011068866/7385509944",
            "avatarIcon": " SAHYADRI HOSPITAL KARVE ROAD DECCAN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 2",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " SDH Daund",
            "Contact": "NA",
            "avatarIcon": " SDH DAUND",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 15",
                "ICU": " 0",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": " AMRUT HOSPITAL ( Yerwada)",
            "Contact": "NA",
            "avatarIcon": " AMRUT HOSPITAL ( YERWADA)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " chaitanya hospital ( warje )",
            "Contact": "9421008333",
            "avatarIcon": " CHAITANYA HOSPITAL ( WARJE )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Meera Hospital ( Bhawani Peth )",
            "Contact": "NA",
            "avatarIcon": " MEERA HOSPITAL ( BHAWANI PETH )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 27",
                "Oxygen": " 15",
                "ICU": " 2",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " VAIBHAVI HOSPITAL ( HADAPSAR)",
            "Contact": "9822291506, 9960785224",
            "avatarIcon": " VAIBHAVI HOSPITAL ( HADAPSAR)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 16",
                "Oxygen": " 14",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " PRIME MULTISPECIALITY HOSPITAL ( Kondhwa )",
            "Contact": "NA",
            "avatarIcon": " PRIME MULTISPECIALITY HOSPITAL ( KONDHWA )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 18",
                "ICU": " 4",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " Chintamani Hospital, Koregaon",
            "Contact": "NA",
            "avatarIcon": " CHINTAMANI HOSPITAL, KOREGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 26",
                "ICU": " 7",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " RUBY HALL CLINIC ( Sasoon rd )",
            "Contact": "8380095717,9860888892",
            "avatarIcon": " RUBY HALL CLINIC ( SASOON RD )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 122",
                "Oxygen": " 234",
                "ICU": " 8",
                "Ventilator": " 39"
            }
        },
        {
            "hospitalName": " Gorad Hospital Baramati",
            "Contact": "NA",
            "avatarIcon": " GORAD HOSPITAL BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Kurkute Hospital ( Wadgaon Sheri)",
            "Contact": "NA",
            "avatarIcon": " KURKUTE HOSPITAL ( WADGAON SHERI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 3",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Medicare Hospital ( Hadapsar)",
            "Contact": "9921672119",
            "avatarIcon": " MEDICARE HOSPITAL ( HADAPSAR)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 19",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shraddha Hospital ( Mundhwa)",
            "Contact": "NA",
            "avatarIcon": " SHRADDHA HOSPITAL ( MUNDHWA)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 9",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Pawana Hospital, somatane phata",
            "Contact": "na",
            "avatarIcon": " PAWANA HOSPITAL, SOMATANE PHATA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 60",
                "ICU": " 5",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": " Deokate Hospital Baramati",
            "Contact": "NA",
            "avatarIcon": " DEOKATE HOSPITAL BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "Oxygen": " 4",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Shri Sant gadage maharaj primary school CCC (Kondhawa )",
            "Contact": "NA",
            "avatarIcon": " SHRI SANT GADAGE MAHARAJ PRIMARY SCHOOL CCC (KONDHAWA )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " SHRI GIRI HOSPITAL & HEART CARE CENTER ( Hadapsar 0",
            "Contact": "9975622923",
            "avatarIcon": " SHRI GIRI HOSPITAL & HEART CARE CENTER ( HADAPSAR 0",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 10",
                "ICU": " 1",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Ram Krushna Hospital ( Tilak rd )",
            "Contact": "NA",
            "avatarIcon": " RAM KRUSHNA HOSPITAL ( TILAK RD )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " GIRIJA HOSPITAL ( Kothrud)",
            "Contact": "8007992008",
            "avatarIcon": " GIRIJA HOSPITAL ( KOTHRUD)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Chinmay Hospital ( Uttamnagar )",
            "Contact": "NA",
            "avatarIcon": " CHINMAY HOSPITAL ( UTTAMNAGAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 2",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Vinod Memorial Hospital ( Visharant wadi )",
            "Contact": "NA",
            "avatarIcon": " VINOD MEMORIAL HOSPITAL ( VISHARANT WADI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Life Line Hospital (Fursungi)",
            "Contact": "7038119200",
            "avatarIcon": " LIFE LINE HOSPITAL (FURSUNGI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 7",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": " PARMAR HOSP ( AUNDH )",
            "Contact": "NA",
            "avatarIcon": " PARMAR HOSP ( AUNDH )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 12",
                "ICU": " 10",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " city hospital ( kothrud)",
            "Contact": "NA",
            "avatarIcon": " CITY HOSPITAL ( KOTHRUD)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 23",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " CARE HOSPITAL WAGHOLI",
            "Contact": "9766959515",
            "avatarIcon": " CARE HOSPITAL WAGHOLI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "Oxygen": " 20",
                "ICU": " 3",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Adhar hospital ( Hadapsar )",
            "Contact": "NA",
            "avatarIcon": " ADHAR HOSPITAL ( HADAPSAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 12",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sahyadri Hospital Kothrud",
            "Contact": "7620960342",
            "avatarIcon": " SAHYADRI HOSPITAL KOTHRUD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 9",
                "ICU": " 1",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " lifeline hospital kondhwa",
            "Contact": "NA",
            "avatarIcon": " LIFELINE HOSPITAL KONDHWA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " 6",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " NIXI ICU & ACCIDENT HOSPITAL",
            "Contact": "NA",
            "avatarIcon": " NIXI ICU & ACCIDENT HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 13",
                "ICU": " 4",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Patil Hospital DCHC Choufula Daund",
            "Contact": "080-43694671",
            "avatarIcon": " PATIL HOSPITAL DCHC CHOUFULA DAUND",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 13",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Surya Prabha Nursing home ( Kothrud )",
            "Contact": "NA",
            "avatarIcon": " SURYA PRABHA NURSING HOME ( KOTHRUD )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " HORIZON HEALTH HOSPITAL ( Fursungi )",
            "Contact": "NA",
            "avatarIcon": " HORIZON HEALTH HOSPITAL ( FURSUNGI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 12",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shinde Nursing Home ( Bhawani Peth )",
            "Contact": "8080262001",
            "avatarIcon": " SHINDE NURSING HOME ( BHAWANI PETH )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 4",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shree Hospital Shirur",
            "Contact": "NA",
            "avatarIcon": " SHREE HOSPITAL SHIRUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " sathe Hospital ( Lohgaon )",
            "Contact": "8605195775",
            "avatarIcon": " SATHE HOSPITAL ( LOHGAON )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 12",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Pawar Hospital ( Dhankawadi )",
            "Contact": "NA",
            "avatarIcon": " PAWAR HOSPITAL ( DHANKAWADI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Symbiosis Hospital ( Lavale)",
            "Contact": "NA",
            "avatarIcon": " SYMBIOSIS HOSPITAL ( LAVALE)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 65",
                "Oxygen": " 75",
                "ICU": " 12",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": " SILVER BIRCH MULTISPECIALITY HOSPITAL ( Dhayari)",
            "Contact": "7447430043",
            "avatarIcon": " SILVER BIRCH MULTISPECIALITY HOSPITAL ( DHAYARI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 53",
                "ICU": " 2",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": " Pulse Hospital, Narhe, Pune",
            "Contact": "NA",
            "avatarIcon": " PULSE HOSPITAL, NARHE, PUNE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "Oxygen": " 13",
                "ICU": " 3",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " INLAKS & BUDHARANI HOSPITAL ( Koregaon park)",
            "Contact": "7558585866",
            "avatarIcon": " INLAKS & BUDHARANI HOSPITAL ( KOREGAON PARK)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 54",
                "Oxygen": " 116",
                "ICU": " 6",
                "Ventilator": " 24"
            }
        },
        {
            "hospitalName": " Dr B. A. Cantonment General Hospital, Khadki",
            "Contact": "NA",
            "avatarIcon": " DR B. A. CANTONMENT GENERAL HOSPITAL, KHADKI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 77",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Bhimashankar Ayurvedic Hospital Research Centre Ambegaon",
            "Contact": "NA",
            "avatarIcon": " BHIMASHANKAR AYURVEDIC HOSPITAL RESEARCH CENTRE AMBEGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "Oxygen": " 80",
                "ICU": " 9",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Bhakt Niwas, Devsthan Trust",
            "Contact": "9860011625",
            "avatarIcon": " BHAKT NIWAS, DEVSTHAN TRUST",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 131",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " MIMER Hospital",
            "Contact": "NA",
            "avatarIcon": " MIMER HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 204",
                "Oxygen": " 124",
                "ICU": " 15",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Harjeevan Hospital, Bholawade, Bhor",
            "Contact": "NA",
            "avatarIcon": " HARJEEVAN HOSPITAL, BHOLAWADE, BHOR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 8",
                "ICU": " 8",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Covid Care Center Tolani, Indori Hospital",
            "Contact": "8308838989",
            "avatarIcon": " COVID CARE CENTER TOLANI, INDORI HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 100",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shri Sant Tukaram Hospital Dehuroad",
            "Contact": "NA",
            "avatarIcon": " SHRI SANT TUKARAM HOSPITAL DEHUROAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 15",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Malegaon Covid Care Center",
            "Contact": "9890725827 9921725827",
            "avatarIcon": " MALEGAON COVID CARE CENTER",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 241",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " BHARATI HOSPITAL ( Dhankawadi )",
            "Contact": "NA",
            "avatarIcon": " BHARATI HOSPITAL ( DHANKAWADI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 186",
                "ICU": " 10",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Critical health care facility, Pune ( Rasta Peth)",
            "Contact": "NA",
            "avatarIcon": " CRITICAL HEALTH CARE FACILITY, PUNE ( RASTA PETH)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " 0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " Panchsheel Hospital ( NANA PETH )",
            "Contact": "9850125517",
            "avatarIcon": " PANCHSHEEL HOSPITAL ( NANA PETH )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Medical College Campus ,Baramati",
            "Contact": "NA",
            "avatarIcon": " MEDICAL COLLEGE CAMPUS ,BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 127",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " OM HOSPITAL, Pragaon Shingave,Ambegaon",
            "Contact": "7020086597",
            "avatarIcon": " OM HOSPITAL, PRAGAON SHINGAVE,AMBEGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 3",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " DR Varpe Hospital Manchar Ambegaon",
            "Contact": "NA",
            "avatarIcon": " DR VARPE HOSPITAL MANCHAR AMBEGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Maxcare Hospital Narayangaon",
            "Contact": "NA",
            "avatarIcon": " MAXCARE HOSPITAL NARAYANGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "Oxygen": " 5",
                "ICU": " 8",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Kalyani Hospital ( Guruwar Peth )",
            "Contact": "9881435523, 9766990820",
            "avatarIcon": " KALYANI HOSPITAL ( GURUWAR PETH )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 3",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Bade Hospital Kamshet",
            "Contact": "NA",
            "avatarIcon": " BADE HOSPITAL KAMSHET",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 18",
                "ICU": " 12",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": " krishna hospital ( Kothrud)",
            "Contact": "NA",
            "avatarIcon": " KRISHNA HOSPITAL ( KOTHRUD)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " 3",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Devyani multispeciality Hospital ( Kothrud)",
            "Contact": "9822037000/9372600000/ 9372619218",
            "avatarIcon": " DEVYANI MULTISPECIALITY HOSPITAL ( KOTHRUD)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 22",
                "Oxygen": " 69",
                "ICU": " 3",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Late Muralidhar Pandurang Laygude Hospital ( Dhayari)",
            "Contact": "NA",
            "avatarIcon": " LATE MURALIDHAR PANDURANG LAYGUDE HOSPITAL ( DHAYARI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Giriraj Hospital, Baramati",
            "Contact": "9226837010",
            "avatarIcon": " GIRIRAJ HOSPITAL, BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Pushpalaxmi Hospital, Daund",
            "Contact": "NA",
            "avatarIcon": " PUSHPALAXMI HOSPITAL, DAUND",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " 1",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Matoshree Hospital, Manchar ambegaon",
            "Contact": "NA",
            "avatarIcon": " MATOSHREE HOSPITAL, MANCHAR AMBEGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 5",
                "ICU": " 5",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Patil Hospital ( Dattananagar )",
            "Contact": "9867796743",
            "avatarIcon": " PATIL HOSPITAL ( DATTANANAGAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Dr.Gujrati Hospital Manchar Ambegaon",
            "Contact": "NA",
            "avatarIcon": " DR.GUJRATI HOSPITAL MANCHAR AMBEGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 2",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Ganesh Kala Krida Covid Center (Swargate)",
            "Contact": "NA",
            "avatarIcon": " GANESH KALA KRIDA COVID CENTER (SWARGATE)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " 5",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Bharati Hospital ( Ayurvedic ) Dhankawadi",
            "Contact": "8275964116",
            "avatarIcon": " BHARATI HOSPITAL ( AYURVEDIC ) DHANKAWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "Oxygen": " 12",
                "ICU": " 6",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Suryakant Hospital, Junnar",
            "Contact": "NA",
            "avatarIcon": " SURYAKANT HOSPITAL, JUNNAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 17",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shantabai Deshpande Hospital Baramati",
            "Contact": "8856036290",
            "avatarIcon": " SHANTABAI DESHPANDE HOSPITAL BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "Oxygen": " 28",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sushrut Hospital ( Kothrud )",
            "Contact": "NA",
            "avatarIcon": " SUSHRUT HOSPITAL ( KOTHRUD )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " MAHESH SMRUTI Multi Specialty HOSPITAL SHEWALWADI",
            "Contact": "NA",
            "avatarIcon": " MAHESH SMRUTI MULTI SPECIALTY HOSPITAL SHEWALWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "Oxygen": " 14",
                "ICU": " 7",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": " Naidu Hospital ( Wellesley Rd )",
            "Contact": "9021229340",
            "avatarIcon": " NAIDU HOSPITAL ( WELLESLEY RD )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 70",
                "ICU": " 0",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": " Shree ganesha multispeciality hospital",
            "Contact": "NA",
            "avatarIcon": " SHREE GANESHA MULTISPECIALITY HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Sanjivani Hospital,Lonavala",
            "Contact": "9730786201",
            "avatarIcon": " SANJIVANI HOSPITAL,LONAVALA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " Shree samarth Hospital ( Rasta Peth)",
            "Contact": "NA",
            "avatarIcon": " SHREE SAMARTH HOSPITAL ( RASTA PETH)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 49",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": " Spandan Hospital, Alephata, Junnar",
            "Contact": "NA",
            "avatarIcon": " SPANDAN HOSPITAL, ALEPHATA, JUNNAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 13",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " RH Nimgaon Ketki",
            "Contact": "020-27992799",
            "avatarIcon": " RH NIMGAON KETKI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Todkar Hospital ( Bhavani Peth)",
            "Contact": "NA",
            "avatarIcon": " TODKAR HOSPITAL ( BHAVANI PETH)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 12",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " SDH Baramati",
            "Contact": "NA",
            "avatarIcon": " SDH BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 75",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " ANAND HOSP ( DHANKAWADI)",
            "Contact": "9922930195",
            "avatarIcon": " ANAND HOSP ( DHANKAWADI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Atharva Hospital, Talegaon",
            "Contact": "NA",
            "avatarIcon": " ATHARVA HOSPITAL, TALEGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 17",
                "Oxygen": " 28",
                "ICU": " 10",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Harsh Hospital ( Bibwewadi)",
            "Contact": "9769447714",
            "avatarIcon": " HARSH HOSPITAL ( BIBWEWADI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " KEM HOSPITAL ( Rasta Peth )",
            "Contact": "NA",
            "avatarIcon": " KEM HOSPITAL ( RASTA PETH )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 156",
                "ICU": " 0",
                "Ventilator": " 11"
            }
        },
        {
            "hospitalName": " Sanjeevani Chest and General Hospital",
            "Contact": "NA",
            "avatarIcon": " SANJEEVANI CHEST AND GENERAL HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sasoon ( Station Rd )",
            "Contact": "9822634464 9657708625",
            "avatarIcon": " SASOON ( STATION RD )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 38",
                "ICU": " 0",
                "Ventilator": " 34"
            }
        },
        {
            "hospitalName": " I MAX HOSPITAL WAGHOLI",
            "Contact": "NA",
            "avatarIcon": " I MAX HOSPITAL WAGHOLI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 25",
                "Oxygen": " 46",
                "ICU": " 7",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Bora Hospital ( Ganesh Peth )",
            "Contact": "NA",
            "avatarIcon": " BORA HOSPITAL ( GANESH PETH )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Lotus Multispeciality Hospital, Manjari, Haveli",
            "Contact": "9890510426",
            "avatarIcon": " LOTUS MULTISPECIALITY HOSPITAL, MANJARI, HAVELI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 10",
                "ICU": " 2",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Rao Nursing Home ( Bibwewadi)",
            "Contact": "NA",
            "avatarIcon": " RAO NURSING HOME ( BIBWEWADI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "Oxygen": " 18",
                "ICU": " 0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " Yash Hospital Hadpasr",
            "Contact": "7887375649",
            "avatarIcon": " YASH HOSPITAL HADPASR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "Oxygen": " 35",
                "ICU": " 9",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " RH Vadgaon Maval",
            "Contact": "NA",
            "avatarIcon": " RH VADGAON MAVAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Mathura Hospital ( Yerawada)",
            "Contact": "NA",
            "avatarIcon": " MATHURA HOSPITAL ( YERAWADA)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Mahesh Memorial Hospital, Alephata Junnar",
            "Contact": "02133 223152",
            "avatarIcon": " MAHESH MEMORIAL HOSPITAL, ALEPHATA JUNNAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 4",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " suraj hospital (kondhwa rd)",
            "Contact": "NA",
            "avatarIcon": " SURAJ HOSPITAL (KONDHWA RD)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Universal Hospital ( Shaniwar wada )",
            "Contact": "02071290152, 02071290283",
            "avatarIcon": " UNIVERSAL HOSPITAL ( SHANIWAR WADA )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 25",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": " Karegaon MIDC Hall, Shirur",
            "Contact": "NA",
            "avatarIcon": " KAREGAON MIDC HALL, SHIRUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 49",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Dalvi Hospital ( Shivajinagar )",
            "Contact": "NA",
            "avatarIcon": " DALVI HOSPITAL ( SHIVAJINAGAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 104",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Medipoint hospital ( Aundh)",
            "Contact": "9822047714",
            "avatarIcon": " MEDIPOINT HOSPITAL ( AUNDH)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 37",
                "ICU": " 5",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": " aarya multispecity hospital(kondhwa)",
            "Contact": "NA",
            "avatarIcon": " AARYA MULTISPECITY HOSPITAL(KONDHWA)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 20",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " SDH Bhor",
            "Contact": "NA",
            "avatarIcon": " SDH BHOR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 31",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " SDH Indapur",
            "Contact": "088062 52525",
            "avatarIcon": " SDH INDAPUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 88",
                "ICU": " 0",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": " Shree Hospital Baramati",
            "Contact": "NA",
            "avatarIcon": " SHREE HOSPITAL BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 15",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Hope hospital ( New Nana peth )",
            "Contact": "9225825461",
            "avatarIcon": " HOPE HOSPITAL ( NEW NANA PETH )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shree seva Hospital ( Fursungi )",
            "Contact": "NA",
            "avatarIcon": " SHREE SEVA HOSPITAL ( FURSUNGI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 12",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shatayu Hospital ( Wadgaon )",
            "Contact": "NA",
            "avatarIcon": " SHATAYU HOSPITAL ( WADGAON )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 4",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " RH Shirur",
            "Contact": "8806666063/26064996/26061519",
            "avatarIcon": " RH SHIRUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 27",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Arogyam Hospital, Chakan",
            "Contact": "NA",
            "avatarIcon": " AROGYAM HOSPITAL, CHAKAN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "Oxygen": " 23",
                "ICU": " 4",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Bhakti Hospital Sanaswadi",
            "Contact": "9822331088",
            "avatarIcon": " BHAKTI HOSPITAL SANASWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 10",
                "ICU": " 5",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Dhande Hospital,Junnar",
            "Contact": "NA",
            "avatarIcon": " DHANDE HOSPITAL,JUNNAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 9",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Jaihind Hospital, Chakan",
            "Contact": "NA",
            "avatarIcon": " JAIHIND HOSPITAL, CHAKAN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 17",
                "Oxygen": " 18",
                "ICU": " 10",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Jivanrekha Multispeciality Hospital, Dehuroad, Pune",
            "Contact": "020-41097777 / 8087634507",
            "avatarIcon": " JIVANREKHA MULTISPECIALITY HOSPITAL, DEHUROAD, PUNE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 24",
                "Oxygen": " 35",
                "ICU": " 4",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Manchar City Hospital Manchar Ambegaon",
            "Contact": "NA",
            "avatarIcon": " MANCHAR CITY HOSPITAL MANCHAR AMBEGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Manisha Hospital, Junnar",
            "Contact": "NA",
            "avatarIcon": " MANISHA HOSPITAL, JUNNAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Matruchhaya Hospital Shirur",
            "Contact": "020-24369032/33",
            "avatarIcon": " MATRUCHHAYA HOSPITAL SHIRUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 3",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " PANSARE HOSPITAL,Daund",
            "Contact": "NA",
            "avatarIcon": " PANSARE HOSPITAL,DAUND",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "Oxygen": " 4",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " RH Saswad",
            "Contact": "020-66096000",
            "avatarIcon": " RH SASWAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Shree Hospital (Shikrapur)",
            "Contact": "NA",
            "avatarIcon": " SHREE HOSPITAL (SHIKRAPUR)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shree Shivraj Nursing Home Baramati",
            "Contact": "NA",
            "avatarIcon": " SHREE SHIVRAJ NURSING HOME BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 11",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shreenath Hospital wadki",
            "Contact": "020-29525922 /",
            "avatarIcon": " SHREENATH HOSPITAL WADKI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 4",
                "ICU": " 7",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shri Vinayak Hospital,Manchar ambegaon",
            "Contact": "NA",
            "avatarIcon": " SHRI VINAYAK HOSPITAL,MANCHAR AMBEGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 2",
                "ICU": " 7",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " SIDDHIVINAYAK HOSPITAL NARHE",
            "Contact": "NA",
            "avatarIcon": " SIDDHIVINAYAK HOSPITAL NARHE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Talawade hospital",
            "Contact": "9922441114",
            "avatarIcon": " TALAWADE HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 3",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Tulja Bhavani Hospital, Junnar",
            "Contact": "NA",
            "avatarIcon": " TULJA BHAVANI HOSPITAL, JUNNAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Bharatiya Jain Sanghatana(BJS), Wagholi",
            "Contact": "7887881499",
            "avatarIcon": " BHARATIYA JAIN SANGHATANA(BJS), WAGHOLI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 191",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " INAMDAR Multi Specialty HOSPITAL ( Fatima Nagar )",
            "Contact": "NA",
            "avatarIcon": " INAMDAR MULTI SPECIALTY HOSPITAL ( FATIMA NAGAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 17",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Yogeshwari Hospital",
            "Contact": "NA",
            "avatarIcon": " YOGESHWARI HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 29",
                "Oxygen": " 9",
                "ICU": " 6",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " Varadvinayak Multi Speciality Hospital Mandavgan Pharata",
            "Contact": "9850227365",
            "avatarIcon": " VARADVINAYAK MULTI SPECIALITY HOSPITAL MANDAVGAN PHARATA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 2",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " ZP Covid Hospital, Hinjewadi, Pune",
            "Contact": "NA",
            "avatarIcon": " ZP COVID HOSPITAL, HINJEWADI, PUNE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 102",
                "Oxygen": " 38",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " CCC Velha ITI Kondhawale",
            "Contact": "27607777",
            "avatarIcon": " CCC VELHA ITI KONDHAWALE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 36",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Lenyadri Hospital",
            "Contact": "NA",
            "avatarIcon": " LENYADRI HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 305",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sonawane Maternity Hospital ( Kashewadi )",
            "Contact": "NA",
            "avatarIcon": " SONAWANE MATERNITY HOSPITAL ( KASHEWADI )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 25",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Om Chaitanya Hospital, Alephata, junnar",
            "Contact": "9552530201",
            "avatarIcon": " OM CHAITANYA HOSPITAL, ALEPHATA, JUNNAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 35",
                "Oxygen": " 15",
                "ICU": " 5",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Aura Multispeciality Hospital,Ambegaon",
            "Contact": "NA",
            "avatarIcon": " AURA MULTISPECIALITY HOSPITAL,AMBEGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 23",
                "ICU": " 10",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Adhar Hospital (paravti gaon)",
            "Contact": "NA",
            "avatarIcon": " ADHAR HOSPITAL (PARAVTI GAON)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Astang Multispeciality Hospital ( Sadashiv Peth )",
            "Contact": "9850072473",
            "avatarIcon": " ASTANG MULTISPECIALITY HOSPITAL ( SADASHIV PETH )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Jupiter Hospital ( Baner )",
            "Contact": "NA",
            "avatarIcon": " JUPITER HOSPITAL ( BANER )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 52",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Rh Alandi",
            "Contact": "9834959803",
            "avatarIcon": " RH ALANDI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 17",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " DR BABASHEB AMBEDKAR AAROGYA SEVA KENDRA",
            "Contact": "NA",
            "avatarIcon": " DR BABASHEB AMBEDKAR AAROGYA SEVA KENDRA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 171",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sanjeevani Hospital Kamshet",
            "Contact": "NA",
            "avatarIcon": " SANJEEVANI HOSPITAL KAMSHET",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 2",
                "ICU": " 3",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Dr Babasaheb Ambedkar Dehuroad Cantonment General Hospital Dehuroad",
            "Contact": "9325780107",
            "avatarIcon": " DR BABASAHEB AMBEDKAR DEHUROAD CANTONMENT GENERAL HOSPITAL DEHUROAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "Oxygen": " 30",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Amrut Hospital and Research Center, Alandi",
            "Contact": "NA",
            "avatarIcon": " AMRUT HOSPITAL AND RESEARCH CENTER, ALANDI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Railway Hospital ( Station rd )",
            "Contact": "8788218882",
            "avatarIcon": " RAILWAY HOSPITAL ( STATION RD )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 35",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " RH Rui",
            "Contact": "NA",
            "avatarIcon": " RH RUI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 18",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " RH Chandoli",
            "Contact": "NA",
            "avatarIcon": " RH CHANDOLI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 24",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Late Draupadabai Muralidhar Khedekar Hospital ( Bopodi)",
            "Contact": "9075513707",
            "avatarIcon": " LATE DRAUPADABAI MURALIDHAR KHEDEKAR HOSPITAL ( BOPODI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 29",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " SDH Manchar Ambegaon",
            "Contact": "NA",
            "avatarIcon": " SDH MANCHAR AMBEGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 27",
                "Oxygen": " 22",
                "ICU": " 2",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": " Columbia Asia Hospital Kharadi, Pune",
            "Contact": "NA",
            "avatarIcon": " COLUMBIA ASIA HOSPITAL KHARADI, PUNE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 19",
                "Oxygen": " 44",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": " Sathe Hospital ( Deccan Gymkhana )",
            "Contact": "9422879755",
            "avatarIcon": " SATHE HOSPITAL ( DECCAN GYMKHANA )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 3",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Londhe Hospital Baramati",
            "Contact": "NA",
            "avatarIcon": " LONDHE HOSPITAL BARAMATI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 4",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " AIMS Hospital & Research Center ( Aundh)",
            "Contact": "9881268787",
            "avatarIcon": " AIMS HOSPITAL & RESEARCH CENTER ( AUNDH)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 82",
                "ICU": " 9",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " SAHYADRI HOSPITAL NAGAR ROAD",
            "Contact": "NA",
            "avatarIcon": " SAHYADRI HOSPITAL NAGAR ROAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 85",
                "Oxygen": " 41",
                "ICU": " 5",
                "Ventilator": " 9"
            }
        },
        {
            "hospitalName": " Patil Hospital,(Sinhgad Road) Pune",
            "Contact": "NA",
            "avatarIcon": " PATIL HOSPITAL,(SINHGAD ROAD) PUNE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 28",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Villoo Poonawala Memorial Hospital ( Hadapsar )",
            "Contact": "9823089264",
            "avatarIcon": " VILLOO POONAWALA MEMORIAL HOSPITAL ( HADAPSAR )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 22",
                "Oxygen": " 17",
                "ICU": " 1",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Dhanawantari hospital ( Rasta peth )",
            "Contact": "NA",
            "avatarIcon": " DHANAWANTARI HOSPITAL ( RASTA PETH )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 1",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " khaire hospital Narayangaon",
            "Contact": "NA",
            "avatarIcon": " KHAIRE HOSPITAL NARAYANGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Medi Square Hospital Perne",
            "Contact": "9284971406",
            "avatarIcon": " MEDI SQUARE HOSPITAL PERNE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 21",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Ratna Memorial Hospital ( Senapati Bapat rd )",
            "Contact": "NA",
            "avatarIcon": " RATNA MEMORIAL HOSPITAL ( SENAPATI BAPAT RD )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "Oxygen": " 17",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shwas Multispeciality Hospital ( ambegaon bk )",
            "Contact": "7796663366",
            "avatarIcon": " SHWAS MULTISPECIALITY HOSPITAL ( AMBEGAON BK )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 26",
                "ICU": " 6",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Jeevandhara Multispeciality Hospital ( Katraj)",
            "Contact": "NA",
            "avatarIcon": " JEEVANDHARA MULTISPECIALITY HOSPITAL ( KATRAJ)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 35",
                "ICU": " 4",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Poona Hospital ( Sadashiv Peth )",
            "Contact": "NA",
            "avatarIcon": " POONA HOSPITAL ( SADASHIV PETH )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 66",
                "ICU": " 1",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Chintamani Hospital, Saswad",
            "Contact": "8554990324",
            "avatarIcon": " CHINTAMANI HOSPITAL, SASWAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 19",
                "Oxygen": " 24",
                "ICU": " 5",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Aundh - baner DCH",
            "Contact": "NA",
            "avatarIcon": " AUNDH - BANER DCH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 130",
                "ICU": " 9",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Apple Hospital 2 ( Wadgaon Shinde rd, Lohgaon )",
            "Contact": "9552594775",
            "avatarIcon": " APPLE HOSPITAL 2 ( WADGAON SHINDE RD, LOHGAON )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 5",
                "ICU": " 3",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Global Hospital (Sinhgad rd )",
            "Contact": "NA",
            "avatarIcon": " GLOBAL HOSPITAL (SINHGAD RD )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 24",
                "ICU": " 1",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Chandralok hospital ( Bibwewadi)",
            "Contact": "NA",
            "avatarIcon": " CHANDRALOK HOSPITAL ( BIBWEWADI)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "Oxygen": " 5",
                "ICU": " 4",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Sterling Multispeciality Hospital, Akurdi",
            "Contact": "7030800300",
            "avatarIcon": " STERLING MULTISPECIALITY HOSPITAL, AKURDI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 41",
                "Oxygen": " 29",
                "ICU": " 17",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": " Rode Hospital, Dighi",
            "Contact": "NA",
            "avatarIcon": " RODE HOSPITAL, DIGHI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Niramay Hospital, Chinchwad",
            "Contact": "NA",
            "avatarIcon": " NIRAMAY HOSPITAL, CHINCHWAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Moraya Hospital, Chinchwadgaon",
            "Contact": "9923231681",
            "avatarIcon": " MORAYA HOSPITAL, CHINCHWADGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 15",
                "ICU": " 3",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": " Medilife Hospital, Kalewadi",
            "Contact": "NA",
            "avatarIcon": " MEDILIFE HOSPITAL, KALEWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 18",
                "ICU": " 4",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Mankikar Hospital, Bhsoari",
            "Contact": "9922736052",
            "avatarIcon": " MANKIKAR HOSPITAL, BHSOARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Lokmanya Hospital, Nigdi",
            "Contact": "NA",
            "avatarIcon": " LOKMANYA HOSPITAL, NIGDI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 17",
                "Oxygen": " 28",
                "ICU": " 17",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": " Kamthe Hospital, Dighi",
            "Contact": "NA",
            "avatarIcon": " KAMTHE HOSPITAL, DIGHI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Jeevan Jyoti Hospital, Kalewadi",
            "Contact": "9767589578",
            "avatarIcon": " JEEVAN JYOTI HOSPITAL, KALEWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 30",
                "ICU": " 6",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Jeevan Hospital Moshi",
            "Contact": "NA",
            "avatarIcon": " JEEVAN HOSPITAL MOSHI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 11",
                "Oxygen": " 0",
                "ICU": " 4",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Jaihind Hospital, Bhosari",
            "Contact": "NA",
            "avatarIcon": " JAIHIND HOSPITAL, BHOSARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 6",
                "ICU": " 5",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Jadhav Hospital, Kalewadi",
            "Contact": "9011023332",
            "avatarIcon": " JADHAV HOSPITAL, KALEWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 6",
                "ICU": " 4",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Helios Bharti Hospital, Kalewadi",
            "Contact": "NA",
            "avatarIcon": " HELIOS BHARTI HOSPITAL, KALEWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 2",
                "ICU": " 3",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Health Care Hospital, Chikhali",
            "Contact": "8669665293",
            "avatarIcon": " HEALTH CARE HOSPITAL, CHIKHALI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 11",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Gunjkar Multispeciality Hospital, Chikhali",
            "Contact": "NA",
            "avatarIcon": " GUNJKAR MULTISPECIALITY HOSPITAL, CHIKHALI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 58",
                "Oxygen": " 31",
                "ICU": " 5",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": " Golden Care Hosp,Wakad",
            "Contact": "NA",
            "avatarIcon": " GOLDEN CARE HOSP,WAKAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 21",
                "Oxygen": " 7",
                "ICU": " 8",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Global Hospital, Wakad",
            "Contact": "9172259047",
            "avatarIcon": " GLOBAL HOSPITAL, WAKAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 8",
                "ICU": " 14",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": " Ganadhish Hospital, Chinchwad",
            "Contact": "NA",
            "avatarIcon": " GANADHISH HOSPITAL, CHINCHWAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 26",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Gaikwad Diabetes Centre",
            "Contact": "9762888910",
            "avatarIcon": " GAIKWAD DIABETES CENTRE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "Oxygen": " 0",
                "ICU": " 3",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Divine Superspeciality Hospital, Morwadi",
            "Contact": "NA",
            "avatarIcon": " DIVINE SUPERSPECIALITY HOSPITAL, MORWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 15",
                "ICU": " 10",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " D.Y. Patil Ayurved Hospital, Pimpri",
            "Contact": "NA",
            "avatarIcon": " D.Y. PATIL AYURVED HOSPITAL, PIMPRI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 207",
                "Oxygen": " 50",
                "ICU": " 12",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Citi Care Hospital, Pimpri",
            "Contact": "9922736052",
            "avatarIcon": " CITI CARE HOSPITAL, PIMPRI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 9",
                "ICU": " 13",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Chetna Hospital, MIDC Chinchwad",
            "Contact": "NA",
            "avatarIcon": " CHETNA HOSPITAL, MIDC CHINCHWAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "Oxygen": " 21",
                "ICU": " 3",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Chavan Hospital, Pimpri",
            "Contact": "NA",
            "avatarIcon": " CHAVAN HOSPITAL, PIMPRI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 8",
                "ICU": " 3",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Chaudhari Hopsital, Chikhali",
            "Contact": "9075965550",
            "avatarIcon": " CHAUDHARI HOPSITAL, CHIKHALI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 11",
                "ICU": " 6",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Care Lifeline Hospitals, Thergaon",
            "Contact": "NA",
            "avatarIcon": " CARE LIFELINE HOSPITALS, THERGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 10",
                "ICU": " 5",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Bharti Hospital, Pimple Saudagar",
            "Contact": "9879888192",
            "avatarIcon": " BHARTI HOSPITAL, PIMPLE SAUDAGAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 7",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Ayushri Hospital, Pimpri",
            "Contact": "NA",
            "avatarIcon": " AYUSHRI HOSPITAL, PIMPRI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 8",
                "ICU": " 5",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Ayush Hospital, Neharunagar",
            "Contact": "NA",
            "avatarIcon": " AYUSH HOSPITAL, NEHARUNAGAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 28",
                "Oxygen": " 7",
                "ICU": " 6",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " Ashraya Hospital, Wakad",
            "Contact": "9561023231",
            "avatarIcon": " ASHRAYA HOSPITAL, WAKAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "Oxygen": " 15",
                "ICU": " 2",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Apple Multispeciality Hospital, Chikhali",
            "Contact": "NA",
            "avatarIcon": " APPLE MULTISPECIALITY HOSPITAL, CHIKHALI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 4",
                "ICU": " 2",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Apex Hospital, Kalewadi",
            "Contact": "9881729591",
            "avatarIcon": " APEX HOSPITAL, KALEWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 4",
                "ICU": " 5",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Anand Multispeciality, Bhosari",
            "Contact": "NA",
            "avatarIcon": " ANAND MULTISPECIALITY, BHOSARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 20",
                "ICU": " 5",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Desai Hospital, Bhosari",
            "Contact": "NA",
            "avatarIcon": " DESAI HOSPITAL, BHOSARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 23",
                "Oxygen": " 15",
                "ICU": " 6",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " SPM School, Yamunanagar (Star Hospital)",
            "Contact": "9881729591",
            "avatarIcon": " SPM SCHOOL, YAMUNANAGAR (STAR HOSPITAL)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 50",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Social Justice Departments Boys Hostel, Moshi (Trust Health Care)",
            "Contact": "NA",
            "avatarIcon": " SOCIAL JUSTICE DEPARTMENTS BOYS HOSTEL, MOSHI (TRUST HEALTH CARE)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 188",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " SKF Copany Guest House (Employee Only)",
            "Contact": "NA",
            "avatarIcon": " SKF COPANY GUEST HOUSE (EMPLOYEE ONLY)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sai Darshan Building, Pimple Gurav (Ayushri Hospiital)",
            "Contact": "9422555055",
            "avatarIcon": " SAI DARSHAN BUILDING, PIMPLE GURAV (AYUSHRI HOSPIITAL)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 47",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Hotel Regal Inn, Morewadi (Ayush Hosp)",
            "Contact": "NA",
            "avatarIcon": " HOTEL REGAL INN, MOREWADI (AYUSH HOSP)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 46",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Hotel Pioneer (Nandedkar Hosp)",
            "Contact": "9922736052",
            "avatarIcon": " HOTEL PIONEER (NANDEDKAR HOSP)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 25",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Hotel Ginger, Wakad (Apollo Hospital)",
            "Contact": "NA",
            "avatarIcon": " HOTEL GINGER, WAKAD (APOLLO HOSPITAL)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Hotel Daksh, New Telco Road, Pimpri (Divine Hospital)",
            "Contact": "NA",
            "avatarIcon": " HOTEL DAKSH, NEW TELCO ROAD, PIMPRI (DIVINE HOSPITAL)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Gharkul D-8, Chikhali (Icon Hosp)",
            "Contact": "2027438888",
            "avatarIcon": " GHARKUL D-8, CHIKHALI (ICON HOSP)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 200",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Gharkul D-7, Chikhali (Icon Hosp)",
            "Contact": "NA",
            "avatarIcon": " GHARKUL D-7, CHIKHALI (ICON HOSP)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 200",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Gharkul D-5, Chikhali (Icon Hosp)",
            "Contact": "NA",
            "avatarIcon": " GHARKUL D-5, CHIKHALI (ICON HOSP)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 201",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Gharkul B-9, Chikhali (Divine Hosp)",
            "Contact": "7720005912",
            "avatarIcon": " GHARKUL B-9, CHIKHALI (DIVINE HOSP)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 198",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Balnagari (Ruby Ailcare)",
            "Contact": "NA",
            "avatarIcon": " BALNAGARI (RUBY AILCARE)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 201",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Balewadi Sports Complex (BVG India)",
            "Contact": "8975768407",
            "avatarIcon": " BALEWADI SPORTS COMPLEX (BVG INDIA)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 220",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " All India Jain Foundation, Ravet (Oxycare Hospital)",
            "Contact": "NA",
            "avatarIcon": " ALL INDIA JAIN FOUNDATION, RAVET (OXYCARE HOSPITAL)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 60",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " A.B.Services Boys Hostel, Akurdi (Ayush Hosp.)",
            "Contact": "NA",
            "avatarIcon": " A.B.SERVICES BOYS HOSTEL, AKURDI (AYUSH HOSP.)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 50",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " New Jijamata Hospital",
            "Contact": "8668872330",
            "avatarIcon": " NEW JIJAMATA HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 62",
                "Oxygen": " 20",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Vital Multispeciality Hospital, Pimpri",
            "Contact": "NA",
            "avatarIcon": " VITAL MULTISPECIALITY HOSPITAL, PIMPRI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 28",
                "ICU": " 4",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Vighnaharta Hospital,Rahatani",
            "Contact": "9921880101",
            "avatarIcon": " VIGHNAHARTA HOSPITAL,RAHATANI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 15",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Vedant Hospital, MIDC Chinchwad",
            "Contact": "NA",
            "avatarIcon": " VEDANT HOSPITAL, MIDC CHINCHWAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 27",
                "ICU": " 7",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Vatsalya Children Hosp, Dange Chowk",
            "Contact": "NA",
            "avatarIcon": " VATSALYA CHILDREN HOSP, DANGE CHOWK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 22",
                "Oxygen": " 6",
                "ICU": " 3",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Varad Hospital & Critical Care, Kiwale",
            "Contact": "9922926844",
            "avatarIcon": " VARAD HOSPITAL & CRITICAL CARE, KIWALE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 9",
                "ICU": " 5",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Unity Hospital, Dudulgaon",
            "Contact": "NA",
            "avatarIcon": " UNITY HOSPITAL, DUDULGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "Oxygen": " 5",
                "ICU": " 3",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Triveni Hospital, Walhekarwadi",
            "Contact": "NA",
            "avatarIcon": " TRIVENI HOSPITAL, WALHEKARWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " 4",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Tambekar Hospital, Chikhali",
            "Contact": "7020260819",
            "avatarIcon": " TAMBEKAR HOSPITAL, CHIKHALI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " 5",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Surya Hospital, Krishna Nagar",
            "Contact": "NA",
            "avatarIcon": " SURYA HOSPITAL, KRISHNA NAGAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " 1",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sunil Wankhede Multispeciality Hospital",
            "Contact": "9822122888",
            "avatarIcon": " SUNIL WANKHEDE MULTISPECIALITY HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "Oxygen": " 3",
                "ICU": " 4",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Star Speciality Hospital. Moshi",
            "Contact": "NA",
            "avatarIcon": " STAR SPECIALITY HOSPITAL. MOSHI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 4",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Star Hospital, Akurdi",
            "Contact": "NA",
            "avatarIcon": " STAR HOSPITAL, AKURDI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 44",
                "Oxygen": " 31",
                "ICU": " 22",
                "Ventilator": " 12"
            }
        },
        {
            "hospitalName": " Spandan Hopsital, Thergaon",
            "Contact": "7020500448",
            "avatarIcon": " SPANDAN HOPSITAL, THERGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 14",
                "ICU": " 4",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Sonali Memorial Hospital,Dange Chouk",
            "Contact": "NA",
            "avatarIcon": " SONALI MEMORIAL HOSPITAL,DANGE CHOUK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 25",
                "ICU": " 12",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Snehbandh Multispeciality Hospital",
            "Contact": "NA",
            "avatarIcon": " SNEHBANDH MULTISPECIALITY HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 11",
                "ICU": " 4",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Siddhi Hospital Dighi",
            "Contact": "7722030304",
            "avatarIcon": " SIDDHI HOSPITAL DIGHI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 15",
                "ICU": " 3",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shri Samarth MS Hospital, Pimple Gurav",
            "Contact": "9960783902",
            "avatarIcon": " SHRI SAMARTH MS HOSPITAL, PIMPLE GURAV",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 8",
                "ICU": " 1",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Shri Sai Seva Hospital, Sant Tuakram Nagar",
            "Contact": "9561363382",
            "avatarIcon": " SHRI SAI SEVA HOSPITAL, SANT TUAKRAM NAGAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shri Nageshwar Surgical and General Hospital, Moshi",
            "Contact": "9172242754",
            "avatarIcon": " SHRI NAGESHWAR SURGICAL AND GENERAL HOSPITAL, MOSHI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 12",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shree Krupa Multispeciality Hospital, Kalewadi",
            "Contact": "9860492631",
            "avatarIcon": " SHREE KRUPA MULTISPECIALITY HOSPITAL, KALEWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shree Hospital, Sangvi",
            "Contact": "9322365446",
            "avatarIcon": " SHREE HOSPITAL, SANGVI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 5",
                "ICU": " 5",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shree Gajanan Hospital, Bhosari",
            "Contact": "7887884983",
            "avatarIcon": " SHREE GAJANAN HOSPITAL, BHOSARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 23",
                "ICU": " 2",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Shree Anand Memorial Hospital, Thegaon",
            "Contact": "9309125521",
            "avatarIcon": " SHREE ANAND MEMORIAL HOSPITAL, THEGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 50",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shende Hospital, Pimple Saudagar",
            "Contact": "9822246881",
            "avatarIcon": " SHENDE HOSPITAL, PIMPLE SAUDAGAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 8",
                "ICU": " 3",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Sevadharam Hospital, Moshi",
            "Contact": "9822022868",
            "avatarIcon": " SEVADHARAM HOSPITAL, MOSHI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 27",
                "ICU": " 13",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sarvadnya Multispeciality Hospital, Moshi",
            "Contact": "7218646464",
            "avatarIcon": " SARVADNYA MULTISPECIALITY HOSPITAL, MOSHI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Sant Tukaram Multispeciality Shivkrupa Hosp, Chikhali",
            "Contact": "9822737476",
            "avatarIcon": " SANT TUKARAM MULTISPECIALITY SHIVKRUPA HOSP, CHIKHALI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 10",
                "ICU": " 3",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Sanjeevani Hospital, Chikhali",
            "Contact": "8149091707",
            "avatarIcon": " SANJEEVANI HOSPITAL, CHIKHALI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Saksham Hospital, Thergaon",
            "Contact": "9970254593",
            "avatarIcon": " SAKSHAM HOSPITAL, THERGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 2",
                "ICU": " 3",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sai Speciality Hospital, Chikhali",
            "Contact": "9226093506",
            "avatarIcon": " SAI SPECIALITY HOSPITAL, CHIKHALI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 14",
                "ICU": " 1",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Sai Criticare, Kiwale",
            "Contact": "8805986199",
            "avatarIcon": " SAI CRITICARE, KIWALE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 30",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": " Rainbow Multispeciality Hosp, Krushna Nagar, Chinchwad",
            "Contact": "9545438058",
            "avatarIcon": " RAINBOW MULTISPECIALITY HOSP, KRUSHNA NAGAR, CHINCHWAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "Oxygen": " 4",
                "ICU": " 2",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Rainbow Hospital, Kalewadi",
            "Contact": "9511223623",
            "avatarIcon": " RAINBOW HOSPITAL, KALEWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 15",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Rahane Hospital, Charholi Budruk",
            "Contact": "7588093056",
            "avatarIcon": " RAHANE HOSPITAL, CHARHOLI BUDRUK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "Oxygen": " 10",
                "ICU": " 5",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Pune International Burns & Cosmetic Center, Bhosari",
            "Contact": "9822094249",
            "avatarIcon": " PUNE INTERNATIONAL BURNS & COSMETIC CENTER, BHOSARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 7",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Pulse MS Hospital, Tathawade",
            "Contact": "8805991414",
            "avatarIcon": " PULSE MS HOSPITAL, TATHAWADE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 11",
                "Oxygen": " 14",
                "ICU": " 3",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Pooja Hospital, Bhosari",
            "Contact": "9011097102",
            "avatarIcon": " POOJA HOSPITAL, BHOSARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 10",
                "ICU": " 4",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Phoenix Hospital, Thergaon",
            "Contact": "9225526566",
            "avatarIcon": " PHOENIX HOSPITAL, THERGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 50",
                "ICU": " 7",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Pataskar Nursing Home, Bhosari",
            "Contact": "9422033778",
            "avatarIcon": " PATASKAR NURSING HOME, BHOSARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 5",
                "ICU": " 4",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Pan Ortho Hospital, Nigdi",
            "Contact": "9112248067",
            "avatarIcon": " PAN ORTHO HOSPITAL, NIGDI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 13",
                "ICU": " 10",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Padmaja Hospital, Chinchwad",
            "Contact": "9145045045",
            "avatarIcon": " PADMAJA HOSPITAL, CHINCHWAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 22",
                "Oxygen": " 8",
                "ICU": " 7",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Oxycare Multispeciality Hospital Kalewadi",
            "Contact": "8408000020",
            "avatarIcon": " OXYCARE MULTISPECIALITY HOSPITAL KALEWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 8",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Orchid Hospital, Thergaon",
            "Contact": "9096089725",
            "avatarIcon": " ORCHID HOSPITAL, THERGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 14",
                "ICU": " 0",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": " Onkar Hospital, Pimple Gurav",
            "Contact": "9119571968",
            "avatarIcon": " ONKAR HOSPITAL, PIMPLE GURAV",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 6",
                "ICU": " 3",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Omkar MS Hospital, Bijlinagar",
            "Contact": "9096089725",
            "avatarIcon": " OMKAR MS HOSPITAL, BIJLINAGAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 2",
                "ICU": " 5",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " Om Hospital, Hutatma Chowk, Bhosari",
            "Contact": "8888825600",
            "avatarIcon": " OM HOSPITAL, HUTATMA CHOWK, BHOSARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "Oxygen": " 13",
                "ICU": " 3",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Ojas Hospital, Ravet",
            "Contact": "7385159540",
            "avatarIcon": " OJAS HOSPITAL, RAVET",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 17",
                "Oxygen": " 44",
                "ICU": " 7",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " Nrusinnha Hospital, Dapodi",
            "Contact": "8446668877",
            "avatarIcon": " NRUSINNHA HOSPITAL, DAPODI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 5",
                "ICU": " 4",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " NRS Hopsital, Wakad",
            "Contact": "9881331333",
            "avatarIcon": " NRS HOPSITAL, WAKAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 21",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " New Life Child Hospital, Kalewadi",
            "Contact": "9225526566",
            "avatarIcon": " NEW LIFE CHILD HOSPITAL, KALEWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 16",
                "Oxygen": " 21",
                "ICU": " 12",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Nandedkar Multispeciality Hospital, Thergaon",
            "Contact": "9960889451",
            "avatarIcon": " NANDEDKAR MULTISPECIALITY HOSPITAL, THERGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 12",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Moon Multispeciality\u00c2Hospital, Chikhali",
            "Contact": "9561044751",
            "avatarIcon": " MOON MULTISPECIALITY\u00c2HOSPITAL, CHIKHALI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 26",
                "ICU": " 4",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Metro Hospital, Rahatani",
            "Contact": "7722019193",
            "avatarIcon": " METRO HOSPITAL, RAHATANI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 25",
                "Oxygen": " 2",
                "ICU": " 8",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Maxcare Hospital, Pimple Saudagar",
            "Contact": "9604536380",
            "avatarIcon": " MAXCARE HOSPITAL, PIMPLE SAUDAGAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 8",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Max Neuro Hospital, Kasarwadi",
            "Contact": "8390906080",
            "avatarIcon": " MAX NEURO HOSPITAL, KASARWADI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 3",
                "ICU": " 29",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": " Mauli Hospital, Talawade",
            "Contact": "9172027447",
            "avatarIcon": " MAULI HOSPITAL, TALAWADE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 22",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Mangalmurti Multispeciality Hospital, Ravet",
            "Contact": "9021279938",
            "avatarIcon": " MANGALMURTI MULTISPECIALITY HOSPITAL, RAVET",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 11",
                "ICU": " 7",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Lunkad Hospital, Dapodi",
            "Contact": "9922441225",
            "avatarIcon": " LUNKAD HOSPITAL, DAPODI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 4",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Lotus Multispeciality Hospital Pimple Saudagar",
            "Contact": "9823123005",
            "avatarIcon": " LOTUS MULTISPECIALITY HOSPITAL PIMPLE SAUDAGAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 32",
                "ICU": " 6",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Lifepoint Hospital, Wakad",
            "Contact": "7447445727",
            "avatarIcon": " LIFEPOINT HOSPITAL, WAKAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 36",
                "Oxygen": " 40",
                "ICU": " 4",
                "Ventilator": " 15"
            }
        },
        {
            "hospitalName": " Lifeline Hospital, Bhosari",
            "Contact": "9822420615",
            "avatarIcon": " LIFELINE HOSPITAL, BHOSARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Life Care Multispeciality Hospital, Punawale",
            "Contact": "9527765554",
            "avatarIcon": " LIFE CARE MULTISPECIALITY HOSPITAL, PUNAWALE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 12",
                "ICU": " 3",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Global Multispeciality Hospital, Dighi",
            "Contact": "7796696655",
            "avatarIcon": " GLOBAL MULTISPECIALITY HOSPITAL, DIGHI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 11",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Gangotri Hospital, Dighi",
            "Contact": "9850612005",
            "avatarIcon": " GANGOTRI HOSPITAL, DIGHI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 14",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Dhanwantari MSH, Nigdi",
            "Contact": "9595756763",
            "avatarIcon": " DHANWANTARI MSH, NIGDI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 17",
                "ICU": " 1",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " Chest and General Hospital, Indrayani Nagar, Bhosari",
            "Contact": "9850172762",
            "avatarIcon": " CHEST AND GENERAL HOSPITAL, INDRAYANI NAGAR, BHOSARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 18",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Birasaheb Rupnar Memorial Hospital Bhosari",
            "Contact": "9860799714",
            "avatarIcon": " BIRASAHEB RUPNAR MEMORIAL HOSPITAL BHOSARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 23",
                "ICU": " 8",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Apple Hospital Pimple Nilakh",
            "Contact": "8888568777",
            "avatarIcon": " APPLE HOSPITAL PIMPLE NILAKH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 19",
                "ICU": " 4",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Ankur Hospital, Thergaon",
            "Contact": "8149733771",
            "avatarIcon": " ANKUR HOSPITAL, THERGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "Oxygen": " 14",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " AJS City Hospital Thergaon",
            "Contact": "8308081314",
            "avatarIcon": " AJS CITY HOSPITAL THERGAON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "Oxygen": " 14",
                "ICU": " 4",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Aegis Multispeciality Hospital, Akurdi",
            "Contact": "9175277825",
            "avatarIcon": " AEGIS MULTISPECIALITY HOSPITAL, AKURDI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 2",
                "ICU": " 5",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Aditya Hospital, Pimple Gurav",
            "Contact": "9075471913",
            "avatarIcon": " ADITYA HOSPITAL, PIMPLE GURAV",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 12",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Aarya Hospital, Moshi",
            "Contact": "9834974688",
            "avatarIcon": " AARYA HOSPITAL, MOSHI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Lokmanya Hospital, Chinchwad",
            "Contact": "9860368282",
            "avatarIcon": " LOKMANYA HOSPITAL, CHINCHWAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 56",
                "ICU": " 9",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " Accord Sant Dnyaneshwar Hospital",
            "Contact": "93709-53474",
            "avatarIcon": " ACCORD SANT DNYANESHWAR HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 38",
                "Oxygen": " 48",
                "ICU": " 23",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": " New Bhosari Hospital",
            "Contact": "9822370098",
            "avatarIcon": " NEW BHOSARI HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 42",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Shree Multispeciality Hospital and ICU Moshi",
            "Contact": "9881558230",
            "avatarIcon": " SHREE MULTISPECIALITY HOSPITAL AND ICU MOSHI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 11",
                "ICU": " 2",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Pritam Hospital, Bhosari",
            "Contact": "9766588135",
            "avatarIcon": " PRITAM HOSPITAL, BHOSARI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 15",
                "ICU": " 4",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Life Care Hospital, Chikhali",
            "Contact": "9503189884",
            "avatarIcon": " LIFE CARE HOSPITAL, CHIKHALI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 15",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Aditya Birla Memorial Hospital - DCH",
            "Contact": "9881123008",
            "avatarIcon": " ADITYA BIRLA MEMORIAL HOSPITAL - DCH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 179",
                "ICU": " 41",
                "Ventilator": " 26"
            }
        },
        {
            "hospitalName": " D.Y. Patil Medical College & Hospital",
            "Contact": "9168259193",
            "avatarIcon": " D.Y. PATIL MEDICAL COLLEGE & HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 378",
                "Oxygen": " 367",
                "ICU": " 26",
                "Ventilator": " 56"
            }
        },
        {
            "hospitalName": " PGI YCM Hospital - DCH",
            "Contact": "7767006111",
            "avatarIcon": " PGI YCM HOSPITAL - DCH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 228",
                "ICU": " 0",
                "Ventilator": " 15"
            }
        },
        {
            "hospitalName": " Annasaheb Magar Stadium (Jumbo Facility)",
            "Contact": "9922509321",
            "avatarIcon": " ANNASAHEB MAGAR STADIUM (JUMBO FACILITY)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 546",
                "ICU": " 76",
                "Ventilator": " 57"
            }
        },
        {
            "hospitalName": " RH Nhavara Shirur",
            "Contact": "9420840048",
            "avatarIcon": " RH NHAVARA SHIRUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " pune Adventist Hospital (Market Yard )",
            "Contact": "9420730717",
            "avatarIcon": " PUNE ADVENTIST HOSPITAL (MARKET YARD )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 22",
                "ICU": " 2",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " smt.Kashibai Navale medical collge and general Hospital ( Narhe Ambegaon )",
            "Contact": "7030010302",
            "avatarIcon": " SMT.KASHIBAI NAVALE MEDICAL COLLGE AND GENERAL HOSPITAL ( NARHE AMBEGAON )",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 77",
                "Oxygen": " 87",
                "ICU": " 5",
                "Ventilator": " 4"
            }
        }
    ],
    "KALYAN": [
        {
            "hospitalName": "Vaibhav Multispeciality hospitalDCH",
            "Contact": "7666927666",
            "avatarIcon": "VM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 24"
            }
        },
        {
            "hospitalName": "UMADEVI SITARAM BAIRAGI HOSPITALDCH",
            "Contact": "9920850551",
            "avatarIcon": "US",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0"
            }
        },
        {
            "hospitalName": "TATA AMANTRACCC",
            "Contact": "9823031250",
            "avatarIcon": "TA",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 1298"
            }
        },
        {
            "hospitalName": "Star City Multispecialty Hospital Pvt. LtdDCH",
            "Contact": "8422991852",
            "avatarIcon": "SC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " -1"
            }
        },
        {
            "hospitalName": "Siddhivinayak Multispeciality HospitalDCH",
            "Contact": "9820647666",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 28"
            }
        },
        {
            "hospitalName": "Shwaas Multispeciality HospitalDCH",
            "Contact": "8805566238",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 51"
            }
        },
        {
            "hospitalName": "shree swami samarth hospitalDCH",
            "Contact": "8828988971",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 15"
            }
        },
        {
            "hospitalName": "ShreeDevi hospital kalyan westCCC",
            "Contact": "",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " -83"
            }
        },
        {
            "hospitalName": "Shastrinagar HospitalDCHC",
            "Contact": "9769232802",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 62"
            }
        },
        {
            "hospitalName": "Savlaram covid hospitalDCH",
            "Contact": "9819931418",
            "avatarIcon": "SC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " -581"
            }
        },
        {
            "hospitalName": "Sai Swastik Multispecaility HospitalDCH",
            "Contact": "9860116595",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 20"
            }
        },
        {
            "hospitalName": "Sai HospitalDCH",
            "Contact": "9370037667/7021633020/8779494650",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 17"
            }
        },
        {
            "hospitalName": "Sai AarogyamDCH",
            "Contact": "9004383456",
            "avatarIcon": "SA",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 16"
            }
        },
        {
            "hospitalName": "RajeshwariDCH",
            "Contact": "678995432",
            "avatarIcon": "R",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0"
            }
        },
        {
            "hospitalName": "Optilife Nx Hospital by Hermes Health Care Pvt Ltd Dombivli (West)DCH",
            "Contact": "9619187198",
            "avatarIcon": "ON",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 21"
            }
        },
        {
            "hospitalName": "Optilfe Multispeciality HospitalDCH",
            "Contact": "9619187198",
            "avatarIcon": "OM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 11"
            }
        },
        {
            "hospitalName": "Noble Multispeciality hospital kalyan (E)DCH",
            "Contact": "9321658559",
            "avatarIcon": "NM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 28"
            }
        },
        {
            "hospitalName": "Noble HospitalDCH",
            "Contact": "9702551551",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 1"
            }
        },
        {
            "hospitalName": "Nahar Multispeciality HospitalDCH",
            "Contact": "8691888890",
            "avatarIcon": "NM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " -24"
            }
        },
        {
            "hospitalName": "Meera HospitalDCH",
            "Contact": "9769160565",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0"
            }
        },
        {
            "hospitalName": "Icon hospital private limitedDCH",
            "Contact": "8425847436",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 11"
            }
        },
        {
            "hospitalName": "Holy CrossDCH",
            "Contact": "8108398502",
            "avatarIcon": "HC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 41"
            }
        },
        {
            "hospitalName": "Dr. C B Vaidya Memorial hospitalDCH",
            "Contact": "9820488533",
            "avatarIcon": "DC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 28"
            }
        },
        {
            "hospitalName": "BAJ RR HOSPITALDCH",
            "Contact": "8898268555",
            "avatarIcon": "BR",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0"
            }
        },
        {
            "hospitalName": "Ayur HospitalDCH",
            "Contact": "9867959999",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 5"
            }
        },
        {
            "hospitalName": "Auxilium HospitalDCH",
            "Contact": "7506672681",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 82"
            }
        },
        {
            "hospitalName": "A & G hospitalCCC",
            "Contact": "9833522985",
            "avatarIcon": "A&",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 34"
            }
        },
        {
            "hospitalName": "Aayush HospitalDCH",
            "Contact": "9619667911",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " -10"
            }
        }
    ],
    "HARIDWAR": [
        {
            "hospitalName": "ZILA JAIL ROSHANABAD",
            "Contact": " 8171455525",
            "avatarIcon": "ZJ",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 40",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "YOG MATA PILOT BABA HOSPITAL",
            "Contact": " 7895453800",
            "avatarIcon": "YM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 22",
                "ICU": " 22"
            }
        },
        {
            "hospitalName": "VINAY VISHAL",
            "Contact": " 7055376698",
            "avatarIcon": "VV",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 77",
                "ICU": " 77"
            }
        },
        {
            "hospitalName": "TULSI HOSPITAL GANESHPUR ROORKEE",
            "Contact": " 7500002999\n 9627444493",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 5",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "SWAMI RAM PRAKASH CHARITABLE HOSPITAL HARIDWAR",
            "Contact": " 8864920132",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 21",
                "Oxygen": " 45",
                "ICU": " 45"
            }
        },
        {
            "hospitalName": "SWAMI BHUMANAND HOSPITAL",
            "Contact": " 9838996111",
            "avatarIcon": "SB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 23",
                "ICU": " 23"
            }
        },
        {
            "hospitalName": "SHREE DHRUV CHARITABLE TRUST",
            "Contact": " 9720004529",
            "avatarIcon": "SD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 10",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "SHANTI KUNJ",
            "Contact": " 6397255580",
            "avatarIcon": "SK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 84",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "S.R. MEDICITY JAGJEETPUR",
            "Contact": " 9717800249",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 24",
                "ICU": " 24"
            }
        },
        {
            "hospitalName": "RAM KRISHAN MISSION HOSPITAL HARIDWAR",
            "Contact": " 7983816880",
            "avatarIcon": "RK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 42",
                "Oxygen": " 87",
                "ICU": " 87"
            }
        },
        {
            "hospitalName": "QWADRA HOSPITAL ROORKEE",
            "Contact": " 9760563335",
            "avatarIcon": "QH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "Oxygen": " 5",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "NEW DEV BHUMI HOSPITAL MEDICAL RESEARCH HOSPITAL",
            "Contact": " 9837906777",
            "avatarIcon": "ND",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "Oxygen": " 15",
                "ICU": " 15"
            }
        },
        {
            "hospitalName": "MULTISPECIALITY HOSPITAL MUSTAFABD LAKSAR",
            "Contact": " 9012163784\n 9259158069",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 50",
                "Oxygen": " 16",
                "ICU": " 16"
            }
        },
        {
            "hospitalName": "MILITARY HOSPITAL MH ROORKEE",
            "Contact": " 9079946561",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 107",
                "Oxygen": " 25",
                "ICU": " 25"
            }
        },
        {
            "hospitalName": "METRO HOSPITAL HARIDWAR",
            "Contact": " 7017678152",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 40",
                "ICU": " 40"
            }
        },
        {
            "hospitalName": "MELA HOSPITAL HARIDWAR",
            "Contact": " 8532835631",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 50",
                "ICU": " 50"
            }
        },
        {
            "hospitalName": "MAXWELL HOSPITAL JAGJEETPUR HARIDWAR",
            "Contact": " 8191804060",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 25",
                "ICU": " 25"
            }
        },
        {
            "hospitalName": "MAIN HOSPITAL BHEL",
            "Contact": " 9837072227",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 32",
                "ICU": " 32"
            }
        },
        {
            "hospitalName": "LIFE LINE SUPERSPECIALITY KGN MEDICAL CENTRE HARIDWAR",
            "Contact": " 9412941190\n 9690726042",
            "avatarIcon": "LL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 15",
                "ICU": " 15"
            }
        },
        {
            "hospitalName": "KAPOOR MULTISPECIALITY HOSPITAL MANGLOUR",
            "Contact": " 9897111115\n 7906405883",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 15",
                "ICU": " 15"
            }
        },
        {
            "hospitalName": "JAYAMAXWELL HOSPITAL",
            "Contact": " 9897536677",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 30",
                "ICU": " 30"
            }
        },
        {
            "hospitalName": "JAGJEETPUR MEDICARE HOSPITAL HARIDWAR",
            "Contact": " 637373482\n 6398864848",
            "avatarIcon": "JM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 10",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "J.N. SINHA MEMORIAL SDH ROORKEE",
            "Contact": " 8126158028",
            "avatarIcon": "JS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 40",
                "ICU": " 40"
            }
        },
        {
            "hospitalName": "IIT ROORKEE",
            "Contact": " 9997844321",
            "avatarIcon": "IR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 250",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "HOTEL SACHIN INTERNATIONAL NEAR RAILWAY STATION",
            "Contact": " 8619431554",
            "avatarIcon": "HS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 120",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "HOTEL PREM DEEP SHIVMURTI HARIDWAR",
            "Contact": " 9817854267",
            "avatarIcon": "HP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 45",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "HOTEL PARMILA BY PASS ROAD HARIDWAR",
            "Contact": " 7500212863\n 9219176173",
            "avatarIcon": "HP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 50",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "HOTEL PACIFIC ROORKEE HARIDWAR",
            "Contact": " 7340042227",
            "avatarIcon": "HP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 35",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "HOTEL JASMIN SIDCUL HARIDWAR",
            "Contact": " 8077457613",
            "avatarIcon": "HJ",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 34",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "HOTEL JAHANVI DALE HARIDWAR",
            "Contact": " 9910525621",
            "avatarIcon": "HJ",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 59",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "HOTEL GREEN LEAF JWALAPUR",
            "Contact": " 9760216856",
            "avatarIcon": "HG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 50",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "GURUKUL KANGARI HARIDWAR",
            "Contact": " 9557756973",
            "avatarIcon": "GK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 100",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "COER HOSPITAL ROORKEE",
            "Contact": " 7579090688",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 47",
                "Oxygen": " 10",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "BASE HOSPITAL",
            "Contact": " 9897156509",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 114",
                "Oxygen": " 6",
                "ICU": " 6"
            }
        },
        {
            "hospitalName": "BABA BARFANI HOSPITAL HARIDWAR",
            "Contact": " 9927037474",
            "avatarIcon": "BB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 60",
                "ICU": " 60"
            }
        },
        {
            "hospitalName": "AROGYAM HOSPITAL",
            "Contact": " 9756725628",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "Oxygen": " 30",
                "ICU": " 30"
            }
        },
        {
            "hospitalName": "ANANDAM HOSPITAL LAKSAR JAGJEETPUR",
            "Contact": " 9412022857",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 15",
                "ICU": " 15"
            }
        },
        {
            "hospitalName": "AKUMS PLANT-3 SIDCUL HARIDWAR",
            "Contact": " 8393999752",
            "avatarIcon": "AP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 99",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        }
    ],
    "NAINITAL": [
        {
            "hospitalName": "VIVEKANAND HOSPITAL",
            "Contact": " 9411161101",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "SMT. USHA BHUGUNA ALFA HEALTH",
            "Contact": " 8126536338",
            "avatarIcon": "SU",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 7",
                "ICU": " 7"
            }
        },
        {
            "hospitalName": "SK NURSING HOME HALDWANI",
            "Contact": " 8194019311",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 14",
                "ICU": " 14"
            }
        },
        {
            "hospitalName": "SIDHIVINAY HOSPITAL",
            "Contact": " 9012350994",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 10",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "SHUSHILA TIWARI GOVERNMENT HOSPITAL HALDWANI",
            "Contact": " 9634623901\n 9997192777",
            "avatarIcon": "ST",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 748",
                "ICU": " 748"
            }
        },
        {
            "hospitalName": "SAI HOSPITAL",
            "Contact": " 9639864155",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 28",
                "ICU": " 28"
            }
        },
        {
            "hospitalName": "NEELKANTH HOSPITAL",
            "Contact": " 8077622358",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " 8"
            }
        },
        {
            "hospitalName": "MAA JAGDAMBA HOSPITAL",
            "Contact": " 9756970838",
            "avatarIcon": "MJ",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "Oxygen": " 8",
                "ICU": " 8"
            }
        },
        {
            "hospitalName": "KRISHNA HOSPITAL RESEARCH CENTRE",
            "Contact": " 9837003915",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 11",
                "ICU": " 11"
            }
        },
        {
            "hospitalName": "KALYAN HOSPITAL",
            "Contact": " 9917264763",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 15",
                "ICU": " 15"
            }
        },
        {
            "hospitalName": "CENTRAL HOSPITAL",
            "Contact": " 7500445111",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 44",
                "ICU": " 44"
            }
        },
        {
            "hospitalName": "BRIJALAL HOSPITAL RESEARCH",
            "Contact": " 9759063358",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "BOMBAY HOSPITAL",
            "Contact": " 7455009297",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 11",
                "ICU": " 11"
            }
        },
        {
            "hospitalName": "BABA NEEM KAROLI",
            "Contact": " 7300919149",
            "avatarIcon": "BN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 7",
                "ICU": " 7"
            }
        }
    ],
    "ALMORA": [
        {
            "hospitalName": "TRH JAGESHWAR",
            "Contact": "9012478082",
            "avatarIcon": "TJ",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "TRC DUDHOLI",
            "Contact": "9837123984",
            "avatarIcon": "TD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 17",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "TOURIST REST HOUSE DEENAPANI ALMORA",
            "Contact": "8006440073",
            "avatarIcon": "TR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "ETC",
            "Contact": "7579186010",
            "avatarIcon": "E",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "DISTRICT INSTITUTE OF EDUCATION AND TRAINING",
            "Contact": "7579186010",
            "avatarIcon": "DI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 40",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "BASE HOSPITAL ALMORA",
            "Contact": "9837251413",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 100",
                "Oxygen": " 147",
                "ICU": " 147"
            }
        }
    ],
    "JABALPUR": [
        {
            "hospitalName": "LAXMI NARYAN HOSPITAL",
            "Contact": "",
            "avatarIcon": "LN",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 25"
            }
        },
        {
            "hospitalName": "DISHA MULTISPECIALITY HEALTH CARE JABALPUR",
            "Contact": "",
            "avatarIcon": "DM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 9"
            }
        },
        {
            "hospitalName": "GYANODAYA HOSTEL RANJHI",
            "Contact": "",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 5",
                "Oxygen Beds": " 20"
            }
        },
        {
            "hospitalName": "Life Care ICU",
            "Contact": "",
            "avatarIcon": "LC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 2",
                "Oxygen Beds": " 0"
            }
        },
        {
            "hospitalName": "Sanskardhani Hospital",
            "Contact": "",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 14"
            }
        },
        {
            "hospitalName": "VEHICLE FACTORY HOSPITAL",
            "Contact": "",
            "avatarIcon": "VF",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 16"
            }
        },
        {
            "hospitalName": "ORDNANCE FACTORY KHAMARIA DCHC",
            "Contact": "",
            "avatarIcon": "OF",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 24"
            }
        },
        {
            "hospitalName": "NEW LIFE",
            "Contact": "",
            "avatarIcon": "NL",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 20"
            }
        },
        {
            "hospitalName": "MANMOHAN NAGAR HOSPITAL",
            "Contact": "",
            "avatarIcon": "MN",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 65"
            }
        },
        {
            "hospitalName": "MOHAN LAL HARGOVINDDAS HOSPITAL",
            "Contact": "",
            "avatarIcon": "ML",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 14"
            }
        },
        {
            "hospitalName": "GOLCHA HOSPITAL",
            "Contact": "",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 24"
            }
        },
        {
            "hospitalName": "BEST SUPER SPECIALTY HOSPITAL",
            "Contact": "",
            "avatarIcon": "BS",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 53"
            }
        },
        {
            "hospitalName": "National Hospital",
            "Contact": "",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 30"
            }
        },
        {
            "hospitalName": "JAMDAR HOSPITAL",
            "Contact": "",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 22"
            }
        },
        {
            "hospitalName": "PANDEY HOSPITAL",
            "Contact": "",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 15"
            }
        },
        {
            "hospitalName": "SHALBY HOSPITAL",
            "Contact": "",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 15"
            }
        },
        {
            "hospitalName": "JABALPUR HOSPITAL",
            "Contact": "",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 50"
            }
        },
        {
            "hospitalName": "ASHISH HOSPITAL",
            "Contact": "",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 23"
            }
        },
        {
            "hospitalName": "BOMBAY HOSPITAL",
            "Contact": "",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 9"
            }
        },
        {
            "hospitalName": "LIFE MEDICITY HOSPITAL",
            "Contact": "9757288301",
            "avatarIcon": "LM",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 35"
            }
        },
        {
            "hospitalName": "SWASTIK HOSPITAL",
            "Contact": "",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 30"
            }
        },
        {
            "hospitalName": "Central Railway Hospital",
            "Contact": "",
            "avatarIcon": "CR",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 10",
                "Oxygen Beds": " 57"
            }
        },
        {
            "hospitalName": "SMART CITY HOSPITAL",
            "Contact": "7999602301",
            "avatarIcon": "SC",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 25"
            }
        },
        {
            "hospitalName": "GMC, Jabalpur - DCH",
            "Contact": "9584262358",
            "avatarIcon": "GJ",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 81",
                "Oxygen Beds": " 144"
            }
        },
        {
            "hospitalName": "DH Jabalpur - DCHC",
            "Contact": "",
            "avatarIcon": "DJ",
            "foundUseful": "0",
            "bedsData": {
                "Normal Beds": " 0",
                "Oxygen Beds": " 148"
            }
        }
    ],
    "RUDRAPRAYAG": [
        {
            "hospitalName": "SPORTS COMPLEX AUGUSTYAMUNI",
            "Contact": "9690786103",
            "avatarIcon": "SC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 82",
                "ICU": " 82"
            }
        },
        {
            "hospitalName": "RAMAKRISHNA MISSION",
            "Contact": "7253049619",
            "avatarIcon": "RM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 27",
                "ICU": " 27"
            }
        },
        {
            "hospitalName": "MADHAV SEVA HOSPITAL NARAYANKOTI GUPTKASHI",
            "Contact": "9650224807",
            "avatarIcon": "MS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 20"
            }
        },
        {
            "hospitalName": "DISTRICT HOSPITAL RUDRAPRAYAG",
            "Contact": "9897663755",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 120",
                "ICU": " 120"
            }
        }
    ],
    "AGRA": [
        {
            "hospitalName": "Agra Trade Centre (WIP)",
            "Contact": "9359843300",
            "avatarIcon": "AT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 26",
                "Oxygen": " 313",
                "ICU": " 11"
            }
        },
        {
            "hospitalName": "Amit Jaggi Memorial Hospital",
            "Contact": "9837067747",
            "avatarIcon": "AJ",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 11",
                "ICU": " 2"
            }
        },
        {
            "hospitalName": "APEX MULTISPECIALITY HOSPITAL",
            "Contact": "9759215602",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 38",
                "ICU": " 12"
            }
        },
        {
            "hospitalName": "Ayushman Hospital",
            "Contact": "9897466665",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "BARAULI AHIR CHC AGRA",
            "Contact": "9760606000",
            "avatarIcon": "BA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "Oxygen": " 15",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "BLOSSOM HOSPITAL",
            "Contact": "7500777770",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 25"
            }
        },
        {
            "hospitalName": "CHC BAH",
            "Contact": "8392853568",
            "avatarIcon": "CB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 20",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "CHC KIRAWALI",
            "Contact": "9335721486",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 5",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "DMH (AGRA)",
            "Contact": " ",
            "avatarIcon": "D(",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 68",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "G.R. Hospital AND Research Centre",
            "Contact": "9927600542",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 18",
                "ICU": " 12"
            }
        },
        {
            "hospitalName": "GOYAL CITY HOSPITAL",
            "Contact": "9412722656",
            "avatarIcon": "GC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 30"
            }
        },
        {
            "hospitalName": "Jai Hospital & Research Centre",
            "Contact": "5622520381",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 20"
            }
        },
        {
            "hospitalName": "JEEVAN JYOTI HOSPITAL",
            "Contact": "9897029148",
            "avatarIcon": "JJ",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 32",
                "ICU": " 8"
            }
        },
        {
            "hospitalName": "KHANDOLI CHC AGRA",
            "Contact": "7351664966",
            "avatarIcon": "KC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 20",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "MOOLCHAND HOSPITAL",
            "Contact": "9917122244",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 48",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "Naiminath Ayurevedic Medical College, Hospital",
            "Contact": " ",
            "avatarIcon": "NA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 150",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "Niyati Hospital (Agra)",
            "Contact": "9958085752",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 52",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "Pathak Hospital",
            "Contact": "9761500937",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 34",
                "ICU": " 6"
            }
        },
        {
            "hospitalName": "Prabha Hospital And Trauma Centre",
            "Contact": "9837040871",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 25",
                "Oxygen": " 36",
                "ICU": " 24"
            }
        },
        {
            "hospitalName": "Purushottamdas Savitridevi Cancer Care & Research Centre",
            "Contact": "8475083000",
            "avatarIcon": "PS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 55",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "Pushpanjali Hospital",
            "Contact": "8954050500",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 60"
            }
        },
        {
            "hospitalName": "Rainbow Hospital",
            "Contact": "9897099333",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 42",
                "ICU": " 8"
            }
        },
        {
            "hospitalName": "Ram Raghu Hospital",
            "Contact": "8368019832",
            "avatarIcon": "RR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 92",
                "ICU": " 35"
            }
        },
        {
            "hospitalName": "RAM TEJ HOSPITAL",
            "Contact": "8954592932",
            "avatarIcon": "RT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "RASHMI MEDICARE CENTRE",
            "Contact": "9837006916",
            "avatarIcon": "RM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 35",
                "ICU": " 15"
            }
        },
        {
            "hospitalName": "S R MEDICAL INSTITUTE AND RESEARCH CENTRE",
            "Contact": "9808488550",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 38",
                "ICU": " 12"
            }
        },
        {
            "hospitalName": "SAKET HOSPITAL",
            "Contact": "9997444600",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 28",
                "ICU": " 15"
            }
        },
        {
            "hospitalName": "SAPPHIRE HOSPITAL",
            "Contact": "9359925708",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "Shantived Institute of Medical Sciences",
            "Contact": "7642932255",
            "avatarIcon": "SI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 80",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "SHREE KRISHNA HOSPITAL",
            "Contact": "9412257292",
            "avatarIcon": "SK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 25",
                "ICU": " 18"
            }
        },
        {
            "hospitalName": "SHRI PARAS HOSPITAL",
            "Contact": "8171131766",
            "avatarIcon": "SP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 34",
                "ICU": " 16"
            }
        },
        {
            "hospitalName": "SNMC Medical College (Agra)",
            "Contact": " ",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 223",
                "ICU": " 91"
            }
        },
        {
            "hospitalName": "UPADHYAY HOSPITAL",
            "Contact": "NA",
            "avatarIcon": "UH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 29"
            }
        },
        {
            "hospitalName": "YASHWANT HOSPITAL",
            "Contact": "NA",
            "avatarIcon": "YH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 17",
                "ICU": " 18"
            }
        }
    ],
    "NOIDA": [
        {
            "hospitalName": "JS TOMAR MEMORIAL HOSPITAL OPERATED BY AJ HEALTHCARE GB NAGAR",
            "Contact": "7678179374",
            "avatarIcon": "JT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 31",
                "ICU": " 9"
            }
        },
        {
            "hospitalName": "surya hospital",
            "Contact": "9899298664",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 78",
                "ICU": " 22"
            }
        },
        {
            "hospitalName": "NOIDA AUTHORITY HOSPITAL",
            "Contact": "7985022163",
            "avatarIcon": "NA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 50",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "Arogaya Hospital",
            "Contact": "9910979599",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 40",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "Tripathi Hospital",
            "Contact": "9810169081",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 34",
                "ICU": " 16"
            }
        },
        {
            "hospitalName": "JAY PEE HOSPITAL,NOIDA",
            "Contact": " ",
            "avatarIcon": "JP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 131",
                "ICU": " 50"
            }
        },
        {
            "hospitalName": "Noida Covid Hospital,sector 39",
            "Contact": "9582793004",
            "avatarIcon": "NC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 32",
                "Oxygen": " 90",
                "ICU": " 42"
            }
        },
        {
            "hospitalName": "G.I.M.S. (Gautam Buddha Nagar)",
            "Contact": "7303884233",
            "avatarIcon": "G(",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 140",
                "Oxygen": " 60",
                "ICU": " 100"
            }
        },
        {
            "hospitalName": "YATHARTH HOSPITAL , Sector 110",
            "Contact": "NA",
            "avatarIcon": "YH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "Oxygen": " 154",
                "ICU": " 60"
            }
        },
        {
            "hospitalName": "YATHARTHA HOSPITAL, GREATER NOIDA",
            "Contact": "NA",
            "avatarIcon": "YH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 124",
                "ICU": " 62"
            }
        },
        {
            "hospitalName": "YATHARTHA HOSPITAL, NOIDA EXTENSION",
            "Contact": "NA",
            "avatarIcon": "YH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 228",
                "ICU": " 128"
            }
        },
        {
            "hospitalName": "SSPHPGTI Noida (Gautam Buddha Nagar)",
            "Contact": " ",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 50",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "School of Medical College Sharda University (Gautam Buddha Nagar)",
            "Contact": " ",
            "avatarIcon": "SO",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 496",
                "Oxygen": " 110",
                "ICU": " 114"
            }
        },
        {
            "hospitalName": "FORTIS HOSPITAL,NOIDA",
            "Contact": " ",
            "avatarIcon": "FH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 3"
            }
        },
        {
            "hospitalName": "NTPC Dadri Vidyut Nagar Hospital",
            "Contact": "9650993607",
            "avatarIcon": "ND",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 38",
                "ICU": " 2"
            }
        },
        {
            "hospitalName": "Noida International Institute of Medical Science, Yamuna Expressway",
            "Contact": " ",
            "avatarIcon": "NI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 106",
                "Oxygen": " 169",
                "ICU": " 25"
            }
        },
        {
            "hospitalName": "SHRI RAM SINGH MULTI-SPECIALTY HOSPITAL",
            "Contact": "NA",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 43",
                "Oxygen": " 38",
                "ICU": " 16"
            }
        },
        {
            "hospitalName": "Prakash Hospital, G.B. Nagar",
            "Contact": "NA",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 76",
                "ICU": " 24"
            }
        }
    ],
    "LUCKNOW": [
        {
            "hospitalName": "Atal Bihari Vajpayee COVID Hospital",
            "Contact": "9519109239",
            "avatarIcon": "AB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 355",
                "ICU": " 150"
            }
        },
        {
            "hospitalName": "SGPGI (Lucknow)",
            "Contact": " ",
            "avatarIcon": "S(",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 50",
                "ICU": " 262"
            }
        },
        {
            "hospitalName": "MIDLAND HEALTHCARE",
            "Contact": "9196555555",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 15"
            }
        },
        {
            "hospitalName": "K.G.M.U (Lucknow)",
            "Contact": "NA",
            "avatarIcon": "K(",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 33",
                "ICU": " 60"
            }
        },
        {
            "hospitalName": "Ram Sagar Mishra Sadhamau DCH (Lucknow)",
            "Contact": "9984513306",
            "avatarIcon": "RS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "Northern Railway Divisional Hospital",
            "Contact": "9794833532",
            "avatarIcon": "NR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 70",
                "Oxygen": " 0",
                "ICU": " 60"
            }
        },
        {
            "hospitalName": "Medanta Lucknow",
            "Contact": "NA",
            "avatarIcon": "ML",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 16"
            }
        },
        {
            "hospitalName": "MAA CHADRIKA DEVI HOSPITAL",
            "Contact": "9415020266",
            "avatarIcon": "MC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 40",
                "ICU": " 20"
            }
        },
        {
            "hospitalName": "Chandan Hospital",
            "Contact": "05226666666",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 35"
            }
        },
        {
            "hospitalName": "Lokbandhu Raj Narain (LUCKNOW)",
            "Contact": "9669890562",
            "avatarIcon": "LR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 130",
                "ICU": " 50"
            }
        },
        {
            "hospitalName": "Lucknow Heritage Hospital",
            "Contact": "8004748577",
            "avatarIcon": "LH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 10",
                "ICU": " 15"
            }
        },
        {
            "hospitalName": "HAL-UP Covid Hospital, Lucknow",
            "Contact": "9559726519",
            "avatarIcon": "HC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 212",
                "ICU": " 17"
            }
        },
        {
            "hospitalName": "JAGARANI HOSPITAL",
            "Contact": "8960099939",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 12",
                "ICU": " 4"
            }
        },
        {
            "hospitalName": "Career Medical College (Lucknow)(Minority)",
            "Contact": " ",
            "avatarIcon": "CM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 21",
                "Oxygen": " 129",
                "ICU": " 35"
            }
        },
        {
            "hospitalName": "Vivekananda Polyclinic And Institute of Medical Science",
            "Contact": "9453207748",
            "avatarIcon": "VP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 24",
                "ICU": " 15"
            }
        },
        {
            "hospitalName": "Balrampur Hospital Lucknow",
            "Contact": "8303707758",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 50",
                "Oxygen": " 212",
                "ICU": " 38"
            }
        },
        {
            "hospitalName": "Atharv Multispeciality Hospital",
            "Contact": "05227118216",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 16",
                "ICU": " 21"
            }
        },
        {
            "hospitalName": "MEDWELL HOSPITAL",
            "Contact": "9450355961",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 15",
                "ICU": " 6"
            }
        },
        {
            "hospitalName": "R.M.L (Lucknow)",
            "Contact": " ",
            "avatarIcon": "R(",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 50",
                "Oxygen": " 0",
                "ICU": " 146"
            }
        },
        {
            "hospitalName": "Sahara Hospital",
            "Contact": "8795777725",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 40"
            }
        },
        {
            "hospitalName": "ASTHA HOSPITAL",
            "Contact": "9336285050",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " 8"
            }
        },
        {
            "hospitalName": "T.S.M. Medical College, (Lucknow)",
            "Contact": " ",
            "avatarIcon": "TM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 50",
                "ICU": " 200"
            }
        },
        {
            "hospitalName": "Apollomedics Hospital, Lucknow",
            "Contact": "8429029801",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 64"
            }
        },
        {
            "hospitalName": "Era Medical College,(Lucknow) (Minority)",
            "Contact": "9555980262",
            "avatarIcon": "EM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "Oxygen": " 170",
                "ICU": " 198"
            }
        },
        {
            "hospitalName": "Integral medical College (Minority) (Lucknow)",
            "Contact": "7311190006",
            "avatarIcon": "IM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 140",
                "Oxygen": " 40",
                "ICU": " 120"
            }
        },
        {
            "hospitalName": "NISHAT HOSPITAL & RESEARCH CENTRE PVT.LTD",
            "Contact": "NA",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 8",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "SUSHMA HOSPITAL",
            "Contact": "9555693316",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 4",
                "ICU": " 8"
            }
        },
        {
            "hospitalName": "NOVA HOSPITAL",
            "Contact": "9935096423",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 13"
            }
        },
        {
            "hospitalName": "K.K. HOSPITAL",
            "Contact": "8173065444",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 10",
                "ICU": " 12"
            }
        },
        {
            "hospitalName": "Super-Specilaty Cancer Institute & Hospital",
            "Contact": "8840357292",
            "avatarIcon": "SC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "Charak Hospital & Research Center",
            "Contact": "8052224000",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "Tender palm Hospital",
            "Contact": "9076612575",
            "avatarIcon": "TP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "Oxygen": " 9",
                "ICU": " 12"
            }
        },
        {
            "hospitalName": "SHATABDI SUPER SPECIALITY HOSPITAL PVT LTD",
            "Contact": "NA",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 10",
                "ICU": " 8"
            }
        },
        {
            "hospitalName": "KAMAKHYA HOSPITAL",
            "Contact": "NA",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 27",
                "ICU": " 4"
            }
        }
    ],
    "VARANASI": [
        {
            "hospitalName": "MEDICITY HOSPITAL",
            "Contact": "7379111133",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 12",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "ALLIANCE HOSPTL",
            "Contact": "9307924202",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 3"
            }
        },
        {
            "hospitalName": "Ashirvaad Hospital",
            "Contact": "8765509221",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "GALAXY HOSPITAL",
            "Contact": "6386322997",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "RAM KRISHNA MISSION HOSPITAL",
            "Contact": "7042727760",
            "avatarIcon": "RK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "SANMUKH HOSPITAL, BABATPUR",
            "Contact": "9451494290",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "Apex hospital (VARANASI)",
            "Contact": " ",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 19",
                "Oxygen": " 34",
                "ICU": " 32"
            }
        },
        {
            "hospitalName": "Alakhnanda Hospital",
            "Contact": "9793883910",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 25",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "ALOK HOSPITAL",
            "Contact": "7007384842",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 14",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "ANANT HOSPITAL",
            "Contact": "9956901414",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 14",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "Care Hospital",
            "Contact": "6388903582",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 17",
                "ICU": " 3"
            }
        },
        {
            "hospitalName": "Central Hospital DLW (VARANASI)",
            "Contact": "9794861507",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 36",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "Deen Dayal Upadhyay Government hospital, (Varanasi)",
            "Contact": "8114001601",
            "avatarIcon": "DD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 147",
                "ICU": " 33"
            }
        },
        {
            "hospitalName": "DIRGHAYU HOSP",
            "Contact": "9415201141",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 32",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "DP MEDICAL CENTRE",
            "Contact": "9532935111",
            "avatarIcon": "DM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 8"
            }
        },
        {
            "hospitalName": "E.S.I.C.HOSPITAL,PANDEYPUR",
            "Contact": " ",
            "avatarIcon": "E",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 34",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "FORD HOSPITAL",
            "Contact": "8090625089",
            "avatarIcon": "FH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 17",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "GYAN VISHNU HOSPTAL",
            "Contact": "9935053139",
            "avatarIcon": "GV",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 19",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "H B C H",
            "Contact": "8080611946",
            "avatarIcon": "HB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 60",
                "ICU": " 13"
            }
        },
        {
            "hospitalName": "Heritage Medical College, (Varanasi)",
            "Contact": " ",
            "avatarIcon": "HM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 60"
            }
        },
        {
            "hospitalName": "INDRA HOSPITAL",
            "Contact": "9452424809",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 12",
                "ICU": " 8"
            }
        },
        {
            "hospitalName": "Institute of Medical Sciences (Sir Sunder lal Hospital) (VARANASI)",
            "Contact": "9450955143",
            "avatarIcon": "IO",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 150",
                "ICU": " 68"
            }
        },
        {
            "hospitalName": "J.P Hospital",
            "Contact": " ",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "JANTA HOSPITAL",
            "Contact": "91986777778",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "Kalpana multi specility hospital",
            "Contact": " ",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 23",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "Khushal Memorial Hospital",
            "Contact": " ",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "KULWANTI HOSPITAL",
            "Contact": "8009509595",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 14",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "LAXMI HOSPITAL",
            "Contact": "9161929292",
            "avatarIcon": "LH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 25",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "Maa Rajwati Hospital",
            "Contact": " ",
            "avatarIcon": "MR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 3",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "Mahasweeta hospital",
            "Contact": "9415228020",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "MAXWELL HOSPITAL",
            "Contact": "7897991775",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 24",
                "ICU": " 15"
            }
        },
        {
            "hospitalName": "MEDWIN HOSPITAL",
            "Contact": "9336917365",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 50",
                "ICU": " 30"
            }
        },
        {
            "hospitalName": "MERIDIAN",
            "Contact": "9628888824",
            "avatarIcon": "M",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "NEW JAGRITI HOSPITAL",
            "Contact": "7525044651",
            "avatarIcon": "NJ",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 12",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "NOVA HOSPITAL",
            "Contact": "\"9935900300",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "OMEGA PLUS HOSPTAL",
            "Contact": "9506211111",
            "avatarIcon": "OP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "Opal Hospital",
            "Contact": " ",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 22",
                "ICU": " 8"
            }
        },
        {
            "hospitalName": "ORIYANA HOSPITAL",
            "Contact": "9889096994",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 43",
                "ICU": " 12"
            }
        },
        {
            "hospitalName": "Papular (bachaw)",
            "Contact": " ",
            "avatarIcon": "P(",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 32",
                "ICU": " 8"
            }
        },
        {
            "hospitalName": "POPULAR HOSPITAL( kakarmatta)",
            "Contact": "9415336349",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 68",
                "ICU": " 28"
            }
        },
        {
            "hospitalName": "Pt. DDU Hospital (VARANASI)",
            "Contact": " ",
            "avatarIcon": "PD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 147",
                "ICU": " 33"
            }
        },
        {
            "hospitalName": "PT. Rajan Mishra DRDO Hospital BHU",
            "Contact": " ",
            "avatarIcon": "PR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 212"
            }
        },
        {
            "hospitalName": "Ram Bilas Hospital",
            "Contact": "7007519584",
            "avatarIcon": "RB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "Royal Maternity & Nurcing Home",
            "Contact": " ",
            "avatarIcon": "RM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "Rudra kashi Hospital",
            "Contact": " ",
            "avatarIcon": "RK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "S A S HOSPITAL",
            "Contact": "8805552711",
            "avatarIcon": "SA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 12"
            }
        },
        {
            "hospitalName": "SAINATH HOSPITAL",
            "Contact": "8423180554",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 18",
                "ICU": " 7"
            }
        },
        {
            "hospitalName": "SANTUSTI HOSPITAL",
            "Contact": " ",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "SARTHAK SUGICAL HOSPITAL",
            "Contact": "9415273558",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " 7"
            }
        },
        {
            "hospitalName": "SHARABH HOSPITAL",
            "Contact": "8762725118",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "SHIV SURGICAL Hospital",
            "Contact": "9415202299",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 6"
            }
        },
        {
            "hospitalName": "Shubham Sadbhawna Hospital",
            "Contact": "9918180444",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 9"
            }
        },
        {
            "hospitalName": "Shivam Hospital",
            "Contact": "9453038026",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "SINGH MEDICAL AND SURGICAL CENTER",
            "Contact": "9919322221",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 21",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "Survoday Hospital",
            "Contact": "7738298787",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 19",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "TRAUMA CENTRE",
            "Contact": "9415352841",
            "avatarIcon": "TC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 68",
                "ICU": " 27"
            }
        },
        {
            "hospitalName": "Trimurti Hospital",
            "Contact": "9839716838",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 12",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "UPKAR HOSPITAL",
            "Contact": "9415336349",
            "avatarIcon": "UH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " 3"
            }
        },
        {
            "hospitalName": "VARSOVA HOSPITAL",
            "Contact": " ",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " 3"
            }
        },
        {
            "hospitalName": "MANI HOSPITAL",
            "Contact": " ",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " 6"
            }
        }
    ],
    "KANPUR": [
        {
            "hospitalName": "GSVM Medical College (Kanpur Nagar)",
            "Contact": "8400331049",
            "avatarIcon": "GM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 224",
                "Oxygen": " 0",
                "ICU": " 190"
            }
        },
        {
            "hospitalName": "Narayana Medical college and research center,Panki",
            "Contact": " ",
            "avatarIcon": "NM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 180",
                "ICU": " 120"
            }
        },
        {
            "hospitalName": "Rama institute of medical sciences and research center Mandhana",
            "Contact": "NA",
            "avatarIcon": "RI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 365",
                "ICU": " 75"
            }
        }
    ],
    "PRAYAGRAJ": [
        {
            "hospitalName": "MLN Medical College (Prayagraj)",
            "Contact": " ",
            "avatarIcon": "MM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 224",
                "ICU": " 304"
            }
        },
        {
            "hospitalName": "VINEETA HOSPITAL PVT LTD",
            "Contact": "8840563683",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 40",
                "ICU": " 59"
            }
        },
        {
            "hospitalName": "NC Railway Central Hospital Prayagraj",
            "Contact": "9794837515",
            "avatarIcon": "NR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 81",
                "ICU": " 16"
            }
        },
        {
            "hospitalName": "YASH HOSPITAL",
            "Contact": "7985095647",
            "avatarIcon": "YH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 29",
                "ICU": " 46"
            }
        },
        {
            "hospitalName": "NARAYAN SWAROOP HOSPITAL",
            "Contact": "9335626524",
            "avatarIcon": "NS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 22",
                "ICU": " 28"
            }
        },
        {
            "hospitalName": "TB Sapru Hospital, (PrayagRaj)",
            "Contact": "9454455135",
            "avatarIcon": "TS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 120",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "United Medicity Hospital & Medical College",
            "Contact": "9889031242",
            "avatarIcon": "UM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 72",
                "Oxygen": " 128",
                "ICU": " 100"
            }
        },
        {
            "hospitalName": "Nazreth",
            "Contact": "NA",
            "avatarIcon": "N",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 41",
                "ICU": " 9"
            }
        },
        {
            "hospitalName": "Shambhu Nath Hos.",
            "Contact": "NA",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 50",
                "ICU": " 100"
            }
        }
    ],
    "SONIPAT": [
        {
            "hospitalName": "NIDAAN HOSPITAL PVT.",
            "Contact": "7082086975",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "HARYANA HOSPITAL",
            "Contact": "7206652666",
            "avatarIcon": "HH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "CHC, JUAN, SONIPAT, HARYANA",
            "Contact": "9416084115",
            "avatarIcon": "CJ",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "CHC GANUR",
            "Contact": "9416285550",
            "avatarIcon": "CG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "SDH GOHANA",
            "Contact": "9050909333",
            "avatarIcon": "SG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "CHC Kharkhoda",
            "Contact": "9871128511",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "ICU": " 5",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "SANJIVNI HOSPITAL, SONIPAT",
            "Contact": "9416012508",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "ASHIRWAD BHAGAT HOSPITAL",
            "Contact": "9729042823",
            "avatarIcon": "AB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "TULIP HOSPITAL",
            "Contact": "9812303197",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "ICU": " 0",
                "Ventilator": " 20"
            }
        },
        {
            "hospitalName": "SUNHERI DEVI HOSPITAL",
            "Contact": "9812600847",
            "avatarIcon": "SD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "SITA HOSPITAL SONIPAT",
            "Contact": "9992300019",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "OSCAR HOSPITAL",
            "Contact": "8395917744",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "ICU": " 0",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": "MEMORIAL HOSPITAL",
            "Contact": "919416015377",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 16",
                "ICU": " 0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "GAJRAJ HOSPITAL",
            "Contact": "8800252638",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "CYGNUS HOSPITAL, Sonipat",
            "Contact": "8059792770",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "SAXSENA HOSPITAL",
            "Contact": "9467575442",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Dayanand Hospital-Sonepat",
            "Contact": "9416084115",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "PHC PURKHAS",
            "Contact": "9899151927",
            "avatarIcon": "PP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "ICU": " 30",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Bhagwan Dass Hospital",
            "Contact": "9996667001",
            "avatarIcon": "BD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 27",
                "ICU": " 0",
                "Ventilator": " 13"
            }
        },
        {
            "hospitalName": "FIIMS Hospital",
            "Contact": "01302231932",
            "avatarIcon": "FH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 63",
                "ICU": " 0",
                "Ventilator": " 36"
            }
        },
        {
            "hospitalName": "Civil Hospital, Sonipat (9050007668 )",
            "Contact": "1263298534",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 182",
                "ICU": " 0",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": "BPS MEDICAL COLLEGE KHANPUR , SONIPAT ( 01263283088)",
            "Contact": "NA",
            "avatarIcon": "BM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 169",
                "ICU": " 80",
                "Ventilator": " 49"
            }
        }
    ],
    "PANIPAT": [
        {
            "hospitalName": "CHC Dadlana",
            "Contact": "",
            "avatarIcon": "CD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "ICU": " 8",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Shubham Hospital",
            "Contact": "9896122663",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Rainbow Hospital",
            "Contact": "8059625656",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "CHC Khotpura",
            "Contact": "",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "ICU": " 9",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Aashirwad Hospital",
            "Contact": "",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "ICU": " 0",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": "Shree Balajee Multispeciality Hospital",
            "Contact": "",
            "avatarIcon": "SB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "ICU": " 0",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": "Hyderabadi General Hospital & Nursing Home",
            "Contact": "",
            "avatarIcon": "HG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "ICU": " 0",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": "Shree Badri Das Hospital and ICU Care Centre",
            "Contact": "",
            "avatarIcon": "SB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Chhabra Hospital",
            "Contact": "9466533099",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "MAX PLUS HOSPITAL",
            "Contact": "7027768001",
            "avatarIcon": "MP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "ICU": " 0",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": "GALAXY HOSPITAL",
            "Contact": "9729001001",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "ICU": " 0",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": "Aayushmaan Bhav Hospital",
            "Contact": "8059010390",
            "avatarIcon": "AB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "ICU": " 4",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": "Cygnus Hospital panipat",
            "Contact": "9466365570",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "shobhit Hospital",
            "Contact": "",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "ICU": " 2",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Prime Hospital Health Leaders",
            "Contact": "9838400809",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "ICU": " 2",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "IBM HOSPITAL & TRAUMA CENTRE",
            "Contact": "8868000007",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "ICU": " 0",
                "Ventilator": " 12"
            }
        },
        {
            "hospitalName": "APEX HOSPITAL & TRAUMA CENTRE",
            "Contact": "9813077233",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "ICU": " 0",
                "Ventilator": " 26"
            }
        },
        {
            "hospitalName": "SDH Samalkha",
            "Contact": "8685047942",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "ICU": " 20",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "LHDM & Prem Hospital",
            "Contact": "9416369866",
            "avatarIcon": "L&",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 71",
                "ICU": " 0",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": "Park Hospital GT road panipat",
            "Contact": "8059625656",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 52",
                "ICU": " 14",
                "Ventilator": " 16"
            }
        },
        {
            "hospitalName": "Civil Hospital panipat",
            "Contact": "",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 74",
                "ICU": " 20",
                "Ventilator": " 9"
            }
        },
        {
            "hospitalName": "Guru Teg Bhadur Sanjeevani Hospital",
            "Contact": "9568056246",
            "avatarIcon": "GT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 307",
                "ICU": " 7",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "NC Medical College",
            "Contact": "NA",
            "avatarIcon": "NM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 42",
                "ICU": " 483",
                "Ventilator": " 5"
            }
        }
    ],
    "RANCHI": [
        {
            "hospitalName": "The 7 Palm Hospital",
            "Contact": "9102994184",
            "avatarIcon": "T7",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Summer Hospital, Singh More",
            "Contact": "9934596605",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "St. Barnabas",
            "Contact": "",
            "avatarIcon": "SB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Singhpur Nursing",
            "Contact": "",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Sewa Sadan",
            "Contact": "6204801102",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Santevita Hospital, HB Road, Near Firayalal Ranchi",
            "Contact": "7360033390",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Samford Kokar",
            "Contact": "7360080040",
            "avatarIcon": "SK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "SAI Hospital",
            "Contact": "",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "SADAR",
            "Contact": "9709016627",
            "avatarIcon": "S",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 72"
            }
        },
        {
            "hospitalName": "RPS Hospital",
            "Contact": "7728027992",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Rinchi Trust Hospital, ITKI Road, Kathal More, Ranchi",
            "Contact": "7234002900",
            "avatarIcon": "RT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "RIMS",
            "Contact": "0651-2545404",
            "avatarIcon": "R",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 62",
                "Oxygen": " 288"
            }
        },
        {
            "hospitalName": "Resaldar baba, Doranda",
            "Contact": "9939676323",
            "avatarIcon": "RB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Rani Hospital, (Children Only)",
            "Contact": "7677111010",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6"
            }
        },
        {
            "hospitalName": "Ram Pyari Super Speciality Hospital, Karmtoli",
            "Contact": "9264443201",
            "avatarIcon": "RP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Raj Hospital, Main Road",
            "Contact": "9771488888",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Pulse Hospital, Bariatu",
            "Contact": "8989805546",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Promise Healthcare",
            "Contact": "",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Prabhawati Hospital",
            "Contact": "7808912001",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Paras Hospital",
            "Contact": "9297991020",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Orchid, HB Road, Near Lalpur, Ranchi",
            "Contact": "9308120007",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Military Hospital, Namkum",
            "Contact": "",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 11"
            }
        },
        {
            "hospitalName": "Medica",
            "Contact": "06516606000",
            "avatarIcon": "M",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Medanta, IRBA Ormanjhi",
            "Contact": "8374648765",
            "avatarIcon": "MI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "MDLM",
            "Contact": "",
            "avatarIcon": "M",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Life Care",
            "Contact": "",
            "avatarIcon": "LC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Lake view",
            "Contact": "",
            "avatarIcon": "LV",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Kanke General Hospital",
            "Contact": "",
            "avatarIcon": "KG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Jaslok Hospital",
            "Contact": "8709099926",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Jagarnath",
            "Contact": "",
            "avatarIcon": "J",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 11",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Health Point, Bariyatu",
            "Contact": "7004711714",
            "avatarIcon": "HP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Gurunanak Hospital, Station Road, Ranchi",
            "Contact": "",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Gulmohar Hospital, Booty More, Ranchi",
            "Contact": "8210001754",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Dwarka Hospital",
            "Contact": "",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Devkamal Hospital",
            "Contact": "7808782978",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "City Trust Hospital",
            "Contact": "",
            "avatarIcon": "CT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "CCL",
            "Contact": "",
            "avatarIcon": "C",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "CCC Khelgaon",
            "Contact": "",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 704",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Belin Nursing Home",
            "Contact": "",
            "avatarIcon": "BN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Anjuman Islamia Hospital, Konka Road Ranchi",
            "Contact": "",
            "avatarIcon": "AI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "All CHCs",
            "Contact": "",
            "avatarIcon": "AC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 140",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Alam Hospital Bariyatu",
            "Contact": "7070991116",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "ACMS Hospital",
            "Contact": "9123880423",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 27",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "7th Day Adventist",
            "Contact": "",
            "avatarIcon": "7D",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 2"
            }
        }
    ],
    "KOLKATA": [
        {
            "hospitalName": "",
            "Contact": "",
            "avatarIcon": "",
            "foundUseful": "0",
            "bedsData": {
                "": "0"
            }
        },
        {
            "hospitalName": "",
            "Contact": "",
            "avatarIcon": "",
            "foundUseful": "0",
            "bedsData": {
                "": "0"
            }
        },
        {
            "hospitalName": "",
            "Contact": "",
            "avatarIcon": "",
            "foundUseful": "0",
            "bedsData": {
                "": "0"
            }
        },
        {
            "hospitalName": "",
            "Contact": "",
            "avatarIcon": "",
            "foundUseful": "0",
            "bedsData": {
                "": "0"
            }
        },
        {
            "hospitalName": "",
            "Contact": "",
            "avatarIcon": "",
            "foundUseful": "0",
            "bedsData": {
                "": "0"
            }
        },
        {
            "hospitalName": "Woodlands Multispeciality Hospital Limited - Unit MS  Bricks Inn (Satellite Pvt. Building)",
            "Contact": "7605058606/7605058607.",
            "avatarIcon": "WM",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "20",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Woodlands Multispeciality Hospital Limited - ASL PRIME HOTEL (Satellite Hotel)",
            "Contact": "7604075551",
            "avatarIcon": "WM",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "28",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Woodlands Multispeciality Hospital Limited (Private Hospital (Self Run))",
            "Contact": "033- 40337000",
            "avatarIcon": "WM",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "18",
                "HDU": "0",
                "CCU": "8",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "UMA MEDICAL RELATED INSTITUTE (Private Hospital (Self Run))",
            "Contact": "033 6701 7711",
            "avatarIcon": "UM",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "4",
                "Oxygen": "8",
                "HDU": "0",
                "CCU": "9",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "TRA General Hospital (Private Hospital (Self Run))",
            "Contact": "033 4011 6800",
            "avatarIcon": "TG",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "5",
                "Oxygen": "10",
                "HDU": "0",
                "CCU": "13",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "The Calcutta Medical Research Institute - Finestays Shanti Priya Guest House (Satellite Hotel)",
            "Contact": "033 4090 4090",
            "avatarIcon": "TC",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "29",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "The Calcutta Medical Research Institute (Private Hospital (Self Run))",
            "Contact": "033 40904090",
            "avatarIcon": "TC",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "106",
                "HDU": "4",
                "CCU": "36",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "TECHNO INDIA DAMA HOSPITAL  RESEARCH CENTRE (Private Hospital (Self Run))",
            "Contact": "9831148099",
            "avatarIcon": "TI",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "10",
                "Oxygen": "12",
                "HDU": "8",
                "CCU": "6",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "Tata Medical Center (Private Hospital (Self Run))",
            "Contact": "033 6605 7000",
            "avatarIcon": "TM",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "15",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "2",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "Swastik Seva Sadan (Private Hospital (Self Run))",
            "Contact": "098314 54235",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "1",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Sterling Hospital (Private Hospital (Self Run))",
            "Contact": "033 2530 1313",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "9",
                "Oxygen": "30",
                "HDU": "0",
                "CCU": "6",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "Srijoni Healing Home (Private Hospital (Self Run))",
            "Contact": "033 2372 0038",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "2",
                "Oxygen": "7",
                "HDU": "2",
                "CCU": "3",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Sri Aurobindo Seva Kendra EEDF (Private Hospital (Self Run))",
            "Contact": "033 4017 1717",
            "avatarIcon": "SA",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "3",
                "HDU": "0",
                "CCU": "4",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "SHUSRUSHA NURSING HOME PVT. LTD. (Private Hospital (Self Run))",
            "Contact": "033 2362 8863",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "2",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Shree Vishudhanand Saraswati Marwari Hospital (Private Hospital (Self Run))",
            "Contact": "033 3061 2200",
            "avatarIcon": "SV",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "40",
                "Oxygen": "20",
                "HDU": "0",
                "CCU": "1",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Saroj Gupta Cancer Centre  Research Institute (Private Hospital (Self Run))",
            "Contact": "033 2453 2781",
            "avatarIcon": "SG",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "4",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Salt Lake City Medical Centre (Private Hospital (Self Run))",
            "Contact": "086972 21002",
            "avatarIcon": "SL",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "21",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Sahid Khudiram Bose Hospital  Diagnostic Centre (Private Hospital (Self Run))",
            "Contact": "033 2544 3250",
            "avatarIcon": "SK",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Ruby General Hospital (Private Hospital (Self Run))",
            "Contact": "9831179175",
            "avatarIcon": "RG",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "8",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "RSV HOSPITAL PRIVATE LIMITED (Private Hospital (Self Run))",
            "Contact": "033 3001 3000",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "9",
                "HDU": "4",
                "CCU": "7",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "ROYD NURSING HOME AND HEALTHCARE CENTRE  (Private Hospital (Self Run))",
            "Contact": "033 4601 6536",
            "avatarIcon": "RN",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Revival Nursing Home (Private Hospital (Self Run))",
            "Contact": "9830057020, 9051959540",
            "avatarIcon": "RN",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "5",
                "Oxygen": "15",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "Repose Clinic  Research Centre Private Limited (Private Hospital (Self Run))",
            "Contact": "033 2287 1442",
            "avatarIcon": "RC",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "4",
                "HDU": "0",
                "CCU": "1",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "Renaissance Hospital Pvt ltd (Private Hospital (Self Run))",
            "Contact": "033-4014-4014, 033-4014-4015, 7603006752",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "17",
                "HDU": "2",
                "CCU": "8",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "Recovery Nursing Home  (Private Hospital (Self Run))",
            "Contact": "9831010041",
            "avatarIcon": "RN",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "4",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Ramkrishna Medical Complex Nursing Home (Private Hospital (Self Run))",
            "Contact": "070597 02169",
            "avatarIcon": "RM",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "12",
                "HDU": "6",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Rameswara Nursing Home Private Limited (Private Hospital (Self Run))",
            "Contact": "033 2355 2923",
            "avatarIcon": "RN",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "22",
                "HDU": "4",
                "CCU": "6",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "Ramakrishna Sarada Mission Matri Bhavan (Private Hospital (Self Run))",
            "Contact": "033 2464 4189",
            "avatarIcon": "RS",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Ramakrishna mission seva pratishthan (Private Hospital (Self Run))",
            "Contact": "033 2475 3639",
            "avatarIcon": "RM",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "34",
                "Oxygen": "46",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Rabindranath Tagore International Institute of Cardiac Sciences - Shree Balaji Guest house (Satellite Hotel)",
            "Contact": "8017167758",
            "avatarIcon": "RT",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "16",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Rabindranath Tagore International Institute of Cardiac Sciences (Private Hospital (Self Run))",
            "Contact": "84200-00623",
            "avatarIcon": "RT",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "133",
                "HDU": "60",
                "CCU": "16",
                "Ventilator": "52"
            }
        },
        {
            "hospitalName": "Peerless Hospital (Private Hospital (Self Run))",
            "Contact": "033 40111222",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "23",
                "HDU": "2",
                "CCU": "0",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "Park Medical Research  Welfare Society (Private Hospital (Self Run))",
            "Contact": "033-22801986  /   22801987",
            "avatarIcon": "PM",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "12",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Ohio Hospital (Private Hospital (Self Run))",
            "Contact": "033 6602 6602",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "19",
                "Oxygen": "0",
                "HDU": "11",
                "CCU": "8",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "North City Hospital (Private Hospital (Self Run))",
            "Contact": "033 6605 0999",
            "avatarIcon": "NC",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "56",
                "HDU": "5",
                "CCU": "9",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "Nightingale Hospital (Private Hospital (Self Run))",
            "Contact": "3335005949",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "40",
                "HDU": "0",
                "CCU": "5",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "Nehru Memorial Techno Global Hospital (Private Hospital (Self Run))",
            "Contact": "033 6630 3030",
            "avatarIcon": "NM",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "5",
                "HDU": "0",
                "CCU": "2",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "NATIONAL KIDNEY AND PROSTATE CLINIC HOLEP SURGERY  PCNL (Private Hospital (Self Run))",
            "Contact": "084200 17061",
            "avatarIcon": "NK",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "4",
                "HDU": "3",
                "CCU": "0",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "Narayana Multispeciality Hospital, Jessore Road, Kolkata (Private Hospital (Self Run))",
            "Contact": "080675 06860",
            "avatarIcon": "NM",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "12",
                "Oxygen": "20",
                "HDU": "17",
                "CCU": "4",
                "Ventilator": "7"
            }
        },
        {
            "hospitalName": "Narayan Memorial Hospital, Behala (Private Hospital (Self Run))",
            "Contact": "096744 46902",
            "avatarIcon": "NM",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "8",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "4",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "NABAJIBAN HOSPITAL PVT.LTD. (Private Hospital (Self Run))",
            "Contact": "033 2555 1544",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "8"
            }
        },
        {
            "hospitalName": "Mission of Mercy Hospital  Research Centre - SIAMTON INN  (Satellite Hotel)",
            "Contact": "9836008484",
            "avatarIcon": "MO",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "15",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Mission of Mercy Hospital  Research Centre (Private Hospital (Self Run))",
            "Contact": "9836008484/9831659585/8910221306",
            "avatarIcon": "MO",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "8",
                "Oxygen": "44",
                "HDU": "4",
                "CCU": "9",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "MEDIVIEW (Private Hospital (Self Run))",
            "Contact": "033 4020 8989",
            "avatarIcon": "M(",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Medica Superspecialty Hospital - Medica Annex II (Satellite Hotel)",
            "Contact": "033 66520000",
            "avatarIcon": "MS",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "38",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Medica Superspecialty Hospital - Laxmi Guest House (Satellite Hotel)",
            "Contact": "033 66520000",
            "avatarIcon": "MS",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "24",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Medica Superspecialty Hospital (Private Hospital (Self Run))",
            "Contact": "7044488841",
            "avatarIcon": "MS",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "2",
                "HDU": "17",
                "CCU": "7",
                "Ventilator": "29"
            }
        },
        {
            "hospitalName": "Medica Covid Hospital - Kishore Bharati Stadium. (Private Hospital (Self Run))",
            "Contact": "7044488841",
            "avatarIcon": "MC",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "48",
                "Oxygen": "0",
                "HDU": "42",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Marwari Relief Society Hospital  (Private Hospital (Self Run))",
            "Contact": "22743722",
            "avatarIcon": "MR",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "17",
                "Oxygen": "24",
                "HDU": "9",
                "CCU": "10",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Kothari Medical Centre (Private Hospital (Self Run))",
            "Contact": "033 4012 7000",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "12",
                "Oxygen": "142",
                "HDU": "0",
                "CCU": "12",
                "Ventilator": "8"
            }
        },
        {
            "hospitalName": "Kolkata Port Trust Hospital, Centenary Hospital (Private Hospital (Self Run))",
            "Contact": "033 2401 4503",
            "avatarIcon": "KP",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "20",
                "Oxygen": "23",
                "HDU": "4",
                "CCU": "5",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Kasturi Medical Centre Pvt Ltd. (Private Hospital (Self Run))",
            "Contact": "033 6614 1915",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "60",
                "HDU": "0",
                "CCU": "10",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "K P C Medical College and Hospital (Private Hospital (Self Run))",
            "Contact": "03340449700 Extn117",
            "avatarIcon": "KP",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "17",
                "HDU": "0",
                "CCU": "5",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "JITENDRA NARAYAN RAY SISHU SEVA BHAWAN (Private Hospital (Self Run))",
            "Contact": "033 2985-2985",
            "avatarIcon": "JN",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "25",
                "HDU": "0",
                "CCU": "10",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "IRIS MULTISPECIALITY HOSPITAL (Private Hospital (Self Run))",
            "Contact": "083349 22922, 9073366676",
            "avatarIcon": "IM",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "31",
                "HDU": "2",
                "CCU": "7",
                "Ventilator": "6"
            }
        },
        {
            "hospitalName": "Institute of Neurosciences (Private Hospital (Self Run))",
            "Contact": "033 4030 9999",
            "avatarIcon": "IO",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "4",
                "HDU": "6",
                "CCU": "6",
                "Ventilator": "6"
            }
        },
        {
            "hospitalName": "ILS Hospitals, Dumdum (Private Hospital (Self Run))",
            "Contact": "03340315041/42",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "4",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "20"
            }
        },
        {
            "hospitalName": "Horizon Life Line pvt Ltd (Private Hospital (Self Run))",
            "Contact": "033 4601 8600",
            "avatarIcon": "HL",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "30",
                "HDU": "0",
                "CCU": "12",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Health Point Nursing Home (Private Hospital (Self Run))",
            "Contact": "6292206001",
            "avatarIcon": "HP",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "29",
                "HDU": "4",
                "CCU": "7",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Haj House Annexe Charnock Hospital  (Private Hospital (Self Run))",
            "Contact": "7596056414",
            "avatarIcon": "HH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "GOOD SAMARITAN HOSPITAL,    , DR BIRESH GUHSA STREET, KOL  (Private Hospital (Self Run))",
            "Contact": "098300 76111",
            "avatarIcon": "GS",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "20",
                "HDU": "0",
                "CCU": "2",
                "Ventilator": "24"
            }
        },
        {
            "hospitalName": "GD Hospital  Diabetes Institute (Private Hospital (Self Run))",
            "Contact": "033 3987  3987",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "55",
                "HDU": "5",
                "CCU": "6",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Fortis Kidney Institute (Private Hospital (Self Run))",
            "Contact": "033 6627 6800",
            "avatarIcon": "FK",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "29",
                "HDU": "0",
                "CCU": "5",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Fortis Hospital Anandapur - OYO  Guestopolis (Satellite Pvt. Building)",
            "Contact": "033 66284646",
            "avatarIcon": "FH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "12",
                "Oxygen": "2",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Fortis Hospital Anandapur (Private Hospital (Self Run))",
            "Contact": "033-6628 4646",
            "avatarIcon": "FH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "24",
                "HDU": "0",
                "CCU": "5",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "Flemming Hospital Zodiac (Private Hospital (Self Run))",
            "Contact": "033 4068 6161",
            "avatarIcon": "FH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "27",
                "HDU": "20",
                "CCU": "30",
                "Ventilator": "15"
            }
        },
        {
            "hospitalName": "Eves Clinic Nursing Home and PolyclinicMultispeciality nursing home  (Private Hospital (Self Run))",
            "Contact": "086971 12664",
            "avatarIcon": "EC",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "10",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Eskag Sanjeevani Pvt. Ltd Baranagar (Private Hospital (Self Run))",
            "Contact": "8335045888",
            "avatarIcon": "ES",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "19",
                "Oxygen": "10",
                "HDU": "0",
                "CCU": "5",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Eskag Sanjeevani Multispeciality Hospital (Private Hospital (Self Run))",
            "Contact": "033 4025 1800",
            "avatarIcon": "ES",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "4",
                "HDU": "0",
                "CCU": "2",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Ekbalpur Nursing Home (Private Hospital (Self Run))",
            "Contact": "033 7144 0909",
            "avatarIcon": "EN",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "18",
                "Oxygen": "26",
                "HDU": "5",
                "CCU": "6",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "Dreamland Nursing Home Kolkata (Private Hospital (Self Run))",
            "Contact": "033 2555 3217",
            "avatarIcon": "DN",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "5",
                "HDU": "0",
                "CCU": "1",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "DM Hospitals P Ltd. (Private Hospital (Self Run))",
            "Contact": "033 2453 6221",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "2",
                "HDU": "1",
                "CCU": "1",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Divine Nursing Home Pvt Ltd  (Private Hospital (Self Run))",
            "Contact": "033 4015 6789",
            "avatarIcon": "DN",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "26",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "DESUN Hospital Private (Private Hospital (Self Run))",
            "Contact": "090517 15171",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "78",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "26",
                "Ventilator": "27"
            }
        },
        {
            "hospitalName": "DESUN Hospital - OYO  SilverKey Hotel Mayfair Tower (Satellite Hotel)",
            "Contact": "9051715171",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "40",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Dafodil Nursing Home  (Private Hospital (Self Run))",
            "Contact": "033 4050 5555",
            "avatarIcon": "DN",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "10",
                "CCU": "0",
                "Ventilator": "8"
            }
        },
        {
            "hospitalName": "Columbia Asia Hospital, Salt Lake (Private Hospital (Self Run))",
            "Contact": "033 6600 3300",
            "avatarIcon": "CA",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "34",
                "HDU": "0",
                "CCU": "3",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "Charring Cross Nursing Home (Private Hospital (Self Run))",
            "Contact": "033 23598353",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "8",
                "Oxygen": "10",
                "HDU": "0",
                "CCU": "8",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Charnock Hospital - Hotel Eastern Plaza  (Satellite Hotel)",
            "Contact": "7044041000",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "40",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Charnock Hospital (Private Hospital (Self Run))",
            "Contact": "9831539000",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "7",
                "HDU": "0",
                "CCU": "16",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "Calcutta Heart Clinic, Salt Lake (Private Hospital (Self Run))",
            "Contact": "9830999989",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "20",
                "HDU": "9",
                "CCU": "9",
                "Ventilator": "15"
            }
        },
        {
            "hospitalName": "BMRC HOSPITALS (Private Hospital (Self Run))",
            "Contact": "1800 345 5500",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "2",
                "CCU": "4",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "BM Birla Heart Research Centre  CK Birla Hospitals (Private Hospital (Self Run))",
            "Contact": "033 4088 4088",
            "avatarIcon": "BB",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Bhagirathi Neotia Woman and Child Care Centre (Private Hospital (Self Run))",
            "Contact": "033 4040 5000",
            "avatarIcon": "BN",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Bellona Nursing Home  Diagnostic Centre Private Limited (Private Hospital (Self Run))",
            "Contact": "033 2448 7059",
            "avatarIcon": "BN",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "2",
                "Oxygen": "3",
                "HDU": "2",
                "CCU": "2",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Belle Vue Clinic - Hotel Orion West Willows (Satellite Hotel)",
            "Contact": "9330759579",
            "avatarIcon": "BV",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "50",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Belle Vue Clinic (Private Hospital (Self Run))",
            "Contact": "9331847265",
            "avatarIcon": "BV",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "8",
                "Oxygen": "0",
                "HDU": "5",
                "CCU": "0",
                "Ventilator": "14"
            }
        },
        {
            "hospitalName": "Baksi Orthopaedics Trauma and Rehabilitation Centre Pvt Ltd (Private Hospital (Self Run))",
            "Contact": "043323730952 / 9831155083",
            "avatarIcon": "BO",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "20",
                "HDU": "0",
                "CCU": "2",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Baine Hospital (Private Hospital (Self Run))",
            "Contact": "096748 73262",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "6",
                "HDU": "0",
                "CCU": "8",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "B. P. PODDAR HOSPITAL  MEDICAL RESEARCH LTD. - B.P.Poddar and Parvati Devi Institute of Medical Sciences and Managements (Satellite Pvt. Building)",
            "Contact": "9051588789 / 9007032832",
            "avatarIcon": "BP",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "B. P. PODDAR HOSPITAL  MEDICAL RESEARCH LTD. (Private Hospital (Self Run))",
            "Contact": "9051588789 / 9073944094",
            "avatarIcon": "BP",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "15",
                "HDU": "0",
                "CCU": "11",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "Ashok Nursing Home (Private Hospital (Self Run))",
            "Contact": "1800 3070 9555",
            "avatarIcon": "AN",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "5",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Apollo Gleneagles Hospital - VISITEL - A Boutique Hotel (Satellite Hotel)",
            "Contact": "9804000486/ 9804000419 ( 8AM to 8PM) /  9804000461 (Sunday)",
            "avatarIcon": "AG",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "75",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Apollo Gleneagles Hospital (Private Hospital (Self Run))",
            "Contact": "9804000416 9804000246 8 PM to 8AM and Sundays and Holidays  9804000461",
            "avatarIcon": "AG",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "27",
                "HDU": "14",
                "CCU": "7",
                "Ventilator": "10"
            }
        },
        {
            "hospitalName": "Apex Institute of Medical Sciences (Private Hospital (Self Run))",
            "Contact": "033 7125 6666",
            "avatarIcon": "AI",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "6",
                "Oxygen": "22",
                "HDU": "4",
                "CCU": "6",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Apex Clinic Private Limited (Private Hospital (Self Run))",
            "Contact": "033 2406 8071",
            "avatarIcon": "AC",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "50",
                "HDU": "0",
                "CCU": "8",
                "Ventilator": "22"
            }
        },
        {
            "hospitalName": "Anandalok Hospital (Private Hospital (Self Run))",
            "Contact": "033 2359 2932",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "21",
                "Ventilator": "6"
            }
        },
        {
            "hospitalName": "AMRI, Salt Lake - Hotel Soujourn (Satellite Hotel)",
            "Contact": "7044087872",
            "avatarIcon": "AS",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "AMRI, Salt Lake - Hotel Monotel (Satellite Hotel)",
            "Contact": "7044087872",
            "avatarIcon": "AS",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "AMRI, Salt Lake (Private Hospital (Self Run))",
            "Contact": "033 6606-3800",
            "avatarIcon": "AS",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "3",
                "CCU": "6",
                "Ventilator": "10"
            }
        },
        {
            "hospitalName": "AMRI Hospital, Mukundupur (Private Hospital (Self Run))",
            "Contact": "033 6606-1000",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "116",
                "Oxygen": "114",
                "HDU": "9",
                "CCU": "21",
                "Ventilator": "15"
            }
        },
        {
            "hospitalName": "AMRI Hospital, Dhakuria (Private Hospital (Self Run))",
            "Contact": "033 6626-0000",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "31",
                "HDU": "7",
                "CCU": "23",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "All Asia Medical Institute A Unit Of Harsh Medical Centre Pvt Ltd (Private Hospital (Self Run))",
            "Contact": "033 4001 2200",
            "avatarIcon": "AA",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "10",
                "HDU": "0",
                "CCU": "5",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "The Calcutta Medical Research Institute Govt. Requisitioned Pvt. Hospital (Govt. Requisitioned Pvt. Hospital)",
            "Contact": "033 40904090",
            "avatarIcon": "TC",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "14",
                "HDU": "1",
                "CCU": "0",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "Rabindranath Tagore International Institute of Cardiac Sciences Category Private Hospital Govt. Requisitioned (Govt. Requisitioned Pvt. Hospital)",
            "Contact": "84200-00623",
            "avatarIcon": "RT",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Peerless Hospital Govt Requisitioned (Govt. Requisitioned Pvt. Hospital)",
            "Contact": "033 40111222",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "K P C Medical College and Hospital Govt Requisitioned (Govt. Requisitioned Pvt. Hospital)",
            "Contact": "03340449700 Extn117",
            "avatarIcon": "KP",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "181",
                "HDU": "6",
                "CCU": "10",
                "Ventilator": "3"
            }
        },
        {
            "hospitalName": "Islamia Hospital patnership with Charring Cross (Govt. Requisitioned Pvt. Hospital)",
            "Contact": "9903776366",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "20",
                "Oxygen": "20",
                "HDU": "0",
                "CCU": "6",
                "Ventilator": "1"
            }
        },
        {
            "hospitalName": "ILS Hospitals, Dumdum Govt. Requisitioned Pvt. Hospital (Govt. Requisitioned Pvt. Hospital)",
            "Contact": "03340315041/42",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "17",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "8"
            }
        },
        {
            "hospitalName": "Fortis Hospital Anandapur Category Private Hospital Govt. Requisitioned (Govt. Requisitioned Pvt. Hospital)",
            "Contact": "033-6628 4646",
            "avatarIcon": "FH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "3",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "DESUN Hospital (Govt. Requisitioned Pvt. Hospital)",
            "Contact": "090517 15171",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "17",
                "HDU": "0",
                "CCU": "3",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "Dafodil Nursing Home Govt. Requisitioned (Govt. Requisitioned Pvt. Hospital)",
            "Contact": "033 4050 5555",
            "avatarIcon": "DN",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "10",
                "Oxygen": "0",
                "HDU": "1",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Columbia Asia Hospital, Salt Lake Govt. Requisitioned Pvt. Hospital (Govt. Requisitioned Pvt. Hospital)",
            "Contact": "033 6600 3300",
            "avatarIcon": "CA",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Charnock Hospital  Govt. Requisitioned  (Govt. Requisitioned Pvt. Hospital)",
            "Contact": "9831539000",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Belle Vue Clinic, Govt Requisitioned (Govt. Requisitioned Pvt. Hospital)",
            "Contact": "9331847265",
            "avatarIcon": "BV",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "8",
                "Oxygen": "0",
                "HDU": "5",
                "CCU": "0",
                "Ventilator": "14"
            }
        },
        {
            "hospitalName": "AMRI HOSPITAL, SALT LAKE Govt Requisitioned (Govt. Requisitioned Pvt. Hospital)",
            "Contact": "033 6606 3800/ 6680 0000",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Sambhunath Pandit Hospital (Government Hospital)",
            "Contact": "033 2302 2820",
            "avatarIcon": "SP",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "84",
                "Oxygen": "59",
                "HDU": "23",
                "CCU": "0",
                "Ventilator": "17"
            }
        },
        {
            "hospitalName": "R. G. Kar Medical College and Hospital  (Government Hospital)",
            "Contact": "033 2530 4557",
            "avatarIcon": "RG",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "254",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Nil Ratan Sarkar Medical College and Hospital (Government Hospital)",
            "Contact": "033 2286 0033 /033 2286-0103-08",
            "avatarIcon": "NR",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "84",
                "Oxygen": "120",
                "HDU": "70",
                "CCU": "0",
                "Ventilator": "4"
            }
        },
        {
            "hospitalName": "Medical College and Hospital, Kolkata, (Government Hospital)",
            "Contact": "033 22551614 /033 22551621",
            "avatarIcon": "MC",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "16",
                "Oxygen": "315",
                "HDU": "0",
                "CCU": "41",
                "Ventilator": "31"
            }
        },
        {
            "hospitalName": "M R Bangur Hospital (Government Hospital)",
            "Contact": "088207 02070/88202 07070",
            "avatarIcon": "MR",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "13",
                "Oxygen": "245",
                "HDU": "215",
                "CCU": "0",
                "Ventilator": "73"
            }
        },
        {
            "hospitalName": "I.D. And B.G. Hospital (Government Hospital)",
            "Contact": "033 2303 2200",
            "avatarIcon": "IA",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "227",
                "HDU": "46",
                "CCU": "24",
                "Ventilator": "2"
            }
        },
        {
            "hospitalName": "ESI Hospital Sealdah (Government Hospital)",
            "Contact": "6290514135",
            "avatarIcon": "EH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "60",
                "Oxygen": "40",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "ESI Hospital Maniktala (Government Hospital)",
            "Contact": "9831790546",
            "avatarIcon": "EH",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "86",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "College of Medicine  Sagore Dutta Hospital (Government Hospital)",
            "Contact": "033-25531316  /033-25239058",
            "avatarIcon": "CO",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "136",
                "Oxygen": "0",
                "HDU": "95",
                "CCU": "0",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "CHITTARANJAN NATIONAL CANCER INSTITUTE-CNCI (Government Hospital)",
            "Contact": "033 247655102/6289726011",
            "avatarIcon": "CN",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "114",
                "Oxygen": "161",
                "HDU": "131",
                "CCU": "0",
                "Ventilator": "0"
            }
        },
        {
            "hospitalName": "Calcutta National Medical College and Hospital (Government Hospital)",
            "Contact": "91634 21886",
            "avatarIcon": "CN",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "14",
                "Oxygen": "142",
                "HDU": "30",
                "CCU": "20",
                "Ventilator": "5"
            }
        },
        {
            "hospitalName": "AMRI, Salt Lake - Vivekananda Yuba Bharati Krirangan Salt Lake Stadium (Satellite Govt. Building)",
            "Contact": "9163645544/ 7596058696",
            "avatarIcon": "AS",
            "foundUseful": "0",
            "bedsData": {
                "Regular": "0",
                "Oxygen": "0",
                "HDU": "0",
                "CCU": "0",
                "Ventilator": "0"
            }
        }
    ],
    "AHMEDABAD": [
        {
            "hospitalName": " Tapan Hospital, Satellite",
            "Contact": "NA",
            "avatarIcon": " TAPAN HOSPITAL, SATELLITE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 7",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " HCG Hospital, Mithakali",
            "Contact": "NA",
            "avatarIcon": " HCG HOSPITAL, MITHAKALI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " CIMS Hospital, Sola,",
            "Contact": "NA",
            "avatarIcon": " CIMS HOSPITAL, SOLA,",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 28",
                "Oxygen": " 17",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " AVISHKAR",
            "Contact": "NA",
            "avatarIcon": " AVISHKAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 4",
                "ICU": " 6",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " RYTHAM HOSPITAL",
            "Contact": "NA",
            "avatarIcon": " RYTHAM HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 6",
                "ICU": " 3",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Jankalyan hospital, Narol",
            "Contact": "NA",
            "avatarIcon": " JANKALYAN HOSPITAL, NAROL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 6",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Venus",
            "Contact": "NA",
            "avatarIcon": " VENUS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 6",
                "ICU": " 3",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sunrise",
            "Contact": "NA",
            "avatarIcon": " SUNRISE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 37",
                "Oxygen": " 17",
                "ICU": " 8",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " SARDAR",
            "Contact": "NA",
            "avatarIcon": " SARDAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 23",
                "ICU": " 5",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Devasyam Children Hospital",
            "Contact": "NA",
            "avatarIcon": " DEVASYAM CHILDREN HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " City Plus",
            "Contact": "NA",
            "avatarIcon": " CITY PLUS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 6",
                "ICU": " 1",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " OM Family Care",
            "Contact": "NA",
            "avatarIcon": " OM FAMILY CARE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " ADITI",
            "Contact": "094092 54092",
            "avatarIcon": " ADITI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 15",
                "ICU": " 1",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Safal Medical hospital",
            "Contact": "NA",
            "avatarIcon": " SAFAL MEDICAL HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "Oxygen": " 9",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " GLOBAL HOSPITAL",
            "Contact": "NA",
            "avatarIcon": " GLOBAL HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 28",
                "Oxygen": " 13",
                "ICU": " 4",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Shreeji icu & multi. Spe. Hospital",
            "Contact": "NA",
            "avatarIcon": " SHREEJI ICU & MULTI. SPE. HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 1",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sushrusha, Navrangpura",
            "Contact": "NA",
            "avatarIcon": " SUSHRUSHA, NAVRANGPURA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 47",
                "ICU": " 5",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": " Krishna hospital",
            "Contact": "NA",
            "avatarIcon": " KRISHNA HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 2",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Ohm Hospital",
            "Contact": "NA",
            "avatarIcon": " OHM HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 3",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " BAPS Yogiji Maharaj",
            "Contact": "NA",
            "avatarIcon": " BAPS YOGIJI MAHARAJ",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 25",
                "Oxygen": " 12",
                "ICU": " 2",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Chandramani Hospital",
            "Contact": "NA",
            "avatarIcon": " CHANDRAMANI HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 13",
                "ICU": " 7",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " FAITH HOSPI. & ICU",
            "Contact": "NA",
            "avatarIcon": " FAITH HOSPI. & ICU",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 12",
                "ICU": " 8",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Rabadia Multi Spe. Hospital Nikol",
            "Contact": "NA",
            "avatarIcon": " RABADIA MULTI SPE. HOSPITAL NIKOL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 27",
                "ICU": " 2",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Narayan medical hospital",
            "Contact": "NA",
            "avatarIcon": " NARAYAN MEDICAL HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 22",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " LOTUS",
            "Contact": "NA",
            "avatarIcon": " LOTUS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 11",
                "ICU": " 7",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Gayatri heart & med. Hospital",
            "Contact": "NA",
            "avatarIcon": " GAYATRI HEART & MED. HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 7",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " CUH, Central United hospital, Odhav",
            "Contact": "079 2657 5151",
            "avatarIcon": " CUH, CENTRAL UNITED HOSPITAL, ODHAV",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "Oxygen": " 35",
                "ICU": " 9",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Star Hospital, Bapunagar,",
            "Contact": "NA",
            "avatarIcon": " STAR HOSPITAL, BAPUNAGAR,",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 30",
                "ICU": " 6",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Asian sup. Spe. Hospital",
            "Contact": "NA",
            "avatarIcon": " ASIAN SUP. SPE. HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 0",
                "ICU": " 6",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Mithiba Hospital",
            "Contact": "NA",
            "avatarIcon": " MITHIBA HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "Oxygen": " 15",
                "ICU": " 2",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " CIVIL Hospital Sola",
            "Contact": "NA",
            "avatarIcon": " CIVIL HOSPITAL SOLA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "Oxygen": " 40",
                "ICU": " 18",
                "Ventilator": " 30"
            }
        },
        {
            "hospitalName": " Rajasthan Hospital -Shahibaug",
            "Contact": "NA",
            "avatarIcon": " RAJASTHAN HOSPITAL -SHAHIBAUG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 74",
                "Oxygen": " 15",
                "ICU": " 12",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": " Devasya Hospital, bopal",
            "Contact": "NA",
            "avatarIcon": " DEVASYA HOSPITAL, BOPAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 21",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Panchshil",
            "Contact": "NA",
            "avatarIcon": " PANCHSHIL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " 3",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Pancham",
            "Contact": "NA",
            "avatarIcon": " PANCHAM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " SHAKTI GENERAL HOSPITAL",
            "Contact": "NA",
            "avatarIcon": " SHAKTI GENERAL HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 8",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Jyoti multi. Spe. Hospital",
            "Contact": "NA",
            "avatarIcon": " JYOTI MULTI. SPE. HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 12",
                "ICU": " 5",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Bopal Icu & Trauma Center, Ambli,",
            "Contact": "NA",
            "avatarIcon": " BOPAL ICU & TRAUMA CENTER, AMBLI,",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 35",
                "Oxygen": " 0",
                "ICU": " 13",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " saviour (lakhudi tadav)",
            "Contact": "NA",
            "avatarIcon": " SAVIOUR (LAKHUDI TADAV)",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 23",
                "ICU": " 15",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " SARTHAK",
            "Contact": "NA",
            "avatarIcon": " SARTHAK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "Oxygen": " 7",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Medicity Campus Hospital",
            "Contact": "NA",
            "avatarIcon": " MEDICITY CAMPUS HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 383",
                "Oxygen": " 1,424",
                "ICU": " 0",
                "Ventilator": " 368"
            }
        },
        {
            "hospitalName": " SMS HOSPITAL",
            "Contact": "NA",
            "avatarIcon": " SMS HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 70",
                "Oxygen": " 202",
                "ICU": " 48",
                "Ventilator": " 20"
            }
        },
        {
            "hospitalName": " Sanjivani hospital , Vastrapur",
            "Contact": "NA",
            "avatarIcon": " SANJIVANI HOSPITAL , VASTRAPUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 33",
                "Oxygen": " 9",
                "ICU": " 8",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Athen Hospital",
            "Contact": "NA",
            "avatarIcon": " ATHEN HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 10",
                "ICU": " 4",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Aarna medical hospital",
            "Contact": "NA",
            "avatarIcon": " AARNA MEDICAL HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " ICON",
            "Contact": "NA",
            "avatarIcon": " ICON",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "Oxygen": " 20",
                "ICU": " 7",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Life care hospital, Khanpur",
            "Contact": "NA",
            "avatarIcon": " LIFE CARE HOSPITAL, KHANPUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "Oxygen": " 12",
                "ICU": " 9",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Sahyog hospital",
            "Contact": "NA",
            "avatarIcon": " SAHYOG HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "Oxygen": " 9",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " DHS Multispecialty Hospital, Vastrapur",
            "Contact": "NA",
            "avatarIcon": " DHS MULTISPECIALTY HOSPITAL, VASTRAPUR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 11",
                "Oxygen": " 23",
                "ICU": " 3",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " SARTHAK HOSPITAL,GHODASAR",
            "Contact": "NA",
            "avatarIcon": " SARTHAK HOSPITAL,GHODASAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 30",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Jankalyan hospital",
            "Contact": "7265-920-920",
            "avatarIcon": " JANKALYAN HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 21",
                "ICU": " 3",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": " Cheers Hospital",
            "Contact": "NA",
            "avatarIcon": " CHEERS HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 3",
                "ICU": " 8",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " ANAND SURGICAL HOSPITAL",
            "Contact": "NA",
            "avatarIcon": " ANAND SURGICAL HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 40",
                "Oxygen": " 39",
                "ICU": " 6",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": " Avadh Hospital",
            "Contact": "NA",
            "avatarIcon": " AVADH HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 20",
                "ICU": " 20",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " PALLAV HOSPITAL",
            "Contact": "NA",
            "avatarIcon": " PALLAV HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 6",
                "ICU": " 5",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Shalby, Nikol",
            "Contact": "NA",
            "avatarIcon": " SHALBY, NIKOL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 73",
                "Oxygen": " 36",
                "ICU": " 10",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": " Mansi hospital",
            "Contact": "NA",
            "avatarIcon": " MANSI HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Sparsh multi. Spe. Hospital",
            "Contact": "NA",
            "avatarIcon": " SPARSH MULTI. SPE. HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 11",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Kothiya Hospital, Nikol,",
            "Contact": "NA",
            "avatarIcon": " KOTHIYA HOSPITAL, NIKOL,",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 14",
                "ICU": " 8",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Saviour Annexe Hospital, Ashram road",
            "Contact": "NA",
            "avatarIcon": " SAVIOUR ANNEXE HOSPITAL, ASHRAM ROAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 23",
                "ICU": " 5",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Samkit hospital",
            "Contact": "NA",
            "avatarIcon": " SAMKIT HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 10",
                "ICU": " 6",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " shreeji Hospital",
            "Contact": "NA",
            "avatarIcon": " SHREEJI HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 2",
                "ICU": " 7",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Riyana Multi. Spe. Hospital",
            "Contact": "NA",
            "avatarIcon": " RIYANA MULTI. SPE. HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " ADWAIT",
            "Contact": "NA",
            "avatarIcon": " ADWAIT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 14",
                "ICU": " 2",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Siddhi Vinayak Hospital",
            "Contact": "NA",
            "avatarIcon": " SIDDHI VINAYAK HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 6",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " Jeevandeep",
            "Contact": "NA",
            "avatarIcon": " JEEVANDEEP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 11",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Spandan Hospital, Vastral,",
            "Contact": "NA",
            "avatarIcon": " SPANDAN HOSPITAL, VASTRAL,",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " 8",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " Aartham Hospital, Ambawadi,",
            "Contact": "NA",
            "avatarIcon": " AARTHAM HOSPITAL, AMBAWADI,",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 74",
                "Oxygen": " 34",
                "ICU": " 13",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": " SENTARA HOSPITAL",
            "Contact": "NA",
            "avatarIcon": " SENTARA HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Ansh multi. Spe. hospital",
            "Contact": "NA",
            "avatarIcon": " ANSH MULTI. SPE. HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 1",
                "ICU": " 1",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Satyamev",
            "Contact": "NA",
            "avatarIcon": " SATYAMEV",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "Oxygen": " 23",
                "ICU": " 13",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " Turning Point Hriday Se",
            "Contact": "NA",
            "avatarIcon": " TURNING POINT HRIDAY SE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 37",
                "Oxygen": " 21",
                "ICU": " 17",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": " Sal Hospital",
            "Contact": "NA",
            "avatarIcon": " SAL HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 195",
                "ICU": " 11",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " URO CURE ASSO. AARNA HOSPI.",
            "Contact": "NA",
            "avatarIcon": " URO CURE ASSO. AARNA HOSPI.",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 0",
                "ICU": " 17",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " V CARE",
            "Contact": "NA",
            "avatarIcon": " V CARE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "Oxygen": " 15",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Rajdeep children hospital",
            "Contact": "NA",
            "avatarIcon": " RAJDEEP CHILDREN HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 16",
                "ICU": " 5",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Sgvp hospital, SG road",
            "Contact": "NA",
            "avatarIcon": " SGVP HOSPITAL, SG ROAD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 55",
                "Oxygen": " 11",
                "ICU": " 6",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": " Trisha multi speciality, Nirnay nagar",
            "Contact": "NA",
            "avatarIcon": " TRISHA MULTI SPECIALITY, NIRNAY NAGAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 4",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Rugved Hospital",
            "Contact": "NA",
            "avatarIcon": " RUGVED HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "Oxygen": " 33",
                "ICU": " 6",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " krishna shalby",
            "Contact": "NA",
            "avatarIcon": " KRISHNA SHALBY",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 65",
                "Oxygen": " 50",
                "ICU": " 25",
                "Ventilator": " 15"
            }
        },
        {
            "hospitalName": " Laxmi hospital",
            "Contact": "NA",
            "avatarIcon": " LAXMI HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 20",
                "ICU": " 6",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Shraddha medical nursing & heart hospital",
            "Contact": "NA",
            "avatarIcon": " SHRADDHA MEDICAL NURSING & HEART HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Kanba Hospital, Nikol, Viratnagar",
            "Contact": "NA",
            "avatarIcon": " KANBA HOSPITAL, NIKOL, VIRATNAGAR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "Oxygen": " 31",
                "ICU": " 5",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": " Kanba ICU & Trauma",
            "Contact": "NA",
            "avatarIcon": " KANBA ICU & TRAUMA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 5",
                "ICU": " 6",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": " Care Plus",
            "Contact": "NA",
            "avatarIcon": " CARE PLUS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 1",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " Guru Prem",
            "Contact": "NA",
            "avatarIcon": " GURU PREM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 2",
                "ICU": " 7",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " PUSHYA HOSPITAL",
            "Contact": "NA",
            "avatarIcon": " PUSHYA HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 40",
                "Oxygen": " 4",
                "ICU": " 3",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": " LOKHANDWALA HOSPITAL",
            "Contact": "NA",
            "avatarIcon": " LOKHANDWALA HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 12",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": " Bodyline Hospital",
            "Contact": "NA",
            "avatarIcon": " BODYLINE HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 11",
                "Oxygen": " 28",
                "ICU": " 8",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": " TLGH",
            "Contact": "NA",
            "avatarIcon": " TLGH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 9",
                "ICU": " 9",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": " Shivalik Multi Specia.",
            "Contact": "NA",
            "avatarIcon": " SHIVALIK MULTI SPECIA.",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 18",
                "ICU": " 8",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": " SVP Hospital",
            "Contact": "NA",
            "avatarIcon": " SVP HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 179",
                "Oxygen": " 115",
                "ICU": " 13",
                "Ventilator": " 12"
            }
        },
        {
            "hospitalName": " LG Hospital",
            "Contact": "NA",
            "avatarIcon": " LG HOSPITAL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 52",
                "ICU": " 2",
                "Ventilator": " 7"
            }
        }
    ],
    "ULHASNAGAR": [
        {
            "hospitalName": "Womens Hospital Maternity",
            "Contact": "02512587233",
            "avatarIcon": "WH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 57"
            }
        },
        {
            "hospitalName": "Shri Balaji Hospital",
            "Contact": "8805994559",
            "avatarIcon": "SB",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 23"
            }
        },
        {
            "hospitalName": "Shree Sai Hospital",
            "Contact": "9224525000",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 20"
            }
        },
        {
            "hospitalName": "Bhagwan Hospital",
            "Contact": "9822884000",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 8"
            }
        },
        {
            "hospitalName": "Dhanvantari Hospital",
            "Contact": "9405669913",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 20"
            }
        },
        {
            "hospitalName": "Gungeet Hospital",
            "Contact": "9820208233",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 11"
            }
        },
        {
            "hospitalName": "Surekha Crticare",
            "Contact": "9322247850",
            "avatarIcon": "SC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 52"
            }
        },
        {
            "hospitalName": "Venkatesh Hospital",
            "Contact": "9970856789",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 15"
            }
        },
        {
            "hospitalName": "Century Rayon Hospital",
            "Contact": "NA",
            "avatarIcon": "CR",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 28"
            }
        },
        {
            "hospitalName": "Ashirwad Hospital",
            "Contact": "9765621313",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 4"
            }
        },
        {
            "hospitalName": "UMC Covid DCHC",
            "Contact": "NA",
            "avatarIcon": "UC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 90"
            }
        },
        {
            "hospitalName": "UMC COVID DCH",
            "Contact": "NA",
            "avatarIcon": "UC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 76"
            }
        },
        {
            "hospitalName": "LifeCare",
            "Contact": "8484951789",
            "avatarIcon": "L",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 35"
            }
        },
        {
            "hospitalName": "INDIAN RED CROSS",
            "Contact": "NA",
            "avatarIcon": "IR",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 55"
            }
        },
        {
            "hospitalName": "Meera Hospital",
            "Contact": "NA",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 31"
            }
        },
        {
            "hospitalName": "S N Hospital",
            "Contact": "NA",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 29"
            }
        },
        {
            "hospitalName": "MaxiLife Hospital",
            "Contact": "NA",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 46"
            }
        },
        {
            "hospitalName": "Sai CritiCare Hospital",
            "Contact": "NA",
            "avatarIcon": "SC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 42"
            }
        },
        {
            "hospitalName": "Meera NX",
            "Contact": "9322664872",
            "avatarIcon": "MN",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 22"
            }
        }
    ],
    "CHENNAI": [
        {
            "hospitalName": "Dr.Metha hospital Chetpet",
            "Contact": "7305026464",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Nichani Hospital",
            "Contact": "9840566854",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "KMS Health Centre Pvt Ltd,Selaiyur",
            "Contact": "7200462325",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sims (Srm Institutes For Medical Science Hospital) Nungumbakkam",
            "Contact": "7339355444",
            "avatarIcon": "S(",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Vijaya Group Of Hospital",
            "Contact": "9500126786",
            "avatarIcon": "VG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Vihaa Hospital Anna Nagar",
            "Contact": "9841432348",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Vhs, Adayar",
            "Contact": "4422542971",
            "avatarIcon": "VA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Venkateswara Hospital",
            "Contact": "9841275552",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Vasantha Subramanian Hospitals Pvt Ltd, Chetpet",
            "Contact": "9884875111",
            "avatarIcon": "VS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Trinity Acute care Hospital",
            "Contact": "8056621324",
            "avatarIcon": "TA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Tosh Hospitals Pvt Ltd",
            "Contact": "9894467903",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "The Guest Hospital, Kilpauk",
            "Contact": "8903573717",
            "avatarIcon": "TG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 5",
                "ICU": " 1",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Tagore Medical College & Hospital",
            "Contact": "4430101111",
            "avatarIcon": "TM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 336",
                "Oxygen": " 39",
                "ICU": " 16",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": "Sundaram Medical Foundation",
            "Contact": "9884245646",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "St. Thomas Hospital",
            "Contact": "8126948524",
            "avatarIcon": "ST",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Srm Medical College & Hospital",
            "Contact": "8056072277",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 156",
                "Oxygen": " 11",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Srinivasan Rajalakshmi Memorial Hospital",
            "Contact": "9444896010",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Srinivas Priya Hospital Perambur",
            "Contact": "7550057577",
            "avatarIcon": "SP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " 4",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Sri Sathya Sai Medical College & Research Institue",
            "Contact": "4427440700",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 316",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sri Ramachandra Medical College Hospital, Porur",
            "Contact": "8939433555",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "Oxygen": " 123",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sree Lakshmi Clinic and Maternity Centre",
            "Contact": "9952900795",
            "avatarIcon": "SL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Sree Balaji Medical College & Hospital",
            "Contact": "4442911000",
            "avatarIcon": "SB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 17",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sooriya Hospital",
            "Contact": "9500085477",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sivam Hospitals, Ullagaram",
            "Contact": "9787859841",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 5",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "SIMS Hospital Vadapalani",
            "Contact": "7397783719",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Saveetha Medical College",
            "Contact": "4466726672",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sakthi Hospital Research Centre Triplicane",
            "Contact": "9840264444",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sai Speed Hospital, Arumbakkam",
            "Contact": "8610850006",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "RPS hospital pvt ltd",
            "Contact": "7358764200",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Retteri Sri Kumaran Health Centre (P) Ltd, Kolathur",
            "Contact": "9003956999",
            "avatarIcon": "RS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Rama Rau Polyclinc, Kilpauk",
            "Contact": "9092967547",
            "avatarIcon": "RR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Prashanth Hospital",
            "Contact": "9840338752",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Panimalar Medical College & Hospital",
            "Contact": "8610850481",
            "avatarIcon": "PM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Padmini Nursing Home Chetpet",
            "Contact": "9791023518",
            "avatarIcon": "PN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Orthomed Hospital",
            "Contact": "9940099992",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Noble Hospital",
            "Contact": "9445330478",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "New Lifemed Hospital",
            "Contact": "9840021112",
            "avatarIcon": "NL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Medway Hospital,Mugappair",
            "Contact": "6374577148",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "New Hope Multi Speciality Hospital, Kilpauk",
            "Contact": "9092967547",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "National Hospital",
            "Contact": "9381166707",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Muthu hospital",
            "Contact": "9445330478",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "MMRV Hospital",
            "Contact": "9884926283",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Miot Hospital",
            "Contact": "7824060120",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 23",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Mint Hospital",
            "Contact": "7338777444",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Melmaruvathur Aadhiparasakthi Medical College",
            "Contact": "7904683997",
            "avatarIcon": "MA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Medway Hospital",
            "Contact": "7299057830",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Maya Nursing Home",
            "Contact": "9841383989",
            "avatarIcon": "MN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "M.R Hospital",
            "Contact": "7358582427",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 1",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "M G M Health Care Pvt Ltd",
            "Contact": "9384002406",
            "avatarIcon": "MG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Lifeline Hospitals",
            "Contact": "9500073959",
            "avatarIcon": "LH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Life Care Hospital",
            "Contact": "8939396762",
            "avatarIcon": "LC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Laksha Hospital Royapettah",
            "Contact": "9500054797",
            "avatarIcon": "LH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "KVT Speciality Hospital, Erukanchery",
            "Contact": "7358330026",
            "avatarIcon": "KS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Kumaran Hospital Pvt ltd",
            "Contact": "9791144090",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Kauvery Hospital",
            "Contact": "7825809004",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Karpaga Vinayaga Medical Science & Ri",
            "Contact": "4471565100",
            "avatarIcon": "KV",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 144",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Kanchi Kamakoti Child Trust Hospital",
            "Contact": "9677003334",
            "avatarIcon": "KK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "K.P Hospital, Adambakkam",
            "Contact": "9952029234",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Hycare Super Speciality Hospital",
            "Contact": "9384060780",
            "avatarIcon": "HS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Gunasekaran Hospitals Pvt Ltd",
            "Contact": "8939414061",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Gleneagles Global Health City",
            "Contact": "8056266444",
            "avatarIcon": "GG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "GLB Hospital",
            "Contact": "9176911860",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "GKM Hospital Pvt Ltd",
            "Contact": "8220894051",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Girishwari Hospital Alwarpet",
            "Contact": "9677059215",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Frontier Lifeline Hospital",
            "Contact": "9790745924",
            "avatarIcon": "FL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Fortis Malar Hospital",
            "Contact": "9962000500",
            "avatarIcon": "FM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Dr.Kamakshi Memorial Hospital",
            "Contact": "8754594545",
            "avatarIcon": "DM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "CSI Kalyani General Hospital",
            "Contact": "8667243945",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 50",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Chettinad Hospital",
            "Contact": "9094713000",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Chennai Orthopedic Centre -ER",
            "Contact": "9790082003",
            "avatarIcon": "CO",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "C.s.i Rainy Multi Speciality Hospital Tondaiarpet",
            "Contact": "9941415544",
            "avatarIcon": "CR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Bloom Fertility and Healthcare, Velachery",
            "Contact": "9551841153",
            "avatarIcon": "BF",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Billroth Hospitals Shenoy Nagar, Chennai TN.",
            "Contact": "4442921777",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Bharathiraja Hospital & Research Centre Pvt Ltd",
            "Contact": "9444170203",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Bhaarath medical college and hospital",
            "Contact": "446111222",
            "avatarIcon": "BM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Be Well Hospital Anna nagar",
            "Contact": "9500032230",
            "avatarIcon": "BW",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Aysha Hospital",
            "Contact": "7824030941",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Astra Ortho N Spine Care Hospital",
            "Contact": "9677311333",
            "avatarIcon": "AO",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 4",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "ArunVijaya Hospitals, KK Nagar",
            "Contact": "6380760063",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Appasamy Hospital",
            "Contact": "6379350953",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 44",
                "ICU": " 8",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Apollo Hospitals, Vanagaram",
            "Contact": "7824821042",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 81",
                "ICU": " 6",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Apollo Hospital, Greams Road",
            "Contact": "7397242423",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        }
    ],
    "RAIPUR": [
        {
            "hospitalName": "Sarvodaya Hospital P-51, Dubey Colony",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 11"
            }
        },
        {
            "hospitalName": "Jain Multispeciality Hospital Raipur",
            "Contact": "NA",
            "avatarIcon": "JM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 4"
            }
        },
        {
            "hospitalName": "Dr. M.R. Bhagwat Memorial Hospital",
            "Contact": "NA",
            "avatarIcon": "DM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 22"
            }
        },
        {
            "hospitalName": "Balgopal children hospital",
            "Contact": "NA",
            "avatarIcon": "BC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 11",
                "Oxygen": " 5"
            }
        },
        {
            "hospitalName": "Sai Kripa Hospital Siltara Raipur",
            "Contact": "NA",
            "avatarIcon": "SK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 13"
            }
        },
        {
            "hospitalName": "Roop Jeevan Hospital Devendra Nagar",
            "Contact": "NA",
            "avatarIcon": "RJ",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 2"
            }
        },
        {
            "hospitalName": "shree sai paikra hospital",
            "Contact": "NA",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Kamla Women's Hospital",
            "Contact": "NA",
            "avatarIcon": "KW",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 5"
            }
        },
        {
            "hospitalName": "niranjan hospital Raipur",
            "Contact": "NA",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 3"
            }
        },
        {
            "hospitalName": "city 24 hospital badipara",
            "Contact": "NA",
            "avatarIcon": "C2",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 17"
            }
        },
        {
            "hospitalName": "Ramkatha hospital santoshi nagar",
            "Contact": "NA",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16"
            }
        },
        {
            "hospitalName": "Evangelical Mission Hospital Tilda",
            "Contact": "NA",
            "avatarIcon": "EM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "Oxygen": " 20"
            }
        },
        {
            "hospitalName": "science college Atari hirapur raipur",
            "Contact": "NA",
            "avatarIcon": "SC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 347",
                "Oxygen": " 150"
            }
        },
        {
            "hospitalName": "maa sharda hospital and prasuti raipur",
            "Contact": "NA",
            "avatarIcon": "MS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 2"
            }
        },
        {
            "hospitalName": "shri balaji hospital tikrapara raipur chhattisgarh",
            "Contact": "NA",
            "avatarIcon": "SB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 15"
            }
        },
        {
            "hospitalName": "care & cure hospital",
            "Contact": "NA",
            "avatarIcon": "C&",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 17"
            }
        },
        {
            "hospitalName": "vardan hospital",
            "Contact": "NA",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "Oxygen": " 10"
            }
        },
        {
            "hospitalName": "Aarogya Hospital",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15"
            }
        },
        {
            "hospitalName": "shreyansh hospital arang",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "laxmi care hospital Chandkhuri Raipur",
            "Contact": "NA",
            "avatarIcon": "LC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 5"
            }
        },
        {
            "hospitalName": "shri krishna hospital kharora",
            "Contact": "NA",
            "avatarIcon": "SK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Shree Sai Care Hospital",
            "Contact": "NA",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 6"
            }
        },
        {
            "hospitalName": "karuna shree hospital raipur",
            "Contact": "NA",
            "avatarIcon": "KS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "vatsalya children hospital raipur",
            "Contact": "NA",
            "avatarIcon": "VC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 3"
            }
        },
        {
            "hospitalName": "nirogyam hospital sejbahar",
            "Contact": "NA",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 5"
            }
        },
        {
            "hospitalName": "swastik nursing home and multi super speciality hospital",
            "Contact": "NA",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 17"
            }
        },
        {
            "hospitalName": "Sanjeevani CBCC cancer Hospital",
            "Contact": "NA",
            "avatarIcon": "SC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 1"
            }
        },
        {
            "hospitalName": "Life Care Hospital",
            "Contact": "NA",
            "avatarIcon": "LC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 3"
            }
        },
        {
            "hospitalName": "smc heart institute and ivf research centre",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 36"
            }
        },
        {
            "hospitalName": "Jai Ambe multispeciality hospital raipur",
            "Contact": "NA",
            "avatarIcon": "JA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15"
            }
        },
        {
            "hospitalName": "vidya hospital",
            "Contact": "NA",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Gaytri hospital",
            "Contact": "NA",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 40"
            }
        },
        {
            "hospitalName": "Divan multispeciality hospital",
            "Contact": "NA",
            "avatarIcon": "DM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 21"
            }
        },
        {
            "hospitalName": "Dhanwantri vancer & Joint Replacement center",
            "Contact": "NA",
            "avatarIcon": "DV",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8"
            }
        },
        {
            "hospitalName": "N.K D Hospital",
            "Contact": "NA",
            "avatarIcon": "ND",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 3"
            }
        },
        {
            "hospitalName": "Mital instutte of medical science Raipur",
            "Contact": "NA",
            "avatarIcon": "MI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 25"
            }
        },
        {
            "hospitalName": "C.I.M.T Hospital Raipur",
            "Contact": "NA",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 15"
            }
        },
        {
            "hospitalName": "Lalmati multispeciality Raipur",
            "Contact": "NA",
            "avatarIcon": "LM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 15"
            }
        },
        {
            "hospitalName": "khushi hospital",
            "Contact": "NA",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 5"
            }
        },
        {
            "hospitalName": "ayush multispeciality hospital raipur",
            "Contact": "NA",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Urmila Hospital Raipur",
            "Contact": "NA",
            "avatarIcon": "UH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 21",
                "Oxygen": " 10"
            }
        },
        {
            "hospitalName": "shubhkamna hospital",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15"
            }
        },
        {
            "hospitalName": "shri kalyan hospital",
            "Contact": "NA",
            "avatarIcon": "SK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 14"
            }
        },
        {
            "hospitalName": "CG Hospital",
            "Contact": "NA",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 6"
            }
        },
        {
            "hospitalName": "New Raipura Hospital",
            "Contact": "NA",
            "avatarIcon": "NR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 16",
                "Oxygen": " 3"
            }
        },
        {
            "hospitalName": "Horizon hospital Raipur",
            "Contact": "NA",
            "avatarIcon": "HH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 17"
            }
        },
        {
            "hospitalName": "Ram Kiran Hospital",
            "Contact": "NA",
            "avatarIcon": "RK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20"
            }
        },
        {
            "hospitalName": "Life Line Hospital",
            "Contact": "NA",
            "avatarIcon": "LL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "Oxygen": " 2"
            }
        },
        {
            "hospitalName": "Mahamaya-Superspeciality-Hospital-",
            "Contact": "NA",
            "avatarIcon": "M",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "Oxygen": " 14"
            }
        },
        {
            "hospitalName": "District Hospital Pandri Raipur",
            "Contact": "NA",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 6"
            }
        },
        {
            "hospitalName": "CHC Kharora",
            "Contact": "NA",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16"
            }
        },
        {
            "hospitalName": "CHC Tilda",
            "Contact": "NA",
            "avatarIcon": "CT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 18"
            }
        },
        {
            "hospitalName": "Swapnil Nursing home",
            "Contact": "NA",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 4"
            }
        },
        {
            "hospitalName": "Sagar Multi Speciality Hospital",
            "Contact": "NA",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 10"
            }
        },
        {
            "hospitalName": "Petals Newborn And Children Hospital Bhimsen bhawan marg, Samta Colony Raipur",
            "Contact": "NA",
            "avatarIcon": "PN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 10"
            }
        },
        {
            "hospitalName": "Mahadeva hospital dawada colony raipur",
            "Contact": "NA",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 13"
            }
        },
        {
            "hospitalName": "Jaulkar ent hospital raipur",
            "Contact": "NA",
            "avatarIcon": "JE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 5"
            }
        },
        {
            "hospitalName": "Ashadeep hospital raipur",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 12"
            }
        },
        {
            "hospitalName": "Anushka hospital raipur amlidih",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10"
            }
        },
        {
            "hospitalName": "Shri Krishna Hospital Raipur",
            "Contact": "NA",
            "avatarIcon": "SK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 5"
            }
        },
        {
            "hospitalName": "Community Hall Arang ccc",
            "Contact": "NA",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 50"
            }
        },
        {
            "hospitalName": "Kriti covid care center",
            "Contact": "NA",
            "avatarIcon": "KC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 150",
                "Oxygen": " 50"
            }
        },
        {
            "hospitalName": "Spine And Skin Centre hospital raipur",
            "Contact": "NA",
            "avatarIcon": "SA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4"
            }
        },
        {
            "hospitalName": "Seva Bharti",
            "Contact": "NA",
            "avatarIcon": "SB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20"
            }
        },
        {
            "hospitalName": "Bihaan. Hospital, Vidhan Sabha Road, Raipur, Chhattisgarh",
            "Contact": "NA",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 22"
            }
        },
        {
            "hospitalName": "agarwal institute of sciences pvt ltd raipur cg",
            "Contact": "NA",
            "avatarIcon": "AI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "Oxygen": " 12"
            }
        },
        {
            "hospitalName": "SHRI ANANT SAI HOSPITAL",
            "Contact": "NA",
            "avatarIcon": "SA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 22",
                "Oxygen": " 20"
            }
        },
        {
            "hospitalName": "GOVT. PT. SHYAMACHARAN SHUKLA COLLEGE, DHARSIWA,",
            "Contact": "NA",
            "avatarIcon": "GP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 35",
                "Oxygen": " 15"
            }
        },
        {
            "hospitalName": "SHREE DANI CARE HOSPITAL DUNDA SEJBAHAR",
            "Contact": "NA",
            "avatarIcon": "SD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 12"
            }
        },
        {
            "hospitalName": "Railway Mandal Chikitsalaya Raipur",
            "Contact": "NA",
            "avatarIcon": "RM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 12"
            }
        },
        {
            "hospitalName": "Town Hall Tilda",
            "Contact": "NA",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 50",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Yashoda Children's Hospital",
            "Contact": "NA",
            "avatarIcon": "YC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10"
            }
        },
        {
            "hospitalName": "Upadhyay Hospital",
            "Contact": "NA",
            "avatarIcon": "UH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 29"
            }
        },
        {
            "hospitalName": "Mamta nurshing home",
            "Contact": "NA",
            "avatarIcon": "MN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 7"
            }
        },
        {
            "hospitalName": "laxmi care hospital",
            "Contact": "NA",
            "avatarIcon": "LC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 7"
            }
        },
        {
            "hospitalName": "Shri Hari krishna hospital raipur",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 5"
            }
        },
        {
            "hospitalName": "Gautam Multispeciality Hospital",
            "Contact": "NA",
            "avatarIcon": "GM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 11",
                "Oxygen": " 21"
            }
        },
        {
            "hospitalName": "Heritage Hospital, Raipur",
            "Contact": "NA",
            "avatarIcon": "HH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 19"
            }
        },
        {
            "hospitalName": "Vaidehi Hospital, Raipur",
            "Contact": "NA",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16"
            }
        },
        {
            "hospitalName": "Jai banjari mata (J.B.M) hospital raipur",
            "Contact": "NA",
            "avatarIcon": "JB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 8"
            }
        },
        {
            "hospitalName": "Ekta Children Hospital Raipur",
            "Contact": "NA",
            "avatarIcon": "EC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 26",
                "Oxygen": " 7"
            }
        },
        {
            "hospitalName": "Diwakar Hospital Raipur",
            "Contact": "NA",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 10"
            }
        },
        {
            "hospitalName": "Jivan Anmol Hosptal Raipur",
            "Contact": "NA",
            "avatarIcon": "JA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 18"
            }
        },
        {
            "hospitalName": "Jagannath Multispeciality",
            "Contact": "NA",
            "avatarIcon": "JM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 15"
            }
        },
        {
            "hospitalName": "Shreyansh Multi Speciality",
            "Contact": "NA",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 12"
            }
        },
        {
            "hospitalName": "Kalda Burn and Plastic Care Raipur",
            "Contact": "NA",
            "avatarIcon": "KB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 30"
            }
        },
        {
            "hospitalName": "Shri Narayana Raipur",
            "Contact": "NA",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 90"
            }
        },
        {
            "hospitalName": "We Care Hospital Raipur",
            "Contact": "NA",
            "avatarIcon": "WC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 20"
            }
        },
        {
            "hospitalName": "LifeWorth Hospital Raipur",
            "Contact": "NA",
            "avatarIcon": "LH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8"
            }
        },
        {
            "hospitalName": "Sankalp Hospital Raipur",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 60"
            }
        },
        {
            "hospitalName": "Shri Balaji Hospital Raipur",
            "Contact": "NA",
            "avatarIcon": "SB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 22",
                "Oxygen": " 100"
            }
        },
        {
            "hospitalName": "OM Hospital Raipur",
            "Contact": "NA",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0"
            }
        },
        {
            "hospitalName": "Suyash Hospital",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 80"
            }
        },
        {
            "hospitalName": "Shree Medishine Hospital Raipur",
            "Contact": "NA",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 30"
            }
        },
        {
            "hospitalName": "RIMS Raipur",
            "Contact": "NA",
            "avatarIcon": "RR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 90",
                "Oxygen": " 30"
            }
        },
        {
            "hospitalName": "Balco Cancer Hospital New Raipur",
            "Contact": "NA",
            "avatarIcon": "BC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 54"
            }
        },
        {
            "hospitalName": "MMI Hospital",
            "Contact": "NA",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 25"
            }
        },
        {
            "hospitalName": "Ram Krishna Care Hospital",
            "Contact": "NA",
            "avatarIcon": "RK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 35"
            }
        },
        {
            "hospitalName": "VY Hospital",
            "Contact": "NA",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 40"
            }
        },
        {
            "hospitalName": "ESIC Private Hospital",
            "Contact": "NA",
            "avatarIcon": "EP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 95"
            }
        }
    ],
    "BANGALORE": [],
    "BHOPAL": [
        {
            "hospitalName": "MAX MULTISPECIALITY BHOPAL HOSPITAL",
            "Contact": "",
            "avatarIcon": "MM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "Oxygen": " 5",
                "ICU": " 15"
            }
        },
        {
            "hospitalName": "Police Covid Care Hospital",
            "Contact": "",
            "avatarIcon": "PC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "COMPOSITE HOSPITAL BANGRASIYA",
            "Contact": "",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 28",
                "Oxygen": " 20",
                "ICU": " 2"
            }
        },
        {
            "hospitalName": "Green City Hospital",
            "Contact": "",
            "avatarIcon": "GC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 16",
                "Oxygen": " 20",
                "ICU": " 14"
            }
        },
        {
            "hospitalName": "Swastik hospital bhopal",
            "Contact": "",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " 14"
            }
        },
        {
            "hospitalName": "AYUSH CCC",
            "Contact": "",
            "avatarIcon": "AC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 80",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "People Medical College (Contract)",
            "Contact": "",
            "avatarIcon": "PM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 240",
                "ICU": " 60"
            }
        },
        {
            "hospitalName": "RAILWAY HOSPITAL",
            "Contact": "",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 44",
                "ICU": " 4"
            }
        },
        {
            "hospitalName": "BMHRC",
            "Contact": "",
            "avatarIcon": "B",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 40",
                "ICU": " 9"
            }
        },
        {
            "hospitalName": "LN MEDICAL COLLEGE (CONTRACT)",
            "Contact": "",
            "avatarIcon": "LM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 593",
                "ICU": " 100"
            }
        },
        {
            "hospitalName": "Chaudhary Hospital",
            "Contact": "",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "Oxygen": " 0",
                "ICU": " 20"
            }
        },
        {
            "hospitalName": "NAVODAYA_COVID_CENTRE",
            "Contact": "",
            "avatarIcon": "N",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 30",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "NAVJEEVAN",
            "Contact": "",
            "avatarIcon": "N",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 0",
                "ICU": " 2"
            }
        },
        {
            "hospitalName": "Narmada Trauma Centre",
            "Contact": "",
            "avatarIcon": "NT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 26",
                "ICU": " 8"
            }
        },
        {
            "hospitalName": "Career Institute of Medical Sciences Bhopal",
            "Contact": "",
            "avatarIcon": "CI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 120",
                "Oxygen": " 30",
                "ICU": " 12"
            }
        },
        {
            "hospitalName": "Noble Multispecilaity Hospital",
            "Contact": "",
            "avatarIcon": "NM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 39",
                "ICU": " 6"
            }
        },
        {
            "hospitalName": "ROSHAN Hospital",
            "Contact": "",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 16",
                "ICU": " 4"
            }
        },
        {
            "hospitalName": "AKSHAY HOSPITAL",
            "Contact": "",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 10",
                "ICU": " 9"
            }
        },
        {
            "hospitalName": "PALIWAL",
            "Contact": "",
            "avatarIcon": "P",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 40",
                "ICU": " 20"
            }
        },
        {
            "hospitalName": "Mahaveer Institute of medical Science and Research",
            "Contact": "",
            "avatarIcon": "MI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 222",
                "Oxygen": " 2",
                "ICU": " 26"
            }
        },
        {
            "hospitalName": "ANANTSHREE HOSPITAL",
            "Contact": "",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 50",
                "ICU": " 24"
            }
        },
        {
            "hospitalName": "ARADHNA MATERNITY & KIDNEY",
            "Contact": "",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 18",
                "ICU": " 9"
            }
        },
        {
            "hospitalName": "MILITARY HOSPITAL",
            "Contact": "",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 56",
                "Oxygen": " 18",
                "ICU": " 1"
            }
        },
        {
            "hospitalName": "Rudransh Multi Speacility Hospital",
            "Contact": "",
            "avatarIcon": "RM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " 12"
            }
        },
        {
            "hospitalName": "Apex Hospital",
            "Contact": "",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 17",
                "ICU": " 13"
            }
        },
        {
            "hospitalName": "CITI HOSPITAL MP NAGAR",
            "Contact": "",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "Oxygen": " 16",
                "ICU": " 14"
            }
        },
        {
            "hospitalName": "Bhopal Care Hospital",
            "Contact": "",
            "avatarIcon": "BC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 18",
                "ICU": " 16"
            }
        },
        {
            "hospitalName": "RKDF Medical College Hospital & Research Centre(CONTRACT)",
            "Contact": "",
            "avatarIcon": "RM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 180",
                "ICU": " 45"
            }
        },
        {
            "hospitalName": "Parul Hospital",
            "Contact": "",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 7",
                "ICU": " 7"
            }
        },
        {
            "hospitalName": "Chirag Children Hospital",
            "Contact": "",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 17",
                "Oxygen": " 19",
                "ICU": " 14"
            }
        },
        {
            "hospitalName": "CVS Apollo Hospital",
            "Contact": "",
            "avatarIcon": "CA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 10",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "Miracles Hospital",
            "Contact": "",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "Oxygen": " 10",
                "ICU": " 15"
            }
        },
        {
            "hospitalName": "DH - BHOPAL J. P. Hospital",
            "Contact": "",
            "avatarIcon": "D-",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "Oxygen": " 80",
                "ICU": " 16"
            }
        },
        {
            "hospitalName": "Kasturba Hospital",
            "Contact": "",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 20",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "Arera Trauma & Critical Care Hospital",
            "Contact": "",
            "avatarIcon": "AT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 11",
                "ICU": " 8"
            }
        },
        {
            "hospitalName": "Bansal Hospital, Bhopal - DCH",
            "Contact": "",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 24",
                "ICU": " 35"
            }
        },
        {
            "hospitalName": "A K Hospital",
            "Contact": "",
            "avatarIcon": "AK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 3",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "Chirayu Medical College",
            "Contact": "9993366621",
            "avatarIcon": "CM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 17",
                "Oxygen": " 95",
                "ICU": " 167"
            }
        },
        {
            "hospitalName": "GMC Bhopal - DCH",
            "Contact": "",
            "avatarIcon": "GB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 120",
                "Oxygen": " 236",
                "ICU": " 316"
            }
        },
        {
            "hospitalName": "All India Institute of Medical Sciences",
            "Contact": "",
            "avatarIcon": "AI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 230",
                "ICU": " 113"
            }
        }
    ],
    "INDORE": [
        {
            "hospitalName": "INDEX HOSPITAL",
            "Contact": "",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 699",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "METRO HOSPITAL",
            "Contact": "",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 8",
                "ICU": " 3"
            }
        },
        {
            "hospitalName": "Maa Ahilya Covid Care Centre",
            "Contact": "",
            "avatarIcon": "MA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 288",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "ESIC HOSPITAL",
            "Contact": "",
            "avatarIcon": "EH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 30",
                "ICU": " 6"
            }
        },
        {
            "hospitalName": "CIVIL HOSPITAL MHOW",
            "Contact": "",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 11",
                "Oxygen": " 14",
                "ICU": " 21"
            }
        },
        {
            "hospitalName": "NEW MINESH HOSPITAL RAU",
            "Contact": "",
            "avatarIcon": "NM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 5",
                "ICU": " 3"
            }
        },
        {
            "hospitalName": "ANAND HOSPITAL",
            "Contact": "",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 28",
                "ICU": " 34"
            }
        },
        {
            "hospitalName": "CURE WELL HOSPITAL",
            "Contact": "",
            "avatarIcon": "CW",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "DNS HOSPITAL",
            "Contact": "",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " 4"
            }
        },
        {
            "hospitalName": "LAKSHMI MEMORIAL HOSPITAL",
            "Contact": "",
            "avatarIcon": "LM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 5",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "BHANDARI HOSPITAL",
            "Contact": "",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " 7"
            }
        },
        {
            "hospitalName": "SUPERSPECIALITY HOSPITAL ( GMC)",
            "Contact": "",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 74",
                "ICU": " 303"
            }
        },
        {
            "hospitalName": "VERMA UNION HOSPITAL",
            "Contact": "",
            "avatarIcon": "VU",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 14"
            }
        },
        {
            "hospitalName": "SNG HOSPITAL",
            "Contact": "",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 1",
                "ICU": " 6"
            }
        },
        {
            "hospitalName": "SMS ENERGY HOSPITAL",
            "Contact": "",
            "avatarIcon": "SE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "SHALBY HOSPITAL",
            "Contact": "",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 31",
                "ICU": " 4"
            }
        },
        {
            "hospitalName": "St. FRANCIS HOSPITAL",
            "Contact": "",
            "avatarIcon": "SF",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 7",
                "ICU": " 7"
            }
        },
        {
            "hospitalName": "MEWADA MEDICARE AND EYECARE HOSPITAL MHOW",
            "Contact": "",
            "avatarIcon": "MM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " 3"
            }
        },
        {
            "hospitalName": "MEDANTA HOSPITAL",
            "Contact": "",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " 6"
            }
        },
        {
            "hospitalName": "GREATER KAILASH HOSPITAL",
            "Contact": "",
            "avatarIcon": "GK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "CHOITHRAM HOSPITAL",
            "Contact": "",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 24",
                "ICU": " 11"
            }
        },
        {
            "hospitalName": "CHL HOSPITAL",
            "Contact": "",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 3",
                "ICU": " 4"
            }
        },
        {
            "hospitalName": "Apollo Hospital",
            "Contact": "",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 6"
            }
        },
        {
            "hospitalName": "MRTB (DCH) (GMC )",
            "Contact": "",
            "avatarIcon": "M(",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 52",
                "ICU": " 46"
            }
        },
        {
            "hospitalName": "Aurobindo Medical College (PVT)",
            "Contact": "",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 245",
                "Oxygen": " 624",
                "ICU": " 331"
            }
        },
        {
            "hospitalName": "MTH (DCH) (GMC)",
            "Contact": "",
            "avatarIcon": "M(",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "Oxygen": " 38",
                "ICU": " 322"
            }
        },
        {
            "hospitalName": "Index Medical College and Hospital, Indore - DCH- (Contract)",
            "Contact": "",
            "avatarIcon": "IM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 149",
                "ICU": " 125"
            }
        },
        {
            "hospitalName": "Suyash Hospital, Indore - DCHC",
            "Contact": "",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 4"
            }
        },
        {
            "hospitalName": "Gokuldas Hospital, Indore - DCHC",
            "Contact": "",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "Aurobindo Medical College and Hospital (CONTRACT)",
            "Contact": "",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 180",
                "ICU": " 20"
            }
        },
        {
            "hospitalName": "Bombay Hospital",
            "Contact": "",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 25",
                "ICU": " 8"
            }
        }
    ],
    "GWALIOR": [
        {
            "hospitalName": "SHYAM VATIKA COVID CARE CENTRE",
            "Contact": "",
            "avatarIcon": "SV",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "SARAF HOSPITAL GWALIOR",
            "Contact": "",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 15"
            }
        },
        {
            "hospitalName": "NAVJEEVAN HOSPITAL GWALIOR",
            "Contact": "",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 25",
                "Oxygen": " 7",
                "ICU": " 19"
            }
        },
        {
            "hospitalName": "BIMR Hospital Gwalior",
            "Contact": "",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 57",
                "ICU": " 18"
            }
        },
        {
            "hospitalName": "Paridhi Hospital Gwalior",
            "Contact": "",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " 7"
            }
        },
        {
            "hospitalName": "GLOBAL HOSPITAL",
            "Contact": "",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "SSIMS Multispecility Hospital",
            "Contact": "",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 35",
                "Oxygen": " 15",
                "ICU": " 20"
            }
        },
        {
            "hospitalName": "Kalyan Multispecility Hospital padav",
            "Contact": "",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "Oxygen": " 36",
                "ICU": " 14"
            }
        },
        {
            "hospitalName": "Kalyan memorial and kdj HOSPITAL MORAR",
            "Contact": "",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 25",
                "Oxygen": " 35",
                "ICU": " 15"
            }
        },
        {
            "hospitalName": "CIVIL HAZIRA GWALIOR",
            "Contact": "",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "Oxygen": " 30",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "GMC-Gwalior - DCHJAH Super Specialty Hospital,",
            "Contact": "",
            "avatarIcon": "G-",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 48"
            }
        },
        {
            "hospitalName": "Appolo Hospital",
            "Contact": "",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 50"
            }
        },
        {
            "hospitalName": "Link Hospital",
            "Contact": "",
            "avatarIcon": "LH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 28",
                "ICU": " 42"
            }
        },
        {
            "hospitalName": "DH Gwalior - DCHC",
            "Contact": "",
            "avatarIcon": "DG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 42",
                "Oxygen": " 108",
                "ICU": " 0"
            }
        }
    ],
    "VIZAG": [
        {
            "hospitalName": "CHC palasa",
            "Contact": "08945241063",
            "avatarIcon": "CP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 12",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "CHC PATHAPATNAM",
            "Contact": "08946255020",
            "avatarIcon": "CP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 1",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "CHC NARASANNAPETA",
            "Contact": "08942-276208",
            "avatarIcon": "CN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "CHC Ichapuram",
            "Contact": "08947231094",
            "avatarIcon": "CI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "Unique Hospital",
            "Contact": "7569576871",
            "avatarIcon": "UH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 3"
            }
        },
        {
            "hospitalName": "GMR VARALAKSHMI CARE HOSPITAL",
            "Contact": "9959325181",
            "avatarIcon": "GV",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "SURYA MUKHI HOSPITAL",
            "Contact": "7382314761",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "MEDICOVER HOSPITALS",
            "Contact": "08942255299",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 14",
                "ICU": " 2"
            }
        },
        {
            "hospitalName": "AREA HOSPITAL RAJAM",
            "Contact": "8941253059",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "TRUST HOSPITAL",
            "Contact": "7207262028",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "kamala hospital",
            "Contact": "8919366009",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "GEMS HOSPITAL",
            "Contact": "8331029565",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 12",
                "ICU": " 17"
            }
        },
        {
            "hospitalName": "RIMS HOSPITAL,SRIKAKULAM",
            "Contact": "08942279214",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 100",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "Amrutha Hospital",
            "Contact": "9290805566",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "AREA HOSPITAL PALAKONDA",
            "Contact": "7207358087",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "DH Tekkali",
            "Contact": "9490570761",
            "avatarIcon": "DT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 39",
                "ICU": " 7"
            }
        },
        {
            "hospitalName": "KIMS Hospital",
            "Contact": "08942271118",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 9"
            }
        },
        {
            "hospitalName": "Life Hospital",
            "Contact": "08942226488",
            "avatarIcon": "LH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "PVS Rammohan Hospital",
            "Contact": "8019986680",
            "avatarIcon": "PR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 0",
                "ICU": " 1"
            }
        },
        {
            "hospitalName": "Dr.Golivi Hos[pitals",
            "Contact": "8333832989",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        }
    ],
    "LUDHIANA": [
        {
            "hospitalName": "Vardhman Mahaveer Health Care",
            "Contact": "9464650550",
            "avatarIcon": "VM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 2",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "SDH Khanna",
            "Contact": "-",
            "avatarIcon": "SK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 100",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "SDH JAGRAON",
            "Contact": "-",
            "avatarIcon": "SJ",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 50",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Military Hospital Patiala",
            "Contact": "9815679300",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 187",
                "Oxygen": " 50",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Mata Kaushalya Hospital",
            "Contact": "9877259302",
            "avatarIcon": "MK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 28",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Kular College of Nursing Gobindgarh",
            "Contact": "08283000163",
            "avatarIcon": "KC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 91",
                "Oxygen": " 43",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Gyan Sagar",
            "Contact": "9872901052",
            "avatarIcon": "GS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 146",
                "Oxygen": " 58",
                "ICU": " ",
                "10": "0",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": "Guru Nanak Dev Hospital",
            "Contact": "01832421050",
            "avatarIcon": "GN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 34",
                "ICU": " ",
                "20": "0",
                "Ventilator": " 61"
            }
        },
        {
            "hospitalName": "GMC Patiala",
            "Contact": "6239432083",
            "avatarIcon": "GP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " -1",
                "Oxygen": " 336",
                "ICU": " ",
                "28": "0",
                "Ventilator": " 53"
            }
        },
        {
            "hospitalName": "GHG NURSING COLLEGE Raikot",
            "Contact": "8728078970",
            "avatarIcon": "GN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 150",
                "Oxygen": " 0",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Civil Hospital Samana",
            "Contact": "9814104535",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 50",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Civil Hospital Rajpura",
            "Contact": "8284085757",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 50",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Civil Hospital Nabha",
            "Contact": "9814190308",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 50",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Civil Hospital",
            "Contact": "01812230933",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 34",
                "Oxygen": " 164",
                "ICU": " ",
                "18": "0",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": "CHC Koomkalan",
            "Contact": "9855800066",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 16",
                "Oxygen": " 31",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "CCC Patiala",
            "Contact": "8408902160",
            "avatarIcon": "CP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 438",
                "Oxygen": " 0",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "9 Air Force Hospital Halwara",
            "Contact": "8527040675",
            "avatarIcon": "9A",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " ",
                "1": "0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "World Diabetics Center",
            "Contact": "9814175300",
            "avatarIcon": "WD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Virdi Memorial Hospital",
            "Contact": "9888236529",
            "avatarIcon": "VM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "VERMA MULTISPECIALITY HOSPITAL & CT SCAN CENTER",
            "Contact": "9781447155",
            "avatarIcon": "VM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " -13",
                "ICU": " ",
                "14": "0",
                "Ventilator": " -5"
            }
        },
        {
            "hospitalName": "Vasal Hospital",
            "Contact": "9814087390",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " ",
                "2": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "USPC Jain Charitable Jain Hospital",
            "Contact": "8360412973",
            "avatarIcon": "UJ",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " ",
                "3": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "TOOR HOSPITAL",
            "Contact": "9814019115",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " ",
                "2": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Suraksha Hospital",
            "Contact": "9216999888",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Suman Hospital",
            "Contact": "01612409133",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 12",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sukhveen Hospital",
            "Contact": "8146791166",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sukhveen Hospital",
            "Contact": "8146791166",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "SOHANA HOSPITAL",
            "Contact": "9814095240",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 50",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "SOBTI NEURO AND SUPER SPECIALITY HOSPITAL",
            "Contact": "9814057900",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " ",
                "6": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "SNS. PAHAWA HOSPITAL",
            "Contact": "9815501515",
            "avatarIcon": "SP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " ",
                "2": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "SIRISH Hospital",
            "Contact": "01612408303",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Simrita Hospital",
            "Contact": "9814040394",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " ",
                "4": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sidhu Hospital",
            "Contact": "9876177704",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 35",
                "ICU": " ",
                "8": "0",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": "Shriman Hospital",
            "Contact": "9814513339",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " ",
                "6": "0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Shri Rama Charitable Daressi",
            "Contact": "8054900235",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Shri Ragunath Hospital",
            "Contact": "9855123401",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 43",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Shri Guru Ram Das Charitable Hospital",
            "Contact": "98146668927",
            "avatarIcon": "SG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 42",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": "Shri Guru Nanak Hospital Rara Sahib",
            "Contact": "8437590555",
            "avatarIcon": "SG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " ",
                "7": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sh.Krishna Charitable Hospital",
            "Contact": "9815200273",
            "avatarIcon": "SC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " ",
                "2": "0",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": "SGL Hospital",
            "Contact": "8725045872",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "SAS Grewal Multispeciality Hospital",
            "Contact": "9876098393",
            "avatarIcon": "SG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 10",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sarvodaya Hospital",
            "Contact": "9814065410",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " ",
                "4": "0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Sanjivani Hospital",
            "Contact": "9815455545",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 7",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sahara Hospital",
            "Contact": "9855407433",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sacred heart Hospital",
            "Contact": "9205651430",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 1",
                "ICU": " ",
                "-3": "0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "S. Satnam Singh Memorial Innocent Hearts Multispeciality Hospital",
            "Contact": "9216194614",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " -4",
                "ICU": " ",
                "1": "0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Rattan Hospital",
            "Contact": "8568806196",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Rajwant Hospital",
            "Contact": "9878432457",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Raj Hospital",
            "Contact": "9814604554",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "R.G Stone Hospital",
            "Contact": "9876910000",
            "avatarIcon": "RS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 12",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Prolife Hospital",
            "Contact": "01615060999",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "PREET NURSING HOME Hambran",
            "Contact": "9815072152",
            "avatarIcon": "PN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 3",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Preet Hospital, Model town",
            "Contact": "9872422554",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 2",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "PIMS Hospital",
            "Contact": "9878406884",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 17",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Phull Neuro And Multispeciality Hospital",
            "Contact": "7837612281",
            "avatarIcon": "PN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 2",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Patel Hospital",
            "Contact": "7888491687",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " -31",
                "ICU": " ",
                "-4": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Pancham Hospital",
            "Contact": "8725008725",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " ",
                "9": "0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Orison Hospital",
            "Contact": "9872222318",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 19",
                "ICU": " ",
                "5": "0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Ohri Hospital",
            "Contact": "9855572657",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " ",
                "5": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Noble Mission Hospital",
            "Contact": "9779011479",
            "avatarIcon": "NM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " ",
                "9": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Nobel Hospital",
            "Contact": "9814062506",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "NHS Hospital",
            "Contact": "9779148800",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 11",
                "ICU": " ",
                "10": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "New Neelam Hospital",
            "Contact": "9780031001",
            "avatarIcon": "NN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Narain Hospital",
            "Contact": "9815033919",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 0",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Mehar Hospital",
            "Contact": "9646875429",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 3",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Mediway",
            "Contact": "0000000000",
            "avatarIcon": "M",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 3",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Mayo super specialty Hospital",
            "Contact": "9779977020",
            "avatarIcon": "MS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " -2",
                "Oxygen": " 2",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Max Super Speciality Hospital",
            "Contact": "9878668107",
            "avatarIcon": "MS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " ",
                "-36": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "MAX City Hospital",
            "Contact": "7837373978",
            "avatarIcon": "MC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 3",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Mann Medicity Hospital",
            "Contact": "9815157000",
            "avatarIcon": "MM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 30",
                "ICU": " ",
                "30": "0",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": "Makkar Hospital",
            "Contact": "9915270451",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Mahal Multi-Specialist Hospital",
            "Contact": "9815500973",
            "avatarIcon": "MM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Ludhiana Mediways Hospital",
            "Contact": "8725800502",
            "avatarIcon": "LM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 31",
                "ICU": " ",
                "10": "0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Lord Mahavira Homeopathy College",
            "Contact": "9814030755",
            "avatarIcon": "LM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "LIFELINE HOSPITAL",
            "Contact": "9988639620",
            "avatarIcon": "LH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 17",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Life Line Hospital opposite DMC (Nursing home)",
            "Contact": "9888830792",
            "avatarIcon": "LL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Kulwant heart Center",
            "Contact": "9814030755",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " ",
                "4": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Kidney hospital",
            "Contact": "9914034610",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "KHANNA NURSING HOME",
            "Contact": "9779731403",
            "avatarIcon": "KN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 27",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Karanvir Hospital",
            "Contact": "9888218444",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Kamal Hospital",
            "Contact": "9814160348",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 3",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Kalyan Hospital",
            "Contact": "9646348877",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "KALRA HOSPITAL",
            "Contact": "9814084115",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Joshi Hospital",
            "Contact": "9815954955",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " ",
                "2": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Johal Hospital",
            "Contact": "9815086865",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Jeevanjot Hospital Khanna",
            "Contact": "9814026683",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 7",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Jeevan Hospital",
            "Contact": "7009493973",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " ",
                "10": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Jain Hospital",
            "Contact": "9316919587",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 2",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "IVY HOSPITAL KHANNA",
            "Contact": "9888805394",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 7",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "IVY Hospital",
            "Contact": "9465587840",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " -17",
                "ICU": " ",
                "-13": "0",
                "Ventilator": " -5"
            }
        },
        {
            "hospitalName": "Iqbal Nursing Home",
            "Contact": "9814033228",
            "avatarIcon": "IN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Infectious Diseases center, Gulab Devi Hospital",
            "Contact": "9814064704",
            "avatarIcon": "ID",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 17",
                "ICU": " ",
                "6": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Indus International Hospital",
            "Contact": "8427446676",
            "avatarIcon": "II",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " -4",
                "Oxygen": " 1",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "IMA/LMCH Covid Center",
            "Contact": "9876293608",
            "avatarIcon": "IC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 23",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "IMA Shahkot",
            "Contact": "9876356229",
            "avatarIcon": "IS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 25",
                "ICU": " ",
                "6": "0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Hunjan Hospital",
            "Contact": "9814020234",
            "avatarIcon": "HH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "HMC Hospital Ludhiana",
            "Contact": "9814429697",
            "avatarIcon": "HH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Hi Care Basant Avenue Hospital",
            "Contact": "9814703108",
            "avatarIcon": "HC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Health Max Multi-speciality Hospital",
            "Contact": "9780521250",
            "avatarIcon": "HM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 10",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Guru Nanak Charitable Hospital Dehlon",
            "Contact": "7888938878",
            "avatarIcon": "GN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "GURU NANAK CHARITABLE HOSPITAL",
            "Contact": "9915098893",
            "avatarIcon": "GN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 11",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Gurdev Hospital",
            "Contact": "9368543012",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 18",
                "ICU": " ",
                "9": "0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Grewal Hospital",
            "Contact": "9815624907",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Grecian Super Speciality Hospital",
            "Contact": "9645924280",
            "avatarIcon": "GS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " ",
                "38": "0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Goodwill Hospital",
            "Contact": "9815694070",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 2",
                "ICU": " ",
                "2": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Goel Hospital",
            "Contact": "9779148800",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Global Heart Hospital",
            "Contact": "9814030467",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Galaxy Hospital",
            "Contact": "9815400185",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 2",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "GAGANDEEP HOSPITAL",
            "Contact": "9501097971",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Fortis hospital",
            "Contact": "9779388886",
            "avatarIcon": "FH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " -19",
                "ICU": " ",
                "-4": "0",
                "Ventilator": " -6"
            }
        },
        {
            "hospitalName": "Fortis Escorts Hospital Amritsar",
            "Contact": "8559057878",
            "avatarIcon": "FE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " ",
                "34": "0",
                "Ventilator": " 24"
            }
        },
        {
            "hospitalName": "Faqir Chand Rooprai Memorial Nursing Home",
            "Contact": "9814298198",
            "avatarIcon": "FC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 2",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Dr. Brat Paul Mehta Memorial Hospital",
            "Contact": "9417055369",
            "avatarIcon": "DB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " ",
                "4": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Dr Anand Medicity",
            "Contact": "9465300055",
            "avatarIcon": "DA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Doaba Hospital",
            "Contact": "9814131065",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " ",
                "6": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Deepak Hospital",
            "Contact": "01614671100",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Deep Hospital",
            "Contact": "7707994405",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " -2",
                "ICU": " ",
                "8": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Columbia Asia Hospital",
            "Contact": "9888100060",
            "avatarIcon": "CA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Chawla Nursing Home",
            "Contact": "9814034501",
            "avatarIcon": "CN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Chand Nursing Home Rahon Road",
            "Contact": "9814034507",
            "avatarIcon": "CN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Cardinova Hospital",
            "Contact": "9814356422",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Capitol Hospital",
            "Contact": "8146288888",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " -9",
                "ICU": " ",
                "1": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Bhagwan Ram Charitable Hospital",
            "Contact": "9815855550",
            "avatarIcon": "BR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 27",
                "ICU": " ",
                "5": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Baba Deep Singh Hospital",
            "Contact": "9878580274",
            "avatarIcon": "BD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "AYKAI HOSPITAL",
            "Contact": "9501066633",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Arora Neuro Center",
            "Contact": "9872888836",
            "avatarIcon": "AN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 30",
                "ICU": " ",
                "4": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Armaan Hospital",
            "Contact": "7888737285",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " -1",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Amcare Hospital",
            "Contact": "9915773141",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " -16",
                "Oxygen": " 2",
                "ICU": " ",
                "13": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Amandeep Hospital",
            "Contact": "7717273222",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " ",
                "4": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Akal Hospital",
            "Contact": "9270200013",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 8",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "AIMC Bassi Hospital",
            "Contact": "9501555558",
            "avatarIcon": "AB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 25",
                "ICU": " ",
                "4": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "ACE Heart & Vascular Institute",
            "Contact": "9899599111",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " -5",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Aastha Kidney Hospital",
            "Contact": "9872777573",
            "avatarIcon": "AK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 38",
                "ICU": " ",
                "13": "0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "CCC PAU HOSTEL 1 to 4",
            "Contact": "0000000000",
            "avatarIcon": "CP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 200",
                "Oxygen": " 50",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "CCC PAU HOSTEL 11",
            "Contact": "09465759745",
            "avatarIcon": "CP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 400",
                "Oxygen": " 0",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "MERITORIUS SCHOOL",
            "Contact": "0000000000",
            "avatarIcon": "MS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 500",
                "Oxygen": " 200",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "MCH Vardhman",
            "Contact": "9417300873",
            "avatarIcon": "MV",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 98",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Civil Hospital Ludhiana",
            "Contact": "8427269747",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 1",
                "ICU": " ",
                "16": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "GTB Hospital",
            "Contact": "9876633689",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 42",
                "ICU": " ",
                "10": "0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "SPS Hospital",
            "Contact": "9888366600",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 70",
                "ICU": " ",
                "11": "0",
                "Ventilator": " 14"
            }
        },
        {
            "hospitalName": "Mohan Dai Oswal Hospital",
            "Contact": "9115926600",
            "avatarIcon": "MD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " ",
                "-6": "0",
                "Ventilator": " -1"
            }
        },
        {
            "hospitalName": "Fortis Hospital",
            "Contact": "9876234631",
            "avatarIcon": "FH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " ",
                "0": "0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "CMC Hospital",
            "Contact": "8847390920",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 78",
                "ICU": " ",
                "12": "0",
                "Ventilator": " 31"
            }
        },
        {
            "hospitalName": "DMC&H",
            "Contact": "9872670761",
            "avatarIcon": "D",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 47",
                "ICU": " ",
                "21": "0",
                "Ventilator": " -1"
            }
        }
    ],
    "GANDHINAGAR": [
        {
            "hospitalName": "Yash Hospital Mansa",
            "Contact": "Dr Tushar Jani",
            "avatarIcon": "YH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Viva Health Care Adalaj",
            "Contact": "Dr Hemant",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Vachhani Hospital",
            "Contact": "VIJAY RAVAL",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "United Eduplus Hospital Uvarsad",
            "Contact": "Dr Urvish",
            "avatarIcon": "UE",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Swarnee Hospital Dehgam",
            "Contact": "Dr Ronak Patel",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Surmount Hospital",
            "Contact": "Dr Hardik Patel",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 6"
            }
        },
        {
            "hospitalName": "Staff Training College sec 17",
            "Contact": "Dr. Hema Joshi",
            "avatarIcon": "ST",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Siddhi Vinayak Multispeciality Hospital Dehgam",
            "Contact": "Dr Lalit Solanki",
            "avatarIcon": "SV",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Shukan Hospital Dehgam",
            "Contact": "Dr Arvind Patel",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Shradhha Hospital, Kalol",
            "Contact": "Mr. Nakul Prajapati",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Shiven Children Hospital VTC Kudasan",
            "Contact": "Dr Chirag Banker",
            "avatarIcon": "SC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 2"
            }
        },
        {
            "hospitalName": "Shashwat Hospital Kudasan",
            "Contact": "Dr Kandarp",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Samarpan Hospital Dehgam",
            "Contact": "DR.KAVIT KOTHARI",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "SDH Mansa",
            "Contact": "Dr Kanan Shukla",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "S.M.V.S Hospital, Raysan (DCH)",
            "Contact": "Dr. Parth Pujara",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 13"
            }
        },
        {
            "hospitalName": "S.J.Patel hospital",
            "Contact": "Dr.Mahesh Thakor",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Ruchi Hospital, Mansa (DCH)",
            "Contact": "Mr. Tanmay Patel",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Royal Gujarat General Hospital",
            "Contact": "Dr Jay Patel",
            "avatarIcon": "RG",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Rainbow Children Hospital Kudasan",
            "Contact": "Ilesh sharma",
            "avatarIcon": "RC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Radhe Hospital,Radhe Signature Kudasan",
            "Contact": "Dr Prakash Patel",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Radhe Hospital VTC Kudasan",
            "Contact": "Mr. Harshal Patel",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "RMRC Rajesh Hospital Kalol",
            "Contact": "Raghavdan Gadhavi",
            "avatarIcon": "RR",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "REVABAI GENERAL HOSPITAL SARDHAV",
            "Contact": "DR.HIMANSHU K TRIVEDI",
            "avatarIcon": "RG",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Pulse medicure hospital and Rehab centre",
            "Contact": "Dr dipen damor",
            "avatarIcon": "PM",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Pratham Children Hospital Kudasan",
            "Contact": "Dr Hiren Prajapati",
            "avatarIcon": "PC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Pagarav Hospital and ICU Sec 23",
            "Contact": "Dr Vaibhav Patel",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "New Life Hospital Bahiyal Dehgam",
            "Contact": "Dr Anshu Patel",
            "avatarIcon": "NL",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "NMIAS, Kolavada (CCC)",
            "Contact": "Dr. Kundan",
            "avatarIcon": "NK",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "N. N. Sarvajanik Hospital",
            "Contact": "Tarunbhai Patel",
            "avatarIcon": "NN",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Mishika multispeciality hospital",
            "Contact": "Jaimin patel",
            "avatarIcon": "MM",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Manjushree research Institute of Ayurvedic Science",
            "Contact": "Dr Haresh soni",
            "avatarIcon": "MR",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "M B Patel Sarvajanik Hospital",
            "Contact": "Dr. Pokar Arpit P.",
            "avatarIcon": "MB",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Lilavati Hospital & ICU Mansa",
            "Contact": "Dr Sonal Patel",
            "avatarIcon": "LH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "L J B Gohil Homoeopathic hospital",
            "Contact": "Hitesh Dobariya",
            "avatarIcon": "LJ",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Krishna Surgical Hospital Dehgam",
            "Contact": "Dr Kishorsinh Rathod",
            "avatarIcon": "KS",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Krishna Hospital Mota Chiloda",
            "Contact": "Dr M D Zala",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Khushi Hospital Mota chiloda",
            "Contact": "Dr Tejas Amin",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Kanoriya Hospital and Research Center",
            "Contact": "Dr. Bhagirath",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Icon Hospital",
            "Contact": "Dr Hemang Shah",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "HiTech Hospital Sec 3",
            "Contact": "Dr Mahipatsinh Parmar",
            "avatarIcon": "HH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Goenka Hospital (DCH)",
            "Contact": "Dr. Hina Baria",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Gmers (Annex) Kolavada",
            "Contact": "Dr Umesh Vaishnav",
            "avatarIcon": "G(",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "GMERS General Hospital, Gandhinagar (DCH)",
            "Contact": "RMO Covid",
            "avatarIcon": "GG",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 18"
            }
        },
        {
            "hospitalName": "Divine Medical & Surgical Hospital, Randheja",
            "Contact": "Dr Smitesh Patel",
            "avatarIcon": "DM",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "DCH PSM Hospital",
            "Contact": "Mr. Vikram Patel",
            "avatarIcon": "DP",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "DCH Adarsh Hospital",
            "Contact": "K V Parmar",
            "avatarIcon": "DA",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Capital Covid Care Centre Sector 22",
            "Contact": "Dr Hippal Patel",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "CHC Sadra",
            "Contact": "Dr. Sunil B. Patel",
            "avatarIcon": "CS",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "CHC Kalol",
            "Contact": "Dr Jagdish Tank",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "CHC Dingucha,Kalol",
            "Contact": "Dr Amisha Aacharya",
            "avatarIcon": "CD",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Bansari Heart & Medical Hospital",
            "Contact": "Dr Tushar Jani",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "B.M.B. Sarvajanik Hospital Veda",
            "Contact": "Dr. Dhaval V. Patel",
            "avatarIcon": "BS",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Ashirvad Hospital Dehgam",
            "Contact": "DR.KANHAI PATEL",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Ashirvad Hospital",
            "Contact": "Ravi Patel",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Apollo Hospital (DCH)",
            "Contact": "Mr. Prakash Kumar Singh",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Amba Health Centre & Hospital Adalaj",
            "Contact": "Jiten Bhai",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Aashka Hospital,Sargasan (DCH)",
            "Contact": "Dr. Parth Varde",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 2"
            }
        },
        {
            "hospitalName": "Aarna Multispeciality Hospital",
            "Contact": "Devendra sharma",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        },
        {
            "hospitalName": "Aarihant Ayurvedic & Aarihant Homoeopathic",
            "Contact": "Shashvat Trivedi",
            "avatarIcon": "AA",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 0"
            }
        }
    ],
    "VADODARA": [
        {
            "hospitalName": "Synergy Physiotherapy Hospital & Rehabilitation Care",
            "Contact": "8141318072",
            "avatarIcon": "SP",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 25"
            }
        },
        {
            "hospitalName": "Swastik Hospital & Poly clinic",
            "Contact": "9909948490",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 7"
            }
        },
        {
            "hospitalName": "Sumeru Navkar Tirth",
            "Contact": "7069100591",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 200"
            }
        },
        {
            "hospitalName": "Soham Hospital",
            "Contact": "8490960197",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 17"
            }
        },
        {
            "hospitalName": "Shri Vraj Hospital & ICU",
            "Contact": "9426977299",
            "avatarIcon": "SV",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 20"
            }
        },
        {
            "hospitalName": "Shreeji Hospital",
            "Contact": "9687089182",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 60"
            }
        },
        {
            "hospitalName": "Shree Vraj Hospital",
            "Contact": "9712003763",
            "avatarIcon": "SV",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 12"
            }
        },
        {
            "hospitalName": "Shree Vallabh Hospital",
            "Contact": "9825081030",
            "avatarIcon": "SV",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 17"
            }
        },
        {
            "hospitalName": "Shre Radhe Multi Speciality Hospital & ICU",
            "Contact": "9601398771",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 14"
            }
        },
        {
            "hospitalName": "Shaishav Hospital",
            "Contact": "7567013443",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 10"
            }
        },
        {
            "hospitalName": "Satyam Hospital (Managed by Shree Aaditya Cheritable Trust)",
            "Contact": "8980111778",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 15"
            }
        },
        {
            "hospitalName": "Satya Hospital",
            "Contact": "8980941031",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 5"
            }
        },
        {
            "hospitalName": "Sanjivani Hospital",
            "Contact": "9664767224",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 25"
            }
        },
        {
            "hospitalName": "Sai Hospital",
            "Contact": "9879536980",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 10"
            }
        },
        {
            "hospitalName": "Por General Hospital",
            "Contact": "8320517486",
            "avatarIcon": "PG",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 10"
            }
        },
        {
            "hospitalName": "Om Multispecialty Hospital",
            "Contact": "9925006535",
            "avatarIcon": "OM",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 11"
            }
        },
        {
            "hospitalName": "Modi Nursing Home",
            "Contact": "9925181285",
            "avatarIcon": "MN",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 15"
            }
        },
        {
            "hospitalName": "Manjusar Hospital",
            "Contact": "7228008555",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 12"
            }
        },
        {
            "hospitalName": "Manjalpur Hospital Pvt. Ltd",
            "Contact": "9099482846",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 11"
            }
        },
        {
            "hospitalName": "Life Line Hospital and ICCU",
            "Contact": "9904702025",
            "avatarIcon": "LL",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 6"
            }
        },
        {
            "hospitalName": "Life Care hospital",
            "Contact": "9687049757",
            "avatarIcon": "LC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 7"
            }
        },
        {
            "hospitalName": "Lal Bag Covid Care Centre",
            "Contact": " ",
            "avatarIcon": "LB",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 100"
            }
        },
        {
            "hospitalName": "Krishna Surgical Hospital & Truma Care Center",
            "Contact": "8007764058",
            "avatarIcon": "KS",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 13"
            }
        },
        {
            "hospitalName": "Krishna Hospital",
            "Contact": "9925148024",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 33"
            }
        },
        {
            "hospitalName": "Kashiba Ayurvedic Hospital",
            "Contact": "7573041994",
            "avatarIcon": "KA",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 50"
            }
        },
        {
            "hospitalName": "Jaynil Hospital",
            "Contact": "8200875645",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 6"
            }
        },
        {
            "hospitalName": "Hotel Adity(Unit Of Sanjivani Hospital-Savli)",
            "Contact": "9979481133",
            "avatarIcon": "HA",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 25"
            }
        },
        {
            "hospitalName": "Healthcare Hospital, Segva",
            "Contact": "9429536831",
            "avatarIcon": "HH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 40"
            }
        },
        {
            "hospitalName": "Gujarat Surgical Hospital",
            "Contact": "7567204809",
            "avatarIcon": "GS",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 12"
            }
        },
        {
            "hospitalName": "Gayatri Nursing Home",
            "Contact": "8320517486",
            "avatarIcon": "GN",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 14"
            }
        },
        {
            "hospitalName": "Faith Hospital",
            "Contact": "9924462400",
            "avatarIcon": "FH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 16"
            }
        },
        {
            "hospitalName": "Ellorapark Hospitals PVT. LTD",
            "Contact": "9974088093",
            "avatarIcon": "EH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 15"
            }
        },
        {
            "hospitalName": "Eeshita Hospital",
            "Contact": "9925033091",
            "avatarIcon": "EH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 12"
            }
        },
        {
            "hospitalName": "Divisional Railway Hospital",
            "Contact": "9724091510",
            "avatarIcon": "DR",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 10"
            }
        },
        {
            "hospitalName": "Dabhoi Covid19 Care Centre",
            "Contact": "9081621623",
            "avatarIcon": "DC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 15"
            }
        },
        {
            "hospitalName": "Covid Care Centre PHC- Kanzat",
            "Contact": "9711129405",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 15"
            }
        },
        {
            "hospitalName": "Covid Care Center PHC Varnama",
            "Contact": "7069056388",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 20"
            }
        },
        {
            "hospitalName": "Covid Care Center - Vega, Dabhoi",
            "Contact": "9898684184",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 100"
            }
        },
        {
            "hospitalName": "Covid Care Center - Vadia",
            "Contact": "7069056388",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 30"
            }
        },
        {
            "hospitalName": "Community Health Centre Padra",
            "Contact": "7990162366",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 20"
            }
        },
        {
            "hospitalName": "Citizen Hospital & Maternity Home",
            "Contact": "9427070413",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 15"
            }
        },
        {
            "hospitalName": "Bhumi Hospital",
            "Contact": "9909951187",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 30"
            }
        },
        {
            "hospitalName": "Baroda Life Line Hospital",
            "Contact": "9978500880",
            "avatarIcon": "BL",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 20"
            }
        },
        {
            "hospitalName": "Aziz Hospital",
            "Contact": "9428507862",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 8"
            }
        },
        {
            "hospitalName": "Ashray Nursing Home",
            "Contact": "8849148289",
            "avatarIcon": "AN",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 15"
            }
        },
        {
            "hospitalName": "Ashirvad Hospital (Ext. Spandan Multi Hospital)",
            "Contact": "9825227437",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 16"
            }
        },
        {
            "hospitalName": "Anugrah Hospital, Manjalpur",
            "Contact": "7786086077",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 20"
            }
        },
        {
            "hospitalName": "Ami Hospital - Waghodiya",
            "Contact": "9724507634",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 18"
            }
        },
        {
            "hospitalName": "Akshar Hospital (Extension of Shreeji Hospital, Maneja)",
            "Contact": "9099922964",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 15"
            }
        },
        {
            "hospitalName": "Abhinav Hospital",
            "Contact": "9426416361",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 15"
            }
        },
        {
            "hospitalName": "Aaradhya Hospital",
            "Contact": "9727772211",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 10"
            }
        }
    ],
    "NAGPUR": [
        {
            "hospitalName": "Chimalwar Clinic",
            "Contact": "NA",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "SST. Madnani Hospital & Nursing Home",
            "Contact": "NA",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Akruti Hospital",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 1",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Nalin Hospital",
            "Contact": "NA",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sneh Nursing Home",
            "Contact": "NA",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Deshmane Hospital",
            "Contact": "NA",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Orthonova Hospital & Critical Care Unit",
            "Contact": "NA",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 3",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Nandita Hospital & Research Centre",
            "Contact": "NA",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Agrawal Hospital",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sigma Hospital (Run by Jeswani Multispecialty Hospital)",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Ortho Avenue Hospital",
            "Contact": "NA",
            "avatarIcon": "OA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 2",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "New Life Hospital (Unit of ChhajedHealthcare Pvt. Ltd.)",
            "Contact": "NA",
            "avatarIcon": "NL",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 21",
                "ICU": " 7",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Midas Multispeciality Hospital Pvt. Ltd.",
            "Contact": "NA",
            "avatarIcon": "MM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 4",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sud Surgical & Maternity Hospital",
            "Contact": "NA",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 1",
                "ICU": " 9",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "RNH Hospital Pvt. Ltd.",
            "Contact": "NA",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 13",
                "ICU": " 1",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Platina Heart Hospital",
            "Contact": "NA",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 4",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Carewell Hospital",
            "Contact": "NA",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " 3",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Asian Hospital & Critical Care",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 1",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Adishakti Hospital",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Shri Dhanwantari Hospital",
            "Contact": "NA",
            "avatarIcon": "SD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Godani Hospital",
            "Contact": "NA",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Gencure Hospital",
            "Contact": "NA",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Life Care Hospital",
            "Contact": "NA",
            "avatarIcon": "LC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 13",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": "Shanti Niketan Nursing Home",
            "Contact": "NA",
            "avatarIcon": "SN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Khalatkar Bal Rugnalaya",
            "Contact": "NA",
            "avatarIcon": "KB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "New Health City Childrens Superspeciality Hospital & Research Center",
            "Contact": "NA",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 6",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Crystal Nursing Home",
            "Contact": "NA",
            "avatarIcon": "CN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Dande Hospital Hill Road",
            "Contact": "NA",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 11",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Prestige Hospital",
            "Contact": "NA",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Rahate Nursing Home",
            "Contact": "NA",
            "avatarIcon": "RN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Safal Hospital",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 25",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Surelife Hospital & Research Institute",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Belsare Hospital",
            "Contact": "NA",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Mother and Child Care Hospital",
            "Contact": "NA",
            "avatarIcon": "MA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Kothari Hospital",
            "Contact": "NA",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "Oxygen": " 12",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Central Hospital Ramdaspeth",
            "Contact": "NA",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Lifesprings Hospital",
            "Contact": "NA",
            "avatarIcon": "LH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sanjeevani Hopital & Maternity Nursing",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Pioneer Nursing Home",
            "Contact": "NA",
            "avatarIcon": "PN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 5",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Prime Nursing Home",
            "Contact": "NA",
            "avatarIcon": "PN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 7",
                "ICU": " 8",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Shanti Mohan Hospital",
            "Contact": "NA",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " 1",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Niramay Hospital",
            "Contact": "NA",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Dr. A. Badar Surgical Nurshing Home",
            "Contact": "NA",
            "avatarIcon": "DA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 2",
                "ICU": " 6",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Auro Fracture & Accident Hospital",
            "Contact": "NA",
            "avatarIcon": "AF",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " 8",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": "Medicare Hospital",
            "Contact": "NA",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Alexis Hospital",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 12",
                "ICU": " 1",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Kingsway Hospital",
            "Contact": "NA",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 4",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Kunal Hospital",
            "Contact": "NA",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 17",
                "ICU": " 17",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Hope Hospital",
            "Contact": "NA",
            "avatarIcon": "HH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 67",
                "ICU": " 22",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Gajbe Critical Care & Multispeciality",
            "Contact": "NA",
            "avatarIcon": "GC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Rugwani Child Care Center & Hospital",
            "Contact": "NA",
            "avatarIcon": "RC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 18",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Nimbunabai Tirpude Hospital",
            "Contact": "NA",
            "avatarIcon": "NT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " 5",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Venus Hospital",
            "Contact": "NA",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 30",
                "ICU": " 9",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Samarpan Hospital",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 8",
                "ICU": " 20",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Shri Bhavani Hospital",
            "Contact": "NA",
            "avatarIcon": "SB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 76",
                "ICU": " 14",
                "Ventilator": " 14"
            }
        },
        {
            "hospitalName": "VIIMS Hospital",
            "Contact": "NA",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 20",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Aastha Hospital (Bhandara road)",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " 5",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Tarangan Hospital",
            "Contact": "NA",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 7",
                "ICU": " 9",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Kubde Hospital",
            "Contact": "NA",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Ramdeobaba Hospital",
            "Contact": "NA",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "New Era (East End) Hospital",
            "Contact": "NA",
            "avatarIcon": "NE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 33",
                "ICU": " 8",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Shri Radhakrishna Hospital",
            "Contact": "NA",
            "avatarIcon": "SR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 64",
                "ICU": " 29",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Seven Star Hospital",
            "Contact": "NA",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 8",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Radiance Hospital",
            "Contact": "NA",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 45",
                "ICU": " 20",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": "Safe Hands Hospital",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 22",
                "ICU": " 8",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Central Avenue Critical Care Hospital",
            "Contact": "NA",
            "avatarIcon": "CA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 6",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sawarkar Multispeciality Hospital",
            "Contact": "NA",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 1",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Evershine Hospital",
            "Contact": "NA",
            "avatarIcon": "EH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 1",
                "ICU": " 5",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Esha Hospital",
            "Contact": "NA",
            "avatarIcon": "EH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Nandanwar Trauma Center",
            "Contact": "NA",
            "avatarIcon": "NT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 1",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Shravan Hospital",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 7",
                "ICU": " 8",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Borkar Multispeciality Hospital",
            "Contact": "NA",
            "avatarIcon": "BM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 21",
                "ICU": " 5",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Suryodaya Hospital",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " 11",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Gracious Hospital",
            "Contact": "NA",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Wanjari Hospital",
            "Contact": "NA",
            "avatarIcon": "WH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 30",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Shree Hospital",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Cure it Hospital",
            "Contact": "NA",
            "avatarIcon": "CI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 7",
                "ICU": " 9",
                "Ventilator": " 9"
            }
        },
        {
            "hospitalName": "Raghuwanshi Hospital & Research Center",
            "Contact": "NA",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Cancer Clinic",
            "Contact": "NA",
            "avatarIcon": "CC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Shivganga Hospital",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 2",
                "ICU": " 7",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "NIMS Hospital",
            "Contact": "NA",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 2",
                "ICU": " 18",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Arneja Institute of Cardiology",
            "Contact": "NA",
            "avatarIcon": "AI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 3",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Criticare Hospital",
            "Contact": "NA",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 1",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Nelson Mother & Child Care Hospital",
            "Contact": "NA",
            "avatarIcon": "NM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 12",
                "ICU": " 15",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": "Columbia Hospital",
            "Contact": "NA",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "G. T. Padole Hospital",
            "Contact": "NA",
            "avatarIcon": "GT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 8",
                "ICU": " 3",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Shrikrishna Hrudayalaya",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 20",
                "ICU": " 11",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Avanti Heart institute",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 6",
                "ICU": " 3",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Suretech Hospital",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 22",
                "ICU": " 14",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Gatewell Hospital",
            "Contact": "NA",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Neuron Hospital",
            "Contact": "NA",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 17",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Gaikwad Critical Care Center",
            "Contact": "NA",
            "avatarIcon": "GC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Itkelwar Multispeciality Hospital",
            "Contact": "NA",
            "avatarIcon": "IM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Green City Nursing Home",
            "Contact": "NA",
            "avatarIcon": "GC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 15",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Vighnharta Hospital",
            "Contact": "NA",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Star City Hospital",
            "Contact": "NA",
            "avatarIcon": "SC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 21",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Shri Saikripa Hospital",
            "Contact": "NA",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 20",
                "ICU": " 9",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Aastha Critical Care Hospital",
            "Contact": "NA",
            "avatarIcon": "AC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Keshav Hospital",
            "Contact": "NA",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Aureus Hospital",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 45",
                "ICU": " 13",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Center Point Hospital",
            "Contact": "NA",
            "avatarIcon": "CP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 27",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Raut Nursing Home",
            "Contact": "NA",
            "avatarIcon": "RN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Asian Kidney Hospital and Medical Center",
            "Contact": "NA",
            "avatarIcon": "AK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 10",
                "ICU": " 19",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Kalamkar Multispeciality Hospital",
            "Contact": "NA",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 1",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Mure Memorial Hospital",
            "Contact": "NA",
            "avatarIcon": "MM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "Oxygen": " 20",
                "ICU": " 9",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Dande Hospital",
            "Contact": "NA",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 17",
                "ICU": " 3",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "G.B. Multicare Hospital",
            "Contact": "NA",
            "avatarIcon": "GM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 11",
                "ICU": " 9",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Central Neurological & Medical Institute",
            "Contact": "NA",
            "avatarIcon": "CN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 3",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Zenith Hospital",
            "Contact": "NA",
            "avatarIcon": "ZH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Aditya hospital",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 12",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Shraman Hospital",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 2",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Medigrace Hospital",
            "Contact": "NA",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Swastik Critical Care",
            "Contact": "NA",
            "avatarIcon": "SC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Krims Hospital",
            "Contact": "NA",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sengupta Hospital",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 8",
                "ICU": " 11",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sunflower Hospital",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Respira Hospital",
            "Contact": "NA",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 1",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Ayushman Hospital",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 38",
                "ICU": " 8",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Sushrut Hospital",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Ganga Care Hospital",
            "Contact": "NA",
            "avatarIcon": "GC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 1",
                "Oxygen": " 36",
                "ICU": " 4",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": "Meditrina Hospital",
            "Contact": "NA",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Viveka Superspeciality Hospital",
            "Contact": "NA",
            "avatarIcon": "VS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Wockhardt Hospital Shankar Nagar",
            "Contact": "NA",
            "avatarIcon": "WH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Wockhardt Hospita Gandhi Nagar",
            "Contact": "NA",
            "avatarIcon": "WH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 59",
                "ICU": " 25",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": "Sanjeevani Hospital (Laxminagar)",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 14",
                "ICU": " 5",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Central India Cardiology Hospital",
            "Contact": "NA",
            "avatarIcon": "CI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Athaayu Multispeciality Hospital",
            "Contact": "NA",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Crescent Hospital & Heart Center",
            "Contact": "NA",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "S S Multispeciality Hospital",
            "Contact": "NA",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Maxcare Hospital",
            "Contact": "NA",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Swasthyam Superspeciality Hospital",
            "Contact": "NA",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "Oxygen": " 24",
                "ICU": " 8",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Nucleus Mother and Child Hospital",
            "Contact": "NA",
            "avatarIcon": "NM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "Oxygen": " 18",
                "ICU": " 31",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Grace Ortho Hospital",
            "Contact": "NA",
            "avatarIcon": "GO",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 1",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Kalpavruksha Hospital",
            "Contact": "NA",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 11",
                "ICU": " 7",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Treat Me Hospital",
            "Contact": "NA",
            "avatarIcon": "TM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 13",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "CIIMS Hospital",
            "Contact": "NA",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Orange City Hospital",
            "Contact": "NA",
            "avatarIcon": "OC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 11",
                "ICU": " 18",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Railway Hospital",
            "Contact": "NA",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 46",
                "ICU": " 22",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "K.T. Nagar NMC Hospital",
            "Contact": "NA",
            "avatarIcon": "KN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 36",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Panchpaoli Womens Hospital",
            "Contact": "NA",
            "avatarIcon": "PW",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 50",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Shalinitai Meghe Hospital (S.G.)",
            "Contact": "NA",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "Oxygen": " 350",
                "ICU": " 15",
                "Ventilator": " 15"
            }
        },
        {
            "hospitalName": "Lata Mangeshkar Hospital Sitabuldi (S.G.)",
            "Contact": "NA",
            "avatarIcon": "LM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "Oxygen": " 41",
                "ICU": " 7",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Lata Mangeshkar Hospital Hingna (S.G.)",
            "Contact": "NA",
            "avatarIcon": "LM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 184",
                "ICU": " 10",
                "Ventilator": " 9"
            }
        },
        {
            "hospitalName": "Pachpaoli DCHC Center",
            "Contact": "NA",
            "avatarIcon": "PD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 73",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Ayush Hopital (NMC Sadar)",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 42",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Isolation Hospital Imamwada",
            "Contact": "NA",
            "avatarIcon": "IH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Indira Gandhi Rugnalaya Gandhi Nagar",
            "Contact": "NA",
            "avatarIcon": "IG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 89",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "AIIMS Hospital",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 198",
                "Oxygen": " 118",
                "ICU": " 8",
                "Ventilator": " 9"
            }
        },
        {
            "hospitalName": "IGGMC",
            "Contact": "NA",
            "avatarIcon": "I",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 434",
                "ICU": " 131",
                "Ventilator": " 101"
            }
        },
        {
            "hospitalName": "GMC",
            "Contact": "NA",
            "avatarIcon": "G",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 567",
                "ICU": " 297",
                "Ventilator": " 150"
            }
        }
    ],
    "FARIDABAD": [
        {
            "hospitalName": "SRU Multispeciality Hospital",
            "Contact": "",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Sanjeevan Multispeciality Hospital",
            "Contact": "",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "ICU": " 2",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": "Raj Nursing Home",
            "Contact": "01294875333",
            "avatarIcon": "RN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "ICU": " 0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Prachi Hospital",
            "Contact": "",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 2",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Bhavya Kalyani Hospital",
            "Contact": "",
            "avatarIcon": "BK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "ICU": " 9",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Vibal Hospital Pvt. Ltd.",
            "Contact": "",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 0",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": "Goyal Hospital",
            "Contact": "01294047396",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "ICU": " 0",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": "Geeta Hospital",
            "Contact": "",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "ICU": " 0",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": "Chugh Hospital",
            "Contact": "",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "ICU": " 14",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Samrat Hospital",
            "Contact": "01292440602",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 0",
                "Ventilator": " 9"
            }
        },
        {
            "hospitalName": "Medwin Hospital",
            "Contact": "",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "ICU": " 12",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": "Lotus Hospital",
            "Contact": "",
            "avatarIcon": "LH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "ICU": " 0",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": "Anshu Hospital",
            "Contact": "",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 10",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Geetanjali Multispeciality Hospital",
            "Contact": "",
            "avatarIcon": "GM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 19",
                "ICU": " 2",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Supreme Hospital",
            "Contact": "01292229297",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Raghav Hospital",
            "Contact": "",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 17",
                "ICU": " 3",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Kant Multispeciality Hospital Pvt Ltd",
            "Contact": "01292480897",
            "avatarIcon": "KM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Aarti Hospital",
            "Contact": "",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "ICU": " 15",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Shanti Devi Memorial Hospital",
            "Contact": "9810465370",
            "avatarIcon": "SD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "ICU": " 8",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Janak Hospital",
            "Contact": "",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "ICU": " 4",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": "Siddhi Vinayak Aspataal",
            "Contact": "9818204062",
            "avatarIcon": "SV",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 6",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": "Sunrise Hospital",
            "Contact": "",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 25",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "National Institute Of Medical Sciences",
            "Contact": "",
            "avatarIcon": "NI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "ICU": " 4",
                "Ventilator": " 9"
            }
        },
        {
            "hospitalName": "Madhav Hospital",
            "Contact": "",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "ICU": " 0",
                "Ventilator": " 28"
            }
        },
        {
            "hospitalName": "D.M. Hospital",
            "Contact": "",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 10",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": "Vedanta Hospital",
            "Contact": "01292231336",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 12",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": "Ortho 1 Hospital",
            "Contact": "8766249797",
            "avatarIcon": "O1",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "ICU": " 12",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": "Kedar Hospital Multispeciality",
            "Contact": "01294895917",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 15",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Durga Multispeciality Hospital",
            "Contact": "",
            "avatarIcon": "DM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 22",
                "ICU": " 0",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": "Manavta Hospital",
            "Contact": "01294874298",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 10",
                "Ventilator": " 11"
            }
        },
        {
            "hospitalName": "Shankar Medicare Center",
            "Contact": "",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "ICU": " 20",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "IBS Ashwani Hospital",
            "Contact": "",
            "avatarIcon": "IA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 22",
                "ICU": " 5",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": "Sarvodya Hospital Sector 19",
            "Contact": "",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 28",
                "ICU": " 0",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": "Doctor Today Multispecialty Hospital",
            "Contact": "01292442001",
            "avatarIcon": "DT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 35",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Pawan Hospital Unit-1",
            "Contact": "",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "ICU": " 0",
                "Ventilator": " 13"
            }
        },
        {
            "hospitalName": "Park Hospital Faridabad",
            "Contact": "",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "ICU": " 10",
                "Ventilator": " 24"
            }
        },
        {
            "hospitalName": "Kedar Hospital",
            "Contact": "",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 31",
                "ICU": " 6",
                "Ventilator": " 12"
            }
        },
        {
            "hospitalName": "Ronak Hospital",
            "Contact": "01292980740",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "ICU": " 20",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": "Asian Fidelis Multispeciality Hospital",
            "Contact": "",
            "avatarIcon": "AF",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 40",
                "ICU": " 0",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": "Santosh Multispeciality Hospital",
            "Contact": "",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 40",
                "ICU": " 10",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": "Medicheck Hospital",
            "Contact": "",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 52",
                "ICU": " 0",
                "Ventilator": " 12"
            }
        },
        {
            "hospitalName": "Arsh Hospital",
            "Contact": "",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 40",
                "ICU": " 5",
                "Ventilator": " 20"
            }
        },
        {
            "hospitalName": "Fortis Escort Hospital",
            "Contact": "",
            "avatarIcon": "FE",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 55",
                "ICU": " 0",
                "Ventilator": " 14"
            }
        },
        {
            "hospitalName": "Al-Falah Hospital",
            "Contact": "",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 50",
                "ICU": " 25",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Shri Atal Bihari Vajpayee Government Medical College, Chhainsa",
            "Contact": "",
            "avatarIcon": "SA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 100",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "B.K. Hospital Faridabad",
            "Contact": "",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 64",
                "ICU": " 25",
                "Ventilator": " 11"
            }
        },
        {
            "hospitalName": "QRG Hospital Medicare",
            "Contact": "01294253000",
            "avatarIcon": "QH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 104",
                "ICU": " 0",
                "Ventilator": " 18"
            }
        },
        {
            "hospitalName": "Asian Hospital",
            "Contact": "",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 87",
                "ICU": " 0",
                "Ventilator": " 39"
            }
        },
        {
            "hospitalName": "Metro Hospital Medicare",
            "Contact": "",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 80",
                "ICU": " 45",
                "Ventilator": " 19"
            }
        },
        {
            "hospitalName": "Sarvodya Hospital Sector-8",
            "Contact": "",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 125",
                "ICU": " 0",
                "Ventilator": " 25"
            }
        },
        {
            "hospitalName": "ESIC Medical College, Faridabad",
            "Contact": "NA",
            "avatarIcon": "EM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 137",
                "ICU": " 350",
                "Ventilator": " 74"
            }
        }
    ],
    "GURUGRAM": [
        {
            "hospitalName": "Shivpriya holistic health hospital",
            "Contact": "01243528282",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Miracles Apollo Cradle/Spectra",
            "Contact": "8010000045",
            "avatarIcon": "MA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Ektaa hospital",
            "Contact": "01242322222",
            "avatarIcon": "EH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Bhanot Hospital",
            "Contact": "01244797600",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Apollo Cradle",
            "Contact": "9811808769",
            "avatarIcon": "AC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Vaishnavi nursing home",
            "Contact": "9911664477",
            "avatarIcon": "VN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "ICU": " 4",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sona Devi Memorial Hospital",
            "Contact": "6350090588",
            "avatarIcon": "SD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "HIGHWAY HOSPITAL AND TRAUMA CENTRE",
            "Contact": "8683000273",
            "avatarIcon": "HH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "ICU": " 0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Geetanjali Hospital, Gurugram",
            "Contact": "01242468088",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "ICU": " 0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Chetanya hospital",
            "Contact": "09999202572",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "ICU": " 0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "AAROGYA MULTI-SPECIALITY HOSPITAL Gurugam",
            "Contact": "9540057559",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "ICU": " 0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Mangalam Hospital",
            "Contact": "01244289571",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 9",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Anand multispeciality hospital",
            "Contact": "01242351257",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "UMA SANJEEVANI HEATH CENTRE PVT LTD",
            "Contact": "01242380575",
            "avatarIcon": "US",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Poly Clinic Sector 31, Gurugram",
            "Contact": "01244767676",
            "avatarIcon": "PC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "ICU": " 3",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Lotus Hospital Gurugram",
            "Contact": "9625529114",
            "avatarIcon": "LH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "K.K Health Care Centre",
            "Contact": "9050018177",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "ICU": " 6",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Chauhan hospital",
            "Contact": "01244035971",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Ayushman Hospital",
            "Contact": "8447257533",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Kamal hospital and maternity centre",
            "Contact": "9899580324",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "ICU": " 0",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": "Genesis Hospital",
            "Contact": "+918285797215",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "ICU": " 0",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": "Dev Hospital",
            "Contact": "8447386864",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Jain Hospital Gurugram",
            "Contact": "01244363084",
            "avatarIcon": "JH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "SAI SIDDHI HOSPITAL",
            "Contact": "9873083638",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "ICU": " 4",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": "Jai Sai Ram Hospital Gurugram",
            "Contact": "8130335020",
            "avatarIcon": "JS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "VS hospital",
            "Contact": "8810210176",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Shiva hospital",
            "Contact": "8130010333",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "ICU": " 0",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": "Alfaa Hospital",
            "Contact": "9818182464",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 7",
                "ICU": " 4",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "SARASWATI HOSPITAL",
            "Contact": "6205497660",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "ICU": " 5",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "RBS HOSPITAL",
            "Contact": "8268006100",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Rathore IMT Hospital and Trauma Centre",
            "Contact": "9812200861",
            "avatarIcon": "RI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Kailash Hospital Gurugram",
            "Contact": "9996888124",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Global Hospital & Trauma Centre Pataudi",
            "Contact": "9899365230",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Sanjeevani hospital",
            "Contact": "01247178890",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Polaris Hospital",
            "Contact": "01244732200",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "ICU": " 12",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Orbit Hospital",
            "Contact": "8448996280",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "ICU": " 0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Madalasa Hospital",
            "Contact": "01244882200",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 0",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": "C K Birla Hospital",
            "Contact": "9555522944",
            "avatarIcon": "CK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Aarogyam Hospital, Gurugram",
            "Contact": "9650356644",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 16",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Global Hospital",
            "Contact": "01244910020",
            "avatarIcon": "GH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "ICU": " 0",
                "Ventilator": " 2"
            }
        },
        {
            "hospitalName": "Aryan Hospital Gurugram",
            "Contact": "01244568296",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "ICU": " 0",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": "Safe Hands Hospital",
            "Contact": "9972899728",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Cloudnine Hospital",
            "Contact": "8800586314",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "ICU": " 14",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Chetna Multispeciality Hospital",
            "Contact": "01244131036",
            "avatarIcon": "CM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "W.Pratiksha Hospital",
            "Contact": "9540057569",
            "avatarIcon": "WH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "ICU": " 0",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": "Samvit Health Care",
            "Contact": "7290090175",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "ICU": " 6",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Vedic hospital",
            "Contact": "7707070001",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Tulasi Healthcare",
            "Contact": "01244292666",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "ICU": " 20",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Triveni hospital",
            "Contact": "9718824055",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "ICU": " 0",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": "Sukhdai hospital",
            "Contact": "9818623546",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sarvodya Hospital gurugram",
            "Contact": "9053451004",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "ICU": " 0",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": "MAHADEV HOSPITAL",
            "Contact": "01244119992",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 12",
                "ICU": " 0",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": "Kathuria Hospital",
            "Contact": "01242375999",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 8",
                "ICU": " 0",
                "Ventilator": " 12"
            }
        },
        {
            "hospitalName": "LORD KRISHNA HOSPITAL",
            "Contact": "918199984102",
            "avatarIcon": "LK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Vardan Hospital gurgaon",
            "Contact": "7840025151",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 17",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "sukhda hospital and maternity centre llp",
            "Contact": "01244118001",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "ICU": " 0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Sethi Hospital",
            "Contact": "01242672102",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "ICU": " 0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "SDH PATAUDI",
            "Contact": "01244232805",
            "avatarIcon": "SP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 22",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Center of Diabetes & Allied Science",
            "Contact": "01242305550",
            "avatarIcon": "CO",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "ICU": " 0",
                "Ventilator": " 8"
            }
        },
        {
            "hospitalName": "Shri Govind Hospital",
            "Contact": "01242971051",
            "avatarIcon": "SG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 14",
                "ICU": " 10",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "SDH SOHNA",
            "Contact": "01244003435",
            "avatarIcon": "SS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 25",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Satyam Medicare Hospital",
            "Contact": "09910926116",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 21",
                "ICU": " 0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Kamla Hospital",
            "Contact": "01246623000",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 13",
                "ICU": " 7",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Max Hospital",
            "Contact": "01244795600",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 23",
                "ICU": " 0",
                "Ventilator": " 3"
            }
        },
        {
            "hospitalName": "Mamta Hospital",
            "Contact": "9667472983",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "ICU": " 0",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": "SPES HOSPITAL",
            "Contact": "01242267777",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "ICU": " 0",
                "Ventilator": " 13"
            }
        },
        {
            "hospitalName": "MEDOX HOSPITAL",
            "Contact": "01242468848",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 25",
                "ICU": " 0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Chirag Hospital",
            "Contact": "01244849100",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 15",
                "ICU": " 7",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": "TIRATH RAM HOSPITAL PVT LTD.",
            "Contact": "9971111975",
            "avatarIcon": "TR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "ICU": " 0",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": "IAS Medicare Hoapital Gurugram",
            "Contact": "9718208222",
            "avatarIcon": "IM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "ICU": " 0",
                "Ventilator": " 10"
            }
        },
        {
            "hospitalName": "Shri Balaji Multispeciality Hospital",
            "Contact": "01242875800",
            "avatarIcon": "SB",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 26",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Sheetla Hospital",
            "Contact": "01244666999",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 21",
                "ICU": " 5",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Kalyani Hospital",
            "Contact": "01244770000",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 30",
                "ICU": " 0",
                "Ventilator": " 1"
            }
        },
        {
            "hospitalName": "Pushpanjali Hospital",
            "Contact": "8221040053",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "ICU": " 0",
                "Ventilator": " 15"
            }
        },
        {
            "hospitalName": "Prakash Hospital and Trauma Centre",
            "Contact": "01242337136",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 16",
                "ICU": " 15",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Composite Hospital NSG",
            "Contact": "01244666555",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 36",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Umkal Hospital",
            "Contact": "7494905008",
            "avatarIcon": "UH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 20",
                "ICU": " 10",
                "Ventilator": " 7"
            }
        },
        {
            "hospitalName": "Boston Hospital",
            "Contact": "01246410841",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 22",
                "ICU": " 20",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Sunrise Hospital",
            "Contact": "7669727676",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 18",
                "ICU": " 11",
                "Ventilator": " 15"
            }
        },
        {
            "hospitalName": "Nivaran multispeciality Hospital",
            "Contact": "01242333100",
            "avatarIcon": "NM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 40",
                "ICU": " 0",
                "Ventilator": " 5"
            }
        },
        {
            "hospitalName": "Civil Hospital gurugram",
            "Contact": "01244222270",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 42",
                "ICU": " 0",
                "Ventilator": " 4"
            }
        },
        {
            "hospitalName": "Aarvy Hospital",
            "Contact": "9811349910",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 22",
                "ICU": " 0",
                "Ventilator": " 25"
            }
        },
        {
            "hospitalName": "Kriti Hospital Gurugram",
            "Contact": "012439898969",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 40",
                "ICU": " 0",
                "Ventilator": " 9"
            }
        },
        {
            "hospitalName": "Colombia Asia Hospital",
            "Contact": "9810553456",
            "avatarIcon": "CA",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 43",
                "ICU": " 0",
                "Ventilator": " 6"
            }
        },
        {
            "hospitalName": "Police Hospital, RTC, BHONDSI",
            "Contact": "01244755555",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 50",
                "ICU": " 0",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Medeor Hospital",
            "Contact": "08067506880",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 53",
                "ICU": " 0",
                "Ventilator": " 14"
            }
        },
        {
            "hospitalName": "Naryana Hospital",
            "Contact": "01244111880",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 35",
                "ICU": " 13",
                "Ventilator": " 20"
            }
        },
        {
            "hospitalName": "Mayom Hospital",
            "Contact": "6262800800",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 51",
                "ICU": " 5",
                "Ventilator": " 12"
            }
        },
        {
            "hospitalName": "Aarvy Healthcare Pvt Ltd",
            "Contact": "01242252001",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 45",
                "ICU": " 0",
                "Ventilator": " 25"
            }
        },
        {
            "hospitalName": "ESI Hospital Gurugram",
            "Contact": "01244777000",
            "avatarIcon": "EH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 40",
                "ICU": " 32",
                "Ventilator": " 0"
            }
        },
        {
            "hospitalName": "Metro Hospital",
            "Contact": "01246034444",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 27",
                "ICU": " 0",
                "Ventilator": " 54"
            }
        },
        {
            "hospitalName": "Signature Hospital",
            "Contact": "01244900000",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 50",
                "ICU": " 0",
                "Ventilator": " 32"
            }
        },
        {
            "hospitalName": "Park Hospital gurugram",
            "Contact": "01244511111",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 32",
                "ICU": " 0",
                "Ventilator": " 58"
            }
        },
        {
            "hospitalName": "Artemis Hospital",
            "Contact": "01244141414",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 73",
                "ICU": " 0",
                "Ventilator": " 30"
            }
        },
        {
            "hospitalName": "Medanta the Medicity",
            "Contact": "01247162200",
            "avatarIcon": "MT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 69",
                "ICU": " 0",
                "Ventilator": " 46"
            }
        },
        {
            "hospitalName": "Fortis Hospital",
            "Contact": "01244585555",
            "avatarIcon": "FH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 118",
                "ICU": " 0",
                "Ventilator": " 40"
            }
        },
        {
            "hospitalName": "Paras Hospital",
            "Contact": "01242278183",
            "avatarIcon": "PH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 117",
                "ICU": " 13",
                "Ventilator": " 46"
            }
        },
        {
            "hospitalName": "SGT Medical College & Research Institute, Budhera",
            "Contact": "NA",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 60",
                "ICU": " 244",
                "Ventilator": " 56"
            }
        }
    ],
    "PANVEL": [],
    "THANE": [
        {
            "hospitalName": "Lake City Hospital",
            "Contact": "NA",
            "avatarIcon": "LC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 62"
            }
        },
        {
            "hospitalName": "Criticare Lifeline Hospital",
            "Contact": "7400378271",
            "avatarIcon": "CL",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 96"
            }
        },
        {
            "hospitalName": "Hargun Health Care",
            "Contact": "8097741077",
            "avatarIcon": "HH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 24"
            }
        },
        {
            "hospitalName": "Neon Hospital",
            "Contact": "NA",
            "avatarIcon": "NH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 77"
            }
        },
        {
            "hospitalName": "Siddhivinayak Hospital Extension",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 49"
            }
        },
        {
            "hospitalName": "Hare Krishna Manav",
            "Contact": "NA",
            "avatarIcon": "HK",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 20"
            }
        },
        {
            "hospitalName": "Aarogyam multispecialitie hospital kopri",
            "Contact": "NA",
            "avatarIcon": "AM",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 56"
            }
        },
        {
            "hospitalName": "Aditya Hospital And ICU",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 25"
            }
        },
        {
            "hospitalName": "Orthocare hospital",
            "Contact": "NA",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 15"
            }
        },
        {
            "hospitalName": "Park Way Titan Hospital",
            "Contact": "NA",
            "avatarIcon": "PW",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 191"
            }
        },
        {
            "hospitalName": "Aarogya Hospital - Kharegoan Kalwa",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 17"
            }
        },
        {
            "hospitalName": "Ace Hospital (Dhanvantari Hospital)",
            "Contact": "NA",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 38"
            }
        },
        {
            "hospitalName": "Swastik Hospital",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 21"
            }
        },
        {
            "hospitalName": "Rohini Hospital",
            "Contact": "02249633457",
            "avatarIcon": "RH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 20"
            }
        },
        {
            "hospitalName": "Parking Plaza",
            "Contact": "NA",
            "avatarIcon": "PP",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 941"
            }
        },
        {
            "hospitalName": "Diya Hospital",
            "Contact": "NA",
            "avatarIcon": "DH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 40"
            }
        },
        {
            "hospitalName": "Shivneri Hospital",
            "Contact": "NA",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 25"
            }
        },
        {
            "hospitalName": "Metropol Hospital",
            "Contact": "NA",
            "avatarIcon": "MH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 14"
            }
        },
        {
            "hospitalName": "Horizon Prime Hospital",
            "Contact": "9769034884",
            "avatarIcon": "HP",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 68"
            }
        },
        {
            "hospitalName": "Thane Noble Hospital",
            "Contact": "9769392166",
            "avatarIcon": "TN",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 43"
            }
        },
        {
            "hospitalName": "Kausa Stadium Hospital",
            "Contact": "NA",
            "avatarIcon": "KS",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 270"
            }
        },
        {
            "hospitalName": "Vedant Multispeciality Vartak Nagar",
            "Contact": "8383005005",
            "avatarIcon": "VM",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 96"
            }
        },
        {
            "hospitalName": "Viraj Hospital",
            "Contact": "9773329678",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 28"
            }
        },
        {
            "hospitalName": "HIGHLAND HOSPITAL",
            "Contact": "9820314254",
            "avatarIcon": "HH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 26"
            }
        },
        {
            "hospitalName": "AvenueE Vedant Covid Hospital",
            "Contact": "9892507700",
            "avatarIcon": "AV",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 119"
            }
        },
        {
            "hospitalName": "Thane Covid Hospital-Global",
            "Contact": "9167251061",
            "avatarIcon": "TC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 824"
            }
        },
        {
            "hospitalName": "Bethany Hospital A - Wing",
            "Contact": "9870035333",
            "avatarIcon": "BH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 21"
            }
        },
        {
            "hospitalName": "Ekta Hopsital LLP & ICCU",
            "Contact": "02221721111",
            "avatarIcon": "EH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 30"
            }
        },
        {
            "hospitalName": "Life Care Hospital",
            "Contact": "02225855081",
            "avatarIcon": "LC",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 18"
            }
        },
        {
            "hospitalName": "Titan Hospital",
            "Contact": "02241008000",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 38"
            }
        },
        {
            "hospitalName": "Thane Health Care",
            "Contact": "9820027535",
            "avatarIcon": "TH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 61"
            }
        },
        {
            "hospitalName": "Sapphire Hospital",
            "Contact": "02225333222",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 130"
            }
        },
        {
            "hospitalName": "Vedant Hospital GB Road",
            "Contact": "02225988000",
            "avatarIcon": "VH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 81"
            }
        },
        {
            "hospitalName": "Kaushalya Hospital",
            "Contact": "02225454000",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Beds": " 47"
            }
        }
    ],
    "DEHRADUN": [
        {
            "hospitalName": "VIVEKANANDA NETRALAYA",
            "Contact": " 6396741154\n 0135-2734356",
            "avatarIcon": "VN",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 33",
                "ICU": " 33"
            }
        },
        {
            "hospitalName": "VIBHUTI SUPER SPECILAITY HOSPITAL",
            "Contact": " 8438458730\n 8868927609",
            "avatarIcon": "VS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 7",
                "ICU": " 7"
            }
        },
        {
            "hospitalName": "VELMED",
            "Contact": " 9837113630",
            "avatarIcon": "V",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 23",
                "ICU": " 23"
            }
        },
        {
            "hospitalName": "UTTARANCHAL HOSPITAL",
            "Contact": " 9412940414",
            "avatarIcon": "UH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 35",
                "Oxygen": " 10",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "TILU RAUTALI",
            "Contact": " 7906313714",
            "avatarIcon": "TR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 89",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "TIBETAN PHC DEKYILING DEHRADUN",
            "Contact": " 8126681687",
            "avatarIcon": "TP",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 9",
                "ICU": " 9"
            }
        },
        {
            "hospitalName": "SYNERGY HOSPITAL",
            "Contact": " 9456562897\n 0135-2226000",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 26",
                "ICU": " 26"
            }
        },
        {
            "hospitalName": "SUBHARTI HOSPITAL",
            "Contact": " 7617585580",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 100",
                "Oxygen": " 109",
                "ICU": " 109"
            }
        },
        {
            "hospitalName": "SRI MAHANT INDRESH HOSPITAL",
            "Contact": " 9939557537",
            "avatarIcon": "SM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 47",
                "Oxygen": " 233",
                "ICU": " 233"
            }
        },
        {
            "hospitalName": "SPS HOSPITAL RISHIKESH",
            "Contact": " 9456142278",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " 16"
            }
        },
        {
            "hospitalName": "SHRI YASHODA AND TRAUMA CENTER",
            "Contact": " 7078404040",
            "avatarIcon": "SY",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 4",
                "ICU": " 4"
            }
        },
        {
            "hospitalName": "SHED HOSPITAL",
            "Contact": " 9412058221",
            "avatarIcon": "SH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 3",
                "ICU": " 3"
            }
        },
        {
            "hospitalName": "RAJIV GANDHI INTERNATIONAL STADIUM",
            "Contact": " 7409113756",
            "avatarIcon": "RG",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 350",
                "Oxygen": " 120",
                "ICU": " 120"
            }
        },
        {
            "hospitalName": "PREM SUKH HOSPITAL TURNER ROAD",
            "Contact": " 9412998075",
            "avatarIcon": "PS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " 16"
            }
        },
        {
            "hospitalName": "PRASAD DOON HOSPITAL",
            "Contact": " 9319312543",
            "avatarIcon": "PD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 22",
                "ICU": " 22"
            }
        },
        {
            "hospitalName": "PHC KALSI",
            "Contact": " 7017275153\n 01360-275574",
            "avatarIcon": "PK",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 9",
                "ICU": " 9"
            }
        },
        {
            "hospitalName": "PARAS DOON HOSPITAL",
            "Contact": " 9927058843",
            "avatarIcon": "PD",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 23",
                "ICU": " 23"
            }
        },
        {
            "hospitalName": "ONGC HOSPITAL",
            "Contact": " 9410396477",
            "avatarIcon": "OH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 14",
                "ICU": " 14"
            }
        },
        {
            "hospitalName": "MRITYUNJAY CRITICAL HOSPITAL",
            "Contact": " 7078666666",
            "avatarIcon": "MC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 6",
                "Oxygen": " 12",
                "ICU": " 12"
            }
        },
        {
            "hospitalName": "MEDICARE MULTI SPECIALIST HOSPITAL SELAQUI",
            "Contact": " 8954575713",
            "avatarIcon": "MM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 4",
                "Oxygen": " 16",
                "ICU": " 16"
            }
        },
        {
            "hospitalName": "MAX SUPER SPECIALTY HOSPITAL",
            "Contact": " 7895264143\n 7895900718\n 0135-2719300",
            "avatarIcon": "MS",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 14",
                "ICU": " 14"
            }
        },
        {
            "hospitalName": "LEMAN HOSPITAL",
            "Contact": " 7302934288",
            "avatarIcon": "LH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 2",
                "Oxygen": " 37",
                "ICU": " 37"
            }
        },
        {
            "hospitalName": "KANISHK HOSPITAL DEHRADUN",
            "Contact": " 8954562773",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 0",
                "ICU": " 0"
            }
        },
        {
            "hospitalName": "KALINDI HOSPITAL",
            "Contact": " 7895151380",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 66",
                "ICU": " 66"
            }
        },
        {
            "hospitalName": "KAILASH HOSPITAL DEHRADUN",
            "Contact": " 9999998808",
            "avatarIcon": "KH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 40",
                "Oxygen": " 64",
                "ICU": " 64"
            }
        },
        {
            "hospitalName": "HIMALAYAN INSTITUTE OF MEDICAL SCIENCES",
            "Contact": " 1352471547",
            "avatarIcon": "HI",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 97",
                "Oxygen": " 98",
                "ICU": " 98"
            }
        },
        {
            "hospitalName": "HEALTH CARE HOSPITAL",
            "Contact": " 8273555555",
            "avatarIcon": "HC",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 10"
            }
        },
        {
            "hospitalName": "GLOBAL TRAUMA CENTER",
            "Contact": " 9759765830",
            "avatarIcon": "GT",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 15",
                "ICU": " 15"
            }
        },
        {
            "hospitalName": "DOON MEDICAL COLLEGE",
            "Contact": " 9412080805",
            "avatarIcon": "DM",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 10",
                "Oxygen": " 113",
                "ICU": " 113"
            }
        },
        {
            "hospitalName": "CORONATION HOSPITAL DEHRADUN",
            "Contact": " 9756191805",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 49",
                "ICU": " 49"
            }
        },
        {
            "hospitalName": "CMI HOSPITAL",
            "Contact": " 9719410066\n 0135-2720411",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 3",
                "Oxygen": " 48",
                "ICU": " 48"
            }
        },
        {
            "hospitalName": "CIVIL HOSPITAL MUSSOORIE",
            "Contact": " 9411388535",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 5",
                "Oxygen": " 24",
                "ICU": " 24"
            }
        },
        {
            "hospitalName": "CITY HEART CENTER",
            "Contact": " 7895727332",
            "avatarIcon": "CH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 5",
                "ICU": " 5"
            }
        },
        {
            "hospitalName": "ARIHANT HOSPITAL",
            "Contact": " 6399444000",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 26",
                "ICU": " 26"
            }
        },
        {
            "hospitalName": "AIIMS RISHIKESH",
            "Contact": " 8475000144",
            "avatarIcon": "AR",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 91",
                "Oxygen": " 439",
                "ICU": " 439"
            }
        },
        {
            "hospitalName": "AARYAN HOSPITAL DOIWALA",
            "Contact": " 9410742022",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 16",
                "ICU": " 16"
            }
        },
        {
            "hospitalName": "AAROGYADHAM HOSPITAL",
            "Contact": " 7668181533\n 9758069202",
            "avatarIcon": "AH",
            "foundUseful": "0",
            "bedsData": {
                "Normal": " 0",
                "Oxygen": " 10",
                "ICU": " 10"
            }
        }
    ]
};