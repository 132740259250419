/* eslint-disable react/jsx-filename-extension */
import React, { useState } from "react";
import {
  LikeFilled,
  LikeTwoTone,
  DislikeFilled,
  DislikeTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { addLike } from "../Firebase/util";
import { FirebaseContext } from "../Firebase";
import styles from "./style.module.css";
import { encryptLike } from "./util";

function LikeButton({ resourceInfo }) {
  const key = encryptLike(resourceInfo);
  const storedValue = window.localStorage.getItem(key);
  let lastLikedStatus = 0;
  if (storedValue) {
    try {
      const { timestamp, value } = JSON.parse(storedValue);
      const lastHourTimestamp = Date.now() - 1000 * 60 * 60;
      if (timestamp > lastHourTimestamp && value >= -1 && value <= 1) {
        lastLikedStatus = value;
      }
    } catch {
      console.error("Invalid value in localStore");
    }
  }

  const [liked, setLiked] = useState(lastLikedStatus);
  const [popupOpened, setPopupOpened] = useState(false);

  function handleClick() {
    setPopupOpened(!popupOpened);
  }

  function handleButtonClick(firebase, value, transaction) {
   
    if (transaction !== 0) {
    
      addLike(firebase, resourceInfo, transaction)
        .then(() => {
          setLiked(value);
          window.localStorage.setItem(
            key,
            JSON.stringify({
              timestamp: Date.now(),
              value,
            })
          );
        })
        .catch(() => {
          console.error("Uh oh, failed like button");
        });
    } else {
      setLiked(value);
      window.localStorage.setItem(
        key,
        JSON.stringify({
          timestamp: Date.now(),
          value,
        })
      );
    }
  }

  function PopupLikeSelector(firebase) {
    return (
      <div className={`${styles.popup} ${styles.flexrow}`}>
        <div className={styles.icon} style={{ marginLeft: "4px" }}>
          {liked < 0 ? (
            <DislikeFilled
              onClick={(e) => handleButtonClick(firebase, 0, 1)}
              className={styles.filled}
            />
          ) : (
            <DislikeTwoTone
              onClick={(e) => handleButtonClick(firebase, -1, -1 - liked)}
              size={24}
              color="#209AE5"
            />
          )}
        </div>
        <div className={styles.icon}>
          {liked > 0 ? (
            <LikeFilled
              onClick={(e) => handleButtonClick(firebase, 0, -1)}
              className={styles.filled}
            />
          ) : (
            <LikeTwoTone
              onClick={(e) => handleButtonClick(firebase, 1, 1 - liked)}
              size={24}
              color="#209AE5"
            />
          )}
        </div>
        <div className={styles.icon}>
          <CloseCircleTwoTone className={styles.icon} />
        </div>
      </div>
    );
  }

  return (
    <div onClick={(e) => handleClick(e)} className={`${styles.flexrow} ${styles.likecontainer}`}>
      <div className={`${styles.bold} ${styles.flexrow}`}>
        {liked > 0 && "Liked!"}
        {liked === 0 && "-"}
        {liked < 0 && "Disliked!"}
      </div>
      <div style={{ flexGrow: "1" }} />
      {!popupOpened && (
        <div className={styles.icon}>
          {liked > 0 && <LikeFilled className={styles.filled} />}
          {liked === 0 && <LikeTwoTone className={styles.filled} />}
          {liked < 0 && <DislikeFilled className={styles.filled} />}
        </div>
      )}
      {popupOpened && (
        <div>
          <FirebaseContext.Consumer>
            {(firebase) => PopupLikeSelector(firebase)}
          </FirebaseContext.Consumer>
        </div>
      )}
    </div>
  );
}
export default LikeButton;
