/* eslint-disable react/prop-types */
import { Button } from "antd";
import { useState } from "react";
import Iframe from "react-iframe";
import TopTitleBar from "../components/TopTitleBar";
import { content } from "../data/SurveyData";
import useWindowSize from "../useWindowSize";

function Survey(props) {
  const primaryColor = "rgb(33, 150, 243)";
  const [doSurvey, setDoSurvey] = useState(false);
  const { width, height } = useWindowSize();
  const iframeHeight = height / 0.866477 - 46 - 47;

  return (
    <div className="" style={{ fontFamily: "sans-serif" }}>
      {doSurvey && (
        <>
          <div
            className="pt-2 pb-2 pl-1 row sticky-top"
            style={{ backgroundColor: primaryColor }}
          >
            <div
              className="col-2 d-flex mt-1"
              onClick={() => {
                setDoSurvey(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <span
                style={{
                  color: "white",
                  fontSize: "1rem",
                  fontWeight: "500",
                  fontFamily: "sans-serif",
                }}
              >
                Done
              </span>
            </div>

            <div
              className="col-10"
              style={{
                color: "white",
                fontSize: "1.2rem",
                fontWeight: "500",
                fontFamily: "sans-serif",
              }}
              className="m-auto"
            >
              Survey
            </div>

            <div className="col-2" />
          </div>
          <Iframe
            className="m-auto survey-form"
            url={content.surveyLink}
            width="100%"
            height={iframeHeight}
            display="initial"
            position="relative"
          />
        </>
      )}
      {!doSurvey && (
        <>
          <TopTitleBar
            title="Help in the COVID-19 efforts by filling survey"
            chosen={() => {}}
          />
          <div className="pb-2" style={{ borderBottom: "1px solid silver" }}>
            <p style={{ fontSize: "1.2rem", fontWeight: "700" }}>
              {" "}
              {content.heading}{" "}
            </p>
            <div style={{ fontSize: "0.9rem", fontWeight: "500" }}>
              {content.bodyStart}
              <br /> <br />
              {content.body}
              <br /> <br />
              {content.bodyEnd}
              <br />
            </div>
          </div>
          <div className="mt-3">
            <Button
              onClick={() => setDoSurvey(true)}
              className="w-100"
              size="large"
              style={{
                background: primaryColor,
                color: "white",
                fontWeight: "600",
                borderRadius: "10px",
              }}
            >
              Start Survey
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default Survey;
