export const sourceData = [
    {
        "city": "AJMER",
        "source": "https://covidinfo.rajasthan.gov.in/Index.aspx"
    },
    {
        "city": "ALMORA",
        "source": "https://covid19.uk.gov.in/bedssummary.aspx"
    },
    {
        "city": "BANGALORE",
        "source": "https://bbmpgov.com/chbms/"
    },
    {
        "city": "BHOPAL",
        "source": "http://sarthak.nhmmp.gov.in/covid/facility-bed-occupancy-dashboard/"
    },
    {
        "city": "CHENNAI",
        "source": "https://stopcorona.tn.gov.in/beds.php"
    },
    {
        "city": "DEHRADUN",
        "source": "https://covid19.uk.gov.in/bedssummary.aspx"
    },
    {
        "city": "DELHI",
        "source": "https://coronabeds.jantasamvad.org/beds.html"
    },
    {
        "city": "FARIDABAD",
        "source": "https://coronaharyana.in/?city=4"
    },
    {
        "city": "GANDHINAGAR",
        "source": "https://vmc.gov.in/HospitalModuleGMC/HospitalBedsDetails.aspx?tid=1"
    },
    {
        "city": "GURUGRAM",
        "source": "https://coronaharyana.in/?city=6"
    },
    {
        "city": "GWALIOR",
        "source": "http://sarthak.nhmmp.gov.in/covid/facility-bed-occupancy-dashboard/"
    },
    {
        "city": "HARIDWAR",
        "source": "https://covid19.uk.gov.in/bedssummary.aspx"
    },
    {
        "city": "HYDERABAD",
        "source": "http://164.100.112.24/SpringMVC/Hospital_Beds_Statistic_Bulletin_citizen.html"
    },
    {
        "city": "INDORE",
        "source": "http://sarthak.nhmmp.gov.in/covid/facility-bed-occupancy-dashboard/"
    },
    {
        "city": "JABALPUR",
        "source": "http://sarthak.nhmmp.gov.in/covid/facility-bed-occupancy-dashboard/"
    },
    {
        "city": "JAIPUR",
        "source": "https://covidinfo.rajasthan.gov.in/Index.aspx"
    },
    {
        "city": "JODHPUR",
        "source": "https://covidinfo.rajasthan.gov.in/Index.aspx"
    },
    {
        "city": "KALYAN-DOMBIVALI",
        "source": "http://13.233.40.233:8080/KDMCBedManagement/"
    },
    {
        "city": "KOLKATA",
        "source": "https://www.wbhealth.gov.in/pages/corona/bed_availability"
    },
    {
        "city": "KOTA",
        "source": "https://covidinfo.rajasthan.gov.in/Index.aspx"
    },
    {
        "city": "LUDHIANA",
        "source": "http://hbmsludhiana.in/index_app_detail.php?type=all"
    },
    {
        "city": "NAGPUR",
        "source": "http://nsscdcl.org/covidbeds/AvailableHospitals.jsp"
    },
    {
        "city": "NAINITAL",
        "source": "https://covid19.uk.gov.in/bedssummary.aspx"
    },
    {
        "city": "PANIPAT",
        "source": "https://coronaharyana.in/"
    },
    {
        "city": "PANVEL",
        "source": "https://covidbedpanvel.in/HospitalInfo/showindex"
    },
    {
        "city": "PUDUCHERRY",
        "source": "https://covid19dashboard.py.gov.in/BedAvailabilityDetails"
    },
    {
        "city": "PUNE",
        "source": "https://covidpune.com/"
    },
    {
        "city": "RAIPUR",
        "source": "https://cg.nic.in/health/covid19/RTPBedAvailable.aspx"
    },
    {
        "city": "RANCHI",
        "source": "https://pratirakshak.co.in/new-report.php"
    },
    {
        "city": "RUDRAPRAYAG",
        "source": "https://covid19.uk.gov.in/bedssummary.aspx"
    },
    {
        "city": "SONIPAT",
        "source": "https://coronaharyana.in/"
    },
    {
        "city": "THANE",
        "source": "https://covidbedthane.in/HospitalInfo/showindex"
    },
    {
        "city": "UDAIPUR",
        "source": "https://covidinfo.rajasthan.gov.in/Index.aspx"
    },
    {
        "city": "ULHASNAGAR",
        "source": "https://umccovidbed.in/HospitalInfo/showindex"
    },
    {
        "city": "VADODARA",
        "source": "https://vmc.gov.in/Covid19VadodaraApp/HospitalBedsDetails.aspx?tid=1"
    },
    {
        "city": "VISAKHAPATNAM",
        "source": "http://dashboard.covid19.ap.gov.in/ims/hospbed_reports/"
    },
    {
        "city": "KANPUR",
        "source": "https://beds.dgmhup-covid19.in/EN/covid19bedtrack"
    },
    {
        "city": "PRAYAGRAJ",
        "source": "https://beds.dgmhup-covid19.in/EN/covid19bedtrack"
    },
    {
        "city": "VARANASI",
        "source": "https://beds.dgmhup-covid19.in/EN/covid19bedtrack"
    },
    {
        "city": "AGRA",
        "source": "https://beds.dgmhup-covid19.in/EN/covid19bedtrack"
    },
    {
        "city": "NOIDA",
        "source": "https://beds.dgmhup-covid19.in/EN/covid19bedtrack"
    },
    {
        "city": "LUCKNOW",
        "source": "https://beds.dgmhup-covid19.in/EN/covid19bedtrack"
    }
];