import { updateData } from "./ExtractedData/LastUpdateData";

export const headingData = {
  heading: "Food",
  lastUpdated: updateData.foodUpdate,
  warning:
    "Data is taken from covidfightclub.org where volunteers try to verify every source. We are not liable for any inaccuracies. Kindly verify the availability before paying advance money.",
  buttonText: "Ask for Help",
  sourceLink: "https://covidfightclub.org/",
};
