/* eslint-disable no-extra-boolean-cast */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  RightOutlined,
  FormOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Button, Avatar } from "antd";
import { BiLink } from "react-icons/bi";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Tiles from "../components/Tiles/HomePageTiles";
import { headingData } from "../data/VacantBedsData";
import { vacantBedData } from "../data/ExtractedData/VacantBeds";
import { sprinklrData } from "../data/ExtractedData/OxygenLinks";
import { FirebaseContext } from "../components/Firebase";
import { sourceData } from "../data/ExtractedData/SourceData";
import SearchBar from "../components/SearchBar";

function VacantBedsHospitals({ match }) {
  if (!match) {
    return null;
  }
  const primaryColor = "rgb(33, 150, 243)";
  const history = useHistory();
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    // scroll to top, on mount
    window.scrollTo(0, 0);
  }, []);

  const getSource = () => {
    const source = sourceData.filter((data) =>
      data.city.includes(match.params.city.toUpperCase())
    )[0];
    const url = !!source ? source.source : "";

    return url;
  };

  const setInput = (input) => {
    setSearchInput(input);
  };

  const onBack = () => {
    history.push("/home/VacantBeds");
  };

  const onChangeChosenData = (val) => {
    history.push(
      `/home/Hospital/${match.params.city.toUpperCase()}/${val.hospitalName.replace(
        /\s/g,
        ""
      )}`
    );
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  function searchHospital(hospitalData) {
    const filteredHospitals = hospitalData.filter((data) =>
      data.hospitalName.toLowerCase().includes(searchInput.toLowerCase())
    );
    return filteredHospitals;
  }

  const sliceIconName = (name) => {
    const iconName = name.match(/\b(\w)/g);
    return iconName.join("").slice(0, 2);
  };

  return (
    <div className="container-fluid">
      <div className="mb-5" style={{ fontFamily: "sans-serif" }}>
        <SearchBar
          searchBarData={{
            back: onBack,
            backLink: "/home/VacantBeds",
            searchString: setInput,
            cityFilter: setInput,
            title: match.params.city,
          }}
        />
        {getSource() !== "" && (
          <Button
            onClick={() => openInNewTab(getSource())}
            size="large"
            className="w-100 mt-2"
          >
            <p
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                cursor: "pointer",
                color: primaryColor,
                textAlign: "center",
              }}
            >
              <BiLink
                size="20px"
                style={{ margin: "3px", float: "right" }}
                color={primaryColor}
              />
              Data Source
            </p>
          </Button>
        )}
        <a onClick={() => openInNewTab(headingData.buttonLink)}>
          <Button
            className="w-100 mt-2"
            size="large"
            style={{
              background: primaryColor,
              color: "white",
              fontWeight: "500",
              borderRadius: "10px",
            }}
          >
            <FormOutlined style={{ fontSize: "25px" }} />
            {headingData.buttonText}
          </Button>
        </a>

        {!!sprinklrData[match.params.city.toUpperCase()] && (
          <Button
            onClick={() => {
              history.push(
                `/home/VacantBeds/${
                  sprinklrData[match.params.city.toUpperCase()][0].City
                }/LiveTwitterLeads`
              );
            }}
            className="w-100 mt-2"
            size="large"
            style={{
              color: primaryColor,
              fontWeight: "500",
              borderRadius: "10px",
            }}
          >
            <TwitterOutlined
              style={{
                fontSize: "25px",
                background: "white",
                color: primaryColor,
              }}
            />
            {headingData.twitterbuttonText}
          </Button>
        )}
        <FirebaseContext.Consumer>
          {() =>
            Object.keys(vacantBedData).indexOf(match.params.city) !== -1 &&
            (searchInput !== ""
              ? searchHospital(vacantBedData[match.params.city])
              : vacantBedData[match.params.city]
            ).length !== 0 ? (
              (searchInput !== ""
                ? searchHospital(vacantBedData[match.params.city])
                : vacantBedData[match.params.city]
              ).map((data) => {
                return (
                  <div
                    key={data.hospitalName}
                    onClick={() => {
                      onChangeChosenData(data);
                    }}
                  >
                    <Tiles
                      avatar
                      avatarIcon={
                        <Avatar
                          size={50}
                          style={{
                            color: "#0066b2",
                            backgroundColor: "#B9D9EB",
                            fontSize: "28px",
                            flexShrink: "0",
                          }}
                        >
                          {data.avatarIcon.length > 2 &&
                            sliceIconName(data.avatarIcon)}
                          {data.avatarIcon.length <= 2 && data.avatarIcon}
                        </Avatar>
                      }
                      beds
                      bedsData={data.bedsData}
                      text={data.hospitalName}
                      icon={<RightOutlined />}
                    />
                  </div>
                );
              })
            ) : (
              <div
                className="m-auto text-center"
                style={{
                  fontWeight: "700",
                  paddingTop: "20px",
                  fontSize: "18px",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <span>Data for this city will be available soon.</span>
              </div>
            )
          }
        </FirebaseContext.Consumer>
      </div>
    </div>
  );
}

export default VacantBedsHospitals;
