/* eslint-disable react/jsx-filename-extension */
import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import style from "../Comments/Comments.module.css";
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 20, color: '#00008b' }} spin />;

function LoginPage(props) {
  const [verifyCode, setVerifyCode] = useState("");
  const { firebase, email, onSubmitCallback, backHandler, verificationError, otpLoading } = props;

  function verify() {
    onSubmitCallback(verifyCode);
  }

  function validateOTP() {
    return verifyCode.length === 6;
  }

  return (
      <div
        className={`${style.flex} ${style.flexcolumn} ${style.flexcenter} ${style.fullscreen} ${style.primary}`}
      >
        <div className={style.closebutton}>
          <CloseOutlined
            size={200}
            style={{
              alignSelf: "flex-start",
              display: otpLoading ? "none" : "block",
            }}
            onClick={() => backHandler()}
          />
        </div>
        <div className={style.container}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "right",
            }}
          />
          <div className={style.maintext}>
            <div>
              <p
                style={{
                  fontSize: 36,
                  fontWeight: 900,
                  bottom: 0,
                  textAlign: "center",
                }}
              >
                Verification code is sent to {email}
              </p>
              <p style={{ fontWeight: 300, fontSize: 24, textAlign: "center" }}>
                {" "}
                Please enter your verification code.{" "}
              </p>
              <input
                style={{
                  color: "black",
                }}
                className={style.maininput}
                type="text"
                value={verifyCode}
                onChange={(e) => setVerifyCode(e.target.value)}
              />
              <p
                style={{
                  fontWeight: 500,
                  fontSize: 16,
                  color: "red",
                }}
              >
                {verificationError}
              </p>
            </div>
          </div>
          <Button
            disabled={!validateOTP() || otpLoading}
            className={style.continuebutton}
            id="sign-in-button"
            onClick={() => verify()}
            style={{
              height: "44px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              borderRadius: "5px",
              justifyContent: "center",
              alignItems: "center",
              flexShrink: "0",
              backgroundColor: (validateOTP() && !otpLoading)
                ? "white"
                : "rgba(255, 255, 255, 0.247)",
              color: (validateOTP() && !otpLoading) ? "black" : "#00008b",
              fontWeight: 900,
            }}
          >
            <div style={{position:"relative"}}>Continue <Spin style={{position:"absolute", right:"-25px", lineHeight: "1",
    top: "calc(50% - 10px)"}} spinning={otpLoading} indicator={antIcon} /></div>
          </Button>
        </div>
      </div>
  );
}

export default LoginPage;
