/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import { Button, notification } from "antd";
import "./style.module.css";
import React from "react";
import { openInNewTab } from "../../JsUtil/OpenInNewTab";

const close = () => {};

const pushNotification = (payload, trigger) => {
  const key = `open${Date.now()}`;

  const btn = (
    <Button
      type="primary"
      size="small"
      onClick={() => openInNewTab("https://selfregistration.cowin.gov.in/")}
    >
      Book Now
    </Button>
  );
  trigger === "success" &&
    notification.success({
      message: payload.data.title,
      description: payload.data.body,
      key,
      onClose: close,
      duration: 0,
      placement: "bottomRight",
      style: {
        border: "1px solid #282c34",
        boxShadow: "5px 10px #888888",
        bottom: "20px",
      },
    });

  trigger !== "success" &&
    notification.info({
      message: payload.data.title,
      description: payload.data.body,
      btn,
      key,
      onClose: close,
      duration: 0,
      placement: "bottomRight",
      style: {
        border: "1px solid #282c34",
        boxShadow: "5px 10px #888888",
        bottom: "20px",
      },
    });
};

export default pushNotification;
