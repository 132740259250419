export const foodData = {
    "Mumbai Suburban": [
        {
            "avatar": "VM",
            "City": "Mumbai Suburban",
            "Provider": "Veena Menghrajani",
            "Contact": "9323802670",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BF",
            "City": "Mumbai Suburban",
            "Provider": "Baesic Fit",
            "Contact": "9372319339",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Mumbai Suburban",
            "Provider": "Pratik",
            "Contact": "9892236959",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PD",
            "City": "Mumbai Suburban",
            "Provider": "Poonam Damani",
            "Contact": "9821319103",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Mumbai Suburban",
            "Provider": "Niharika",
            "Contact": "9892045778",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "G",
            "City": "Mumbai Suburban",
            "Provider": "Govinda's",
            "Contact": "9987786411",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Mumbai Suburban",
            "Provider": "Pratik",
            "Contact": "9892236959",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "C",
            "City": "Mumbai Suburban",
            "Provider": "Curryfiy",
            "Contact": "9892513151",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SD",
            "City": "Mumbai Suburban",
            "Provider": "Spicey dicey",
            "Contact": "9820093828",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SO",
            "City": "Mumbai Suburban",
            "Provider": "Sangeeta Organic Meals",
            "Contact": "9892462995",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "V(",
            "City": "Mumbai Suburban",
            "Provider": "Vivek (Yummy Tiffins)",
            "Contact": "9820223338",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "C",
            "City": "Mumbai Suburban",
            "Provider": "Curryfiy",
            "Contact": "9892513151",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FG",
            "City": "Mumbai Suburban",
            "Provider": "Fauram Gala",
            "Contact": "9167759101",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KF",
            "City": "Mumbai Suburban",
            "Provider": "Kafrisa Food Co",
            "Contact": "8850307646",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PD",
            "City": "Mumbai Suburban",
            "Provider": "Poonam Damani",
            "Contact": "9821319103",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AP",
            "City": "Mumbai Suburban",
            "Provider": "Aiyo, Patrao",
            "Contact": "8450912371",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AR",
            "City": "Mumbai Suburban",
            "Provider": "Andrea Rosario",
            "Contact": "9004819546",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Mumbai Suburban",
            "Provider": "Moms kitchen",
            "Contact": "9819469739",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Mumbai Suburban",
            "Provider": "Sanju Sharma",
            "Contact": "9920482348",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Mumbai Suburban",
            "Provider": "Manpreet",
            "Contact": "9321838714",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SD",
            "City": "Mumbai Suburban",
            "Provider": "Spicey dicey",
            "Contact": "9820093828",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Mumbai Suburban",
            "Provider": "preeti",
            "Contact": "9870082996",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "Mumbai Suburban",
            "Provider": "Kuldip",
            "Contact": "9833597061",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "G",
            "City": "Mumbai Suburban",
            "Provider": "Govinda's",
            "Contact": "9987786411",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SY",
            "City": "Mumbai Suburban",
            "Provider": "Sindhfully yours",
            "Contact": "7700066169",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "T",
            "City": "Mumbai Suburban",
            "Provider": "Tanima",
            "Contact": "8591101543",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MB",
            "City": "Mumbai Suburban",
            "Provider": "Mrs. Bella Pathak",
            "Contact": "8879257481",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SN",
            "City": "Mumbai Suburban",
            "Provider": "Sarita Nirmal",
            "Contact": "9004899554",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NK",
            "City": "Mumbai Suburban",
            "Provider": "Nidhi Kapoor",
            "Contact": "9769649006",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "C",
            "City": "Mumbai Suburban",
            "Provider": "Curryfiy",
            "Contact": "9892513151",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HM",
            "City": "Mumbai Suburban",
            "Provider": "Harshala M. Shirke",
            "Contact": "9223282268",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "V(",
            "City": "Mumbai Suburban",
            "Provider": "Vivek (Yummy Tiffins)",
            "Contact": "9820223338",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HK",
            "City": "Mumbai Suburban",
            "Provider": "Homechefs Kitchen",
            "Contact": "9619553666",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "LK",
            "City": "Mumbai Suburban",
            "Provider": "Lyfe Kitchen",
            "Contact": "7506670666",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AV",
            "City": "Mumbai Suburban",
            "Provider": "Angad Ved Raj",
            "Contact": "9309803953",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BF",
            "City": "Mumbai Suburban",
            "Provider": "Baesic Fit",
            "Contact": "9372319339",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Mumbai Suburban",
            "Provider": "Pratik",
            "Contact": "9892236959",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FG",
            "City": "Mumbai Suburban",
            "Provider": "Fauram Gala",
            "Contact": "9167759101",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NK",
            "City": "Mumbai Suburban",
            "Provider": "Nidhi Kapoor",
            "Contact": "9769649006",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Mumbai Suburban",
            "Provider": "Sreedevi",
            "Contact": "8826640770",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "H",
            "City": "Mumbai Suburban",
            "Provider": "Henna",
            "Contact": "9819722679",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Mumbai Suburban",
            "Provider": "Niharika",
            "Contact": "9892045778",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NG",
            "City": "Mumbai Suburban",
            "Provider": "Nidhi Gathani",
            "Contact": "8591041098",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "T",
            "City": "Mumbai Suburban",
            "Provider": "Tanima",
            "Contact": "8591101543",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CH",
            "City": "Mumbai Suburban",
            "Provider": "Chandras home cooked meal",
            "Contact": "9869909435",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "C",
            "City": "Mumbai Suburban",
            "Provider": "Chandani",
            "Contact": "9324093207",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ZF",
            "City": "Mumbai Suburban",
            "Provider": "Zazo Foods",
            "Contact": "9967221942",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AR",
            "City": "Mumbai Suburban",
            "Provider": "Andrea Rosario",
            "Contact": "9004819546",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Mumbai Suburban",
            "Provider": "Shilpa",
            "Contact": "9820312469",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "L",
            "City": "Mumbai Suburban",
            "Provider": "Le...Kha",
            "Contact": "7045600201",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Mumbai Suburban",
            "Provider": "Niharika",
            "Contact": "9892045778",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "Y",
            "City": "Mumbai Suburban",
            "Provider": "Yogisattva",
            "Contact": "9867455009",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "C",
            "City": "Mumbai Suburban",
            "Provider": "Chandani",
            "Contact": "9324093207",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AM",
            "City": "Mumbai Suburban",
            "Provider": "Adnan Mithaiwala",
            "Contact": "9224592048",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KA",
            "City": "Mumbai Suburban",
            "Provider": "Kirti Ahuja",
            "Contact": "9004709007",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "V(",
            "City": "Mumbai Suburban",
            "Provider": "Vivek (Yummy Tiffins)",
            "Contact": "9820223338",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "Y",
            "City": "Mumbai Suburban",
            "Provider": "Yogisattva",
            "Contact": "9867455009",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SK",
            "City": "Mumbai Suburban",
            "Provider": "Sujata Kumar",
            "Contact": "9833699624",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BF",
            "City": "Mumbai Suburban",
            "Provider": "Baesic Fit",
            "Contact": "9372319339",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SK",
            "City": "Mumbai Suburban",
            "Provider": "Sujata Kumar",
            "Contact": "9833699624",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Mumbai Suburban",
            "Provider": "Sanju Sharma",
            "Contact": "9920482348",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PD",
            "City": "Mumbai Suburban",
            "Provider": "Poonam Damani",
            "Contact": "9821319103",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Mumbai Suburban",
            "Provider": "Moms kitchen",
            "Contact": "9819469739",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "YK",
            "City": "Mumbai Suburban",
            "Provider": "Yards Kitchen",
            "Contact": "9167186818",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GB",
            "City": "Mumbai Suburban",
            "Provider": "Gita Bajaj (rozi rasoii)",
            "Contact": "9920256397",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SN",
            "City": "Mumbai Suburban",
            "Provider": "Sarita Nirmal",
            "Contact": "9004899554",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AT",
            "City": "Mumbai Suburban",
            "Provider": "Ashit Thakkar",
            "Contact": "9869861700",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TG",
            "City": "Mumbai Suburban",
            "Provider": "Tadka Ghar Ka",
            "Contact": "9920177900",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Mumbai Suburban",
            "Provider": "Sreedevi",
            "Contact": "8826640770",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Mumbai Suburban",
            "Provider": "Puja/Ishita",
            "Contact": "9920603963",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KF",
            "City": "Mumbai Suburban",
            "Provider": "KA food factory",
            "Contact": "9029720606",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HM",
            "City": "Mumbai Suburban",
            "Provider": "Harshala M. Shirke",
            "Contact": "9223282268",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "V",
            "City": "Mumbai Suburban",
            "Provider": "ValueMeal",
            "Contact": "8779589327",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MN",
            "City": "Mumbai Suburban",
            "Provider": "Manish Nagpure",
            "Contact": "9930402442",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TG",
            "City": "Mumbai Suburban",
            "Provider": "Tadka Ghar Ka",
            "Contact": "9920177900",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AM",
            "City": "Mumbai Suburban",
            "Provider": "Adnan Mithaiwala",
            "Contact": "9224592048",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "L",
            "City": "Mumbai Suburban",
            "Provider": "Le...Kha",
            "Contact": "7045600201",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TT",
            "City": "Mumbai Suburban",
            "Provider": "Twisted Tiffin",
            "Contact": "9833686152",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "LK",
            "City": "Mumbai Suburban",
            "Provider": "Lyfe Kitchen",
            "Contact": "7506670666",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HK",
            "City": "Mumbai Suburban",
            "Provider": "Homechefs Kitchen",
            "Contact": "9619553666",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VM",
            "City": "Mumbai Suburban",
            "Provider": "Veena Menghrajani",
            "Contact": "9323802670",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VP",
            "City": "Mumbai Suburban",
            "Provider": "Vallari Parikh Singh / The Spicy Palate",
            "Contact": "9987583419",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SY",
            "City": "Mumbai Suburban",
            "Provider": "Sindhfully yours",
            "Contact": "7700066169",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VM",
            "City": "Mumbai Suburban",
            "Provider": "Veena Menghrajani",
            "Contact": "9323802670",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MB",
            "City": "Mumbai Suburban",
            "Provider": "Mrs. Bella Pathak",
            "Contact": "8879257481",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ND",
            "City": "Mumbai Suburban",
            "Provider": "Neeta Desai",
            "Contact": "9920152204",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TV",
            "City": "Mumbai Suburban",
            "Provider": "Tralata Vegan Guilt-free Food",
            "Contact": "9136003677",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Mumbai Suburban",
            "Provider": "Sanjana",
            "Contact": "9833108604",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "YK",
            "City": "Mumbai Suburban",
            "Provider": "Yards Kitchen",
            "Contact": "9167186818",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "B",
            "City": "Mumbai Suburban",
            "Provider": "Bhojanyan",
            "Contact": "7303202030",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Mumbai Suburban",
            "Provider": "preeti",
            "Contact": "9870082996",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AP",
            "City": "Mumbai Suburban",
            "Provider": "Ashwin Patel",
            "Contact": "9870866868",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PT",
            "City": "Mumbai Suburban",
            "Provider": "Patil Tiffin",
            "Contact": "8830345208",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MN",
            "City": "Mumbai Suburban",
            "Provider": "Manish Nagpure",
            "Contact": "9930402442",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PT",
            "City": "Mumbai Suburban",
            "Provider": "Patil Tiffin",
            "Contact": "8830345208",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "Mumbai Suburban",
            "Provider": "Kuldip",
            "Contact": "9833597061",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ZF",
            "City": "Mumbai Suburban",
            "Provider": "Zazo Foods",
            "Contact": "9967221942",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VP",
            "City": "Mumbai Suburban",
            "Provider": "Vallari Parikh Singh / The Spicy Palate",
            "Contact": "9987583419",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "H",
            "City": "Mumbai Suburban",
            "Provider": "Harpreet",
            "Contact": "9930011147",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Mumbai Suburban",
            "Provider": "Pratik",
            "Contact": "9892236959",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Mumbai Suburban",
            "Provider": "Sanjana",
            "Contact": "9833108604",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SH",
            "City": "Mumbai Suburban",
            "Provider": "Singing Home Chef",
            "Contact": "9820050465",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "H",
            "City": "Mumbai Suburban",
            "Provider": "Harpreet",
            "Contact": "9930011147",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "B",
            "City": "Mumbai Suburban",
            "Provider": "Bhojanyan",
            "Contact": "7303202030",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Mumbai Suburban",
            "Provider": "Sneha",
            "Contact": "7666581248",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KF",
            "City": "Mumbai Suburban",
            "Provider": "KA food factory",
            "Contact": "9029720606",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HC",
            "City": "Mumbai Suburban",
            "Provider": "Hetal Chheda",
            "Contact": "9930035588",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Mumbai Suburban",
            "Provider": "Maya's Kitchen",
            "Contact": "7900066371",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AV",
            "City": "Mumbai Suburban",
            "Provider": "Angad Ved Raj",
            "Contact": "9309803953",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KF",
            "City": "Mumbai Suburban",
            "Provider": "Kafrisa Food Co",
            "Contact": "8850307646",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Mumbai Suburban",
            "Provider": "Manpreet",
            "Contact": "9321838714",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Mumbai Suburban",
            "Provider": "Puja/Ishita",
            "Contact": "9920603963",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "G",
            "City": "Mumbai Suburban",
            "Provider": "Govinda's",
            "Contact": "9987786411",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TT",
            "City": "Mumbai Suburban",
            "Provider": "Twisted Tiffin",
            "Contact": "9833686152",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SD",
            "City": "Mumbai Suburban",
            "Provider": "Spicey dicey",
            "Contact": "9820093828",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "IE",
            "City": "Mumbai Suburban",
            "Provider": "Indian essence",
            "Contact": "9769604791",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Mumbai Suburban",
            "Provider": "Shilpa",
            "Contact": "9820312469",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GB",
            "City": "Mumbai Suburban",
            "Provider": "Gita Bajaj (rozi rasoii)",
            "Contact": "9920256397",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Mumbai Suburban",
            "Provider": "Asmita",
            "Contact": "9930362949",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "V",
            "City": "Mumbai Suburban",
            "Provider": "ValueMeal",
            "Contact": "8779589327",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SH",
            "City": "Mumbai Suburban",
            "Provider": "Singing Home Chef",
            "Contact": "9820050465",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "IE",
            "City": "Mumbai Suburban",
            "Provider": "Indian essence",
            "Contact": "9769604791",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NG",
            "City": "Mumbai Suburban",
            "Provider": "Nidhi Gathani",
            "Contact": "8591041098",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KB",
            "City": "Mumbai Suburban",
            "Provider": "Kiran Bhatia",
            "Contact": "9324114145",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SO",
            "City": "Mumbai Suburban",
            "Provider": "Sangeeta Organic Meals",
            "Contact": "9892462995",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AT",
            "City": "Mumbai Suburban",
            "Provider": "Ashit Thakkar",
            "Contact": "9869861700",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TV",
            "City": "Mumbai Suburban",
            "Provider": "Tralata Vegan Guilt-free Food",
            "Contact": "9136003677",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AP",
            "City": "Mumbai Suburban",
            "Provider": "Ashwin Patel",
            "Contact": "9870866868",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CH",
            "City": "Mumbai Suburban",
            "Provider": "Chandras home cooked meal",
            "Contact": "9869909435",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ND",
            "City": "Mumbai Suburban",
            "Provider": "Neeta Desai",
            "Contact": "9920152204",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KB",
            "City": "Mumbai Suburban",
            "Provider": "Kiran Bhatia",
            "Contact": "9324114145",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AP",
            "City": "Mumbai Suburban",
            "Provider": "Aiyo, Patrao",
            "Contact": "8450912371",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Mumbai Suburban",
            "Provider": "Maya's Kitchen",
            "Contact": "7900066371",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Mumbai Suburban",
            "Provider": "Asmita",
            "Contact": "9930362949",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HC",
            "City": "Mumbai Suburban",
            "Provider": "Hetal Chheda",
            "Contact": "9930035588",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SO",
            "City": "Mumbai Suburban",
            "Provider": "Sangeeta Organic Meals",
            "Contact": "9892462995",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "H",
            "City": "Mumbai Suburban",
            "Provider": "Henna",
            "Contact": "9819722679",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Mumbai Suburban",
            "Provider": "Sneha",
            "Contact": "7666581248",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KA",
            "City": "Mumbai Suburban",
            "Provider": "Kirti Ahuja",
            "Contact": "9004709007",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Kamrup Metropolitan": [
        {
            "avatar": "TK",
            "City": "Kamrup Metropolitan",
            "Provider": "Team Kata Food",
            "Contact": "6900266004",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AK",
            "City": "Kamrup Metropolitan",
            "Provider": "Anita Kitchen",
            "Contact": "8133081216",
            "Notes": "Food/meals on order. No delivery service. Rapido delivery could be option",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SJ",
            "City": "Kamrup Metropolitan",
            "Provider": "Shekhar Jajodia",
            "Contact": "9864200057",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RJ",
            "City": "Kamrup Metropolitan",
            "Provider": "Rekha Jain",
            "Contact": "8876695150",
            "Notes": "Free",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Kamrup Metropolitan",
            "Provider": "Swadisht",
            "Contact": "8472088408",
            "Notes": "Price as per menu ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Kamrup Metropolitan",
            "Provider": "Swastik",
            "Contact": "8471804188",
            "Notes": "Can provide food for 2-3 people",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KF",
            "City": "Kamrup Metropolitan",
            "Provider": "KATA Food",
            "Contact": "6900266004",
            "Notes": "Delivering hygienic, healthy, home-cooked food to anyone in isolation/quarantine free of charge.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AD",
            "City": "Kamrup Metropolitan",
            "Provider": "Anurekha Deb",
            "Contact": "9101179060",
            "Notes": "Hi, I can make decent meals (in fact, delicious sometimes) and I would love to cook and send to people who are suffering from COVID anywhere in Guwahati, as many aren\u2019t able to cook due to numerous reasons. Nutrition is our basic need and I know how testing can such times be when you are unwell and at your most vulnerable self. I want to cook and send nutritious meals which might help people suffering from COVID get proper nutrition and recover faster. I would urge you to share this amongst people who might need it, and also to share this amongst people who might want to collaborate with me in this in terms of donation/ providing transportation means for food delivery or in anything they feel they can help. People with COVID having pets in their homes can also request for meals for their furry babies. We need to take care of each other, we are a community and we need to work together while maintaining physical distance, not social. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ZP",
            "City": "Kamrup Metropolitan",
            "Provider": "Zephyr Pegu",
            "Contact": "7002047634",
            "Notes": "Will be able to provide food and groceries for people in need. But due to personal transport constraints it'll be only possible on Saturday Sundays and holidays. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RS",
            "City": "Kamrup Metropolitan",
            "Provider": "Rajgarh Shopping Corner/Silpukhuri Shopping Corner",
            "Contact": "9954700862",
            "Notes": "Grocery delivery",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "Kamrup Metropolitan",
            "Provider": "Khusi",
            "Contact": "7002606108",
            "Notes": "INR 100",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MZ",
            "City": "Kamrup Metropolitan",
            "Provider": "Mirza Zulfikar Rahman",
            "Contact": "9954218653",
            "Notes": "Grocery and General Medicine delivery only from the shop to home - around beltola, basistha and Dispur area",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RG",
            "City": "Kamrup Metropolitan",
            "Provider": "Rudra's Grocery",
            "Contact": "8638815576",
            "Notes": "Grocery delivery",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RS",
            "City": "Kamrup Metropolitan",
            "Provider": "Rajib Sonowal",
            "Contact": "8447932679",
            "Notes": "Can deliver raw material food and cooked food for people under quarantine.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TT",
            "City": "Kamrup Metropolitan",
            "Provider": "The Table Talk Cafe",
            "Contact": "9831645979",
            "Notes": "As per menu",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ZZ",
            "City": "Kamrup Metropolitan",
            "Provider": "Zoya Zaman and Jackie Zaman",
            "Contact": "8130063186",
            "Notes": "Free food for COVID patients. Pick up near Eggsotic.Menu:Monday: Rajma ChawalTuesday: Veg Khichdi & AcharWednesday: Chole & Jeera RiceThursday: Fried rice & honey potatoFriday: Ghee rice & jeera alooSaturday: Veg stroganoff with butter riceSunday: Veg stew with bun",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GD",
            "City": "Kamrup Metropolitan",
            "Provider": "Garam Dabba",
            "Contact": "8638666175",
            "Notes": "Team Garam Dabba will free deliver healthy and fresh homemade meals at nominal cost around Rukminigaon, Beltola, Survey in Guwahati.Veg thali - INR 60Non-veg thali - INR 80Free delivery",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Kamrup Metropolitan",
            "Provider": "Swadisht",
            "Contact": "8472088408",
            "Notes": "Price as per menu ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AD",
            "City": "Kamrup Metropolitan",
            "Provider": "Anurekha Deb",
            "Contact": "9101179060",
            "Notes": "Hi, I can make decent meals (in fact, delicious sometimes) and I would love to cook and send to people who are suffering from COVID anywhere in Guwahati, as many aren\u2019t able to cook due to numerous reasons. Nutrition is our basic need and I know how testing can such times be when you are unwell and at your most vulnerable self. I want to cook and send nutritious meals which might help people suffering from COVID get proper nutrition and recover faster. I would urge you to share this amongst people who might need it, and also to share this amongst people who might want to collaborate with me in this in terms of donation/ providing transportation means for food delivery or in anything they feel they can help. People with COVID having pets in their homes can also request for meals for their furry babies. We need to take care of each other, we are a community and we need to work together while maintaining physical distance, not social. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Kamrup Metropolitan",
            "Provider": "NEEVs",
            "Contact": "8472982748",
            "Notes": "Not mentioned",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FA",
            "City": "Kamrup Metropolitan",
            "Provider": "Farhana Ahmed",
            "Contact": "9319782779",
            "Notes": "Home services (food/groceries/vegetables)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Kamrup Metropolitan",
            "Provider": "Sahamarmita",
            "Contact": "9864274919",
            "Notes": "People affected by the crisis, our hearts go out to you. In these dire times, if you are unable to prepare homely, timely meals for yourself and your family, please reach out. We will deliver healthy food to wherever you are located in Guwahati @1Rs",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DB",
            "City": "Kamrup Metropolitan",
            "Provider": "Deja Brew Cafe",
            "Contact": "7002700410",
            "Notes": "We are a restaurant situated in the heart of the city. Our name is deja brew cafe and bistro. We have a good reach over people as well . Food and public reach is our forte.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AK",
            "City": "Kamrup Metropolitan",
            "Provider": "Anita Kitchen",
            "Contact": "8133081216",
            "Notes": "Food/meals on order. No delivery service. Rapido delivery could be option",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DB",
            "City": "Kamrup Metropolitan",
            "Provider": "Deja Brew Cafe",
            "Contact": "7002700410",
            "Notes": "We are a restaurant situated in the heart of the city. Our name is deja brew cafe and bistro. We have a good reach over people as well . Food and public reach is our forte.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FA",
            "City": "Kamrup Metropolitan",
            "Provider": "Farhana Ahmed",
            "Contact": "9319782779",
            "Notes": "Home services (food/groceries/vegetables)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ZP",
            "City": "Kamrup Metropolitan",
            "Provider": "Zephyr Pegu",
            "Contact": "7002047634",
            "Notes": "Will be able to provide food and groceries for people in need. But due to personal transport constraints it'll be only possible on Saturday Sundays and holidays. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PS",
            "City": "Kamrup Metropolitan",
            "Provider": "Punjabi Sabha",
            "Contact": "9864021361",
            "Notes": "Free Lunch Sewa for Covid patients (report mandatory); Book your meal a day before by 6pm. Delivery 1 pm onwards",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NL",
            "City": "Kamrup Metropolitan",
            "Provider": "Nutri Life",
            "Contact": "9366168944",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "K&",
            "City": "Kamrup Metropolitan",
            "Provider": "Kashmiri & Shruti",
            "Contact": "9971447586",
            "Notes": "Meals for people with dietary restrictions, especially open to queer/LGBTQIA folks.Also happy to run errands, buy essentials and medicines",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "EC",
            "City": "Kamrup Metropolitan",
            "Provider": "EICASA Covid Army/ Aapurti Departmental Stores",
            "Contact": "7099001300",
            "Notes": "Grocery delivery",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "K&",
            "City": "Kamrup Metropolitan",
            "Provider": "Kashmiri & Shruti",
            "Contact": "9971447586",
            "Notes": "Meals for people with dietary restrictions, especially open to queer/LGBTQIA folks.Also happy to run errands, buy essentials and medicines",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MC",
            "City": "Kamrup Metropolitan",
            "Provider": "Madhumita Choudhary",
            "Contact": "9954499507",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MZ",
            "City": "Kamrup Metropolitan",
            "Provider": "Mirza Zulfikar Rahman",
            "Contact": "9954218653",
            "Notes": "Grocery and General Medicine delivery only from the shop to home - around beltola, basistha and Dispur area",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "LC",
            "City": "Kamrup Metropolitan",
            "Provider": "Lions Club, Guwahati",
            "Contact": "9864077189",
            "Notes": "Free",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ZZ",
            "City": "Kamrup Metropolitan",
            "Provider": "Zoya Zaman and Jackie Zaman",
            "Contact": "8130063186",
            "Notes": "Free food for COVID patients. Pick up near Eggsotic.Menu:Monday: Rajma ChawalTuesday: Veg Khichdi & AcharWednesday: Chole & Jeera RiceThursday: Fried rice & honey potatoFriday: Ghee rice & jeera alooSaturday: Veg stroganoff with butter riceSunday: Veg stew with bun",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "Kamrup Metropolitan",
            "Provider": "Khusi",
            "Contact": "7002606108",
            "Notes": "INR 100",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RC",
            "City": "Kamrup Metropolitan",
            "Provider": "Rishav Changkakoti",
            "Contact": "9854055354",
            "Notes": "Home services (food/groceries/vegetables)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GD",
            "City": "Kamrup Metropolitan",
            "Provider": "Garam Dabba",
            "Contact": "8638666175",
            "Notes": "Team Garam Dabba will free deliver healthy and fresh homemade meals at nominal cost around Rukminigaon, Beltola, Survey in Guwahati.Veg thali - INR 60Non-veg thali - INR 80Free delivery",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RR",
            "City": "Kamrup Metropolitan",
            "Provider": "Relief Riders Guwahati",
            "Contact": "8638379426",
            "Notes": "Service for delivery is free, requester have to pay for the item purchased by volunteer",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MD",
            "City": "Kamrup Metropolitan",
            "Provider": "Manju Dudhoria",
            "Contact": "9954025111",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RS",
            "City": "Kamrup Metropolitan",
            "Provider": "Rajib Sonowal",
            "Contact": "8447932679",
            "Notes": "Can deliver raw material food and cooked food for people under quarantine.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KF",
            "City": "Kamrup Metropolitan",
            "Provider": "KATA Food",
            "Contact": "6900266004",
            "Notes": "Delivering hygienic, healthy, home-cooked food to anyone in isolation/quarantine free of charge.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RC",
            "City": "Kamrup Metropolitan",
            "Provider": "Rishav Changkakoti",
            "Contact": "9854055354",
            "Notes": "Home services (food/groceries/vegetables)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MD",
            "City": "Kamrup Metropolitan",
            "Provider": "Manju Dudhoria",
            "Contact": "9954025111",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "1A",
            "City": "Kamrup Metropolitan",
            "Provider": "11th Avenue",
            "Contact": "9127162231",
            "Notes": "Free",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "1A",
            "City": "Kamrup Metropolitan",
            "Provider": "11th Avenue",
            "Contact": "9127162231",
            "Notes": "Free",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RJ",
            "City": "Kamrup Metropolitan",
            "Provider": "Rekha Jain",
            "Contact": "8876695150",
            "Notes": "Free",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Kamrup Metropolitan",
            "Provider": "Sahamarmita",
            "Contact": "9864274919",
            "Notes": "People affected by the crisis, our hearts go out to you. In these dire times, if you are unable to prepare homely, timely meals for yourself and your family, please reach out. We will deliver healthy food to wherever you are located in Guwahati @1Rs",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PS",
            "City": "Kamrup Metropolitan",
            "Provider": "Punjabi Sabha",
            "Contact": "9864021361",
            "Notes": "Free Lunch Sewa for Covid patients (report mandatory); Book your meal a day before by 6pm. Delivery 1 pm onwards",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "H",
            "City": "Kamrup Metropolitan",
            "Provider": "Haharjyo",
            "Contact": "8723072216",
            "Notes": "In collaboration with Amri Helping Hands Society.Free home cooked food for COVID affected - We are delivering free home cooked food in Guwahati for those are COVID affected and living in isolation all by themselves.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AS",
            "City": "Kamrup Metropolitan",
            "Provider": "Ann Sewa Guwahati",
            "Contact": "9864091974",
            "Notes": "Free Food in Athgaon, Kumarapara and Rehabari",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NL",
            "City": "Kamrup Metropolitan",
            "Provider": "Nutri Life",
            "Contact": "9366168944",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SG",
            "City": "Kamrup Metropolitan",
            "Provider": "Suman Gupta",
            "Contact": "9706087094",
            "Notes": "Veg- 60, Non-Veg-100",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RR",
            "City": "Kamrup Metropolitan",
            "Provider": "Relief Riders Guwahati",
            "Contact": "8638379426",
            "Notes": "Service for delivery is free, requester have to pay for the item purchased by volunteer",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RG",
            "City": "Kamrup Metropolitan",
            "Provider": "Rudra's Grocery",
            "Contact": "8638815576",
            "Notes": "Grocery delivery",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RS",
            "City": "Kamrup Metropolitan",
            "Provider": "Rajgarh Shopping Corner/Silpukhuri Shopping Corner",
            "Contact": "9954700862",
            "Notes": "Grocery delivery",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Kamrup Metropolitan",
            "Provider": "Swastik",
            "Contact": "8471804188",
            "Notes": "Can provide food for 2-3 people",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "LC",
            "City": "Kamrup Metropolitan",
            "Provider": "Lions Club, Guwahati",
            "Contact": "9864077189",
            "Notes": "Free",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "EC",
            "City": "Kamrup Metropolitan",
            "Provider": "EICASA Covid Army/ Aapurti Departmental Stores",
            "Contact": "7099001300",
            "Notes": "Grocery delivery",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MC",
            "City": "Kamrup Metropolitan",
            "Provider": "Madhumita Choudhary",
            "Contact": "9954499507",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Kamrup Metropolitan",
            "Provider": "NEEVs",
            "Contact": "8472982748",
            "Notes": "Not mentioned",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SG",
            "City": "Kamrup Metropolitan",
            "Provider": "Suman Gupta",
            "Contact": "9706087094",
            "Notes": "Veg- 60, Non-Veg-100",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SJ",
            "City": "Kamrup Metropolitan",
            "Provider": "Shekhar Jajodia",
            "Contact": "9864200057",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "H",
            "City": "Kamrup Metropolitan",
            "Provider": "Haharjyo",
            "Contact": "8723072216",
            "Notes": "In collaboration with Amri Helping Hands Society.Free home cooked food for COVID affected - We are delivering free home cooked food in Guwahati for those are COVID affected and living in isolation all by themselves.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AS",
            "City": "Kamrup Metropolitan",
            "Provider": "Ann Sewa Guwahati",
            "Contact": "9864091974",
            "Notes": "Free Food in Athgaon, Kumarapara and Rehabari",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TT",
            "City": "Kamrup Metropolitan",
            "Provider": "The Table Talk Cafe",
            "Contact": "9831645979",
            "Notes": "As per menu",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Jalpaiguri": [
        {
            "avatar": "D",
            "City": "Jalpaiguri",
            "Provider": "Dipabali",
            "Contact": "8617384958",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:25:18",
            "Found Useful": "0"
        },
        {
            "avatar": "D",
            "City": "Jalpaiguri",
            "Provider": "Dipabali",
            "Contact": "8617384958",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:25:18",
            "Found Useful": "0"
        }
    ],
    "East Delhi": [
        {
            "avatar": "CM",
            "City": "East Delhi",
            "Provider": "Covid Meals",
            "Contact": "9999298091",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "East Delhi",
            "Provider": "maa ki nihari and tiffin service",
            "Contact": "9718483340",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "East Delhi",
            "Provider": "maa ki nihari and tiffin service",
            "Contact": "9718483340",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CM",
            "City": "East Delhi",
            "Provider": "Covid Meals",
            "Contact": "9999298091",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Noida": [
        {
            "avatar": "V",
            "City": "Noida",
            "Provider": "Vishal",
            "Contact": "9718761666",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "V",
            "City": "Noida",
            "Provider": "Vishal",
            "Contact": "9718761666",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "Y",
            "City": "Noida",
            "Provider": "Yash",
            "Contact": "8287142461",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SR",
            "City": "Noida",
            "Provider": "Shubh Rasoi",
            "Contact": "8600422337",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "Y",
            "City": "Noida",
            "Provider": "Yash",
            "Contact": "8287142461",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SR",
            "City": "Noida",
            "Provider": "Shubh Rasoi",
            "Contact": "8600422337",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Lucknow": [
        {
            "avatar": "PD",
            "City": "Lucknow",
            "Provider": "Punjabi delish",
            "Contact": "7860311766",
            "Notes": "FOOD SERVICE FOR PEOPLE UNDER HOME ISOLATIONVEGETARIAN THAALMENU 1 (RS 100/- PER THAAL)1)\tDAL2)\tCHAPATI (4 N0.)3)\tSEASONAL VEG4)\tSALADMENU 2 (RS 170/- PER THAAL)1)\tPANEER 2)\tDAL/CHOLA/RAJMA3)\tCHAPATI (4 NO.)4)\tSEASONAL VEG5)\tRICE6)\tRAITA7)\tSALADMINIMUM ORDER 2 THAALADVANCE  PAYMENT  ON  CONFIRMATIONGPAYPAYTM(7860311766)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NT",
            "City": "Lucknow",
            "Provider": "Naniji Tiffin Service",
            "Contact": "9670074158",
            "Notes": "4 tawa  rotis, dal, seasonal  vegetable, rice, salad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "LF",
            "City": "Lucknow",
            "Provider": "Lucknow food walks",
            "Contact": "7310071700",
            "Notes": "Dal+steam rice+seasonal vegetable dry/curry+tawa roti+salad+turmeric milk+ khada",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HK",
            "City": "Lucknow",
            "Provider": "Hot Kitchen",
            "Contact": "9335917510",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NT",
            "City": "Lucknow",
            "Provider": "Naniji Tiffin Service",
            "Contact": "9670074158",
            "Notes": "4 tawa  rotis, dal, seasonal  vegetable, rice, salad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PD",
            "City": "Lucknow",
            "Provider": "Punjabi delish",
            "Contact": "7860311766",
            "Notes": "FOOD SERVICE FOR PEOPLE UNDER HOME ISOLATIONVEGETARIAN THAALMENU 1 (RS 100/- PER THAAL)1)\tDAL2)\tCHAPATI (4 N0.)3)\tSEASONAL VEG4)\tSALADMENU 2 (RS 170/- PER THAAL)1)\tPANEER 2)\tDAL/CHOLA/RAJMA3)\tCHAPATI (4 NO.)4)\tSEASONAL VEG5)\tRICE6)\tRAITA7)\tSALADMINIMUM ORDER 2 THAALADVANCE  PAYMENT  ON  CONFIRMATIONGPAYPAYTM(7860311766)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "LF",
            "City": "Lucknow",
            "Provider": "Lucknow food walks",
            "Contact": "7310071700",
            "Notes": "Dal+steam rice+seasonal vegetable dry/curry+tawa roti+salad+turmeric milk+ khada",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HK",
            "City": "Lucknow",
            "Provider": "Hot Kitchen",
            "Contact": "9335917510",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Ahmedabad": [
        {
            "avatar": "N",
            "City": "Ahmedabad",
            "Provider": "Nutramish",
            "Contact": "8866564149",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KS",
            "City": "Ahmedabad",
            "Provider": "Kanu Shah",
            "Contact": "9377031719",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Ahmedabad",
            "Provider": "Paridhi",
            "Contact": "9558803069",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GK",
            "City": "Ahmedabad",
            "Provider": "Guru Kripa Foods",
            "Contact": "7574995200",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NB",
            "City": "Ahmedabad",
            "Provider": "Night Bite",
            "Contact": "9879360009",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DT",
            "City": "Ahmedabad",
            "Provider": "Drishtti Thakkar",
            "Contact": "9099421155",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Ahmedabad",
            "Provider": "Paridhi",
            "Contact": "9558803069",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DT",
            "City": "Ahmedabad",
            "Provider": "Drishtti Thakkar",
            "Contact": "9099421155",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VS",
            "City": "Ahmedabad",
            "Provider": "Varsha Soni",
            "Contact": "9925813163",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KS",
            "City": "Ahmedabad",
            "Provider": "Kanu Shah",
            "Contact": "9377031719",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NB",
            "City": "Ahmedabad",
            "Provider": "Night Bite",
            "Contact": "9879360009",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Ahmedabad",
            "Provider": "Nutramish",
            "Contact": "8866564149",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GK",
            "City": "Ahmedabad",
            "Provider": "Guru Kripa Foods",
            "Contact": "7574995200",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VS",
            "City": "Ahmedabad",
            "Provider": "Varsha Soni",
            "Contact": "9925813163",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "All": [
        {
            "avatar": "L",
            "City": "All",
            "Provider": "Linda",
            "Contact": "9890072074",
            "Notes": "Tiffin system and special orders too. Veg meals at Rs 150/ Non-veg meals Rs 200",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TP",
            "City": "All",
            "Provider": "The Pakwan",
            "Contact": "9881887584",
            "Notes": "Only Dinner ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AK",
            "City": "All",
            "Provider": "Adarsh Kumar",
            "Contact": "9370718374",
            "Notes": "Delivery has begun only for areas around Zuarinagar upto Vishal Megamart",
            "Verified": "Verified",
            "Last Verified": " 15/05/2021 13:00:00",
            "Found Useful": "0"
        },
        {
            "avatar": "PD",
            "City": "All",
            "Provider": "Prottush De",
            "Contact": "9971419632",
            "Notes": "Veg meal, Rs 120, Non-veg meal Rs 150, full menu available. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "C",
            "City": "All",
            "Provider": "Chetan",
            "Contact": "7020256884",
            "Notes": "120 per meal in this area, meal cost will vary for farther places One day in advance notification",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CO",
            "City": "All",
            "Provider": "Cafe off the grid",
            "Contact": "9823637178",
            "Notes": "Delivery services have begun, limited deliveries based on the availability of delivery partnerOrders can also be placed through Swiggy/Zomato",
            "Verified": "Verified",
            "Last Verified": " 15/05/2021 11:00:00",
            "Found Useful": "0"
        },
        {
            "avatar": "CD",
            "City": "All",
            "Provider": "Cafe Dawn To Dusk",
            "Contact": "9970822579",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "All",
            "Provider": "Suman Sharma/Ajay Lamba",
            "Contact": "8956088624",
            "Notes": "Free food",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "T(",
            "City": "All",
            "Provider": "Tapri (Sarvesh)",
            "Contact": "7264989779",
            "Notes": "Provide complete meal (breakfast+lunch+dinner) for Covid patients with doorstep delivery. Free meals for people who cannot afford, but they will decide it",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BD",
            "City": "All",
            "Provider": "B Dsouza",
            "Contact": "9326927922",
            "Notes": "Will share the rates with customers directly",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "V",
            "City": "All",
            "Provider": "Vicky",
            "Contact": "7721916878",
            "Notes": "Tiffin, cost Rs 100",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GB",
            "City": "All",
            "Provider": "Golu Bhai",
            "Contact": "7499141230",
            "Notes": "Lunchboxes only, 12:00 noon to 3 pm",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AK",
            "City": "All",
            "Provider": "Adarsh Kumar",
            "Contact": "9370718374",
            "Notes": "Delivery has begun only for areas around Zuarinagar upto Vishal Megamart",
            "Verified": "Verified",
            "Last Verified": " 15/05/2021 13:00:00",
            "Found Useful": "0"
        },
        {
            "avatar": "NN",
            "City": "All",
            "Provider": "Namita Nayudu",
            "Contact": "7972156741",
            "Notes": "Please only Whatsapp the second number Veg 3 roti, rice, dal,sabji, salad Rs 150 and non veg 3 roti, rice, chiken/ fish or egg curry salad. For fish curry you need to order in advance",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BS",
            "City": "All",
            "Provider": "Bela Shah",
            "Contact": "9423886944",
            "Notes": "Free meals for Covid patients. Whatsapp 2 hours in advance so she can prepare the food",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "All",
            "Provider": "Mansoor",
            "Contact": "9765453741",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BD",
            "City": "All",
            "Provider": "B Dsouza",
            "Contact": "9326927922",
            "Notes": "Will share the rates with customers directly",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GS",
            "City": "All",
            "Provider": "Gargi Sagarkar ",
            "Contact": "7720051350",
            "Notes": "Veg - 180 Fish Curry - 200 Chicken - 230",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SP",
            "City": "All",
            "Provider": "Sangeeta Parekh",
            "Contact": "9823086802",
            "Notes": "Delivery has to be self arranged. Veg Lunch Thalis only (with dal, rice, 3 rotis, sabji, salad) available from 1 pm to 1:30 pm.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "All",
            "Provider": "Shree Sai Annadan Seva Kendra",
            "Contact": "9850930597",
            "Notes": "For covid home isolation patients in Pilerne-Marra, Sangolda, Reis Magos, Saligao, Guruim and Nerul areas only. If you are a resident of these areas, please get in touch with nearest panchayat member to place ORder. Please place order 1 day in advance. Meals for lunch and dinner. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RS",
            "City": "All",
            "Provider": "Rian Stores",
            "Contact": "000",
            "Notes": "Rian store provides same day delivery of fresh fruits and vegetables with good offers and 24*7 service in Delgi, Noida and Ghaziabad",
            "Verified": "Verified",
            "Last Verified": " 2021-05-14 10:50:00",
            "Found Useful": "0"
        },
        {
            "avatar": "RS",
            "City": "All",
            "Provider": "Rian Stores",
            "Contact": "000",
            "Notes": "Rian store provides same day delivery of fresh fruits and vegetables with good offers and 24*7 service in Delgi, Noida and Ghaziabad",
            "Verified": "Verified",
            "Last Verified": " 2021-05-14 10:50:00",
            "Found Useful": "0"
        },
        {
            "avatar": "HF",
            "City": "All",
            "Provider": "Hartek foundation",
            "Contact": "9814006980",
            "Notes": "LANGAR SEWA at your doorsteps for Covid Positive Patients and Senior Citizens in tricity of Chandigarh,(Tricity) Contact HARTEK foundation helpline numbers at  +91-6284-55-2070/ +91 98140 06980 SEWA till our last breath! @hartekgroup",
            "Verified": "Verified",
            "Last Verified": " 2021-05-10 13:14:13",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "All",
            "Provider": "free food facility",
            "Contact": "7392975957",
            "Notes": "Dr. Akhilesh das gupta foundation provides free food facility to covid families required Name and mobile number, full address, Covid positive reportat purankila, sadar, narhi",
            "Verified": "Verified",
            "Last Verified": " 2021-05-12 20:35:00",
            "Found Useful": "0"
        },
        {
            "avatar": "HF",
            "City": "All",
            "Provider": "Hartek foundation",
            "Contact": "9814006980",
            "Notes": "LANGAR SEWA at your doorsteps for Covid Positive Patients and Senior Citizens in tricity of Chandigarh,(Tricity) Contact HARTEK foundation helpline numbers at  +91-6284-55-2070/ +91 98140 06980 SEWA till our last breath! @hartekgroup",
            "Verified": "Verified",
            "Last Verified": " 2021-05-10 13:14:13",
            "Found Useful": "0"
        },
        {
            "avatar": "RO",
            "City": "All",
            "Provider": "Rohit oberoi",
            "Contact": "9988111208",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-14 11:59:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "All",
            "Provider": "free food facility",
            "Contact": "7392975957",
            "Notes": "Dr. Akhilesh das gupta foundation provides free food facility to covid families required Name and mobile number, full address, Covid positive reportat purankila, sadar, narhi",
            "Verified": "Verified",
            "Last Verified": " 2021-05-12 20:35:00",
            "Found Useful": "0"
        },
        {
            "avatar": "RO",
            "City": "All",
            "Provider": "Rohit oberoi",
            "Contact": "9988111208",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-14 11:59:00",
            "Found Useful": "0"
        },
        {
            "avatar": "RS",
            "City": "All",
            "Provider": "Rian Stores",
            "Contact": "000",
            "Notes": "Rian store provides same day delivery of fresh fruits and vegetables with good offers and 24*7 service in Delgi, Noida and Ghaziabad",
            "Verified": "Verified",
            "Last Verified": " 2021-05-14 10:50:00",
            "Found Useful": "0"
        },
        {
            "avatar": "RS",
            "City": "All",
            "Provider": "Rian Stores",
            "Contact": "000",
            "Notes": "Rian store provides same day delivery of fresh fruits and vegetables with good offers and 24*7 service in Delgi, Noida and Ghaziabad",
            "Verified": "Verified",
            "Last Verified": " 2021-05-14 10:50:00",
            "Found Useful": "0"
        },
        {
            "avatar": "RS",
            "City": "All",
            "Provider": "Rashan sewa",
            "Contact": "9811100921",
            "Notes": "Rashan sewa If any of your worker/ maid Or any known person, who at present does not have any source of income and requires rashan for his/her survival. Anywhere In india callbetween 12:30 PM to 7 pm",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 12:44:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "All",
            "Provider": "Free food home delivery",
            "Contact": "09716998233",
            "Notes": "DelhiFREE FOOD service for covid +ve patientsHOME DELIVERYContact- 09716998233Note- they provide nutritious food for covid patients all over dwarka. Call and book your plate directly.Verified 6:26 pm 13may",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 12:56:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "All",
            "Provider": "Free food home delivery",
            "Contact": "09716998233",
            "Notes": "DelhiFREE FOOD service for covid +ve patientsHOME DELIVERYContact- 09716998233Note- they provide nutritious food for covid patients all over dwarka. Call and book your plate directly.Verified 6:26 pm 13may",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 12:56:00",
            "Found Useful": "0"
        },
        {
            "avatar": "RS",
            "City": "All",
            "Provider": "Rashan sewa",
            "Contact": "9811100921",
            "Notes": "Rashan sewa If any of your worker/ maid Or any known person, who at present does not have any source of income and requires rashan for his/her survival. Anywhere In india callbetween 12:30 PM to 7 pm",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 12:44:00",
            "Found Useful": "0"
        }
    ],
    "Bengaluru (Bangalore) Urban": [
        {
            "avatar": "CK",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Chetan Kini",
            "Contact": "9717141691",
            "Notes": "chethankini1@hotmail.comchethankini1",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MC",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Mission Chai",
            "Contact": "9448385243",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Pranav",
            "Contact": "9987889799",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Anushree",
            "Contact": "9538799132",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SA",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Saloni aunty",
            "Contact": "9611800456",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VH",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Vishal Himatsinghka",
            "Contact": "8017011554",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CK",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Cheran Kumer",
            "Contact": "7483541562",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "OB",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Oota Box",
            "Contact": "8030636310",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Sudeshna",
            "Contact": "9007066563",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HC",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Home cooked food",
            "Contact": "9321022986",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Pranav",
            "Contact": "9987889799",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Sudeshna",
            "Contact": "9007066563",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Sudeshna",
            "Contact": "9007066563",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HS",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Hansika Subramanian",
            "Contact": "9167347028",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "V",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Vandana",
            "Contact": "7899549696",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Manjunath",
            "Contact": "9591109600",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CK",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Chetan Kini",
            "Contact": "9717141691",
            "Notes": "chethankini1@hotmail.comchethankini1",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Anushree",
            "Contact": "9538799132",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AH",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Ajit's Home cooked meal",
            "Contact": "7022354874",
            "Notes": "Ajit is providing meals out of his home for Covid affected patients and families. Order through the swiggy link.",
            "Verified": "Verified",
            "Last Verified": " 2021-05-22 01:26:00",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Sneha",
            "Contact": "9986529312",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Swaad",
            "Contact": "9731794455",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CK",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Cheran Kumer",
            "Contact": "7483541562",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CK",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Cheran Kumer",
            "Contact": "7483541562",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HC",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Home cooked food",
            "Contact": "9321022986",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Sneha",
            "Contact": "9986529312",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VH",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Vishal Himatsinghka",
            "Contact": "8017011554",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Anushree",
            "Contact": "9538799132",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "OB",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Oota Box",
            "Contact": "8030636310",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Mitushree",
            "Contact": "9353153914",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SI",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "South Indian Meal",
            "Contact": "7550187681",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Shuchi",
            "Contact": "7619403505",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "B",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Bharavi",
            "Contact": "9886334291",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MC",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Mission Chai",
            "Contact": "9448385243",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "B",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Bharavi",
            "Contact": "9886334291",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RO",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Recipe of Hope",
            "Contact": "8088407064",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Sunita",
            "Contact": "8310729246",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SI",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "South Indian Meal",
            "Contact": "7550187681",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HS",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Hansika Subramanian",
            "Contact": "9167347028",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MB",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Masala Box",
            "Contact": "8061972252",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Sarvesh",
            "Contact": "9611744411",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AH",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Ajit's Home cooked meal",
            "Contact": "7022354874",
            "Notes": "Ajit is providing meals out of his home for Covid affected patients and families. Order through the swiggy link.",
            "Verified": "Verified",
            "Last Verified": " 2021-05-22 01:26:00",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Sarvesh",
            "Contact": "9611744411",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KD",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Krupa Dave",
            "Contact": "9916657432",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Shuchi",
            "Contact": "7619403505",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PK",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Peters kitch",
            "Contact": "9819634834",
            "Notes": "10",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "V",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Vandana",
            "Contact": "7899549696",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Sunita",
            "Contact": "8310729246",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PK",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Peters kitch",
            "Contact": "9819634834",
            "Notes": "10",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MC",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Mission Chai",
            "Contact": "9448385243",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CK",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Chetan Kini",
            "Contact": "9717141691",
            "Notes": "chethankini1@hotmail.comchethankini1",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SA",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Saloni aunty",
            "Contact": "9611800456",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Manjunath",
            "Contact": "9591109600",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Mitushree",
            "Contact": "9353153914",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "OP",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Odisha Puja Committee",
            "Contact": "8179748086",
            "Notes": "Homemade food for people under Home Isolation",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 14:00:00",
            "Found Useful": "0"
        },
        {
            "avatar": "OP",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Odisha Puja Committee",
            "Contact": "8179748086",
            "Notes": "Homemade food for people under Home Isolation",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 14:00:00",
            "Found Useful": "0"
        },
        {
            "avatar": "RO",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Recipe of Hope",
            "Contact": "8088407064",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SA",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Saloni aunty",
            "Contact": "9611800456",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "OB",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Oota Box",
            "Contact": "8030636310",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KD",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Krupa Dave",
            "Contact": "9916657432",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HC",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Home cooked food",
            "Contact": "9321022986",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Pranav",
            "Contact": "9987889799",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VH",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Vishal Himatsinghka",
            "Contact": "8017011554",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Swaad",
            "Contact": "9731794455",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MB",
            "City": "Bengaluru (Bangalore) Urban",
            "Provider": "Masala Box",
            "Contact": "8061972252",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Pune": [
        {
            "avatar": "SS",
            "City": "Pune",
            "Provider": "Saee Samarth",
            "Contact": "8446366975",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Pune",
            "Provider": "Sangita Shah",
            "Contact": "9822003930",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Pune",
            "Provider": "Anju",
            "Contact": "9890773956",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SK",
            "City": "Pune",
            "Provider": "Shilpa Kamthe",
            "Contact": "9307733920",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PP",
            "City": "Pune",
            "Provider": "Priya Padulkar",
            "Contact": "9370501710",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BA",
            "City": "Pune",
            "Provider": "Bhavin Anajwala",
            "Contact": "9028603512",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Pune",
            "Provider": "Pavithra",
            "Contact": "7249291157",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SA",
            "City": "Pune",
            "Provider": "sanjana Aggarwal",
            "Contact": "9850507762",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KT",
            "City": "Pune",
            "Provider": "Kiran Thapa",
            "Contact": "9975805459",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PA",
            "City": "Pune",
            "Provider": "Prachi Avinash Shasane",
            "Contact": "8600206472",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SA",
            "City": "Pune",
            "Provider": "shweta Agarwal",
            "Contact": "7757885147",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "R",
            "City": "Pune",
            "Provider": "Rupali",
            "Contact": "8446601057",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SB",
            "City": "Pune",
            "Provider": "Shraddha bhabhad",
            "Contact": "8459886893",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RP",
            "City": "Pune",
            "Provider": "Rohini Pandit",
            "Contact": "8788409717",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TF",
            "City": "Pune",
            "Provider": "The food Boxx",
            "Contact": "9284948896",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SA",
            "City": "Pune",
            "Provider": "shweta Agarwal",
            "Contact": "7757885147",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AT",
            "City": "Pune",
            "Provider": "Annapurna Tiffin services",
            "Contact": "7414936372",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SP",
            "City": "Pune",
            "Provider": "Shreya Pandit",
            "Contact": "8788636339",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GF",
            "City": "Pune",
            "Provider": "Gaurie's foodcraft",
            "Contact": "7888001715",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AP",
            "City": "Pune",
            "Provider": "Ashutosh Parvate",
            "Contact": "8007359266",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RG",
            "City": "Pune",
            "Provider": "Rashi Gupta",
            "Contact": "7755915811",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NV",
            "City": "Pune",
            "Provider": "nishant Vadalkar",
            "Contact": "9967214664",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TC",
            "City": "Pune",
            "Provider": "The Curious Child",
            "Contact": "9892415762",
            "Notes": "Vegetarian meal for covid patients for free (Manjari, Keshavnagar, Magarpatta Only)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TW",
            "City": "Pune",
            "Provider": "Tippy wahan",
            "Contact": "9850747422",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "YP",
            "City": "Pune",
            "Provider": "Yashashree Patkar",
            "Contact": "7499695965",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GS",
            "City": "Pune",
            "Provider": "Garima Singh",
            "Contact": "9050410863",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Pune",
            "Provider": "Pavithra",
            "Contact": "7249291157",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HL",
            "City": "Pune",
            "Provider": "Home Love",
            "Contact": "9730444882",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TP",
            "City": "Pune",
            "Provider": "Tanpure Pratibha",
            "Contact": "7030353717",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AJ",
            "City": "Pune",
            "Provider": "Apeksha Jain",
            "Contact": "9325633650",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PG",
            "City": "Pune",
            "Provider": "Pooja Ghadge",
            "Contact": "9970157377",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MA",
            "City": "Pune",
            "Provider": "Megha Agrawal",
            "Contact": "9665499888",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SJ",
            "City": "Pune",
            "Provider": "Sai joshi",
            "Contact": "8657081511",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PB",
            "City": "Pune",
            "Provider": "Purvi Bhanushali",
            "Contact": "9769896690",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SP",
            "City": "Pune",
            "Provider": "Shreya Pandit",
            "Contact": "8788636339",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SK",
            "City": "Pune",
            "Provider": "Shilpa Kamthe",
            "Contact": "9307733920",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CC",
            "City": "Pune",
            "Provider": "Chetan chauhan",
            "Contact": "7385985252",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VS",
            "City": "Pune",
            "Provider": "Vivek Samudra",
            "Contact": "9822042836",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SA",
            "City": "Pune",
            "Provider": "sanjana Aggarwal",
            "Contact": "9850507762",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MM",
            "City": "Pune",
            "Provider": "Madhavi Motey",
            "Contact": "9850924292",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KB",
            "City": "Pune",
            "Provider": "Kavita Bamb",
            "Contact": "9881330988",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PB",
            "City": "Pune",
            "Provider": "Purvi Bhanushali",
            "Contact": "9769896690",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "H",
            "City": "Pune",
            "Provider": "HappyKitchen",
            "Contact": "9599448269",
            "Notes": "Simple and yummy home food made with loads of love \u2764\ufe0f",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NJ",
            "City": "Pune",
            "Provider": "Nidhi Jain",
            "Contact": "9960928795",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NC",
            "City": "Pune",
            "Provider": "Nidhi Chadha",
            "Contact": "9822864361",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Pune",
            "Provider": "Anju",
            "Contact": "9890773956",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Pune",
            "Provider": "Anju",
            "Contact": "9890773956",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PP",
            "City": "Pune",
            "Provider": "Priya Padulkar",
            "Contact": "9370501710",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SJ",
            "City": "Pune",
            "Provider": "Sneha Jain",
            "Contact": "9833441123",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KG",
            "City": "Pune",
            "Provider": "Komal gupta",
            "Contact": "8076840909",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PD",
            "City": "Pune",
            "Provider": "Payal Devada",
            "Contact": "7840997941",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KG",
            "City": "Pune",
            "Provider": "Komal gupta",
            "Contact": "8076840909",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Pune",
            "Provider": "Sangita Shah",
            "Contact": "9822003930",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PL",
            "City": "Pune",
            "Provider": "Payal Lohade",
            "Contact": "9503019840",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RR",
            "City": "Pune",
            "Provider": "Rina Ramani",
            "Contact": "8007330005",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MM",
            "City": "Pune",
            "Provider": "Madhavi Motey",
            "Contact": "9850924292",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GF",
            "City": "Pune",
            "Provider": "Gaurie's foodcraft",
            "Contact": "7888001715",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BA",
            "City": "Pune",
            "Provider": "Bhavin Anajwala",
            "Contact": "9028603512",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TM",
            "City": "Pune",
            "Provider": "the Mindful cafe",
            "Contact": "9665083355",
            "Notes": "the cafe is itself a healthy eating option we cook with love and with all precautions. No preservatives been used in cafe. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RG",
            "City": "Pune",
            "Provider": "Rashi Gupta",
            "Contact": "7755915811",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SA",
            "City": "Pune",
            "Provider": "sanjana Aggarwal",
            "Contact": "9850507762",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "YP",
            "City": "Pune",
            "Provider": "Yashashree Patkar",
            "Contact": "7499695965",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AS",
            "City": "Pune",
            "Provider": "Arti Singhal",
            "Contact": "9730278885",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Pune",
            "Provider": "Sangita Shah",
            "Contact": "9822003930",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KT",
            "City": "Pune",
            "Provider": "Kiran Thapa",
            "Contact": "9975805459",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CH",
            "City": "Pune",
            "Provider": "Chiya's Home Gourmet",
            "Contact": "8600091723",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GU",
            "City": "Pune",
            "Provider": "Gauri Upganlawar",
            "Contact": "8888463990",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PD",
            "City": "Pune",
            "Provider": "Payal Devada",
            "Contact": "7840997941",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AT",
            "City": "Pune",
            "Provider": "Annapurna Tiffin services",
            "Contact": "7414936372",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TM",
            "City": "Pune",
            "Provider": "the Mindful cafe",
            "Contact": "9665083355",
            "Notes": "the cafe is itself a healthy eating option we cook with love and with all precautions. No preservatives been used in cafe. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CF",
            "City": "Pune",
            "Provider": "Cafe food anf fit",
            "Contact": "7066388503",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SK",
            "City": "Pune",
            "Provider": "Shilpa Kamthe",
            "Contact": "9307733920",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CF",
            "City": "Pune",
            "Provider": "Cafe food anf fit",
            "Contact": "7066388503",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "R",
            "City": "Pune",
            "Provider": "Rupali",
            "Contact": "8446601057",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PP",
            "City": "Pune",
            "Provider": "Priya Padulkar",
            "Contact": "9370501710",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Pune",
            "Provider": "Saee Samarth",
            "Contact": "8446366975",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NJ",
            "City": "Pune",
            "Provider": "Nidhi Jain",
            "Contact": "9960928795",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RR",
            "City": "Pune",
            "Provider": "Rina Ramani",
            "Contact": "8007330005",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AC",
            "City": "Pune",
            "Provider": "Ashwini chaphale",
            "Contact": "9923316618",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HL",
            "City": "Pune",
            "Provider": "Home Love",
            "Contact": "9730444882",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AC",
            "City": "Pune",
            "Provider": "Ashwini chaphale",
            "Contact": "9923316618",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BS",
            "City": "Pune",
            "Provider": "Bhavana Shukla",
            "Contact": "7741904392",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SJ",
            "City": "Pune",
            "Provider": "Sneha Jain",
            "Contact": "9833441123",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NS",
            "City": "Pune",
            "Provider": "Neha suryavanshi",
            "Contact": "7770025531",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GU",
            "City": "Pune",
            "Provider": "Gauri Upganlawar",
            "Contact": "8888463990",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KT",
            "City": "Pune",
            "Provider": "Kiran Thapa",
            "Contact": "9975805459",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NC",
            "City": "Pune",
            "Provider": "Nidhi Chadha",
            "Contact": "9822864361",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PL",
            "City": "Pune",
            "Provider": "Payal Lohade",
            "Contact": "9503019840",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RP",
            "City": "Pune",
            "Provider": "Rohini Pandit",
            "Contact": "8788409717",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PM",
            "City": "Pune",
            "Provider": "Pankaja Mehta",
            "Contact": "9881739887",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PM",
            "City": "Pune",
            "Provider": "Pankaja Mehta",
            "Contact": "9881739887",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CH",
            "City": "Pune",
            "Provider": "Chiya's Home Gourmet",
            "Contact": "8600091723",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "H",
            "City": "Pune",
            "Provider": "HappyKitchen",
            "Contact": "9599448269",
            "Notes": "Simple and yummy home food made with loads of love \u2764\ufe0f",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GS",
            "City": "Pune",
            "Provider": "Garima Singh",
            "Contact": "9050410863",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PG",
            "City": "Pune",
            "Provider": "Pooja Ghadge",
            "Contact": "9970157377",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MA",
            "City": "Pune",
            "Provider": "Megha Agrawal",
            "Contact": "9665499888",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DB",
            "City": "Pune",
            "Provider": "Devesh Bhatia",
            "Contact": "7447450011",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SA",
            "City": "Pune",
            "Provider": "Shikha Agarwal",
            "Contact": "7498199204",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TF",
            "City": "Pune",
            "Provider": "The food Boxx",
            "Contact": "9284948896",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "Pune",
            "Provider": "Kavita",
            "Contact": "8626034863",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "Pune",
            "Provider": "Kavita",
            "Contact": "8626034863",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AS",
            "City": "Pune",
            "Provider": "Arti Singhal",
            "Contact": "9730278885",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BS",
            "City": "Pune",
            "Provider": "Bhavana Shukla",
            "Contact": "7741904392",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CC",
            "City": "Pune",
            "Provider": "Chetan chauhan",
            "Contact": "7385985252",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TP",
            "City": "Pune",
            "Provider": "Tanpure Pratibha",
            "Contact": "7030353717",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Pune",
            "Provider": "Saee Samarth",
            "Contact": "8446366975",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RD",
            "City": "Pune",
            "Provider": "Reena Dashore",
            "Contact": "9970964464",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DB",
            "City": "Pune",
            "Provider": "Devesh Bhatia",
            "Contact": "7447450011",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KB",
            "City": "Pune",
            "Provider": "Kavita Bamb",
            "Contact": "9881330988",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PA",
            "City": "Pune",
            "Provider": "Prachi Avinash Shasane",
            "Contact": "8600206472",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NS",
            "City": "Pune",
            "Provider": "Neha suryavanshi",
            "Contact": "7770025531",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SJ",
            "City": "Pune",
            "Provider": "Sai joshi",
            "Contact": "8657081511",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SK",
            "City": "Pune",
            "Provider": "SONUT2 KI RASOI",
            "Contact": "9175230863",
            "Notes": "quality and delicious food on your door step",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TW",
            "City": "Pune",
            "Provider": "Tippy wahan",
            "Contact": "9850747422",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AP",
            "City": "Pune",
            "Provider": "Ashutosh Parvate",
            "Contact": "8007359266",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VS",
            "City": "Pune",
            "Provider": "Vivek Samudra",
            "Contact": "9822042836",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SK",
            "City": "Pune",
            "Provider": "Shalmali Kulkarni",
            "Contact": "9822879312",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NV",
            "City": "Pune",
            "Provider": "nishant Vadalkar",
            "Contact": "9967214664",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AJ",
            "City": "Pune",
            "Provider": "Apeksha Jain",
            "Contact": "9325633650",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RD",
            "City": "Pune",
            "Provider": "Reena Dashore",
            "Contact": "9970964464",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TP",
            "City": "Pune",
            "Provider": "Tejal Patil",
            "Contact": "7414912446",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SK",
            "City": "Pune",
            "Provider": "Shalmali Kulkarni",
            "Contact": "9822879312",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SB",
            "City": "Pune",
            "Provider": "Shraddha bhabhad",
            "Contact": "8459886893",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SA",
            "City": "Pune",
            "Provider": "Shikha Agarwal",
            "Contact": "7498199204",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Pune",
            "Provider": "Pavithra",
            "Contact": "7249291157",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BA",
            "City": "Pune",
            "Provider": "Bhavin Anajwala",
            "Contact": "9028603512",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SK",
            "City": "Pune",
            "Provider": "SONUT2 KI RASOI",
            "Contact": "9175230863",
            "Notes": "quality and delicious food on your door step",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TC",
            "City": "Pune",
            "Provider": "The Curious Child",
            "Contact": "9892415762",
            "Notes": "Vegetarian meal for covid patients for free (Manjari, Keshavnagar, Magarpatta Only)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TP",
            "City": "Pune",
            "Provider": "Tejal Patil",
            "Contact": "7414912446",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Mumbai City": [
        {
            "avatar": "PJ",
            "City": "Mumbai City",
            "Provider": "Priya Jham",
            "Contact": "9920795749",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Mumbai City",
            "Provider": "Namrata",
            "Contact": "7506056804",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SK",
            "City": "Mumbai City",
            "Provider": "Saroj's Kitchen",
            "Contact": "9819136333",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SD",
            "City": "Mumbai City",
            "Provider": "Sos deliver kitChen",
            "Contact": "9773355505",
            "Notes": "Meal box with 4 roti, one sabji rice daal and papad ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "B",
            "City": "Mumbai City",
            "Provider": "Bhojanyan",
            "Contact": "8591375835",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ST",
            "City": "Mumbai City",
            "Provider": "shreeji tiffins",
            "Contact": "9930737357",
            "Notes": "Delicious and simple home cooked gujarati food. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CH",
            "City": "Mumbai City",
            "Provider": "Chandras home cooked meal",
            "Contact": "9869909435",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CH",
            "City": "Mumbai City",
            "Provider": "Chandras home cooked meal",
            "Contact": "9869909435",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CH",
            "City": "Mumbai City",
            "Provider": "Chandras home cooked meal",
            "Contact": "9869909435",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MT",
            "City": "Mumbai City",
            "Provider": "Mumma's Tiffin",
            "Contact": "8169245321",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DT",
            "City": "Mumbai City",
            "Provider": "Dadis Theple",
            "Contact": "9930077216",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "LS",
            "City": "Mumbai City",
            "Provider": "Leela Shirgaonkar",
            "Contact": "9699454895",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NB",
            "City": "Mumbai City",
            "Provider": "Nayaab Biryani",
            "Contact": "7777001057",
            "Notes": "Veg:Rice (Jeera/ Steamed)Dry vegetable Dal Tadka/ Dal FryChapati (2 Pcs)SaladNon Veg:Rice (Jeera/ Steamed)Chicken thick gravy or dryDal Tadka/ Dal FryChapati (2 Pcs)Salad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PF",
            "City": "Mumbai City",
            "Provider": "Pratibha foods",
            "Contact": "9730880017",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HC",
            "City": "Mumbai City",
            "Provider": "Hetal Chheda",
            "Contact": "9930035588",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Mumbai City",
            "Provider": "Namrata",
            "Contact": "7506056804",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ML",
            "City": "Mumbai City",
            "Provider": "MinakshiMy Little Kitchen",
            "Contact": "9820573244",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TK",
            "City": "Mumbai City",
            "Provider": "The kitchen voyage",
            "Contact": "8850379443",
            "Notes": "Veg thali, Non veg thali, Dal Rice, Dal Khichdi, Chicken rice with raita, Chicken curry with rice or any other food on request ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PS",
            "City": "Mumbai City",
            "Provider": "Purple Shell",
            "Contact": "7045833283",
            "Notes": "Vegetarian food. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CC",
            "City": "Mumbai City",
            "Provider": "Calorie care",
            "Contact": "8080089089",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VT",
            "City": "Mumbai City",
            "Provider": "Vrindawan Tiffin",
            "Contact": "9323105063",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GB",
            "City": "Mumbai City",
            "Provider": "Grandmas Barni",
            "Contact": "9372805674",
            "Notes": "Home-cooked healthy vegetarian food, made with utmost love and hygiene. Enjoy a fresh, tasty, and wholesome meal. You can subscribe as well as place single orders. We take dietary / customization requests.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VT",
            "City": "Mumbai City",
            "Provider": "Vrindawan Tiffin",
            "Contact": "9323105063",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ST",
            "City": "Mumbai City",
            "Provider": "shreeji tiffins",
            "Contact": "9930737357",
            "Notes": "Delicious and simple home cooked gujarati food. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TT",
            "City": "Mumbai City",
            "Provider": "The Theatre Platter",
            "Contact": "9819051343",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MC",
            "City": "Mumbai City",
            "Provider": "MASALA CANTEEN",
            "Contact": "7304046666",
            "Notes": "Meal boxes with healthy meal and variety of options ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HP",
            "City": "Mumbai City",
            "Provider": "Harsha Punjabi",
            "Contact": "9820740232",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Mumbai City",
            "Provider": "Soulkadhi",
            "Contact": "8591261127",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ML",
            "City": "Mumbai City",
            "Provider": "MinakshiMy Little Kitchen",
            "Contact": "9820573244",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NB",
            "City": "Mumbai City",
            "Provider": "Nayaab Biryani",
            "Contact": "7777001057",
            "Notes": "Veg:Rice (Jeera/ Steamed)Dry vegetable Dal Tadka/ Dal FryChapati (2 Pcs)SaladNon Veg:Rice (Jeera/ Steamed)Chicken thick gravy or dryDal Tadka/ Dal FryChapati (2 Pcs)Salad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PJ",
            "City": "Mumbai City",
            "Provider": "Priya Jham",
            "Contact": "9920795749",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SD",
            "City": "Mumbai City",
            "Provider": "Sos deliver kitChen",
            "Contact": "9773355505",
            "Notes": "Meal box with 4 roti, one sabji rice daal and papad ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Mumbai City",
            "Provider": "Soulkadhi",
            "Contact": "8591261127",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "B",
            "City": "Mumbai City",
            "Provider": "Bhojanyan",
            "Contact": "8591375835",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "B",
            "City": "Mumbai City",
            "Provider": "Bhojanyan",
            "Contact": "8591375835",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TT",
            "City": "Mumbai City",
            "Provider": "The Theatre Platter",
            "Contact": "9819051343",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PS",
            "City": "Mumbai City",
            "Provider": "Purple Shell",
            "Contact": "7045833283",
            "Notes": "Vegetarian food. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TS",
            "City": "Mumbai City",
            "Provider": "Terjani Samant",
            "Contact": "9913501593",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Mumbai City",
            "Provider": "Namrata",
            "Contact": "7506056804",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BF",
            "City": "Mumbai City",
            "Provider": "Bhargavs Food Junction",
            "Contact": "9082273746",
            "Notes": "Dal Chawal Sabji 5Roti  7Salad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TE",
            "City": "Mumbai City",
            "Provider": "Tiffin Express",
            "Contact": "9819815088",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DT",
            "City": "Mumbai City",
            "Provider": "Dadis Theple",
            "Contact": "9930077216",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "J",
            "City": "Mumbai City",
            "Provider": "Jayu  Ketki",
            "Contact": "7506357959",
            "Notes": "Home prepped meal by me and my mom. The food is mild, heathy and tasty",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TD",
            "City": "Mumbai City",
            "Provider": "The Dhaba Boy",
            "Contact": "9820840683",
            "Notes": "The Meal in the tiffin will be consists of 4 chapatis, Sabji, Dal / Kadhi , rice ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HP",
            "City": "Mumbai City",
            "Provider": "Harsha Punjabi",
            "Contact": "9820740232",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "H",
            "City": "Mumbai City",
            "Provider": "HomeSome",
            "Contact": "9920377978",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SD",
            "City": "Mumbai City",
            "Provider": "Sos deliver kitChen",
            "Contact": "9773355505",
            "Notes": "Meal box with 4 roti, one sabji rice daal and papad ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "H",
            "City": "Mumbai City",
            "Provider": "HomeSome",
            "Contact": "9920377978",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SK",
            "City": "Mumbai City",
            "Provider": "Saroj's Kitchen",
            "Contact": "9819136333",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TK",
            "City": "Mumbai City",
            "Provider": "The kitchen voyage",
            "Contact": "8850379443",
            "Notes": "Veg thali, Non veg thali, Dal Rice, Dal Khichdi, Chicken rice with raita, Chicken curry with rice or any other food on request ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "V",
            "City": "Mumbai City",
            "Provider": "veena",
            "Contact": "9969005955",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Mumbai City",
            "Provider": "Aliya",
            "Contact": "9930053413",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "J",
            "City": "Mumbai City",
            "Provider": "Jayu  Ketki",
            "Contact": "7506357959",
            "Notes": "Home prepped meal by me and my mom. The food is mild, heathy and tasty",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PJ",
            "City": "Mumbai City",
            "Provider": "Priya Jham",
            "Contact": "9920795749",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "LS",
            "City": "Mumbai City",
            "Provider": "Leela Shirgaonkar",
            "Contact": "9699454895",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "D",
            "City": "Mumbai City",
            "Provider": "Deven",
            "Contact": "9987720149",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NG",
            "City": "Mumbai City",
            "Provider": "Nidhi Gathani",
            "Contact": "8591041098",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GB",
            "City": "Mumbai City",
            "Provider": "Grandmas Barni",
            "Contact": "9372805674",
            "Notes": "Home-cooked healthy vegetarian food, made with utmost love and hygiene. Enjoy a fresh, tasty, and wholesome meal. You can subscribe as well as place single orders. We take dietary / customization requests.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NG",
            "City": "Mumbai City",
            "Provider": "Nidhi Gathani",
            "Contact": "8591041098",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FO",
            "City": "Mumbai City",
            "Provider": "Fingers On by Neeta Rohra",
            "Contact": "9920022017",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HC",
            "City": "Mumbai City",
            "Provider": "Hetal Chheda",
            "Contact": "9930035588",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Mumbai City",
            "Provider": "Aliya",
            "Contact": "9930053413",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "V",
            "City": "Mumbai City",
            "Provider": "veena",
            "Contact": "9969005955",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Mumbai City",
            "Provider": "Puja",
            "Contact": "7400427437",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TS",
            "City": "Mumbai City",
            "Provider": "Terjani Samant",
            "Contact": "9913501593",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BF",
            "City": "Mumbai City",
            "Provider": "Bhargavs Food Junction",
            "Contact": "9082273746",
            "Notes": "Dal Chawal Sabji 5Roti  7Salad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TD",
            "City": "Mumbai City",
            "Provider": "The Dhaba Boy",
            "Contact": "9820840683",
            "Notes": "The Meal in the tiffin will be consists of 4 chapatis, Sabji, Dal / Kadhi , rice ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ST",
            "City": "Mumbai City",
            "Provider": "shreeji tiffins",
            "Contact": "9930737357",
            "Notes": "Delicious and simple home cooked gujarati food. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MC",
            "City": "Mumbai City",
            "Provider": "MASALA CANTEEN",
            "Contact": "7304046666",
            "Notes": "Meal boxes with healthy meal and variety of options ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TE",
            "City": "Mumbai City",
            "Provider": "Tiffin Express",
            "Contact": "9819815088",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Mumbai City",
            "Provider": "Maa'si",
            "Contact": "7688007799",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FO",
            "City": "Mumbai City",
            "Provider": "Fingers On by Neeta Rohra",
            "Contact": "9920022017",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MT",
            "City": "Mumbai City",
            "Provider": "Mumma's Tiffin",
            "Contact": "8169245321",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "D",
            "City": "Mumbai City",
            "Provider": "Deven",
            "Contact": "9987720149",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SK",
            "City": "Mumbai City",
            "Provider": "Saroj's Kitchen",
            "Contact": "9819136333",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PS",
            "City": "Mumbai City",
            "Provider": "Priyanka Satpal",
            "Contact": "9029077721",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Mumbai City",
            "Provider": "Maa'si",
            "Contact": "7688007799",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PF",
            "City": "Mumbai City",
            "Provider": "Pratibha foods",
            "Contact": "9730880017",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Mumbai City",
            "Provider": "Puja",
            "Contact": "7400427437",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CC",
            "City": "Mumbai City",
            "Provider": "Calorie care",
            "Contact": "8080089089",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PS",
            "City": "Mumbai City",
            "Provider": "Priyanka Satpal",
            "Contact": "9029077721",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Paschim Burdwan (West Bardhaman)": [
        {
            "avatar": "AC",
            "City": "Paschim Burdwan (West Bardhaman)",
            "Provider": "Asansol Chivalry",
            "Contact": "7384467390",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 14:04:56",
            "Found Useful": "0"
        },
        {
            "avatar": "AC",
            "City": "Paschim Burdwan (West Bardhaman)",
            "Provider": "Asansol Chivalry",
            "Contact": "7384467390",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 14:04:56",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Paschim Burdwan (West Bardhaman)",
            "Provider": "Suvadip",
            "Contact": "9800156933",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:37:45",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Paschim Burdwan (West Bardhaman)",
            "Provider": "Suvadip",
            "Contact": "9800156933",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:37:45",
            "Found Useful": "0"
        },
        {
            "avatar": "PB",
            "City": "Paschim Burdwan (West Bardhaman)",
            "Provider": "Puja Bhattad",
            "Contact": "9933007565",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:49:56",
            "Found Useful": "0"
        },
        {
            "avatar": "PB",
            "City": "Paschim Burdwan (West Bardhaman)",
            "Provider": "Puja Bhattad",
            "Contact": "9933007565",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:49:56",
            "Found Useful": "0"
        }
    ],
    "Surat": [
        {
            "avatar": "SK",
            "City": "Surat",
            "Provider": "Sindhi Ka Zayka",
            "Contact": "9898234455",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FT",
            "City": "Surat",
            "Provider": "FREE Tiffin Service  Gayatri Shaktipeeth Surat",
            "Contact": "8140430741",
            "Notes": "1 Dal1 Sabji1 Papad4 RotiRiceSalad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HG",
            "City": "Surat",
            "Provider": "Heena's Gourmet",
            "Contact": "9879539094",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AK",
            "City": "Surat",
            "Provider": "Aunty's KitchenSunita Dhanuka",
            "Contact": "9699768791",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HG",
            "City": "Surat",
            "Provider": "Heena's Gourmet",
            "Contact": "9879539094",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "G",
            "City": "Surat",
            "Provider": "GauravPinky",
            "Contact": "7990913349",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Surat",
            "Provider": "Peedhi",
            "Contact": "9227546690",
            "Notes": "Peedhi (generations) is a punjabi home cooked food, specialising in traditional and authentic rajma-chawal, daal makhani, and a couple of varieties of paneer. Menu might expand with time, experience and demand. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VP",
            "City": "Surat",
            "Provider": "Vijeta Patel",
            "Contact": "9313441361",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FT",
            "City": "Surat",
            "Provider": "FREE Tiffin Service  Gayatri Shaktipeeth Surat",
            "Contact": "8140430741",
            "Notes": "1 Dal1 Sabji1 Papad4 RotiRiceSalad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "YT",
            "City": "Surat",
            "Provider": "Yashvi Tiffin service",
            "Contact": "8160914265",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "JT",
            "City": "Surat",
            "Provider": "Jalaram Tiffin Service",
            "Contact": "9924640028",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NT",
            "City": "Surat",
            "Provider": "Neha Tiffin Service",
            "Contact": "7405559004",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "WC",
            "City": "Surat",
            "Provider": "woodland Caterers",
            "Contact": "9824146399",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VL",
            "City": "Surat",
            "Provider": "Vibha LadVib's Kitchen",
            "Contact": "8866086242",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HG",
            "City": "Surat",
            "Provider": "Heena's Gourmet",
            "Contact": "9879539094",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SK",
            "City": "Surat",
            "Provider": "Sindhi Ka Zayka",
            "Contact": "9898234455",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BC",
            "City": "Surat",
            "Provider": "Bhoomi Catering",
            "Contact": "9374722275",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VP",
            "City": "Surat",
            "Provider": "Vijeta Patel",
            "Contact": "9313441361",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "G",
            "City": "Surat",
            "Provider": "GauravPinky",
            "Contact": "7990913349",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BC",
            "City": "Surat",
            "Provider": "Bhoomi Catering",
            "Contact": "9374722275",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AK",
            "City": "Surat",
            "Provider": "Aunty's KitchenSunita Dhanuka",
            "Contact": "9699768791",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SK",
            "City": "Surat",
            "Provider": "Sindhi Ka Zayka",
            "Contact": "9898234455",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "JT",
            "City": "Surat",
            "Provider": "Jalaram Tiffin Service",
            "Contact": "9924640028",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NT",
            "City": "Surat",
            "Provider": "Neha Tiffin Service",
            "Contact": "7405559004",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FT",
            "City": "Surat",
            "Provider": "FREE Tiffin Service  Gayatri Shaktipeeth Surat",
            "Contact": "8140430741",
            "Notes": "1 Dal1 Sabji1 Papad4 RotiRiceSalad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VL",
            "City": "Surat",
            "Provider": "Vibha LadVib's Kitchen",
            "Contact": "8866086242",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Surat",
            "Provider": "Peedhi",
            "Contact": "9227546690",
            "Notes": "Peedhi (generations) is a punjabi home cooked food, specialising in traditional and authentic rajma-chawal, daal makhani, and a couple of varieties of paneer. Menu might expand with time, experience and demand. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AK",
            "City": "Surat",
            "Provider": "Aunty's KitchenSunita Dhanuka",
            "Contact": "9699768791",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PK",
            "City": "Surat",
            "Provider": "Parekh's Kitchen",
            "Contact": "6391009100",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CD",
            "City": "Surat",
            "Provider": "Chhaya Dalal",
            "Contact": "9879128559",
            "Notes": "Dal,rice, sabji(as per choice), roti",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RM",
            "City": "Surat",
            "Provider": "Reshma M. DaruwalaManish K. Daruwala",
            "Contact": "9228889096",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "WC",
            "City": "Surat",
            "Provider": "woodland Caterers",
            "Contact": "9824146399",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PK",
            "City": "Surat",
            "Provider": "Parekh's Kitchen",
            "Contact": "6391009100",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "YT",
            "City": "Surat",
            "Provider": "Yashvi Tiffin service",
            "Contact": "8160914265",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CD",
            "City": "Surat",
            "Provider": "Chhaya Dalal",
            "Contact": "9879128559",
            "Notes": "Dal,rice, sabji(as per choice), roti",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RM",
            "City": "Surat",
            "Provider": "Reshma M. DaruwalaManish K. Daruwala",
            "Contact": "9228889096",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Ghaziabad": [
        {
            "avatar": "F",
            "City": "Ghaziabad",
            "Provider": "Farha",
            "Contact": "7678624853",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CN",
            "City": "Ghaziabad",
            "Provider": "cup n cakes",
            "Contact": "9871644660",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "QP",
            "City": "Ghaziabad",
            "Provider": "Quirky Perky Cafe",
            "Contact": "9810908374",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HS",
            "City": "Ghaziabad",
            "Provider": "Happy soul cafe",
            "Contact": "9167072634",
            "Notes": "Covid special thali consisting of Ghee rice + yellow daal + mix veg +phuka  ( 3nos.) + curd + green salad +papad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "QP",
            "City": "Ghaziabad",
            "Provider": "Quirky Perky Cafe",
            "Contact": "9810908374",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DK",
            "City": "Ghaziabad",
            "Provider": "DishCovery Kitchen",
            "Contact": "9746517050",
            "Notes": "Covid Special Thali - Ghee Rice+Yellow Daal Thadka+Mix Veg+Phulka - 3 Nos.+Green Salad+Papad+Curd",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "F",
            "City": "Ghaziabad",
            "Provider": "Farha",
            "Contact": "7678624853",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "YN",
            "City": "Ghaziabad",
            "Provider": "yatika narang",
            "Contact": "9999391994",
            "Notes": "freesnack packets biscuit",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HS",
            "City": "Ghaziabad",
            "Provider": "Happy soul cafe",
            "Contact": "9167072634",
            "Notes": "Covid special thali consisting of Ghee rice + yellow daal + mix veg +phuka  ( 3nos.) + curd + green salad +papad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DK",
            "City": "Ghaziabad",
            "Provider": "DishCovery Kitchen",
            "Contact": "9746517050",
            "Notes": "Covid Special Thali - Ghee Rice+Yellow Daal Thadka+Mix Veg+Phulka - 3 Nos.+Green Salad+Papad+Curd",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CN",
            "City": "Ghaziabad",
            "Provider": "cup n cakes",
            "Contact": "9871644660",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TK",
            "City": "Ghaziabad",
            "Provider": "Tulika's Kitchen",
            "Contact": "9810710272",
            "Notes": "covidspecial diet",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TR",
            "City": "Ghaziabad",
            "Provider": "Traditional Rasoi",
            "Contact": "9958825514",
            "Notes": "1 gravy vegetable + 1 dry vegetable + 4 chapattis + salad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "F",
            "City": "Ghaziabad",
            "Provider": "Farha",
            "Contact": "7678624853",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "YN",
            "City": "Ghaziabad",
            "Provider": "yatika narang",
            "Contact": "9999391994",
            "Notes": "freesnack packets biscuit",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TR",
            "City": "Ghaziabad",
            "Provider": "Traditional Rasoi",
            "Contact": "9958825514",
            "Notes": "1 gravy vegetable + 1 dry vegetable + 4 chapattis + salad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TK",
            "City": "Ghaziabad",
            "Provider": "Tulika's Kitchen",
            "Contact": "9810710272",
            "Notes": "covidspecial diet",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Thane": [
        {
            "avatar": "AK",
            "City": "Thane",
            "Provider": "Aai khanaval",
            "Contact": "7710869262",
            "Notes": "1 dry sabji1 curry sabji3 chapati (roti) DalRiceSalad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DB",
            "City": "Thane",
            "Provider": "Don Burrito",
            "Contact": "9892453569",
            "Notes": "Mexican-based cuisine made from fresh healthy local ingredients.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DG",
            "City": "Thane",
            "Provider": "Dabba Garam",
            "Contact": "9167246985",
            "Notes": "Dabba Garam provids affordable and best home like food experience to all and ease their life with healthy customizable meal option.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VM",
            "City": "Thane",
            "Provider": "Vaishali Mathure",
            "Contact": "9029484827",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Thane",
            "Provider": "Sukhwinder",
            "Contact": "8779296573",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HJ",
            "City": "Thane",
            "Provider": "Hotel Jawahar",
            "Contact": "8329575220",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RC",
            "City": "Thane",
            "Provider": "Rekhaben caterer",
            "Contact": "9167344105",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MC",
            "City": "Thane",
            "Provider": "Mom Co",
            "Contact": "9619998688",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PS",
            "City": "Thane",
            "Provider": "Pushpa Subramanian",
            "Contact": "9769934225",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Thane",
            "Provider": "Anita",
            "Contact": "9769953994",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "I",
            "City": "Thane",
            "Provider": "IndieSindhi",
            "Contact": "9920921722",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NK",
            "City": "Thane",
            "Provider": "Nikhat kitchen catering and tiffin service",
            "Contact": "9323696940",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MC",
            "City": "Thane",
            "Provider": "Mom Co",
            "Contact": "9619998688",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "H",
            "City": "Thane",
            "Provider": "Happyfood",
            "Contact": "9619145883",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Thane",
            "Provider": "Sukhwinder",
            "Contact": "8779296573",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Thane",
            "Provider": "Sharmila",
            "Contact": "9920185567",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PS",
            "City": "Thane",
            "Provider": "Pushpa Subramanian",
            "Contact": "9769934225",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GK",
            "City": "Thane",
            "Provider": "Ghar ka",
            "Contact": "9702784784",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DG",
            "City": "Thane",
            "Provider": "Dabba Garam",
            "Contact": "9167246985",
            "Notes": "Dabba Garam provids affordable and best home like food experience to all and ease their life with healthy customizable meal option.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RC",
            "City": "Thane",
            "Provider": "Rekhaben caterer",
            "Contact": "9167344105",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "I",
            "City": "Thane",
            "Provider": "IndieSindhi",
            "Contact": "9920921722",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "H",
            "City": "Thane",
            "Provider": "Happyfood",
            "Contact": "9619145883",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Thane",
            "Provider": "Sukhwinder",
            "Contact": "8779296573",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Thane",
            "Provider": "Sanjay",
            "Contact": "7710011031",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DB",
            "City": "Thane",
            "Provider": "Don Burrito",
            "Contact": "9892453569",
            "Notes": "Mexican-based cuisine made from fresh healthy local ingredients.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "D",
            "City": "Thane",
            "Provider": "Dalchinii",
            "Contact": "9321962714",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HJ",
            "City": "Thane",
            "Provider": "Hotel Jawahar",
            "Contact": "8329575220",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NK",
            "City": "Thane",
            "Provider": "Nikhat kitchen catering and tiffin service",
            "Contact": "9323696940",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Thane",
            "Provider": "Sanjay",
            "Contact": "7710011031",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RK",
            "City": "Thane",
            "Provider": "Radha ka khaana",
            "Contact": "8860833042",
            "Notes": "Basic Gujarati food for lunch and dinner. Kadhi-Pulav, Masala Khichdi-Kadhi, Gujarati Dal-Rice, Dal Tadka-Jeera rice. Depending on availability. Please place orders 10-12 hours in advance.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DB",
            "City": "Thane",
            "Provider": "Don Burrito",
            "Contact": "9892453569",
            "Notes": "Mexican-based cuisine made from fresh healthy local ingredients.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AK",
            "City": "Thane",
            "Provider": "Aai khanaval",
            "Contact": "7710869262",
            "Notes": "1 dry sabji1 curry sabji3 chapati (roti) DalRiceSalad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ST",
            "City": "Thane",
            "Provider": "Shilpa Taste Box",
            "Contact": "9833670903",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Thane",
            "Provider": "Anita",
            "Contact": "9769953994",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VM",
            "City": "Thane",
            "Provider": "Vaishali Mathure",
            "Contact": "9029484827",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SF",
            "City": "Thane",
            "Provider": "Sai Foods",
            "Contact": "9372418863",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AM",
            "City": "Thane",
            "Provider": "aparna Mitra",
            "Contact": "9820609415",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GK",
            "City": "Thane",
            "Provider": "Ghar ka",
            "Contact": "9702784784",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AK",
            "City": "Thane",
            "Provider": "Aai khanaval",
            "Contact": "7710869262",
            "Notes": "1 dry sabji1 curry sabji3 chapati (roti) DalRiceSalad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SF",
            "City": "Thane",
            "Provider": "Sai Foods",
            "Contact": "9372418863",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Thane",
            "Provider": "Sharmila",
            "Contact": "9920185567",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "D",
            "City": "Thane",
            "Provider": "Dalchinii",
            "Contact": "9321962714",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DG",
            "City": "Thane",
            "Provider": "Dabba Garam",
            "Contact": "9167246985",
            "Notes": "Dabba Garam provids affordable and best home like food experience to all and ease their life with healthy customizable meal option.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AM",
            "City": "Thane",
            "Provider": "aparna Mitra",
            "Contact": "9820609415",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VM",
            "City": "Thane",
            "Provider": "Vaishali Mathure",
            "Contact": "9029484827",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RK",
            "City": "Thane",
            "Provider": "Radha ka khaana",
            "Contact": "8860833042",
            "Notes": "Basic Gujarati food for lunch and dinner. Kadhi-Pulav, Masala Khichdi-Kadhi, Gujarati Dal-Rice, Dal Tadka-Jeera rice. Depending on availability. Please place orders 10-12 hours in advance.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ST",
            "City": "Thane",
            "Provider": "Shilpa Taste Box",
            "Contact": "9833670903",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "South 24 Parganas": [
        {
            "avatar": "HD",
            "City": "South 24 Parganas",
            "Provider": "HOME DELIVERY",
            "Contact": "9330985565",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/13/2021 19:30:00",
            "Found Useful": "0"
        },
        {
            "avatar": "HD",
            "City": "South 24 Parganas",
            "Provider": "HOME DELIVERY",
            "Contact": "9330985565",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/13/2021 19:30:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FP",
            "City": "South 24 Parganas",
            "Provider": "Food Provider",
            "Contact": "8420830460",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/14/2021 20:18:09",
            "Found Useful": "0"
        },
        {
            "avatar": "HD",
            "City": "South 24 Parganas",
            "Provider": "HOME DELIVERY",
            "Contact": "7980618341",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/13/2021 19:29:17",
            "Found Useful": "0"
        },
        {
            "avatar": "D",
            "City": "South 24 Parganas",
            "Provider": "Damayanti",
            "Contact": "8910060878",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:35:59",
            "Found Useful": "0"
        },
        {
            "avatar": "D",
            "City": "South 24 Parganas",
            "Provider": "Damayanti",
            "Contact": "8910060878",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:35:59",
            "Found Useful": "0"
        },
        {
            "avatar": "HD",
            "City": "South 24 Parganas",
            "Provider": "HOME DELIVERY",
            "Contact": "7980618341",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/13/2021 19:29:17",
            "Found Useful": "0"
        },
        {
            "avatar": "HD",
            "City": "South 24 Parganas",
            "Provider": "HOME DELIVERY",
            "Contact": "9330985565",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/13/2021 19:30:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FP",
            "City": "South 24 Parganas",
            "Provider": "Food Provider",
            "Contact": "8420830460",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/14/2021 20:18:09",
            "Found Useful": "0"
        }
    ],
    "Kolkata": [
        {
            "avatar": "KK",
            "City": "Kolkata",
            "Provider": "KG's Kitchen",
            "Contact": "9903378663",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:07:57",
            "Found Useful": "0"
        },
        {
            "avatar": "CC",
            "City": "Kolkata",
            "Provider": "covid-19 canteen",
            "Contact": "7044630806",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:49:14",
            "Found Useful": "0"
        },
        {
            "avatar": "TB",
            "City": "Kolkata",
            "Provider": "Taza Bazaar",
            "Contact": "7439583627",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/18/2021 18:08:01",
            "Found Useful": "0"
        },
        {
            "avatar": "FM",
            "City": "Kolkata",
            "Provider": "Free meals",
            "Contact": "8335088799",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:37:15",
            "Found Useful": "0"
        },
        {
            "avatar": "SC",
            "City": "Kolkata",
            "Provider": "Sramajibi canteen ",
            "Contact": "9674519255",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:48:21",
            "Found Useful": "0"
        },
        {
            "avatar": "SB",
            "City": "Kolkata",
            "Provider": "Susmita Biswas",
            "Contact": "7044321822",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:56:25",
            "Found Useful": "0"
        },
        {
            "avatar": "PB",
            "City": "Kolkata",
            "Provider": "Pragya Banik",
            "Contact": "8336045662",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/13/2021 17:36:15",
            "Found Useful": "0"
        },
        {
            "avatar": "AA",
            "City": "Kolkata",
            "Provider": "Asmita and Anurag",
            "Contact": "8482055025",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 12:27:07",
            "Found Useful": "0"
        },
        {
            "avatar": "KP",
            "City": "Kolkata",
            "Provider": "Kerry patta",
            "Contact": "9830706543",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:49:57",
            "Found Useful": "0"
        },
        {
            "avatar": "CC",
            "City": "Kolkata",
            "Provider": "covid-19 canteen",
            "Contact": "7044630806",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:49:14",
            "Found Useful": "0"
        },
        {
            "avatar": "AA",
            "City": "Kolkata",
            "Provider": "Asmita and Anurag",
            "Contact": "8482055025",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 12:27:07",
            "Found Useful": "0"
        },
        {
            "avatar": "DA",
            "City": "Kolkata",
            "Provider": "Depend ablez",
            "Contact": "9163267000",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:48:26",
            "Found Useful": "0"
        },
        {
            "avatar": "SC",
            "City": "Kolkata",
            "Provider": "Sramajibi canteen ",
            "Contact": "9674519255",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:48:21",
            "Found Useful": "0"
        },
        {
            "avatar": "D",
            "City": "Kolkata",
            "Provider": "Deep",
            "Contact": "8910369548",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/12/2021 11:06:25",
            "Found Useful": "0"
        },
        {
            "avatar": "H",
            "City": "Kolkata",
            "Provider": "Harinavi",
            "Contact": "#ERROR!",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/16/2021 12:05:10",
            "Found Useful": "0"
        },
        {
            "avatar": "IM",
            "City": "Kolkata",
            "Provider": "Iskon Medinipur",
            "Contact": "9051497216",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/19/2021 19:46:18",
            "Found Useful": "0"
        },
        {
            "avatar": "SF",
            "City": "Kolkata",
            "Provider": "Seva Free Food",
            "Contact": "7044765985",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/5/2021 20:09:49",
            "Found Useful": "0"
        },
        {
            "avatar": "B",
            "City": "Kolkata",
            "Provider": "Be-kery",
            "Contact": "7044077280",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:49:55",
            "Found Useful": "0"
        },
        {
            "avatar": "H",
            "City": "Kolkata",
            "Provider": "Harinavi",
            "Contact": "#ERROR!",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/16/2021 12:05:10",
            "Found Useful": "0"
        },
        {
            "avatar": "HD",
            "City": "Kolkata",
            "Provider": "Home delivery ",
            "Contact": "9674147275",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:37:29",
            "Found Useful": "0"
        },
        {
            "avatar": "JR",
            "City": "Kolkata",
            "Provider": "Jayashree r jayajatra",
            "Contact": "629184675",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/20/2021 12:16:32",
            "Found Useful": "0"
        },
        {
            "avatar": "SC",
            "City": "Kolkata",
            "Provider": "Subhra canteen",
            "Contact": "8388030406",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/19/2021 13:17:36",
            "Found Useful": "0"
        },
        {
            "avatar": "D",
            "City": "Kolkata",
            "Provider": "DietFixx",
            "Contact": "9830038142",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:48:22",
            "Found Useful": "0"
        },
        {
            "avatar": "AD",
            "City": "Kolkata",
            "Provider": "Anna dan",
            "Contact": "9830614790",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/17/2021 16:35:50",
            "Found Useful": "0"
        },
        {
            "avatar": "LD",
            "City": "Kolkata",
            "Provider": "Lintu Dhaba",
            "Contact": "9874401999",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 12:16:27",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Kolkata",
            "Provider": "Mrs Kitchen ",
            "Contact": "9830574772",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 12:22:57",
            "Found Useful": "0"
        },
        {
            "avatar": "HD",
            "City": "Kolkata",
            "Provider": "Home delivery ",
            "Contact": "9874557022",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/6/2021 10:22:25",
            "Found Useful": "0"
        },
        {
            "avatar": "TB",
            "City": "Kolkata",
            "Provider": "Taza Bazaar",
            "Contact": "7439583627",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/18/2021 18:08:01",
            "Found Useful": "0"
        },
        {
            "avatar": "RM",
            "City": "Kolkata",
            "Provider": "Rakhi Mukherjee",
            "Contact": "9674327763",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NK",
            "City": "Kolkata",
            "Provider": "Nosh Kolkata",
            "Contact": "9933835338",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Kolkata",
            "Provider": "Parashmani",
            "Contact": "8617248592",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/19/2021 20:13:14",
            "Found Useful": "0"
        },
        {
            "avatar": "YT",
            "City": "Kolkata",
            "Provider": "Yours Truly ",
            "Contact": "6291180092",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HD",
            "City": "Kolkata",
            "Provider": "Home delivery ",
            "Contact": "9831710175",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/6/2021 10:24:46",
            "Found Useful": "0"
        },
        {
            "avatar": "HD",
            "City": "Kolkata",
            "Provider": "Home delivery ",
            "Contact": "9831710175",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/6/2021 10:24:46",
            "Found Useful": "0"
        },
        {
            "avatar": "ME",
            "City": "Kolkata",
            "Provider": "Ma er rannaghar",
            "Contact": "9051554426",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/17/2021 15:45:39",
            "Found Useful": "0"
        },
        {
            "avatar": "KP",
            "City": "Kolkata",
            "Provider": "Kerry patta",
            "Contact": "9830706543",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:49:57",
            "Found Useful": "0"
        },
        {
            "avatar": "D",
            "City": "Kolkata",
            "Provider": "DietFixx",
            "Contact": "9830038142",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:48:22",
            "Found Useful": "0"
        },
        {
            "avatar": "KK",
            "City": "Kolkata",
            "Provider": "KG's Kitchen",
            "Contact": "9903378663",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:07:57",
            "Found Useful": "0"
        },
        {
            "avatar": "YT",
            "City": "Kolkata",
            "Provider": "Yours Truly ",
            "Contact": "6291180092",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "JR",
            "City": "Kolkata",
            "Provider": "Jayashree r jayajatra",
            "Contact": "629184675",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/20/2021 12:16:32",
            "Found Useful": "0"
        },
        {
            "avatar": "HA",
            "City": "Kolkata",
            "Provider": "Harsh and Ankur",
            "Contact": "8697449350",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 12:33:25",
            "Found Useful": "0"
        },
        {
            "avatar": "SC",
            "City": "Kolkata",
            "Provider": "Subhra canteen",
            "Contact": "8388030406",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/19/2021 13:17:36",
            "Found Useful": "0"
        },
        {
            "avatar": "MF",
            "City": "Kolkata",
            "Provider": "Moody Foody by Ankita",
            "Contact": "9610096439",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "B",
            "City": "Kolkata",
            "Provider": "Be-kery",
            "Contact": "7044077280",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:49:55",
            "Found Useful": "0"
        },
        {
            "avatar": "SC",
            "City": "Kolkata",
            "Provider": "Sramajibi canteen ",
            "Contact": "9674519255",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:48:21",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Kolkata",
            "Provider": "Nanighar",
            "Contact": "8282828787",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:32:11",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Kolkata",
            "Provider": "Parashmani",
            "Contact": "8617248592",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/19/2021 20:13:14",
            "Found Useful": "0"
        },
        {
            "avatar": "FM",
            "City": "Kolkata",
            "Provider": "Free meals",
            "Contact": "8335088799",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:37:15",
            "Found Useful": "0"
        },
        {
            "avatar": "NK",
            "City": "Kolkata",
            "Provider": "Nosh Kolkata",
            "Contact": "9933835338",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "IM",
            "City": "Kolkata",
            "Provider": "Iskon Medinipur",
            "Contact": "9051497216",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/19/2021 19:46:18",
            "Found Useful": "0"
        },
        {
            "avatar": "AD",
            "City": "Kolkata",
            "Provider": "Anna dan",
            "Contact": "9830614790",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/17/2021 16:35:50",
            "Found Useful": "0"
        },
        {
            "avatar": "GG",
            "City": "Kolkata",
            "Provider": "Ghare Ghare ahare",
            "Contact": "8777847853",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/19/2021 20:48:17",
            "Found Useful": "0"
        },
        {
            "avatar": "PI",
            "City": "Kolkata",
            "Provider": "Peerless inn",
            "Contact": "9007012906",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:48:36",
            "Found Useful": "0"
        },
        {
            "avatar": "D",
            "City": "Kolkata",
            "Provider": "Deep",
            "Contact": "8910369548",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/12/2021 11:06:25",
            "Found Useful": "0"
        },
        {
            "avatar": "RH",
            "City": "Kolkata",
            "Provider": "ranjan's homefood",
            "Contact": "8820470143",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:33:45",
            "Found Useful": "0"
        },
        {
            "avatar": "RM",
            "City": "Kolkata",
            "Provider": "Rakhi Mukherjee",
            "Contact": "9674327763",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Kolkata",
            "Provider": "Shailja Sutodia",
            "Contact": "9830066639",
            "Notes": "utopiastore@gmail.com",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HD",
            "City": "Kolkata",
            "Provider": "Home delivery ",
            "Contact": "9674147275",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:37:29",
            "Found Useful": "0"
        },
        {
            "avatar": "DA",
            "City": "Kolkata",
            "Provider": "Depend ablez",
            "Contact": "9163267000",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:48:26",
            "Found Useful": "0"
        },
        {
            "avatar": "SB",
            "City": "Kolkata",
            "Provider": "Susmita Biswas",
            "Contact": "7044321822",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:56:25",
            "Found Useful": "0"
        },
        {
            "avatar": "KK",
            "City": "Kolkata",
            "Provider": "KG's Kitchen",
            "Contact": "9903378663",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:07:57",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Kolkata",
            "Provider": "Nanighar",
            "Contact": "8282828787",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:32:11",
            "Found Useful": "0"
        },
        {
            "avatar": "MF",
            "City": "Kolkata",
            "Provider": "Moody Foody by Ankita",
            "Contact": "9610096439",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SB",
            "City": "Kolkata",
            "Provider": "Susmita Biswas",
            "Contact": "7044321822",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:56:25",
            "Found Useful": "0"
        },
        {
            "avatar": "PB",
            "City": "Kolkata",
            "Provider": "Pragya Banik",
            "Contact": "8336045662",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/13/2021 17:36:15",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Kolkata",
            "Provider": "Mrs Kitchen ",
            "Contact": "9830574772",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 12:22:57",
            "Found Useful": "0"
        },
        {
            "avatar": "IA",
            "City": "Kolkata",
            "Provider": "Idly Aunty",
            "Contact": "9674624505",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Kolkata",
            "Provider": "Shailja Sutodia",
            "Contact": "9830066639",
            "Notes": "utopiastore@gmail.com",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HD",
            "City": "Kolkata",
            "Provider": "Home delivery ",
            "Contact": "9874557022",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/6/2021 10:22:25",
            "Found Useful": "0"
        },
        {
            "avatar": "GG",
            "City": "Kolkata",
            "Provider": "Ghare Ghare ahare",
            "Contact": "8777847853",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/19/2021 20:48:17",
            "Found Useful": "0"
        },
        {
            "avatar": "FM",
            "City": "Kolkata",
            "Provider": "Free meals",
            "Contact": "8335088799",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:37:15",
            "Found Useful": "0"
        },
        {
            "avatar": "PI",
            "City": "Kolkata",
            "Provider": "Peerless inn",
            "Contact": "9007012906",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:48:36",
            "Found Useful": "0"
        },
        {
            "avatar": "IA",
            "City": "Kolkata",
            "Provider": "Idly Aunty",
            "Contact": "9674624505",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RH",
            "City": "Kolkata",
            "Provider": "ranjan's homefood",
            "Contact": "8820470143",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:33:45",
            "Found Useful": "0"
        },
        {
            "avatar": "HA",
            "City": "Kolkata",
            "Provider": "Harsh and Ankur",
            "Contact": "8697449350",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 12:33:25",
            "Found Useful": "0"
        },
        {
            "avatar": "RK",
            "City": "Kolkata",
            "Provider": "Rani's Kitchen",
            "Contact": "9836551616",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:31:01",
            "Found Useful": "0"
        },
        {
            "avatar": "SF",
            "City": "Kolkata",
            "Provider": "Seva Free Food",
            "Contact": "7044765985",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/5/2021 20:09:49",
            "Found Useful": "0"
        },
        {
            "avatar": "CC",
            "City": "Kolkata",
            "Provider": "covid-19 canteen",
            "Contact": "7044630806",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:49:14",
            "Found Useful": "0"
        },
        {
            "avatar": "ME",
            "City": "Kolkata",
            "Provider": "Ma er rannaghar",
            "Contact": "9051554426",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/17/2021 15:45:39",
            "Found Useful": "0"
        },
        {
            "avatar": "TB",
            "City": "Kolkata",
            "Provider": "Taza Bazaar",
            "Contact": "7439583627",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/18/2021 18:08:01",
            "Found Useful": "0"
        },
        {
            "avatar": "LD",
            "City": "Kolkata",
            "Provider": "Lintu Dhaba",
            "Contact": "9874401999",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 12:16:27",
            "Found Useful": "0"
        },
        {
            "avatar": "RK",
            "City": "Kolkata",
            "Provider": "Rani's Kitchen",
            "Contact": "9836551616",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:31:01",
            "Found Useful": "0"
        }
    ],
    "Nashik": [
        {
            "avatar": "N",
            "City": "Nashik",
            "Provider": "Naivedhya",
            "Contact": "9922323809",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MS",
            "City": "Nashik",
            "Provider": "Mrs. Smita Ghotikar",
            "Contact": "9923802548",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MP",
            "City": "Nashik",
            "Provider": "Manik Pachorkar",
            "Contact": "9922444745",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KK",
            "City": "Nashik",
            "Provider": "Kaustubh Kulkarni",
            "Contact": "9011561431",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MT",
            "City": "Nashik",
            "Provider": "Mrs. Trupti Kulkarni",
            "Contact": "7620567503",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NS",
            "City": "Nashik",
            "Provider": "Namak shamak",
            "Contact": "9011199966",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "IN",
            "City": "Nashik",
            "Provider": "IPTA Nashik",
            "Contact": "7083294973",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Nashik",
            "Provider": "Neelkant",
            "Contact": "7020360933",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MA",
            "City": "Nashik",
            "Provider": "Mr. Atul Kulkarni",
            "Contact": "9922183353",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ST",
            "City": "Nashik",
            "Provider": "Satvik Tiffin",
            "Contact": "9923235474",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KK",
            "City": "Nashik",
            "Provider": "Kaustubh Kulkarni",
            "Contact": "9011561431",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MR",
            "City": "Nashik",
            "Provider": "Mrs. Ranjana Gosavi",
            "Contact": "9325766057",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MA",
            "City": "Nashik",
            "Provider": "Mrs. Ashwini Kashikar",
            "Contact": "9923499227",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CP",
            "City": "Nashik",
            "Provider": "Chaitanya Prasad Services",
            "Contact": "9049246262",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MP",
            "City": "Nashik",
            "Provider": "Manik Pachorkar",
            "Contact": "9922444745",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "JF",
            "City": "Nashik",
            "Provider": "Jyotrima Foods",
            "Contact": "9359689040",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MT",
            "City": "Nashik",
            "Provider": "Mrs. Trupti Kulkarni",
            "Contact": "7620567503",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RD",
            "City": "Nashik",
            "Provider": "Rashmi Devanhalli",
            "Contact": "9372430147",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MS",
            "City": "Nashik",
            "Provider": "Mrs. Smita Ghotikar",
            "Contact": "9923802548",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Nashik",
            "Provider": "Naivedhya",
            "Contact": "9922323809",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DF",
            "City": "Nashik",
            "Provider": "Devdhar Foods",
            "Contact": "9970041677",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DR",
            "City": "Nashik",
            "Provider": "Desi Roti Shoti",
            "Contact": "7030891919",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DF",
            "City": "Nashik",
            "Provider": "Devdhar Foods",
            "Contact": "9970041677",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NS",
            "City": "Nashik",
            "Provider": "Namak shamak",
            "Contact": "9011199966",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "JF",
            "City": "Nashik",
            "Provider": "Jyotrima Foods",
            "Contact": "9359689040",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ST",
            "City": "Nashik",
            "Provider": "Saurabh tiffin",
            "Contact": "9307957393",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DR",
            "City": "Nashik",
            "Provider": "Desi Roti Shoti",
            "Contact": "7030891919",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RD",
            "City": "Nashik",
            "Provider": "Rashmi Devanhalli",
            "Contact": "9372430147",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Nashik",
            "Provider": "Neelkant",
            "Contact": "7020360933",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MR",
            "City": "Nashik",
            "Provider": "Mrs. Ranjana Gosavi",
            "Contact": "9325766057",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ST",
            "City": "Nashik",
            "Provider": "Satvik Tiffin",
            "Contact": "9923235474",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PT",
            "City": "Nashik",
            "Provider": "Purvartha Tiffin services",
            "Contact": "7972882775",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MS",
            "City": "Nashik",
            "Provider": "Mrs. Smita Ghotikar",
            "Contact": "9923802548",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MA",
            "City": "Nashik",
            "Provider": "Mrs. Ashwini Kashikar",
            "Contact": "9923499227",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MP",
            "City": "Nashik",
            "Provider": "Manik Pachorkar",
            "Contact": "9922444745",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MA",
            "City": "Nashik",
            "Provider": "Mr. Atul Kulkarni",
            "Contact": "9922183353",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "IN",
            "City": "Nashik",
            "Provider": "IPTA Nashik",
            "Contact": "7083294973",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CP",
            "City": "Nashik",
            "Provider": "Chaitanya Prasad Services",
            "Contact": "9049246262",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Nashik",
            "Provider": "Naivedhya",
            "Contact": "9922323809",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PT",
            "City": "Nashik",
            "Provider": "Purvartha Tiffin services",
            "Contact": "7972882775",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ST",
            "City": "Nashik",
            "Provider": "Saurabh tiffin",
            "Contact": "9307957393",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Kanpur Nagar": [
        {
            "avatar": "RK",
            "City": "Kanpur Nagar",
            "Provider": "Reenas Kitchen",
            "Contact": "8800739777",
            "Notes": "We provide Tiffins as welk as Multi Cuisine food. In tiffin we provide daal, roti , sabzi, rice and salad. For Multi cuisine please find the attached Menu.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GH",
            "City": "Kanpur Nagar",
            "Provider": "Gehani Hunger",
            "Contact": "8418988886",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RK",
            "City": "Kanpur Nagar",
            "Provider": "Reenas Kitchen",
            "Contact": "8800739777",
            "Notes": "We provide Tiffins as welk as Multi Cuisine food. In tiffin we provide daal, roti , sabzi, rice and salad. For Multi cuisine please find the attached Menu.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GH",
            "City": "Kanpur Nagar",
            "Provider": "Gehani Hunger",
            "Contact": "8418988886",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RK",
            "City": "Kanpur Nagar",
            "Provider": "Reenas Kitchen",
            "Contact": "8800739777",
            "Notes": "We provide Tiffins as welk as Multi Cuisine food. In tiffin we provide daal, roti , sabzi, rice and salad. For Multi cuisine please find the attached Menu.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "South Delhi": [
        {
            "avatar": "K",
            "City": "South Delhi",
            "Provider": "Kusha/Twinkle",
            "Contact": "9582555032",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HB",
            "City": "South Delhi",
            "Provider": "Home Bytes",
            "Contact": "9711150463",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "South Delhi",
            "Provider": "Mummys kitchen",
            "Contact": "9810462091",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AV",
            "City": "South Delhi",
            "Provider": "Alka verma",
            "Contact": "9810684884",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "South Delhi",
            "Provider": "Priyanka",
            "Contact": "9811906621",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "South Delhi",
            "Provider": "Priyanka",
            "Contact": "9811906621",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "South Delhi",
            "Provider": "Mummys kitchen",
            "Contact": "9810462091",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "R",
            "City": "South Delhi",
            "Provider": "Rajni",
            "Contact": "8875336039",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "South Delhi",
            "Provider": "Kusha/Twinkle",
            "Contact": "9582555032",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HB",
            "City": "South Delhi",
            "Provider": "Home Bytes",
            "Contact": "9711150463",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HB",
            "City": "South Delhi",
            "Provider": "Home Bytes",
            "Contact": "9711150463",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "South Delhi",
            "Provider": "Mummys kitchen",
            "Contact": "9810462091",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AV",
            "City": "South Delhi",
            "Provider": "Alka verma",
            "Contact": "9810684884",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "R",
            "City": "South Delhi",
            "Provider": "Rajni",
            "Contact": "8875336039",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "South Delhi",
            "Provider": "Smita",
            "Contact": "9810727092",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "South Delhi",
            "Provider": "Kusha/Twinkle",
            "Contact": "9582555032",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "South Delhi",
            "Provider": "Smita",
            "Contact": "9810727092",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Chennai": [
        {
            "avatar": "S(",
            "City": "Chennai",
            "Provider": "Sanjeeta (OGMO)",
            "Contact": "9790849559",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "9840050875",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:35:00",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Chennai",
            "Provider": "Masalabox",
            "Contact": "8893627252",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Chennai",
            "Provider": "Amitabhojanam",
            "Contact": "9345517634",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "B",
            "City": "Chennai",
            "Provider": "Balaji",
            "Contact": "9952037075",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Chennai",
            "Provider": "Amitabhojanam",
            "Contact": "9345517634",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AJ",
            "City": "Chennai",
            "Provider": "Apeksha Jain",
            "Contact": "9164066342",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "9003265766",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:35:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "9841877147",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:35:00",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Chennai",
            "Provider": "none",
            "Contact": "9840951316",
            "Notes": "South Indian tiffin",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Chennai",
            "Provider": "Mala's Kitchen",
            "Contact": "9176349844",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AJ",
            "City": "Chennai",
            "Provider": "Apeksha Jain",
            "Contact": "9164066342",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MN",
            "City": "Chennai",
            "Provider": "Mom Nom",
            "Contact": "9962999119",
            "Notes": "Home cooked meal ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "7708053759",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:35:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "9003265766",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:35:00",
            "Found Useful": "0"
        },
        {
            "avatar": "JF",
            "City": "Chennai",
            "Provider": "jain food service chennai",
            "Contact": "7010671776",
            "Notes": "its a home made food pure jain no onion gralic and if there is special order v can provide the service as customer demand",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SM",
            "City": "Chennai",
            "Provider": "Shubham Mehra",
            "Contact": "9940499896",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "Chennai",
            "Provider": "karpagam",
            "Contact": "9840239265",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "9940396840",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:35:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "7708053759",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:35:00",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Chennai",
            "Provider": "none",
            "Contact": "9840951316",
            "Notes": "South Indian tiffin",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SI",
            "City": "Chennai",
            "Provider": "SNB Iyengar Thaligai",
            "Contact": "8610862945",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Chennai",
            "Provider": "Annams",
            "Contact": "9551079550",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "Free food",
            "Contact": "9884011145",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-19 11:42:00",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Chennai",
            "Provider": "none",
            "Contact": "9840951316",
            "Notes": "South Indian tiffin",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AF",
            "City": "Chennai",
            "Provider": "Anirudha foundation",
            "Contact": "9841417246",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:21:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SC",
            "City": "Chennai",
            "Provider": "Salem coroporation covid control room",
            "Contact": "9865696924",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-24 07:03:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "9940396840",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:35:00",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Chennai",
            "Provider": "Masalabox",
            "Contact": "8893627252",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "8300948011",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:31:00",
            "Found Useful": "0"
        },
        {
            "avatar": "B",
            "City": "Chennai",
            "Provider": "Balaji",
            "Contact": "9952037075",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "9841031052",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:40:00",
            "Found Useful": "0"
        },
        {
            "avatar": "MT",
            "City": "Chennai",
            "Provider": "Monkey temptation",
            "Contact": "9941116475",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "9500022153",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:40:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "9500022153",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:40:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "9003265766",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:35:00",
            "Found Useful": "0"
        },
        {
            "avatar": "JF",
            "City": "Chennai",
            "Provider": "jain food service chennai",
            "Contact": "7010671776",
            "Notes": "its a home made food pure jain no onion gralic and if there is special order v can provide the service as customer demand",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SA",
            "City": "Chennai",
            "Provider": "Shreya Agarwal",
            "Contact": "9962421538",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Chennai",
            "Provider": "Amitabhojanam",
            "Contact": "9345517634",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FL",
            "City": "Chennai",
            "Provider": "free lunch for covid patients",
            "Contact": "8300714083",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:27:00",
            "Found Useful": "0"
        },
        {
            "avatar": "Y",
            "City": "Chennai",
            "Provider": "Yash",
            "Contact": "7395985955",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "9841877147",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:35:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SM",
            "City": "Chennai",
            "Provider": "Shubham Mehra",
            "Contact": "9940499896",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AD",
            "City": "Chennai",
            "Provider": "Andhra Deli",
            "Contact": "7550009207",
            "Notes": "A light and nutritious lunch , consisting of rice , green vegetable , dhal , mild rasam & buttermilk",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "G",
            "City": "Chennai",
            "Provider": "GreenMeal",
            "Contact": "9884422877",
            "Notes": "Home cooked healthy food consisting of two sabzis, rice, 2 rotis, raita/salad, home-made pickle for INR 150/- .",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "L",
            "City": "Chennai",
            "Provider": "latha",
            "Contact": "9884387200",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SB",
            "City": "Chennai",
            "Provider": "Sri Balaji Foods",
            "Contact": "7550091077",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "9841877147",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:35:00",
            "Found Useful": "0"
        },
        {
            "avatar": "S(",
            "City": "Chennai",
            "Provider": "Sanjeeta (OGMO)",
            "Contact": "9790849559",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "Chennai",
            "Provider": "karpagam",
            "Contact": "9840239265",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KC",
            "City": "Chennai",
            "Provider": "K's Catering",
            "Contact": "8925267757",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "Free food",
            "Contact": "9884011145",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-19 11:42:00",
            "Found Useful": "0"
        },
        {
            "avatar": "L",
            "City": "Chennai",
            "Provider": "latha",
            "Contact": "9884387200",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "G",
            "City": "Chennai",
            "Provider": "GreenMeal",
            "Contact": "9884422877",
            "Notes": "Home cooked healthy food consisting of two sabzis, rice, 2 rotis, raita/salad, home-made pickle for INR 150/- .",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CK",
            "City": "Chennai",
            "Provider": "Chappati Kings",
            "Contact": "9383840006",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Chennai",
            "Provider": "Masalabox",
            "Contact": "8893627252",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CK",
            "City": "Chennai",
            "Provider": "Chappati Kings",
            "Contact": "9383840006",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "B",
            "City": "Chennai",
            "Provider": "Balaji",
            "Contact": "9952037075",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Chennai",
            "Provider": "Amitabhojanam",
            "Contact": "9345517634",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "9840050875",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:35:00",
            "Found Useful": "0"
        },
        {
            "avatar": "MN",
            "City": "Chennai",
            "Provider": "Mom Nom",
            "Contact": "9962999119",
            "Notes": "Home cooked meal ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AJ",
            "City": "Chennai",
            "Provider": "Apeksha Jain",
            "Contact": "9164066342",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SC",
            "City": "Chennai",
            "Provider": "Salem coroporation covid control room",
            "Contact": "9865696924",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-24 07:03:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Chennai",
            "Provider": "Shri Sivasai mess",
            "Contact": "9566223434",
            "Notes": "Rice, sambar, rasam, kootu, poriyal, kuzhambu, buttermilk, papad and pickle",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Chennai",
            "Provider": "Annams",
            "Contact": "9551079550",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "8300948011",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:31:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "9841031052",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:40:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Chennai",
            "Provider": "Shri Sivasai mess",
            "Contact": "9566223434",
            "Notes": "Rice, sambar, rasam, kootu, poriyal, kuzhambu, buttermilk, papad and pickle",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S(",
            "City": "Chennai",
            "Provider": "Sanjeeta (OGMO)",
            "Contact": "9790849559",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MT",
            "City": "Chennai",
            "Provider": "Monkey temptation",
            "Contact": "9941116475",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SA",
            "City": "Chennai",
            "Provider": "Shreya Agarwal",
            "Contact": "9962421538",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AF",
            "City": "Chennai",
            "Provider": "Anirudha foundation",
            "Contact": "9841417246",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:21:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SB",
            "City": "Chennai",
            "Provider": "Sri Balaji Foods",
            "Contact": "7550091077",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KC",
            "City": "Chennai",
            "Provider": "K's Catering",
            "Contact": "8925267757",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Chennai",
            "Provider": "Mala's Kitchen",
            "Contact": "9176349844",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SI",
            "City": "Chennai",
            "Provider": "SNB Iyengar Thaligai",
            "Contact": "8610862945",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Chennai",
            "Provider": "free food for covid patients",
            "Contact": "9840050875",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:35:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FL",
            "City": "Chennai",
            "Provider": "free lunch for covid patients",
            "Contact": "8300714083",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-13 18:27:00",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Chennai",
            "Provider": "Mala's Kitchen",
            "Contact": "9176349844",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AD",
            "City": "Chennai",
            "Provider": "Andhra Deli",
            "Contact": "7550009207",
            "Notes": "A light and nutritious lunch , consisting of rice , green vegetable , dhal , mild rasam & buttermilk",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "Y",
            "City": "Chennai",
            "Provider": "Yash",
            "Contact": "7395985955",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Palghar": [
        {
            "avatar": "VP",
            "City": "Palghar",
            "Provider": "Valentine Pereira",
            "Contact": "9152595128",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VP",
            "City": "Palghar",
            "Provider": "Valentine Pereira",
            "Contact": "9152595128",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KS",
            "City": "Palghar",
            "Provider": "Khadya Srushti",
            "Contact": "8888864162",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KS",
            "City": "Palghar",
            "Provider": "Khadya Srushti",
            "Contact": "8888864162",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VP",
            "City": "Palghar",
            "Provider": "Valentine Pereira",
            "Contact": "9152595128",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Rupnagar": [
        {
            "avatar": "PG",
            "City": "Rupnagar",
            "Provider": "Priya Gandhi",
            "Contact": "8837773470",
            "Notes": "Food cooked with love.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PG",
            "City": "Rupnagar",
            "Provider": "Priya Gandhi",
            "Contact": "8837773470",
            "Notes": "Food cooked with love.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Udaipur": [
        {
            "avatar": "RV",
            "City": "Udaipur",
            "Provider": "Rajshree varma",
            "Contact": "9928850973",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RV",
            "City": "Udaipur",
            "Provider": "Rajshree varma",
            "Contact": "9928850973",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DC",
            "City": "Udaipur",
            "Provider": "Dhaara cafe",
            "Contact": "9892003893",
            "Notes": "Chapati-Bhaji & Dal-Rice",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MS",
            "City": "Udaipur",
            "Provider": "MB Singh",
            "Contact": "9352500408",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DH",
            "City": "Udaipur",
            "Provider": "Double Happiness",
            "Contact": "8764680128",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DC",
            "City": "Udaipur",
            "Provider": "Dhaara cafe",
            "Contact": "9892003893",
            "Notes": "Chapati-Bhaji & Dal-Rice",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Udaipur",
            "Provider": "Mamta",
            "Contact": "8209971633",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MS",
            "City": "Udaipur",
            "Provider": "Mahavir singhRanawat",
            "Contact": "7727836898",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GM",
            "City": "Udaipur",
            "Provider": "Gaurishankar Mallviya",
            "Contact": "9772304244",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MS",
            "City": "Udaipur",
            "Provider": "Meal Seva",
            "Contact": "8826055111",
            "Notes": "Can make home food like daal chawal, Roti sabzi , idli Sambhar salad etc , Simple Home made food which will be cooked with love and care .I am no chef , this is not for business and food is not for sale, it is a genuine effort to help someone in need . If there is a small hospital with a few patients can do it for them as well with prior notice. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GM",
            "City": "Udaipur",
            "Provider": "Gaurishankar Mallviya",
            "Contact": "9772304244",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Udaipur",
            "Provider": "shailendra Singh",
            "Contact": "9983977714",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RL",
            "City": "Udaipur",
            "Provider": "Rahul Lodha",
            "Contact": "7627062229",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Udaipur",
            "Provider": "Shradha",
            "Contact": "9314664412",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RV",
            "City": "Udaipur",
            "Provider": "Rajshree varma",
            "Contact": "9928850973",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DC",
            "City": "Udaipur",
            "Provider": "Dhaara cafe",
            "Contact": "9892003893",
            "Notes": "Chapati-Bhaji & Dal-Rice",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Udaipur",
            "Provider": "Mamta",
            "Contact": "8209971633",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MS",
            "City": "Udaipur",
            "Provider": "Mahavir singhRanawat",
            "Contact": "7727836898",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "R",
            "City": "Udaipur",
            "Provider": "Ranaji",
            "Contact": "9828882111",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "R",
            "City": "Udaipur",
            "Provider": "Ranaji",
            "Contact": "9828882111",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "LT",
            "City": "Udaipur",
            "Provider": "Lovely tiffin center",
            "Contact": "9784909397",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "LT",
            "City": "Udaipur",
            "Provider": "Lovely tiffin center",
            "Contact": "9784909397",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RV",
            "City": "Udaipur",
            "Provider": "Rajshree varma",
            "Contact": "9928850973",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MS",
            "City": "Udaipur",
            "Provider": "MB Singh",
            "Contact": "9352500408",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MS",
            "City": "Udaipur",
            "Provider": "Meal Seva",
            "Contact": "8826055111",
            "Notes": "Can make home food like daal chawal, Roti sabzi , idli Sambhar salad etc , Simple Home made food which will be cooked with love and care .I am no chef , this is not for business and food is not for sale, it is a genuine effort to help someone in need . If there is a small hospital with a few patients can do it for them as well with prior notice. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RL",
            "City": "Udaipur",
            "Provider": "Rahul Lodha",
            "Contact": "7627062229",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Udaipur",
            "Provider": "Shradha",
            "Contact": "9314664412",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Udaipur",
            "Provider": "shailendra Singh",
            "Contact": "9983977714",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DH",
            "City": "Udaipur",
            "Provider": "Double Happiness",
            "Contact": "8764680128",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "North Goa": [
        {
            "avatar": "TV",
            "City": "North Goa",
            "Provider": "The Village Beyond",
            "Contact": "7208792619",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MA",
            "City": "North Goa",
            "Provider": "Mrinmoy Acharya",
            "Contact": "9820123541",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BD",
            "City": "North Goa",
            "Provider": "B Dsouza",
            "Contact": "9326927922",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "EM",
            "City": "North Goa",
            "Provider": "Earth Mama Smoothies",
            "Contact": "9892884388",
            "Notes": "Healthy , wholesome and nutritious smoothies. Made fresh daily. It's vegan and sugar free and glutenfree. An easy way of incorporating fruits and vegetables into your diet. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TT",
            "City": "North Goa",
            "Provider": "Tripti Tiffin",
            "Contact": "8999720928",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RS",
            "City": "North Goa",
            "Provider": "Rohit S Blaggan",
            "Contact": "9309509180",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "North Goa",
            "Provider": "Ketki",
            "Contact": "9819675788",
            "Notes": "I am no chef, can provide simple nutritious meals when needed.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PD",
            "City": "North Goa",
            "Provider": "Prottush De",
            "Contact": "9971419632",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TR",
            "City": "North Goa",
            "Provider": "The Rasoda",
            "Contact": "7499941027",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NN",
            "City": "North Goa",
            "Provider": "Namita Nayudu",
            "Contact": "7972156741",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "North Goa",
            "Provider": "Ketki",
            "Contact": "9819675788",
            "Notes": "I am no chef, can provide simple nutritious meals when needed.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SN",
            "City": "North Goa",
            "Provider": "Samir nayudu",
            "Contact": "8319788014",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NN",
            "City": "North Goa",
            "Provider": "Namita Nayudu",
            "Contact": "7972156741",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TV",
            "City": "North Goa",
            "Provider": "The Village Beyond",
            "Contact": "7208792619",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MA",
            "City": "North Goa",
            "Provider": "Mrinmoy Acharya",
            "Contact": "9820123541",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SN",
            "City": "North Goa",
            "Provider": "Samir nayudu",
            "Contact": "8319788014",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TT",
            "City": "North Goa",
            "Provider": "Tripti Tiffin",
            "Contact": "8999720928",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "L",
            "City": "North Goa",
            "Provider": "Lisa",
            "Contact": "9820179889",
            "Notes": "Healthy food no sugar.. I have had covid and learnt to cook what would digest easier but nutritious ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "North Goa",
            "Provider": "Miro kitchenette",
            "Contact": "8530810222",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "L",
            "City": "North Goa",
            "Provider": "Lisa",
            "Contact": "9820179889",
            "Notes": "Healthy food no sugar.. I have had covid and learnt to cook what would digest easier but nutritious ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MB",
            "City": "North Goa",
            "Provider": "Mita biradar",
            "Contact": "9403877800",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "C",
            "City": "North Goa",
            "Provider": "Chetan",
            "Contact": "7020256884",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "OW",
            "City": "North Goa",
            "Provider": "Our White door",
            "Contact": "9764214139",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "C",
            "City": "North Goa",
            "Provider": "Chetan",
            "Contact": "7020256884",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BD",
            "City": "North Goa",
            "Provider": "B Dsouza",
            "Contact": "9326927922",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "North Goa",
            "Provider": "Miro kitchenette",
            "Contact": "8530810222",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TV",
            "City": "North Goa",
            "Provider": "The Village Beyond",
            "Contact": "7208792619",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RS",
            "City": "North Goa",
            "Provider": "Rohit S Blaggan",
            "Contact": "9309509180",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PD",
            "City": "North Goa",
            "Provider": "Prottush De",
            "Contact": "9971419632",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MB",
            "City": "North Goa",
            "Provider": "Mita biradar",
            "Contact": "9403877800",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "OW",
            "City": "North Goa",
            "Provider": "Our White door",
            "Contact": "9764214139",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "EM",
            "City": "North Goa",
            "Provider": "Earth Mama Smoothies",
            "Contact": "9892884388",
            "Notes": "Healthy , wholesome and nutritious smoothies. Made fresh daily. It's vegan and sugar free and glutenfree. An easy way of incorporating fruits and vegetables into your diet. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TR",
            "City": "North Goa",
            "Provider": "The Rasoda",
            "Contact": "7499941027",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Kota": [
        {
            "avatar": "P",
            "City": "Kota",
            "Provider": "Prerna",
            "Contact": "8980419648",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TT",
            "City": "Kota",
            "Provider": "Tiffin talesSpiced Up",
            "Contact": "9929993005",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "F",
            "City": "Kota",
            "Provider": "Food",
            "Contact": "6378065032",
            "Notes": "Can order the meal for covid patients. With complete hygine and purity. Be together in this hard time. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TT",
            "City": "Kota",
            "Provider": "Tiffin talesSpiced Up",
            "Contact": "9929993005",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TT",
            "City": "Kota",
            "Provider": "Tiffin talesSpiced Up",
            "Contact": "9929993005",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "G",
            "City": "Kota",
            "Provider": "Gurjot",
            "Contact": "9636414033",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "G",
            "City": "Kota",
            "Provider": "Gurjot",
            "Contact": "9636414033",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Kota",
            "Provider": "Prerna",
            "Contact": "8980419648",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "P",
            "City": "Kota",
            "Provider": "Prerna",
            "Contact": "8980419648",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "F",
            "City": "Kota",
            "Provider": "Food",
            "Contact": "6378065032",
            "Notes": "Can order the meal for covid patients. With complete hygine and purity. Be together in this hard time. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Hyderabad": [
        {
            "avatar": "GS",
            "City": "Hyderabad",
            "Provider": "Good Samaritan",
            "Contact": "8008856763",
            "Notes": "Delicious nutritious meals served with utmost cleanliness a dedication. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CJ",
            "City": "Hyderabad",
            "Provider": "Chef Jolly",
            "Contact": "9848218309",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "JF",
            "City": "Hyderabad",
            "Provider": "Jahnavi flavours of home",
            "Contact": "6300975328",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GS",
            "City": "Hyderabad",
            "Provider": "Good Samaritan",
            "Contact": "8008856763",
            "Notes": "Delicious nutritious meals served with utmost cleanliness a dedication. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Hyderabad",
            "Provider": "Alekhya",
            "Contact": "9148983579",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CJ",
            "City": "Hyderabad",
            "Provider": "Chef Jolly",
            "Contact": "9848218309",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "JF",
            "City": "Hyderabad",
            "Provider": "Jahnavi flavours of home",
            "Contact": "6300975328",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Hyderabad",
            "Provider": "Alekhya",
            "Contact": "9148983579",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Purba Medinipur (East Medinipur)": [
        {
            "avatar": "SS",
            "City": "Purba Medinipur (East Medinipur)",
            "Provider": "Subhadeep Sarkar",
            "Contact": "9800982837",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:12:05",
            "Found Useful": "0"
        },
        {
            "avatar": "T",
            "City": "Purba Medinipur (East Medinipur)",
            "Provider": "Tathagata",
            "Contact": "8768851745",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:27:32",
            "Found Useful": "0"
        },
        {
            "avatar": "MM",
            "City": "Purba Medinipur (East Medinipur)",
            "Provider": "Manik Mandal",
            "Contact": "9775507297",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:14:36",
            "Found Useful": "0"
        },
        {
            "avatar": "SA",
            "City": "Purba Medinipur (East Medinipur)",
            "Provider": "Saiyad Abu",
            "Contact": "9733102201",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:17:29",
            "Found Useful": "0"
        },
        {
            "avatar": "PP",
            "City": "Purba Medinipur (East Medinipur)",
            "Provider": "Prabir Pike",
            "Contact": "8768516858",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:13:54",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Purba Medinipur (East Medinipur)",
            "Provider": "Subhadeep Sarkar",
            "Contact": "9800982837",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:12:05",
            "Found Useful": "0"
        },
        {
            "avatar": "BM",
            "City": "Purba Medinipur (East Medinipur)",
            "Provider": "Buddhadeb Maiti ",
            "Contact": "8250335092",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:20:42",
            "Found Useful": "0"
        },
        {
            "avatar": "SB",
            "City": "Purba Medinipur (East Medinipur)",
            "Provider": "Sukumar Bera",
            "Contact": "7602712399",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:12:47",
            "Found Useful": "0"
        },
        {
            "avatar": "SB",
            "City": "Purba Medinipur (East Medinipur)",
            "Provider": "Sukumar Bera",
            "Contact": "7602712399",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:12:47",
            "Found Useful": "0"
        },
        {
            "avatar": "MM",
            "City": "Purba Medinipur (East Medinipur)",
            "Provider": "Manik Mandal",
            "Contact": "9775507297",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:14:36",
            "Found Useful": "0"
        },
        {
            "avatar": "BM",
            "City": "Purba Medinipur (East Medinipur)",
            "Provider": "Buddhadeb Maiti ",
            "Contact": "8250335092",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:20:42",
            "Found Useful": "0"
        },
        {
            "avatar": "PP",
            "City": "Purba Medinipur (East Medinipur)",
            "Provider": "Prabir Pike",
            "Contact": "8768516858",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:13:54",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Purba Medinipur (East Medinipur)",
            "Provider": "Subhadeep Sarkar",
            "Contact": "9800982837",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:12:05",
            "Found Useful": "0"
        },
        {
            "avatar": "T",
            "City": "Purba Medinipur (East Medinipur)",
            "Provider": "Tathagata",
            "Contact": "8768851745",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:27:32",
            "Found Useful": "0"
        },
        {
            "avatar": "SA",
            "City": "Purba Medinipur (East Medinipur)",
            "Provider": "Saiyad Abu",
            "Contact": "9733102201",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:17:29",
            "Found Useful": "0"
        }
    ],
    "North West Delhi": [
        {
            "avatar": "DT",
            "City": "North West Delhi",
            "Provider": "Delhi tiffins",
            "Contact": "9718029639",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DT",
            "City": "North West Delhi",
            "Provider": "Delhi tiffins",
            "Contact": "9718029639",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "North West Delhi",
            "Provider": "Namrata",
            "Contact": "9313327121",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DT",
            "City": "North West Delhi",
            "Provider": "Delhi tiffins",
            "Contact": "9718029639",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "North West Delhi",
            "Provider": "Namrata",
            "Contact": "9313327121",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NA",
            "City": "North West Delhi",
            "Provider": "Namita arora",
            "Contact": "8076815248",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NA",
            "City": "North West Delhi",
            "Provider": "Namita arora",
            "Contact": "8076815248",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Khordha": [
        {
            "avatar": "MM",
            "City": "Khordha",
            "Provider": "Manish Mohapatra",
            "Contact": "8270303159",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Khordha",
            "Provider": "Mothers kitchen",
            "Contact": "7205172684",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MM",
            "City": "Khordha",
            "Provider": "Manish Mohapatra",
            "Contact": "8270303159",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TO",
            "City": "Khordha",
            "Provider": "The Oregano",
            "Contact": "9090952789",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TO",
            "City": "Khordha",
            "Provider": "The Oregano",
            "Contact": "9090952789",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Khordha",
            "Provider": "Mothers kitchen",
            "Contact": "7205172684",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MM",
            "City": "Khordha",
            "Provider": "Manish Mohapatra",
            "Contact": "8270303159",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Ludhiana": [
        {
            "avatar": "JP",
            "City": "Ludhiana",
            "Provider": "Jagannath parshadam",
            "Contact": "7402828074",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-23 05:50:00",
            "Found Useful": "0"
        },
        {
            "avatar": "DJ",
            "City": "Ludhiana",
            "Provider": "Dimpal jain",
            "Contact": "8284830100",
            "Notes": "4-5 persons, veg food",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "JP",
            "City": "Ludhiana",
            "Provider": "Jagannath parshadam",
            "Contact": "7402828074",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-23 05:50:00",
            "Found Useful": "0"
        },
        {
            "avatar": "DJ",
            "City": "Ludhiana",
            "Provider": "Dimpal jain",
            "Contact": "8284830100",
            "Notes": "4-5 persons, veg food",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Hooghly": [
        {
            "avatar": "TL",
            "City": "Hooghly",
            "Provider": "The Lady with a Ladle",
            "Contact": "9051259157",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 12:42:43",
            "Found Useful": "0"
        },
        {
            "avatar": "TL",
            "City": "Hooghly",
            "Provider": "The Lady with a Ladle",
            "Contact": "9051259157",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 12:42:43",
            "Found Useful": "0"
        },
        {
            "avatar": "SM",
            "City": "Hooghly",
            "Provider": "Sudipta Malakar",
            "Contact": "9088484812",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:49:57",
            "Found Useful": "0"
        },
        {
            "avatar": "MC",
            "City": "Hooghly",
            "Provider": "Mum Cooks Yum",
            "Contact": "8697662386",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 12:38:29",
            "Found Useful": "0"
        },
        {
            "avatar": "BB",
            "City": "Hooghly",
            "Provider": "Bhuri Bhoj",
            "Contact": "9051831115",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:43:51",
            "Found Useful": "0"
        },
        {
            "avatar": "BB",
            "City": "Hooghly",
            "Provider": "Bhuri Bhoj",
            "Contact": "9051831115",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:43:51",
            "Found Useful": "0"
        },
        {
            "avatar": "MC",
            "City": "Hooghly",
            "Provider": "Mum Cooks Yum",
            "Contact": "8697662386",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 12:38:29",
            "Found Useful": "0"
        },
        {
            "avatar": "HD",
            "City": "Hooghly",
            "Provider": "HOME DELIVERY",
            "Contact": "9038285145",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/13/2021 12:54:33",
            "Found Useful": "0"
        },
        {
            "avatar": "SM",
            "City": "Hooghly",
            "Provider": "Sudipta Malakar",
            "Contact": "9088484812",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:49:57",
            "Found Useful": "0"
        },
        {
            "avatar": "HD",
            "City": "Hooghly",
            "Provider": "HOME DELIVERY",
            "Contact": "9038285145",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/13/2021 12:54:33",
            "Found Useful": "0"
        }
    ],
    "Erode": [
        {
            "avatar": "QC",
            "City": "Erode",
            "Provider": "Quarantine Combo",
            "Contact": "9843294475",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 06:18:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FP",
            "City": "Erode",
            "Provider": "Food providers in Erode",
            "Contact": "9154154497",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 07:10:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Erode",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9715858513",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:56:00",
            "Found Useful": "0"
        },
        {
            "avatar": "QC",
            "City": "Erode",
            "Provider": "Quarantine Combo",
            "Contact": "9843294475",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 06:18:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Erode",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9715858513",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:56:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FP",
            "City": "Erode",
            "Provider": "Food providers in Erode",
            "Contact": "9154154497",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 07:10:00",
            "Found Useful": "0"
        }
    ],
    "Bengaluru (Bangalore) Rural": [
        {
            "avatar": "E",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "Evaa",
            "Contact": "9620009922",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "NH8",
            "Contact": "9606100274",
            "Notes": "Dal, Sabji, 2 Phulka, Rice...Khicdhi..",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TF",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "The Farmers Son",
            "Contact": "7624862020",
            "Notes": "100% Cold Pressed Juice, no added sugar, no water, no ice, no preservatives, all natural",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SF",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "Srk fruit shop",
            "Contact": "9535363355",
            "Notes": "Fresh fruits supply at wholesale prices",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "C",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "ChaiZo",
            "Contact": "8217675484",
            "Notes": "Chai for Covid patients in 3 flavours -Free home Delivery Plain ChaiAdrak Chai Adrak+Tulsi Chai ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "E",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "Evaa",
            "Contact": "9620009922",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AK",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "Adhvaithaa Kandaari",
            "Contact": "8147004916",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SF",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "Srk fruit shop",
            "Contact": "9535363355",
            "Notes": "Fresh fruits supply at wholesale prices",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SF",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "Srk fruit shop",
            "Contact": "9535363355",
            "Notes": "Fresh fruits supply at wholesale prices",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "Adhya",
            "Contact": "8095332362",
            "Notes": "Dal, rice, chapati,salad | kichadi, boiled egg, Raita, achar",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MF",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "MN Foods",
            "Contact": "9712952625",
            "Notes": "Home cooked breakfast (item of choice to the extent possible)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "Sachithas",
            "Contact": "7795196221",
            "Notes": "2 Chapathis, 1 Curry, 1 box rice, 1 box rasam",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TF",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "The Farmers Son",
            "Contact": "7624862020",
            "Notes": "100% Cold Pressed Juice, no added sugar, no water, no ice, no preservatives, all natural",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "C",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "ChaiZo",
            "Contact": "8217675484",
            "Notes": "Chai for Covid patients in 3 flavours -Free home Delivery Plain ChaiAdrak Chai Adrak+Tulsi Chai ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "Sachithas",
            "Contact": "7795196221",
            "Notes": "2 Chapathis, 1 Curry, 1 box rice, 1 box rasam",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TF",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "The Farmers Son",
            "Contact": "7624862020",
            "Notes": "100% Cold Pressed Juice, no added sugar, no water, no ice, no preservatives, all natural",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MF",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "MN Foods",
            "Contact": "9712952625",
            "Notes": "Home cooked breakfast (item of choice to the extent possible)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "NH8",
            "Contact": "9606100274",
            "Notes": "Dal, Sabji, 2 Phulka, Rice...Khicdhi..",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "NH8",
            "Contact": "9606100274",
            "Notes": "Dal, Sabji, 2 Phulka, Rice...Khicdhi..",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "Adhya",
            "Contact": "8095332362",
            "Notes": "Dal, rice, chapati,salad | kichadi, boiled egg, Raita, achar",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AK",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "Adhvaithaa Kandaari",
            "Contact": "8147004916",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "E",
            "City": "Bengaluru (Bangalore) Rural",
            "Provider": "Evaa",
            "Contact": "9620009922",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Ranchi": [
        {
            "avatar": "CY",
            "City": "Ranchi",
            "Provider": "Cafe Yum Yum",
            "Contact": "9835497696",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "JK",
            "City": "Ranchi",
            "Provider": "jai kitchen",
            "Contact": "7762909657",
            "Notes": "all food prepared is hygienic, authentic and delicious as i am a chef from last 16 years with national and international experience, all food prepared here is healthier and helpful for this pandemic and minimally charged. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CY",
            "City": "Ranchi",
            "Provider": "Cafe Yum Yum",
            "Contact": "9835497696",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "JK",
            "City": "Ranchi",
            "Provider": "jai kitchen",
            "Contact": "7762909657",
            "Notes": "all food prepared is hygienic, authentic and delicious as i am a chef from last 16 years with national and international experience, all food prepared here is healthier and helpful for this pandemic and minimally charged. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Visakhapatnam": [
        {
            "avatar": "HP",
            "City": "Visakhapatnam",
            "Provider": "Hotel PL Grand",
            "Contact": "9948810494",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HP",
            "City": "Visakhapatnam",
            "Provider": "Hotel PL Grand",
            "Contact": "9948810494",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MP",
            "City": "Visakhapatnam",
            "Provider": "Mr.Gandhi Prasad",
            "Contact": "9492733337",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HP",
            "City": "Visakhapatnam",
            "Provider": "Hotel PL Grand",
            "Contact": "9948810494",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HM",
            "City": "Visakhapatnam",
            "Provider": "Hotel Minerva Grand",
            "Contact": "8666678888",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MP",
            "City": "Visakhapatnam",
            "Provider": "Mr.Gandhi Prasad",
            "Contact": "9492733337",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HM",
            "City": "Visakhapatnam",
            "Provider": "Hotel Minerva Grand",
            "Contact": "8666678888",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Vadodara": [
        {
            "avatar": "2M",
            "City": "Vadodara",
            "Provider": "24Carats Multi Cuisine Restaurant",
            "Contact": "9227881149",
            "Notes": "An upscale fine dine restaurant that strives to offer an elegant experience of the highest level, \u201924 Carats\u2019 has kept up to the changing trends in food since the last three decades serving world cuisine & fusion food.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Vadodara",
            "Provider": "matru",
            "Contact": "6351950067",
            "Notes": "5 rotis, kathod, Gujarati Sabji, Rice, Dal, Salad, Pickle",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "2M",
            "City": "Vadodara",
            "Provider": "24Carats Multi Cuisine Restaurant",
            "Contact": "9227881149",
            "Notes": "An upscale fine dine restaurant that strives to offer an elegant experience of the highest level, \u201924 Carats\u2019 has kept up to the changing trends in food since the last three decades serving world cuisine & fusion food.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Vadodara",
            "Provider": "matru",
            "Contact": "6351950067",
            "Notes": "5 rotis, kathod, Gujarati Sabji, Rice, Dal, Salad, Pickle",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Howrah": [
        {
            "avatar": "NK",
            "City": "Howrah",
            "Provider": "not known",
            "Contact": "9830766739",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/2/2021 22:13:30",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Howrah",
            "Provider": "Sinchan",
            "Contact": "7980658684",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:41:45",
            "Found Useful": "0"
        },
        {
            "avatar": "NK",
            "City": "Howrah",
            "Provider": "not known",
            "Contact": "9674262038",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 14:46:10",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Howrah",
            "Provider": "Sinchan",
            "Contact": "7980658684",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:41:45",
            "Found Useful": "0"
        },
        {
            "avatar": "B",
            "City": "Howrah",
            "Provider": "Bhuribhoj",
            "Contact": "9051831115",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:40:47",
            "Found Useful": "0"
        },
        {
            "avatar": "NK",
            "City": "Howrah",
            "Provider": "not known",
            "Contact": "9674262038",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 14:46:10",
            "Found Useful": "0"
        },
        {
            "avatar": "B",
            "City": "Howrah",
            "Provider": "Bhuribhoj",
            "Contact": "9051831115",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:40:47",
            "Found Useful": "0"
        },
        {
            "avatar": "NK",
            "City": "Howrah",
            "Provider": "not known",
            "Contact": "8697917279",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/13/2021 19:28:30",
            "Found Useful": "0"
        },
        {
            "avatar": "NK",
            "City": "Howrah",
            "Provider": "not known",
            "Contact": "8697917279",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/13/2021 19:28:30",
            "Found Useful": "0"
        },
        {
            "avatar": "AD",
            "City": "Howrah",
            "Provider": "Anirban das",
            "Contact": "8910775984",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:40:32",
            "Found Useful": "0"
        },
        {
            "avatar": "AD",
            "City": "Howrah",
            "Provider": "Anirban das",
            "Contact": "8910775984",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:40:32",
            "Found Useful": "0"
        },
        {
            "avatar": "NK",
            "City": "Howrah",
            "Provider": "not known",
            "Contact": "9830766739",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/2/2021 22:13:30",
            "Found Useful": "0"
        }
    ],
    "Salem": [
        {
            "avatar": "SS",
            "City": "Salem",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9994349579",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:26:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Salem",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9994349579",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:26:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Salem",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9994349579",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:26:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Salem",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9994349579",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:26:00",
            "Found Useful": "0"
        }
    ],
    "Tiruchirappalli": [
        {
            "avatar": "FF",
            "City": "Tiruchirappalli",
            "Provider": "Free food for all covid patients ",
            "Contact": "9790834612",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 07:19:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Tiruchirappalli",
            "Provider": "Food For free",
            "Contact": "9176038006",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 07:00:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Tiruchirappalli",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9942904550",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-22 00:58:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Tiruchirappalli",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9942904550",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-22 00:58:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Tiruchirappalli",
            "Provider": "Food For free",
            "Contact": "9176038006",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 07:00:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Tiruchirappalli",
            "Provider": "Free food for all covid patients ",
            "Contact": "9790834612",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 07:19:00",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Tiruchirappalli",
            "Provider": "Free food for all covid patients ",
            "Contact": "9790834612",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 07:19:00",
            "Found Useful": "0"
        }
    ],
    "Jorhat": [
        {
            "avatar": "GK",
            "City": "Jorhat",
            "Provider": "Giriraj Karnani",
            "Contact": "8486082626",
            "Notes": "Home services (food/groceries/vegetables)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DA",
            "City": "Jorhat",
            "Provider": "Dhaba AS-03",
            "Contact": "7896431161",
            "Notes": "Meal delivery service for Covid patients. As per menu. Paid.Thali rate (breakfast, lunch and dinner) - INR 340-380",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GK",
            "City": "Jorhat",
            "Provider": "Giriraj Karnani",
            "Contact": "8486082626",
            "Notes": "Home services (food/groceries/vegetables)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CB",
            "City": "Jorhat",
            "Provider": "Cafe Beans- Shekhar Maloo and Yash Gattani",
            "Contact": "9954333779",
            "Notes": "Free Food",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CB",
            "City": "Jorhat",
            "Provider": "Cafe Beans- Shekhar Maloo and Yash Gattani",
            "Contact": "9954333779",
            "Notes": "Free Food",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DA",
            "City": "Jorhat",
            "Provider": "Dhaba AS-03",
            "Contact": "7896431161",
            "Notes": "Meal delivery service for Covid patients. As per menu. Paid.Thali rate (breakfast, lunch and dinner) - INR 340-380",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Coimbatore": [
        {
            "avatar": "S",
            "City": "Coimbatore",
            "Provider": "ShivanjaliSrinivasan",
            "Contact": "9952425643",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "IC",
            "City": "Coimbatore",
            "Provider": "Iskcon Coimbatore",
            "Contact": "8270840006",
            "Notes": "Home delivers free lunch to covid positive quarantined family",
            "Verified": "Verified",
            "Last Verified": " 2021-05-15 12:23:00",
            "Found Useful": "0"
        },
        {
            "avatar": "RP",
            "City": "Coimbatore",
            "Provider": "Rita Parekh",
            "Contact": "9345881947",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KK",
            "City": "Coimbatore",
            "Provider": "Komal's KitchenSonam",
            "Contact": "9994302221",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Coimbatore",
            "Provider": "Mandeep Kaur",
            "Contact": "9952343922",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BK",
            "City": "Coimbatore",
            "Provider": "Bhatijas Kitchen",
            "Contact": "8754802444",
            "Notes": "Bhatija's Kitchen is a pure veg restaurant in the heart of Coimbatore. Serving north indian food We provide home style dishes that are healthy & satisfy your taste buds Call us to customise your meals. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RP",
            "City": "Coimbatore",
            "Provider": "Rita Parekh",
            "Contact": "9345881947",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HT",
            "City": "Coimbatore",
            "Provider": "Healthy Treats",
            "Contact": "9944520883",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Coimbatore",
            "Provider": "ShivanjaliSrinivasan",
            "Contact": "9952425643",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Coimbatore",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9345225024",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:30:00",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Coimbatore",
            "Provider": "Mandeep Kaur",
            "Contact": "9952343922",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BK",
            "City": "Coimbatore",
            "Provider": "Bhatijas Kitchen",
            "Contact": "8754802444",
            "Notes": "Bhatija's Kitchen is a pure veg restaurant in the heart of Coimbatore. Serving north indian food We provide home style dishes that are healthy & satisfy your taste buds Call us to customise your meals. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "IC",
            "City": "Coimbatore",
            "Provider": "Iskcon Coimbatore",
            "Contact": "8270840006",
            "Notes": "Home delivers free lunch to covid positive quarantined family",
            "Verified": "Verified",
            "Last Verified": " 2021-05-15 12:23:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SC",
            "City": "Coimbatore",
            "Provider": "Shree Caf\u00e9 Deva Govindaraju",
            "Contact": "9047657700",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "L",
            "City": "Coimbatore",
            "Provider": "Latha",
            "Contact": "9894955776",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Coimbatore",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9345225024",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:30:00",
            "Found Useful": "0"
        },
        {
            "avatar": "HM",
            "City": "Coimbatore",
            "Provider": "Happy MealsHema",
            "Contact": "8870167237",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HM",
            "City": "Coimbatore",
            "Provider": "Happy MealsHema",
            "Contact": "8870167237",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KK",
            "City": "Coimbatore",
            "Provider": "Komal's KitchenSonam",
            "Contact": "9994302221",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SB",
            "City": "Coimbatore",
            "Provider": "Sri Balaji Catering Service",
            "Contact": "9442628731",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 04:45:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SC",
            "City": "Coimbatore",
            "Provider": "Shree Caf\u00e9 Deva Govindaraju",
            "Contact": "9047657700",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HT",
            "City": "Coimbatore",
            "Provider": "Healthy Treats",
            "Contact": "9944520883",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "L",
            "City": "Coimbatore",
            "Provider": "Latha",
            "Contact": "9894955776",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SB",
            "City": "Coimbatore",
            "Provider": "Sri Balaji Catering Service",
            "Contact": "9442628731",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 04:45:00",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Coimbatore",
            "Provider": "ShivanjaliSrinivasan",
            "Contact": "9952425643",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "North 24 Parganas": [
        {
            "avatar": "A",
            "City": "North 24 Parganas",
            "Provider": "Adrija",
            "Contact": "6291575300",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:41:18",
            "Found Useful": "0"
        },
        {
            "avatar": "AM",
            "City": "North 24 Parganas",
            "Provider": "Arijit Mukherjee",
            "Contact": "89810873761",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:46:55",
            "Found Useful": "0"
        },
        {
            "avatar": "R",
            "City": "North 24 Parganas",
            "Provider": "Ritam",
            "Contact": "7479134353",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/13/2021 15:25:20",
            "Found Useful": "0"
        },
        {
            "avatar": "R2",
            "City": "North 24 Parganas",
            "Provider": "Ready 2 Serve",
            "Contact": "9330386851",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:45:14",
            "Found Useful": "0"
        },
        {
            "avatar": "AB",
            "City": "North 24 Parganas",
            "Provider": "Ahare Bahare",
            "Contact": "9051620985",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/8/2021 19:35:44",
            "Found Useful": "0"
        },
        {
            "avatar": "RV",
            "City": "North 24 Parganas",
            "Provider": "Red volunteers Barrackpore",
            "Contact": "8697111856",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/4/2021 16:14:59",
            "Found Useful": "0"
        },
        {
            "avatar": "HD",
            "City": "North 24 Parganas",
            "Provider": "HOME DELIVERY",
            "Contact": "9874624389",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/13/2021 16:38:41",
            "Found Useful": "0"
        },
        {
            "avatar": "HB",
            "City": "North 24 Parganas",
            "Provider": "Heshel by red volunteers kamarhati",
            "Contact": "9674953080",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/4/2021 16:02:11",
            "Found Useful": "0"
        },
        {
            "avatar": "R2",
            "City": "North 24 Parganas",
            "Provider": "Ready 2 Serve",
            "Contact": "9330386851",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:45:14",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "North 24 Parganas",
            "Provider": "sayani sengupta",
            "Contact": "9903583823",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:49:11",
            "Found Useful": "0"
        },
        {
            "avatar": "TD",
            "City": "North 24 Parganas",
            "Provider": "Timir Dutta chowdhury",
            "Contact": "9830237797",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/8/2021 19:38:46",
            "Found Useful": "0"
        },
        {
            "avatar": "HD",
            "City": "North 24 Parganas",
            "Provider": "HOME DELIVERY",
            "Contact": "9874624389",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/13/2021 16:38:41",
            "Found Useful": "0"
        },
        {
            "avatar": "KB",
            "City": "North 24 Parganas",
            "Provider": "Khao by Ritik Lal",
            "Contact": "6291530266",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 15:08:25",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "North 24 Parganas",
            "Provider": "Suvendu Samanta",
            "Contact": "9932414398",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:15:21",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "North 24 Parganas",
            "Provider": "Suvendu Samanta",
            "Contact": "9932414398",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:15:21",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "North 24 Parganas",
            "Provider": "sayani sengupta",
            "Contact": "9903583823",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:49:11",
            "Found Useful": "0"
        },
        {
            "avatar": "AM",
            "City": "North 24 Parganas",
            "Provider": "Arijit Mukherjee",
            "Contact": "89810873761",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:46:55",
            "Found Useful": "0"
        },
        {
            "avatar": "AB",
            "City": "North 24 Parganas",
            "Provider": "Ahare Bahare",
            "Contact": "9051620985",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/8/2021 19:35:44",
            "Found Useful": "0"
        },
        {
            "avatar": "SC",
            "City": "North 24 Parganas",
            "Provider": "Shreya's Cafe",
            "Contact": "7439752707",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:56:28",
            "Found Useful": "0"
        },
        {
            "avatar": "CC",
            "City": "North 24 Parganas",
            "Provider": "Calcutta cookbook ",
            "Contact": "9051339185",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KB",
            "City": "North 24 Parganas",
            "Provider": "Khao by Ritik Lal",
            "Contact": "6291530266",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 15:08:25",
            "Found Useful": "0"
        },
        {
            "avatar": "UK",
            "City": "North 24 Parganas",
            "Provider": "Unnati Kitchen",
            "Contact": "9830610008",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:34:38",
            "Found Useful": "0"
        },
        {
            "avatar": "AG",
            "City": "North 24 Parganas",
            "Provider": "Akash guha roy",
            "Contact": "918777068028",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 10:45:00",
            "Found Useful": "0"
        },
        {
            "avatar": "HB",
            "City": "North 24 Parganas",
            "Provider": "Heshel by red volunteers kamarhati",
            "Contact": "9674953080",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/4/2021 16:02:11",
            "Found Useful": "0"
        },
        {
            "avatar": "A/",
            "City": "North 24 Parganas",
            "Provider": "Anushongik / Sanjoy saha",
            "Contact": "9051478526",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:42:10",
            "Found Useful": "0"
        },
        {
            "avatar": "RS",
            "City": "North 24 Parganas",
            "Provider": "Ra seek",
            "Contact": "7980847805",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:48:59",
            "Found Useful": "0"
        },
        {
            "avatar": "SM",
            "City": "North 24 Parganas",
            "Provider": "Subhra Mukherjee",
            "Contact": "8902105897",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 12:52:19",
            "Found Useful": "0"
        },
        {
            "avatar": "NK",
            "City": "North 24 Parganas",
            "Provider": "Nitu's Kitchen",
            "Contact": "9330122223",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:21:29",
            "Found Useful": "0"
        },
        {
            "avatar": "GG",
            "City": "North 24 Parganas",
            "Provider": "Goopi gyne, Bagha byne",
            "Contact": "8100611018",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/8/2021 19:43:35",
            "Found Useful": "0"
        },
        {
            "avatar": "RS",
            "City": "North 24 Parganas",
            "Provider": "Ra seek",
            "Contact": "7980847805",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:48:59",
            "Found Useful": "0"
        },
        {
            "avatar": "SM",
            "City": "North 24 Parganas",
            "Provider": "Subhra Mukherjee",
            "Contact": "8902105897",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 12:52:19",
            "Found Useful": "0"
        },
        {
            "avatar": "CC",
            "City": "North 24 Parganas",
            "Provider": "Calcutta cookbook ",
            "Contact": "9051339185",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AG",
            "City": "North 24 Parganas",
            "Provider": "Akash guha roy",
            "Contact": "918777068028",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 10:45:00",
            "Found Useful": "0"
        },
        {
            "avatar": "R",
            "City": "North 24 Parganas",
            "Provider": "Ritam",
            "Contact": "7479134353",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/13/2021 15:25:20",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "North 24 Parganas",
            "Provider": "sejuti",
            "Contact": "8617224535",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "UK",
            "City": "North 24 Parganas",
            "Provider": "Unnati Kitchen",
            "Contact": "9830610008",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:34:38",
            "Found Useful": "0"
        },
        {
            "avatar": "ME",
            "City": "North 24 Parganas",
            "Provider": "Maa er heshel",
            "Contact": "8013093423",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:49:03",
            "Found Useful": "0"
        },
        {
            "avatar": "GG",
            "City": "North 24 Parganas",
            "Provider": "Goopi gyne, Bagha byne",
            "Contact": "8100611018",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/8/2021 19:43:35",
            "Found Useful": "0"
        },
        {
            "avatar": "RV",
            "City": "North 24 Parganas",
            "Provider": "Red volunteers Barrackpore",
            "Contact": "8697111856",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/4/2021 16:14:59",
            "Found Useful": "0"
        },
        {
            "avatar": "A/",
            "City": "North 24 Parganas",
            "Provider": "Anushongik / Sanjoy saha",
            "Contact": "9051478526",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:42:10",
            "Found Useful": "0"
        },
        {
            "avatar": "ME",
            "City": "North 24 Parganas",
            "Provider": "Maa er heshel",
            "Contact": "8013093423",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/3/2021 12:49:03",
            "Found Useful": "0"
        },
        {
            "avatar": "MD",
            "City": "North 24 Parganas",
            "Provider": "Meghnath Doloi",
            "Contact": "9002619893",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:16:37",
            "Found Useful": "0"
        },
        {
            "avatar": "SC",
            "City": "North 24 Parganas",
            "Provider": "Shreya's Cafe",
            "Contact": "7439752707",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:56:28",
            "Found Useful": "0"
        },
        {
            "avatar": "NK",
            "City": "North 24 Parganas",
            "Provider": "Nitu's Kitchen",
            "Contact": "9330122223",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 13:21:29",
            "Found Useful": "0"
        },
        {
            "avatar": "HD",
            "City": "North 24 Parganas",
            "Provider": "HOME DELIVERY",
            "Contact": "9748768390",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/10/2021 8:55:39",
            "Found Useful": "0"
        },
        {
            "avatar": "TD",
            "City": "North 24 Parganas",
            "Provider": "Timir Dutta chowdhury",
            "Contact": "9830237797",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/8/2021 19:38:46",
            "Found Useful": "0"
        },
        {
            "avatar": "HD",
            "City": "North 24 Parganas",
            "Provider": "HOME DELIVERY",
            "Contact": "9748768390",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/10/2021 8:55:39",
            "Found Useful": "0"
        },
        {
            "avatar": "MD",
            "City": "North 24 Parganas",
            "Provider": "Meghnath Doloi",
            "Contact": "9002619893",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 4/28/2021 12:16:37",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "North 24 Parganas",
            "Provider": "sejuti",
            "Contact": "8617224535",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AM",
            "City": "North 24 Parganas",
            "Provider": "Arijit Mukherjee",
            "Contact": "89810873761",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:46:55",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "North 24 Parganas",
            "Provider": "Adrija",
            "Contact": "6291575300",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:41:18",
            "Found Useful": "0"
        },
        {
            "avatar": "SA",
            "City": "North 24 Parganas",
            "Provider": "Shyam and Showmen",
            "Contact": "7059850084",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 15:09:27",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "North 24 Parganas",
            "Provider": "Adrija",
            "Contact": "6291575300",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:41:18",
            "Found Useful": "0"
        },
        {
            "avatar": "SA",
            "City": "North 24 Parganas",
            "Provider": "Shyam and Showmen",
            "Contact": "7059850084",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/11/2021 15:09:27",
            "Found Useful": "0"
        }
    ],
    "Papum Pare": [
        {
            "avatar": "A",
            "City": "Papum Pare",
            "Provider": "Apeats",
            "Contact": "8729843483",
            "Notes": "Groceries and Home Items | Calling hours: 10 am to 6:30 pm",
            "Verified": "Verified",
            "Last Verified": " 5/26/2021 16:20:24",
            "Found Useful": "0"
        },
        {
            "avatar": "NO",
            "City": "Papum Pare",
            "Provider": "Need on Wheel",
            "Contact": "6009909268",
            "Notes": "Groceries and Home Items|  Calling hours: 10 am to 8 pm",
            "Verified": "Verified",
            "Last Verified": " 5/26/2021 16:20:24",
            "Found Useful": "0"
        },
        {
            "avatar": "ND",
            "City": "Papum Pare",
            "Provider": "NE Departmental Store",
            "Contact": "8119005950",
            "Notes": "Groceries and Home Items|  Calling hours: 10 am to 5:30 pm",
            "Verified": "Verified",
            "Last Verified": " 5/26/2021 16:20:24",
            "Found Useful": "0"
        },
        {
            "avatar": "UT",
            "City": "Papum Pare",
            "Provider": "U Tell Us",
            "Contact": "7640072516",
            "Notes": "Groceries and Home Items ",
            "Verified": "Verified",
            "Last Verified": " 5/26/2021 16:20:24",
            "Found Useful": "0"
        },
        {
            "avatar": "ND",
            "City": "Papum Pare",
            "Provider": "NESS Delivery ",
            "Contact": "6009974914",
            "Notes": "Groceries and Home Items ",
            "Verified": "Verified",
            "Last Verified": " 5/27/2021 15:28:09",
            "Found Useful": "0"
        },
        {
            "avatar": "LM",
            "City": "Papum Pare",
            "Provider": "Liem Mart ",
            "Contact": "8787631996",
            "Notes": "Groceries and Home Items|  Calling hours: 2pm- 8:30 pm",
            "Verified": "Verified",
            "Last Verified": " 5/27/2021 15:28:09",
            "Found Useful": "0"
        },
        {
            "avatar": "KD",
            "City": "Papum Pare",
            "Provider": "KK Departmental Store",
            "Contact": "7005441574",
            "Notes": "Groceries and Home Items |  Calling hours: 9 am- 7pm",
            "Verified": "Verified",
            "Last Verified": " 5/27/2021 15:28:09",
            "Found Useful": "0"
        },
        {
            "avatar": "KD",
            "City": "Papum Pare",
            "Provider": "KK Departmental Store",
            "Contact": "7005441574",
            "Notes": "Groceries and Home Items |  Calling hours: 9 am- 7pm",
            "Verified": "Verified",
            "Last Verified": " 5/27/2021 15:28:09",
            "Found Useful": "0"
        },
        {
            "avatar": "ND",
            "City": "Papum Pare",
            "Provider": "NE Departmental Store",
            "Contact": "8119005950",
            "Notes": "Groceries and Home Items|  Calling hours: 10 am to 5:30 pm",
            "Verified": "Verified",
            "Last Verified": " 5/26/2021 16:20:24",
            "Found Useful": "0"
        },
        {
            "avatar": "VM",
            "City": "Papum Pare",
            "Provider": "Vishal Mega Mart",
            "Contact": "7217888095",
            "Notes": "Groceries and Home Items ",
            "Verified": "Verified",
            "Last Verified": " 5/27/2021 15:28:09",
            "Found Useful": "0"
        },
        {
            "avatar": "H",
            "City": "Papum Pare",
            "Provider": "Hungriji",
            "Contact": "9383345120",
            "Notes": "Groceries and Home Items |  Calling hours: 10 am to 6 pm",
            "Verified": "Verified",
            "Last Verified": " 5/26/2021 16:20:24",
            "Found Useful": "0"
        },
        {
            "avatar": "HP",
            "City": "Papum Pare",
            "Provider": "Henkako Plus",
            "Contact": "6009547302",
            "Notes": "Groceries and Home Items| Calling hours: 10 am to 6 pm",
            "Verified": "Verified",
            "Last Verified": " 5/26/2021 16:20:24",
            "Found Useful": "0"
        },
        {
            "avatar": "NO",
            "City": "Papum Pare",
            "Provider": "Need on Wheel",
            "Contact": "6009909268",
            "Notes": "Groceries and Home Items|  Calling hours: 10 am to 8 pm",
            "Verified": "Verified",
            "Last Verified": " 5/26/2021 16:20:24",
            "Found Useful": "0"
        },
        {
            "avatar": "DD",
            "City": "Papum Pare",
            "Provider": "Dukan Dada",
            "Contact": "6909665305",
            "Notes": "Groceries and Home Items ",
            "Verified": "Verified",
            "Last Verified": " 5/26/2021 16:20:24",
            "Found Useful": "0"
        },
        {
            "avatar": "DD",
            "City": "Papum Pare",
            "Provider": "Dukan Dada",
            "Contact": "6909665305",
            "Notes": "Groceries and Home Items ",
            "Verified": "Verified",
            "Last Verified": " 5/26/2021 16:20:24",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Papum Pare",
            "Provider": "Apeats",
            "Contact": "8729843483",
            "Notes": "Groceries and Home Items | Calling hours: 10 am to 6:30 pm",
            "Verified": "Verified",
            "Last Verified": " 5/26/2021 16:20:24",
            "Found Useful": "0"
        },
        {
            "avatar": "H",
            "City": "Papum Pare",
            "Provider": "Hungriji",
            "Contact": "9383345120",
            "Notes": "Groceries and Home Items |  Calling hours: 10 am to 6 pm",
            "Verified": "Verified",
            "Last Verified": " 5/26/2021 16:20:24",
            "Found Useful": "0"
        },
        {
            "avatar": "HP",
            "City": "Papum Pare",
            "Provider": "Henkako Plus",
            "Contact": "6009547302",
            "Notes": "Groceries and Home Items| Calling hours: 10 am to 6 pm",
            "Verified": "Verified",
            "Last Verified": " 5/26/2021 16:20:24",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Papum Pare",
            "Provider": "MMart ",
            "Contact": "9774001860",
            "Notes": "Groceries and Home Items|  Calling hours: 9 am- 5 pm",
            "Verified": "Verified",
            "Last Verified": " 5/27/2021 15:28:09",
            "Found Useful": "0"
        },
        {
            "avatar": "VM",
            "City": "Papum Pare",
            "Provider": "Vishal Mega Mart",
            "Contact": "7217888095",
            "Notes": "Groceries and Home Items ",
            "Verified": "Verified",
            "Last Verified": " 5/27/2021 15:28:09",
            "Found Useful": "0"
        },
        {
            "avatar": "LM",
            "City": "Papum Pare",
            "Provider": "Liem Mart ",
            "Contact": "8787631996",
            "Notes": "Groceries and Home Items|  Calling hours: 2pm- 8:30 pm",
            "Verified": "Verified",
            "Last Verified": " 5/27/2021 15:28:09",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "Papum Pare",
            "Provider": "MMart ",
            "Contact": "9774001860",
            "Notes": "Groceries and Home Items|  Calling hours: 9 am- 5 pm",
            "Verified": "Verified",
            "Last Verified": " 5/27/2021 15:28:09",
            "Found Useful": "0"
        },
        {
            "avatar": "UT",
            "City": "Papum Pare",
            "Provider": "U Tell Us",
            "Contact": "7640072516",
            "Notes": "Groceries and Home Items ",
            "Verified": "Verified",
            "Last Verified": " 5/26/2021 16:20:24",
            "Found Useful": "0"
        },
        {
            "avatar": "ND",
            "City": "Papum Pare",
            "Provider": "NESS Delivery ",
            "Contact": "6009974914",
            "Notes": "Groceries and Home Items ",
            "Verified": "Verified",
            "Last Verified": " 5/27/2021 15:28:09",
            "Found Useful": "0"
        }
    ],
    "Jaipur": [
        {
            "avatar": "G",
            "City": "Jaipur",
            "Provider": "gyanjeecaterers",
            "Contact": "9602088003",
            "Notes": "150 per meal1 rice1vegetable(tinda, bhindi,turai , lauki , aloo)4 chapati 1 lentil",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "G",
            "City": "Jaipur",
            "Provider": "gyanjeecaterers",
            "Contact": "9602088003",
            "Notes": "150 per meal1 rice1vegetable(tinda, bhindi,turai , lauki , aloo)4 chapati 1 lentil",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Jaipur",
            "Provider": "noname",
            "Contact": "9571344816",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Jaipur",
            "Provider": "noname",
            "Contact": "9571344816",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Gurgaon": [
        {
            "avatar": "CM",
            "City": "Gurgaon",
            "Provider": "Covid meals/Vinum",
            "Contact": "9910274260",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Gurgaon",
            "Provider": "Shipra",
            "Contact": "9810774958",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PS",
            "City": "Gurgaon",
            "Provider": "Prithvi Salian",
            "Contact": "9821582205",
            "Notes": "Can cook home cooked meals, roti sabzi and salad for the ones in genuine need",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SJ",
            "City": "Gurgaon",
            "Provider": "Shubhra Jain",
            "Contact": "7620971566",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SJ",
            "City": "Gurgaon",
            "Provider": "Shubhra Jain",
            "Contact": "7620971566",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FS",
            "City": "Gurgaon",
            "Provider": "Food supplier",
            "Contact": "9654989733",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TB",
            "City": "Gurgaon",
            "Provider": "Tasty Box",
            "Contact": "8130035293",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "R",
            "City": "Gurgaon",
            "Provider": "Rashmi",
            "Contact": "9811201254",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TB",
            "City": "Gurgaon",
            "Provider": "Tasty Box",
            "Contact": "8130035293",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Gurgaon",
            "Provider": "Shipra",
            "Contact": "9810774958",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Gurgaon",
            "Provider": "Sonali",
            "Contact": "9910474449",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "R",
            "City": "Gurgaon",
            "Provider": "Rashmi",
            "Contact": "9811201254",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PS",
            "City": "Gurgaon",
            "Provider": "Prithvi Salian",
            "Contact": "9821582205",
            "Notes": "Can cook home cooked meals, roti sabzi and salad for the ones in genuine need",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FS",
            "City": "Gurgaon",
            "Provider": "Food supplier",
            "Contact": "9654989733",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Gurgaon",
            "Provider": "Sonali",
            "Contact": "9910474449",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CM",
            "City": "Gurgaon",
            "Provider": "Covid meals/Vinum",
            "Contact": "9910274260",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "North Delhi": [
        {
            "avatar": "R",
            "City": "North Delhi",
            "Provider": "Ritu",
            "Contact": "9818254051",
            "Notes": "covidespecial diet",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "R",
            "City": "North Delhi",
            "Provider": "Ritu",
            "Contact": "9818254051",
            "Notes": "covidespecial diet",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "North Delhi",
            "Provider": "Milli",
            "Contact": "9999336411",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "M",
            "City": "North Delhi",
            "Provider": "Milli",
            "Contact": "9999336411",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Bhopal": [
        {
            "avatar": "FF",
            "City": "Bhopal",
            "Provider": "Food for +",
            "Contact": "6264914927",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Bhopal",
            "Provider": "Manjeet Kapoor",
            "Contact": "9893012444",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Bhopal",
            "Provider": "Food for +",
            "Contact": "6264914927",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TT",
            "City": "Bhopal",
            "Provider": "Tias tiffins",
            "Contact": "9920992636",
            "Notes": "Dal rice roti sabji occasional fruits",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TT",
            "City": "Bhopal",
            "Provider": "Tias tiffins",
            "Contact": "9920992636",
            "Notes": "Dal rice roti sabji occasional fruits",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FF",
            "City": "Bhopal",
            "Provider": "Food for +",
            "Contact": "6264914927",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AB",
            "City": "Bhopal",
            "Provider": "Asad Baig",
            "Contact": "7987608456",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AA",
            "City": "Bhopal",
            "Provider": "Ashish Agarwal",
            "Contact": "9424444128",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AB",
            "City": "Bhopal",
            "Provider": "Asad Baig",
            "Contact": "7987608456",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AA",
            "City": "Bhopal",
            "Provider": "Ashish Agarwal",
            "Contact": "9424444128",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Bhopal",
            "Provider": "Manjeet Kapoor",
            "Contact": "9893012444",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Raipur": [
        {
            "avatar": "RT",
            "City": "Raipur",
            "Provider": "RS Tiffin Services",
            "Contact": "8827710105",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Raipur",
            "Provider": "MPS Kitchen",
            "Contact": "9074912841",
            "Notes": "mps_kitchen",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MS",
            "City": "Raipur",
            "Provider": "Maa Sharda Sweet & Salt",
            "Contact": "9685113985",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NV",
            "City": "Raipur",
            "Provider": "Nidhi Venkat",
            "Contact": "9445992889",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RT",
            "City": "Raipur",
            "Provider": "RS Tiffin Services",
            "Contact": "8827710105",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NV",
            "City": "Raipur",
            "Provider": "Nidhi Venkat",
            "Contact": "9445992889",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Raipur",
            "Provider": "MPS Kitchen",
            "Contact": "9074912841",
            "Notes": "mps_kitchen",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MS",
            "City": "Raipur",
            "Provider": "Maa Sharda Sweet & Salt",
            "Contact": "9685113985",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Raipur",
            "Provider": "MPS Kitchen",
            "Contact": "9074912841",
            "Notes": "mps_kitchen",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RT",
            "City": "Raipur",
            "Provider": "RS Tiffin Services",
            "Contact": "8827710105",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Karimganj": [
        {
            "avatar": "MU",
            "City": "Karimganj",
            "Provider": "Misbah Uddin Ahmed",
            "Contact": "9045252120",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MU",
            "City": "Karimganj",
            "Provider": "Misbah Uddin Ahmed",
            "Contact": "9045252120",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Cachar": [
        {
            "avatar": "SK",
            "City": "Cachar",
            "Provider": "Sristi's Kitchen",
            "Contact": "9401707553",
            "Notes": "Home cooked lunch with properly maintained hygiene for home isolates, caretake of Covid patients, students at your doorstep now at Silchar.Area: No delivery charges for areas - Meherpur, Link Road, National Highway, Kathal Road, Sunai Road, Rangirkhari, Hospital Road, Bilpar (additional delivery charges for other areas)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SK",
            "City": "Cachar",
            "Provider": "Sristi's Kitchen",
            "Contact": "9401707553",
            "Notes": "Home cooked lunch with properly maintained hygiene for home isolates, caretake of Covid patients, students at your doorstep now at Silchar.Area: No delivery charges for areas - Meherpur, Link Road, National Highway, Kathal Road, Sunai Road, Rangirkhari, Hospital Road, Bilpar (additional delivery charges for other areas)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Thoothukudi (Tuticorin)": [
        {
            "avatar": "SS",
            "City": "Thoothukudi (Tuticorin)",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9942118444",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:59:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Thoothukudi (Tuticorin)",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9942118444",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:59:00",
            "Found Useful": "0"
        }
    ],
    "Dindigul": [
        {
            "avatar": "SS",
            "City": "Dindigul",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "8300262209",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:31:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Dindigul",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "8300262209",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:31:00",
            "Found Useful": "0"
        }
    ],
    "Kanchipuram": [
        {
            "avatar": "9B",
            "City": "Kanchipuram",
            "Provider": "90s Bistro",
            "Contact": "7397273505",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Kanchipuram",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "8438395980",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:31:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Kanchipuram",
            "Provider": "Sri Satya Sai Seva",
            "Contact": "9444030136",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 07:20:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Kanchipuram",
            "Provider": "Sri Satya Sai Seva",
            "Contact": "9444030136",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 07:20:00",
            "Found Useful": "0"
        },
        {
            "avatar": "9B",
            "City": "Kanchipuram",
            "Provider": "90s Bistro",
            "Contact": "7397273505",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Kanchipuram",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "8438395980",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:31:00",
            "Found Useful": "0"
        }
    ],
    "Barpeta": [
        {
            "avatar": "BD",
            "City": "Barpeta",
            "Provider": "Bhargav Das",
            "Contact": "8721072489",
            "Notes": "Home services (food/groceries/vegetables) and Transport",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BD",
            "City": "Barpeta",
            "Provider": "Bhargav Das",
            "Contact": "8721072489",
            "Notes": "Home services (food/groceries/vegetables) and Transport",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Mysuru (Mysore)": [
        {
            "avatar": "S",
            "City": "Mysuru (Mysore)",
            "Provider": "Shree",
            "Contact": "9740399700",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DS",
            "City": "Mysuru (Mysore)",
            "Provider": "Dixit Swasthya Ahara",
            "Contact": "9481819312",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Mysuru (Mysore)",
            "Provider": "Shree",
            "Contact": "9740399700",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "I",
            "City": "Mysuru (Mysore)",
            "Provider": "Ikshus",
            "Contact": "9844643897",
            "Notes": "Any types of kichidi, chapathi, vegetable curry, salad, rice-rasam, upma, etc.. or any items desired by patients. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "DS",
            "City": "Mysuru (Mysore)",
            "Provider": "Dixit Swasthya Ahara",
            "Contact": "9481819312",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SH",
            "City": "Mysuru (Mysore)",
            "Provider": "Sowmya Hemnath",
            "Contact": "8792190072",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "I",
            "City": "Mysuru (Mysore)",
            "Provider": "Ikshus",
            "Contact": "9844643897",
            "Notes": "Any types of kichidi, chapathi, vegetable curry, salad, rice-rasam, upma, etc.. or any items desired by patients. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FB",
            "City": "Mysuru (Mysore)",
            "Provider": "Food Box Mysuru",
            "Contact": "9620212227",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Mysuru (Mysore)",
            "Provider": "Shree",
            "Contact": "9740399700",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "FB",
            "City": "Mysuru (Mysore)",
            "Provider": "Food Box Mysuru",
            "Contact": "9620212227",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SH",
            "City": "Mysuru (Mysore)",
            "Provider": "Sowmya Hemnath",
            "Contact": "8792190072",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Mysuru (Mysore)",
            "Provider": "AP",
            "Contact": "9620909331",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Mysuru (Mysore)",
            "Provider": "AP",
            "Contact": "9620909331",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Aurangabad": [
        {
            "avatar": "TS",
            "City": "Aurangabad",
            "Provider": "Tuljai Swayampak Ghar",
            "Contact": "8669073429",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TS",
            "City": "Aurangabad",
            "Provider": "Tuljai Swayampak Ghar",
            "Contact": "8669073429",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AD",
            "City": "Aurangabad",
            "Provider": "Atiishay dabba pure veg",
            "Contact": "7588978897",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AD",
            "City": "Aurangabad",
            "Provider": "Atiishay dabba pure veg",
            "Contact": "7588978897",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Sonitpur": [
        {
            "avatar": "R",
            "City": "Sonitpur",
            "Provider": "Raj",
            "Contact": "9483428069",
            "Notes": "Home services (food/groceries/vegetables)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "R",
            "City": "Sonitpur",
            "Provider": "Raj",
            "Contact": "9483428069",
            "Notes": "Home services (food/groceries/vegetables)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Central Delhi": [
        {
            "avatar": "SS",
            "City": "Central Delhi",
            "Provider": "Savoury Sage",
            "Contact": "9654562471",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Central Delhi",
            "Provider": "Savoury Sage",
            "Contact": "9654562471",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Central Delhi",
            "Provider": "Maa ka ChulahPiyush Mathur",
            "Contact": "7701993549",
            "Notes": "@maakachulah",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "Central Delhi",
            "Provider": "Maa ka ChulahPiyush Mathur",
            "Contact": "7701993549",
            "Notes": "@maakachulah",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Dhanbad": [
        {
            "avatar": "SS",
            "City": "Dhanbad",
            "Provider": "Social serv",
            "Contact": "9334000222",
            "Notes": "Basic meal dal sabji chapati and sabji",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Dhanbad",
            "Provider": "Social serv",
            "Contact": "9334000222",
            "Notes": "Basic meal dal sabji chapati and sabji",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Haridwar": [
        {
            "avatar": "TW",
            "City": "Haridwar",
            "Provider": "The Wisdom Global School",
            "Contact": "9837033485",
            "Notes": "Good hygiene food  , Break fast delivery up 9 , Lunch up 2 Pm and Dinner up to 8 Pm , Fresh food every Time . Free of charge .",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TW",
            "City": "Haridwar",
            "Provider": "The Wisdom Global School",
            "Contact": "9837033485",
            "Notes": "Good hygiene food  , Break fast delivery up 9 , Lunch up 2 Pm and Dinner up to 8 Pm , Fresh food every Time . Free of charge .",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Patna": [
        {
            "avatar": "RT",
            "City": "Patna",
            "Provider": "Ricky Tiffin",
            "Contact": "8709935292",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Patna",
            "Provider": "Shashwat",
            "Contact": "9591951833",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Patna",
            "Provider": "Shashwat",
            "Contact": "9591951833",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "B",
            "City": "Patna",
            "Provider": "BoxBoy",
            "Contact": "7644002900",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "B",
            "City": "Patna",
            "Provider": "BoxBoy",
            "Contact": "7644002900",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RT",
            "City": "Patna",
            "Provider": "Ricky Tiffin",
            "Contact": "8709935292",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Rajkot": [
        {
            "avatar": "MT",
            "City": "Rajkot",
            "Provider": "Meraki Trust",
            "Contact": "9820608880",
            "Notes": "Dal Rice & Sabzi Roti",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MT",
            "City": "Rajkot",
            "Provider": "Meraki Trust",
            "Contact": "9820608880",
            "Notes": "Dal Rice & Sabzi Roti",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Rajkot",
            "Provider": "Sneha Sangani",
            "Contact": "7678069725",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MT",
            "City": "Rajkot",
            "Provider": "Meraki Trust",
            "Contact": "9820608880",
            "Notes": "Dal Rice & Sabzi Roti",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Rajkot",
            "Provider": "Sneha Sangani",
            "Contact": "7678069725",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Nagpur": [
        {
            "avatar": "A",
            "City": "Nagpur",
            "Provider": "Annapurna",
            "Contact": "9823599456",
            "Notes": "120+delivery charges ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MA",
            "City": "Nagpur",
            "Provider": "Mrs. Ajita Atul DorlikarESHAN FOODS",
            "Contact": "8530672200",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RK",
            "City": "Nagpur",
            "Provider": "RahulRVs Kitchen",
            "Contact": "74480660608",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RK",
            "City": "Nagpur",
            "Provider": "RahulRVs Kitchen",
            "Contact": "74480660608",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RB",
            "City": "Nagpur",
            "Provider": "Rakesh BahekarPlaceefy",
            "Contact": "9579224490",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MA",
            "City": "Nagpur",
            "Provider": "Mrs. Ajita Atul DorlikarESHAN FOODS",
            "Contact": "8530672200",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RK",
            "City": "Nagpur",
            "Provider": "RahulRVs Kitchen",
            "Contact": "74480660608",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RM",
            "City": "Nagpur",
            "Provider": "Ritika MulchandaniHomemade khana",
            "Contact": "8805029180",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Nagpur",
            "Provider": "Shailendra SharmaThe Klassic Kitchen",
            "Contact": "8698928474",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RB",
            "City": "Nagpur",
            "Provider": "Rakesh BahekarPlaceefy",
            "Contact": "9579224490",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Nagpur",
            "Provider": "Annapurna",
            "Contact": "9823599456",
            "Notes": "120+delivery charges ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MA",
            "City": "Nagpur",
            "Provider": "Mrs. Ajita Atul DorlikarESHAN FOODS",
            "Contact": "8530672200",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "A",
            "City": "Nagpur",
            "Provider": "Annapurna",
            "Contact": "9823599456",
            "Notes": "120+delivery charges ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Nagpur",
            "Provider": "Shailendra SharmaThe Klassic Kitchen",
            "Contact": "8698928474",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RM",
            "City": "Nagpur",
            "Provider": "Ritika MulchandaniHomemade khana",
            "Contact": "8805029180",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "South East Delhi": [
        {
            "avatar": "PN",
            "City": "South East Delhi",
            "Provider": "Pragi Nagpal",
            "Contact": "8448976006",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "PN",
            "City": "South East Delhi",
            "Provider": "Pragi Nagpal",
            "Contact": "8448976006",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Bilaspur": [
        {
            "avatar": "CM",
            "City": "Bilaspur",
            "Provider": "Covid Meals",
            "Contact": "7898760003",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "CM",
            "City": "Bilaspur",
            "Provider": "Covid Meals",
            "Contact": "7898760003",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Dharwad": [
        {
            "avatar": "R",
            "City": "Dharwad",
            "Provider": "Roots  Ghar Ka Khana",
            "Contact": "9611168110",
            "Notes": "Lunch ( 4 chapati, bhaji, salad rice and dal) and dinner (soup, outlaw or khichadi, chapati and sabji) is provided. 400 Rs for both lunch and dinner per person per day ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "R",
            "City": "Dharwad",
            "Provider": "Roots  Ghar Ka Khana",
            "Contact": "9611168110",
            "Notes": "Lunch ( 4 chapati, bhaji, salad rice and dal) and dinner (soup, outlaw or khichadi, chapati and sabji) is provided. 400 Rs for both lunch and dinner per person per day ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "East Khasi Hills": [
        {
            "avatar": "RT",
            "City": "East Khasi Hills",
            "Provider": "Round Table India",
            "Contact": "7005109273",
            "Notes": "They deliver food in all the areas of Shillong. Vegetarian food only. Lunch and dinner only. Can call or WhatsApp. The patient should provide the COVID report",
            "Verified": "Verified",
            "Last Verified": " 5/25/2021 18:32:39",
            "Found Useful": "0"
        },
        {
            "avatar": "RT",
            "City": "East Khasi Hills",
            "Provider": "Round Table India",
            "Contact": "7005109273",
            "Notes": "They deliver food in all the areas of Shillong. Vegetarian food only. Lunch and dinner only. Can call or WhatsApp. The patient should provide the COVID report",
            "Verified": "Verified",
            "Last Verified": " 5/25/2021 18:32:39",
            "Found Useful": "0"
        }
    ],
    "Ujjain": [
        {
            "avatar": "KK",
            "City": "Ujjain",
            "Provider": "Kapoors kitchen",
            "Contact": "9826443523",
            "Notes": "1 seasonal veg , daal , rice / daliya, raita, 4 roti, salad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "KK",
            "City": "Ujjain",
            "Provider": "Kapoors kitchen",
            "Contact": "9826443523",
            "Notes": "1 seasonal veg , daal , rice / daliya, raita, 4 roti, salad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Ujjain",
            "Provider": "Naivaidyam",
            "Contact": "9977745365",
            "Notes": "1) Butter Khichadi, Dahi, Papad, Salad, Handful Gud. 2) 4 Roti, 1 Seasonal Veg, Dal Jeera, Plain Rice, Handful Gud. 3) 2 Roti + 3 Parathe, 2Sabzi (1Seasonal Veg + 1 Paneer,) Dahi, Butter Khichadi, Salad, Papad, Handful Gud. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "N",
            "City": "Ujjain",
            "Provider": "Naivaidyam",
            "Contact": "9977745365",
            "Notes": "1) Butter Khichadi, Dahi, Papad, Salad, Handful Gud. 2) 4 Roti, 1 Seasonal Veg, Dal Jeera, Plain Rice, Handful Gud. 3) 2 Roti + 3 Parathe, 2Sabzi (1Seasonal Veg + 1 Paneer,) Dahi, Butter Khichadi, Salad, Papad, Handful Gud. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Krishnagiri": [
        {
            "avatar": "SS",
            "City": "Krishnagiri",
            "Provider": "shri satya sai seva",
            "Contact": "9443449554",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 07:26:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Krishnagiri",
            "Provider": "shri satya sai seva",
            "Contact": "9443449554",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 07:26:00",
            "Found Useful": "0"
        }
    ],
    "Nanded": [
        {
            "avatar": "JB",
            "City": "Nanded",
            "Provider": "Jai bhadra foods",
            "Contact": "9420900434",
            "Notes": "120",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "JB",
            "City": "Nanded",
            "Provider": "Jai bhadra foods",
            "Contact": "9420900434",
            "Notes": "120",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "JB",
            "City": "Nanded",
            "Provider": "Jai bhadra foods",
            "Contact": "9420900434",
            "Notes": "120",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Indore": [
        {
            "avatar": "AB",
            "City": "Indore",
            "Provider": "Anuj batra",
            "Contact": "9111120304",
            "Notes": "1 Dal, 1 Vegetable, 3 Chapatis and Rice(Only Dinner)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SR",
            "City": "Indore",
            "Provider": "Sheetal restaurantPrayag Agrawal",
            "Contact": "9009009797",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SR",
            "City": "Indore",
            "Provider": "Sheetal restaurantPrayag Agrawal",
            "Contact": "9009009797",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AB",
            "City": "Indore",
            "Provider": "Anuj batra",
            "Contact": "9111120304",
            "Notes": "1 Dal, 1 Vegetable, 3 Chapatis and Rice(Only Dinner)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "UJ",
            "City": "Indore",
            "Provider": "Umang Jain",
            "Contact": "9827023542",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "UJ",
            "City": "Indore",
            "Provider": "Umang Jain",
            "Contact": "9827023542",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Purba Burdwan (Bardhaman)": [
        {
            "avatar": "K",
            "City": "Purba Burdwan (Bardhaman)",
            "Provider": "Kishore ",
            "Contact": "9564528776",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:39:48",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "Purba Burdwan (Bardhaman)",
            "Provider": "Kishore ",
            "Contact": "9564528776",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:39:48",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "Purba Burdwan (Bardhaman)",
            "Provider": "Kishore ",
            "Contact": "9564528776",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:39:48",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "Purba Burdwan (Bardhaman)",
            "Provider": "Kishore ",
            "Contact": "9564528776",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:39:48",
            "Found Useful": "0"
        }
    ],
    "Rangareddy": [
        {
            "avatar": "TC",
            "City": "Rangareddy",
            "Provider": "TARZ CREATOR STUDIO  CAFE",
            "Contact": "7893105830",
            "Notes": "A Home Made fresh Feel Gourmet food to boost your immunity during Covid. Customised according to Customer Requirement ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TC",
            "City": "Rangareddy",
            "Provider": "TARZ CREATOR STUDIO  CAFE",
            "Contact": "7893105830",
            "Notes": "A Home Made fresh Feel Gourmet food to boost your immunity during Covid. Customised according to Customer Requirement ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AO",
            "City": "Rangareddy",
            "Provider": "Aromas of Bengal",
            "Contact": "8296914664",
            "Notes": "Customized meals that includes non spicy food and Protein packed.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AO",
            "City": "Rangareddy",
            "Provider": "Aromas of Bengal",
            "Contact": "8296914664",
            "Notes": "Customized meals that includes non spicy food and Protein packed.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TC",
            "City": "Rangareddy",
            "Provider": "TARZ CREATOR STUDIO  CAFE",
            "Contact": "7893105830",
            "Notes": "A Home Made fresh Feel Gourmet food to boost your immunity during Covid. Customised according to Customer Requirement ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TH",
            "City": "Rangareddy",
            "Provider": "The health bowl",
            "Contact": "8801277531",
            "Notes": "A healthy breakfast includs salads and fruit juices and healthy smoothies to boost immunity",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TH",
            "City": "Rangareddy",
            "Provider": "The health bowl",
            "Contact": "8801277531",
            "Notes": "A healthy breakfast includs salads and fruit juices and healthy smoothies to boost immunity",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AH",
            "City": "Rangareddy",
            "Provider": "A honest attempt",
            "Contact": "7976339206",
            "Notes": "We are currently serving in Kothaguda, Kondapur, Whitefields & near Cyber Towers only, in Hyderabad.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AH",
            "City": "Rangareddy",
            "Provider": "A honest attempt",
            "Contact": "7976339206",
            "Notes": "We are currently serving in Kothaguda, Kondapur, Whitefields & near Cyber Towers only, in Hyderabad.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Tiruvallur": [
        {
            "avatar": "G",
            "City": "Tiruvallur",
            "Provider": "Gandhiskitchen",
            "Contact": "9840462654",
            "Notes": "A home cooked meal by a home cook who has been taking care of a joint family for the past 25 years.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "G",
            "City": "Tiruvallur",
            "Provider": "Gandhiskitchen",
            "Contact": "9840462654",
            "Notes": "A home cooked meal by a home cook who has been taking care of a joint family for the past 25 years.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Dehradun": [
        {
            "avatar": "TN",
            "City": "Dehradun",
            "Provider": "The nook",
            "Contact": "8630943378",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NK",
            "City": "Dehradun",
            "Provider": "Neha kaura",
            "Contact": "8006436325",
            "Notes": "Simple home cooked food ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TN",
            "City": "Dehradun",
            "Provider": "The nook",
            "Contact": "8630943378",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NK",
            "City": "Dehradun",
            "Provider": "Neha kaura",
            "Contact": "8006436325",
            "Notes": "Simple home cooked food ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Sivaganga": [
        {
            "avatar": "SS",
            "City": "Sivaganga",
            "Provider": "Sri sathiya sai sewa organization",
            "Contact": "9500786051",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 12:16:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Sivaganga",
            "Provider": "Sri sathiya sai sewa organization",
            "Contact": "9500786051",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 12:16:00",
            "Found Useful": "0"
        }
    ],
    "South West Delhi": [
        {
            "avatar": "MK",
            "City": "South West Delhi",
            "Provider": "Maa ka ChulahPiyush Mathur",
            "Contact": "7701993549",
            "Notes": "Quarantine Thali Available Covid Meals",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VO",
            "City": "South West Delhi",
            "Provider": "Veg Only",
            "Contact": "9910044561",
            "Notes": "Update us for lunch before 11am and for dinner before 6pm",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "South West Delhi",
            "Provider": "Kaveri",
            "Contact": "9999356846",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MK",
            "City": "South West Delhi",
            "Provider": "Maa ka ChulahPiyush Mathur",
            "Contact": "7701993549",
            "Notes": "Quarantine Thali Available Covid Meals",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "K",
            "City": "South West Delhi",
            "Provider": "Kaveri",
            "Contact": "9999356846",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VO",
            "City": "South West Delhi",
            "Provider": "Veg Only",
            "Contact": "9910044561",
            "Notes": "Update us for lunch before 11am and for dinner before 6pm",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Darrang": [
        {
            "avatar": "AS",
            "City": "Darrang",
            "Provider": "Ankur Saikia",
            "Contact": "9319782779",
            "Notes": "Home services (food/groceries/vegetables)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AB",
            "City": "Darrang",
            "Provider": "Abhijit Baruah",
            "Contact": "6901996705",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AB",
            "City": "Darrang",
            "Provider": "Abhijit Baruah",
            "Contact": "6901996705",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "AS",
            "City": "Darrang",
            "Provider": "Ankur Saikia",
            "Contact": "9319782779",
            "Notes": "Home services (food/groceries/vegetables)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Nainital": [
        {
            "avatar": "F",
            "City": "Nainital",
            "Provider": "Foodstuffs",
            "Contact": "7055837318",
            "Notes": "We all types of salads and Indian food varieties and Italian pastas varieties ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "F",
            "City": "Nainital",
            "Provider": "Foodstuffs",
            "Contact": "7055837318",
            "Notes": "We all types of salads and Indian food varieties and Italian pastas varieties ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Cuddalore": [
        {
            "avatar": "SS",
            "City": "Cuddalore",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9444814913",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:55:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Cuddalore",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9444814913",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:55:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Cuddalore",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9444814913",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:55:00",
            "Found Useful": "0"
        }
    ],
    "Ahmednagar": [
        {
            "avatar": "S",
            "City": "Ahmednagar",
            "Provider": "sagar",
            "Contact": "9960626660",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Ahmednagar",
            "Provider": "sagar",
            "Contact": "9960626660",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NG",
            "City": "Ahmednagar",
            "Provider": "Navratri Group",
            "Contact": "9764021111",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "NG",
            "City": "Ahmednagar",
            "Provider": "Navratri Group",
            "Contact": "9764021111",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Sindhudurg": [
        {
            "avatar": "SF",
            "City": "Sindhudurg",
            "Provider": "Swara Foods",
            "Contact": "9404171731",
            "Notes": "4  POLI/CHAPATI /BHAKARI  AND 1 PLATE BHAJI WITH SALAD RS. 100",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SF",
            "City": "Sindhudurg",
            "Provider": "Swara Foods",
            "Contact": "9404171731",
            "Notes": "4  POLI/CHAPATI /BHAKARI  AND 1 PLATE BHAJI WITH SALAD RS. 100",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Chandigarh": [
        {
            "avatar": "JH",
            "City": "Chandigarh",
            "Provider": "Jullundur Hotel",
            "Contact": "8054989256",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "L",
            "City": "Chandigarh",
            "Provider": "Lizzetli",
            "Contact": "9988090667",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "JH",
            "City": "Chandigarh",
            "Provider": "Jullundur Hotel",
            "Contact": "8054989256",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "L",
            "City": "Chandigarh",
            "Provider": "Lizzetli",
            "Contact": "9988090667",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "L",
            "City": "Chandigarh",
            "Provider": "Lizzetli",
            "Contact": "9988090667",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "JH",
            "City": "Chandigarh",
            "Provider": "Jullundur Hotel",
            "Contact": "8054989256",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Faridabad": [
        {
            "avatar": "HH",
            "City": "Faridabad",
            "Provider": "Helping Hands",
            "Contact": "9560474981",
            "Notes": "@_helpinghands.ind",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HH",
            "City": "Faridabad",
            "Provider": "Helping Hands",
            "Contact": "9560474981",
            "Notes": "@_helpinghands.ind",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HH",
            "City": "Faridabad",
            "Provider": "Helping Hands",
            "Contact": "9560474981",
            "Notes": "@_helpinghands.ind",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HF",
            "City": "Faridabad",
            "Provider": "Homely Food",
            "Contact": "9999190919",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "HF",
            "City": "Faridabad",
            "Provider": "Homely Food",
            "Contact": "9999190919",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Jodhpur": [
        {
            "avatar": "JF",
            "City": "Jodhpur",
            "Provider": "Jodhpur food",
            "Contact": "9521021201",
            "Notes": "Dal, rice, vegetables, chapati, salad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "JF",
            "City": "Jodhpur",
            "Provider": "Jodhpur food",
            "Contact": "9521021201",
            "Notes": "Dal, rice, vegetables, chapati, salad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SJ",
            "City": "Jodhpur",
            "Provider": "Sanjana Jain",
            "Contact": "9414020592",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "JF",
            "City": "Jodhpur",
            "Provider": "Jodhpur food",
            "Contact": "9521021201",
            "Notes": "Dal, rice, vegetables, chapati, salad",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SJ",
            "City": "Jodhpur",
            "Provider": "Sanjana Jain",
            "Contact": "9414020592",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Theni": [
        {
            "avatar": "SS",
            "City": "Theni",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9943998986",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:24:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Theni",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9943998986",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:24:00",
            "Found Useful": "0"
        }
    ],
    "Paschim Medinipur (West Medinipur)": [
        {
            "avatar": "AR",
            "City": "Paschim Medinipur (West Medinipur)",
            "Provider": "Akash Rahman",
            "Contact": "7001257016",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:38:43",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Paschim Medinipur (West Medinipur)",
            "Provider": "Shankhamala",
            "Contact": "7059607047",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/14/2021 15:49:30",
            "Found Useful": "0"
        },
        {
            "avatar": "S",
            "City": "Paschim Medinipur (West Medinipur)",
            "Provider": "Shankhamala",
            "Contact": "7059607047",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/14/2021 15:49:30",
            "Found Useful": "0"
        },
        {
            "avatar": "AR",
            "City": "Paschim Medinipur (West Medinipur)",
            "Provider": "Akash Rahman",
            "Contact": "7001257016",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/7/2021 1:38:43",
            "Found Useful": "0"
        }
    ],
    "East Sikkim": [
        {
            "avatar": "CD",
            "City": "East Sikkim",
            "Provider": "Chitto Delivery",
            "Contact": "9734014831",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/26/2021 16:20:24",
            "Found Useful": "0"
        },
        {
            "avatar": "CD",
            "City": "East Sikkim",
            "Provider": "Chitto Delivery",
            "Contact": "9734014831",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 5/26/2021 16:20:24",
            "Found Useful": "0"
        }
    ],
    "Vellore": [
        {
            "avatar": "SS",
            "City": "Vellore",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9486102227",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:54:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Vellore",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9486102227",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:54:00",
            "Found Useful": "0"
        }
    ],
    "Dakshina Kannada": [
        {
            "avatar": "VK",
            "City": "Dakshina Kannada",
            "Provider": "Vandy's Kitchen",
            "Contact": "7411369543",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SJ",
            "City": "Dakshina Kannada",
            "Provider": "Subha J Rao",
            "Contact": "9894210027",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VK",
            "City": "Dakshina Kannada",
            "Provider": "Vandy's Kitchen",
            "Contact": "7411369543",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VK",
            "City": "Dakshina Kannada",
            "Provider": "Vandy's Kitchen",
            "Contact": "7411369543",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SJ",
            "City": "Dakshina Kannada",
            "Provider": "Subha J Rao",
            "Contact": "9894210027",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Tirunelveli": [
        {
            "avatar": "SS",
            "City": "Tirunelveli",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9698927868",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:23:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Tirunelveli",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9698927868",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:23:00",
            "Found Useful": "0"
        }
    ],
    "Tiruppur": [
        {
            "avatar": "SS",
            "City": "Tiruppur",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9487515709",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:27:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Tiruppur",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9487515709",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:27:00",
            "Found Useful": "0"
        }
    ],
    "New Delhi": [
        {
            "avatar": "BB",
            "City": "New Delhi",
            "Provider": "Bhartiya Bhojan",
            "Contact": "9354807919",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "BB",
            "City": "New Delhi",
            "Provider": "Bhartiya Bhojan",
            "Contact": "9354807919",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Lakhimpur": [
        {
            "avatar": "MD",
            "City": "Lakhimpur",
            "Provider": "Manjita Dowarah",
            "Contact": "7002875054",
            "Notes": "Home services (food/groceries/vegetables)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "MD",
            "City": "Lakhimpur",
            "Provider": "Manjita Dowarah",
            "Contact": "7002875054",
            "Notes": "Home services (food/groceries/vegetables)",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Jalandhar": [
        {
            "avatar": "ST",
            "City": "Jalandhar",
            "Provider": "Serving the needy",
            "Contact": "9041051400",
            "Notes": "Dal with sabzi rice n chappati",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "ST",
            "City": "Jalandhar",
            "Provider": "Serving the needy",
            "Contact": "9041051400",
            "Notes": "Dal with sabzi rice n chappati",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Kozhikode": [
        {
            "avatar": "RC",
            "City": "Kozhikode",
            "Provider": "Rian Chakraborty",
            "Contact": "8707590491",
            "Notes": "The meal will be based on fresh ingredients bought from the farmers market. The chef is proficient in a number of cuisines. Charity meals available. Paid meals will be based on the customers demands. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "RC",
            "City": "Kozhikode",
            "Provider": "Rian Chakraborty",
            "Contact": "8707590491",
            "Notes": "The meal will be based on fresh ingredients bought from the farmers market. The chef is proficient in a number of cuisines. Charity meals available. Paid meals will be based on the customers demands. ",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Dhule": [
        {
            "avatar": "GJ",
            "City": "Dhule",
            "Provider": "Gitesh Joshi",
            "Contact": "8390361492",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "GJ",
            "City": "Dhule",
            "Provider": "Gitesh Joshi",
            "Contact": "8390361492",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Raigad": [
        {
            "avatar": "TB",
            "City": "Raigad",
            "Provider": "Treat basket",
            "Contact": "9870811254",
            "Notes": "We are Home food providing comforting veg and non- veg Tiffins/Combo meals.Everything is cooked in sunflower oil and in hygienic conditions.Entirely different vessels are used for non-veg preparations.Food will be given in standard disposable Containers.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "TB",
            "City": "Raigad",
            "Provider": "Treat basket",
            "Contact": "9870811254",
            "Notes": "We are Home food providing comforting veg and non- veg Tiffins/Combo meals.Everything is cooked in sunflower oil and in hygienic conditions.Entirely different vessels are used for non-veg preparations.Food will be given in standard disposable Containers.",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Kolhapur": [
        {
            "avatar": "VK",
            "City": "Kolhapur",
            "Provider": "Vahini Khadya Kendra",
            "Contact": "9518787455",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "VK",
            "City": "Kolhapur",
            "Provider": "Vahini Khadya Kendra",
            "Contact": "9518787455",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Dharmapuri": [
        {
            "avatar": "SS",
            "City": "Dharmapuri",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9942902634",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:25:00",
            "Found Useful": "0"
        },
        {
            "avatar": "SS",
            "City": "Dharmapuri",
            "Provider": "Sri sathia sai sewa organization",
            "Contact": "9942902634",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " 2021-05-21 00:25:00",
            "Found Useful": "0"
        }
    ],
    "Cuttack": [
        {
            "avatar": "SC",
            "City": "Cuttack",
            "Provider": "Swostik Caterers",
            "Contact": "6371439398",
            "Notes": "Healthy and Homely Cooked Food prepared in a good hygiene and packed and sealed. Food will be provided as per Customer requirements",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SC",
            "City": "Cuttack",
            "Provider": "Swostik Caterers",
            "Contact": "6371439398",
            "Notes": "Healthy and Homely Cooked Food prepared in a good hygiene and packed and sealed. Food will be provided as per Customer requirements",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ],
    "Tinsukia": [
        {
            "avatar": "SR",
            "City": "Tinsukia",
            "Provider": "Samrat Roy",
            "Contact": "9706637210",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        },
        {
            "avatar": "SR",
            "City": "Tinsukia",
            "Provider": "Samrat Roy",
            "Contact": "9706637210",
            "Notes": "",
            "Verified": "Verified",
            "Last Verified": " ",
            "Found Useful": "0"
        }
    ]
};