/* eslint-disable react/jsx-filename-extension */
import React from "react";

function WrapWithTopTitle({ children, title, forId}) {
  return (
    <div className="m-1">
      <label for={forId} style={{fontWeight: "600", fontSize: "17px"}}> {title} </label>
      {children}
    </div>
  );
}

export default WrapWithTopTitle;
