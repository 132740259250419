/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React from "react";
import Iframe from "react-iframe";
import { useHistory } from "react-router-dom";
import TopTitleBar from "../components/TopTitleBar";
import style from "./hotspots.module.css";

function PredictiveAnalysis() {
  const primaryColor = "rgb(33, 150, 243)";
  const history = useHistory();

  return (
    <>
    <TopTitleBar title="Crowdsourced COVID-19 Predictions" />
    <div className={style.iframeContainer}> 
        <Iframe
          src="https://govindjeevan.github.io/pathcheck-prediction-plots/predicted_hotspots.html"
          width="100%"
          scrolling="no"
          className={style.iframe}
        />
     
    </div>
    </>
  );
}

export default PredictiveAnalysis;
