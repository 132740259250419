/* eslint-disable react/jsx-filename-extension */
import React, { useState } from "react";
import { ThunderboltFilled, AlertFilled } from "@ant-design/icons";

import TopTitleBar from "../components/TopTitleBar";
import style from "./pages.module.css";
import {
  PrimaryButton,
  Input,
  Card,
  RadioButton,
} from "../components/FormComponents";

const BookOrNotify = ({ firebase, onToggleBetweenPages }) => {
  const [preference, setPreference] = useState(undefined);
  const [phone, setPhone] = useState("");

  return (
    <>
      <TopTitleBar
        title="Vaccine Auto Slot Booking"
        chosen={onToggleBetweenPages}
        backLink="/home/Vaccination"
      />
      <div
        style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <div className={style.vaccinepage}>
          <div className={`d-flex flex-column ${style.formContainer}`}>
            <div
              className={`${style.dropdown}`}
              style={{ marginBottom: "1rem" }}
            >
              <RadioButton
                data={["Only Notify", "Auto Book"]}
                onSelect={setPreference}
                value={preference}
                dataIcons={[
                  <AlertFilled
                    style={{ fontSize: "20px", marginRight: "4px" }}
                  />,
                  <ThunderboltFilled
                    style={{ fontSize: "20px", marginRight: "4px" }}
                  />,
                ]}
                buttonStyle={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              />
            </div>

            <div
              className={`${style.dropdown}`}
              style={{ marginBottom: "1rem" }}
            >
              <Card
                title={`You've selected: ${preference}`}
                text="As soon as slot will be available, an email & notification will be sent, after ..."
              />
            </div>
            <div
              className={`${style.dropdown}`}
              style={{ marginBottom: "1rem" }}
            >
              <Input
                label="Phone"
                setInput={setPhone}
                value={phone}
                placeholder="9876543210"
              />
              <p
                className={style.errormsg}
                style={{
                  color: "red",
                  visibility:
                    phone.length !== 10 && !isNaN(phone) ? "visible" : "hidden",
                }}
              >
                {" "}
                Please enter a valid mobile number{" "}
              </p>
            </div>
            <PrimaryButton isSecondary icon={null} text="Verify" />
            <PrimaryButton
              icon={null}
              text="Edit Details"
              onClick={onToggleBetweenPages}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BookOrNotify;
