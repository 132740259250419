/* eslint-disable import/no-extraneous-dependencies */
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/messaging";
import "firebase/functions";
import "firebase/analytics";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    const app = firebase.initializeApp(config);
    this.firebase = firebase;
    this.app = app;
    this.db = app.database();
    this.analytics = app.analytics();
    if (firebase.messaging.isSupported()) {
      this.messaging = app.messaging();
    }
  }
}

export default Firebase;
