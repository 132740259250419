import React, { useState } from "react";
import { FirebaseContext } from "../Firebase";
import { addComment } from "../Firebase/util";
import style from "./WriteComment.module.css";

function WriteComment({
  resourceInfo,
  setAddComment,
  authUser,
  // setCommentsReceived,
}) {
  const primaryColor = "rgb(33, 150, 243)";
  const [postComment, setPostComment] = useState(false);
  const [msg, setMessage] = useState("");

  function inputHandler(e) {
    if (e.target.value !== "") {
      setPostComment(true);
      setMessage(e.target.value);
    } else setPostComment(false);
  }

  function PostCommentHandler(firebase, comment) {
    addComment(firebase, resourceInfo, {
      msg,
      name: authUser.displayName,
      email: authUser.phoneNumber,
    }).then(() => {
      setAddComment(false);
    });
  }

  function backHandler() {
    setAddComment(false);
  }

  function PostButtonHandler(firebase) {
    return (
      <div
        className={`d-flex mt-1 ${style.postbutton}`}
        onClick={() => PostCommentHandler(firebase)}
        style={{
          cursor: "pointer",
          pointerEvents: postComment ? "auto" : "none",
          opacity: postComment ? 1 : 0.4,
          color: "white",
          fontWeight: "500",
          paddingRight: "10px",
          transition: "opacity 100ms ease-out",
        }}
      >
        Post
      </div>
      // <button title="Post" disabled={!postComment} onClick={() => PostCommentHandler(firebase)} />
    );
  }

  return (
    <div className={`${style.overlay}`}>
      <div
        className={`pt-2 pb-2 pl-2 pr-2 d-flex row ${style.header}`}
        style={{
          backgroundColor: primaryColor,
        }}
      >
        <div
          className={`d-flex mt-1 ${style.back}`}
          onClick={() => backHandler()}
          style={{ cursor: "pointer" }}
        >
          Cancel
        </div>

        <div
          className={`${style.title} m-auto`}
          style={{
            color: "white",
            fontSize: "1.2rem",
            fontWeight: "500",
            fontFamily: "sans-serif",
          }}
        >
          Comment
        </div>
        <FirebaseContext.Consumer>
          {(firebase) => PostButtonHandler(firebase)}
        </FirebaseContext.Consumer>
      </div>
      <textarea
        type="text"
        onChange={(e) => inputHandler(e)}
        style={{
          flexGrow: "1",
          width: "100%",
          borderWidth: 0,
          borderRadius: "5px",
          borderColor: "white",
          justifyContent: "flex-start",
          alignItems: "top",
          padding: "5px 30px 0px",
          outline: "0",
        }}
        placeholder="Write a comment..."
      />
    </div>
  );
}

export default WriteComment;
