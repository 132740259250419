/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useRef } from "react";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Spin, message } from "antd";
import style from "./Comments.module.css";

import PhoneInput from "../Auth/PhoneInput";

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const AntIcon = (
  <LoadingOutlined style={{ fontSize: 20, color: "#00008b" }} spin />
);

function AddUser({ setAddComment, firebase }) {
  const [currState, setCurrState] = useState("NAME");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState({ short: "IN" });
  const [email, setEmail] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [loading, setLoading] = useState(false);
  const captchaRef = useRef(null);

  useEffect(() => {
    console.log("Loading changed: ", loading);
  }, [loading]);
  useEffect(() => {
    window.recaptchaVerifier = new firebase.firebase.auth.RecaptchaVerifier(
      captchaRef.current,
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignInSubmit();
        },
      }
    );
  }, []);

  /**
   * On submit phone
   */
  function submitPhoneNumber() {
    const appVerifier = window.recaptchaVerifier;
    setLoading(true);
    const sendingOTP = message.loading(
      { content: "Getting OTP to phone", style: { marginTop: "65px" } },
      0
    );
    firebase.app
      .auth()
      .signInWithPhoneNumber(
        `+${phoneNumber.code}${phoneNumber.phone}`,
        appVerifier
      )
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setCurrState("VERIFY");
        setLoading(false);
        sendingOTP();
        message.success(
          { content: "OTP sent!", style: { marginTop: "65px" } },
          0
        );
        // ...
      })
      .catch((error) => {
        /* Reload recapthca
         */
        window.recaptchaVerifier.render().then((widgetId) => {
          grecaptcha.reset(widgetId);
        });
        setLoading(false);
        sendingOTP();
        message.error(
          {
            content: "Could not send OTP, Please try again!",
            style: { marginTop: "65px" },
          },
          0
        );
        console.error(error);
        // Error; SMS not sent
        // ...
      });
  }
  function submitEmail() {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: window.location.href,
      // This must be true.
      handleCodeInApp: true,
    };
    setLoading(true);
    const sendingMail = message.loading(
      {
        content: "Sending verification link to email",
        style: { marginTop: "65px" },
      },
      0
    );
    firebase.app
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        window.localStorage.setItem("usernameForSignIn", name);
        setLoading(false);
        sendingMail();
        message.success(
          { content: "Verification email sent!", style: { marginTop: "65px" } },
          0
        );
        setCurrState("EMAIL_VERIFY");
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setLoading(false);
        sendingMail();
        message.error(
          {
            content: "Error occured, Please try again!",
            style: { marginTop: "65px" },
          },
          0
        );
        // ...
      });
  }

  async function verify() {
    setLoading(true);
    const verifyingOTP = message.loading(
      {
        content: "Verifying OTP...",
        style: { marginTop: "65px" },
      },
      0
    );
    try {
      const { user } = await confirmationResult.confirm(verifyCode);
      await user.updateProfile({ displayName: name });
      verifyingOTP();
    } catch {
      verifyingOTP();
      message.error(
        {
          content: "OTP verification failed, please try again",
          style: { marginTop: "65px" },
        },
        0
      );
    }
    setLoading(false);
  }

  function changeName(e) {
    setName(e.target.value);
  }

  function saveName() {
    setCurrState("LOGIN_METHOD");
  }

  function backHandler() {
    setAddComment(false);
  }

  const isDisabled =
    name.length === 0 ||
    (currState === "EMAIL" && !validateEmail(email)) ||
    loading;

  return (
    <>
      <div
        className={`${style.flex} ${style.flexcolumn} ${style.flexcenter} ${style.fullscreen} ${style.primary}`}
      >
        <div className={style.closebutton}>
          <CloseOutlined
            size={200}
            style={{
              alignSelf: "flex-start",
            }}
            onClick={() => backHandler()}
          />
        </div>
        <div className={style.container}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "right",
            }}
          />
          {currState === "NAME" && (
            <div className={style.maintext}>
              <div>
                <p
                  style={{
                    fontSize: 36,
                    fontWeight: 900,
                    bottom: 0,
                    margin: 0,
                    textAlign: "center",
                  }}
                >
                  Hello, stranger!{" "}
                </p>
                <p className={style.secondarytext}>
                  {" "}
                  Please tell us who you are before continuing.{" "}
                </p>
                <input
                  className={style.maininput}
                  type="text"
                  placeholder="Name"
                  onChange={(e) => changeName(e)}
                  value={name}
                />
              </div>
            </div>
          )}
          {currState === "LOGIN_METHOD" && (
            <div className={style.maintext}>
              <div>
                <p
                  style={{
                    fontSize: 36,
                    fontWeight: 900,
                    bottom: 0,
                    textAlign: "center",
                  }}
                >
                  Welcome, {name}!
                </p>
                <p
                  style={{ fontWeight: 300, fontSize: 24, textAlign: "center" }}
                  className={style.secondarytext}
                >
                  {" "}
                  Select authentication method{" "}
                </p>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => setCurrState("EMAIL")}
                >
                  Login with Email
                </Button>
                <Divider className={style.divider}>OR</Divider>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => setCurrState("MOBILE")}
                >
                  Login with Phone number
                </Button>
              </div>
            </div>
          )}
          {currState === "MOBILE" && (
            <div className={style.maintext}>
              <div>
                <p
                  style={{
                    fontSize: 36,
                    fontWeight: 900,
                    bottom: 0,
                    textAlign: "center",
                  }}
                >
                  Welcome, {name}!
                </p>
                <p
                  style={{ fontWeight: 300, fontSize: 24, textAlign: "center" }}
                >
                  {" "}
                  Please enter your phone number.{" "}
                </p>
                <PhoneInput
                  value={phoneNumber}
                  onChange={(v) => setPhoneNumber(v)}
                />
              </div>
            </div>
          )}
          {currState === "EMAIL" && (
            <div className={style.maintext}>
              <div>
                <p
                  style={{
                    fontSize: 36,
                    fontWeight: 900,
                    bottom: 0,
                    textAlign: "center",
                  }}
                >
                  Welcome, {name}!
                </p>
                <p
                  style={{ fontWeight: 300, fontSize: 24, textAlign: "center" }}
                >
                  {" "}
                  Please enter your e-mail address.{" "}
                </p>
                <Input
                  value={email}
                  className={style.maininput}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span
                  role="alert"
                  style={{
                    visibility:
                      validateEmail(email) && email !== ""
                        ? "hidden"
                        : "visible",
                  }}
                  className={style.error}
                >
                  Please enter a valid e-mail.
                </span>
              </div>
            </div>
          )}
          {currState === "VERIFY" && (
            <div className={style.maintext}>
              <div>
                <p
                  style={{
                    fontSize: 36,
                    fontWeight: 900,
                    bottom: 0,
                    textAlign: "center",
                  }}
                >
                  SMS is sent to {`+${phoneNumber.code}-${phoneNumber.phone}`}
                </p>
                <p
                  style={{ fontWeight: 300, fontSize: 24, textAlign: "center" }}
                >
                  {" "}
                  Please enter your verification code.{" "}
                </p>
                <input
                  style={{
                    color: "black",
                  }}
                  className={style.maininput}
                  type="text"
                  value={verifyCode}
                  onChange={(e) => setVerifyCode(e.target.value)}
                />
              </div>
            </div>
          )}
          {currState === "EMAIL_VERIFY" && (
            <div className={style.maintext}>
              <div>
                <p
                  style={{
                    fontSize: 36,
                    fontWeight: 900,
                    bottom: 0,
                    textAlign: "center",
                  }}
                >
                  E-mail with link is sent to {email}. Open the link to
                  authenticate.
                </p>
              </div>
            </div>
          )}
          <div id="recaptcha-container" ref={captchaRef} />
          {currState !== "LOGIN_METHOD" && currState !== "EMAIL_VERIFY" && (
            <Button
              disabled={isDisabled}
              className={style.continuebutton}
              id="sign-in-button"
              onClick={() => {
                if (currState === "NAME") {
                  saveName();
                } else if (currState === "MOBILE") {
                  submitPhoneNumber();
                } else if (currState === "EMAIL") {
                  submitEmail();
                } else {
                  verify();
                }
              }}
              style={{
                height: "44px",
                width: "100%",
                display: "flex",
                borderRadius: "5px",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: !isDisabled
                  ? "white"
                  : "rgba(255, 255, 255, 0.247)",
                color: !isDisabled ? "black" : "#00008b",
                fontWeight: 900,
              }}
            >
              <div style={{ position: "relative" }}>
                Continue{" "}
                <Spin
                  style={{
                    position: "absolute",
                    right: "-25px",
                    lineHeight: "1",
                    top: "calc(50% - 10px)",
                  }}
                  spinning={loading}
                  indicator={AntIcon}
                />
              </div>
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default AddUser;
