/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import { BiLink } from "react-icons/bi";
import { Avatar } from "antd";
import TopTitleBar from "../components/TopTitleBar";
import Tiles from "../components/Tiles/HomePageTiles";
import { resourcesData } from "../data/ExtractedData/Resources";

function ResourceList(props) {
  const primaryColor = "rgb(33, 150, 243)";
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className="" style={{ fontFamily: "sans-serif" }}>
      <TopTitleBar title="Resource List" chosen={props.chosen} />
      <div>
        <p style={{ fontSize: "1.25rem", fontWeight: "700" }}> Resource </p>
        <div style={{ fontSize: "0.9rem", fontWeight: "500" }}>
          We strongly advice you against forwarding advance money to any
          vendor(s) or service provider(s) to safeguard you from any kind of
          fraud.
          <br />
          <br />
        </div>
        <p style={{ fontSize: "1.12rem", fontWeight: "700" }}> Useful links </p>
      </div>
      <div className="mt-3">
        <>
          {Object.keys(resourcesData).map((data, key) => (
            <div>
              <a onClick={() => openInNewTab(resourcesData[data][0].Link)}>
                <Tiles
                  avatarIcon={
                    <Avatar
                      size={48}
                      style={{
                        color: "#0066b2",
                        backgroundColor: "#B9D9EB",
                        fontSize: "24px",
                      }}
                    >
                      {resourcesData[data][0].avatar}
                    </Avatar>
                  }
                  text={data}
                  image={resourcesData[data][0].Image}
                  icon={<BiLink size="20px" color={primaryColor} />}
                />
              </a>
            </div>
          ))}
        </>
      </div>
    </div>
  );
}

export default ResourceList;
