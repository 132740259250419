/* eslint-disable prettier/prettier */
import React from "react"
import { List, Avatar } from "antd"
import moment from "moment";
import styles from "./Discussion.module.css";
import { LanguageConstants } from "../../Constants/LanguageConstants";

const Discussion = ({ discussion, isLoading }) => (
  <List
    loading={isLoading}
    itemLayout="horizontal"
    dataSource={discussion}
    renderItem={(item, idx) => (
      <List.Item key={idx} className={styles.discussionListItem}>
        <List.Item.Meta
          avatar={
            <Avatar
              size={44}
              style={{
                color: "#0066b2",
                backgroundColor: "#B9D9EB",
                fontSize: "22px",
              }}
            >
              {item.Username.slice(0, 1)}
            </Avatar>
          }
          title={
            <>
              <a href={item.Username}>{item.Username}</a>
              {LanguageConstants.SPACE}
              <div style={{ fontSize: "10px" }}>
                {moment(item.time).format("LLL")}
              </div>
            </>
          }
          description={
            <div>
              {LanguageConstants.SPACE}
              {item.Comment}
              {LanguageConstants.SPACE}
            </div>
          }
        />
      </List.Item>
    )}
  />
);

export default Discussion;
