import React, { useRef, useEffect } from "react";
import { Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import HomePagesHandler from "./HomePagesHandler";
import OxygenLeads from "./OxygenLeads";
import VacantBedsHospitals from "./VacantBedsHospitals";
import Hospital from "./Hospital";
import { FirebaseContext } from "../components/Firebase";
import VacantBedsTwitter from "./VacantBedTwitter";
import StateHelpline from "../widgets/StateHelpline";
import ShowParticularData from "../widgets/ShowParticularData";
import TeamMemberDisplay from "../components/TeamMemberDisplay";
import ShowAbout from "../components/ShowAbout";

function wrapWithFirebase({ match }) {
  return (
    <FirebaseContext.Consumer>
      {(firebase) => <Hospital firebase={firebase} match={match} />}
    </FirebaseContext.Consumer>
  );
}

function wrapWithFirebaseFoodProvider({ match }) {
  return (
    <FirebaseContext.Consumer>
      {(firebase) => <ShowParticularData firebase={firebase} match={match} />}
    </FirebaseContext.Consumer>
  );
}
const routes = [
  { path: "/home/:page", Component: HomePagesHandler },
  { path: "/home/OxygenLeads/:city", Component: OxygenLeads },
  { path: "/home/VacantBeds/:city", Component: VacantBedsHospitals },
  {
    path: "/home/VacantBeds/:city/LiveTwitterLeads",
    Component: VacantBedsTwitter,
  },
  { path: "/home/Hospital/:city/:id", Component: wrapWithFirebase },
  { path: "/home/Helpline/:state", Component: StateHelpline },
  {
    path: "/home/foodProvider/:city/:provider",
    Component: wrapWithFirebaseFoodProvider,
  },
  { path: "/team/:member", Component: TeamMemberDisplay },
  { path: "/about/:about", Component: ShowAbout },
];

function TransitionPages({ match, location, Component, path }) {
  const prevCountRef = useRef();
  const prevLocationRef = useRef();
  useEffect(() => {
    prevCountRef.current = match;
  }, [match]);
  useEffect(() => {
    prevLocationRef.current = location;
  }, [location]);
  const prevMatch = prevCountRef.current;
  const prevLocation = prevLocationRef.current;
  function getMatch() {
    if (match) return match;
    return prevMatch;
  }
  function getPageClass() {
    const prevBasePage = path.slice(1).split("/")[0];
    const currBasePage = location.pathname.slice(1).split("/")[0];
    if (!match && prevBasePage !== currBasePage) {
      if (prevBasePage === "home") {
        return "left";
      }
      if (prevBasePage === "about") {
        return "right";
      }
      if (currBasePage === "home") {
        return "right";
      }
      return "left";
    }
    if (prevLocation) {
      // depth++ left
      const prevDepth = prevLocation.pathname.split("/").length;
      const currDepth = location.pathname.split("/").length;
      if (prevDepth > currDepth) return "right";
    }
    return "left";
  }
  return (
    <CSSTransition
      in={match != null}
      timeout={300}
      classNames="page"
      unmountOnExit
    >
      <div className={`page ${getPageClass()}`}>
        <Component match={getMatch()} />
      </div>
    </CSSTransition>
  );
}

function HomePages() {
  return (
    <>
      {routes.map(({ path, Component }) => (
        <Route key={path} exact path={path}>
          {({ match, location }) => (
            <TransitionPages
              match={match}
              path={path}
              location={location}
              Component={Component}
            />
          )}
        </Route>
      ))}
    </>
  );
}
export default HomePages;
