/* eslint-disable react/jsx-filename-extension */
import React from "react";
import styles from "./components.module.css";

function Card({ title, text, style, note }) {
  return (
    <div style={style} className={styles.card}>
      <div className={styles.cardTitle}>{title}</div>
      <div>{text}</div>
      <div className={styles.note}>{note}</div>
    </div>
  );
}

export default Card;
