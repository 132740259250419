import { Select } from "antd";
import React, { useState } from "react";
import style from "./components.module.css";

const { Option } = Select;

const Dropdown = ({
  multiple,
  values,
  onSelect,
  placeholder,
  selected,
  label,
}) => {
  return (
    <>
      <label className={style.label}>Select {label}</label>
      <Select
        mode={multiple ? "multiple " : "single"}
        size="large"
        placeholder={placeholder}
        value={selected}
        onChange={(e) => onSelect(e)}
        style={{
          maxHeight: "40px",
          overflow: "auto",
        }}
        filterOption={(input, option) => {
          return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        showSearch
        className="custom-dropdown"
      >
        {values.map((val, idx) => (
          <Option value={val} key={idx}>
            {" "}
            {val}{" "}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default Dropdown;
