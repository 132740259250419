export const sprinklrData = {
    "AGRA": [
        {
            "avatar": "A",
            "City": "Agra",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/44?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=44%2444_Oxygen%20Supplies&widgetId=608c496f011fb174c4e8b741",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/44?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=44%2444_Hospital%20Beds"
        }
    ],
    "AHMEDABAD": [
        {
            "avatar": "A",
            "City": "Ahmedabad",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/6?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=6%246_Oxygen%20Supplies&widgetId=608c20ccfce18a42ebfa8c76",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/6?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=6%246_Hospital%20Beds"
        }
    ],
    "BANGALORE": [
        {
            "avatar": "B",
            "City": "Bangalore",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/4?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=4%244_Oxygen%20Supplies&widgetId=608c1c44fce18a42ebf88311",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/4?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=4%244_Hospital%20Beds"
        }
    ],
    "BHOPAL": [
        {
            "avatar": "B",
            "City": "Bhopal",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/13?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=13%2413_Oxygen%20Supplies&widgetId=608c46f4011fb174c4e7eadf",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/13?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=13%2413_Hospital%20Beds"
        }
    ],
    "CHENNAI": [
        {
            "avatar": "C",
            "City": "Chennai",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/9?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=9%249_Oxygen%20Supplies&widgetId=608c4681011fb174c4e7c467",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/9?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=9%249_Hospital%20Beds"
        }
    ],
    "DELHI": [
        {
            "avatar": "D",
            "City": "Delhi",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/1?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=1%241_Oxygen%20Supplies&widgetId=608c0f23b7cd1c7c449cb1cc",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/1?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=1%241_Hospital%20Beds"
        }
    ],
    "FARIDABAD": [
        {
            "avatar": "F",
            "City": "Faridabad",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/46?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=46%2446_Oxygen%20Supplies&widgetId=608c4991011fb174c4e8c049",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/46?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=46%2446_Hospital%20Beds"
        }
    ],
    "GURUGRAM": [
        {
            "avatar": "G",
            "City": "Gurugram",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/5?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=5%245_Oxygen%20Supplies&widgetId=608c1e68fce18a42ebf96b43",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/5?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=5%245_Hospital%20Beds"
        }
    ],
    "HYDERABAD": [
        {
            "avatar": "H",
            "City": "Hyderabad",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/15?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=15%2415_Oxygen%20Supplies&widgetId=608c4722011fb174c4e7f760",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/15?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=15%2415_Hospital%20Beds"
        }
    ],
    "INDORE": [
        {
            "avatar": "I",
            "City": "Indore",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/14?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=14%2414_Oxygen%20Supplies&widgetId=608c470c011fb174c4e7f1f1",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/14?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=14%2414_Hospital%20Beds"
        }
    ],
    "JAIPUR": [
        {
            "avatar": "J",
            "City": "Jaipur",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/43?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=43%2443_Oxygen%20Supplies&widgetId=608c4961011fb174c4e8b2bd",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/43?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=43%2443_Hospital%20Beds"
        }
    ],
    "KOLKATA": [
        {
            "avatar": "K",
            "City": "Kolkata",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/10?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=10%2410_Oxygen%20Supplies&widgetId=608c46a9011fb174c4e7d265",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/10?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=10%2410_Hospital%20Beds"
        }
    ],
    "LUCKNOW": [
        {
            "avatar": "L",
            "City": "Lucknow",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/12?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=12%2412_Oxygen%20Supplies&widgetId=608c46dd011fb174c4e7e2d6",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/12?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=12%2412_Hospital%20Beds"
        }
    ],
    "NAGPUR": [
        {
            "avatar": "N",
            "City": "Nagpur",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/8?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=8%248_Oxygen%20Supplies&widgetId=608c4670011fb174c4e7bfc8",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/8?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=8%248_Hospital%20Beds"
        }
    ],
    "NOIDA": [
        {
            "avatar": "N",
            "City": "Noida",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/11?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=11%2411_Oxygen%20Supplies&widgetId=608c46c1011fb174c4e7dabd",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/11?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=11%2411_Hospital%20Beds"
        }
    ],
    "PRAYAGRAJ": [
        {
            "avatar": "P",
            "City": "Prayagraj",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/47?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=47%2447_Oxygen%20Supplies&widgetId=608c49af011fb174c4e8c70f",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/47?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=47%2447_Hospital%20Beds"
        }
    ],
    "PUNE": [
        {
            "avatar": "P",
            "City": "Pune",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/2?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=2%242_Oxygen%20Supplies&widgetId=608c1882fce18a42ebf6d73e",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/2?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=2%242_Hospital%20Beds"
        }
    ],
    "RANCHI": [
        {
            "avatar": "R",
            "City": "Ranchi",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/49?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=49%2449_Oxygen%20Supplies&widgetId=608c49dc011fb174c4e8d257",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/49?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=49%2449_Hospital%20Beds"
        }
    ],
    "THANE": [
        {
            "avatar": "T",
            "City": "Thane",
            "Link Oxygen": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/16?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=16%2416_Oxygen%20Supplies&widgetId=608c4730011fb174c4e7fbb9",
            "Link Bed": "https://external.sprinklr.com/insights/explorer/dashboard/601b9e214c7a6b689d76f493/tab/16?id=DASHBOARD_601b9e214c7a6b689d76f493&tabId=16%2416_Hospital%20Beds"
        }
    ]
};